import React, { FunctionComponent } from 'react';

const SVGIconHomeV1: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 14 16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M0.333374 15.5V5.5L7.04171 0.5L13.6667 5.5V15.5H8.79171V9.5625H5.18754V15.5H0.333374ZM1.58337 14.25H3.93754V8.3125H10.0417V14.25H12.4167V6.125L7.04171 2.0625L1.58337 6.125V14.25Z' />
  </svg>
);

export { SVGIconHomeV1 };
