import React, {
  Fragment,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import style from './CommunicationBoard.module.scss';
import {
  BrassIconMoneyBill,
  BrassIconProps,
  BrassLoader,
} from '../../../../components';
import {
  NotificationCardItem,
  UserCommunications,
  UserNotificationGroup,
} from '../../models';
import { translate } from './CommunicationBoard.translations';
import { ApiResult } from '../../../../models';
import { CommunicationService } from '../../services';
import { CommunicationList } from './communication-list/CommunicationList';
import { NotificationCard } from '../notification-board/notification-card/NotificationCard';

interface CommunicationBoardProps extends PropsWithChildren<any> {
  routes: any;
}

const CommunicationBoard: FunctionComponent<CommunicationBoardProps> = memo(
  (props: CommunicationBoardProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filteredCommunicationsList, setFilteredCommunicationsList] = useState<
      UserCommunications[]
    >([]);
    const [communicationList, setCommunicationList] = useState<UserCommunications[]>(
      [],
    );
    const [communicationGroup, setNotificationType] =
      useState<UserNotificationGroup>(UserNotificationGroup.ALL);

    const communicationCards: NotificationCardItem[] = [
      {
        icon: (_props: BrassIconProps) => <BrassIconMoneyBill {..._props} />,
        data: communicationList.filter(
          (notification: UserCommunications) =>
            notification.group === UserNotificationGroup.FINANCE,
        ).length,
        description: translate('finance'),
        group: UserNotificationGroup.FINANCE,
      },
    ];

    useEffect(() => {
      setIsLoading(true);
      CommunicationService.getCommunicationList().then(
        (apiResult: ApiResult<UserCommunications[]>) => {
          if (apiResult.success) {
            setCommunicationList(apiResult.data);
            setFilteredCommunicationsList(apiResult.data);
          }
          setIsLoading(false);
        },
      );
    }, []);

    useEffect(() => {
      if (communicationGroup !== UserNotificationGroup.ALL) {
        setFilteredCommunicationsList(
          communicationList.filter(
            (n: UserCommunications) => n.group === communicationGroup,
          ),
        );
      } else {
        setFilteredCommunicationsList(communicationList);
      }
    }, [communicationGroup]);

    if (isLoading) {
      return <BrassLoader useLoadingMask />;
    }

    return (
      <Fragment>
        <div className={style.container}>
          {communicationCards.map((communicationCard, index) => (
            <div
              key={index}
              className={style.card}
              onClick={(): void => {
                if (communicationGroup === communicationCard.group) {
                  setNotificationType(UserNotificationGroup.ALL);
                } else {
                  setNotificationType(
                    communicationCard.group as UserNotificationGroup,
                  );
                }
              }}
            >
              <NotificationCard
                onClick={(): void => {
                  if (communicationGroup === communicationCard.group) {
                    setNotificationType(UserNotificationGroup.ALL);
                  } else {
                    setNotificationType(
                      communicationCard.group as UserNotificationGroup,
                    );
                  }
                }}
                className={
                  communicationGroup === communicationCard.group
                    ? style['card-on']
                    : style['card-off']
                }
                Icon={communicationCard.icon}
                data={communicationCard.data}
                description={communicationCard.description}
              />
            </div>
          ))}
        </div>
        <CommunicationList data={filteredCommunicationsList} routes={props.routes} />
      </Fragment>
    );
  },
);

export type { CommunicationBoardProps };
export { CommunicationBoard };
