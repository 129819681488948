import React, {
  CSSProperties,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import styles from './Background.module.scss';

interface BrassBackgroundProps extends PropsWithChildren<any> {
  onClickCallBack?: any;
  style?: CSSProperties;
}

const BrassBackground: FunctionComponent<BrassBackgroundProps> = (
  props: BrassBackgroundProps,
) => {
  const { className, style } = props;
  const _className = classnames(
    'brass-background',
    styles['brass-background'],
    className,
  );

  return (
    <Fragment>
      {createPortal(
        <div
          role='presentation'
          className={_className}
          style={style}
          onClick={() => {
            props.onClickCallBack();
          }}
          onKeyDown={() => {
            props.onClickCallBack();
          }}
        />,
        document.getElementById('background-root') as HTMLElement,
      )}
    </Fragment>
  );
};

export { BrassBackground };
export type { BrassBackgroundProps };
