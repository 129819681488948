import { Label } from '@progress/kendo-react-labels';
import { useState } from 'react';
import { translate } from './PublishDocumentsModal.translations';
import {
  DocumentActionType,
  IPublishDocuments,
  IPublishDocumentsListProps,
} from './PublishDocumentsModal.interfaces';
import style from './PublishDocumentsModal.module.scss';
import { listPublishDocuments } from './PublishListDocuments';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { BrassPublishDocumentWindow } from './publish-document-window/PublishDocumentWindow';
import { BrassIcon } from '../icon';
import classNames from 'classnames';

const BrassPublishDocumentsModal = (props: IPublishDocuments): any => {
  const {
    getPublishDocument,
    disabled,
    setPublishDocument,
    acceptFilesTypes,
    color,
    label,
    children,
    noLabel,
    classname_list_icons,
    classname_window,
    overlayStyle,
  } = props;
  const [publishDocumentElement, setPublishDocumentElement] =
    useState<IPublishDocumentsListProps>({} as IPublishDocumentsListProps);
  const [open, setOpen] = useState<boolean>(false);

  const onSelectDocument = (item: IPublishDocumentsListProps): void => {
    if (!disabled) {
      setPublishDocumentElement(item);
      setOpen(true);
    }
  };

  const hideFilesNotAccepts = (typeDocument: DocumentActionType): boolean =>
    acceptFilesTypes! &&
    acceptFilesTypes?.length > 0 &&
    acceptFilesTypes?.find((accepts) => typeDocument === accepts) === undefined;

  return (
    <>
      {noLabel ? (
        <></>
      ) : (
        <>
          {!label && (
            <Label className={style.label} style={{ color: color ? color : '#000' }}>
              <BrassIcon
                icon={faLink}
                className={style.icon}
                color={color ? color : '#000'}
              />
              {translate('title')}
            </Label>
          )}
          {label! && label}
        </>
      )}
      <div
        className={classNames(
          { [style['publish-icon-list']]: true },
          classname_list_icons,
        )}
      >
        {children}
        {listPublishDocuments.map((item, i) => (
          <div
            title={item.name}
            key={i}
            onKeyPress={undefined}
            role={'button'}
            className={classNames({
              [style['hide']]: hideFilesNotAccepts(item.action),
            })}
            tabIndex={-1}
            onClick={() => onSelectDocument(item)}
          >
            {item.icon({
              fill: disabled ? `${color ?? '#000000'}60` : `${color ?? '#000000'}`,
              color: disabled ? `${color ?? '#000000'}60` : `${color ?? '#000000'}`,
              cursor: disabled ? 'default' : 'pointer',
            })}
          </div>
        ))}
      </div>
      {open && (
        <BrassPublishDocumentWindow
          onCancelClose={() => setOpen(false)}
          publishDocumentElement={publishDocumentElement}
          setPublishDocument={setPublishDocument}
          getPublishDocument={getPublishDocument}
          className={classname_window}
          overlayStyle={overlayStyle}
        />
      )}
    </>
  );
};

export { BrassPublishDocumentsModal };
