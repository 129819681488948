import React, {
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import style from './CardTreeListGrid.module.scss';
import { BrassCardTreeListGridProps } from './CardTreeListGridProps.interface';
import {
  onItemChangeCard,
  onHeaderSelectionChangeCard,
  onSelectionChangeCard,
  getColumnsCard,
  getGridToolbarCard,
  getHelpPanelCard,
  getExcelExportCard,
  getPDFExportCard,
  getGridBrassLoaderCard,
  onDataStateChangeCard,
  onSortChangeCard,
  onPageChangeCard,
  onFilterChangeCard,
  getWindowWidthCard,
  getUpsertWindowCard,
  pageDataCard,
  getExcelExportColumnsCard,
  onGroupChangeCard,
  onExpandChangeCard,
  cellRenderCard,
} from './CardTreeListGrid.functions';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import {
  Grid,
  GridCellProps,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridGroupChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassGridConfigDefault,
  BrassSplitter,
  MainLayoutContainerContext,
} from '../../../../components';
import {
  AggregateDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { useWindowSize } from '../../../../hooks';

const USER_HELP_PANEL_SIZE = 400;

const renderCardGrid = (props: BrassCardTreeListGridProps): ReactNode => {
  const {
    sortable,
    reorderable,
    gridState,
    extraGridToolbar,
    customFilterGridToolbar,
    selectable,
    pageable,
    groupable,
    filterable,
    upsertWindow,
    className,
    customGridToolbar,
    fieldIdName,
    width,
    height,
    color,
  } = props;
  const _className = classnames('brass-grid', style['brass-grid'], className);
  const anchorRef = useRef<any>(null);
  const { menuExpanded } = useContext(MainLayoutContainerContext);

  const excelExportColumns = getExcelExportColumnsCard(props);
  const columns: GridColumnProps[] = getColumnsCard(props);

  let gridPDFExport: GridPDFExport | null;
  const gridExcelExport = useRef<ExcelExport | null>(null);

  /** REVER */

  const [windowWidth, setwindowWidth] = useState<string>(
    getWindowWidthCard(menuExpanded),
  );

  useEffect(() => {
    const recalculateWindowWidth = () =>
      setwindowWidth(getWindowWidthCard(menuExpanded));
    setwindowWidth(getWindowWidthCard(menuExpanded));
    window.addEventListener('resize', recalculateWindowWidth, true);

    return () => window.removeEventListener('resize', recalculateWindowWidth);
  }, [menuExpanded, window.innerWidth]);

  const aggregates = columns!
    // @ts-ignore
    .filter((col) => col.props && col.props.aggregate)
    // @ts-ignore
    .map((col) => col.props)
    .map((col) => {
      return {
        field: col.aggregateField,
        aggregate: col.aggregate,
      } as AggregateDescriptor;
    });

  const groups = gridState.dataState.group;
  if (groups) {
    groups.forEach((group) => (group.aggregates = aggregates));
  }

  useEffect(() => {
    document.body.style.setProperty('--background-table', `${color}60` || '#FFFFFF');
    document.body.style.setProperty('--color-table', `${color}` || '#0000008A');
  }, [color]);

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      {gridState.gridLoading && getGridBrassLoaderCard()}
      <BrassSplitter
        panes={[
          {},
          {
            size: `${USER_HELP_PANEL_SIZE}px`,
            resizable: false,
          },
        ]}
        style={{
          height: '100%',
        }}
      >
        <Grid
          ref={anchorRef}
          style={{
            width: !width
              ? gridState.showHelpPanel
                ? `${Number(windowWidth.split('px')[0]) - USER_HELP_PANEL_SIZE}px`
                : windowWidth
              : width,
            height: height ? `${height}px` : '100%',
            maxHeight: height ? `${height}px` : '100%',
          }}
          dataItemKey={gridState.dataItemKey}
          expandField='expanded'
          editField='inEdit'
          resizable={true}
          cellRender={(
            defaultRendering: ReactElement<
              HTMLTableCellElement,
              string | JSXElementConstructor<any>
            > | null,
            originalProps: GridCellProps,
          ) => cellRenderCard(defaultRendering, originalProps, props)}
          sortable={sortable!}
          reorderable={reorderable!}
          data={gridState.dataResult}
          {...gridState.dataState}
          className={classnames(_className, style['grid-footer'])}
          onItemChange={(event: GridItemChangeEvent) =>
            onItemChangeCard(event, props)
          }
          onDataStateChange={(event: GridDataStateChangeEvent) =>
            onDataStateChangeCard(event, props)
          }
          pageable={pageable ? pageDataCard : undefined}
          pageSize={
            gridState.gridConfig
              ? gridState.gridConfig.take
              : BrassGridConfigDefault.take
          }
          total={gridState.recordsList.total}
          onPageChange={(event: GridPageChangeEvent) =>
            onPageChangeCard(event, props)
          }
          groupable={{
            enabled: !!gridState.gridConfig.group,
            footer: groupable?.footer,
          }}
          group={gridState.group}
          onGroupChange={(event: GridGroupChangeEvent) =>
            onGroupChangeCard(event, props)
          }
          onExpandChange={(event: GridExpandChangeEvent) =>
            onExpandChangeCard(event, props)
          }
          sort={gridState.gridConfig.sort!}
          onSortChange={(event: GridSortChangeEvent) =>
            onSortChangeCard(event, props)
          }
          filterable={filterable}
          filter={gridState.gridFilter as CompositeFilterDescriptor}
          onFilterChange={(event: GridFilterChangeEvent) =>
            onFilterChangeCard(event, props)
          }
          selectable={selectable}
          selectedField='selected'
          onSelectionChange={(event: GridSelectionChangeEvent) =>
            onSelectionChangeCard(event, props, fieldIdName, selectable)
          }
          onHeaderSelectionChange={(event: GridHeaderSelectionChangeEvent) =>
            onHeaderSelectionChangeCard(event, props, fieldIdName)
          }
        >
          {customGridToolbar}
          {getGridToolbarCard(props, gridPDFExport!, gridExcelExport) &&
            gridState.showFilterPanel &&
            customFilterGridToolbar && (
              <GridToolbar>{customFilterGridToolbar}</GridToolbar>
            ) &&
            extraGridToolbar}

          {!gridState.gridLoading && <GridNoRecords />}
          {columns}
        </Grid>
        {getHelpPanelCard(props)}
      </BrassSplitter>

      {upsertWindow && getUpsertWindowCard(props)}
      <div style={{ overflow: 'hidden', maxHeight: 0, maxWidth: 0 }}>
        {getExcelExportCard(props, excelExportColumns || columns, gridExcelExport)}
        {getPDFExportCard(
          props,
          columns,
          (pdfExport: GridPDFExport) => (gridPDFExport = pdfExport),
        )}
      </div>
    </div>
  );
};

export { renderCardGrid };
