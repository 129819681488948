import React, { FunctionComponent, useState } from 'react';
import style from './ImageViewer.module.scss';
import classNames from 'classnames';
import { newGuid } from '../../../../util/Guid';

interface BrassImageViewerProps {
  url: string;
  className?: string;
}

const BrassImageViewer: FunctionComponent<BrassImageViewerProps> = (
  props: BrassImageViewerProps,
) => {
  const { url, className } = props;

  const imageId = newGuid();

  const [scale, setScale] = useState<number>(1);
  const [panning, setPanning] = useState<boolean>(false);

  const [pointX, setPointX] = useState<number>(0);
  const [pointY, setPointY] = useState<number>(0);

  const [start, setStart] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // https://dev.to/stackfindover/zoom-image-point-with-mouse-wheel-11n3
  const onWheel = (event: any): void => {
    event.preventDefault();

    let _scale = scale;
    const xs = (event.clientX - pointX) / scale;
    const ys = (event.clientY - pointY) / scale;
    const delta = event.wheelDelta ? event.wheelDelta : -event.deltaY;

    if (delta > 0) {
      _scale = scale * 1.2;
      setScale(_scale);
    } else {
      _scale = scale / 1.2;
      setScale(_scale);
    }

    setPointX(event.clientX - xs * _scale);
    setPointY(event.clientY - ys * _scale);
  };

  const onMouseUp = (): void => setPanning(false);
  const onMouseDown = (event: any): void => {
    setPanning(true);
    setStart({ x: event.clientX - pointX, y: event.clientY - pointY });
  };
  const onMouseMove = (event: any): void => {
    if (!panning) {
      return;
    }

    setPointX(event.clientX - start.x);
    setPointY(event.clientY - start.y);
  };

  return (
    <div
      id={imageId}
      className={classNames('brass-image-viewer', style['center'], className)}
      onWheel={onWheel}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      style={{
        transform:
          'translate(' + pointX + 'px, ' + pointY + 'px) scale(' + scale + ')',
        backgroundImage: `url('${url}')`,
        backgroundSize: 'contain',
      }}
    />
  );
};

export { BrassImageViewer };
