const isObject = (object: any): boolean =>
  object != null && typeof object === 'object';

const isDeepEqual = (object1: any, object2: any): boolean => {
  if (object1 === undefined || object2 === undefined) {
    return false;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (areObjects) {
      return isDeepEqual(val1, val2);
    } else if (!areObjects && val1 !== val2) {
      return false;
    }
  }
  return true;
};

type KeyListObject = {
  [key: string]: any[];
};

// Esse método obtém a chave de um item presente em uma lista contida nessa chave
const getKeyFromItemOnListValue = (
  object: KeyListObject,
  item: any,
): string | undefined => {
  const entries: [string, any[]][] = Object.entries(object);
  for (let i = 0; i < entries.length; i++) {
    if (entries[i][1].includes(item)) {
      return entries[i][0] as string;
    }
  }
  return undefined;
};

export { isDeepEqual, isObject, getKeyFromItemOnListValue };
