import {
  AuthUser,
  DynamicDispatchAlert,
  getLanguagesStorage,
  IBrassCommentChatV1Props,
  IPermissionChatV1,
} from '../../../index';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  IChatPayload,
  IChatResponse,
  IInsertCommentChat,
  ItemLikes,
} from './CommentChat.model';
import { ChatService } from './CommentChat.service';
import { translate } from './CommentChat.translations';
import { validate } from 'uuid';
import { Endpoints } from '../../../models';
import { ApiService, EnvironmentService } from '../../../services';
interface IChatFunctions {
  referenceId: string;
  forceRefresh: boolean;
  onClickSendMessage?: (e: IInsertCommentChat) => IInsertCommentChat | void;
  endpoints?: Endpoints | null;
  permissions?: IPermissionChatV1;
  initOpen?: boolean;
}

const ChatFunctions = (props: IChatFunctions) => {
  const {
    referenceId,
    forceRefresh,
    onClickSendMessage,
    endpoints,
    permissions,
    initOpen,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [data, setData] = useState<IChatResponse[]>([] as IChatResponse[]);
  const [message, setMessage] = useState<string>('');
  const dispatch = useDispatch();
  ChatService.api = new ApiService(
    endpoints!
      ? endpoints
      : EnvironmentService.endpoints!
      ? EnvironmentService.endpoints
      : Endpoints.CONNECT,
  );
  const [openColapse, setOpenColapse] = useState<boolean>(false);
  const [openComment, setOpenComment] = useState<boolean>(false);

  const handleGetChatByReferenceId = useCallback(() => {
    if (validate(referenceId)) {
      setIsLoading(true);
      ChatService.getChatByReferenceId(referenceId)
        .then((res) => {
          if (res.success) {
            setData(res.data);
          } else {
            DynamicDispatchAlert(dispatch, ['getChatError'], 'ERROR', translate);
          }
        })
        .catch(() => {
          DynamicDispatchAlert(dispatch, ['getChatError'], 'ERROR', translate);
        })
        .finally(() => setIsLoading(false));
    }
  }, [referenceId, refresh, forceRefresh]);

  const handleInsertKeyPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (onClickSendMessage) {
        onClickSendMessage({
          payload: {
            language: getLanguagesStorage()?.language,
            message,
            referenceId,
            user: [{ userId: AuthUser.getId() }],
          },
          options: {
            setIsLoading,
            setMessage,
            setDisableButton,
          },
        });
      }
    }
  };

  const handleActionShowComment = () => {
    if (permissions?.canComment) {
      setOpenColapse(true);
      setOpenComment((prev) => !prev);
    }
  };

  const verifyLike = (likes?: any[]) => {
    if (likes && likes.length > 0) {
      return (
        likes.filter((_) => _?.userId === AuthUser._currentUser?.id)?.length > 0
      );
    } else {
      return false;
    }
  };

  const formatName = (name: string) => {
    const splitUserName = name.split(' ');
    const firstUserName = splitUserName[0].split('')[0];
    const lastUserName = splitUserName[splitUserName.length - 1].split('')[0];
    const initialUserName = firstUserName.concat(lastUserName);

    return initialUserName;
  };

  useEffect(() => {
    handleGetChatByReferenceId();
  }, [handleGetChatByReferenceId]);

  useEffect(() => {
    if (initOpen) {
      setOpenColapse(true);
      setRefresh((prev) => prev);
    }
  }, [initOpen]);

  return {
    isLoading,
    setIsLoading,
    refresh,
    setRefresh,
    data,
    message,
    disableButton,
    setDisableButton,
    handleInsertKeyPressEnter,
    setMessage,
    openColapse,
    openComment,
    handleActionShowComment,
    verifyLike,
    setOpenColapse,
    formatName,
  };
};

export { ChatFunctions };
