import { emptyGuid, SystemTypeEnum } from '../../';

class System {
  // Informa a GRID que o registro está em processo de EXCLUSÃO
  public deleting: boolean = false;

  public id: string = emptyGuid();
  public systemId: string = emptyGuid();
  public parentSystemId: string = emptyGuid();

  public ownerSystemId: string = emptyGuid();
  public accountId: string = emptyGuid();
  public projectId: string = emptyGuid();
  public areaId: string = emptyGuid();
  public systemClassificationId: string = emptyGuid();
  public fileClassificationId: string = emptyGuid();

  public tag: string = '';
  public name: string = '';
  public thumbnail: string = '';
  public description: string = '';
  public costCenter: string = '';

  public category: string[] = [];

  public type: SystemTypeEnum = SystemTypeEnum.INSTITUTIONAL;

  constructor(system: Partial<System>) {
    Object.assign(this, system);
  }
}

export { System };
