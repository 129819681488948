import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import {
  BrassDivFlex,
  BrassForm,
  BrassFormElement,
  BrassLoader,
  BrassWindowActionsBar,
} from '../../../../components/brass';
import { DropDownData } from '../../../../models';
import { RelationalFlagsProvider } from '../../../../store/RelationalFlagsContext';
import { BrassButtonSaveV1 } from '../../buttons';
import style from './LinkRelationalFlagsForm.module.scss';
import { translate } from './LinkRelationalFlagsForm.translations';
import { ListRelationalContextMap } from './list-context-relational-map/ListRelationalContextMap';
import { ListRelationalGroup } from './list-relational-group/ListRelationalGroup';
import { ListRelationalSubGroup } from './list-relational-subgroup/ListRelationalSubGroup';
import { RelationalFlagGrid } from './relational-flag-grid/RelationalFlagGrid';
import { RelationalFlagSelected } from './relational-flag-selected/RelationalFlagSelected';
import { SearchFlags } from './search/SearchFlags';

interface BrassLinkFlagsFormProps {
  onSubmit: (data: any) => void;
  onSaveClose: () => void;
  content?: (e?: any) => ReactElement;
  initialValueFlags: DropDownData[];
  readonly?: boolean;
}

const BrassLinkRelationalFlagsForm: FunctionComponent<BrassLinkFlagsFormProps> = (
  props: BrassLinkFlagsFormProps,
) => {
  const { onSubmit, onSaveClose, content, initialValueFlags } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFlags, setSelectedFlags] = useState<DropDownData[]>(
    initialValueFlags || [],
  );

  const handleSubmit = useCallback(async (flags: any): Promise<void> => {
    setIsLoading(true);
    onSubmit(flags);
    if (onSaveClose) {
      onSaveClose();
    }
    setIsLoading(false);
  }, []);

  const renderForm: any = () => {
    return (
      <>
        <BrassFormElement className={style.fields}>
          {content && <div className={style.content}>{content()}</div>}
          <div className={style.box}>
            <SearchFlags />
          </div>
          <div className={style.box1}>
            <ListRelationalContextMap />
          </div>
          <div className={style.box2}>
            <ListRelationalGroup />
            <ListRelationalSubGroup />
            <RelationalFlagGrid
              setSelectedFlags={setSelectedFlags}
              selectedFlags={selectedFlags}
              readonly={props?.readonly}
            />
          </div>
          <div className={style.box3}>
            <RelationalFlagSelected
              initialValueFlags={initialValueFlags}
              setSelectedFlags={setSelectedFlags}
              selectedFlags={selectedFlags}
              readonly={props?.readonly}
            />
          </div>
          <BrassWindowActionsBar>
            <BrassDivFlex />
            {!props?.readonly && (
              <BrassButtonSaveV1
                autoFocus
                type='button'
                onClick={() => {
                  handleSubmit(selectedFlags);
                }}
                disabled={!selectedFlags}
                hideIconWhenLoader
                className={style['button-save']}
              />
            )}
          </BrassWindowActionsBar>
        </BrassFormElement>
      </>
    );
  };

  return (
    <RelationalFlagsProvider>
      <div className={style.form}>
        {!isLoading && <BrassForm render={renderForm} />}
        {isLoading && <BrassLoader useLoadingMask />}
      </div>
    </RelationalFlagsProvider>
  );
};

export { BrassLinkRelationalFlagsForm };
