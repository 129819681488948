import React, { FunctionComponent } from 'react';
import style from './ButtonV1.module.scss';
import classnames from 'classnames';
import { Button, ButtonProps } from '@progress/kendo-react-buttons';

interface BrassButtonPropsV2 extends Omit<ButtonProps, 'icon'> {
  icon?: any;
  roundBorder?: boolean;
}

const BrassButtonV2: FunctionComponent<BrassButtonPropsV2> = (
  props: BrassButtonPropsV2,
) => {
  const { className, roundBorder } = props;
  const _props = { ...props };
  delete _props.roundBorder;

  const _className = classnames(
    'brass-button',
    style['brass-button'],
    roundBorder && style['brass-button-round'],
    className,
  );

  return <Button {..._props} className={_className} />;
};

BrassButtonV2.defaultProps = {
  type: 'submit',
};

export { BrassButtonV2 };
export type { BrassButtonPropsV2 };
