// @ts-nocheck
import {
  Component,
  FunctionComponent,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Fragment,
} from 'react';
import style from './TreeListGrid.v3.module.scss';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridGroupChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridPageChangeEvent,
  GridRowProps,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassButtonHelpV1,
  BrassLoader,
  BrassTreeListGridPropsV3,
  BrassWindow,
  BrassButtonFilter,
  HideHeaderCell,
  BrassGridCell,
  BrassPager,
  BrassPagerProps,
  BrassButtonAddV1,
  BrassButtonRefreshV1,
  BrassButtonViewV1,
  BrassButtonEditV1,
  BrassButtonDeleteV1,
} from '../../../..';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnProps,
} from '@progress/kendo-react-excel-export';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  ApiGridResult,
  GridActions,
  GridColumnTypeEnum,
  GridItem,
  II18n,
} from '../../../../../models';
import { emptyGuid } from '../../../../../util';
import { MoneyService, DateTimeService, I18nService } from '../../../../../services';
import {
  BrassEditCell,
  BrassDeleteCell,
  BrassCommandCell,
  BrassGridFooterCellProps,
} from '../../cells';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { groupBy, process, GroupDescriptor } from '@progress/kendo-data-query';
import {
  TreeListFilterChangeEvent,
  TreeListPageChangeEvent,
} from '@progress/kendo-react-treelist';
import classNames from 'classnames';

interface CellRenderProps {
  originalProps: GridCellProps;
  td: ReactElement<HTMLTableCellElement>;
  enterEdit: (
    dataItem: GridItem,
    field: string | undefined,
    props: BrassTreeListGridPropsV3,
  ) => void;
  editField: string | undefined;
  gridProps: BrassTreeListGridPropsV3;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: ReactElement<HTMLTableRowElement>;
  exitEdit: (props: BrassTreeListGridPropsV3) => void;
  editField: string | undefined;
  gridProps: BrassTreeListGridPropsV3;
}

const translations: II18n = {
  es: {
    group: 'Agrupar por',
  },
  enUS: {
    group: 'Group by',
  },
  ptBR: {
    group: 'Agrupar por',
  },
};

const translateInternal = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const enterEdit = (dataItem: GridItem, props: BrassTreeListGridPropsV3) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const newData = props.gridState.dataResult.data.map((item: GridItem) => {
      return {
        ...item,
        inEdit: item.id === dataItem.id ? true : undefined,
      };
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  }
};

const cancelEdit = (dataItem: GridItem, props: BrassTreeListGridPropsV3) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const originalItem = props.gridState.recordsList!.records!.find(
      (item: GridItem) => item.id === dataItem.id,
    );
    const newData = props.gridState.dataResult.data.map((item: GridItem) =>
      item.id === originalItem!.id ? { ...originalItem, inEdit: undefined } : item,
    );

    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  }
};

const exitEdit = (props: BrassTreeListGridPropsV3) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const updatedItem = props.gridState.dataResult.data.find(
      (item: GridItem) => item.inEdit,
    )!;
    // props.gridCallbacks.onUpdate(updatedItem);

    const newData = props.gridState.dataResult.data.map((item: GridItem) => {
      return {
        ...item,
        inEdit: undefined,
      };
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
    props.gridDispatch({
      type: GridActions.UPDATE_EDIT_FIELD,
      payload: undefined,
    });
  }
};

const pageData: any = {
  info: true,
  buttonCount: 5,
  type: 'numeric',
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 3000, 5000, 10000],
  previousNext: true,
  group: [],
};

const selectColumn = (props: BrassTreeListGridPropsV3, columns: ReactNode[]) => {
  if (props.selectable && props.selectable.enabled) {
    columns.unshift(
      <GridColumn
        width='62px'
        key='selected'
        field='selected'
        resizable={false}
        groupable={false}
        filterable={false}
        className={style['brass-grid-column']}
        headerSelectionValue={
          props.gridState.recordsList.records &&
          props.gridState.recordsList.records.length > 0 &&
          props.gridState.selectedRecords.length ===
            props.gridState.recordsList.records.length
        }
      />,
    );
  }
};

const commandColumn = (
  props: BrassTreeListGridPropsV3,
  baseColumns: ReactNode[],
) => {
  if (
    props.permissions!.update &&
    baseColumns.map((col: any) => col.props).find((col: any) => col.editor)
  ) {
    props.treeListColumns
      ? baseColumns.push({
          cell: editCell(),
          width: 100,
          field: 'actions',
          title: 'Actions',
        })
      : baseColumns.unshift(
          <GridColumn
            key='inline-command'
            field='inlineCommand'
            groupable={false}
            width='120px'
            cell={(_props) =>
              BrassCommandCell({
                gridProps: props,
                gridCellProps: _props,
                onUpdateCallback: async (
                  dataItem: GridItem,
                  props: BrassTreeListGridPropsV3,
                ): Promise<void> => {
                  if (
                    props.gridState.dataResult &&
                    props.gridState.dataResult.data
                  ) {
                    const updatedItem = props.gridState.dataResult.data.find(
                      (item: GridItem) => item.inEdit,
                    )!;
                    await props.gridCallbacks.onUpdate(updatedItem);
                    cancelEdit(dataItem, props);
                  }
                },
                onCancelCallback: cancelEdit,
                onEditCallback: enterEdit,
                onDeleteCallback: async (
                  dataItem: GridItem,
                  props: BrassTreeListGridPropsV3,
                ): Promise<void> => {
                  if (
                    props.gridState.dataResult &&
                    props.gridState.dataResult.data
                  ) {
                    const updatedItem = props.gridState.dataResult.data.find(
                      (item: GridItem) => item.inEdit,
                    )!;
                    await props.gridCallbacks.onDelete(updatedItem);
                    cancelEdit(dataItem, props);
                  }
                },
              })
            }
            filterable={false}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const editCell = (): any =>
  class extends Component {
    public render(): any {
      return (
        <td>
          teste
          {/* <Button>
            teste
          </Button> */}
        </td>
      );
    }
  };

const editColumn = (props: BrassTreeListGridPropsV3, baseColumns: ReactNode[]) => {
  if (
    props.permissions!.update &&
    !baseColumns.map((col: any) => col.props).find((col: any) => col && col.editor)
  ) {
    props.treeListColumns
      ? baseColumns.push({
          cell: editCell(),
          width: 120,
          field: 'actions',
          title: 'Actions',
        })
      : baseColumns.unshift(
          <GridColumn
            key='actions'
            resizable={false}
            filterable={false}
            groupable={false}
            width='auto'
            cell={(_props) => BrassEditCell(_props, props.gridCallbacks.onEdit)}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const actionsColumn = (
  props: BrassTreeListGridPropsV3,
  baseColumns: ReactNode[],
) => {
  if (
    (props.permissions!.update &&
      !baseColumns
        .map((col: any) => col.props)
        .find((col: any) => col && col.editor)) ||
    props.permissions!.delete
  ) {
    baseColumns.push(
      <GridColumn
        resizable={false}
        key='edit'
        field='edit'
        groupable={false}
        width='50px'
        cell={(_props: any) => {
          if (_props.rowType === 'groupHeader' || _props.rowType === 'groupFooter') {
            return <Fragment />;
          }

          return (
            <BrassGridCell {..._props} textAlign='center'>
              <BrassButtonViewV1
                onClick={() => {}}
                showText={false}
                hideIconWhenLoader
              />
              {props.permissions!.update && (
                <BrassButtonEditV1
                  onClick={() => {}}
                  showText={false}
                  hideIconWhenLoader
                />
              )}
              {props.permissions!.delete && (
                <BrassButtonDeleteV1
                  onClick={() => {}}
                  showText={false}
                  hideIconWhenLoader
                />
              )}
            </BrassGridCell>
          );
        }}
        filterable={false}
        headerCell={HideHeaderCell}
      />,
    );
  }
};

const deleteColumn = (props: BrassTreeListGridPropsV3, baseColumns: ReactNode[]) => {
  if (props.permissions!.delete) {
    baseColumns.push(
      <GridColumn
        resizable={false}
        key='delete'
        field='delete'
        width='50px'
        groupable={false}
        cell={(_props) =>
          BrassDeleteCell(
            _props,
            props.gridCallbacks.onDelete,
            props.gridState.recordsList,
            props.gridDispatch,
          )
        }
        filterable={false}
        headerCell={HideHeaderCell}
      />,
    );
  }
};

const onSelectionChange = (
  event: GridSelectionChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  const recordId = event.dataItem.id;
  const recordSelected = props.gridState.selectedRecords.indexOf(recordId) !== -1;

  // Atualiza o ESTADO local com os ID selecionados pelo usuário
  let _selectedRecordsId = [...props.gridState.selectedRecords];
  if (recordSelected) {
    _selectedRecordsId = _selectedRecordsId.filter((f) => f !== recordId);
  } else {
    _selectedRecordsId.push(recordId);
  }
  props.gridDispatch({
    type: GridActions.UPDATE_SELECTED_RECORDS,
    payload: _selectedRecordsId,
  });

  // ----------------------------------------------------------------------
  // Marca o Registro como SELECIONADO/NÃO-SELECIONADO
  if (
    props.gridState.recordsList.records &&
    props.gridState.recordsList.records.length > 0
  ) {
    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: {
        ...props.gridState.recordsList,
        records: props.gridState.recordsList.records.map((record: any) => {
          if (record.id === recordId) {
            record[event.selectedField] = !recordSelected;
          }
          return record;
        }),
      },
    });
  }

  props.onSelectionChange && props.onSelectionChange(_selectedRecordsId, event);
};

const onGroupChange = (
  event: GridGroupChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  if (event.group.length <= 2) {
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_STATE,
      payload: {
        ...props.gridState.dataState,
        group: event.group,
      },
    });
    props.gridDispatch({
      type: GridActions.UPDATE_GRID_CONFIG,
      payload: {
        ...props.gridState.gridConfig,
        group: event.group,
      },
    });

    props.gridDispatch({
      type: GridActions.UPDATE_GROUP,
      payload: event.group,
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: setExpandedState({
        data: processWithGroups(props.gridState.recordsList.records!, event.group),
        collapsedIds: props.gridState.collapsedState,
      }),
    });
  }
};

const onExpandChange = (
  event: GridExpandChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  const item = event.dataItem;

  if (item.groupId) {
    const newCollapsedIds = !event.value
      ? [...props.gridState.collapsedState, item.groupId]
      : props.gridState.collapsedState.filter((groupId) => groupId !== item.groupId);
    props.gridDispatch({
      type: GridActions.UPDATE_COLLAPSED_STATE,
      payload: newCollapsedIds,
    });
  }
};

const onHeaderSelectionChange = (
  event: GridHeaderSelectionChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  let _selectedRecordsId = [];
  if (
    props.gridState.recordsList.records &&
    props.gridState.recordsList.records.length > 0
  ) {
    const allRecordsSelected =
      props.gridState.selectedRecords.length ===
      props.gridState.recordsList.records.length;

    if (!allRecordsSelected) {
      _selectedRecordsId = props.gridState.recordsList.records.map((r: any) => r.id);
    }

    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: {
        ...props.gridState.recordsList,
        records: props.gridState.recordsList.records.map((record: any) => {
          record[event.selectedField] = !allRecordsSelected;
          return record;
        }),
      },
    });
  }

  props.gridDispatch({
    type: GridActions.UPDATE_SELECTED_RECORDS,
    payload: _selectedRecordsId,
  });
  props.onHeaderSelectionChange &&
    props.onHeaderSelectionChange(_selectedRecordsId, event);
};

const exportPDF = (gridPDFExport: GridPDFExport | null) => {
  if (gridPDFExport !== null && gridPDFExport !== undefined) {
    gridPDFExport.save();
  }
};

const excelExport = (gridExcelExport: MutableRefObject<ExcelExport | null>) => {
  if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
    gridExcelExport.current.save();
  }
};

const onItemChange = (
  event: GridItemChangeEvent,
  props: BrassTreeListGridPropsV3,
): void => {
  const { dataItem, field, value } = event;
  if (props.gridState.editField) {
    const field = event.field || '';
    event.dataItem[field] = event.value;
    const newData = props.gridState.dataResult.data.map((item: GridItem) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  } else {
    const update =
      props.gridState.recordsList.records &&
      props.gridState.recordsList.records.map((record: any) => {
        if (!record.oldValues) {
          record.oldValues = record;
        }
        if (record.id === dataItem.id) {
          return { ...record, [field || 'undefined']: value };
        }
        return record;
      });

    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: { ...update },
    });
  }
};

const processWithGroupsAggregate = (
  recordsList,
  gridConfig,
  gridDispatch,
  gridColumns,
) => {
  const groups = gridConfig.group;
  const aggregates = gridColumns!
    .filter((col) => col.props.aggregate)
    .map((col) => col.props)
    .map((col) => {
      return {
        field: col.aggregateField,
        aggregate: col.aggregate,
      };
    });

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...gridConfig,
      group: groups,
    },
  });

  const newDataState = process(recordsList, gridConfig);
  setGroupIds({
    data: newDataState.data,
    group: gridConfig.group,
  });
  return newDataState;
};

const getCells = (columns, cellProps) => {
  const cells = [];
  columns.forEach((column) => {
    if (column.aggregate && column.aggregateField && column.columnType) {
      const _sumValues =
        cellProps.dataItem.aggregates[column.aggregateField][column.aggregate];
      cells.push(
        <td style={{ fontSize: 18, textAlign: 'center' }}>
          {column.columnType === GridColumnTypeEnum.MONEY
            ? MoneyService.formatDecimal(_sumValues ?? 0)
            : DateTimeService.getHoursFromMinutes(_sumValues)}
        </td>,
      );
    } else {
      cells.push(<td>&nbsp;</td>);
    }
  });
  return cells;
};

const cellRender = (tdElement, cellProps, props: BrassTreeListGridPropsV3) => {
  if (
    cellProps.rowType === 'groupHeader' &&
    tdElement &&
    tdElement.props.role != 'presentation'
  ) {
    const columns = props.gridColumns;
    return (
      <>
        <td
          {...tdElement.props}
          colSpan={tdElement.props.colSpan - columns.length}
        ></td>
        {getCells(columns, cellProps)}
      </>
    );
  }
  return tdElement;
};

const getColumns = (props: BrassTreeListGridPropsV3) => {
  const baseColumns = props.gridColumns!.map((columnProps: GridColumnProps) => {
    if (columnProps.aggregate) {
      return (
        <GridColumn
          {...columnProps}
          footerCell={(_props: BrassGridFooterCellProps) => {
            const { colSpan, style: _style } = _props;
            const recordsList = props.gridState.recordsList as ApiGridResult<T>;

            if (recordsList.records && recordsList.records.length === 0) {
              return <td colSpan={colSpan} style={_style}></td>;
            } else {
              const recordSum = recordsList.records?.map(
                (record: any) => record[columnProps.aggregateField] || 0,
              );
              const _sumValues =
                recordSum &&
                recordSum.length &&
                recordSum.reduce(
                  (previousValue: number, currentValue: number) =>
                    previousValue + currentValue,
                );
              const _formatedSum =
                columnProps.columnType === GridColumnTypeEnum.MONEY
                  ? MoneyService.formatDecimal(_sumValues ?? 0)
                  : DateTimeService.getHoursFromMinutes(_sumValues || 0);

              return (
                <td colSpan={colSpan} style={{ ..._style, textAlign: 'center' }}>
                  <span style={{ fontSize: '18px' }}>{_formatedSum}</span>
                </td>
              );
            }
          }}
        />
      );
    }
    return <GridColumn {...columnProps} />;
  });

  selectColumn(props, baseColumns);
  actionsColumn(props, baseColumns);
  deleteColumn(props, baseColumns);

  // Converte todas as colunas para o Objeto GridColumnProps
  let _columns: GridColumnProps[] = baseColumns.map(
    (col: any) => col as GridColumnProps,
  );

  if (!props.permissions!.update) {
    _columns = _columns.filter((col: GridColumnProps) => col.field !== 'edit');
  }
  if (!props.permissions!.delete) {
    _columns = _columns.filter((col: GridColumnProps) => col.field !== 'delete');
  }

  return _columns;
};

const getExcelExportColumns = (props: BrassTreeListGridPropsV3) =>
  (props.excelExportColumns || (props.gridColumns as ExcelExportColumnProps[]))!.map(
    (columnProps: ExcelExportColumnProps) => <ExcelExportColumn {...columnProps} />,
  );

const getGridToolbar = (
  props: BrassTreeListGridPropsV3,
  gridPDFExport: GridPDFExport,
  gridExcelExport: MutableRefObject<ExcelExport | null>,
): ReactNode => {
  const {
    permissions,
    gridCallbacks,
    toolbarBeforeActions,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarAfterActions,
    customFilterGridToolbar,
    gridState,
    gridDispatch,
    groupable,
    userHelp,
    translate,
  } = props;

  const getTitle = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '13px',
      }}
    >
      {props.icon}
      <h2 className={classNames(style['brass-grid-title'], 'title-grid-custom-v3')}>
        {props?.titleGrid ?? translate('gridTitle')}
      </h2>
    </div>
  );

  const getInsertButton = () =>
    permissions?.create && (
      <BrassButtonAddV1
        fillMode='solid'
        onClick={gridCallbacks.onAdd}
        disabled={gridState.gridLoading}
        themeColor='primary'
        style={{ borderRadius: '5px' }}
      />
    );

  const getRefreshButton = () => (
    <BrassButtonRefreshV1
      showText={false}
      onClick={() => {
        gridDispatch({
          type: GridActions.UPDATE_GRID_LOADING,
          payload: true,
        });
        gridCallbacks.onRefresh();
      }}
      disabled={gridState.gridLoading}
    />
  );

  const getExportButton = () =>
    permissions?.export && (
      <DropDownButton
        icon='printer'
        items={['PDF', 'Excel']}
        className={style['brass-button-export']}
        onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
          itemClickEvent.itemIndex === 0
            ? exportPDF(gridPDFExport)
            : excelExport(gridExcelExport)
        }
        disabled={gridState.gridLoading}
      />
    );

  const getGroupableButton = () =>
    groupable && (
      <Button
        togglable
        showText={false}
        title={translateInternal('group')}
        icon='aggregate-fields'
        className={style['brass-button-groupable']}
        onClick={() => {
          if (!gridState.gridConfig.group) {
            gridDispatch({
              type: GridActions.UPDATE_GRID_CONFIG,
              payload: {
                ...gridState.gridConfig,
                group: [],
              },
            });
          } else {
            gridDispatch({
              type: GridActions.UPDATE_GRID_CONFIG,
              payload: {
                ...gridState.gridConfig,
                group: undefined,
              },
            });
          }
        }}
      />
    );

  const getCustomFilterGridToolbar = () =>
    customFilterGridToolbar && (
      <BrassButtonFilter
        togglable
        fillMode='flat'
        key='buttonFilter'
        onClick={() =>
          gridDispatch({
            type: GridActions.UPDATE_SHOW_FILTER_PANEL,
            payload: !gridState.showFilterPanel,
          })
        }
      />
    );

  const getHelpUser = () =>
    userHelp && (
      <BrassButtonHelpV1
        togglable
        fillMode='outline'
        showText={false}
        className={style.getHelpUser}
        style={{ border: 'none' }}
        onClick={() =>
          gridDispatch({
            type: GridActions.UPDATE_SHOW_HELP_PANEL,
            payload: !gridState.showHelpPanel,
          })
        }
      />
    );

  return (
    <GridToolbar>
      {toolbarBeforeTitle}
      {getTitle()}
      {toolbarAfterTitle}
      <ToolbarSpacer />

      {toolbarBeforeActions}
      {getInsertButton()}
      {getRefreshButton()}
      {getExportButton()}
      {props.groupable?.enabled && getGroupableButton()}
      {getCustomFilterGridToolbar()}
      {toolbarAfterActions}

      {getHelpUser()}
    </GridToolbar>
  );
};

const getHelpPanel = (props: BrassTreeListGridPropsV3): ReactNode =>
  props.gridState.showHelpPanel && (
    <div className={style['brass-grid-user-help']}>{props.userHelp}</div>
  );

const getExcelExport = (
  props: BrassTreeListGridPropsV3,
  preColunsExportExcel: ReactNode[],
  gridExcelExport: MutableRefObject<ExcelExport | null>,
): ReactNode => (
  <ExcelExport
    ref={gridExcelExport}
    data={props.gridState.recordsList.records}
    fileName={`${props.translate('gridTitle')} - ${new Date().toDateString()}`}
  >
    <Grid data={props.gridState.recordsList.records} style={{ display: 'none' }}>
      {preColunsExportExcel}
    </Grid>
  </ExcelExport>
);

const getPDFExport = (
  props: BrassTreeListGridPropsV3,
  columns: GridColumnProps[],
  ref: LegacyRef<GridPDFExport>,
): ReactNode => (
  <GridPDFExport
    ref={ref}
    margin='1cm'
    paperSize='A4'
    fileName={`${props.translate('gridTitle')}${
      props.complementFileNameExport
    } - ${new Date().toDateString()}`}
  >
    <Grid data={props.gridState.recordsList.records}>{columns}</Grid>
  </GridPDFExport>
);

const getGridBrassLoader = (anchorRef: MutableRefObject<any>): ReactNode => {
  let offsetTop = 0;
  let offsetLeft = 0;
  let offsetHeight = 0;
  let offsetWidth = 0;
  if (anchorRef.current && anchorRef.current.element) {
    offsetTop = anchorRef.current.element.offsetTop || 0;
    offsetLeft = anchorRef.current.element.offsetLeft || 0;

    offsetWidth = (anchorRef.current.element.offsetWidth || 0) + 100;
    offsetHeight = anchorRef.current.element.offsetHeight || 0;
  }

  if (offsetTop + offsetLeft + offsetHeight + offsetWidth >= 1) {
    return (
      <BrassLoader
        useLoadingMask
        style={{
          top: offsetTop,
          left: offsetLeft,
          height: offsetHeight,
          width: offsetWidth,
        }}
      />
    );
  } else {
    return <BrassLoader useLoadingMask />;
  }
};

const onDataStateChange = (
  event: GridDataStateChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      group: event.dataState.group,
    },
  });
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_STATE,
    payload: processWithGroupsAggregate(
      props.gridState.recordsList,
      event.dataState,
      props.gridDispatch,
      props.gridColumns,
    ),
  });
};

const onSortChange = (
  event: GridSortChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      sort: event.sort,
    },
  });
};

const onPageChange = (
  event: GridPageChangeEvent | TreeListPageChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_RESULT,
    payload: {
      ...props.gridState.dataResult,
      data: props.gridState.recordsList.records,
    },
  });
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_STATE,
    payload: {
      ...props.gridState.dataState,
      take: event.page.take,
    },
  });
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_LOADING,
    payload: true,
  });

  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      skip: event.page.skip,
      take: event.page.take,
    },
  });
};

const onFilterChange = (
  event: GridFilterChangeEvent | TreeListFilterChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  if (!event.filter) {
    props.gridDispatch({
      type: GridActions.UPDATE_GRID_CONFIG,
      payload: {
        ...props.gridState.gridConfig,
        filter: event.filter,
      },
    });
    props.gridDispatch({
      type: GridActions.UPDATE_TREE_LIST_FILTER,
      payload: event.filter,
    });
  }
  if (props.treeListColumns) {
    props.gridDispatch({
      type: GridActions.UPDATE_TREE_LIST_FILTER,
      payload: event.filter,
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_STATE,
      payload: {
        ...props.gridState.dataState,
        filter: event.filter,
      },
    });
  }
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_FILTER,
    payload: event.filter,
  });
};

/** Largura do Menu quando Expandido */
const MENU_EXPANDED_WIDTH = 240;

/** Largura do Menu quando Minimizado */
const MENU_COLLAPSED_WIDTH = 50;

const getWindowWidth = (
  menuExpanded: boolean,
  reduceWindowWidth: number = 0,
  increaseWindowWidth: number = 0,
) =>
  menuExpanded
    ? `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_EXPANDED_WIDTH +
        increaseWindowWidth
      }px`
    : `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_COLLAPSED_WIDTH +
        increaseWindowWidth
      }px`;

const getUpsertWindow = (props: BrassTreeListGridPropsV3) => {
  const { gridState, gridDispatch, gridCallbacks, upsertWindow, translate } = props;
  const { isFullscreen, width, title, WindowContent } = upsertWindow!;
  const isUpdating = gridState.recordId !== emptyGuid();
  const onSaveClose = (): void => {
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: undefined,
    });
    gridCallbacks.onRefresh();
  };
  const onCancelClose = (): void => {
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: undefined,
    });
  };
  return (
    gridState.recordId && (
      <BrassWindow
        title={
          title
            ? title
            : translate(isUpdating ? 'formEdit' : 'formAdd', [translate('gridItem')])
        }
        onClose={() =>
          gridDispatch({
            type: GridActions.UPDATE_RECORD_ID,
            payload: undefined,
          })
        }
        stage={isFullscreen ? 'FULLSCREEN' : undefined}
        maximizeButton={() => null}
        minimizeButton={() => null}
        initialLeft={width ? window.innerWidth - width : window.innerWidth}
        initialTop={0}
        initialWidth={width}
        initialHeight={window.innerHeight}
      >
        <WindowContent
          recordId={gridState.recordId}
          onSaveClose={onSaveClose}
          onCancelClose={onCancelClose}
        />
      </BrassWindow>
    )
  );
};

const handleDataStateChange = (
  event: TreeListDataStateChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_RECORDS_LIST,
    payload: event.dataState,
  });
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_STATE,
    payload: event.dataState,
  });
};

const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
  const newDataState = groupBy(data, group);
  setGroupIds({ data: newDataState, group });

  return newDataState;
};

const expandRecursive = (recordId: string, rec: any[]): any => {
  const oldRec = rec;
  const newRec = rec.map((item: any) => {
    if (item) {
      if (item.id === recordId) {
        if (item.expanded) {
          item.expanded = false;
        } else {
          item.expanded = true;
        }
        return item;
      } else {
        if (item.children) {
          expandRecursive(recordId, item.children);
        }
      }
    }
  });
  newRec.forEach((el: any, index: number) => {
    if (el === undefined) {
      newRec[index] = oldRec[index]; // Atualiza os registros apenas com o item modificado
    }
  });
  return newRec;
};

const onTreeListExpandChange = (
  event: TreeListExpandChangeEvent,
  props: BrassTreeListGridPropsV3,
) => {
  const recordId = event.dataItem.id;
  const newStateData = expandRecursive(
    recordId,
    props.gridState.recordsList.records!,
  );
  props.gridDispatch({
    type: GridActions.UPDATE_RECORDS_LIST,
    payload: { records: newStateData },
  });
};

const TreeListPager: FunctionComponent<BrassPagerProps> = (
  props: BrassPagerProps,
) => <BrassPager {...props} previousNext={true} />;

export {
  pageData,
  exportPDF,
  excelExport,
  selectColumn,
  editColumn,
  deleteColumn,
  onItemChange,
  onGroupChange,
  onExpandChange,
  onSelectionChange,
  onHeaderSelectionChange,
  getColumns,
  getGridToolbar,
  getHelpPanel,
  getExcelExport,
  getExcelExportColumns,
  getPDFExport,
  getGridBrassLoader,
  onDataStateChange,
  onSortChange,
  onPageChange,
  onFilterChange,
  getWindowWidth,
  getUpsertWindow,
  cellRender,
  handleDataStateChange,
  onTreeListExpandChange,
  TreeListPager,
};
