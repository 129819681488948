import {
  BrassNotificationWindow,
  BrassProgressCircularBar,
  PositionNotification,
} from '../../../index';
import { ProgressPopUpFunctions } from './ProgressPopUp.functions';
import style from './ProgressPopUp.module.scss';
import { translate } from './ProgressPopUp.translations';

const BrassProgressPopUp = () => {
  const { selector, setCloseProgressPopUp, closeProgressPopUp, onClose } =
    ProgressPopUpFunctions();

  return (
    <>
      {selector?.progressPopUp?.stackProgress?.length > 0 && (
        <div
          className={style['download-window']}
          style={PositionNotification.bottomRight}
        >
          <div className={style['download-header']}>
            <div className={style['download-title']}>
              {translate('downloadDocument')}
            </div>
            <button
              className={style['download-close']}
              onClick={() => setCloseProgressPopUp(true)}
            >
              X
            </button>
          </div>
          <div className={style['download-body']}>
            {selector?.progressPopUp?.stackProgress.map((item) => {
              const { name, id, size, progress } = item;
              return (
                <div key={id} className={style['box-item-list']}>
                  <div className={style['content-description']}>
                    <div className={style['download-filename']}>{name}</div>
                    <div className={style['download-size']}>
                      {translate('size')}: {size ?? 'Desconhecido'}
                    </div>
                  </div>
                  <div className={style['download-progress']}>
                    <BrassProgressCircularBar
                      progressValue={progress ?? 0}
                      styleProgressBar={{
                        width: '40px',
                        height: '40px',
                      }}
                      colors={{
                        backgroundColor: '#f5f5f5',
                        lineProgressColor: 'gray',
                        progressColor: 'black',
                        textColor: 'black',
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {closeProgressPopUp && (
        <BrassNotificationWindow
          description={translate('confirmationDelete')}
          windowProps={{
            height: 200,
            modal: false,
            overlayStyle: {
              zIndex: 1000,
            },
          }}
          onConfirm={onClose}
          onCancelClose={() => setCloseProgressPopUp(false)}
        />
      )}
    </>
  );
};

export { BrassProgressPopUp };
