import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { AppBar, AppBarProps } from '@progress/kendo-react-layout';

interface BrassBarProps extends AppBarProps {}

const BrassBar: FunctionComponent<BrassBarProps> = (props: BrassBarProps) => {
  const { className } = props;
  const _className = classnames('brass-bar', className);

  return <AppBar style={{ padding: 0 }} {...props} className={_className} />;
};

export { BrassBar };
export type { BrassBarProps };
