import React, { ComponentType, FunctionComponent } from 'react';
import { Field, FieldProps } from '@progress/kendo-react-form';
import { BrassInputProps } from '../../inputs/Input';

interface BrassFieldProps extends FieldProps {
  component: string | ComponentType<BrassInputProps> | ComponentType<any>;
}

const BrassField: FunctionComponent<BrassFieldProps> = (props: BrassFieldProps) => (
  <Field {...props} />
);

export { BrassField };
export type { BrassFieldProps };
