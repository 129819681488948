import React, { FunctionComponent } from 'react';
import {
  BrassInput,
  BrassForm,
  BrassField,
  BrassFormElement,
  BrassButton,
} from '../../components';

const FormPlayground: FunctionComponent = () => (
  <BrassForm
    onSubmit={() => {}}
    render={() => (
      <BrassFormElement style={{}}>
        <BrassField name={'firstName'} component={BrassInput} label={'First name'} />
        <BrassField name={'lastName'} component={BrassInput} label={'Last name'} />
        <BrassButton>SAVE</BrassButton>
      </BrassFormElement>
    )}
  />
);

export { FormPlayground };
