import React, { FunctionComponent } from 'react';

interface BrassWindowTitleCustomProps {
  title: string;
  className?: string;
}

const BrassWindowTitleCustom: FunctionComponent<BrassWindowTitleCustomProps> = (
  props: BrassWindowTitleCustomProps,
) => (
  <div className={props?.className}>
    <span>{props?.title}</span>
  </div>
);

export { BrassWindowTitleCustom };
export type { BrassWindowTitleCustomProps };
