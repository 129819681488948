import React, {
  createRef,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Editor,
  EditorMountEvent,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from '@progress/kendo-react-editor';
import { translate } from './EditorTextField.translations';
import style from '../FieldCheckerForm.module.scss';
import { Label } from '@progress/kendo-react-labels';
import { BrassEditor, BrassEditorFieldProps } from '../../../../../components/brass';

interface EditorTextFieldProps extends BrassEditorFieldProps {
  onChangeValue: (content: string) => void;
  defaultValue?: string;
  editabled?: boolean;
  label: string;
}

const EditorTextField: FunctionComponent<EditorTextFieldProps> = (
  props: EditorTextFieldProps,
) => {
  const { editabled, label } = props;
  const editor = createRef<Editor>();
  const [value, setValue] = useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      props.defaultValue ?? '',
    ),
  );
  const editableRef = useRef<boolean>(true);
  const view = useRef<any>(null);
  const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;

  const onMount = (event: EditorMountEvent) => {
    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: () => editableRef.current },
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
      }),
    ];
    view.current = new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc: state.doc, plugins }),
      },
    );
    return view.current;
  };

  useEffect(() => {
    editableRef.current = editabled!;
    if (view.current && editabled) {
      view.current.updateState(view.current.state);
    }
  }, [editabled]);

  const onChange = (event: any): void => {
    setValue(event.value);
    props.onChangeValue(event.html);
  };

  const {
    Bold,
    Italic,
    Link,
    Unlink,
    FormatBlock,
    Undo,
    Redo,
    OrderedList,
    UnorderedList,
  } = EditorTools;

  return (
    <div style={{ marginTop: '1em', width: 'calc(100% - 60px)' }}>
      <Label style={{ fontSize: '11px' }}>{label}</Label>
      <BrassEditor
        {...props}
        tools={
          editabled
            ? [
                [Bold, Italic, Link, Unlink],
                [Undo, Redo],
                [OrderedList, UnorderedList],
                FormatBlock,
              ]
            : []
        }
        onChange={onChange}
        className={editabled ? style.editor : style['editor-disabled']}
        style={{
          height: '25vh',
          width: '100%',
          resize: 'none',
          flexDirection: 'column-reverse',
        }}
        ref={editor}
        value={value}
        defaultContent={value.textContent ?? props.defaultValue}
        onMount={onMount}
      />
    </div>
  );
};
export { EditorTextField };
