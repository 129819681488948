import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIcon3d: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M22.375 18.2285V21.0301L18.4688 17.1238V11.9785C18.4687 11.8335 18.4282 11.6913 18.3519 11.5679C18.2756 11.4446 18.1665 11.3449 18.0367 11.2801L13.7812 9.15116V4.81288L15.8016 6.83319L16.9062 5.7285L13 1.82225L9.09375 5.7285L10.1984 6.83319L12.2188 4.81288V9.15194L7.96328 11.2793C7.83341 11.3442 7.72419 11.444 7.64788 11.5675C7.57157 11.691 7.53118 11.8333 7.53125 11.9785V17.1238L3.625 21.0301V18.2285H2.0625V23.6973H7.53125V22.1348H4.72969L8.46641 18.398L12.6508 20.4902C12.7592 20.5443 12.8788 20.5725 13 20.5725C13.1212 20.5725 13.2408 20.5443 13.3492 20.4902L17.5336 18.398L21.2703 22.1348H18.4688V23.6973H23.9375V18.2285H22.375ZM12.2188 18.5269L9.09375 16.9644V13.2426L12.2188 14.8051V18.5269ZM13 13.4488L10.0594 11.9785L13 10.5082L15.9406 11.9785L13 13.4488ZM16.9062 16.9644L13.7812 18.5269V14.8051L16.9062 13.2426V16.9644Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIcon3d };
