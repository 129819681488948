import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';
import React from 'react';

const translations: II18n = {
  es: {
    relationalFlagSelected: 'Indicadores de relaciones vinculadas',
  },
  enUS: {
    relationalFlagSelected: 'Linked relationship flags',
  },
  ptBR: {
    relationalFlagSelected: 'Flags de relacionamento vinculadas',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
