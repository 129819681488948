import { FunctionComponent, useEffect } from 'react';
import { BrassLoader } from '../../../../components';
import {
  CookieService,
  LocalStorageService
} from '../../../../services';

const LoginRedirect: FunctionComponent = () => {
  useEffect(() => {
    CookieService.removeAllCookie();
    LocalStorageService.removeAllLocalStorage();
    setTimeout(() => {
      let redirect = `?redirect=${window.location.origin}`;

      window.location.href = `${window.location.origin}/auth/login/${redirect}`;
    }, 1000);
  }, []);

  return <BrassLoader useLoadingMask />;
};

export { LoginRedirect };

