import React, { FunctionComponent } from 'react';
import { BrassAvatar } from '../../components';

const AvatarPlayground: FunctionComponent = () => (
  <BrassAvatar
    rounded='full'
    type='text'
    style={{
      marginRight: 5,
    }}
  >
    Avatar
  </BrassAvatar>
);

export { AvatarPlayground };
