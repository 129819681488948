import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconOrganization: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.1429 13.7157V15.5329C17.1429 15.69 17.0171 15.8186 16.8571 15.8186H15.04C14.8829 15.8186 14.7543 15.69 14.7543 15.5329V13.7157C14.7543 13.5557 14.8829 13.43 15.04 13.43H15.6629V11.25H10.2857V13.43H10.9086C11.0657 13.43 11.1943 13.5557 11.1943 13.7157V15.5329C11.1943 15.69 11.0657 15.8186 10.9086 15.8186H9.09143C8.9343 15.8186 8.80572 15.69 8.80572 15.5329V13.7157C8.80572 13.5557 8.9343 13.43 9.09143 13.43H9.71429V11.25H4.33715V13.43H4.96C5.11714 13.43 5.24571 13.5557 5.24571 13.7157V15.5329C5.24571 15.69 5.11714 15.8186 4.96 15.8186H3.14286C2.98572 15.8186 2.85715 15.69 2.85715 15.5329V13.7157C2.85715 13.5557 2.98572 13.43 3.14286 13.43H3.76572V10.9643C3.76572 10.8043 3.89429 10.6786 4.05143 10.6786H9.71429V8.84144H7.95428C7.79714 8.84144 7.66857 8.71285 7.66857 8.55573V4.46714C7.66857 4.30998 7.79714 4.18143 7.95428 4.18143H12.0429C12.2 4.18143 12.3286 4.30998 12.3286 4.46714V8.55573C12.3286 8.71285 12.2 8.84144 12.0429 8.84144H10.2857V10.6786H15.9486C16.1057 10.6786 16.2343 10.8043 16.2343 10.9643V13.43H16.8571C17.0171 13.43 17.1429 13.5557 17.1429 13.7157Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconOrganization };
