import React, { useEffect, useState } from 'react';
import { BrassIcon } from '../../../index';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { FunctionComponent, useRef } from 'react';
import style from './CarouselV1.module.scss';
import { useWindowSize } from '../../../../hooks';

interface CarouselV1Props {
  clickJump?: number;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  styleBar?: React.CSSProperties | undefined;
  styleIcon?: React.CSSProperties | undefined;
  styleCarousel?: React.CSSProperties | undefined;
}

const BrassCarouselV1: FunctionComponent<CarouselV1Props> = (props) => {
  const { clickJump, styleBar, iconLeft, iconRight, styleIcon, styleCarousel } =
    props;

  const listRef = useRef<any>({});
  const scrollRef = useRef<any>({});

  const handleClickRight = () => {
    scrollRef.current.scrollBy(
      clickJump ? clickJump : scrollRef.current.clientWidth - 25,
      0,
    );
  };

  const handleClickLeft = () => {
    scrollRef.current.scrollBy(
      clickJump ? -clickJump : -(scrollRef.current.clientWidth - 25),
      0,
    );
  };

  return (
    <>
      <div className={style['carousel']} style={styleCarousel} ref={scrollRef}>
        {listRef?.current?.clientWidth > scrollRef?.current?.clientWidth && (
          <div
            className={classNames(
              style['carousel-button-left'],
              'carousel-button-left',
            )}
            onClick={handleClickLeft}
            role={'button'}
            style={styleBar}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <BrassIcon
              icon={iconLeft ?? faChevronLeft}
              title={'left'}
              style={styleIcon}
            />
          </div>
        )}
        <div className={style.list} ref={listRef}>
          {props?.children}
        </div>
        {listRef?.current?.clientWidth > scrollRef?.current?.clientWidth && (
          <div
            className={style['box-right']}
            role={'button'}
            onClick={handleClickRight}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <div
              className={classNames(style['carousel-button-right'])}
              style={styleBar}
            >
              <BrassIcon
                icon={iconRight ?? faChevronRight}
                title={'right'}
                style={styleIcon}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { BrassCarouselV1 };
