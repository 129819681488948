import React, { FunctionComponent } from 'react';
import { BrassButtonShared, BrassButtonSharedProps } from '.';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const BrassButtonYes: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-yes'
    labelKey='yes'
    translations={translations}
    icon={<BrassIcon icon={faCheckCircle} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    yes: 'Sí',
  },
  enUS: {
    yes: 'Yes',
  },
  ptBR: {
    yes: 'Sim',
  },
};

export { BrassButtonYes };
