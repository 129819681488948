import { Fragment, FunctionComponent, memo, useEffect, useState } from 'react';
import style from './WorkHoursReportStatistics.module.scss';
import { translate } from './WorkHoursReportStatistics.translations';
import {
  BrassIconCalendar,
  BrassIconClock,
  BrassIconLayerGroup,
  BrassIconProps,
  BrassIconTasks,
  BrassLoader,
} from '../../../../components';
import { StatisticsCard, StatisticsChart } from '..';
import { IndicatorsService } from '../../services';
import { ApiResult, AuthUser, DateRange } from '../../../../models';
import { StatisticsCardItem, UserStatistics } from '../../models';

interface WorkHoursReportStatisticsProps {
  periodOfAnalysis: DateRange;
}

const WorkHoursReportStatistics: FunctionComponent<WorkHoursReportStatisticsProps> =
  memo((props: WorkHoursReportStatisticsProps) => {
    const { periodOfAnalysis } = props;
    const [loadingStatistics, setLoadingStatistics] = useState<boolean>(true);
    const [userStatistics, setUserStatistics] = useState<UserStatistics>(
      new UserStatistics({}),
    );

    const statisticsCards: StatisticsCardItem[] = [
      {
        icon: (_props: BrassIconProps) => <BrassIconClock {..._props} />,
        data: userStatistics.totalWorkedHoursText,
        description: translate('totalWorkedHours'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconCalendar {..._props} />,
        data: userStatistics.totalWorkedDays,
        description: translate('totalWorkedDays'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconTasks {..._props} />,
        data: userStatistics.averageWorkedHoursText,
        description: translate('averageWorkedHours'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconLayerGroup {..._props} />,
        data: userStatistics.countWorkedDisciplines,
        description: translate('disciplinesNumber'),
      },
    ];

    useEffect(() => {
      if (periodOfAnalysis.start && periodOfAnalysis.end) {
        setLoadingStatistics(true);
        IndicatorsService.getUserStatistics(AuthUser.getId(), periodOfAnalysis).then(
          (apiResult: ApiResult<UserStatistics>) => {
            if (apiResult.success) {
              apiResult.data.dailyHoursLimitNumber = parseInt(
                apiResult.data.dailyHoursLimit.substring(0, 2),
                10,
              );
              setUserStatistics(apiResult.data);
            }
            setLoadingStatistics(false);
          },
        );
      }
    }, [periodOfAnalysis]);

    if (loadingStatistics) {
      return <BrassLoader useLoadingMask />;
    }

    return (
      <Fragment>
        <div className={style['statistics-container']}>
          {statisticsCards.map((statisticsCard, index) => (
            <div key={index} className={style['statistics-card']}>
              <StatisticsCard
                Icon={statisticsCard.icon}
                data={statisticsCard.data}
                description={statisticsCard.description}
              />
            </div>
          ))}
        </div>
        <StatisticsChart
          chartTitle={translate('chartTitle')}
          // axisTitle={translate('titleCategory')}
          categories={userStatistics.workedDaysPlotData.map((data) => data.day)}
          data={userStatistics.workedDaysPlotData.map((data) => data.workedHours)}
          dailyHoursLimit={8} // userStatistics.dailyHoursLimitNumber
        />
      </Fragment>
    );
  });

export { WorkHoursReportStatistics };
export type { WorkHoursReportStatisticsProps };
