import React, { FunctionComponent, useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { translate } from './UpsertNavigatorFile.translations';
import {
  ApiResult,
  Account,
  BrassButtonCancel,
  BrassButtonSave,
  BrassDialog,
  BrassDialogActionsBar,
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  Notification,
  notificationActions,
  NotificationStyles,
  ApiErrorsEnum,
  BrassFormRenderProps,
  NavigatorFile,
  FileTypeDropdown,
} from '../../../';
import { NameField } from './upset-navigator-file-form/NameField';
import { FileNavigatorService } from '../../../services/fileNavigator.service';
import { DescriptionField } from './upset-navigator-file-form/DescriptionField';
import { OriginalNameField } from './upset-navigator-file-form/OriginalNameField';

interface UpsertNavigatorFileProps {
  onSaveClose?: () => void;
  onCancelClose?: () => void;

  projectId: string;
  navigatorFileId: string;
}
const UpsertNavigatorFile: FunctionComponent<UpsertNavigatorFileProps> = memo(
  (props: UpsertNavigatorFileProps) => {
    const { onSaveClose, onCancelClose, projectId, navigatorFileId } = props;
    const isUpdate = navigatorFileId !== undefined;

    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(isUpdate);

    const [initialFormValues, setInitialFormValues] = useState<NavigatorFile>(
      new NavigatorFile({
        accountId: Account.getId(),
        projectId,
        fileId: navigatorFileId,
      }),
    );

    const onSubmitInclude = (_fieldsValue: NavigatorFile | any): void => {
      setSubmiting(true);
      setLoginError('');

      const fieldsValue = _fieldsValue as NavigatorFile;

      FileNavigatorService.updateNavigatorFile({
        ...initialFormValues,
        ...fieldsValue,
        fileId: navigatorFileId,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnUpdate'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnUpdate'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    const onSubmitUpdate = (fieldsValue: NavigatorFile | any): void => {
      setSubmiting(true);
      setLoginError('');

      FileNavigatorService.updateNavigatorFile({
        ...initialFormValues,
        ...(fieldsValue as NavigatorFile),
        fileId: navigatorFileId,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnUpdate'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnUpdate'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    useEffect(() => {
      if (isUpdate && navigatorFileId) {
        FileNavigatorService.getFileNavigator(navigatorFileId).then(
          (result: ApiResult<NavigatorFile>) => {
            setInitialFormValues(result.data);
            setLoadingData(false);
          },
        );
      }
    }, [isUpdate, navigatorFileId]);

    return (
      <BrassDialog
        onClose={onCancelClose}
        iconTitle={faEdit}
        title={translate(isUpdate ? 'editFile' : 'includeFile')} // , [_titleComplement]
      >
        <BrassForm
          onSubmit={isUpdate ? onSubmitUpdate : onSubmitInclude}
          render={({ allowSubmit, valid, onSubmit }: BrassFormRenderProps) => {
            let disableButtonSave = submiting;
            if (!valid) {
              disableButtonSave = true;
            } else if (!allowSubmit) {
              disableButtonSave = true;
            }

            return (
              <div style={isUpdate ? { padding: 10 } : {}}>
                <BrassFormElement style={{ height: 370, width: 600 }}>
                  <NameField
                    loading={loadingData}
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.name}
                  />

                  <OriginalNameField
                    loading={loadingData}
                    disabled={loadingData || submiting}
                    defaultValue={
                      initialFormValues && initialFormValues.originalName
                    }
                  />

                  <DescriptionField
                    loading={loadingData}
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.description}
                  />

                  <FileTypeDropdown
                    loading={loadingData}
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.fileTypeId}
                  />
                </BrassFormElement>

                <BrassDialogActionsBar>
                  <BrassDivFlex />
                  {loginError && <BrassError>{loginError}</BrassError>}
                  <BrassButtonSave
                    autoFocus
                    onClick={onSubmit}
                    disabled={disableButtonSave}
                    showLoader={submiting}
                    hideIconWhenLoader
                  />
                  <BrassButtonCancel onClick={onCancelClose} />
                </BrassDialogActionsBar>
              </div>
            );
          }}
        />
      </BrassDialog>
    );
  },
);

export { UpsertNavigatorFile };
export type { UpsertNavigatorFileProps };
