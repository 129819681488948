import React from 'react';
import { I18nService, II18n } from '../../../index';

const translations: II18n = {
  es: {
    noData: 'No se encontraron registros',
  },
  enUS: {
    noData: 'No record found',
  },
  ptBR: {
    noData: 'Nenhum registro encontrado',
  },
};

const translateDefault = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translateDefault };
