enum GridActions {
  UPDATE_DATE_ITEM_KEY = 'UPDATE_DATE_ITEM_KEY',
  UPDATE_DATA_STATE = 'UPDATE_DATA_STATE',
  UPDATE_DATA_RESULT = 'UPDATE_DATA_RESULT',
  UPDATE_EDIT_FIELD = 'UPDATE_EDIT_FIELD',
  UPDATE_GRID_CONFIG = 'UPDATE_GRID_CONFIG',
  UPDATE_GRID_FILTER = 'UPDATE_GRID_FILTER',
  UPDATE_TREE_LIST_FILTER = 'UPDATE_TREE_LIST_FILTER',
  UPDATE_GRID_LOADING = 'UPDATE_GRID_LOADING',
  UPDATE_GROUP = 'UPDATE_GROUP',
  UPDATE_COLLAPSED_STATE = 'UPDATE_COLLAPSED_STATE',
  UPDATE_RECORD_ID = 'UPDATE_RECORD_ID',
  UPDATE_RECORDS_LIST = 'UPDATE_RECORDS_LIST',
  UPDATE_SELECTED_RECORDS = 'UPDATE_SELECTED_RECORDS',
  UPDATE_SHOW_HELP_PANEL = 'UPDATE_SHOW_HELP_PANEL',
  UPDATE_SHOW_FILTER_PANEL = 'UPDATE_SHOW_FILTER_PANEL',
}

export { GridActions };
