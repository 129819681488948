import React, { Fragment, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import style from './Window.module.scss';
import classnames from 'classnames';
import { Window, WindowProps } from '@progress/kendo-react-dialogs';
import { BrassBackground } from '../layout';

interface BrassWindowProps extends WindowProps {
  closeOnBackgroundClick?: boolean;
  showDarkBackground?: boolean;
}

const BrassWindow: FunctionComponent<BrassWindowProps> = (
  props: BrassWindowProps,
) => {
  const { className } = props;
  const _className = classnames('brass-window', style['brass-window'], className);

  return (
    <Fragment>
      {props.showDarkBackground && (
        <BrassBackground
          onClickCallBack={props.closeOnBackgroundClick ? props.onClose : undefined}
        />
      )}
      {createPortal(
        <Window {...props} className={_className} />,
        document.getElementById('overlay-root') as HTMLElement,
      )}
    </Fragment>
  );
};

BrassWindow.defaultProps = {
  resizable: true,
  closeOnBackgroundClick: true,
  showDarkBackground: true,
};

export { BrassWindow };
export type { BrassWindowProps };
