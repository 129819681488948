import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import style from './TextAreaField.module.scss';
import classnames from 'classnames';
import { translate } from './TextAreaField.translations';
import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { BrassField } from '../Field';
import { BrassError } from '../../../labels/Error';
import { requiredValidator } from '../validators/required';
import { I18nService } from '../../../../../services';

interface TextFieldInputProps {
  visited?: boolean;
  valid?: boolean;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  validationMessage?: string;
  maxLength?: number;
  onChange?: (event: TextAreaChangeEvent) => void;
  isValidChange?: (valid: boolean) => void;
}

const TextFieldInput: FunctionComponent<TextFieldInputProps> = (
  props: TextFieldInputProps,
) => {
  const {
    validationMessage,
    maxLength,
    value,
    disabled,
    visited,
    onChange,
    isValidChange,
  } = props;
  const _props = { ...props };
  delete _props.isValidChange;

  const [internalValue, setInternalValue] = useState<string>(value || '');
  const onInternalChange = (e: TextAreaChangeEvent): void => {
    setInternalValue(e.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (isValidChange) {
      isValidChange(validationMessage === '');
    }
  }, [isValidChange, validationMessage]);

  return (
    <div className={'k-form-field-wrap'}>
      <TextArea
        disabled={disabled}
        className={style['text-area']}
        {..._props}
        onChange={onInternalChange}
      />
      {visited && validationMessage && (
        <BrassError className={style['div-messages-error']}>
          {validationMessage}
        </BrassError>
      )}
      {maxLength && !validationMessage && (
        <div className={style['div-messages-info']}>
          {internalValue.length || 0} / {maxLength}
        </div>
      )}
    </div>
  );
};

interface TextAreaFieldProps
  extends Omit<TextFieldInputProps, 'valid'>,
    PropsWithChildren<any> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}

const TextAreaField: FunctionComponent<TextAreaFieldProps> = (
  props: TextAreaFieldProps,
) => {
  const { name, label, required, loading, className } = props;
  const _className = classnames(style['brass-textarea-field'], className);
  const [isValid, setIsValid] = useState<boolean>(true);

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  const _props = { ...props };
  _props.isValidChange = (valid: boolean) => setIsValid(valid);

  if (loading) {
    return (
      <FieldWrapper className={_className}>
        <Label editorValid={isValid}>{label}</Label>
        <BrassField
          {..._props}
          disabled
          name={name!}
          component={TextArea}
          defaultValue={translate('loadingData')}
        />
      </FieldWrapper>
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }} editorValid={isValid}>
        {required ? `${label!}*` : label!}
      </Label>
      <BrassField
        {..._props}
        name={name!}
        validator={validators}
        component={TextFieldInput}
      />
    </FieldWrapper>
  );
};

export { TextAreaField };
export type { TextAreaFieldProps };
