import React, {
  memo,
  Fragment,
  useState,
  useContext,
  FunctionComponent,
} from 'react';
import style from './BottomPanel.module.scss';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import {
  BrassTab,
  BrassLoader,
  BrassListView,
  BrassTabContainer,
} from '../../../../../components';
import { NavigatorFileChild, NavigatorFileChildItem } from '../../../../../models';
import { ViewerContextV1 } from '../../../../../store';
import { orderBy } from '../../../../../util';
import {
  BottomPanelBar,
  BottomPanelBarProps,
} from './bottom-panel-bar/BottomPanelBar';
import { NavigatorCardByGroup } from './navigator-card-by-group/NavigatorCardByGroup';

interface BottomPanelProps {
  onPromoteToInitialFileView?: (record: NavigatorFileChildItem) => void;
  onEditRecord?: (record: NavigatorFileChildItem) => void;
  onDeleteRecord?: (record: NavigatorFileChildItem) => void;
  bottomBarPanelProps: BottomPanelBarProps;
}

const BottomPanel: FunctionComponent<BottomPanelProps> = memo(
  (props: BottomPanelProps) => {
    const {
      showBottomPanel,
      navigatorFile,
      loadingViewer,
      loadingBottomPanelData,
      bottomPanelBarFilterValue,
      isLoadingTab,
    } = useContext(ViewerContextV1);
    const {
      onDeleteRecord,
      onEditRecord,
      onPromoteToInitialFileView,
      bottomBarPanelProps,
    } = props;
    const [selected, setSelected] = useState<number>(0);

    if (loadingViewer) {
      return <Fragment />;
    }

    // NOTA: quando for arquivo não haverá filhos assim não exibe o TABContainer
    const showTabContainer = !navigatorFile.document.isFile;
    navigatorFile.children = orderBy(navigatorFile.children, 'title');

    return (
      <Fragment>
        <BottomPanelBar {...bottomBarPanelProps} />
        {showTabContainer && showBottomPanel && (
          <Fragment>
            {loadingBottomPanelData && <BrassLoader useLoadingMask />}
            {!loadingBottomPanelData && (
              <BrassTabContainer
                selected={selected}
                onSelect={(e: TabStripSelectEventArguments): void =>
                  setSelected(e.selected)
                }
                className={style['brass-tab-container']}
              >
                {navigatorFile.children.map(
                  (navigatorFileChild: NavigatorFileChild, index: number) => {
                    // Ordenação da TAB com base no TAG ou Name
                    navigatorFileChild.items = navigatorFileChild.items.map(
                      (item) => {
                        const orderByValue = item.tag ? item.tag : item.name;
                        return {
                          ...item,
                          orderByField: orderByValue,
                        };
                      },
                    );

                    navigatorFileChild.items = orderBy(
                      navigatorFileChild.items,
                      'orderByField',
                    );

                    return (
                      <BrassTab key={index} title={navigatorFileChild.title}>
                        {isLoadingTab && <BrassLoader useLoadingMask />}
                        <BrassListView
                          data={navigatorFileChild.items.filter(
                            (item: NavigatorFileChildItem) =>
                              (item.orderByField || '')
                                .toLowerCase()
                                .includes(bottomPanelBarFilterValue.toLowerCase()),
                          )}
                          item={(_props: any) => (
                            <NavigatorCardByGroup
                              dataItem={_props.dataItem}
                              onPromoteToInitialFileView={onPromoteToInitialFileView}
                              onEditRecord={onEditRecord}
                              onDeleteRecord={onDeleteRecord}
                            />
                          )}
                          className={style['list-view']}
                        />
                      </BrassTab>
                    );
                  },
                )}
              </BrassTabContainer>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  },
);

export { BottomPanel };
export type { BottomPanelProps };
