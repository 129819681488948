import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    communications: 'Comunicaciones',
    messagesInTotal: 'Mensajes en total',
    alert: 'Alerta',
    alerts: 'Alertas',
    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'REGISTRO DE ACTIVIDAD SEMANAL DEL USUARIO EN PENDENCIA',
    RECOVERY_PASSWORD: 'CONTRASEÑA DE RECUPERACIÓN',
    RHT_SUBMISSION_REQUEST: 'SOLICITUD DE PRESENTACIÓN DE IHT',
    RHT_APPROVAL_REQUEST: 'SOLICITUD DE APROBACIÓN IHT',
    RHT_INVOICE_REQUEST: 'AUTORIZACIÓN DE EMISIÓN DE FACTURA',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDENCIA DE ENVÍO DE FACTURA DE USUARIO',
    PAYCHECK_NOTIFICATION: 'Cheque de pago disponible',
  },
  enUS: {
    communications: 'Communications',
    messagesInTotal: 'Messages in total',
    alert: 'Alert',
    alerts: 'Alerts',
    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'REGISTRO DE ACTIVIDAD SEMANAL DEL USUARIO EN PENDENCIA',
    RECOVERY_PASSWORD: 'CONTRASEÑA DE RECUPERACIÓN',
    RHT_SUBMISSION_REQUEST: 'SOLICITUD DE PRESENTACIÓN DE RHT',
    RHT_APPROVAL_REQUEST: 'SOLICITUD DE APROBACIÓN RHT',
    RHT_INVOICE_REQUEST: 'INVOICE ISSUANCE AUTHORIZATION',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDENCIA DE ENVÍO DE FACTURA DE USUARIO',
    PAYCHECK_NOTIFICATION: 'Paycheck Available',
  },
  ptBR: {
    communications: 'Comunicações',
    messagesInTotal: 'Mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',
    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'PENDÊNCIA DE REGISTRO DE ATIVIDADE SEMANAL DO USUÁRIO',
    RECOVERY_PASSWORD: 'SENHA DE RECUPERAÇÃO',
    RHT_SUBMISSION_REQUEST: 'SOLICITAÇÃO DE SUBMISSÃO DE RHT',
    RHT_APPROVAL_REQUEST: 'PEDIDO DE APROVAÇÃO RHT',
    RHT_INVOICE_REQUEST: 'AUTORIZAÇÃO DE EMISSÃO DE NF',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDÊNCIA DE ENVIO DE FATURA DE USUÁRIO',
    PAYCHECK_NOTIFICATION: 'Contracheque Disponível',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
