import React, {
  Dispatch,
  Fragment,
  FunctionComponent,
  memo,
  SetStateAction,
  useState,
} from 'react';
import classnames from 'classnames';
import style from './NavigatorCard.module.scss';
import { translate } from './NavigatorCard.translations';
import {
  BrassCard,
  BrassCardSubtitle,
  BrassCardTitle,
  BrassLoader,
  BrassButtonConfigure,
  BrassContextMenu,
  BrassIconInformation,
} from '../../..';
import { NavigatorPermissions, AuthUser } from '../../../../models';
import { MenuSelectEvent } from '@progress/kendo-react-layout';

interface NavigatorCardProps {
  tag: string;
  name: string;
  thumbnail: string;
  onClick: (setCardLoading: Dispatch<SetStateAction<boolean>>) => void;
  conversionFinished?: boolean;
  cardDescription?: string;

  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickPromoteToInitialFileView?: () => void;
}

interface ItemsContextMenuCardDataProps {
  eventClick?: () => void;
}
interface ItemsContextMenuCardProps {
  text: string;
  data: ItemsContextMenuCardDataProps;
}

const NavigatorCard: FunctionComponent<NavigatorCardProps> = memo(
  (props: NavigatorCardProps) => {
    const {
      onClick,
      tag,
      name,
      thumbnail,
      conversionFinished,
      cardDescription,
      onClickEdit,
      onClickDelete,
      onClickPromoteToInitialFileView,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [showContextMenuCard, setShowContextMenuCard] = useState<
      HTMLButtonElement | undefined
    >(undefined);

    const allowEditRecord = AuthUser.checkPermission(
      NavigatorPermissions.NAVIGATOR_FILE_UPDATE,
    );
    const allowDeleteRecord = AuthUser.checkPermission(
      NavigatorPermissions.NAVIGATOR_FILE_DELETE,
    );

    // ----------------------------------------------------------------------------
    const itemsContextMenuCard: ItemsContextMenuCardProps[] = [];
    if (onClickEdit && allowEditRecord) {
      itemsContextMenuCard.push({
        text: translate('edit'),
        data: { eventClick: onClickEdit },
      });
    }
    if (onClickDelete && allowDeleteRecord) {
      if (allowDeleteRecord) {
        itemsContextMenuCard.push({
          text: translate('delete'),
          data: { eventClick: onClickDelete },
        });
      }
    }
    if (onClickPromoteToInitialFileView && allowEditRecord) {
      itemsContextMenuCard.push({
        text: translate('promoteInitialView'),
        data: { eventClick: onClickPromoteToInitialFileView },
      });
    }

    const defaultThumbnail = '/images/brass-logo.png';
    const onCardClick = (): void => {
      if (!loading) {
        setLoading(true);
        setTimeout(() => onClick(setLoading), 100);
      }
    };
    const onClickButtonConfigure = (event: any): void => {
      if (showContextMenuCard) {
        setShowContextMenuCard(undefined);
      } else {
        setShowContextMenuCard(event.currentTarget as HTMLButtonElement);
      }
    };
    const onClickItemMenuConfigure = (event: MenuSelectEvent): void => {
      const itemData = event.item.data as ItemsContextMenuCardDataProps;
      if (itemData && itemData.eventClick) {
        itemData.eventClick();
      }
    };

    // ----------------------------------------------------------------------------
    const className = classnames(
      style['navigator-card'],
      loading ? style['navigator-card-loading'] : '',
    );
    return (
      <BrassCard
        className={className}
        tooltip={conversionFinished ? translate('clickToOpen') : ''}
      >
        {loading && <BrassLoader useLoadingMask />}

        <div onClick={onCardClick} onKeyDown={onCardClick} role='none'>
          {!conversionFinished && (
            <div className={style['navigator-card-converting']}>
              <BrassLoader />
              {translate('converting')}
              <BrassLoader />
            </div>
          )}
          <div
            title={name}
            style={{ backgroundImage: `url(${thumbnail || defaultThumbnail})` }}
            className={classnames(
              style['navigator-card-image'],
              !conversionFinished && style['navigator-card-image-with-convert'],
            )}
          >
            {cardDescription && (
              <BrassIconInformation
                title={cardDescription}
                className={style.iconInformation}
              />
            )}
          </div>
          <div>
            <BrassCardTitle className={style['navigator-card-title']}>
              {tag || name}
            </BrassCardTitle>
            {tag && (
              <BrassCardSubtitle className={style['navigator-card-tag']}>
                {name}
              </BrassCardSubtitle>
            )}
          </div>
        </div>

        {(allowEditRecord || allowDeleteRecord) && itemsContextMenuCard.length >= 1 && (
          <Fragment>
            <BrassButtonConfigure
              roundBorder
              fillMode={'flat'}
              showText={false}
              onClick={onClickButtonConfigure}
              className={style['navigator-card-configure']}
            />
            {showContextMenuCard && (
              <BrassContextMenu
                anchor={showContextMenuCard}
                onSelect={onClickItemMenuConfigure}
                menuItems={itemsContextMenuCard}
              />
            )}
          </Fragment>
        )}
      </BrassCard>
    );
  },
);

NavigatorCard.defaultProps = {
  conversionFinished: false,
};

export { NavigatorCard };
