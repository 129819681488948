import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonConfigure: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-configure'
    labelKey='configure'
    tooltipKey='clickToConfigure'
    translations={translations}
    icon={<BrassIcon icon={faCog} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    configure: 'Configurar',
    clickToConfigure: 'haga clic para configurar',
  },
  enUS: {
    configure: 'Configure',
    clickToConfigure: 'Click to configure',
  },
  ptBR: {
    configure: 'Configurar',
    clickToConfigure: 'Clique para configurar',
  },
};

export { BrassButtonConfigure };
