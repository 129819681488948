import React, {
  Fragment,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import style from './AcademyStatistics.module.scss';
import { translate } from './AcademyStatistics.translations';
import {
  BrassIconCalendar,
  BrassIconCalendarDay,
  BrassIconChart,
  BrassIconClock,
  BrassIconDiscipline,
  BrassIconProps,
  BrassLoader,
} from '../../../../components';
import { StatisticsCard, StatisticsChart } from '..';
import { StatisticsCardItem, AcademyStatisticsModel } from '../../models';
import { IndicatorsService } from '../../services';
import { I18nService } from '../../../../services';
import { ApiResult, AuthUser, DateRange } from '../../../../models';

interface AcademyStatisticsProps {
  disableChart?: boolean;
  periodOfAnalysis: DateRange;
}

const AcademyStatistics: FunctionComponent<AcademyStatisticsProps> = memo(
  (props: AcademyStatisticsProps) => {
    const { disableChart, periodOfAnalysis } = props;
    const [loadingStatistics, setLoadingStatistics] = useState<boolean>(true);
    const [academyStatistics, setAcademyStatistics] =
      useState<AcademyStatisticsModel>(new AcademyStatisticsModel({}));

    const statisticsCards: StatisticsCardItem[] = [
      {
        icon: (_props: BrassIconProps) => <BrassIconClock {..._props} />,
        data: academyStatistics.totalClassHoursText,
        description: translate('totalClassHours'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconCalendar {..._props} />,
        data: academyStatistics.completedCourses,
        description: translate('completedCourses'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconCalendarDay {..._props} />,
        data: academyStatistics.coursesTaken,
        description: translate('coursesTaken'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconChart {..._props} />,
        data: academyStatistics.performance,
        description: translate('performance'),
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconDiscipline {..._props} />,
        data: academyStatistics.disciplinePerformance,
        description: translate('disciplinePerformance'),
      },
    ];

    useEffect(() => {
      setLoadingStatistics(true);
      IndicatorsService.getAcademyStatistics(
        AuthUser.getId(),
        periodOfAnalysis,
      ).then((apiResult: ApiResult<AcademyStatisticsModel>) => {
        if (apiResult.success) {
          apiResult.data.dailyHoursLimitNumber = parseInt(
            apiResult.data.dailyHoursLimit.substring(0, 2),
            10,
          );
          setAcademyStatistics(apiResult.data);
        }
        setLoadingStatistics(false);
      });
    }, [periodOfAnalysis]);

    if (loadingStatistics) {
      return <BrassLoader useLoadingMask />;
    }

    return (
      <Fragment>
        <div className={style['statistics-container']}>
          {statisticsCards.map((statisticsCard, index) => (
            <div key={index} className={style['statistics-card']}>
              <StatisticsCard
                Icon={statisticsCard.icon}
                data={statisticsCard.data}
                description={statisticsCard.description}
              />
            </div>
          ))}
        </div>
        {!props.disableChart && (
          <StatisticsChart
            chartTitle={translate('chartTitle')}
            axisTitle={translate('titleCategory')}
            categories={academyStatistics.workedDaysPlotData.map((data) =>
              data.day.toString(),
            )}
            data={academyStatistics.workedDaysPlotData.map(
              (data) => data.workedHours,
            )}
            dailyHoursLimit={academyStatistics.dailyHoursLimitNumber}
          />
        )}
      </Fragment>
    );
  },
);

AcademyStatistics.defaultProps = {
  disableChart: false,
};

export { AcademyStatistics };
export type { AcademyStatisticsProps };
