import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  initialProgressPopUpState as initialState,
  ProgressPopUpState,
} from './state';
import { ProgressPopUp } from '../../models';
import { RootReducerProps } from '../AppStore';

const ProgressPropUpSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    showProgressPopUp: (
      state: ProgressPopUpState,
      action: PayloadAction<ProgressPopUp>,
    ) => {
      const notificationAlreadyExists = state.stackProgress.find(
        (notification) => notification.id === action.payload.id,
      );

      if (!notificationAlreadyExists) {
        state.stackProgress.push(action.payload);
      }
    },
    updateProgressPopUp: (
      state: ProgressPopUpState,
      action: PayloadAction<ProgressPopUp>,
    ) => {
      const updatedList = state.stackProgress.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload;
        }
        return item;
      });
      state.stackProgress = updatedList;
    },
    closeProgressPopUp: (state: ProgressPopUpState) => {
      state.stackProgress = [];
    },
  },
});

const progressPopUpReducer = ProgressPropUpSlice.reducer;
const progressPopUpActions = ProgressPropUpSlice.actions;
const useProgressPopUp = (state: RootReducerProps) => state;

export {
  ProgressPropUpSlice,
  progressPopUpReducer,
  progressPopUpActions,
  useProgressPopUp,
};
