import React, { ReactNode } from 'react';

interface BrassTabContainerProps {
  children: ReactNode | any;
  selected: number;
  onSelect: (index: any) => void;
  style?: React.CSSProperties;
  className?: string;
  contentClassName?: string;
  tabPosition?: 'left' | 'right' | 'top' | 'bottom';
  disabled?: boolean;
}


const BrassTabContainer = ({ children, selected, onSelect, style, className }: BrassTabContainerProps) => {
  return (
 <div style={{ ...style, display: 'flex', flexDirection: 'column', height: '100%' }} className={className}>
      <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
        {React.Children.map(children, (child, index) => (
          <button
            key={index} // Adicione uma chave aqui
            onClick={() => !child.props.disabled && onSelect({ selected: index })} // Mude para passar o objeto com a chave `selected`
            disabled={child.props.disabled}
            style={{
              padding: '10px 20px',
              cursor: child.props.disabled ? 'not-allowed' : 'pointer',
              backgroundColor: selected === index ? '#eee' : '#fff',
              border: 'none',
              borderBottom: selected === index ? '2px solid #282d37' : 'none',
              color: child.props.disabled ? '#0000008a' : '#000000DE',
              outline: 'none',
            }}
          >
            {child.props.title.toUpperCase()}
          </button>
        ))}
      </div>
      <div style={{ flex: 1, padding: '20px' }}>
        {children[selected]}
      </div>
    </div>
  );
};

export { BrassTabContainer };
export type { BrassTabContainerProps };

