enum GeneralCardBorderColorsEnum {
  GREEN = '#70AD47',
  YELLOW = '#FACC2C',
  ORANGE = '#FDA451',
  RED = '#DA0B0B',
  MIDGRAY = '#3B4351',
  LIGHTGRAY = '#595959',
  DARKGRAY = '#2D2B2B',
  BLUE = '#0070C0',
  PURPLE = '#7030A0',
}

export { GeneralCardBorderColorsEnum };
