import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconTraining: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M19 0H1C0.4 0 0 0.4 0 1V15C0 15.6 0.4 16 1 16H9V17.3L6.7 18C6.2 18.2 5.9 18.7 6.1 19.3C6.3 19.8 6.8 20.1 7.4 19.9L10.1 19L12.8 19.9C13.3 20.1 13.9 19.8 14.1 19.3C14.3 18.8 14 18.2 13.5 18L11 17.3V16H19C19.6 16 20 15.6 20 15V1C20 0.4 19.6 0 19 0ZM18 14H2V2H18V14Z'
      fill='white'
    />
    <path
      d='M5.6 12.8C6.9 11.8 8 11.8 9.3 12.8C9.6 13.1 10.2 13.1 10.6 12.8C11.9 11.8 13 11.8 14.3 12.8C15 13.3 16 12.8 16 12V5C16 4.7 15.9 4.4 15.6 4.2C13.8 2.7 11.8 2.6 10 3.8C8.1 2.6 6.2 2.8 4.4 4.2C4.1 4.4 4 4.7 4 5V12C4 12.8 5 13.3 5.6 12.8ZM14 5.5V10.3C13.5 10.1 13 10 12.5 10C12 10 11.5 10.1 11 10.3V5.5C12 4.8 13 4.8 14 5.5ZM6 5.5C7 4.8 8 4.8 9 5.5V10.3C8.5 10.1 8 10 7.5 10C7 10 6.5 10.1 6 10.3V5.5Z'
      fill='white'
    />
  </svg>
);

export { SVGIconTraining };
