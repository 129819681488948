import { State } from '@progress/kendo-data-query';
import { GridSortSettings } from '@progress/kendo-react-grid';
import classnames from 'classnames';
import {
  Dispatch,
  FunctionComponent,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { BrassGridConfigDefault } from '../../util';
import { renderTreeList } from '../TreeListGrid.treeList';
import {
  BrassTreeListGridProps,
  BrassTreeListGridPropsV1,
} from '../TreeListGridProps.interface';
import { renderGridV1 } from './TreeListGrid.grid.v1';
import style from './TreeListGrid.v1.module.scss';

const BrassTreeListGridV1: FunctionComponent<BrassTreeListGridPropsV1> = memo(
  (props: BrassTreeListGridPropsV1) => {
    const { className, pageable } = props;
    const _className = classnames('brass-grid', style['brass-grid'], className);
    const [dataState, setSataState] = useState<State>(BrassGridConfigDefault);
    const [selectedRecords, setSelectedRecords] = useState<string[]>([]);

    const renderGrid = useCallback(
      (
        _props: BrassTreeListGridPropsV1,
        _selectedRecords: string[],
        _setSelectedRecords: Dispatch<SetStateAction<string[]>>,
        _className: string,
        _dataState: State,
        _setSataState: Dispatch<SetStateAction<State>>,
        _hideActionButton?: boolean,
      ) =>
        renderGridV1(
          _hideActionButton,
          _props,
          _selectedRecords,
          _setSelectedRecords,
          _className,
          _dataState,
          _setSataState,
        ),
      [props, selectedRecords, dataState],
    );

    const renderGridEvoke = (hideActionButton?: boolean) => {
      if (props.isTreeGrid) {
        return renderTreeList(
          hideActionButton,
          props as unknown as BrassTreeListGridProps,
          _className,
        );
      } else {
        return renderGrid(
          props,
          selectedRecords,
          setSelectedRecords,
          _className,
          dataState,
          setSataState,
          hideActionButton,
        );
      }
    };

    return (
      <div style={{ display: 'block' }}>
        {renderGridEvoke(false)}
        {pageable}
      </div>
    );
  },
);

BrassTreeListGridV1.defaultProps = {
  pageable: true,
  groupable: {
    enabled: true,
    footer: 'none',
  },
  reorderable: true,
  filterable: true,
  isTreeGrid: false,
  visibleButtonRefresh: true,
  showGridFooterOnlyInGroupMode: true,

  sortable: {
    mode: 'multiple',
  } as GridSortSettings,

  selectable: {
    drag: false,
    cell: false,
    enabled: false,
    mode: 'multiple',
  } as any,

  columnsGrid: [],
};

export { BrassTreeListGridV1 };

