import React, { FunctionComponent } from 'react';
import style from './DatePickerButton.module.scss';
import { ActiveView } from '@progress/kendo-react-dateinputs';
import {
  BrassButton,
  BrassCalendar,
  BrassCalendarProps,
  BrassDatePicker,
  BrassDatePickerProps,
} from '../../../brass';
import { DateTimeService } from '../../../../services';

interface BrassDatePickerButtonProps extends BrassDatePickerProps {
  buttonText: string;
  bottomView?: ActiveView;
  topView?: ActiveView;
  dateFormat?: string;
}

const BrassDatePickerButton: FunctionComponent<BrassDatePickerButtonProps> = (
  props: BrassDatePickerButtonProps,
) => {
  const { bottomView, topView, buttonText, dateFormat, value } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <BrassDatePicker
        className={style['date-picker']}
        calendar={(_props: BrassCalendarProps) => (
          <BrassCalendar {..._props} bottomView={bottomView} topView={topView} />
        )}
        toggleButton={(_props) => (
          <BrassButton {..._props} themeColor='primary' className='k-button k-link'>
            {_props.children}
            {buttonText}:{' '}
            {value &&
              DateTimeService.getFormattedDate(value.toISOString(), dateFormat)}
          </BrassButton>
        )}
        pickerWrap={(_props: any) => _props.children}
        {...props}
      />
    </div>
  );
};

BrassDatePickerButton.defaultProps = {
  bottomView: 'year',
  topView: 'year',
  dateFormat: 'MM/yyyy',
};

export type { BrassDatePickerButtonProps };
export { BrassDatePickerButton };
