import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { ConnectModulePermissions } from './Connect.submodule.enum';

enum ConnectHelpCenterFeaturesPermissions {
  TICKET = 0x00000100,
  INSTRUCTIVES = 0x00000200,
}

enum ConnectHelpCenterPermissions {
  HELP_CENTER_MODULE = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE,

  /// <summary>
  /// Help Center - Ticket
  /// </summary>
  HELP_CENTER_TICKET_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE +
    ConnectHelpCenterFeaturesPermissions.TICKET +
    Action.READ,
  HELP_CENTER_TICKET_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE +
    ConnectHelpCenterFeaturesPermissions.TICKET +
    Action.UPDATE,
  HELP_CENTER_TICKET_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE +
    ConnectHelpCenterFeaturesPermissions.TICKET +
    Action.KEY_USER,

  /// <summary>
  /// Help Center - Ticket
  /// </summary>
  HELP_CENTER_INSTRUCTIVES_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE +
    ConnectHelpCenterFeaturesPermissions.INSTRUCTIVES +
    Action.READ,
  HELP_CENTER_INSTRUCTIVES_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.HELP_CENTER_MODULE +
    ConnectHelpCenterFeaturesPermissions.INSTRUCTIVES +
    Action.KEY_USER,
}

export { ConnectHelpCenterPermissions };
