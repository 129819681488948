import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import style from './MaskedTextBox.module.scss';
import { MaskedTextBox, MaskedTextBoxProps } from '@progress/kendo-react-inputs';

interface BrassMaskedTextBoxProps extends MaskedTextBoxProps {}

const BrassMaskedTextBox: FunctionComponent<BrassMaskedTextBoxProps> = (
  props: BrassMaskedTextBoxProps,
) => {
  const { className } = props;
  const _className = classnames(
    'brass-masked-textbox',
    style['brass-masked-textbox'],
    className,
  );
  return <MaskedTextBox {...props} className={_className} />;
};

export { BrassMaskedTextBox };
export type { BrassMaskedTextBoxProps };
