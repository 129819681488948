import { BrassGridConfigProps } from '../../../components';
import { ApiGridResult, ApiResult, DropDownData, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { UserGroupsDataApi, UserListData, UserPersonalData } from '../models';
import { UserStatusEnum } from '../models/enum/UserStatus.enum';

class UserService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);
  public static cacheDisciplinesDropDownData: DropDownData[] = [];
  public static cachePositionsDropDownData: DropDownData[] = [];
  public static cacheUserLeadersDropDownData: DropDownData[] = [];
  public static cacheUsersDropDownData: DropDownData[] = [];

  // Drop Downs
  public static async getDisciplinesDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await UserService.api.get<
      DropDownData[]
    >(`/connect/v1/system/dropdown`);

    if (!apiResult.errorMessage && apiResult.data) {
      UserService.cacheDisciplinesDropDownData = apiResult.data;
    }
    return apiResult;
  }

  public static async getPositionsDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await UserService.api.get<
      DropDownData[]
    >(`/connect/v1/account-position/dropdown`);

    if (!apiResult.errorMessage && apiResult.data) {
      UserService.cachePositionsDropDownData = apiResult.data;
    }
    return apiResult;
  }

  public static async getUserLeadersDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await UserService.api.get<
      DropDownData<string>[]
    >(`/connect/v1/user/dropdown/DISCIPLINE_LEADER`);

    if (!apiResult.errorMessage && apiResult.data) {
      UserService.cacheUserLeadersDropDownData = apiResult.data;
    }
    return apiResult;
  }

  public static async getUsersDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await UserService.api.get<
      DropDownData<string>[]
    >(`/connect/v1/user/dropdown/ALL`);

    if (!apiResult.errorMessage && apiResult.data) {
      UserService.cacheUsersDropDownData = apiResult.data;
    }
    return apiResult;
  }

  // CRUD User

  public static async getUsersList(
    gridConfig: BrassGridConfigProps,
    getRecordsParams?: {
      userStatusFilter: UserStatusEnum;
    },
  ): Promise<ApiResult<ApiGridResult<UserListData>>> {
    const statusFilter = getRecordsParams?.userStatusFilter || UserStatusEnum.ALL;
    return await UserService.api.post<ApiGridResult<UserListData>>(
      `/connect/v1/user/list/${statusFilter}`,
      gridConfig,
    );
  }

  public static async createUserPersonalData(
    userPersonalData: UserGroupsDataApi,
  ): Promise<ApiResult> {
    return await UserService.api.post<UserPersonalData>(
      `/connect/v1/user`,
      userPersonalData,
    );
  }

  public static async getUserPersonalData(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.get<UserPersonalData>(`/connect/v1/user/${userId}`);
  }

  public static async updateUserPersonalData(
    userPersonalData: UserGroupsDataApi,
  ): Promise<ApiResult> {
    return await UserService.api.put<UserPersonalData>(`/connect/v1/user`, userPersonalData);
  }

  public static async activeUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/active`,
      {},
    );
  }

  public static async blockedUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/blocked`,
      {},
    );
  }

  public static async suspendedUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/suspended`,
      {},
    );
  }

  public static async editingUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/editing`,
      {},
    );
  }

  public static async lastedStatusUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/lasted-status`,
      {},
    );
  }

  public static async getViewedV3(userId: string): Promise<ApiResult<string>> {
    return await UserService.api.get<string>(`/connect/v1/user/${userId}/viewed-v3`, {});
  }

  public static async setViewedV3(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.put<UserPersonalData>(
      `/connect/v1/user/${userId}/viewed-v3`,
      {},
    );
  }

  public static async deleteUser(
    userId: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.delete<UserPersonalData>(`/connect/v1/user/${userId}`);
  }

  public static async resetPassword(
    userId: string,
    newPassword: string,
  ): Promise<ApiResult<UserPersonalData>> {
    return await UserService.api.post<any>(`/connect/v1/user/${userId}`, {userId, newPassword});
  }
  
}

export { UserService };
