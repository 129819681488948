import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import style from './SearchInputV1.module.scss';
import { translate } from './SearchInput.translations';
import { BrassInput, BrassInputProps } from '../../../brass';
import { I18nService } from '../../../../services';
import { BrassIcon } from '../../icon/Icon';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface BrassSearchInputV1 extends BrassInputProps {
  classNameIcon?: string;
  classNameContainer?: string;
  hideIcon?: string;
}
const BrassSearchInputV1: FunctionComponent<BrassSearchInputV1> = (
  props: BrassSearchInputV1,
) => {
  const { classNameContainer, classNameIcon } = props;
  const _classNameContainer = classnames(
    style['search-input-container'],
    classNameContainer,
  );

  const _props = { ...props };
  delete _props.classNameIcon;
  delete _props.classNameContainer;

  return (
    <div className={_classNameContainer}>
      <BrassInput
        autoFocus
        className={style['search-input']}
        placeholder={translate('search')}
        {..._props}
      />
      {!props.hideIcon && (
        <BrassIcon
          className={classnames(style['search-icon'], classNameIcon)}
          icon={faSearch}
        />
      )}
    </div>
  );
};

export { BrassSearchInputV1 };
