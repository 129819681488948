import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Notification, NotificationProps } from '@progress/kendo-react-notification';

interface BrassNotificationProps extends NotificationProps {}

const BrassNotification: FunctionComponent<BrassNotificationProps> = (
  props: BrassNotificationProps,
) => {
  const { className } = props;
  const _className = classnames('brass-notification', className);

  return <Notification {...props} closable className={_className} />;
};

export { BrassNotification };
export type { BrassNotificationProps };
