import React, { Component, Dispatch, ReactNode, SetStateAction } from 'react';
import style from './TreeListGrid.module.scss';
import { GridSelectableSettings } from '@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings';
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassButtonAdd,
  BrassButtonHelp,
  BrassButtonRefresh,
  BrassGridCellProps,
  BrassGridConfigProps,
  BrassLoader,
  BrassTreeListGridPropsV1,
  HideHeaderCell,
} from '../../../..';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Button,
  DropDownButton,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';

const SelectColumn = (
  columns: ReactNode[],
  gridData: any,
  selectedRecords: string[],
  selectable?: GridSelectableSettings,
) => {
  if (selectable && selectable.enabled) {
    columns.unshift(
      <GridColumn
        width='50px'
        key='selected'
        field='selected'
        groupable={false}
        filterable={false}
        className={style['brass-grid-column-center']}
        headerSelectionValue={
          gridData?.records &&
          gridData?.records?.length &&
          selectedRecords?.length === gridData?.records?.length
        }
      />,
    );
  }
};

const editCell = (): any =>
  class extends Component {
    public render(): any {
      return (
        <td>
          teste
          {/* <Button>
            teste
          </Button> */}
        </td>
      );
    }
  };

const EditColumn = (
  allowEdit: boolean,
  preColumns: ReactNode[],
  isTreeGrid: boolean,
  EditCell: BrassGridCellProps | any,
  onEditCallback: (item: any) => void,
) => {
  if (allowEdit) {
    isTreeGrid
      ? preColumns.push({
          cell: editCell(),
          width: 100,
          field: 'actions',
          title: 'Actions',
        })
      : preColumns.unshift(
          <GridColumn
            resizable={false}
            key='edit'
            field='edit'
            groupable={false}
            width='50px'
            cell={(_props) => EditCell(_props, onEditCallback)}
            filterable={false}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const DeleteColumn = (
  allowDelete: boolean,
  preColumns: ReactNode[],
  DeleteCell: BrassGridCellProps | any,
  onDeleteCallback: (item: any) => void,
  gridData: any,
  updateGridData: Dispatch<any>,
) => {
  if (allowDelete) {
    preColumns.push(
      <GridColumn
        resizable={false}
        key='delete'
        field='delete'
        width='50px'
        groupable={false}
        cell={(_props) =>
          DeleteCell(_props, onDeleteCallback, gridData, updateGridData)
        }
        filterable={false}
        headerCell={HideHeaderCell}
      />,
    );
  }
};

const onSelectionChangeFunction = (
  event: GridSelectionChangeEvent,
  selectedRecords: string[],
  setSelectedRecords: (value: SetStateAction<string[]>) => void,
  gridData: any,
  updateGridData: any,
  onSelectionChangeProp: (
    selectedRecordsId: string[],
    event: GridSelectionChangeEvent,
  ) => void,
) => {
  const recordId = event.dataItem.id;
  const recordSelected = selectedRecords.indexOf(recordId) !== -1;

  // Atualiza o ESTADO local com os ID selecionados pelo usuário
  let _selectedRecordsId = [...selectedRecords];
  if (recordSelected) {
    _selectedRecordsId = _selectedRecordsId.filter((f) => f !== recordId);
  } else {
    _selectedRecordsId.push(recordId);
  }
  setSelectedRecords(_selectedRecordsId);

  // ----------------------------------------------------------------------
  // Marca o Registro como SELECIONADO/NÃO-SELECIONADO
  gridData.records = gridData?.records.map((record: any) => {
    if (record.id === recordId) {
      record[event.selectedField] = !recordSelected;
    }
    return record;
  });

  updateGridData({ ...gridData });
  onSelectionChangeProp && onSelectionChangeProp(_selectedRecordsId, event);
};

const onHeaderSelectionChangeFunction = (
  event: GridHeaderSelectionChangeEvent,
  selectedRecords: string[],
  gridData: any,
  updateGridData: (value: any) => void,
  setSelectedRecords: (value: SetStateAction<string[]>) => void,
  onHeaderSelectionChangeProp:
    | ((selectedRecordsId: string[], event: GridHeaderSelectionChangeEvent) => void)
    | undefined,
) => {
  let _selectedRecordsId = [];
  const allRecordsSelected = selectedRecords.length === gridData?.records.length;

  if (!allRecordsSelected) {
    _selectedRecordsId = gridData?.records.map((r: any) => r.id);
  }

  gridData.records = gridData?.records.map((record: any) => {
    record[event.selectedField] = !allRecordsSelected;
    return record;
  });

  updateGridData({ ...gridData });
  setSelectedRecords(_selectedRecordsId);
  onHeaderSelectionChangeProp &&
    onHeaderSelectionChangeProp(_selectedRecordsId, event);
};

export {
  SelectColumn,
  EditColumn,
  DeleteColumn,
  onSelectionChangeFunction,
  onHeaderSelectionChangeFunction,
};
