import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './UserNotifications.module.scss';
import {
  BrassBadge,
  BrassBadgeContainer,
  BrassButton,
  BrassIcon,
} from '../../../../../../components';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import {
  NotificationService,
  UserNotificationCount,
} from '../../../../../../modules/notification';
import { ApiResult, ModuleUrlEnum } from '../../../../../../models';
import { redirectPlatform } from '../../../../../../util';
import { windowStage } from '@progress/kendo-react-dialogs/dist/npm/StageEnum';
import { EnvironmentService } from '../../../../../../services';
import { MainLayoutContainerContext } from '../../MainLayoutContainer';

const UserNotifications: FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    notificationCount,
    setNotificationCount,
    isPulsingNotification,
    setIsPulsingNotification,
    handleCountNotifications,
  } = useContext(MainLayoutContainerContext);

  useEffect(() => {
    handleCountNotifications && handleCountNotifications();
  }, []);

  return (
    <BrassBadgeContainer>
      <BrassButton
        fillMode='flat'
        onClick={(): void => {
          setNotificationCount && setNotificationCount(0);
          setIsPulsingNotification && setIsPulsingNotification(false);

          const actualPlatform = EnvironmentService.app;

          if (actualPlatform == ModuleUrlEnum.CONNECT) {
            if (navigate) {
              navigate('/home/#notifications');
              return;
            }
          }

          redirectPlatform(ModuleUrlEnum.CONNECT, 'home/#notifications');
        }}
        className={isPulsingNotification ? style.alert : ''}
      >
        <BrassIcon icon={faBell} size='2x' color='white' />
        {((notificationCount && Number(notificationCount) > 0) ||
          notificationCount === '99+') && (
          <BrassBadge
            align={{ vertical: 'top', horizontal: 'end' }}
            position='edge'
            themeColor='error'
            style={{
              margin: '12px 6px 0 0',
              fontSize: '0.7rem !important',
              zIndex: 0,
            }}
          >
            {notificationCount}
          </BrassBadge>
        )}
      </BrassButton>
    </BrassBadgeContainer>
  );
};

export { UserNotifications };
