import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { RadioGroup, RadioGroupProps } from '@progress/kendo-react-inputs';

interface BrassRadioGroupProps extends RadioGroupProps {}

const BrassRadioGroup: FunctionComponent<BrassRadioGroupProps> = (
  props: BrassRadioGroupProps,
) => {
  const { className } = props;
  const _className = classnames('brass-radio-group', className);
  return <RadioGroup {...props} className={_className} />;
};

export { BrassRadioGroup };
export type { BrassRadioGroupProps };
