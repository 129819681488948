import React, { FunctionComponent, memo } from 'react';
import { translate } from './ProjectStatusColumn.translations';
import { BrassGridColumnMenu } from '../../../../components';
import { GridColumn, GridColumnProps } from '@progress/kendo-react-grid';

interface ProjectStatusColumnProps extends GridColumnProps {}

const ProjectStatusColumn: FunctionComponent<ProjectStatusColumnProps> = memo(
  (props: ProjectStatusColumnProps) => (
    <GridColumn
      key='projectStatus'
      field='projectStatus'
      title={translate('status')}
      width='200'
      columnMenu={BrassGridColumnMenu}
      {...props}
    />
  ),
);

export { ProjectStatusColumn };
export type { ProjectStatusColumnProps };
