import React, { FunctionComponent } from 'react';
import { translate } from './PhoneField.translations';
import {
  BrassMaskedTextBoxField,
  BrassMaskedTextBoxFieldProps,
} from '../../../../brass';
import { I18nService } from '../../../../../services';

const BrassPhoneField: FunctionComponent<BrassMaskedTextBoxFieldProps> = (
  props: BrassMaskedTextBoxFieldProps,
) => (
  <BrassMaskedTextBoxField
    {...props}
    mask='(00) 00000 0000'
    defaultValue=''
    label={translate('phone')}
  />
);

export { BrassPhoneField };
