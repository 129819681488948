class PaginationModel {
  public take: number = 0;
  public skip: number = 0;
  public total: number = 0;

  constructor(paginationModel: Partial<PaginationModel>) {
    Object.assign(this, paginationModel);
  }
}

export { PaginationModel };
