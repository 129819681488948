import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { CardSubtitle } from '@progress/kendo-react-layout';
import { CardSubtitleProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardSubtitleProps';

interface BrassCardSubtitleProps extends CardSubtitleProps {}

const BrassCardSubtitle: FunctionComponent<BrassCardSubtitleProps> = (
  props: BrassCardSubtitleProps,
) => {
  const { className } = props;
  const _className = classnames('brass-card-subtitle', className);

  return <CardSubtitle {...props} className={_className} />;
};

export { BrassCardSubtitle };
export type { BrassCardSubtitleProps };
