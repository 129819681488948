import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    titleModal: 'check translations',
  },
  enUS: {
    titleModal: 'check translations',
  },
  ptBR: {
    titleModal: 'Verificar traduções',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
