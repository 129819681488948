import { ConnectModulePermissions } from '../connect/Connect.submodule.enum';
import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';

enum ContractManagmentFeaturesPermissions {
  PROJECT_CLIENT = 0x00000100,
  CUSTOMER_MANAGERS = 0x00000200,
  PROJECT_PROPOSAL = 0x00000300,
  MANAGEMENT_TASK_ACTIVITY_PROPOSAL = 0x00000400,
  PROPOSAL_MANAGER = 0x00000500,
  PROJECT = 0x00000600,
  PROJECT_BUDGET = 0x00000700,
  PROJECT_FOLLOW_UP = 0x00000800,
}

enum ProjectPermissionsV1 {
  PROJECT_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.READ,
  PROJECT_CREATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.CREATE,
  PROJECT_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.UPDATE,
  PROJECT_DELETE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.DELETE,
  PROJECT_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.EXPORT,

  PROJECT_MANAGEMENT_TASK_ACTIVITY_PROPOSAL_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.MANAGEMENT_TASK_ACTIVITY_PROPOSAL +
    Action.READ,
  PROJECT_MANAGEMENT_TASK_ACTIVITY_PROPOSAL_CREATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.MANAGEMENT_TASK_ACTIVITY_PROPOSAL +
    Action.CREATE,
  PROJECT_MANAGEMENT_TASK_ACTIVITY_PROPOSAL_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.MANAGEMENT_TASK_ACTIVITY_PROPOSAL +
    Action.UPDATE,
  PROJECT_MANAGEMENT_TASK_ACTIVITY_PROPOSAL_DELETE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.MANAGEMENT_TASK_ACTIVITY_PROPOSAL +
    Action.DELETE,
  PROJECT_MANAGEMENT_TASK_ACTIVITY_PROPOSAL_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.MANAGEMENT_TASK_ACTIVITY_PROPOSAL +
    Action.EXPORT,

  PROJECT_BUDGET_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_BUDGET +
    Action.READ,
  PROJECT_BUDGET_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_BUDGET +
    Action.UPDATE,
  PROJECT_BUDGET_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_BUDGET +
    Action.EXPORT,

  PROJECT_LEADER_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.READ,
  PROJECT_LEADER_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT +
    Action.UPDATE,

  PROJECT_FOLLOW_UP_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_FOLLOW_UP +
    Action.READ,
  PROJECT_FOLLOW_UP_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_FOLLOW_UP +
    Action.UPDATE,

  PROJECT_CLIENT_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.READ,
  PROJECT_CLIENT_CREATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.CREATE,
  PROJECT_CLIENT_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.UPDATE,
  PROJECT_CLIENT_DELETE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.DELETE,
  PROJECT_CLIENT_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.EXPORT,
  PROJECT_CLIENT_SUSPEND = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.SUSPEND,
  PROJECT_CLIENT_BLOCK = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_CLIENT +
    Action.BLOCK,

  PROJECT_PROPOSAL_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_PROPOSAL +
    Action.READ,
  PROJECT_PROPOSAL_CREATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_PROPOSAL +
    Action.CREATE,
  PROJECT_PROPOSAL_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_PROPOSAL +
    Action.UPDATE,
  PROJECT_PROPOSAL_DELETE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_PROPOSAL +
    Action.DELETE,
  PROJECT_PROPOSAL_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROJECT_PROPOSAL +
    Action.EXPORT,

  PROPOSAL_MANAGER_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROPOSAL_MANAGER +
    Action.READ,
  PROPOSAL_MANAGER_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROPOSAL_MANAGER +
    Action.UPDATE,
  PROPOSAL_MANAGER_COMMENT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROPOSAL_MANAGER +
    Action.COMMENT,
  PROPOSAL_MANAGER_LIKE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROPOSAL_MANAGER +
    Action.LIKE,
  PROPOSAL_MANAGER_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.PROPOSAL_MANAGER +
    Action.KEY_USER,

  CUSTOMER_MANAGERS_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.READ,
  CUSTOMER_MANAGERS_CREATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.CREATE,
  CUSTOMER_MANAGERS_UPDATE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.UPDATE,
  CUSTOMER_MANAGERS_DELETE = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.DELETE,
  CUSTOMER_MANAGERS_BLOCK = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.BLOCK,
  CUSTOMER_MANAGERS_EXPORT = PermissionModule.CONNECT +
    ConnectModulePermissions.CONTRACT_MANAGMENT_MODULE +
    ContractManagmentFeaturesPermissions.CUSTOMER_MANAGERS +
    Action.EXPORT,
}

export { ProjectPermissionsV1 };
