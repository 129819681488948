import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import classnames from 'classnames';
import { Editor, EditorProps, EditorTools } from '@progress/kendo-react-editor';

interface BrassEditorProps extends EditorProps {
  ref: any;
}

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const BrassEditor: ForwardRefExoticComponent<
  Pick<BrassEditorProps, keyof BrassEditorProps> & RefAttributes<unknown>
> = forwardRef((props: BrassEditorProps) => {
  const { className, ref } = props;
  const _className = classnames('brass-editor', className);

  return (
    <Editor
      tools={[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink, InsertImage, ViewHtml],
        [InsertTable],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell],
      ]}
      {...props}
      ref={ref}
      className={_className}
    />
  );
});

export { BrassEditor };
export type { BrassEditorProps };
