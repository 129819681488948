import React, { FunctionComponent, useContext } from 'react';
import {
  BrassImageViewer,
  BrassLoader,
  BrassPdfViewer,
  BrassPointCloudViewer,
  NavigatorFileType,
  ViewerContext,
} from '../../../../../';
import { Loader } from '@progress/kendo-react-indicators';
import { EmptyViewer } from './empty-viewer/EmptyViewer';
import style from './MainViewer.module.scss';

const MainViewerManagement: FunctionComponent = () => {
  const { showDetails, navigatorFile, loadingViewer } = useContext(ViewerContext);

  const documentType = navigatorFile.document.type;
  const url = navigatorFile.document.viewer;
  const heigthStyle = showDetails
    ? style['point-document-details-viewer-on']
    : style['point-document-viewer'];

  if (loadingViewer) {
    return <BrassLoader useLoadingMask />;
  }

  if (!documentType) {
    return <EmptyViewer className={heigthStyle} />;
  } else if (!navigatorFile.document.conversionFinished) {
    // Indica que o documento ainda está em processo de Conversão.
    let { conversionProgress } = navigatorFile.document;

    if (conversionProgress === 0) {
      conversionProgress = 10;
    }
    return (
      <div className={style.documentViewerDiv}>
        <h2>Progresso de conversão:</h2>
        <h1>{conversionProgress}%</h1>
        <Loader type='converging-spinner' />
      </div>
    );
  }

  if (!documentType) {
    return <EmptyViewer />;
  } else if (documentType === NavigatorFileType.POINTCLOUD) {
    return <BrassPointCloudViewer url={url} className={heigthStyle} />;
  } else if (documentType === NavigatorFileType.PDF) {
    return <BrassPdfViewer url={url} className={heigthStyle} />;
  } else if (documentType === NavigatorFileType.IMG) {
    return <BrassImageViewer url={url} className={heigthStyle} />;
  } else if (documentType === NavigatorFileType.VIDEO) {
    return (
      <iframe
        height='850px'
        width='100%'
        src={url}
        className={heigthStyle}
        title={navigatorFile.name}
      />
    );
  }
  return <EmptyViewer />;
};

export { MainViewerManagement };
