import React, { FunctionComponent } from 'react';
import styles from './Chip.module.scss';
import { Chip, ChipProps } from '@progress/kendo-react-buttons';

interface BrassChipProps extends ChipProps {}

const BrassChip: FunctionComponent<BrassChipProps> = (props: BrassChipProps) => (
  <Chip className='brass-chip' {...props} />
);

export { BrassChip };
export type { BrassChipProps };
