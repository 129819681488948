import React, { FunctionComponent } from 'react';
import style from './Stepper.module.scss';
import classnames from 'classnames';
import { Stepper, StepperProps } from '@progress/kendo-react-layout';

interface BrassStepperProps extends StepperProps {}

const BrassStepper: FunctionComponent<BrassStepperProps> = (
  props: BrassStepperProps,
) => {
  const { className } = props;
  const _className = classnames('brass-stepper', style['brass-stepper'], className);

  return <Stepper {...props} className={_className} />;
};

export { BrassStepper };
export type { BrassStepperProps };
