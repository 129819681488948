class SchedulerData {
  public id: number = 0;
  public ownerId: number = 0;
  public personId: number = 0;
  public taskId: number = 0;
  public title: string = '';
  public description: string = '';
  public start: Date = new Date();
  public startTimezone: string | null = null;
  public end: Date = new Date();
  public endTimezone: string | null = null;
  public recurrenceRule: string | null = null;
  public recurrenceId: string | null = null;
  public recurrenceExceptions: Date[] | null = null;
  public isAllDay: boolean = false;

  constructor(schedulerData: Partial<SchedulerData>) {
    schedulerData.personId = schedulerData.ownerId;
    Object.assign(this, schedulerData);
  }
}

export { SchedulerData };
