import { emptyGuid } from '../util/Guid';
import { AuthUser } from './authUser.model';

class Account {
  public id: string = emptyGuid();
  public name: string = '';

  constructor(account: Partial<Account>) {
    Object.assign(this, account);
  }

  public static getId(): string {
    if (AuthUser.isAuthenticated()) {
      const user = AuthUser.currentUser;
      return user.accountId;
    }
    return emptyGuid();
  }

  public static isValidAccountId = (): boolean => Account.getId() !== emptyGuid();
}

export { Account };
