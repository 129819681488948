import React, { Fragment, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from '@progress/kendo-react-animation';
import {
  BrassNotification,
  BrassNotificationGroup,
  BrassNotificationProps,
} from '../../brass';
import { notificationActions, RootReducerProps } from '../../../store';
import { Notification } from '../../../models';
import { PositionNotification } from './NotificationsPositions';

interface BrassGeneralNotificationProps extends BrassNotificationProps {}

const BrassGeneralNotification: FunctionComponent<BrassGeneralNotificationProps> = (
  props: BrassGeneralNotificationProps,
) => {
  const dispatch = useDispatch();
  const notifications = useSelector<RootReducerProps>(
    (state) => state.notification.notifications,
  ) as Notification[];

  const closeNotification = (notificationId: string): void => {
    dispatch(notificationActions.hideNotification(notificationId));
  };

  if (notifications.length <= 0) {
    return <Fragment />;
  } else {
    return (
      <BrassNotificationGroup
        style={{
          zIndex: 9999999,
          ...PositionNotification[notifications[0].position],
        }}
      >
        {notifications.map((notification: Notification) => {
          setTimeout(() => closeNotification(notification.id), notification.timeout);
          return (
            <Fade key={notification.id}>
              <BrassNotification
                {...props}
                type={{ style: notification.style, icon: notification.icon }}
                onClose={() => closeNotification(notification.id)}
              >
                {notification.content}
              </BrassNotification>
            </Fade>
          );
        })}
      </BrassNotificationGroup>
    );
  }
};

export { BrassGeneralNotification };
export type { BrassGeneralNotificationProps };
