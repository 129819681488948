import { emptyGuid } from '../../../util';
import { UserNotificationContext } from './userNotificationContext.model';

class UserNotificationResponse {
  public toUser: string = emptyGuid();
  public message: string = '';
  public context: UserNotificationContext = {};

  constructor(userNotificationContext: Partial<UserNotificationResponse>) {
    Object.assign(this, userNotificationContext);
  }
}

export { UserNotificationResponse };
