import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIconPlusCircle: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.875 8H8.5V5.625C8.5 5.55625 8.44375 5.5 8.375 5.5H7.625C7.55625 5.5 7.5 5.55625 7.5 5.625V8H5.125C5.05625 8 5 8.05625 5 8.125V8.875C5 8.94375 5.05625 9 5.125 9H7.5V11.375C7.5 11.4437 7.55625 11.5 7.625 11.5H8.375C8.44375 11.5 8.5 11.4437 8.5 11.375V9H10.875C10.9437 9 11 8.94375 11 8.875V8.125C11 8.05625 10.9437 8 10.875 8Z'
      fill={props.fill}
    />
    <path
      d='M8 1.5C4.13438 1.5 1 4.63438 1 8.5C1 12.3656 4.13438 15.5 8 15.5C11.8656 15.5 15 12.3656 15 8.5C15 4.63438 11.8656 1.5 8 1.5ZM8 14.3125C4.79063 14.3125 2.1875 11.7094 2.1875 8.5C2.1875 5.29063 4.79063 2.6875 8 2.6875C11.2094 2.6875 13.8125 5.29063 13.8125 8.5C13.8125 11.7094 11.2094 14.3125 8 14.3125Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconPlusCircle };
