import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonEnter: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-enter'
    labelKey='enter'
    tooltipKey='clickToEnter'
    translations={translations}
    {...props}
  />
);

const translations: II18n = {
  es: {
    enter: 'Ingresar',
    clickToEnter: 'haga clic para imprimir',
  },
  enUS: {
    enter: 'Enter',
    clickToEnter: 'Click para entrar',
  },
  ptBR: {
    enter: 'Entrar',
    clickToEnter: 'Clique para entrar',
  },
};

export { BrassButtonEnter };
