import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIconClarityList: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='25'
    height='26'
    viewBox='0 0 25 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#clip0_366_13272)'>
      <path
        d='M19.4443 1.88892H5.55539C5.18704 1.88892 4.83377 2.03524 4.5733 2.29571C4.31283 2.55618 4.1665 2.90945 4.1665 3.2778V22.7222C4.1665 23.0906 4.31283 23.4439 4.5733 23.7043C4.83377 23.9648 5.18704 24.1111 5.55539 24.1111H19.4443C19.8126 24.1111 20.1659 23.9648 20.4264 23.7043C20.6868 23.4439 20.8332 23.0906 20.8332 22.7222V3.2778C20.8332 2.90945 20.6868 2.55618 20.4264 2.29571C20.1659 2.03524 19.8126 1.88892 19.4443 1.88892ZM9.02761 18.5556H7.63873V17.1667H9.02761V18.5556ZM9.02761 15.7778H7.63873V14.3889H9.02761V15.7778ZM9.02761 13H7.63873V11.6111H9.02761V13ZM9.02761 10.2222H7.63873V8.83336H9.02761V10.2222ZM9.02761 7.44447H7.63873V6.05558H9.02761V7.44447ZM17.3609 18.5556H10.4165V17.1667H17.3609V18.5556ZM17.3609 15.7778H10.4165V14.3889H17.3609V15.7778ZM17.3609 13H10.4165V11.6111H17.3609V13ZM17.3609 10.2222H10.4165V8.83336H17.3609V10.2222ZM17.3609 7.44447H10.4165V6.05558H17.3609V7.44447Z'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_366_13272'>
        <rect width='25' height='25' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export { SVGIconClarityList };
