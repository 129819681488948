import React, { createContext, ReactNode, useState } from 'react';
import { getLanguagesStorage } from '../components';
import { useWindowSize } from '../hooks';
import { DropDownData } from '../models';
import { RelationalContextMap } from '../models/RelationalContextMap.model';
import { RelationalGroup } from '../models/RelationalGroup.model';

interface RelationalFlagsState {
  color: string;
  language: string;
  relationalContextMap: RelationalContextMap;
  relationalGroup: RelationalGroup;
  openRelationalGroup: boolean;
  expandSubGroup: boolean;
  width: number;
  height: number;
  flags: DropDownData[];
  selectedFlags: string[];
  filter: string;
  flagValue: DropDownData;
  dataItemRemove: DropDownData | null;
  flagsNoSelecteds: DropDownData[];
  refresh: boolean;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setRelationalContextMap: React.Dispatch<
    React.SetStateAction<RelationalContextMap>
  >;
  setRelationalGroup: React.Dispatch<React.SetStateAction<RelationalGroup>>;
  setOpenRelationalGroup: React.Dispatch<React.SetStateAction<boolean>>;
  setExpandSubGroup: React.Dispatch<React.SetStateAction<boolean>>;
  setFlags: React.Dispatch<React.SetStateAction<DropDownData[]>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setFlagValue: React.Dispatch<React.SetStateAction<DropDownData<string>>>;
  setSelectedFlags: React.Dispatch<React.SetStateAction<string[]>>;
  setFlagsNoSelecteds: React.Dispatch<React.SetStateAction<DropDownData[]>>;
  setDataItemRemove: React.Dispatch<React.SetStateAction<DropDownData | null>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RelationalFlagsStateProvider {
  children: ReactNode;
}

const RelationalFlagsContext = createContext<RelationalFlagsState>(
  {} as RelationalFlagsState,
);

const RelationalFlagsProvider: (props: RelationalFlagsStateProvider) => any = ({
  children,
}: RelationalFlagsStateProvider) => {
  const languageLocalStorage = getLanguagesStorage()?.language;
  const [color, setColor] = useState<string>('');
  const [language, setLanguage] = useState<string>(languageLocalStorage);
  const [width, height] = useWindowSize();
  const [relationalContextMap, setRelationalContextMap] = useState(
    {} as RelationalContextMap,
  );
  const [relationalGroup, setRelationalGroup] = useState(
    {} as RelationalGroup 
  );
  const [openRelationalGroup, setOpenRelationalGroup] = useState(false);
  const [expandSubGroup, setExpandSubGroup] = useState<boolean>(false);
  const [flags, setFlags] = useState<DropDownData[]>([]);
  const [selectedFlags, setSelectedFlags] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [flagValue, setFlagValue] = useState<DropDownData>({} as DropDownData);
  const [flagsNoSelecteds, setFlagsNoSelecteds] = useState<DropDownData[]>([]);
  const [dataItemRemove, setDataItemRemove] = useState<DropDownData | null>(
    {} as DropDownData,
  );
  const [refresh, setRefresh] = useState<boolean>(false);

  return (
    <RelationalFlagsContext.Provider
      value={{
        color,
        language,
        width,
        height,
        relationalContextMap,
        relationalGroup,
        openRelationalGroup,
        expandSubGroup,
        flags,
        filter,
        flagValue,
        flagsNoSelecteds,
        refresh,
        setColor,
        setLanguage,
        setRelationalContextMap,
        setRelationalGroup,
        setOpenRelationalGroup,
        setExpandSubGroup,
        setFlags,
        setFilter,
        setFlagValue,
        selectedFlags,
        setSelectedFlags,
        setFlagsNoSelecteds,
        setRefresh,
        dataItemRemove,
        setDataItemRemove,
      }}
    >
      {children}
    </RelationalFlagsContext.Provider>
  );
};

export { RelationalFlagsContext, RelationalFlagsProvider };

