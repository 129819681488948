import { DateTimeService } from '../../../../services/dateTime.service';
import React, {
  FunctionComponent,
  memo,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  BrassChart,
  BrassChartTitle,
  BrassChartCategoryAxis,
  BrassChartCategoryAxisItem,
  BrassChartSeries,
  BrassChartLegend,
} from '../../../../components';
import style from './StatisticsChart.module.scss';
import { translate } from './StatisticsChart.translations';
import { ChartSeriesItem } from '@progress/kendo-react-charts';

interface StatisticsChartProps extends PropsWithChildren<any> {
  categories: string[];
  chartTitle: string;
  axisTitle?: string;
  data: any[];
  dailyHoursLimit: number;
}

const StatisticsChart: FunctionComponent<StatisticsChartProps> = memo(
  (props: StatisticsChartProps) => {
    const { data, categories, axisTitle, chartTitle, dailyHoursLimit } = props;
    const [hoursLimit, setHoursLimit] = useState<number[]>([]);
    const formatedCategories = categories.map((cat) =>
      DateTimeService.getMonthNameByEnglish(cat),
    );

    useEffect(() => {
      const numberOfDays = data.length;
      const dailyHours: number[] = [];
      for (let i = 0; i < numberOfDays; i++) {
        dailyHours.push(dailyHoursLimit);
      }
      setHoursLimit(dailyHours);
    }, [data, dailyHoursLimit]);

    return (
      <div className={style['chart-container']}>
        <BrassChart>
          <BrassChartTitle text={chartTitle} />
          <BrassChartCategoryAxis>
            <BrassChartCategoryAxisItem
              categories={formatedCategories}
              title={{ text: axisTitle }}
            />
          </BrassChartCategoryAxis>
          <BrassChartLegend
            visible={true}
            position='bottom'
            orientation='horizontal'
          />
          <BrassChartSeries>
            <ChartSeriesItem
              name={translate('dailyWorkHours')}
              markers={{ visible: true }}
              type='area'
              color='gray'
              data={data}
              tooltip={{ visible: true }}
            />
            <ChartSeriesItem
              name={translate('dailyWorkHoursLimit')}
              markers={{ visible: false }}
              type='line'
              color='red'
              data={hoursLimit}
            />
          </BrassChartSeries>
        </BrassChart>
      </div>
    );
  },
);

export type { StatisticsChartProps };
export { StatisticsChart };
