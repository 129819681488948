import React, { Fragment, FunctionComponent, ReactElement } from 'react';
import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { AuthUser, ModuleUrlEnum } from '../../../../../../models';
import {
  CookieService,
  EnvironmentService,
  LocalStorageService,
} from '../../../../../../services';
import { translate } from './UserAvatar.translations';
import style from './UserAvatar.module.scss';
import { BrassAvatar } from '../../../../../brass';
import { getRedirectPlatformUrl } from '../../../../../../util';

const UserAvatar: FunctionComponent = () => {
  if (!AuthUser.currentUser) {
    return <Fragment />;
  }

  const srcUserAvatar = AuthUser.currentUser.photo;
  const userName = AuthUser.currentUser.name;
  const splitUserName = userName.split(' ');

  const firstUserName = splitUserName[0].split('')[0];
  const lastUserName = splitUserName[splitUserName.length - 1].split('')[0];
  const initialUserName = firstUserName.concat(lastUserName);

  const contentAvatar = (): ReactElement => (
    <Fragment>
      {srcUserAvatar && (
        <BrassAvatar rounded='full' type='image' themeColor='light'>
          <img alt={userName} src={srcUserAvatar} />
        </BrassAvatar>
      )}
      {!srcUserAvatar && (
        <BrassAvatar rounded='full' type='text' themeColor='light'>
          <div title={userName} className={style.userName}>
            {initialUserName}
          </div>
        </BrassAvatar>
      )}
    </Fragment>
  );

  const handleSelect = (e: MenuSelectEvent) => {
    if (e.item.text === translate('logout')) {
      LocalStorageService.removeLocalStorage(CookieService.cookieAuthUser);
      CookieService.removeCookie('authUser');
    }
  };

  const actualPlatform = EnvironmentService.app;
  return (
    <Menu onSelect={handleSelect}>
      <MenuItem render={contentAvatar} key='00'>
        <MenuItem
          key='01'
          text={translate('userProfile')}
          url={
            actualPlatform == ModuleUrlEnum.CONNECT
              ? '/profile'
              : getRedirectPlatformUrl(ModuleUrlEnum.CONNECT, 'profile')
          }
          icon='gear'
        />
        <MenuItem
          key='02'
          text={translate('changePassword')}
          url={
            actualPlatform == ModuleUrlEnum.CONNECT
              ? '/profile/change-password'
              : getRedirectPlatformUrl(
                  ModuleUrlEnum.CONNECT,
                  'profile/change-password',
                )
          }
          icon='edit'
        />
        <MenuItem key='03' text={translate('logout')} url='/login' icon='logout' />
      </MenuItem>
    </Menu>
  );
};

export { UserAvatar };
