import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';

enum SgiModulePermission {
  MANAGEMENT_CONTENT = 0x00010000,
  LESSON_LEARNED = 0x00020000,
  USER_PAINEL = 0x00030000,
}

enum SgiFeaturesPermission {
  MANAGEMENT_AREA_SYSTEM = 0x00000100,
  MANAGEMENT_CONTENT = 0x00000200,

  LESSON_LEARNED = 0x00000100,
  MANAGEMENT_SUGESTION = 0x00000200,

  USER_PAINEL_VISUALIZATION_CONTENT = 0x00000100,
  USER_PAINEL_LESSON_LEARNED = 0x00000200,
}

export { SgiModulePermission, SgiFeaturesPermission };
