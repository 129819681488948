import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useContext,
  memo,
  useState,
} from 'react';
import style from './Viewer.v1.module.scss';
import classnames from 'classnames';
import { SplitterOnChangeEvent } from '@progress/kendo-react-layout';
import { ViewerContextV1, ViewerContextV1Provider } from '../../../../store';
import { EncryptionService } from '../../../../services';
import { AuthUser, NavigatorPermissions } from '../../../../models';
import { AccessDenied, BrassSplitter } from '../../../../components';
import { emptyGuid } from '../../../../util';
import { MainViewer } from './main-viewer/MainViewer';
import { BottomPanel, BottomPanelProps } from './bottom-panel/BottomPanel';

interface MainViewerProps {
  floatingButtons?: ReactElement[];
}

interface ViewerV1Props {
  projectId?: string;
  areaId?: string;
  systemId?: string;
  fileId?: string;
  modals?: ReactElement[];
  mainViewerProps: MainViewerProps;
  bottomPanelProps: BottomPanelProps;
}

const ViewerV1: FunctionComponent<ViewerV1Props> = memo((props: ViewerV1Props) => {
  const {
    projectId,
    areaId,
    fileId,
    systemId,
    modals,
    mainViewerProps,
    bottomPanelProps,
  } = props;

  const { showBottomPanel, loadingViewer, showSidePanel, navigatorFile } =
    useContext(ViewerContextV1);

  const [footerSize] = useState<string>(showBottomPanel ? '304px' : '78px');

  const [sidePanelId] = useState<string>(
    EncryptionService.getMd5Encrypt(
      `${navigatorFile.name}${navigatorFile.document.id}`,
    ),
  );

  const [nestedPanes, setNestedPanes] = useState<Array<any>>([
    {},
    { size: 0, resizable: false, min: 470 },
  ]);

  useEffect(() => {
    setNestedPanes([
      {},
      {
        size: showSidePanel ? '31.5vw' : '0vw',
        resizable: showSidePanel ? true : false,
        min: 470,
      },
    ]);
  }, [showSidePanel]);

  const onNestedChange = (event: SplitterOnChangeEvent): void =>
    setNestedPanes(event.newState);

  if (!AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILE_READ)) {
    return <AccessDenied />;
  }

  return (
    <ViewerContextV1Provider
      projectId={projectId !== emptyGuid() ? projectId : undefined}
      areaId={areaId !== emptyGuid() ? areaId : undefined}
      fileId={fileId !== emptyGuid() ? fileId : undefined}
      systemId={systemId !== emptyGuid() ? systemId : undefined}
    >
      <BrassSplitter
        className={classnames(
          style['brass-spliter'],
          loadingViewer && style['brass-spliter-loading'],
        )}
        orientation='vertical'
        panes={[
          {},
          {
            resizable: false,
            size: footerSize,
          },
        ]}
      >
        <div style={{ width: '100vw' }}>
          <BrassSplitter
            panes={nestedPanes}
            orientation='horizontal'
            onChange={onNestedChange}
          >
            <div style={{ width: '100%' }}>
              {!loadingViewer && mainViewerProps.floatingButtons}
              <MainViewer />
            </div>
            <div id={`side-informations-${sidePanelId}`} />
          </BrassSplitter>
          <div id={`float-informations-${sidePanelId}`} />
        </div>
        <div style={{ width: '100vw' }}>
          <BottomPanel {...bottomPanelProps} />
        </div>
      </BrassSplitter>
      {modals}
    </ViewerContextV1Provider>
  );
});

// const showAddButton = (
//     AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_AREA_CREATE) ||
//     AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE)
//   ) &&
//   EnvironmentService.isNavigatorIntegrator;

// const showDownloadButton =
//     hasDocumentId &&
//     navigatorFile.document.conversionFinished &&
//     navigatorFile.document.download &&
//     AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILE_ALLOW_DOWNLOAD) &&
//     EnvironmentService.isNavigatorIntegrator;

//   const showShareButton =
//     AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_READ) &&
//     (AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_CREATE) ||
//       AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_UPDATE) ||
//       AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_DELETE)) &&
//     EnvironmentService.isNavigatorIntegrator;

//   const showInformationButton = EnvironmentService.isNavigatorIntegrator;

// const [showUpsertArea, setShowUpsertArea] = useState<
//     NavigatorFileChildItem | undefined
//   >(undefined);
//   const [showUpsertSystem, setShowUpsertSystem] = useState<
//     NavigatorFileChildItem | undefined
//   >(undefined);
//   const [showUpsertNavigatorFile, setShowUpsertNavigatorFile] = useState<
//     NavigatorFileChildItem | undefined
//   >(undefined);
//   const [showConfirmDeleteRecord, setShowConfirmDeleteRecord] = useState<
//     NavigatorFileChildItem | undefined
//   >(undefined);
//   const [showConfirmPromoteToInitialFileView, setConfirmPromoteToInitialFileView] =
//     useState<NavigatorFileChildItem | undefined>(undefined);

// const promoteToInitialFileView = (rec: NavigatorFileChildItem): void => {
//   if (rec.type === NavigatorItemType.FILE) {
//     setIsLoadingTab(true);

//     FileNavigatorService.promoteToInitialFileView(rec.id).then(() => {
//       loadData();
//       setIsLoadingTab(false);
//     });
//   }
//   setConfirmPromoteToInitialFileView(undefined);
// };

// const deleteRecord = (rec: NavigatorFileChildItem): void => {
//   setIsLoadingTab(true);

//   if (rec.type === NavigatorItemType.FILE) {
//     FileNavigatorService.deleteFileNavigator(rec.id).then(() => {
//       loadData();
//       setIsLoadingTab(false);
//     });
//   } else if (rec.type === NavigatorItemType.SYSTEM) {
//     SystemService.deleteSystem(rec.projectId, rec.id).then(() => {
//       loadData();
//       setIsLoadingTab(false);
//       dispatch(
//         notificationActions.showNotification(
//           new Notification({
//             style: NotificationStyles.SUCCESS,
//             content: translate('successOnDeleteSystem'),
//           }),
//         ),
//       );
//     });
//   } else if (rec.type === NavigatorItemType.AREA) {
//     AreaService.deleteArea(rec.projectId, rec.id).then(() => {
//       loadData();
//       setIsLoadingTab(false);
//       dispatch(
//         notificationActions.showNotification(
//           new Notification({
//             style: NotificationStyles.SUCCESS,
//             content: translate('successOnDeleteArea'),
//           }),
//         ),
//       );
//     });
//   }

//   setShowConfirmDeleteRecord(undefined);
// };

// const editRecord = (rec: NavigatorFileChildItem): void => {
//   if (rec.type === NavigatorItemType.AREA) {
//     setShowUpsertArea(rec);
//   } else if (rec.type === NavigatorItemType.SYSTEM) {
//     setShowUpsertSystem(rec);
//   } else if (rec.type === NavigatorItemType.FILE) {
//     setShowUpsertNavigatorFile(rec);
//   }
// };

ViewerV1.defaultProps = {
  // modals: [
  //   {allowEditRecord && showUpsertArea && (
  //     <UpsertArea
  //       onSaveClose={() => {
  //         setShowUpsertArea(undefined);
  //         loadData();
  //       }}
  //       onCancelClose={() => setShowUpsertArea(undefined)}
  //       areaId={showUpsertArea.id}
  //       projectId={showUpsertArea.projectId}
  //       type={AreaTypeEnum.PHYSICAL}
  //       titleComplement={showUpsertArea.name}
  //     />
  //   )}
  //   {allowEditRecord && showUpsertSystem && (
  //     <UpsertSystem
  //       onSaveClose={() => {
  //         setShowUpsertSystem(undefined);
  //         loadData();
  //       }}
  //       onCancelClose={() => setShowUpsertSystem(undefined)}
  //       systemId={showUpsertSystem.id}
  //       areaId={showUpsertSystem.areaId}
  //       projectId={showUpsertSystem.projectId}
  //       type={SystemTypeEnum.PHYSICAL}
  //       titleComplement={showUpsertSystem.name}
  //     />
  //   )}
  //   {allowEditRecord && showUpsertNavigatorFile && (
  //     <UpsertNavigatorFile
  //       onSaveClose={() => {
  //         setShowUpsertNavigatorFile(undefined);
  //         loadData();
  //       }}
  //       onCancelClose={() => setShowUpsertNavigatorFile(undefined)}
  //       navigatorFileId={showUpsertNavigatorFile.id}
  //       projectId={showUpsertNavigatorFile.projectId}
  //     />
  //   )}
  //   {allowEditRecord && showConfirmPromoteToInitialFileView && (
  //     <BrassConfirmDialog
  //       onYes={() => promoteToInitialFileView(showConfirmPromoteToInitialFileView)}
  //       onNo={() => setConfirmPromoteToInitialFileView(undefined)}
  //       title={translate('titlePromoteToInitialFileView')}
  //       content={translate('areYouSurePromoteToInitialFileView')}
  //     />
  //   )}
  //   {allowDeleteRecord && showConfirmDeleteRecord && (
  //     <BrassConfirmExclusionDialog
  //       onYes={() => deleteRecord(showConfirmDeleteRecord)}
  //       onNo={() => setShowConfirmDeleteRecord(undefined)}
  //     />
  //   )}
  // ]
};

export { ViewerV1 };
