import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { AppBarSpacer, AppBarSpacerProps } from '@progress/kendo-react-layout';

interface BrassBarSpacerProps extends AppBarSpacerProps {}

const BrassBarSpacer: FunctionComponent<BrassBarSpacerProps> = (
  props: BrassBarSpacerProps,
) => {
  const { className } = props;
  const _className = classnames('brass-bar-spacer', className);

  return <AppBarSpacer {...props} className={_className} />;
};

export { BrassBarSpacer };
export type { BrassBarSpacerProps };
