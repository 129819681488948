import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonClearFilters: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-clear-filters'
    labelKey='clearFilters'
    tooltipKey='clickToClearFilters'
    icon='filter-clear'
    translations={translations}
    {...props}
  />
);

const translations: II18n = {
  es: {
    clearFilters: 'Borrar los Filtros',
    clickToClearFilters: 'Haga clic para borrar los filtros',
  },
  enUS: {
    clearFilters: 'Clear Filters',
    clickToClearFilters: 'Click to clear filter',
  },
  ptBR: {
    clearFilters: 'Limpar Filtros',
    clickToClearFilters: 'Clique para limpar filtros',
  },
};

export { BrassButtonClearFilters };
