import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RelationalContextMap } from '../../../../../models/RelationalContextMap.model';
import { RelationalFlagsContext } from '../../../../../store/RelationalFlagsContext';
import { RelationalGroupService } from '../../../../../services/RelationalGroup.service';
import { RelationalContextMapService } from '../../../../../services/RelationalContextMap.service';
import { useDebounce } from '../../../../../hooks';
import { ApiResult, PaginationModel } from '../../../../../models';
import { DynamicDispatchAlert, emptyGuid } from '../../../../../util';
import { translate } from './ListRelationalContextMap.translations';

const ListRelationalMapFunctions = (): any => {
  const dispatch = useDispatch();
  const [list, setList] = useState<RelationalContextMap[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    setRelationalContextMap,
    relationalGroup,
    language,
    setRelationalGroup,
    relationalContextMap,
    filter,
    refresh,
  } = useContext(RelationalFlagsContext);
  const [pagination, setPagination] = useState<PaginationModel>(
    new PaginationModel({
      take: 20,
      total: 0,
      skip: 1,
    } as PaginationModel),
  );
  const scrollObserver = useRef<any>();
  const debouncedFilter: any = useDebounce(filter, 700);

  useEffect(() => {
    if (list?.length >= pagination.total) {
      return;
    } else {
      if (scrollObserver?.current) {
        const intersectionObserver = new IntersectionObserver((entries) => {
          if (entries.some((entry) => entry.isIntersecting)) {
            setPagination({ ...pagination, skip: pagination.skip + 1 });
          }
        });
        intersectionObserver.observe(scrollObserver?.current);
        return () => intersectionObserver.disconnect();
      } else {
        return;
      }
    }
  }, [list]);

  const handleGetList = async (
    newFilter?: string,
    update?: boolean,
  ): Promise<any> => {
    setIsLoading(true);
    let result = null as unknown as ApiResult;
    if (newFilter || !!update) {
      result = await RelationalContextMapService.getAllRelationalContextMap(
        language,
        debouncedFilter,
        pagination.take,
        1,
      );
    } else {
      result = await RelationalContextMapService.getAllRelationalContextMap(
        language,
        debouncedFilter,
        pagination.take,
        pagination.skip,
      );
    }
    if (result) {
      if (newFilter || !!update) {
        setPagination({ ...pagination, skip: 1, total: result.data.total });
        setList(result.data.records);
      } else {
        setPagination({ ...pagination, total: result.data.total });
        setList((listData) => [...listData, ...result.data.records]);
      }
      if (result.errorMessage) {
        DynamicDispatchAlert(dispatch, result.message, 'ERROR', translate);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Adiciona na lista
    if (pagination.skip > 1) {
      handleGetList();
    }
  }, [pagination.skip]);

  useEffect(() => {
    // Zera a lista
    handleGetList(debouncedFilter, refresh);
  }, [debouncedFilter, refresh]);

  const handleGetGroupId = useCallback(
    async (id?: string) => {
      if (id && id !== emptyGuid()) {
        setIsLoading(true);
        RelationalGroupService.getGroupId(id, language)
          .then((apiResult: ApiResult<any>) => {
            if (apiResult.success) {
              if (apiResult.data) {
                setRelationalGroup(apiResult.data);
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setRelationalGroup({} as any);
      }
    },
    [language],
  );

  const onOpen = (item: any): void => {
    if (item.isSelected === false) {
      setRelationalContextMap({} as any);
      return;
    }
    setRelationalContextMap(item);
  };

  return {
    relationalGroup,
    relationalContextMap,
    handleGetGroupId,
    list,
    setList,
    onOpen,
    isLoading,
    pagination,
    scrollObserver,
  };
};

export { ListRelationalMapFunctions };
