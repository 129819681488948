import React, { FunctionComponent } from 'react';
import style from './DropDownCell.module.scss';
import classnames from 'classnames';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { BrassDropDownList } from '../../../dropdown';
import { DropDownData } from '../../../../../models';
import { BrassGridCell, BrassGridCellProps } from '../cell/Cell';

interface BrassDropDownCellProps extends BrassGridCellProps {
  options: DropDownData<any>[];
}

const BrassDropDownCell: FunctionComponent<BrassDropDownCellProps> = (
  props: BrassDropDownCellProps,
) => {
  const handleChange = (e: DropDownListChangeEvent): void => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };

  const { dataItem, className } = props;
  const _className = classnames(style['brass-dropdown-cell'], className);
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return (
    <BrassGridCell {...props} className={_className}>
      {dataItem.inEdit ? (
        <BrassDropDownList
          style={{ width: '100px' }}
          onChange={handleChange}
          value={props.options.find((c) => c.value === dataValue)}
          data={props.options}
          textField='text'
          dataItemKey='value'
        />
      ) : (
        props.options.find((c) => c.value === dataValue)?.text
      )}
    </BrassGridCell>
  );
};

export type { BrassDropDownCellProps };
export { BrassDropDownCell };
