import { BaseService } from './base.service';

class MoneyService extends BaseService {
  public static formatDecimal = (value: number): string =>
    value.toLocaleString(MoneyService.userLanguage, {
      style: 'currency',
      currency: 'BRL', // MoneyService.userCurrency
    });

  public static formatInteger = (value: number): string =>
    value.toLocaleString(MoneyService.userLanguage, {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
    });

  public static getExcelExportColumnFormat = (): string => 'R$ #.##0,00';
  /*
    if (MoneyService.userLanguage === 'pt-BR') {
      return 'R$ #.##0,00';
    } else {
      return '$ #.##0,00';
    }
    */
}

export { MoneyService };
