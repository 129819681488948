import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    recoveryPassword: 'Cambiar contraseña',
    currentPasswordField: 'Contraseña actual',
    newPasswordField: 'Nueva contraseña',
    confirmNewPasswordField: 'Confirmar nueva contraseña',
    title: 'Cambiar mi contraseña',
    description: `Estimado usuario: Debe utilizar una contraseña segura para que otras 
      personas no puedan acceder a su nombre de usuario. Cuanto más fuerte sea su contraseña, 
      más difícil será para una persona desconocida adivinarla. \n\n
      Utilice número + letras mayúsculas y minúsculas + caracteres especiales`,
    weak: 'Contraseña débil',
    medium: 'Seguridad media',
    strong: 'Contraseña segura',
    updateSuccess: '¡La contraseña se cambió correctamente!',
    errorOnUpdate:
      'Error interno al cambiar su contraseña. Vuelve a intentarlo en unos instantes.',
    passwordError:
      'Contraseña actual incorrecta, verifique la contraseña actual y vuelva a intentarlo',
  },
  enUS: {
    recoverPassword: 'Change Password',
    currentPasswordField: 'Current password',
    newPasswordField: 'New Password',
    confirmNewPasswordField: 'Confirm new password',
    title: 'Change My Password',
    description: `Dear User, You must use a strong password so that other people cannot access 
      your username. The stronger your password, the harder it will be for an unknown person to 
      guess your password. \n\n Use Number + Uppercase and Lowercase Letters + Special characters`,
    weak: 'Weak password',
    medium: 'Medium security',
    strong: 'Strong password',
    updateSuccess: 'Password changed successfully!',
    errorOnUpdate:
      'Internal error changing your password. Please try again in a few moments.',
    passwordError:
      'Incorrect current password, please check current password and try again',
  },
  ptBR: {
    recoverPassword: 'Alteração de Senha',
    currentPasswordField: 'Senha atual',
    newPasswordField: 'Nova senha',
    confirmNewPasswordField: 'Confirmar nova senha',
    title: 'Alterar Minha Senha',
    description: `Prezado usuário, você deve utilizar uma senha forte para que outras pessoas 
      não acessem com seu usuário. Quanto mais forte sua senha mais difícil será para um 
      desconhecido adivinhar sua senha. \n\n
      Utilize Número + Letras Maiúsculas e Minúsculas + Caracteres especiais`,
    weak: 'Senha fraca',
    medium: 'Segurança mediana',
    strong: 'Senha forte',
    updateSuccess: 'Senha alterada com sucesso!',
    errorOnUpdate:
      'Erro interno ao alterar sua senha. Tente novamente em alguns instantes.',
    passwordError: 'Senha atual incorreta, confira a senha atual e tente novamente',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
