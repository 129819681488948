import React from 'react';
import style from './EditCell.module.scss';
import { BrassButtonEdit, BrassGridCell, BrassGridCellProps } from '../../../..';

const BrassEditCell = (
  _props: BrassGridCellProps,
  onEditCallback: (item: any) => void,
): any => (
  <BrassGridCell {..._props}>
    <BrassButtonEdit
      fillMode='flat'
      roundBorder
      showText={false}
      hideIconWhenLoader
      hidden={_props.dataItem.hideEdit}
      showLoader={_props.dataItem.deleting}
      className={style['brass-grid-edit-button']}
      disabled={!onEditCallback || _props.dataItem.deleting}
      onClick={() => onEditCallback && onEditCallback(_props.dataItem)}
    />
  </BrassGridCell>
);

export { BrassEditCell };
