import { II18n } from '../../../../../../../models/i18n';
import { I18nService } from '../../../../../../../services/i18n.service';

const translations: II18n = {
  es: {
    hint: 'Arraste e solte arquivos aqui para fazer upload',
    alert: 'No se seleccionó ninguna imagen',
    uploadSuccessfully: 'Upload bien sucedido',
    errorOnUpload: 'Erro al cargar',
  },
  enUS: {
    hint: 'Arrastre y suelte los archivos aquí para upload',
    alert: 'No image was selected',
    uploadSuccessfully: 'Upload was successful',
    errorOnUpload: 'There was an error trying to upload',
  },
  ptBR: {
    hint: 'Arraste e solte arquivos aqui para fazer upload',
    alert: 'Nenhuma imagem foi selecionada',
    uploadSuccessfully: 'Carregamento bem-sucedido',
    errorOnUpload: 'Ocorreu um erro ao tentar fazer o upload',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
