import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassTooltip } from '../../brass/tooltip';
import { BrassLoader } from '../../brass/';
import classNames from 'classnames';
import { BrassButtonPropsV2, BrassButtonV2 } from '../../brass/buttons/ButtonV2';

interface BrassButtonSharedPropsv2 extends Omit<BrassButtonPropsV2, 'icon'> {
  icon?: any;
  showText?: boolean;
  showLoader?: boolean;

  // Ocultar o Icone enquando o Loader estiver ON?
  hideIconWhenLoader?: boolean;

  labelKey?: string;
  tooltipKey?: string;
  translations?: II18n;
}

const BrassButtonSharedV2: FunctionComponent<BrassButtonSharedPropsv2> = (
  props: BrassButtonSharedPropsv2,
) => {
  const _props = { ...props };
  delete _props.icon;
  delete _props.labelKey;
  delete _props.showLoader;
  delete _props.showText;
  delete _props.tooltipKey;
  delete _props.translations;
  delete _props.hideIconWhenLoader;

  const tooltipKey =
    props.translations &&
    I18nService.translate(props.translations!, props.tooltipKey!);
  const labelKey =
    props.showText &&
    props.translations &&
    I18nService.translate(props.translations!, props.labelKey!);

  const button = (
    <span title={tooltipKey}>
      <BrassButtonV2
        themeColor={_props.themeColor || 'dark'}
        fillMode={_props.fillMode || 'solid'}
        {..._props}
      >
        {props.showLoader ? (
          <BrassLoader
            themeColor={_props.themeColor === 'primary' ? 'dark' : 'light'}
            style={{ width: 'auto' }}
          />
        ) : (
          props.icon
        )}
        {props.showText && (
          <span
            style={{
              marginLeft: props.showText ? '5px' : '0px',
            }}
          >
            {labelKey}
          </span>
        )}
      </BrassButtonV2>
    </span>
  );

  if (props.translations && props.tooltipKey) {
    return <BrassTooltip>{button}</BrassTooltip>;
  } else {
    return button;
  }
};

BrassButtonSharedV2.defaultProps = {
  showText: true,
  showLoader: false,
  hideIconWhenLoader: false,
  disabled: false,
};

export { BrassButtonSharedV2 };
export type { BrassButtonSharedPropsv2 };
