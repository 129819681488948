import { BrassGridConfigProps } from '../../../components/brass';
import {
    ApiGridResult,
    ApiResult,
    DropDownData,
    Endpoints,
    FiltersTextProps,
    II18n,
    Project,
} from '../../../models';
import { ApiService, I18nService } from '../../../services';

const translations: II18n = {
  es: {
    INVALID: 'No válidos!',
    PROJECT_FILTER_ALL: 'Todos los proyectos',
    PROJECT_FILTER_ACTIVE: 'Proyectos Activos',
    PROJECT_FILTER_INACTIVE: 'Proyectos inactivos',
    PROJECT_FILTER_ACTIVE_NAVIGATOR_ONLY: 'Solo navegador de proyectos',
  },
  enUS: {
    INVALID: 'Invalid!',
    PROJECT_FILTER_ALL: 'All Projects',
    PROJECT_FILTER_ACTIVE: 'Active Projects',
    PROJECT_FILTER_INACTIVE: 'Inactive Projects',
    PROJECT_FILTER_ACTIVE_NAVIGATOR_ONLY: 'Project Navigator Only',
  },
  ptBR: {
    INVALID: 'Inválido!',
    PROJECT_FILTER_ALL: 'Todos os Projetos',
    PROJECT_FILTER_ACTIVE: 'Projetos Ativos',
    PROJECT_FILTER_INACTIVE: 'Projetos Inativos',
    PROJECT_FILTER_ACTIVE_NAVIGATOR_ONLY: 'Somente Projetos do Navigator',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

enum ProjectFilters {
  ALL = 'all',
  ACTIVE = 'act',
  INACTIVE = 'ina',
  ACTIVE_NAVIGATOR_ONLY = 'ano',
  COMPLETED = 'com',
}

enum ProjectFiltersText {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ACTIVE_NAVIGATOR_ONLY = 'ACTIVE_NAVIGATOR_ONLY',
  COMPLETED = 'COMPLETED',
}

class ProjectService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT, translations);

  public static filterTextAll: FiltersTextProps<ProjectFiltersText, ProjectFilters> =
    {
      key: ProjectFiltersText.ALL,
      filter: ProjectFilters.ALL,
      label: translate(`PROJECT_FILTER_${ProjectFiltersText.ALL}`).toUpperCase(),
    };
  public static filterTextActive: FiltersTextProps<
    ProjectFiltersText,
    ProjectFilters
  > = {
    key: ProjectFiltersText.ACTIVE,
    filter: ProjectFilters.ACTIVE,
    label: I18nService.translate(
      translations,
      `PROJECT_FILTER_${ProjectFiltersText.ACTIVE}`,
    ).toUpperCase(),
  };
  public static filterTextInactive: FiltersTextProps<
    ProjectFiltersText,
    ProjectFilters
  > = {
    key: ProjectFiltersText.INACTIVE,
    filter: ProjectFilters.INACTIVE,
    label: I18nService.translate(
      translations,
      `PROJECT_FILTER_${ProjectFiltersText.INACTIVE}`,
    ).toUpperCase(),
  };
  public static filterTextActiveNavigatorOnly: FiltersTextProps<
    ProjectFiltersText,
    ProjectFilters
  > = {
    key: ProjectFiltersText.ACTIVE_NAVIGATOR_ONLY,
    filter: ProjectFilters.ACTIVE_NAVIGATOR_ONLY,
    label: I18nService.translate(
      translations,
      `PROJECT_FILTER_${ProjectFiltersText.ACTIVE_NAVIGATOR_ONLY}`,
    ).toUpperCase(),
  };
  public static completed: FiltersTextProps<ProjectFiltersText, ProjectFilters> = {
    key: ProjectFiltersText.COMPLETED,
    filter: ProjectFilters.COMPLETED,
    label: I18nService.translate(
      translations,
      `PROJECT_FILTER_${ProjectFiltersText.COMPLETED}`,
    ).toUpperCase(),
  };

  public static cacheProjectsDropDownData: DropDownData[] = [];

  public static async getProjectsDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await this.api.get<DropDownData[]>(
      `/connect/v1/projects/dropdown`,
    );

    if (apiResult.success) {
      this.cacheProjectsDropDownData = apiResult.data;
    }
    return apiResult;
  }

  public static async getAllProjectsDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await this.api.get<DropDownData[]>(
      `/connect/v1/allprojects/dropdown`,
    );

    if (apiResult.success) {
      this.cacheProjectsDropDownData = apiResult.data;
    }
    return apiResult;
  }

  public static async getNavigatorProjects(
    filter: ProjectFilters,
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<Project>>> {
    const apiResult: ApiResult<ApiGridResult<Project>> = await this.api.post<
      ApiGridResult<Project>
    >(`/connect/v1/projects/filter/${filter}`, gridConfig, {});

    return apiResult;
  }
}

export { ProjectFilters, ProjectFiltersText, ProjectService };

