import classnames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';
import style from './PointCloudViewer.module.scss';

interface BrassPointCloudViewerProps extends PropsWithChildren<any> {
  url: string;
}

const BrassPointCloudViewer: FunctionComponent<BrassPointCloudViewerProps> = (
  props: BrassPointCloudViewerProps,
) => {
  const { url, className } = props;
  const authEnvironment = 'navigator';
  const _url = btoa(url);
  const _className = classnames(
    'brass-point-cloud-viewer',
    style['brass-point-cloud-viewer'],
    style.iframe,
    className,
  );

  return (
    <iframe
      className={_className}
      title='Point Cloud Viewer'
      src={`https://${authEnvironment}.bdbsystem.com.br/viewer.html#${_url}`}
    />
  );
};

export { BrassPointCloudViewer };
export type { BrassPointCloudViewerProps };

