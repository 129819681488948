import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconPleased: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.98668 0.584167C3.02985 0.589571 0.615346 2.977 0.584346 5.94233C0.553161 8.93083 2.95351 11.3833 5.94201 11.4158H5.94214C8.93081 11.447 11.3831 9.04533 11.4156 6.05683V6.05677C11.4468 3.0681 9.04514 0.615769 6.05664 0.584602H6.05651C6.03321 0.584342 6.0099 0.584212 5.98666 0.584342L5.98668 0.584167ZM5.98733 0.960534C6.00901 0.960469 6.03076 0.960599 6.05257 0.960795H6.0527C8.83786 0.989831 11.0684 3.2673 11.0394 6.05263V6.05269C11.0091 8.83786 8.73153 11.0684 5.94636 11.0394H5.94623C3.16107 11.009 0.931899 8.73136 0.960899 5.94619C0.98974 3.18286 3.23207 0.965527 5.98757 0.960527L5.98733 0.960534Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.75116 4.49017C7.3608 4.48678 7.03703 4.80294 7.03313 5.19277V5.19342V5.19368C7.02851 5.58385 7.34563 5.90781 7.7358 5.91171V5.91178H7.73638H7.73664C8.12708 5.91647 8.44959 5.59863 8.45468 5.20911V5.20905V5.20833C8.45806 4.81848 8.14198 4.49368 7.75123 4.4903L7.75116 4.49017ZM7.74791 4.86654C7.93398 4.86816 8.07949 5.01712 8.07824 5.2041V5.20436C8.0757 5.3916 7.92701 5.53776 7.74068 5.53528H7.73989C7.55356 5.53365 7.40708 5.38404 7.40956 5.19771H7.4095V5.197C7.41112 5.01008 7.55884 4.86503 7.74785 4.86666L7.74791 4.86654Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.27968 4.45367C3.88989 4.45028 3.56673 4.7665 3.56164 5.15627V5.15705C3.55826 5.54656 3.87434 5.87125 4.26554 5.87508V5.87515H4.26613H4.26639C4.65682 5.87983 4.97934 5.562 4.98442 5.17255L4.98436 5.17248V5.17157C4.98774 4.7812 4.67042 4.45692 4.27967 4.45354L4.27968 4.45367ZM4.27642 4.83003C4.46249 4.83166 4.60924 4.98121 4.60805 5.1676V5.16786C4.60551 5.35509 4.45682 5.50126 4.27049 5.49878H4.27042H4.26964C4.08187 5.49715 3.93682 5.34819 3.93801 5.16121V5.16095C3.94055 4.97372 4.08911 4.8284 4.27636 4.83003H4.27642Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.19466 7.44017C3.12155 7.43881 3.05424 7.47995 3.02208 7.54564C2.98992 7.6114 2.99877 7.68978 3.0448 7.74668C3.66297 8.53353 4.81797 8.89511 5.96414 8.90337C7.1103 8.91171 8.26914 8.56769 8.90164 7.80996V7.81002C8.93465 7.77181 8.95092 7.72194 8.94682 7.67168C8.94272 7.62135 8.91856 7.5748 8.87983 7.54245C8.84102 7.51009 8.7909 7.49472 8.7407 7.49967C8.69044 7.50462 8.64435 7.52955 8.61264 7.56888C8.09324 8.19128 7.03327 8.53476 5.96697 8.52708C4.90057 8.51939 3.8493 8.16144 3.3408 7.51424V7.51418C3.30597 7.46847 3.25213 7.44126 3.19471 7.44015L3.19466 7.44017Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2.62002 6.44799C2.52236 6.44506 2.43864 6.51726 2.42732 6.61427C2.41599 6.71134 2.48077 6.80085 2.57647 6.82052C2.83317 6.87696 3.02497 7.10607 3.02211 7.38257C3.01924 7.65907 2.82269 7.88439 2.56541 7.9351V7.93504C2.51567 7.94389 2.47146 7.97234 2.44288 8.01401C2.41436 8.05574 2.40375 8.10717 2.41352 8.15678C2.42328 8.20639 2.45258 8.25001 2.4949 8.27775C2.53715 8.30548 2.58878 8.31505 2.63819 8.30431C3.06846 8.21954 3.39386 7.84011 3.39848 7.38641C3.40316 6.93269 3.08553 6.54703 2.65719 6.45288H2.65726C2.64502 6.45001 2.63252 6.44838 2.62002 6.44799H2.62002Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.36334 6.51966C9.35273 6.52012 9.34212 6.52142 9.3317 6.52357C8.9015 6.60834 8.5748 6.98737 8.57012 7.44147C8.56543 7.89519 8.88334 8.27935 9.31069 8.37487H9.31075C9.35971 8.38659 9.4114 8.37813 9.45411 8.35143C9.49682 8.32474 9.52709 8.28203 9.5381 8.23288C9.54903 8.18366 9.53985 8.13216 9.51258 8.08985C9.4853 8.04746 9.44213 8.01784 9.39285 8.00755C9.13517 7.95 8.9437 7.72187 8.94657 7.44539C8.94943 7.16929 9.14715 6.94357 9.40452 6.89285H9.40445C9.50159 6.87508 9.56845 6.78524 9.55764 6.68712C9.54683 6.58901 9.46207 6.5159 9.36337 6.51967L9.36334 6.51966Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconPleased };
