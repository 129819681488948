import React, { FunctionComponent, useEffect, useState } from 'react';
import { translate } from './DisciplinesDropDown.translations';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { UserService } from '../../services/User.service';
import { BrassDropDownField, BrassDropDownFieldProps } from '../../../../components';
import { ApiResult, DropDownData } from '../../../../models';
import { I18nService } from '../../../../services';

interface DisciplinesDropDownProps extends BrassDropDownFieldProps {
  onChange: (e: DropDownListChangeEvent) => void;
}

const DisciplinesDropdown: FunctionComponent<DisciplinesDropDownProps> = (
  props: DisciplinesDropDownProps,
) => {
  const [disciplines, setDisciplines] = useState<DropDownData[]>(
    UserService.cacheDisciplinesDropDownData,
  );
  const [loading, setLoading] = useState<boolean>(disciplines.length === 0);

  useEffect(() => {
    if (!disciplines.length) {
      UserService.getDisciplinesDropDown().then(
        (apiResult: ApiResult<DropDownData[]>) => {
          if (!apiResult.errorMessage) {
            setDisciplines(apiResult.data);
            setLoading(false);
          }
        },
      );
    }
  }, [disciplines.length]);

  return (
    <BrassDropDownField
      {...props}
      loading={loading}
      options={disciplines}
      label={translate('discipline')}
      onChange={props.onChange}
    />
  );
};

export { DisciplinesDropdown };
export type { DisciplinesDropDownProps };
