import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconViewTemplate: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M9.92569 13.4425H13.9555V15.3253H9.92569V13.4425Z' fill={props.fill} />
    <path d='M9.92569 9.67899H13.9555V12.5012H9.92569V9.67899Z' fill={props.fill} />
    <path d='M4.72217 7.06183H8.754V15.3255H4.72217V7.06183Z' fill={props.fill} />
    <path
      d='M14.7149 3.25L13.5743 4.16797L14.0508 4.75782H12.125V6.22267H14.0508L13.5743 6.81252L14.7149 7.73048L16.5196 5.49215L14.7149 3.25Z'
      fill={props.fill}
    />
    <path
      d='M15.4268 11.4465V16.7628H3.25401V5.6185H8.99901V4.33926L2.25301 4.34506C2.11304 4.34506 2.00021 4.47989 2.00021 4.64584L2.01004 17.7512C2.01004 17.9172 2.12567 18.05 2.26766 18.05L16.4222 18.0364C16.5643 18.0364 16.6787 17.9016 16.6787 17.7356L16.6729 11.4466H15.427L15.4268 11.4465Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconViewTemplate };
