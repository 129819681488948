import React, { FunctionComponent } from 'react';
import style from './BadgeContainer.module.scss';
import classnames from 'classnames';
import {
  BadgeContainer,
  BadgeContainerProps,
} from '@progress/kendo-react-indicators';

interface BrassBadgeContainerProps extends BadgeContainerProps {}

const BrassBadgeContainer: FunctionComponent<BrassBadgeContainerProps> = (
  props: BrassBadgeContainerProps,
) => {
  const { className } = props;
  const _className = classnames('brass-badge', style['brass-badge'], className);

  return <BadgeContainer {...props} className={_className} />;
};

export { BrassBadgeContainer };
export type { BrassBadgeContainerProps };
