import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { ExternalIntegrationSubmodule } from './ExternalIntegration.submodule.enum';

enum ExternalIntegrationPermissionsV1 {
  ELFA_READ = PermissionModule.EXTERNAL_INTEGRATION +
    ExternalIntegrationSubmodule.ELFA +
    Action.READ,
  BIM360_READ = PermissionModule.EXTERNAL_INTEGRATION +
    ExternalIntegrationSubmodule.BIM360 +
    Action.READ,
}

export { ExternalIntegrationPermissionsV1 };
