import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { ConnectSubmodule } from './Connect.submodule.enum';

enum ConnectPermissionsV1 {
  /// <summary>
  /// Crud de Account [(FE) /accounts]
  /// </summary>
  ACCOUNT_READ = PermissionModule.CONNECT + ConnectSubmodule.ACCOUNT + Action.READ,
  ACCOUNT_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ACCOUNT +
    Action.CREATE,
  ACCOUNT_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ACCOUNT +
    Action.UPDATE,
  ACCOUNT_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ACCOUNT +
    Action.DELETE,
  ACCOUNT_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ACCOUNT +
    Action.EXPORT,
  /// <summary>
  /// Crud de Usuário [(FE) /home/connect/users]
  /// </summary>
  USER_READ = PermissionModule.CONNECT + ConnectSubmodule.USER + Action.READ,
  USER_CREATE = PermissionModule.CONNECT + ConnectSubmodule.USER + Action.CREATE,
  USER_UPDATE = PermissionModule.CONNECT + ConnectSubmodule.USER + Action.UPDATE,
  USER_DELETE = PermissionModule.CONNECT + ConnectSubmodule.USER + Action.DELETE,
  USER_EXPORT = PermissionModule.CONNECT + ConnectSubmodule.USER + Action.EXPORT,
  /// <summary>
  /// Gestão de Colaboradores [(FE) /home/connect/employees]
  /// </summary>
  EMPLOYEE_READ = PermissionModule.CONNECT + ConnectSubmodule.EMPLOYEE + Action.READ,
  EMPLOYEE_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.EMPLOYEE +
    Action.CREATE,
  EMPLOYEE_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.EMPLOYEE +
    Action.UPDATE,
  EMPLOYEE_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.EMPLOYEE +
    Action.DELETE,
  EMPLOYEE_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.EMPLOYEE +
    Action.EXPORT,
  /// <summary>
  /// Crud de Usuário [(FE) /home/connect/user-group]
  /// </summary>
  USER_GROUP_READ = PermissionModule.CONNECT +
    ConnectSubmodule.USER_GROUP +
    Action.READ,
  USER_GROUP_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.USER_GROUP +
    Action.CREATE,
  USER_GROUP_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.USER_GROUP +
    Action.UPDATE,
  USER_GROUP_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.USER_GROUP +
    Action.DELETE,
  USER_GROUP_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.USER_GROUP +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/operational-calendars -> /home/connect/administrative/operational-calendars]
  /// </summary>
  ADMINISTRATIVE_OPERATIONAL_CALENDAR_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_OPERATIONAL_CALENDAR +
    Action.READ,
  ADMINISTRATIVE_OPERATIONAL_CALENDAR_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_OPERATIONAL_CALENDAR +
    Action.CREATE,
  ADMINISTRATIVE_OPERATIONAL_CALENDAR_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_OPERATIONAL_CALENDAR +
    Action.UPDATE,
  ADMINISTRATIVE_OPERATIONAL_CALENDAR_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_OPERATIONAL_CALENDAR +
    Action.DELETE,
  ADMINISTRATIVE_OPERATIONAL_CALENDAR_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_OPERATIONAL_CALENDAR +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/marital-status]
  /// </summary>
  ADMINISTRATIVE_MARITAL_STATUS_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MARITAL_STATUS +
    Action.READ,
  ADMINISTRATIVE_MARITAL_STATUS_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MARITAL_STATUS +
    Action.CREATE,
  ADMINISTRATIVE_MARITAL_STATUS_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MARITAL_STATUS +
    Action.UPDATE,
  ADMINISTRATIVE_MARITAL_STATUS_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MARITAL_STATUS +
    Action.DELETE,
  ADMINISTRATIVE_MARITAL_STATUS_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MARITAL_STATUS +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/ethnicities]
  /// </summary>
  ADMINISTRATIVE_ETHNICITY_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ETHNICITY +
    Action.READ,
  ADMINISTRATIVE_ETHNICITY_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ETHNICITY +
    Action.CREATE,
  ADMINISTRATIVE_ETHNICITY_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ETHNICITY +
    Action.UPDATE,
  ADMINISTRATIVE_ETHNICITY_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ETHNICITY +
    Action.DELETE,
  ADMINISTRATIVE_ETHNICITY_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ETHNICITY +
    Action.EXPORT,
  /// <summary>
  /// [(FE)/home/connect/administrative/medical-exams]
  /// </summary>
  ADMINISTRATIVE_MEDICAL_EXAM_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM +
    Action.READ,
  ADMINISTRATIVE_MEDICAL_EXAM_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM +
    Action.CREATE,
  ADMINISTRATIVE_MEDICAL_EXAM_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM +
    Action.UPDATE,
  ADMINISTRATIVE_MEDICAL_EXAM_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM +
    Action.DELETE,
  ADMINISTRATIVE_MEDICAL_EXAM_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM +
    Action.EXPORT,
  /// <summary>
  /// [(FE)/home/connect/administrative/medical-exams-scheduler]
  /// </summary>
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER +
    Action.READ,
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER +
    Action.CREATE,
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER +
    Action.UPDATE,
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER +
    Action.DELETE,
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/genders]
  /// </summary>
  ADMINISTRATIVE_GENDER_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_GENDER +
    Action.READ,
  ADMINISTRATIVE_GENDER_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_GENDER +
    Action.CREATE,
  ADMINISTRATIVE_GENDER_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_GENDER +
    Action.UPDATE,
  ADMINISTRATIVE_GENDER_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_GENDER +
    Action.DELETE,
  ADMINISTRATIVE_GENDER_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_GENDER +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/reasons-for-contract-change]
  /// </summary>
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE +
    Action.READ,
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE +
    Action.CREATE,
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE +
    Action.UPDATE,
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE +
    Action.DELETE,
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/nationalities]
  /// </summary>
  ADMINISTRATIVE_NATIONALITY_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_NATIONALITY +
    Action.READ,
  ADMINISTRATIVE_NATIONALITY_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_NATIONALITY +
    Action.CREATE,
  ADMINISTRATIVE_NATIONALITY_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_NATIONALITY +
    Action.UPDATE,
  ADMINISTRATIVE_NATIONALITY_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_NATIONALITY +
    Action.DELETE,
  ADMINISTRATIVE_NATIONALITY_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_NATIONALITY +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/administrative/employee-hiring-level]
  /// </summary>
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL +
    Action.READ,
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL +
    Action.CREATE,
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL +
    Action.UPDATE,
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL +
    Action.DELETE,
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/employment-relationships -> /home/connect/administrative/employment-relationships]
  /// </summary>
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP +
    Action.READ,
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP +
    Action.CREATE,
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP +
    Action.UPDATE,
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP +
    Action.DELETE,
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/attachment-types -> /home/connect/administrative/attachment-types]
  /// </summary>
  ADMINISTRATIVE_ATTACHMENT_TYPE_READ = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ATTACHMENT_TYPE +
    Action.READ,
  ADMINISTRATIVE_ATTACHMENT_TYPE_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ATTACHMENT_TYPE +
    Action.CREATE,
  ADMINISTRATIVE_ATTACHMENT_TYPE_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ATTACHMENT_TYPE +
    Action.UPDATE,
  ADMINISTRATIVE_ATTACHMENT_TYPE_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ATTACHMENT_TYPE +
    Action.DELETE,
  ADMINISTRATIVE_ATTACHMENT_TYPE_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.ADMINISTRATIVE_ATTACHMENT_TYPE +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/config-hrpd/employee-benefits -> /home/connect/human-resources/employee-benefits]
  /// </summary>
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT_READ = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_BENEFIT +
    Action.READ,
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_BENEFIT +
    Action.CREATE,
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_BENEFIT +
    Action.UPDATE,
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_BENEFIT +
    Action.DELETE,
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_BENEFIT +
    Action.EXPORT,
  /// <summary>
  /// [(FE) /home/connect/config-hrpd/employee-positions -> /home/connect/human-resources/employee-positions]
  /// </summary>
  HUMAN_RESOURCES_EMPLOYEE_POSITION_READ = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_POSITION +
    Action.READ,
  HUMAN_RESOURCES_EMPLOYEE_POSITION_CREATE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_POSITION +
    Action.CREATE,
  HUMAN_RESOURCES_EMPLOYEE_POSITION_UPDATE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_POSITION +
    Action.UPDATE,
  HUMAN_RESOURCES_EMPLOYEE_POSITION_DELETE = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_POSITION +
    Action.DELETE,
  HUMAN_RESOURCES_EMPLOYEE_POSITION_EXPORT = PermissionModule.CONNECT +
    ConnectSubmodule.HUMAN_RESOURCES_EMPLOYEE_POSITION +
    Action.EXPORT,
}

const _CONNECT_MODULE = 10000;

enum ConnectPermissions {
  CONNECT_MODULE = _CONNECT_MODULE, // 10.000

  CONNECT_ACCOUNT_READ = CONNECT_MODULE + 101,
  CONNECT_ACCOUNT_CREATE = CONNECT_MODULE + 102,
  CONNECT_ACCOUNT_UPDATE = CONNECT_MODULE + 103,
  CONNECT_ACCOUNT_DELETE = CONNECT_MODULE + 104,
  CONNECT_ACCOUNT_EXPORT = CONNECT_MODULE + 105,
  CONNECT_PROJECT_READ = CONNECT_MODULE + 201,
  CONNECT_PROJECT_CREATE = CONNECT_MODULE + 202,
  CONNECT_PROJECT_UPDATE = CONNECT_MODULE + 203,
  CONNECT_PROJECT_DELETE = CONNECT_MODULE + 204,
  CONNECT_PROJECT_EXPORT = CONNECT_MODULE + 205,
  CONNECT_AREA_READ = CONNECT_MODULE + 301,
  CONNECT_AREA_CREATE = CONNECT_MODULE + 302,
  CONNECT_AREA_UPDATE = CONNECT_MODULE + 303,
  CONNECT_AREA_DELETE = CONNECT_MODULE + 304,
  CONNECT_AREA_EXPORT = CONNECT_MODULE + 305,
  CONNECT_SYSTEM_READ = CONNECT_MODULE + 401,
  CONNECT_SYSTEM_CREATE = CONNECT_MODULE + 402,
  CONNECT_SYSTEM_UPDATE = CONNECT_MODULE + 403,
  CONNECT_SYSTEM_DELETE = CONNECT_MODULE + 404,
  CONNECT_SYSTEM_EXPORT = CONNECT_MODULE + 405,
  CONNECT_PROJECTPHASES_READ = CONNECT_MODULE + 406,
  CONNECT_PROJECTPHASES_CREATE = CONNECT_MODULE + 407,
  CONNECT_PROJECTPHASES_UPDATE = CONNECT_MODULE + 408,
  CONNECT_PROJECTPHASES_DELETE = CONNECT_MODULE + 409,
  CONNECT_PROJECTPHASES_EXPORT = CONNECT_MODULE + 410,
  CONNECT_FINANCEESTATISTICS_READ = CONNECT_MODULE + 411,
  CONNECT_PROJECT_PLANNING_READ = CONNECT_MODULE + 412,
  CONNECT_PROJECT_PLANNING_CREATE = CONNECT_MODULE + 413,
  CONNECT_PROJECT_PLANNING_UPDATE = CONNECT_MODULE + 414,
  CONNECT_PROJECT_PLANNING_DELETE = CONNECT_MODULE + 415,
  CONNECT_PROJECT_PLANNING_EXPORT = CONNECT_MODULE + 416,
  // ----------------------------------------------------------------------------
  // Permite acesso do RHT
  CONNECT_WORKTIMERECORD_READ = CONNECT_MODULE + 501,
  // Permite ao usuário ler o RHT de outro usuário
  CONNECT_WORKTIMERECORD_READ_OTHER = CONNECT_MODULE + 502,
  CONNECT_WORKTIMERECORD_CREATE = CONNECT_MODULE + 503,
  CONNECT_WORKTIMERECORD_UPDATE = CONNECT_MODULE + 504,
  CONNECT_WORKTIMERECORD_DELETE = CONNECT_MODULE + 505,
  CONNECT_WORKTIMERECORD_APPROVAL = CONNECT_MODULE + 506,
  // permissoes para edicao de fases (etapas) de projeto.
  CONNECT_WORKTIMERECORD_PROJECTPHASE_READ = CONNECT_MODULE + 507,
  CONNECT_WORKTIMERECORD_PROJECTPHASE_CREATE = CONNECT_MODULE + 508,
  CONNECT_WORKTIMERECORD_PROJECTPHASE_UPDATE = CONNECT_MODULE + 509,
  CONNECT_WORKTIMERECORD_PROJECTPHASE_DELETE = CONNECT_MODULE + 510,
  // permissoes para edicao de atividades na etaoa de projeto.
  CONNECT_WORKTIMERECORD_PROJECTPHASEACTIVITY_READ = CONNECT_MODULE + 511,
  CONNECT_WORKTIMERECORD_PROJECTPHASEACTIVITY_CREATE = CONNECT_MODULE + 512,
  CONNECT_WORKTIMERECORD_PROJECTPHASEACTIVITY_UPDATE = CONNECT_MODULE + 513,
  CONNECT_WORKTIMERECORD_PROJECTPHASEACTIVITY_DELETE = CONNECT_MODULE + 514,
  // Aprovação de Lider de Projeto no RHT
  CONNECT_WORKTIMERECORD_PROJECT_LEADER_READ = CONNECT_MODULE + 515,
  CONNECT_WORKTIMERECORD_PROJECT_LEADER_UPDATE = CONNECT_MODULE + 517,
  CONNECT_WORKTIMERECORD_ADMINISTRATIVE_RECORD = CONNECT_MODULE + 518,
  // permissao para o lider de projeto aprovar rht de outro usuario
  CONNECT_WORKTIMERECORD_LEADER_APPROVAL = CONNECT_MODULE + 519,
  CONNECT_WORKTIMERECORD_EXPORT = CONNECT_MODULE + 520,
  // gestor do projeto
  CONNECT_WORKTIMERECORD_PROJECT_MANAGER = CONNECT_MODULE + 522,
  CONNECT_WORKTIMERECORD_PROJECT_MANAGER_LIST = CONNECT_MODULE + 523,
  CONNECT_WORKTIMERECORD_PROJECT_MANAGER_COMMENT = CONNECT_MODULE + 524,
  // ----------------------------------------------------------------------------
  CONNECT_SYSTEMCLASSIFICATION_READ = CONNECT_MODULE + 601,
  CONNECT_SYSTEMCLASSIFICATION_CREATE = CONNECT_MODULE + 602,
  CONNECT_SYSTEMCLASSIFICATION_UPDATE = CONNECT_MODULE + 603,
  CONNECT_SYSTEMCLASSIFICATION_DELETE = CONNECT_MODULE + 604,
  CONNECT_SYSTEMCLASSIFICATION_EXPORT = CONNECT_MODULE + 605,
  CONNECT_ACTIVITY_READ = CONNECT_MODULE + 701,
  CONNECT_ACTIVITY_CREATE = CONNECT_MODULE + 702,
  CONNECT_ACTIVITY_UPDATE = CONNECT_MODULE + 703,
  CONNECT_ACTIVITY_DELETE = CONNECT_MODULE + 704,
  CONNECT_EMPLOYMENT_POSITION_READ = CONNECT_MODULE + 801,
  CONNECT_EMPLOYMENT_POSITION_CREATE = CONNECT_MODULE + 802,
  CONNECT_EMPLOYMENT_POSITION_UPDATE = CONNECT_MODULE + 803,
  CONNECT_EMPLOYMENT_POSITION_DELETE = CONNECT_MODULE + 804,
  CONNECT_EMPLOYMENT_POSITION_EXPORT = CONNECT_MODULE + 805,
  CONNECT_ACCOUNTTEMPLATE_READ = CONNECT_MODULE + 901,
  CONNECT_ACCOUNTTEMPLATE_CREATE = CONNECT_MODULE + 902,
  CONNECT_ACCOUNTTEMPLATE_UPDATE = CONNECT_MODULE + 903,
  CONNECT_ACCOUNTTEMPLATE_DELETE = CONNECT_MODULE + 904,
  CONNECT_EMPLOYMENTRELATIONSHIP_READ = CONNECT_MODULE + 1001,
  CONNECT_EMPLOYMENTRELATIONSHIP_CREATE = CONNECT_MODULE + 1002,
  CONNECT_EMPLOYMENTRELATIONSHIP_UPDATE = CONNECT_MODULE + 1003,
  CONNECT_EMPLOYMENTRELATIONSHIP_DELETE = CONNECT_MODULE + 1004,
  CONNECT_EMPLOYMENTRELATIONSHIPCALENDAR_READ = CONNECT_MODULE + 1011,
  CONNECT_EMPLOYMENTRELATIONSHIPCALENDAR_CREATE = CONNECT_MODULE + 1012,
  CONNECT_EMPLOYMENTRELATIONSHIPCALENDAR_UPDATE = CONNECT_MODULE + 1013,
  CONNECT_EMPLOYMENTRELATIONSHIPCALENDAR_DELETE = CONNECT_MODULE + 1014,
  CONNECT_USER_READ = CONNECT_MODULE + 1101,
  CONNECT_USER_CREATE = CONNECT_MODULE + 1102,
  CONNECT_USER_UPDATE = CONNECT_MODULE + 1103,
  CONNECT_USER_DELETE = CONNECT_MODULE + 1104,
  CONNECT_USER_ACTIVATE = CONNECT_MODULE + 1105,
  CONNECT_USER_DEACTIVATE = CONNECT_MODULE + 1106,
  CONNECT_USER_VIEW_BANKDATA = CONNECT_MODULE + 1107,
  CONNECT_USER_UPDATE_BANKDATA = CONNECT_MODULE + 1108,
  CONNECT_USER_DELETE_BANKDATA = CONNECT_MODULE + 1109,
  CONNECT_USER_CREATE_BANKDATA = CONNECT_MODULE + 1110,
  CONNECT_USER_HIRINGDATA_READ = CONNECT_MODULE + 1111,
  CONNECT_USER_HIRINGDATA_CREATE = CONNECT_MODULE + 1112,
  CONNECT_USER_INCLUDE_ALL_PROJECTS = CONNECT_MODULE + 1113,
  CONNECT_USER_EXCLUDE_ALL_PROJECTS = CONNECT_MODULE + 1114,
  CONNECT_USER_CHANGE_PASSWORD = CONNECT_MODULE + 1115,
  CONNECT_USER_CREATE_SHADOW_PASSWORD = CONNECT_MODULE + 1116,
  CONNECT_USER_TEAM_LEADER_READ = CONNECT_MODULE + 1117,
  CONNECT_USER_TEAM_LEADER_EXPORT = CONNECT_MODULE + 1118,
  CONNECT_FILETYPE_READ = CONNECT_MODULE + 1201,
  CONNECT_FILETYPE_CREATE = CONNECT_MODULE + 1202,
  CONNECT_FILETYPE_UPDATE = CONNECT_MODULE + 1203,
  CONNECT_FILETYPE_DELETE = CONNECT_MODULE + 1204,
  CONNECT_FILETYPE_EXPORT = CONNECT_MODULE + 1205,
  CONNECT_USERGROUP_READ = CONNECT_MODULE + 1301,
  CONNECT_USERGROUP_CREATE = CONNECT_MODULE + 1302,
  CONNECT_USERGROUP_UPDATE = CONNECT_MODULE + 1303,
  CONNECT_USERGROUP_DELETE = CONNECT_MODULE + 1304,
  CONNECT_FINANCE_PROCESS_PAYCHECK = CONNECT_MODULE + 1401,
  CONNECT_FINANCE_WORKTIMERECORD_LIST = CONNECT_MODULE + 1402,
  CONNECT_FINANCE_WORKTIMERECORD_APPROVE = CONNECT_MODULE + 1403,
  CONNECT_FINANCE_WORKTIMERECORD_STATUS_UPDATE = CONNECT_MODULE + 1404,
  CONNECT_FINANCE_WORKTIMERECORD_CANCEL = CONNECT_MODULE + 1405,
  CONNECT_FINANCE_INVOICE_READ = CONNECT_MODULE + 1406,
  CONNECT_FINANCE_INVOICE_LIST = CONNECT_MODULE + 1407,
  CONNECT_FINANCE_INVOICE_CREATE = CONNECT_MODULE + 1408,
  CONNECT_FINANCE_WORKTIMERECORD_CONSOLIDATE = CONNECT_MODULE + 1409,
  CONNECT_FINANCE_LIST_MY_PAYCHECKS = CONNECT_MODULE + 1410,
  CONNECT_FINANCE_AJUST_BALLANCE_HOURS = CONNECT_MODULE + 1411,
  CONNECT_ETHNICITIES_CREATE = CONNECT_MODULE + 1501,
  CONNECT_ETHNICITIES_READ = CONNECT_MODULE + 1502,
  CONNECT_ETHNICITIES_DELETE = CONNECT_MODULE + 1503,
  CONNECT_ETHNICITIES_UPDATE = CONNECT_MODULE + 1504,
  CONNECT_MEDICAL_EXAM_READ = CONNECT_MODULE + 1601,
  CONNECT_MEDICAL_EXAM_CREATE = CONNECT_MODULE + 1602,
  CONNECT_MEDICAL_EXAM_UPDATE = CONNECT_MODULE + 1603,
  CONNECT_MEDICAL_EXAM_DELETE = CONNECT_MODULE + 1604,
  CONNECT_MEDICAL_EXAM_SCHEDULER_READ = CONNECT_MODULE + 1605,
  CONNECT_MEDICAL_EXAM_SCHEDULER_CREATE = CONNECT_MODULE + 1606,
  CONNECT_MEDICAL_EXAM_SCHEDULER_UPDATE = CONNECT_MODULE + 1607,
  CONNECT_MEDICAL_EXAM_SCHEDULER_DELETE = CONNECT_MODULE + 1608,
  CONNECT_CONTRACT_CHANGE_MOTIVATION_READ = CONNECT_MODULE + 1701,
  CONNECT_CONTRACT_CHANGE_MOTIVATION_CREATE = CONNECT_MODULE + 1702,
  CONNECT_CONTRACT_CHANGE_MOTIVATION_UPDATE = CONNECT_MODULE + 1703,
  CONNECT_CONTRACT_CHANGE_MOTIVATION_DELETE = CONNECT_MODULE + 1704,
  CONNECT_EMPLOYMENT_BENEFICTS_READ = CONNECT_MODULE + 1801,
  CONNECT_EMPLOYMENT_BENEFICTS_CREATE = CONNECT_MODULE + 1802,
  CONNECT_EMPLOYMENT_BENEFICTS_UPDATE = CONNECT_MODULE + 1803,
  CONNECT_EMPLOYMENT_BENEFICTS_DELETE = CONNECT_MODULE + 1804,
  CONNECT_GENDER_READ = CONNECT_MODULE + 1901,
  CONNECT_GENDER_CREATE = CONNECT_MODULE + 1902,
  CONNECT_GENDER_UPDATE = CONNECT_MODULE + 1903,
  CONNECT_GENDER_DELETE = CONNECT_MODULE + 1904,
  CONNECT_HIRING_LEVEL_READ = CONNECT_MODULE + 2101,
  CONNECT_HIRING_LEVEL_CREATE = CONNECT_MODULE + 2102,
  CONNECT_HIRING_LEVEL_UPDATE = CONNECT_MODULE + 2103,
  CONNECT_HIRING_LEVEL_DELETE = CONNECT_MODULE + 2104,
  CONNECT_NATIONALITY_READ = CONNECT_MODULE + 2201,
  CONNECT_NATIONALITY_CREATE = CONNECT_MODULE + 2202,
  CONNECT_NATIONALITY_UPDATE = CONNECT_MODULE + 2203,
  CONNECT_NATIONALITY_DELETE = CONNECT_MODULE + 2204,
  CONNECT_MARITAL_STATUS_READ = CONNECT_MODULE + 2301,
  CONNECT_MARITAL_STATUS_CREATE = CONNECT_MODULE + 2302,
  CONNECT_MARITAL_STATUS_UPDATE = CONNECT_MODULE + 2303,
  CONNECT_MARITAL_STATUS_DELETE = CONNECT_MODULE + 2304,
  CONNECT_ATTACHMENT_TYPE_READ = CONNECT_MODULE + 2401,
  CONNECT_ATTACHMENT_TYPE_CREATE = CONNECT_MODULE + 2402,
  CONNECT_ATTACHMENT_TYPE_UPDATE = CONNECT_MODULE + 2403,
  CONNECT_ATTACHMENT_TYPE_DELETE = CONNECT_MODULE + 2404,
  CONNECT_BANK_READ = CONNECT_MODULE + 2501,
  CONNECT_BANK_CREATE = CONNECT_MODULE + 2502,
  CONNECT_BANK_UPDATE = CONNECT_MODULE + 2503,
  CONNECT_BANK_DELETE = CONNECT_MODULE + 2504,
  // relatorios em que aparecam custos
  CONNECT_REPORT_CUSTO_INCORRIDO = CONNECT_MODULE + 2601,
  CONNECT_REPORT_PAGAMENTO_PJ = CONNECT_MODULE + 2602,
  CONNECT_REPORT_TEMPO_INCORRIDO = CONNECT_MODULE + 2603,
  CONNECT_REPORT_ATIVIDADES_EXISTENTES = CONNECT_MODULE + 2604,
  CONNECT_REPORT_COLABORADORES_DISCIPLINA = CONNECT_MODULE + 2605,
  CONNECT_LANCAMENTOS_USUARIO_PERIODO = CONNECT_MODULE + 2606,
  CONNECT_PROJETOS_STATUS = CONNECT_MODULE + 2607,
  CONNECT_COLABORADORES_ATIVOS_INATIVOS = CONNECT_MODULE + 2608,
  CONNECT_EXECUTED_USER_ACTIVITIES_WITH_COST = CONNECT_MODULE + 2609,
  CONNECT_EXECUTED_USER_ACTIVITIES_WITHOUT_COST = CONNECT_MODULE + 2610,
  CONNECT_WORKTIMERECORD_FOLLOW_UP = CONNECT_MODULE + 2611,
  CONNECT_REPORT_COMPENSATORY_TIME_OFF = CONNECT_MODULE + 2612,
  CONNECT_REPORT_ATIVIDADES_CUSTO_INCORRIDO = CONNECT_MODULE + 2613,
  CONNECT_EMPLOYEE_READ = CONNECT_MODULE + 2701,
  CONNECT_EMPLOYEE_CREATE = CONNECT_MODULE + 2702,
  CONNECT_EMPLOYEE_UPDATE = CONNECT_MODULE + 2703,
  CONNECT_EMPLOYEE_DELETE = CONNECT_MODULE + 2704,
  CONNECT_EMPLOYEE_ATTACHMENT_READ = CONNECT_MODULE + 2705,
  CONNECT_EMPLOYEE_ATTACHMENT_CREATE = CONNECT_MODULE + 2706,
  CONNECT_EMPLOYEE_ATTACHMENT_UPDATE = CONNECT_MODULE + 2707,
  CONNECT_EMPLOYEE_ATTACHMENT_DELETE = CONNECT_MODULE + 2708,
  CONNECT_EMPLOYEE_EXPORT = CONNECT_MODULE + 2709,
  // Pedidos de Horas-Extras
  CONNECT_OVERTIME_READ = CONNECT_MODULE + 2801,
  CONNECT_OVERTIME_APPROVE_MY_TEAM_REQUEST = CONNECT_MODULE + 2802,
  CONNECT_OVERTIME_EXPORT = CONNECT_MODULE + 2803,
  // permissoes administrativas gerais
  CONNECT_ADMINISTRATIVE_READ = CONNECT_MODULE + 2901,
  CONNECT_AREA_LEADER = CONNECT_MODULE + 2902,
  CONNECT_DISCIPLINE_LEADER = CONNECT_MODULE + 2902,
  // sistemas externos
  CONNECT_ELFA = CONNECT_MODULE + 3001,
  CONNECT_BIM360 = CONNECT_MODULE + 3002,
  CONNECT_WORK_TIME_RECORD_PROJECT_PHASES_READ = CONNECT_MODULE + 3101,
  CONNECT_WORK_TIME_RECORD_PROJECT_PHASES_CREATE = CONNECT_MODULE + 3102,
  CONNECT_WORK_TIME_RECORD_PROJECT_PHASES_UPDATE = CONNECT_MODULE + 3103,
  CONNECT_WORK_TIME_RECORD_PROJECT_PHASES_DELETE = CONNECT_MODULE + 3104,
  CONNECT_TEMPLATE_MANAGEMENT_READ = CONNECT_MODULE + 3201,
  CONNECT_TEMPLATE_MANAGEMENT_CREATE = CONNECT_MODULE + 3202,
  CONNECT_TEMPLATE_MANAGEMENT_UPDATE = CONNECT_MODULE + 3203,
  CONNECT_TEMPLATE_MANAGEMENT_DELETE = CONNECT_MODULE + 3204,
  CONNECT_PROJECT_BUDGET_READ = CONNECT_MODULE + 3301,
  CONNECT_VACANCY_APPROVAL = CONNECT_MODULE + 3401,
  /// <summary>
  /// Cadastro de Gestores [(FE) /home/connect/register-managers]
  /// <summary>
  CUSTOMER_MANAGERS_READ = PermissionModule.CONNECT +
    ConnectSubmodule.PROJECT_CUSTOMER +
    Action.READ,
  CUSTOMER_MANAGERS_CREATE = PermissionModule.PROJECT +
    ConnectSubmodule.PROJECT_CUSTOMER +
    Action.CREATE,
  CUSTOMER_MANAGERS_UPDATE = PermissionModule.PROJECT +
    ConnectSubmodule.PROJECT_CUSTOMER +
    Action.UPDATE,
  CUSTOMER_MANAGERS_DELETE = PermissionModule.PROJECT +
    ConnectSubmodule.PROJECT_CUSTOMER +
    Action.DELETE,
  CUSTOMER_MANAGERS_EXPORT = PermissionModule.PROJECT +
    ConnectSubmodule.PROJECT_CUSTOMER +
    Action.EXPORT,
  READ_PROTECTED_LGPD_INFO = PermissionModule.CONNECT +
    ConnectSubmodule.READ_PROTECTED_LGPD_INFO +
    Action.READ,
}

enum PersonalDepartmentPermissions {
  PERSONAL_DEPARTMENT_MODULE = 'PERSONAL_DEPARTMENT_MODULE',
}

export { ConnectPermissions, ConnectPermissionsV1, PersonalDepartmentPermissions };
