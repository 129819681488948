import React, {
  FunctionComponent,
  useState,
  Fragment,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { translate } from './UserGroupGridOtherPrivileges.translations';
import { GridColumn } from '@progress/kendo-react-grid'; // CHANGE
import styles from './UserGroupGridOtherPrivileges.module.scss';
import {
  AccessDenied,
  BrassButton,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassTreeListGrid,
  BrassIconUser,
} from '../../../../../../../components';
import { AuthUser, ConnectPermissions } from '../../../../../../../models';
import { PermissionService } from '../../../../../../../services';
import { ApiGridResult, DropDownData } from '../../../../../../../';
import { UserGroupData } from '../../../../../../../modules';

interface UserGroupGridOtherPrivilegesProps {
  setAllowSubmit: Dispatch<SetStateAction<boolean>>;
  userData: UserGroupData;
  setUserData: Dispatch<SetStateAction<UserGroupData>>;
}

const UserGroupGridOtherPrivileges: FunctionComponent<
  UserGroupGridOtherPrivilegesProps
> = (props: UserGroupGridOtherPrivilegesProps) => {
  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [selectedPrivileges, setSelectedPrivileges] = useState<number[] | string[]>(
    [],
  );
  const [otherProvilegesList, setOtherProvilegesList] = useState<
    ApiGridResult<DropDownData<any>>
  >({
    records: PermissionService.permissions.map((permission: any) => {
      return {
        value: permission.value,
        text: permission.text,
        id: permission.value,
      };
    }),
    total: PermissionService.permissions.length,
  });
  const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>({
    ...BrassGridConfigDefault,
    take: 99999,
  });

  const loadGridData = (): void => {
    setGridLoading(true);
    const op = {
      records: PermissionService.permissions.map((permission: any) => {
        return {
          value: permission.value,
          text: permission.text,
          id: permission.value,
        };
      }),
      total: PermissionService.permissions.length - props.userData.privileges.length,
    };

    const newListPrivileges = op.records!.filter(
      (privilege: any) =>
        !props.userData.privileges.includes(privilege.value as never),
    );
    setOtherProvilegesList({
      records: newListPrivileges,
      total: newListPrivileges.length,
    });
    setGridLoading(false);
  };

  useEffect(loadGridData, [props.userData.privileges]);

  const insertPrivileges = (): void => {
    setGridLoading(true);
    let privileges = props.userData.privileges;
    privileges = privileges.concat(...(selectedPrivileges as number[]));
    props.setUserData({
      ...props.userData,
      privileges,
    });

    const op = otherProvilegesList.records!.filter(
      (privilege: any) => !selectedPrivileges.includes(privilege.value as never),
    );

    setOtherProvilegesList({
      ...otherProvilegesList,
      records: op,
    });
    props.setAllowSubmit(true);
    setGridLoading(false);
    setSelectedPrivileges([]);
  };

  const columns = [
    <GridColumn
      key='value'
      field='value'
      title={translate('value')}
      columnMenu={BrassGridColumnMenu}
      width='180'
    />,
    <GridColumn
      key='text'
      field='text'
      title={translate('text')}
      columnMenu={BrassGridColumnMenu}
      width={gridConfig.group?.length ? '200' : 'auto'}
    />,
  ];

  if (!AuthUser.checkPermission(ConnectPermissions.CONNECT_USERGROUP_READ)) {
    return <AccessDenied />;
  }

  return (
    <Fragment>
      <BrassTreeListGrid
        dataItemKey='id'
        className={styles.grid}
        visibleButtonRefresh={false}
        columns={columns}
        gridData={otherProvilegesList}
        gridLoading={gridLoading}
        setGridLoading={setGridLoading}
        gridTitle={translate('privilegesTitle')}
        gridIcon={<BrassIconUser />}
        gridConfig={gridConfig}
        updateGridConfig={setGridConfig}
        updateGridData={setOtherProvilegesList}
        toolbarBeforeActions={[
          <BrassButton
            type='button'
            disabled={selectedPrivileges.length === 0}
            key='brassbutton'
            onClick={insertPrivileges}
          >
            {translate('insert', [
              selectedPrivileges.length > 0
                ? selectedPrivileges.length.toString()
                : '',
            ])}
          </BrassButton>,
        ]}
        selectable={{
          enabled: true,
          mode: 'multiple',
        }}
        onSelectionChange={setSelectedPrivileges}
        onHeaderSelectionChange={setSelectedPrivileges}
        pageable={false}
      />
    </Fragment>
  );
};

export { UserGroupGridOtherPrivileges };
