import React, { FunctionComponent, useState } from 'react';
import style from './RadioGroupButton.module.scss';
import { BrassButtonSharedPropsv2, BrassButtonSharedV2 } from '../../shared';
import { II18n } from '../../../models';
import { RadioButtonProps } from '@progress/kendo-react-inputs';

const translations: II18n = {
  es: {
    required: 'Este campo é obrigatório',
  },
  enUS: {
    required: 'This field is required',
  },
  ptBR: {
    required: 'Este campo é obrigatório',
  },
};

interface BrassRadioGroupButtonProps extends RadioButtonProps {
  data: any[];
  buttonProps?: BrassButtonSharedPropsv2;
  buttonStyle?: any;
  defaultValue?: any;
  divStyle?: any;
  onChange: (newValue: any) => void;
}

const BrassRadioGroupButton: FunctionComponent<BrassRadioGroupButtonProps> = (
  props: BrassRadioGroupButtonProps,
) => {
  const { data, buttonProps, buttonStyle, defaultValue, disabled, onChange } = props;
  const [selectedValue, setSelectedValue] = useState<any | undefined>(defaultValue);

  const handleChange = (newValue: string) => {
    if (newValue === selectedValue) {
      setSelectedValue(undefined);
      onChange(undefined);
    } else {
      setSelectedValue(newValue);
      onChange(newValue);
    }
  };

  const getThemeColor = (
    item: any,
    isSelected: boolean,
  ):
    | 'base'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'dark'
    | 'light'
    | 'inverse'
    | null
    | undefined => {
    if (item.backgroundColorOn && item.textColorOn) {
      return isSelected ? null : 'base';
    } else {
      return isSelected ? 'primary' : 'base';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {data.map((item: any, index: number) => {
        const isSelected = selectedValue === item.value;
        return (
          <div
            style={
              props.divStyle || {
                flex: '1 1 21%',
                margin: '5px 10px 5px 0',
              }
            }
            className={style['toggable-button']}
            key={'Button' + index.toString()}
          >
            <BrassButtonSharedV2
              togglable
              type='button'
              onClick={() => handleChange(item.value)}
              selected={isSelected}
              labelKey={item.label}
              key={item.label}
              translations={translations}
              themeColor={getThemeColor(item, isSelected)}
              style={{
                backgroundColor:
                  getThemeColor(item, isSelected) === null
                    ? item.backgroundColorOn
                    : item.backgroundColorOff || 'unset',
                color:
                  getThemeColor(item, isSelected) === null
                    ? item.textColorOn
                    : item.textColorOff || 'unset',
                borderColor:
                  getThemeColor(item, isSelected) === null
                    ? item.textColorOn
                    : item.textColorOff || 'unset',
                width: '100%',
                ...buttonStyle,
              }}
              value={selectedValue}
              disabled={disabled}
              {...buttonProps}
            />
          </div>
        );
      })}
    </div>
  );
};

export { BrassRadioGroupButton };
export type { BrassRadioGroupButtonProps };
