import React, {
  FunctionComponent,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { ViewerService } from '../services/viewer.service';
import { NavigatorFileViewer, RequestNavigatorFileParams } from '../models/viewer';
import { LocalStorageService } from '../services';
import { ApiResult } from '../models';
import { parseBool, emptyGuid } from '../util';

enum ViewerDialogs {
  AREA = 'AREA',
  SYSTEM = 'SYSTEM',
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  DOCUMENT_INFORMATION = 'DOCUMENT_INFORMATION',
  SHARE_DOCUMENTS = 'SHARE_DOCUMENTS',
}

interface ViewerContextV1Props {
  projectId?: string;
  areaId?: string;
  systemId?: string;
  fileId?: string;

  loadingViewer: boolean;
  loadingBottomPanelData: boolean;

  isLoadingTab: boolean;
  setIsLoadingTab: Dispatch<SetStateAction<boolean>>;

  showBottomPanel: boolean;
  showSidePanel: boolean;

  navigatorFile: NavigatorFileViewer;
  setNavigatorFile: Dispatch<SetStateAction<NavigatorFileViewer>>;

  showHideDialog: (dialog: ViewerDialogs) => void;
  setShowSidePanel: (arg0: boolean) => void;
  showHideBottomPanel: () => void;
  showHideUploadDocument: () => void;
  showHideAssetManagement: () => void;
  onEdit: () => void;
  onShare: (emailList: string[]) => void;
  onCreateShareLink: () => Promise<string>;
  onAddDocument: () => void;
  onAddAsset: () => void;
  onAddSubarea: () => void;
  onAddSystem: () => void;
  loadBottomPanelData: () => void;

  bottomPanelBarFilterValue: string;
  onBottomPanelBarSearchInputChange: (value: string) => void;
}

interface ViewerContextV1ProviderProps extends PropsWithChildren<any> {
  projectId?: string;
  areaId?: string;
  systemId?: string;
  fileId?: string;
}

const ViewerContextV1 = createContext<ViewerContextV1Props>({
  projectId: emptyGuid(),
  areaId: emptyGuid(),
  systemId: emptyGuid(),
  fileId: emptyGuid(),
  loadingViewer: true,
  loadingBottomPanelData: true,
  isLoadingTab: false,
  setIsLoadingTab: () => {},
  showBottomPanel: false,
  showSidePanel: false,
  navigatorFile: new NavigatorFileViewer({}),
  setNavigatorFile: () => {},
  setShowSidePanel: () => {},
  showHideDialog: (_: ViewerDialogs) => {},
  showHideBottomPanel: () => {},
  showHideUploadDocument: () => {},
  showHideAssetManagement: () => {},
  onEdit: () => {},
  onShare: (_: string[]) => {},
  onCreateShareLink: () => new Promise(() => {}),
  onAddDocument: () => {},
  onAddAsset: () => {},
  onAddSubarea: () => {},
  onAddSystem: () => {},
  loadBottomPanelData: () => {},
  bottomPanelBarFilterValue: '',
  onBottomPanelBarSearchInputChange: (_: string) => {},
});

const ViewerContextV1Provider: FunctionComponent<ViewerContextV1ProviderProps> = (
  props: ViewerContextV1ProviderProps,
) => {
  const { projectId, areaId, systemId, fileId } = props;
  const dispatch = useDispatch();
  const [loadingViewer, setLoadingViewer] = useState<boolean>(true);
  const [loadingBottomPanelData, setLoadingBottomPanelData] =
    useState<boolean>(true);
  const [isLoadingTab, setIsLoadingTab] = useState<boolean>(false);
  const lsIniticalStateShowDetails = LocalStorageService.getLocalStorage(
    'navigator-viewer-show-bottom-panel',
  );
  const initicalStateShowDetails = (lsIniticalStateShowDetails || 'false') as string;

  const lsShowDetails = parseBool(initicalStateShowDetails);
  const [showBottomPanel, setShowBottomPanel] = useState<boolean>(lsShowDetails);

  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
  const [showAddDocumentDialog, setShowAddDocumentDialog] = useState<boolean>(false);
  const [showAddAssetDialog, setShowAddAssetDialog] = useState<boolean>(false);
  const [showViewedDocumentSharingDialog, setShowViewedDocumentSharingDialog] =
    useState<boolean>(false);
  const [showDocumentInformationsDialog, setShowDocumentInformationsDialog] =
    useState<boolean>(false);
  const [showAddAreaDialog, setShowAddAreaDialog] = useState<boolean>(false);
  const [showAddSystemDialog, setShowAddSystemDialog] = useState<boolean>(false);
  const [navigatorFile, setNavigatorFile] = useState<NavigatorFileViewer>(
    new NavigatorFileViewer({}),
  );

  const showHideBottomPanel = (): void => {
    setShowBottomPanel((previousState) => !previousState);
    LocalStorageService.setLocalStorage(
      'navigator-viewer-show-bottom-panel',
      showBottomPanel.toString(),
    );
  };

  const showHideDialog = (dialog: ViewerDialogs): void => {
    switch (dialog) {
      case ViewerDialogs.AREA:
        setShowAddAreaDialog(!showAddAreaDialog);
        return;

      case ViewerDialogs.SYSTEM:
        setShowAddSystemDialog(!showAddSystemDialog);
        return;

      case ViewerDialogs.DOWNLOAD:
        closeAllSidePanels();
        setShowSidePanel(!showDownloadDialog);
        setShowDownloadDialog(!showDownloadDialog);
        return;

      case ViewerDialogs.UPLOAD:
        closeAllSidePanels();
        setShowSidePanel(!showUploadDialog);
        setShowUploadDialog(!showUploadDialog);
        return;

      case ViewerDialogs.UPLOAD_DOCUMENT:
        closeAllSidePanels();
        setShowSidePanel(!showUploadDialog);
        setShowUploadDialog(!showUploadDialog);
        return;

      case ViewerDialogs.DOCUMENT_INFORMATION:
        closeAllSidePanels();
        setShowSidePanel(!showDocumentInformationsDialog);
        setShowDocumentInformationsDialog(!showDocumentInformationsDialog);
        return;

      case ViewerDialogs.SHARE_DOCUMENTS:
        closeAllSidePanels();
        setShowSidePanel(!showViewedDocumentSharingDialog);
        setShowViewedDocumentSharingDialog(!showViewedDocumentSharingDialog);
        return;

      default:
        return;
    }
  };

  const closeAllSidePanels = (): void => {
    setShowDownloadDialog(false);
    setShowAddDocumentDialog(false);
    setShowAddAssetDialog(false);
    setShowUploadDialog(false);
    setShowDocumentInformationsDialog(false);
    setShowViewedDocumentSharingDialog(false);
    setShowSidePanel(false);
  };

  const showHideEditDialog = (): void => setShowEditDialog(!showEditDialog);

  const showHideShareDialog = (): void => setShowShareDialog(!showShareDialog);

  const showHideUploadDocument = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showAddDocumentDialog);
    setShowAddDocumentDialog(!showAddDocumentDialog);
  };
  const showHideAssetManagement = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showAddAssetDialog);
    setShowAddAssetDialog(!showAddAssetDialog);
  };

  const [bottomPanelBarFilterValue, setDetailsViewerBarFilterValue] =
    useState<string>('');

  const onEdit = (): void => {
    setShowEditDialog(!showEditDialog);
  };

  const onShare = async (emailList: string[]): Promise<void> => {
    const apiResult: ApiResult<string> = await ViewerService.shareDocument(
      emailList,
    );
    if (apiResult.data || !apiResult.data) {
      setShowShareDialog(!showShareDialog);
    }
  };

  const onCreateShareLink = async (): Promise<string> => {
    const apiResult: ApiResult<string> = await ViewerService.createShareLink();
    return apiResult.data;
  };

  const onAddDocument = (): void => {
    setShowAddDocumentDialog(!showAddDocumentDialog);
  };

  const onAddAsset = (): void => {
    setShowAddAssetDialog(!showAddAssetDialog);
  };

  const onAddSubarea = (): void => {
    setShowAddAreaDialog(!showAddAreaDialog);
  };

  const onAddSystem = (): void => {
    setShowAddSystemDialog(!showAddSystemDialog);
  };

  // Carrega os dados do Back
  const loadBottomPanelData = (): void => {
    setLoadingBottomPanelData(true);

    setNavigatorFile((s) => {
      return {
        ...s,
        children: [],
      };
    });

    const requestNavigatorFileParams = new RequestNavigatorFileParams({
      projectId: props.projectId || emptyGuid(),
      areaId: props.areaId || emptyGuid(),
      systemId: props.systemId || emptyGuid(),
      fileId: props.fileId || emptyGuid(),
    });

    ViewerService.getNavigatorFileViewer(requestNavigatorFileParams).then(
      (apiResult: ApiResult<NavigatorFileViewer>) => {
        const navigatorFileViewer = apiResult.data as NavigatorFileViewer;

        if (navigatorFileViewer.document) {
          if (navigatorFileViewer.document.key) {
            navigatorFileViewer.document.key = atob(
              atob(atob(navigatorFileViewer.document.key)),
            );
          }
          if (navigatorFileViewer.document.cnd) {
            navigatorFileViewer.document.cnd = atob(
              atob(atob(navigatorFileViewer.document.cnd)),
            );
          }
          if (navigatorFileViewer.document.viewer) {
            navigatorFileViewer.document.viewer = atob(
              atob(atob(navigatorFileViewer.document.viewer)),
            );
          }
          if (navigatorFileViewer.document.download) {
            navigatorFileViewer.document.download = atob(
              atob(atob(navigatorFileViewer.document.download)),
            );
          }

          if (navigatorFileViewer.children) {
            navigatorFileViewer.children.map((c) => {
              if (c.items) {
                c.items.map((i) => {
                  if (i.thumbnail) {
                    i.thumbnail = atob(atob(atob(i.thumbnail)));
                  }
                  return i;
                });
              }
              return c;
            });
          }
        }

        setNavigatorFile(new NavigatorFileViewer(navigatorFileViewer));

        setLoadingViewer(false);
        setLoadingBottomPanelData(false);
      },
    );
  };

  useEffect(loadBottomPanelData, [
    dispatch,
    props.areaId,
    props.fileId,
    props.projectId,
    props.systemId,
  ]);

  const onBottomPanelBarSearchInputChange = (value: string): void =>
    setDetailsViewerBarFilterValue(value);

  const valueProvider: ViewerContextV1Props = {
    projectId,
    areaId,
    systemId,
    fileId,
    loadingViewer,
    loadingBottomPanelData,
    isLoadingTab,
    setIsLoadingTab,
    showBottomPanel,
    showSidePanel,
    navigatorFile,
    setNavigatorFile,
    setShowSidePanel,
    showHideBottomPanel,
    showHideUploadDocument,
    showHideAssetManagement,
    onEdit,
    onShare,
    onCreateShareLink,
    onAddDocument,
    onAddAsset,
    onAddSubarea,
    onAddSystem,
    loadBottomPanelData,
    bottomPanelBarFilterValue,
    onBottomPanelBarSearchInputChange,
    showHideDialog,
  };

  return (
    <ViewerContextV1.Provider value={valueProvider}>
      {props.children}
    </ViewerContextV1.Provider>
  );
};

export { ViewerContextV1, ViewerContextV1Provider, ViewerDialogs };
