import { ApiResult, AuthUser, Endpoints, II18n } from '../../../models';
import { ApiService } from '../../../services';
import { AuthUserCredentials, ChangePasswordModel } from '../models';

const translations: II18n = {
  es: {
    INVALID_USER_OR_PASSWORD: 'Correo electrónico o contraseña no válidos',
  },
  enUS: {
    INVALID_USER_OR_PASSWORD: 'Invalid email or password!',
  },
  ptBR: {
    INVALID_USER_OR_PASSWORD: 'Email ou senha inválido!',
  },
};

class ProfileService {
  private static api: ApiService = new ApiService(Endpoints.AUTH, translations);

  public static async changePassword(
    changePasswordParam: ChangePasswordModel,
  ): Promise<ApiResult<any>> {
    const apiResult: ApiResult<any> = await this.api.post<any>(
      '/connect/v1/user/changeCurrentPassword',
      {
        ...changePasswordParam,
      },
    );

    return apiResult;
  }

  public static async login(
    authUserCredentials: AuthUserCredentials,
  ): Promise<ApiResult<AuthUser>> {
    return await this.api.post<AuthUser>('connect/v2/user/auth', authUserCredentials);
  }
}

export { ProfileService };

