import React, { FunctionComponent, memo, useCallback } from 'react';
import style from './CardTreeListGrid.module.scss';
import { renderCardGrid } from './CardTreeListGrid.grid';
import { renderCardTreeList } from './CardTreeListGrid.treeList';
import { BrassCardTreeListGridProps } from './CardTreeListGridProps.interface';
import { AccessDenied, BrassLogo } from '../../../../components';

const BrassCardTreeListGrid: FunctionComponent<BrassCardTreeListGridProps> = memo(
  (props: BrassCardTreeListGridProps) => {
    if (!props.permissions!.read) {
      return <AccessDenied />;
    }

    const render = useCallback(
      () =>
        props.treeListColumns ? renderCardTreeList(props) : renderCardGrid(props),
      [props],
    );

    return (
      <div style={{ display: 'block', height: '100%' }}>
        {render()}
        {props.pageable}
      </div>
    );
  },
);

BrassCardTreeListGrid.defaultProps = {
  gridColumns: [],
  permissions: {
    read: true,
  },
  pageable: true,
  groupable: {
    enabled: true,
    footer: 'none',
  },
  reorderable: true,
  filterable: true,
  sortable: {
    mode: 'multiple',
  },
  selectable: {
    drag: false,
    cell: false,
    enabled: false,
    mode: 'single',
  },
};

export { BrassCardTreeListGrid };
