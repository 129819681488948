// import { ApiService, ApiResult, Endpoints } from '@brass-brasil/core';
import { ApiResult } from '../';
import { Endpoints } from '../models';
import { NavigatorFile } from '../models/viewer';
import { ApiService } from './api.service';

class FileNavigatorService {
  private static api: ApiService = new ApiService(Endpoints.NAVIGATOR);

  public static async deleteFileNavigator(fileId: string): Promise<ApiResult> {
    return await this.api.delete(`/connect/v1/file/${fileId}`);
  }
  public static async promoteToInitialFileView(fileId: string): Promise<ApiResult> {
    return await this.api.put(`/connect/v1/file/${fileId}/promote/initial-file-view`, {});
  }

  public static async updateNavigatorFile(file: NavigatorFile): Promise<ApiResult> {
    return await this.api.put('/connect/v1/file', file);
  }
  public static async getFileNavigator(
    fileId: string,
  ): Promise<ApiResult<NavigatorFile>> {
    return await this.api.get(`/connect/v1/file/${fileId}`);
  }
}

export { FileNavigatorService };
