import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    communications: 'Comunicaciones',
    comments: 'Comentarios',
    newComments: 'Nuevos',
    messagesInTotal: 'Mensajes en total',
    RHT_AUTHORIZATION_COMMENT: 'COMENTÁRIO NA AUTORIZAÇÂO DO RHT',
  },
  enUS: {
    communications: 'Communications',
    comments: 'Comments',
    newComments: 'New',
    messagesInTotal: 'messages in total',
    RHT_AUTHORIZATION_COMMENT: 'COMENTÁRIO NA AUTORIZAÇÂO DO RHT',
  },
  ptBR: {
    communications: 'Comunicações',
    comments: 'Comentários',
    newComments: 'Novos',
    messagesInTotal: 'mensagens no total',
    RHT_AUTHORIZATION_COMMENT: 'COMENTÁRIO NA AUTORIZAÇÂO DO RHT',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
