import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import {
  NotificationGroup,
  NotificationGroupProps,
} from '@progress/kendo-react-notification';

interface BrassNotificationGroupProps extends NotificationGroupProps {}

const BrassNotificationGroup: FunctionComponent<BrassNotificationGroupProps> = (
  props: BrassNotificationGroupProps,
) => {
  const { className } = props;
  const _className = classnames('brass-notification-group', className);

  return <NotificationGroup {...props} className={_className} />;
};

export { BrassNotificationGroup };
export type { BrassNotificationGroupProps };
