import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonHelpV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-help'
    labelKey='help'
    tooltipKey='clickToHelp'
    translations={translations}
    icon={<BrassIcon icon={faQuestionCircle} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    help: 'Eliminar',
    clickToHelp: 'Haga clic para eliminar',
  },
  enUS: {
    help: 'Help',
    clickToHelp: 'Click to help',
  },
  ptBR: {
    help: 'Ajuda',
    clickToHelp: 'Ajuda',
  },
};

export { BrassButtonHelpV1 };
