import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconMembers: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M4.21699 3.26367C2.73379 3.26367 1.51799 4.47942 1.51799 5.96267C1.51799 7.44587 2.73374 8.66167 4.21699 8.66167C4.96139 8.66167 5.63763 8.35471 6.12733 7.86212C6.07707 7.61297 6.0492 7.35626 6.0492 7.09311C6.0492 6.29557 6.2925 5.55131 6.7059 4.92761C6.29723 3.95377 5.33377 3.26381 4.2169 3.26381L4.21699 3.26367ZM15.7795 3.26491C14.663 3.26491 13.6995 3.95423 13.2905 4.92754C13.704 5.55131 13.9473 6.29551 13.9472 7.09304C13.9472 7.35653 13.9194 7.61381 13.8691 7.86329C14.3589 8.35588 15.0351 8.66284 15.7794 8.66284C17.2626 8.66284 18.4784 7.44709 18.4784 5.96384C18.4784 4.48059 17.2627 3.26484 15.7794 3.26484L15.7795 3.26491ZM9.99816 4.39408C8.51496 4.39408 7.29916 5.60983 7.29916 7.09308C7.29916 8.57633 8.51491 9.79208 9.99816 9.79208C11.4814 9.79208 12.6972 8.57633 12.6972 7.09308C12.6972 5.60983 11.4814 4.39408 9.99816 4.39408V4.39408ZM4.21683 9.12808C1.88933 9.12808 0.000492195 11.0221 0.000492195 13.3492V14.9825V14.9824C-9.37389e-05 15.1482 0.0651405 15.3074 0.181876 15.4251C0.298607 15.5427 0.457326 15.6092 0.623092 15.6099H4.53176V14.4795C4.53176 12.647 5.50149 11.0888 6.90476 10.1033C6.17273 9.49783 5.23836 9.12796 4.21676 9.12796L4.21683 9.12808ZM15.7793 9.13042C14.7582 9.13042 13.8255 9.49982 13.095 10.1045C14.4989 11.0897 15.4693 12.6467 15.4693 14.4795V15.6087H19.3743V15.6086C19.5401 15.6086 19.699 15.5427 19.8163 15.4255C19.9334 15.3084 19.9993 15.1494 19.9993 14.9836V13.3515C19.9993 11.0243 18.1068 9.13034 15.7793 9.13034V9.13042ZM9.99799 10.2584C7.67049 10.2584 5.78166 12.1524 5.78166 14.4795V16.1128C5.78205 16.2779 5.84767 16.4362 5.96434 16.5531C6.08094 16.6699 6.23914 16.7359 6.40426 16.7366H13.5918C13.7577 16.7372 13.9171 16.6718 14.0348 16.5548C14.1525 16.4378 14.2188 16.2788 14.2192 16.1128V14.4795C14.2192 12.1524 12.3255 10.2584 9.99799 10.2584V10.2584Z' />
  </svg>
);

export { SVGIconMembers };
