import React, {
  Component,
  Dispatch,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import style from './TreeListGrid.v1.module.scss';
import { GridSelectableSettings } from '@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassButtonAddV1,
  BrassButtonGroup,
  BrassButtonHelp,
  BrassButtonRefreshV1,
  BrassGridCellProps,
  BrassGridConfigProps,
  BrassLoader,
  BrassTreeListGridPropsV1,
  BrassButtonFilter,
  HideHeaderCell,
} from '../../../..';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { DateTimeService, I18nService, II18n } from '../../../../../';
import { BrassGridColumnProps } from '../..';

const translations: II18n = {
  es: {
    group: 'Agrupar por',
  },
  enUS: {
    group: 'Group by',
  },
  ptBR: {
    group: 'Agrupar por',
  },
};

const translateInternal = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const SelectColumn = (
  columns: ReactNode[],
  gridData: any,
  selectedRecords: string[],
  selectable?: GridSelectableSettings,
) => {
  if (selectable && selectable.enabled) {
    columns.unshift(
      <GridColumn
        width='50px'
        key='selected'
        field='selected'
        groupable={false}
        filterable={false}
        className={style['brass-grid-column-center']}
        headerSelectionValue={
          gridData.records &&
          gridData.records.length &&
          selectedRecords.length === gridData.records.length
        }
      />,
    );
  }
};

const editCell = (): any =>
  class extends Component {
    public render(): any {
      return (
        <td>
          teste
          {/* <Button>
            teste
          </Button> */}
        </td>
      );
    }
  };

const EditColumn = (
  allowEdit: boolean,
  preColumns: ReactNode[],
  isTreeGrid: boolean,
  EditCell: BrassGridCellProps | any,
  onEditCallback: (item: any) => void,
) => {
  if (allowEdit) {
    isTreeGrid
      ? preColumns.push({
          cell: editCell(),
          width: 100,
          field: 'actions',
          title: 'Actions',
        })
      : preColumns.unshift(
          <GridColumn
            key='edit'
            field='edit'
            groupable={false}
            width='50px'
            cell={(_props) => EditCell(_props, onEditCallback)}
            filterable={false}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const DeleteColumn = (
  allowDelete: boolean,
  preColumns: ReactNode[],
  DeleteCell: BrassGridCellProps | any,
  onDeleteCallback: (item: any) => void,
  gridData: any,
  updateGridData: Dispatch<any>,
) => {
  if (allowDelete) {
    preColumns.push(
      <GridColumn
        key='delete'
        field='delete'
        width='50px'
        groupable={false}
        cell={(_props) =>
          DeleteCell(_props, onDeleteCallback, gridData, updateGridData)
        }
        filterable={false}
        headerCell={HideHeaderCell}
      />,
    );
  }
};

const onSelectionChangeFunction = (
  event: GridSelectionChangeEvent,
  selectedRecords: string[],
  setSelectedRecords: (value: SetStateAction<string[]>) => void,
  gridData: any,
  updateGridData: any,
  onSelectionChangeProp: (
    selectedRecordsId: string[],
    event: GridSelectionChangeEvent,
  ) => void,
) => {
  const recordId = event.dataItem.id;
  const recordSelected = selectedRecords.indexOf(recordId) !== -1;

  // Atualiza o ESTADO local com os ID selecionados pelo usuário
  let _selectedRecordsId = [...selectedRecords];
  if (recordSelected) {
    _selectedRecordsId = _selectedRecordsId.filter((f) => f !== recordId);
  } else {
    _selectedRecordsId.push(recordId);
  }
  setSelectedRecords(_selectedRecordsId);

  // ----------------------------------------------------------------------
  // Marca o Registro como SELECIONADO/NÃO-SELECIONADO
  gridData.records = gridData.records.map((record: any) => {
    if (record.id === recordId) {
      record[event.selectedField] = !recordSelected;
    }
    return record;
  });

  updateGridData({ ...gridData });
  onSelectionChangeProp && onSelectionChangeProp(_selectedRecordsId, event);
};

const onHeaderSelectionChangeFunction = (
  event: GridHeaderSelectionChangeEvent,
  selectedRecords: string[],
  gridData: any,
  updateGridData: (value: any) => void,
  setSelectedRecords: (value: SetStateAction<string[]>) => void,
  onHeaderSelectionChangeProp:
    | ((selectedRecordsId: string[], event: GridHeaderSelectionChangeEvent) => void)
    | undefined,
) => {
  let _selectedRecordsId = [];
  const allRecordsSelected = selectedRecords.length === gridData.records.length;

  if (!allRecordsSelected) {
    _selectedRecordsId = gridData.records.map((r: any) => r.id);
  }

  gridData.records = gridData.records.map((record: any) => {
    record[event.selectedField] = !allRecordsSelected;
    return record;
  });

  updateGridData({ ...gridData });
  setSelectedRecords(_selectedRecordsId);
  onHeaderSelectionChangeProp &&
    onHeaderSelectionChangeProp(_selectedRecordsId, event);
};

const exportPDF = (gridPDFExport: GridPDFExport | null) => {
  if (gridPDFExport !== null && gridPDFExport !== undefined) {
    gridPDFExport.save();
  }
};
const excelExport = (gridExcelExport: MutableRefObject<ExcelExport | null>) => {
  if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
    gridExcelExport.current.save();
  }
};

const onItemChange = (
  event: GridItemChangeEvent,
  updateGridData: any,
  gridData: any,
): void => {
  const { dataItem, field, value } = event;

  const update =
    gridData.records &&
    gridData.records.map((record: any) => {
      if (!record.oldValues) {
        record.oldValues = record;
      }
      if (record.id === dataItem.id) {
        return { ...record, [field || 'undefined']: value };
      }
      return record;
    });

  updateGridData({ ...update });
};

const getColumns = (
  preColumns: JSX.Element[],
  hideActionButtons: boolean = false,
): BrassGridColumnProps[] => {
  // Converte todas as colunas para o Objeto GridColumnProps
  const _columns: BrassGridColumnProps[] = preColumns.map(
    (col: any) => col as BrassGridColumnProps,
  );

  if (hideActionButtons) {
    return _columns.filter(
      (col: BrassGridColumnProps) => col.field !== 'edit' && col.field !== 'delete',
    );
  }

  return _columns;
};

const getGridToolbar = (
  props: BrassTreeListGridPropsV1,

  gridPDFExport: GridPDFExport,
  gridExcelExport: MutableRefObject<ExcelExport | null>,

  showHelpPanel: boolean,
  setShowHelpPanel: Dispatch<SetStateAction<boolean>>,

  showFilterPanel: boolean,
  setShowFilterPanel: Dispatch<SetStateAction<boolean>>,

  isGrouping: boolean,
  setIsGrouping: Dispatch<SetStateAction<boolean>>,
): ReactNode => {
  const {
    allowInsert,
    allowExport,
    visibleButtonRefresh,
    onAddCallback,
    onRefreshCallback,
    toolbarBeforeActions,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarAfterActions,
    customFilterGridToolbar,
    gridConfig,
    updateGridConfig,
    gridLoading,
    groupable,
    setGridLoading,
    helpUser,
  } = props;

  const getTitle = () => (
    <h2 className={style['brass-grid-title']}>
      {props.gridIcon}
      {props.gridTitle}
    </h2>
  );

  const getInsertButton = () =>
    allowInsert && (
      <BrassButtonAddV1
        fillMode='solid'
        onClick={onAddCallback as any}
        disabled={gridLoading}
        themeColor='primary'
        style={{ borderRadius: '5px' }}
      />
    );

  const getRefreshButton = () =>
    visibleButtonRefresh && (
      <BrassButtonRefreshV1
        showText={false}
        onClick={() => {
          setGridLoading && setGridLoading(true);
          onRefreshCallback && onRefreshCallback();
        }}
        disabled={gridLoading}
      />
    );

  const getExportButton = () =>
    allowExport && (
      <DropDownButton
        icon='printer'
        items={['PDF', 'Excel']}
        className={style['brass-button-export']}
        onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
          itemClickEvent.itemIndex === 0
            ? exportPDF(gridPDFExport)
            : excelExport(gridExcelExport)
        }
        disabled={gridLoading}
      />
    );

  const getGroupableButton = () =>
    groupable && (
      <Button
        togglable
        title={translateInternal('group')}
        icon='aggregate-fields'
        className={style['brass-button-groupable']}
        onClick={() => {
          if (isGrouping) {
            updateGridConfig!({
              ...gridConfig,
              group: [],
            });
          }
          setIsGrouping(!isGrouping);
        }}
      />
    );

  const getCustomFilterGridToolbar = () =>
    customFilterGridToolbar && (
      <BrassButtonFilter
        togglable
        fillMode='flat'
        key='buttonFilter'
        onClick={() => setShowFilterPanel(!showFilterPanel)}
      />
    );

  const getHelpUser = () =>
    helpUser && (
      <BrassButtonHelp
        togglable
        fillMode='flat'
        showText={false}
        onClick={() => setShowHelpPanel(!showHelpPanel)}
      />
    );

  return (
    <GridToolbar>
      {toolbarBeforeTitle}
      {getTitle()}
      {toolbarAfterTitle}
      <ToolbarSpacer />

      {toolbarBeforeActions}
      {getInsertButton()}
      {getRefreshButton()}
      {getExportButton()}
      {getGroupableButton()}
      {getCustomFilterGridToolbar()}
      {toolbarAfterActions}

      {getHelpUser()}
    </GridToolbar>
  );
};

const getHelpPanel = (showHelpPanel: boolean, helpUser: ReactNode): ReactNode =>
  showHelpPanel && <div className={style['help-user']}>{helpUser}</div>;

const getExcelExport = (
  gridData: any,
  gridTitle: string,
  preColunsExportExcell: ReactNode[],
  gridExcelExport: MutableRefObject<ExcelExport | null>,
): ReactNode => (
  <ExcelExport
    ref={gridExcelExport}
    data={gridData.records}
    fileName={`${gridTitle} - ${new Date().toDateString()}`}
  >
    <Grid data={gridData.records} style={{ display: 'none' }}>
      {preColunsExportExcell}
    </Grid>
  </ExcelExport>
);
const getPDFExport = (
  gridData: any,
  columns: GridColumnProps[],
  gridTitle: string,
  complementFileNameExport: string,
  ref: LegacyRef<PDFExport>,
): ReactNode => (
  <PDFExport
    ref={ref}
    margin='1cm'
    paperSize='A4'
    fileName={`${gridTitle}${complementFileNameExport} - ${new Date().toDateString()}`}
  >
    <Grid data={gridData.records}>{columns}</Grid>
  </PDFExport>
);

const onGridExpandChange = (
  event: GridExpandChangeEvent,

  idCollapsedGroup: {
    field: string;
    value: string;
  }[],

  setIdCollapsedGroup: Dispatch<
    SetStateAction<
      {
        field: string;
        value: string;
      }[]
    >
  >,
): void => {
  const {
    dataItem: { value, field },
    value: isExpanded,
  } = event;
  let newIdList = [...idCollapsedGroup];

  if (!isExpanded) {
    newIdList.push({ value, field });
  } else {
    newIdList = newIdList.filter((f) => !(f.value === value && f.field === field));
  }

  newIdList = [...Array.from(new Set(newIdList))]; // Unique array
  setIdCollapsedGroup(newIdList);
};

const fnExpandRecords = (
  data: any[],
  idCollapsedGroup: {
    field: string;
    value: string;
  }[],
): void => {
  data.map((rec: any) => {
    const { value, field } = rec;
    const existRec = idCollapsedGroup.find(
      (f) => f.value === value && f.field === field,
    );

    rec.expanded = existRec === undefined;

    if (rec.items && rec.items.length) {
      fnExpandRecords(rec.items, idCollapsedGroup);
    }

    return rec;
  });
};

const getGridBrassLoader = (anchorRef: MutableRefObject<any>): ReactNode => {
  let offsetTop = 0;
  let offsetLeft = 0;
  let offsetHeight = 0;
  let offsetWidth = 0;
  if (anchorRef.current && anchorRef.current.element) {
    offsetTop = anchorRef.current.element.offsetTop || 0;
    offsetLeft = anchorRef.current.element.offsetLeft || 0;

    offsetWidth = (anchorRef.current.element.offsetWidth || 0) + 100;
    offsetHeight = anchorRef.current.element.offsetHeight || 0;
  }

  if (offsetTop + offsetLeft + offsetHeight + offsetWidth >= 1) {
    return (
      <BrassLoader
        useLoadingMask
        style={{
          top: offsetTop,
          left: offsetLeft,
          height: offsetHeight,
          width: offsetWidth,
        }}
      />
    );
  } else {
    return <BrassLoader useLoadingMask />;
  }
};

const onDataStateChange = (
  event: GridDataStateChangeEvent,
  gridConfig: BrassGridConfigProps,
  setSataState: Dispatch<SetStateAction<State>>,
  updateGridConfig?: Dispatch<SetStateAction<BrassGridConfigProps>>,
) => {
  if (updateGridConfig) {
    updateGridConfig({
      ...gridConfig,
      group: event.dataState.group,
    });
  }
  setSataState(event.dataState);
};

const onSortChange = (
  event: GridSortChangeEvent,
  gridConfig: BrassGridConfigProps,
  updateGridConfig?: Dispatch<SetStateAction<BrassGridConfigProps>>,
) => {
  if (updateGridConfig) {
    updateGridConfig({
      ...gridConfig,
      sort: event.sort,
    });
  }
};

const onPageChange = (
  event: GridPageChangeEvent,
  dataState: State,
  gridConfig: BrassGridConfigProps,
  setSataState: Dispatch<SetStateAction<State>>,
  updateGridConfig?: Dispatch<SetStateAction<BrassGridConfigProps>>,
  setGridLoading?: (item: any) => void,
) => {
  setSataState({
    ...dataState,
    take: event.page.take,
  });

  if (updateGridConfig) {
    if (setGridLoading) {
      setGridLoading(true);
    }

    updateGridConfig({
      ...gridConfig,
      skip: event.page.skip,
      take: event.page.take,
    });
  }
};

const onFilterChange = (
  event: GridFilterChangeEvent,
  gridConfig: BrassGridConfigProps,
  setLocalGridFilter: Dispatch<SetStateAction<CompositeFilterDescriptor>>,
  updateGridConfig?: Dispatch<SetStateAction<BrassGridConfigProps>>,
) => {
  if (!event.filter) {
    updateGridConfig &&
      updateGridConfig({
        ...gridConfig,
        filter: event.filter,
      });
  }
  setLocalGridFilter(event.filter);
};

/** Largura do Menu quando Expandido */
const MENU_EXPANDED_WIDTH = 240;

/** Largura do Menu quando Minimizado */
const MENU_COLLAPSED_WIDTH = 50;

const getWindowWidth = (
  menuExpanded: boolean,
  reduceWindowWidth: number = 0,
  increaseWindowWidth: number = 0,
) =>
  menuExpanded
    ? `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_EXPANDED_WIDTH +
        increaseWindowWidth
      }px`
    : `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_COLLAPSED_WIDTH +
        increaseWindowWidth
      }px`;

const getCells = (
  gridColumns: BrassGridColumnProps[],
  cellProps: GridCellProps,
): ReactNode => {
  const cells: ReactNode[] = [];
  gridColumns.forEach((column: BrassGridColumnProps) => {
    if (column.aggregate) {
      const sum: number =
        cellProps.dataItem.aggregates[column.aggregateField!][column.aggregate];
      cells!.push(
        <td style={{ textAlign: 'center' }}>
          {column.columnType === 'hours'
            ? DateTimeService.getHoursFromMinutes(sum)
            : sum}
        </td>,
      );
    } else {
      cells!.push(<td>&nbsp;</td>);
    }
  });
  return cells;
};

const cellRender = (
  tdElement: any,
  cellProps: GridCellProps,
  columns: BrassGridColumnProps[],
):
  | ReactElement<HTMLTableCellElement>
  | ReactElement<HTMLTableCellElement>[]
  | null => {
  if (
    cellProps.rowType === 'groupHeader' &&
    tdElement &&
    tdElement.props.role !== 'presentation'
  ) {
    return (
      <>
        <td
          {...tdElement.props}
          colSpan={tdElement.props.colSpan - columns.length}
        ></td>
        {getCells(columns, cellProps)}
      </>
    );
  }
  return tdElement;
};

export {
  exportPDF,
  excelExport,
  SelectColumn,
  EditColumn,
  DeleteColumn,
  onItemChange,
  onSelectionChangeFunction,
  onHeaderSelectionChangeFunction,
  getColumns,
  getGridToolbar,
  getHelpPanel,
  getExcelExport,
  getPDFExport,
  onGridExpandChange,
  fnExpandRecords,
  getGridBrassLoader,
  onDataStateChange,
  onSortChange,
  onPageChange,
  onFilterChange,
  getWindowWidth,
  cellRender,
};
