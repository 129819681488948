import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    titleModal: 'Check translations',
    successOnAdd: 'Añadido con éxito',
    errorOnAdd: 'Error al agregar Propriedad',
    mainLanguage: 'Nombre del registro Idioma ',
  },
  enUS: {
    titleModal: 'Check translations',
    successOnAdd: 'Language add with success',
    errorOnAdd: 'Erro in save languages',
    mainLanguage: 'Name of Registration Language ',
  },
  ptBR: {
    titleModal: 'Verificar traduções',
    successOnAdd: 'Idioma salvo com sucesso',
    errorOnAdd: 'Erro ao salvar idioma',
    mainLanguage: 'Nome do Idioma de Cadastro ',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
