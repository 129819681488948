import { classNames } from '@progress/kendo-react-common';
import { translateDefault } from './NoData.translate';
import style from './NoData.module.scss';

interface NoDataProps {
  translate?: typeof translateDefault;
  value?: string;
}

const BrassNoData = (props: NoDataProps) => {
  const { translate, value } = props;
  return (
    <div className={classNames(style['no-data'])}>
      <span>
        {translate
          ? translate(value || 'noData')
          : translateDefault(value || 'noData')}
      </span>
    </div>
  );
};

export { BrassNoData };
