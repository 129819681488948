import React, { createRef, Fragment, FunctionComponent } from 'react';
import { ExternalDropZone, Upload, UploadProps } from '@progress/kendo-react-upload';

interface BrassUploaderProps extends UploadProps {
  showExternalDropZone?: boolean;
  hint?: string;
  note?: string;
}

const BrassUploader: FunctionComponent<BrassUploaderProps> = (
  props: BrassUploaderProps,
) => {
  const uploadRef = createRef<Upload>();
  return (
    <Fragment>
      {props.showExternalDropZone && (
        <ExternalDropZone
          uploadRef={uploadRef}
          customHint={<span>{props.hint}</span>}
          customNote={<span>{props.note}</span>}
        />
      )}
      <Upload ref={uploadRef} {...props} />
    </Fragment>
  );
};

BrassUploader.defaultProps = {
  showExternalDropZone: true,
};

export type { BrassUploaderProps };
export { BrassUploader };
