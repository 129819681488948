import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { DropDownButton, DropDownButtonProps } from '@progress/kendo-react-buttons';

interface BrassDropDownButtonProps extends DropDownButtonProps {}

const BrassDropDownButton: FunctionComponent<BrassDropDownButtonProps> = (
  props: BrassDropDownButtonProps,
) => {
  const { className } = props;
  const _className = classnames('brass-dropdown-button', className);

  return <DropDownButton {...props} className={_className} />;
};

export { BrassDropDownButton };
export type { BrassDropDownButtonProps };
