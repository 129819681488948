import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconMegaphone: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.8 0.2C18.1 -0.1 17.3 -1.62981e-09 16.7 0.5C15.4 1.6 13.1 3.1 9.9 3.9C9.7 3 8.9 2.4 8 2.4H2C0.9 2.4 0 3.3 0 4.4V11.4C0 12.5 0.9 13.4 2 13.4H2.2L3.6 18.1C4 19.2 5.1 20 6.3 20C8.3 20 9.7 18 9 16.2L8.2 13.3C9 13.2 9.6 12.6 9.8 11.8C13 12.6 15.2 14.1 16.6 15.2C17.2 15.7 18 15.8 18.7 15.5C19.4 15.2 19.9 14.5 19.9 13.7V2C20 1.2 19.6 0.5 18.8 0.2ZM2 4.4H8V11.4H2V4.4ZM6.3 18C5.9 18 5.6 17.8 5.5 17.5L4.3 13.4H6.2L7.1 16.8C7.4 17.4 6.9 18 6.3 18ZM10 9.8V5.9C13.8 5 16.4 3.3 18 2V13.8C16.5 12.4 13.8 10.7 10 9.8Z'
      fill='white'
    />
  </svg>
);

export { SVGIconMegaphone };
