import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { IntegratorSubmodule } from './Integrator.submodule.enum';

enum IntegratorPermissionsV1 {
  /// <summary>
  /// Classificação de Sistema [(FE) /systems/classification]
  /// </summary>
  SYSTEM_CLASSIFICATION_READ = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_CLASSIFICATION +
    Action.READ,
  SYSTEM_CLASSIFICATION_CREATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_CLASSIFICATION +
    Action.CREATE,
  SYSTEM_CLASSIFICATION_UPDATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_CLASSIFICATION +
    Action.UPDATE,
  SYSTEM_CLASSIFICATION_DELETE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_CLASSIFICATION +
    Action.DELETE,
  SYSTEM_CLASSIFICATION_EXPORT = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_CLASSIFICATION +
    Action.EXPORT,

  /// <summary>
  /// Classificação de Sistema [(FE) /systems/classification]
  /// </summary>
  SYSTEM_TYPE_READ = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_TYPE +
    Action.READ,
  SYSTEM_TYPE_CREATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_TYPE +
    Action.CREATE,
  SYSTEM_TYPE_UPDATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_TYPE +
    Action.UPDATE,
  SYSTEM_TYPE_DELETE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_TYPE +
    Action.DELETE,
  SYSTEM_TYPE_EXPORT = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.SYSTEM_TYPE +
    Action.EXPORT,

  /// <summary>
  /// Tipo de arquivos [(FE) /file/type]
  /// </summary>
  FILE_TYPE_READ = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_TYPE +
    Action.READ,
  FILE_TYPE_CREATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_TYPE +
    Action.CREATE,
  FILE_TYPE_UPDATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_TYPE +
    Action.UPDATE,
  FILE_TYPE_DELETE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_TYPE +
    Action.DELETE,
  FILE_TYPE_EXPORT = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_TYPE +
    Action.EXPORT,
  /// <summary>
  /// Classificação de arquivos [(FE) /file/classification]
  /// </summary>
  FILE_CLASSIFICATION_READ = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_CLASSIFICATION +
    Action.READ,
  FILE_CLASSIFICATION_CREATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_CLASSIFICATION +
    Action.CREATE,
  FILE_CLASSIFICATION_UPDATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_CLASSIFICATION +
    Action.UPDATE,
  FILE_CLASSIFICATION_DELETE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_CLASSIFICATION +
    Action.DELETE,
  FILE_CLASSIFICATION_EXPORT = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_CLASSIFICATION +
    Action.EXPORT,
  // NOTA: para permitir o CRUD de ÁREA utilizar a permissão:: PermissionAreaV1.AREA_PHYSICAL_CREATE
  // NOTA: para permitir o CRUD de SISTEMA utilizar a permissão:: PermissionSystemV1.SYSTEM_PHYSICAL_CREATE
  FILE_SHARE = PermissionModule.INTEGRATOR + IntegratorSubmodule.FILE + Action.SHARE,
  FILE_UPDATE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE +
    Action.UPDATE,
  FILE_DELETE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE +
    Action.DELETE,
  /// <summary>
  /// Permite que o usuário defina um arquivo como Visão Principal/Inicial
  /// </summary>
  FILE_PROMOTE = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE +
    Action.PROMOTE,
  FILE_UPLOAD = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE +
    Action.UPLOAD,
  FILE_DOWNLOAD = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE +
    Action.DOWNLOAD,
  FILE_INFORMATION_READ = PermissionModule.INTEGRATOR +
    IntegratorSubmodule.FILE_INFORMATION +
    Action.READ,
}

const _NAVIGATOR_MODULE = 100000;

enum NavigatorPermissions {
  NO_PERMISION = 0,
  NAVIGATOR_MODULE = _NAVIGATOR_MODULE, // 100.000

  NAVIGATOR_FILE_READ = _NAVIGATOR_MODULE + 101,
  NAVIGATOR_FILE_CREATE = _NAVIGATOR_MODULE + 102,
  NAVIGATOR_FILE_UPDATE = _NAVIGATOR_MODULE + 103,
  NAVIGATOR_FILE_DELETE = _NAVIGATOR_MODULE + 104,
  NAVIGATOR_FILE_EXPORT = _NAVIGATOR_MODULE + 105,
  NAVIGATOR_FILE_ALLOW_DOWNLOAD = _NAVIGATOR_MODULE + 106,

  NAVIGATOR_FILESHARE_READ = _NAVIGATOR_MODULE + 201,
  NAVIGATOR_FILESHARE_CREATE = _NAVIGATOR_MODULE + 202,
  NAVIGATOR_FILESHARE_UPDATE = _NAVIGATOR_MODULE + 203,
  NAVIGATOR_FILESHARE_DELETE = _NAVIGATOR_MODULE + 204,
  NAVIGATOR_FILESHARE_EXPORT = _NAVIGATOR_MODULE + 205,

  NAVIGATOR_PROJECT_READ = _NAVIGATOR_MODULE + 301,
  NAVIGATOR_PROJECT_CREATE = _NAVIGATOR_MODULE + 302,
  NAVIGATOR_PROJECT_UPDATE = _NAVIGATOR_MODULE + 303,
  NAVIGATOR_PROJECT_DELETE = _NAVIGATOR_MODULE + 304,
  NAVIGATOR_PROJECT_EXPORT = _NAVIGATOR_MODULE + 305,

  NAVIGATOR_AREA_READ = _NAVIGATOR_MODULE + 401,
  NAVIGATOR_AREA_CREATE = _NAVIGATOR_MODULE + 402,
  NAVIGATOR_AREA_UPDATE = _NAVIGATOR_MODULE + 403,
  NAVIGATOR_AREA_DELETE = _NAVIGATOR_MODULE + 404,
  NAVIGATOR_AREA_EXPORT = _NAVIGATOR_MODULE + 405,

  NAVIGATOR_FILE_CLASSIFICATION_READ = _NAVIGATOR_MODULE + 501,
  NAVIGATOR_FILE_CLASSIFICATION_CREATE = _NAVIGATOR_MODULE + 502,
  NAVIGATOR_FILE_CLASSIFICATION_UPDATE = _NAVIGATOR_MODULE + 503,
  NAVIGATOR_FILE_CLASSIFICATION_DELETE = _NAVIGATOR_MODULE + 504,
  NAVIGATOR_FILE_CLASSIFICATION_EXPORT = _NAVIGATOR_MODULE + 505,

  NAVIGATOR_FILETYPE_READ = NAVIGATOR_MODULE + 601,
  NAVIGATOR_FILETYPE_CREATE = NAVIGATOR_MODULE + 602,
  NAVIGATOR_FILETYPE_UPDATE = NAVIGATOR_MODULE + 603,
  NAVIGATOR_FILETYPE_DELETE = NAVIGATOR_MODULE + 604,
  NAVIGATOR_FILETYPE_EXPORT = NAVIGATOR_MODULE + 605,

  NAVIGATOR_SURVEY_READ = NAVIGATOR_MODULE + 701,
  NAVIGATOR_SURVEY_CREATE = NAVIGATOR_MODULE + 702,
  NAVIGATOR_SURVEY_UPDATE = NAVIGATOR_MODULE + 703,
  NAVIGATOR_SURVEY_DELETE = NAVIGATOR_MODULE + 704,
  NAVIGATOR_SURVEY_EXPORT = NAVIGATOR_MODULE + 705,

  NAVIGATOR_BIND_READ = NAVIGATOR_MODULE + 801,
  NAVIGATOR_BIND_CREATE = NAVIGATOR_MODULE + 802,
  NAVIGATOR_BIND_UPDATE = NAVIGATOR_MODULE + 803,
  NAVIGATOR_BIND_DELETE = NAVIGATOR_MODULE + 804,
  NAVIGATOR_BIND_EXPORT = NAVIGATOR_MODULE + 805,

  NAVIGATOR_SYSTEMCLASSIFICATION_READ = NAVIGATOR_MODULE + 901,
  NAVIGATOR_SYSTEMCLASSIFICATION_CREATE = NAVIGATOR_MODULE + 902,
  NAVIGATOR_SYSTEMCLASSIFICATION_UPDATE = NAVIGATOR_MODULE + 903,
  NAVIGATOR_SYSTEMCLASSIFICATION_DELETE = NAVIGATOR_MODULE + 904,
  NAVIGATOR_SYSTEMCLASSIFICATION_EXPORT = NAVIGATOR_MODULE + 905,
}

export { NavigatorPermissions, IntegratorPermissionsV1 };
