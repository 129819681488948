import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconPenAlt: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    {...props}
    fill={props?.fill ?? '#424242'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.75 16.25H4.66667L13.8958 7.02083L12.9792 6.10417L3.75 15.3333V16.25ZM16.5417 6.125L13.875 3.45833L14.75 2.58333C14.9861 2.34722 15.2778 2.22917 15.625 2.22917C15.9722 2.22917 16.2639 2.34722 16.5 2.58333L17.4167 3.5C17.6528 3.73611 17.7708 4.02778 17.7708 4.375C17.7708 4.72222 17.6528 5.01389 17.4167 5.25L16.5417 6.125ZM15.6667 7L5.16667 17.5H2.5V14.8333L13 4.33333L15.6667 7ZM13.4375 6.5625L12.9792 6.10417L13.8958 7.02083L13.4375 6.5625Z'
      fill={props?.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconPenAlt };
