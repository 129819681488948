import React, { FunctionComponent, useEffect, useState, memo } from 'react';
import { translate } from './ProjectsDropDown.translations';
import { ProjectService } from '../../services';
import { ApiResult, DropDownData } from '../../../../models';
import { I18nService } from '../../../../services';
import { BrassMultiSelectProps, BrassMultiSelect } from '../../../../components';

interface ProjectsDropDownMultiSelectProps extends BrassMultiSelectProps {
  customLabel?: string;
}

const ProjectsDropDownMultiSelect: FunctionComponent<ProjectsDropDownMultiSelectProps> =
  memo((props: ProjectsDropDownMultiSelectProps) => {
    const { customLabel } = props;

    const [projects, setProjects] = useState<DropDownData[]>(
      ProjectService.cacheProjectsDropDownData ?? [],
    );
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      setLoading(true);
      ProjectService.getProjectsDropDown().then(
        (apiResult: ApiResult<DropDownData[]>) => {
          if (apiResult.success) {
            setProjects(
              apiResult.data.map((a) => {
                a.text = a.text ? a.text : ' ';
                return a;
              }) ?? [],
            );
            setLoading(false);
          }
        },
      );
    }, []);

    return (
      <BrassMultiSelect
        {...props}
        loading={loading}
        data={projects}
        label={customLabel || translate('project')}
        textField='text'
        dataItemKey='value'
      />
    );
  });

export { ProjectsDropDownMultiSelect };
export type { ProjectsDropDownMultiSelectProps };
