import React from 'react';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    successOnAdd: 'Género agregado correctamente',
    errorOnAdd: 'Error al intentar agregar el género',
  },
  enUS: {
    successOnAdd: 'Gender successfully added',
    errorOnAdd: 'Error while trying to add the gender',
  },
  ptBR: {
    successOnAdd: 'Gênero adicionado com sucesso',
    errorOnAdd: 'Erro ao tentar adicionar o gênero',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
