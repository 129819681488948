import {
  ApiResult,
  DropDownData,
  Endpoints,
  Permissions,
  PermissionsType,
} from '../models';
import { replaceallText } from '../util';
import { ApiService } from './api.service';
import { BaseService } from './base.service';

class PermissionService extends BaseService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  private static _permissions: DropDownData<PermissionsType>[] = Object.entries(
    Permissions,
  ).map(([text, value]) => {
    return { text, value };
  });

  public static permissions: DropDownData<PermissionsType>[] =
    PermissionService._permissions.slice(
      PermissionService._permissions.length / 2,
      PermissionService._permissions.length,
    );

  // Retorna o Array de Textos das Permissões
  private static getTextFromPermission = (
    permissions: DropDownData<PermissionsType>[],
    filter?: (value: DropDownData<PermissionsType>) => boolean,
  ): string[] => {
    if (filter) {
      return permissions
        .filter((f: DropDownData<PermissionsType>) => filter(f))
        .map((permission: any) => replaceallText(permission.text, '_', ' '));
    } else {
      return permissions.map((permission: any) =>
        replaceallText(permission.text, '_', ' '),
      );
    }
  };

  // Retorna o Array de Textos das Permissões
  public static getPermissionText = (
    filter?: (value: DropDownData<PermissionsType>) => boolean,
  ): string[] =>
    PermissionService.getTextFromPermission(PermissionService.permissions, filter);

  public static async getPermissions(): Promise<
    ApiResult<{ permissions: number[] }>
  > {
    return PermissionService.api.get<{ permissions: number[] }>(
      `/connect/v1/user/my-permissions`,
    );
  }

  public static async getAdditionalPermissions(): Promise<
    ApiResult<{ permissions: string[] }>
  > {
    return PermissionService.api.get<{ permissions: string[] }>(
      `/connect/v1/user/additional-permissions`,
    );
  }
}

export { PermissionService };

