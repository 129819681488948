import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { translate } from './UserGroupGrid.translations';
import {
  BrassGridCellProps,
  BrassGridColumnMenu,
  BrassGridColumnProps,
  BrassGridConfigProps,
  BrassGridCell,
  BrassButtonSharedV1,
  BrassIcon,
  BrassButtonNotificationV1,
} from '../../../../../components';
import {
  ApiResult,
  AuthUser,
  ConnectPermissions,
  GridCallbacks,
  GridItem,
  Notification,
  NotificationStyles,
} from '../../../../../models';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { notificationActions } from '../../../../../store';

const performActions = (
  endpointToCall: (groupId: string) => Promise<ApiResult<any>>,
  recordId: string,
  onSuccessMessage: string,
  onErrorMessage: string,
  setId: (value: SetStateAction<string | undefined>) => void,
  dispatch: Dispatch<any>,
  gridCallbacks: GridCallbacks<GridItem>,
): void => {
  endpointToCall(recordId)
    .then((apiResult: ApiResult) => {
      if (apiResult.success) {
        gridCallbacks.onRefresh();
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate(onSuccessMessage),
            }),
          ),
        );
        gridCallbacks.onRefresh();
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: translate(onErrorMessage),
            }),
          ),
        );
        gridCallbacks.onRefresh();
      }
    })
    .finally(() => {
      setId(undefined);
    });
};

const getColumns = (
  gridConfig: BrassGridConfigProps,
  setEditGroup: Dispatch<React.SetStateAction<string | undefined>>,
  setDeleteGroup: Dispatch<React.SetStateAction<string | undefined>>,
): BrassGridColumnProps[] => {
  const hasGroup = gridConfig.group?.length !== 0;

  const getGridColumnName = (): BrassGridColumnProps =>
    ({
      resizable: true,
      field: 'name',
      minResizableWidth: 300,
      width: hasGroup ? '830' : 'auto',
      title: translate('groupname'),
      columnMenu: BrassGridColumnMenu,
      cell: (_props: BrassGridCellProps) => (
        <BrassGridCell textAlign='left' fieldName='name' {..._props} />
      ),
    } as BrassGridColumnProps);

  const getGridColumnActions = (): BrassGridColumnProps =>
    ({
      resizable: false,
      field: 'actions',
      minResizableWidth: 300,
      width: '600',
      title: translate('actions'),
      columnMenu: BrassGridColumnMenu,
      cell: (_props: BrassGridCellProps) => {
        if (_props.rowType === 'groupHeader') {
          return <Fragment />;
        }
        return (
          <BrassGridCell {..._props} textAlign='center'>
            {AuthUser.checkPermission(
              ConnectPermissions.CONNECT_USERGROUP_UPDATE,
            ) && (
              <BrassButtonNotificationV1
                onClick={() => setEditGroup(_props.dataItem.id)}
                showText={false}
                title={translate('edit')}
                icon={<BrassIcon icon={faEdit} size='lg' />}
              />
            )}
            {AuthUser.checkPermission(
              ConnectPermissions.CONNECT_USERGROUP_DELETE,
            ) && (
              <BrassButtonSharedV1
                onClick={() => setDeleteGroup(_props.dataItem.id)}
                showText={false}
                title={translate('delete')}
                icon={<BrassIcon icon={faTrashAlt} size='lg' />}
                hideIconWhenLoader
              />
            )}
          </BrassGridCell>
        );
      },
    } as BrassGridColumnProps);

  const columns: BrassGridColumnProps[] = [
    getGridColumnName(),
    getGridColumnActions(),
  ].map((col: BrassGridColumnProps, index: number) => {
    return {
      ...col,
      key: index,
    };
  });

  return columns;
};

export { getColumns, performActions };
