import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    userProfile: 'Perfil del Usuario',
    successOnUpdate: 'Perfil del Usuario actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar el Perfil del Usuario',
  },
  enUS: {
    userProfile: 'User Profile',
    successOnUpdate: 'User Profile successfully updated',
    errorOnUpdate: 'Error while trying to update the User Profile',
  },
  ptBR: {
    userProfile: 'Perfil do Usuário',
    successOnUpdate: 'Perfil do Usuário atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar o Perfil do Usuário',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
