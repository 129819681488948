import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const BrassButtonClose: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-close'
    labelKey='close'
    tooltipKey='clickToClose'
    translations={translations}
    icon={<BrassIcon icon={faTimes} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    close: 'Fechar',
    clickToClose: 'Haga clic para fechar',
  },
  enUS: {
    close: 'Close',
    clickToClose: 'Click to close',
  },
  ptBR: {
    close: 'Fechar',
    clickToClose: 'Clique para fechar',
  },
};

export { BrassButtonClose };
