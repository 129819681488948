import React, { FunctionComponent } from 'react';
import {
  BrassDropDownList,
  BrassComboBox,
  BrassMultiSelect,
} from '../../components';

const data = [
  { text: 'X-Small', id: 1 },
  { text: 'Small', id: 2 },
  { text: 'Medium', id: 3 },
  { text: 'Large', id: 4 },
  { text: 'X-Large', id: 5 },
  { text: '2X-Large', id: 6 },
];

const DropDownPlaygroundList: FunctionComponent = () => (
  <BrassDropDownList
    data={data}
    textField={'text'}
    dataItemKey={'id'}
    onChange={() => {}}
  />
);

const PlayGroundComboBox: FunctionComponent = () => (
  <BrassComboBox
    data={data}
    allowCustom={false}
    textField={'text'}
    dataItemKey={'id'}
  />
);

const sports = [
  'Baseball',
  'Basketball',
  'Cricket',
  'Field Hockey',
  'Football',
  'Table Tennis',
  'Tennis',
  'Volleyball',
];

const PlayGroundMultiSelect: FunctionComponent = () => (
  <div>
    <div>
      <div>Favorite sports:</div>
      <BrassMultiSelect data={sports} onChange={() => {}} value={[]} />
    </div>
  </div>
);

const PlaygroundDropDown: FunctionComponent = () => (
  <div>
    <DropDownPlaygroundList />
    <PlayGroundComboBox />
    <PlayGroundMultiSelect />
  </div>
);

export { PlaygroundDropDown };
