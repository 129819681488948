import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIconGroup: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.875 5.1875C9.875 6.4307 9.38114 7.62299 8.50206 8.50206C7.62299 9.38114 6.4307 9.875 5.1875 9.875C3.9443 9.875 2.75201 9.38114 1.87294 8.50206C0.99386 7.62299 0.5 6.4307 0.5 5.1875C0.5 3.9443 0.99386 2.75201 1.87294 1.87294C2.75201 0.99386 3.9443 0.5 5.1875 0.5C6.4307 0.5 7.62299 0.99386 8.50206 1.87294C9.38114 2.75201 9.875 3.9443 9.875 5.1875Z'
      fill={props.fill}
    />
    <path
      d='M14.5625 9.875C13.3193 9.875 12.127 9.38114 11.2479 8.50206C10.3689 7.62299 9.875 6.4307 9.875 5.1875C9.875 3.9443 10.3689 2.75201 11.2479 1.87294C12.127 0.99386 13.3193 0.5 14.5625 0.5C15.8057 0.5 16.998 0.99386 17.8771 1.87294C18.7561 2.75201 19.25 3.9443 19.25 5.1875C19.25 6.4307 18.7561 7.62299 17.8771 8.50206C16.998 9.38114 15.8057 9.875 14.5625 9.875Z'
      fill={props.fill}
    />
    <path
      d='M14.5625 9.875H15.3438C16.1017 9.87491 16.8339 10.1503 17.4039 10.6499C17.9739 11.1495 18.3429 11.8392 18.4422 12.5906L23.3016 10.4312C23.5394 10.3253 23.8 10.2804 24.0596 10.3008C24.3192 10.3211 24.5696 10.406 24.788 10.5478C25.0065 10.6895 25.186 10.8836 25.3104 11.1124C25.4347 11.3412 25.4999 11.5974 25.5 11.8578V23.5172C25.4998 23.7774 25.4346 24.0334 25.3103 24.262C25.1861 24.4906 25.0067 24.6846 24.7885 24.8263C24.5702 24.968 24.3201 25.0529 24.0607 25.0735C23.8013 25.094 23.5409 25.0494 23.3031 24.9438L18.4422 22.7844C18.3429 23.5358 17.9739 24.2255 17.4039 24.7251C16.8339 25.2247 16.1017 25.5001 15.3438 25.5H3.625C2.7962 25.5 2.00134 25.1708 1.41529 24.5847C0.82924 23.9987 0.5 23.2038 0.5 22.375V13C0.5 12.1712 0.82924 11.3763 1.41529 10.7903C2.00134 10.2042 2.7962 9.875 3.625 9.875H14.5625Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconGroup };
