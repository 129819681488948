import React, { FunctionComponent } from 'react';
import { translate } from './RadioGroupField.translations';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassRadioGroup, BrassRadioGroupProps } from '../../../inputs';
import { BrassError, BrassHint } from '../../../labels';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';

interface RadioGroupFieldInputProps extends BrassRadioGroupProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
  layout?: 'horizontal' | 'vertical';
}

const RadioGroupFieldInput: FunctionComponent<RadioGroupFieldInputProps> = (
  props: any,
) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    name,
    layout,
    valid,
    disabled,
    hint,
    required,
    loading,
    ...others
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  return (
    <FieldWrapper>
      {label && (
        <Label
          style={{ opacity: 0.6 }}
          id={labelId}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {required ? `${props.label}*` : props.label}
        </Label>
      )}
      <BrassRadioGroup
        className='brass-radio-group'
        ariaDescribedBy={`${hindId} ${errorId}`}
        ariaLabelledBy={labelId}
        valid={valid}
        layout={layout || 'vertical'}
        disabled={disabled}
        name={name!}
        {...others}
      />
      {showHint && <BrassHint id={hindId}>{hint}</BrassHint>}
      {showValidationMessage && (
        <BrassError id={errorId}>{validationMessage}</BrassError>
      )}
    </FieldWrapper>
  );
};

interface BrassRadioGroupFieldProps
  extends Omit<BrassFieldProps & RadioGroupFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}

const BrassRadioGroupField: FunctionComponent<BrassRadioGroupFieldProps> = (
  props: BrassRadioGroupFieldProps,
) => {
  const { name, required, loading } = props;

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassRadioGroup}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <BrassField
      {...props}
      name={name!}
      validator={validators}
      component={RadioGroupFieldInput}
      label={props.label}
    />
  );
};

export { BrassRadioGroupField };
export type { BrassRadioGroupFieldProps };
