import React, { FunctionComponent, memo, useEffect, useState } from 'react';
// import style from './DropDownField.module.scss';
import { Label } from '@progress/kendo-react-labels';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import {
  DropDownListChangeEvent,
  DropDownsPopupSettings,
} from '@progress/kendo-react-dropdowns';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassLoader } from '../../../indicators';
import { BrassError } from '../../../labels';
import { BrassDropDownList, BrassDropDownListProps } from '../../../dropdown';
import { requiredValidator } from '../validators/required';
import { DropDownData } from '../../../../../models';
import { LocalStorageService } from '../../../../../services';
import { LoaderSize } from '@progress/kendo-react-indicators';
import { BrassDropDownListV1 } from '../../../dropdown/dropdown-list/DropDownListV1';

interface DropDownInputProps extends BrassDropDownListProps {
  value?: string;
  visited?: boolean;
  disabled?: boolean;
  label?: string;
  options: DropDownData<any>[];
  popupSettings?: DropDownsPopupSettings;
  useLastValue?: boolean;
}

const DropDownInput: FunctionComponent<DropDownInputProps> = memo(
  (fieldRenderProps: DropDownInputProps) => {
    const {
      options,
      label,
      disabled,
      onChange,
      visited,
      defaultValue,
      validationMessage,
      popupSettings,
      useLastValue,
      name,
      onLeftButtonClick,
      leftButtonIcon,
    } = fieldRenderProps;

    const [internalValue, setInternalValue] = useState<DropDownData | undefined>(
      undefined,
    );
    const [lastValue, setLastValue] = useState<DropDownData | undefined>(undefined);

    const onInternalChange = (e: DropDownListChangeEvent): void => {
      if (onChange) {
        onChange(e.value);
      }
      setInternalValue(e.value);
      const lastData = LocalStorageService.getLocalStorageJSON('defaultValue');
      const newData = {
        ...(lastData as Object),
        [name as string]: e.value,
      };
      LocalStorageService.setLocalStorageJSON('defaultValue', newData);
    };

    const _defaultValue = options!.find(
      (o: DropDownData) =>
        o?.value === internalValue?.value || o?.value === defaultValue?.value,
    );

    useEffect(() => {
      if (!_defaultValue && options!.length === 1) {
        const newValue = { ...options![0] };

        if (onChange) {
          onChange({
            value: newValue.value,
          } as DropDownListChangeEvent);
        }
      }
    }, [options]);

    useEffect(() => {
      if (useLastValue) {
        const savedData: any =
          LocalStorageService.getLocalStorageJSON('defaultValue');
        if (savedData) {
          Object.entries(savedData as Object).forEach((res) => {
            if (res[0] === name) {
              if (options.findIndex((_) => _.value === res[1].value) >= 0) {
                setInternalValue(res[1]);
                setLastValue(res[1]);
                if (onChange) {
                  onChange({
                    value: res[1].value,
                  } as DropDownListChangeEvent);
                }
              }
            }
          });
        }
      }
    }, []);

    if (options.length === 1) {
      return (
        <div style={fieldRenderProps.style}>
          <BrassDropDownListV1
            id={label}
            label={label}
            data={options}
            textField='text'
            dataItemKey='value'
            value={options[0]}
            style={{ height: '20px' }}
            defaultValue={options[0].value}
            filterable={false}
            popupSettings={popupSettings}
            leftButtonIcon={leftButtonIcon}
            onLeftButtonClick={onLeftButtonClick}
          />
        </div>
      );
    }

    if (useLastValue) {
      return (
        <div style={fieldRenderProps.style}>
          <BrassDropDownListV1
            id={label}
            label={label}
            disabled={disabled}
            textField='text'
            dataItemKey='value'
            defaultValue={lastValue ? lastValue : null}
            onChange={onInternalChange}
            data={options.filter((option) => option?.text && option?.value)}
            popupSettings={popupSettings}
            leftButtonIcon={leftButtonIcon}
            onLeftButtonClick={onLeftButtonClick}
          />
          {visited! && validationMessage && (
            <BrassError>{validationMessage}</BrassError>
          )}
        </div>
      );
    }

    return (
      <div style={fieldRenderProps.style}>
        <BrassDropDownListV1
          id={label}
          label={label}
          disabled={disabled}
          textField='text'
          dataItemKey='value'
          defaultValue={_defaultValue}
          onChange={onInternalChange}
          data={options.filter((option) => option?.text && option?.value)}
          popupSettings={popupSettings}
          leftButtonIcon={leftButtonIcon}
          onLeftButtonClick={onLeftButtonClick}
        />
        {visited! && validationMessage && (
          <BrassError>{validationMessage}</BrassError>
        )}
      </div>
    );
  },
);

interface BrassDropDownFieldPropsV2
  extends Omit<BrassFieldProps & DropDownInputProps, 'valid'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  component?: any;
  options?: DropDownData<any>[];
  className?: string;
  onChange?: (e: any) => void;
  defaultValue?: any;
  className_label?: string;
  size?: LoaderSize;
}

const BrassDropDownFieldV2: FunctionComponent<BrassDropDownFieldPropsV2> = memo(
  (props: BrassDropDownFieldPropsV2) => {
    const { name, required, loading, disabled, className, className_label, size } =
      props;

    const validators = [];
    if (required) {
      validators.push(requiredValidator);
    }

    if (loading) {
      return (
        <FieldWrapper className={className}>
          <Label
            style={{ opacity: 0.6, fontSize: '11px' }}
            className={className_label}
          >
            {required ? `${props.label}*` : props.label}
          </Label>
          <BrassLoader primary={false} size={size} />
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper className={className}>
        <Label
          style={{ opacity: 0.6, fontSize: '11px' }}
          className={className_label}
        >
          {required ? `${props.label}*` : props.label}
        </Label>
        <BrassField
          {...props}
          name={name!}
          validator={validators}
          component={DropDownInput}
          label={undefined}
          disabled={disabled}
        />
      </FieldWrapper>
    );
  },
);

export { BrassDropDownFieldV2 };
export type { BrassDropDownFieldPropsV2 };
