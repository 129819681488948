import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconAvatar: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.1557 11.2457V16.8571C15.1557 17.0171 15.0271 17.1429 14.87 17.1429H12.6071V14.3143C12.6071 14.12 12.4814 13.9629 12.3214 13.9629C12.1643 13.9629 12.0357 14.12 12.0357 14.3143V17.1429H7.96142V14.3143C7.96142 14.12 7.83284 13.9629 7.6757 13.9629C7.51857 13.9629 7.38999 14.12 7.38999 14.3143V17.1429H5.13C4.97284 17.1429 4.84428 17.0171 4.84428 16.8571V11.2457C4.84428 10.1028 5.84428 9.17142 7.07571 9.17142H8.13284C8.22999 9.17142 8.31856 9.22 8.36999 9.29999L10.23 12.0971L12.0843 9.29999C12.1357 9.22 12.2243 9.17142 12.3214 9.17142H12.9243C14.1528 9.17142 15.1557 10.1028 15.1557 11.2457Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M9.99856 8.92857C11.5243 8.92857 12.6271 6.87142 12.6271 5.48571C12.6271 4.03714 11.4471 2.85714 9.99856 2.85714C8.54999 2.85714 7.37285 4.03714 7.37285 5.48571C7.37285 6.87142 8.4757 8.92857 9.99856 8.92857Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconAvatar };
