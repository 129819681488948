import React, {
  Fragment,
  FunctionComponent,
  useContext,
  memo,
  useEffect,
} from 'react';
import style from './DetailsViewerBar.module.scss';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import {
  BrassButtonCollapseDown,
  BrassDropDownButtonAdd,
  BrassButtonInformation,
  NavigatorPermissions,
  BrassButtonExpandUp,
  BrassButtonDownload,
  BrassButtonRefresh,
  ConnectPermissions,
  BrassButtonShare,
  BrassSearchInput,
  BrassBarSpacer,
  ViewerContext,
  I18nService,
  emptyGuid,
  BrassBar,
  AuthUser,
  II18n,
  EnvironmentService,
} from '../../../../../../';

const DetailsViewerBar: FunctionComponent = memo(() => {
  const {
    showDetails,
    navigatorFile,
    loadData,
    showHideDetailsClick,
    showHideDownloadDialog,
    showHideAddSystemDialog,
    showHideAddAreaDialog,
    onDetailsViewerBarFilterChange,
    showHideDocumentInformationsDialog,
    showHideViewedDocumentSharingDialog,
  } = useContext(ViewerContext);

  const area: string = translate('area').toUpperCase();
  const system: string = translate('system').toUpperCase();

  // NOTA: quando for arquivo não haverá filhos assim não exibe o Expandir
  const isFile = navigatorFile.document.isFile;
  useEffect(() => {
    if (isFile) {
      showHideDetailsClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hasDocumentId = navigatorFile.document.id !== emptyGuid();

  const showDownloadButton =
    hasDocumentId &&
    navigatorFile.document.conversionFinished &&
    navigatorFile.document.download &&
    AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILE_ALLOW_DOWNLOAD) &&
    EnvironmentService.isNavigatorIntegrator;

  const showShareButton =
    AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_READ) &&
    (AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_CREATE) ||
      AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_UPDATE) ||
      AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILESHARE_DELETE)) &&
    EnvironmentService.isNavigatorIntegrator;

  // Quando o usuário estiver em PROJETO pode adicionar uma Área ou Sistema,
  // quando estiver em uma área, somente pode adicionar SISTEMAS
  const isArea = navigatorFile.areaId !== emptyGuid();
  const isSystem = navigatorFile.systemId !== emptyGuid();
  const isProjectHomePage =
    navigatorFile.projectId !== emptyGuid() &&
    navigatorFile.fileId === emptyGuid() &&
    navigatorFile.systemId === emptyGuid() &&
    navigatorFile.areaId === emptyGuid();

  const allowCreateRecord = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_FILE_CREATE,
  );
  const showUploadButton = !navigatorFile.document.isFile;

  const allowAreaCreate = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_AREA_CREATE,
  );
  const allowSystemCreate = AuthUser.checkPermission(
    ConnectPermissions.CONNECT_SYSTEM_CREATE,
  );

  // verificar opções para adicionar novo sistema ou nova área
  let addableItems: string[] = [];
  if (
    (isProjectHomePage || isArea) &&
    AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_AREA_CREATE)
  ) {
    addableItems = [area];
  }
  if (
    (isProjectHomePage || isSystem) &&
    AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE)
  ) {
    addableItems = [...addableItems, system];
  }

  // ------------------------------------------------------------------------------------
  if (isArea && allowSystemCreate) {
    addableItems = [...addableItems, system];
  }
  if (isSystem && allowSystemCreate) {
    addableItems = [...addableItems, system];
  }

  // Cria uma única versão dos Items
  // TODO: mover esta função para CORE->UTIL->Array.tsx
  addableItems = addableItems.filter((v, i, a) => a.indexOf(v) === i);

  // ------------------------------------------------------------------------------------
  const handleAddClick = (event: DropDownButtonItemClickEvent): void => {
    if (event.item === area && allowAreaCreate) {
      showHideAddAreaDialog();
    } else if (event.item === system && allowSystemCreate) {
      showHideAddSystemDialog();
    }
  };

  return (
    <BrassBar position='bottom' themeColor='dark' className={style['app-bar']}>
      <div className={style['details-name']}>
        {navigatorFile.tag && `${navigatorFile.tag} - `}
        {navigatorFile.name}
      </div>

      <BrassBarSpacer />
      {/* <BrassButtonEdit themeColor='base' fillMode='flat' onClick={showHideEditDialog} /> */}

      {showDetails && (
        <BrassSearchInput
          classNameContainer={style['search-input']}
          onChange={(_props: InputChangeEvent) =>
            onDetailsViewerBarFilterChange(_props.value)
          }
        />
      )}

      {showDownloadButton && (
        <BrassButtonDownload
          fillMode='flat'
          themeColor='base'
          className={style['white-color']}
          onClick={showHideDownloadDialog}
        />
      )}

      {showDetails && (
        <Fragment>
          {(AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_AREA_CREATE) ||
            AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE)) &&
            EnvironmentService.isNavigatorIntegrator && (
              <BrassDropDownButtonAdd
                themeColor='base'
                fillMode='flat'
                items={addableItems}
                onItemClick={handleAddClick}
                className={style['white-color']}
              />
            )}
          <BrassButtonRefresh
            themeColor='base'
            showText={false}
            fillMode='flat'
            onClick={() => loadData()}
            className={style['white-color']}
          />
        </Fragment>
      )}

      {showShareButton && hasDocumentId && (
        <BrassButtonShare
          fillMode='flat'
          themeColor='base'
          className={style['white-color']}
          onClick={showHideViewedDocumentSharingDialog}
        />
      )}

      {EnvironmentService.isNavigatorIntegrator && hasDocumentId && (
        <BrassButtonInformation
          fillMode='flat'
          themeColor='base'
          className={style['white-color']}
          onClick={showHideDocumentInformationsDialog}
        />
      )}

      {showDetails ? (
        <BrassButtonCollapseDown
          themeColor={'base'}
          fillMode='flat'
          showText={false}
          className={style['button-up-down']}
          onClick={showHideDetailsClick}
        />
      ) : (
        <BrassButtonExpandUp
          themeColor={'base'}
          fillMode='flat'
          showText={false}
          className={style['button-up-down']}
          onClick={showHideDetailsClick}
        />
      )}
    </BrassBar>
  );
});

const translations: II18n = {
  es: {
    area: 'Área',
    system: 'Sistema',
    search: 'Buscar',
  },
  enUS: {
    area: 'Area',
    system: 'System',
    search: 'Search',
  },
  ptBR: {
    area: 'Área',
    system: 'Sistema',
    search: 'Pesquisar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { DetailsViewerBar };
