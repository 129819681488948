import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonSharedV1, BrassButtonSharedPropsV1 } from './ButtonV1';

const BrassButtonNotificationV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-notification'
    labelKey='notification'
    tooltipKey='clickToSeeNotifications'
    translations={translations}
    icon='notification'
    {...props}
  />
);

const translations: II18n = {
  es: {
    notification: 'notificación',
    clickToSeeNotifications: 'haga clic para ver notificaciones',
  },
  enUS: {
    notification: 'notification',
    clickToSeeNotifications: 'click to see notifications',
  },
  ptBR: {
    notification: 'notificação',
    clickToSeeNotifications: 'clique para ver notificações',
  },
};

export { BrassButtonNotificationV1 };
