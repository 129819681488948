import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    differentPasswords: 'Different Passwords',
    weak: 'Contraseña débil',
    medium: 'Seguridad media',
    strong: 'Contraseña segura',
  },
  enUS: {
    differentPasswords: 'Different passwords',
    weak: 'Weak password',
    medium: 'Medium security',
    strong: 'Strong password',
  },
  ptBR: {
    differentPasswords: 'Senhas diferentes',
    weak: 'Senha fraca',
    medium: 'Segurança mediana',
    strong: 'Senha forte',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
