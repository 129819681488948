import React from 'react';
import { I18nService, II18n } from '../../../../index';

const translations: II18n = {
  es: {
    commentInput: 'Escriba un comentario',
    insertCommentInChatSuccess: 'Comentário inserido no chat',
    insertCommentInChatError: 'Erro ao adicionar comentário',
    addComment: 'Agregar comentario',
    supportComment: 'Comentario de apoyo',
  },
  enUS: {
    commentInput: 'Type a comment',
    insertCommentInChatSuccess: 'Comment add in chat',
    insertCommentInChatError: 'Error in add comment',
    addComment: 'Add comment',
    supportComment: 'Support comment',
  },
  ptBR: {
    commentInput: 'Digite um comentário',
    insertCommentInChatSuccess: 'Comentário inserido no chat',
    insertCommentInChatError: 'Erro ao adicionar comentário',
    addComment: 'Adicionar comentário',
    supportComment: 'Apoiar comentário',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
