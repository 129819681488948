import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconThecnicalSupport: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 18 16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.22801 0.695504C7.80666 0.64928 6.37051 1.03594 5.13067 1.86354C3.73842 2.79394 2.76517 4.16804 2.31717 5.70404C2.19315 5.69004 2.04491 5.69557 1.86912 5.74538C1.21157 5.93315 0.717373 6.47025 0.495156 6.94446C0.207139 7.5625 0.0855893 8.37981 0.214039 9.22363C0.341383 10.0648 0.696723 10.7581 1.14112 11.1888C1.58669 11.6195 2.09027 11.7606 2.59027 11.6544C3.33467 11.4955 3.70381 11.3766 3.59959 10.6831L3.09491 7.32108C3.19673 5.50158 4.13872 3.78858 5.71407 2.73541C7.82274 1.32695 10.5881 1.41718 12.5994 2.9612C13.9987 4.03405 14.8122 5.64186 14.9049 7.3292L14.5518 9.68103C13.7649 11.835 11.8146 13.327 9.55527 13.5389H8.05169C7.66412 13.5389 7.35169 13.8513 7.35169 14.2389V14.6082C7.35169 14.9962 7.66412 15.3086 8.05169 15.3086H9.94752C10.3354 15.3086 10.6467 14.9962 10.6467 14.6082V14.4151C12.349 13.9995 13.8365 12.953 14.8028 11.4938L15.4104 11.6549C15.9047 11.7831 16.414 11.62 16.8595 11.1893C17.3039 10.7587 17.6593 10.0653 17.7866 9.22416C17.9151 8.38035 17.7902 7.56433 17.5053 6.945C17.2195 6.32573 16.7935 5.93236 16.3015 5.79121C16.0955 5.7319 15.8722 5.71016 15.68 5.70436C15.2751 4.3155 14.4404 3.05236 13.238 2.1302C12.0555 1.22285 10.649 0.74263 9.22797 0.695947L9.22801 0.695504Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.76951 4.77667H9.23019C9.42219 4.77667 9.57622 4.85199 9.57622 5.00577C9.57622 5.45935 9.64465 5.63643 9.80774 5.70375C9.97089 5.77068 10.1435 5.69424 10.464 5.37355C10.574 5.26489 10.7349 5.32179 10.8702 5.45714L11.1967 5.78201C11.332 5.91736 11.3876 6.07954 11.2789 6.18819C10.9582 6.50869 10.8807 6.68246 10.9491 6.84464C11.0162 7.00779 11.1934 7.07713 11.6471 7.07713C11.802 7.07713 11.8762 7.2311 11.8762 7.42198V7.88291C11.8762 8.07399 11.802 8.22776 11.6471 8.22776C11.1934 8.22776 11.0162 8.29729 10.9491 8.46018C10.8818 8.62242 10.9582 8.79598 11.2789 9.11663C11.3876 9.22535 11.332 9.38733 11.1967 9.52268L10.8702 9.84801C10.7349 9.9833 10.5727 10.04 10.464 9.92998C10.1435 9.60934 9.96979 9.53421 9.80774 9.60133C9.64465 9.66819 9.57622 9.84553 9.57622 10.2991C9.57622 10.4531 9.42245 10.5271 9.23019 10.5271H8.76951C8.57862 10.5271 8.42466 10.4531 8.42466 10.2991C8.42466 9.84553 8.35506 9.66819 8.19307 9.60133C8.02999 9.53401 7.85622 9.60933 7.53572 9.92998C7.42687 10.04 7.26489 9.9833 7.12954 9.84801L6.80421 9.52268C6.66886 9.38732 6.61332 9.22534 6.72198 9.11663C7.04261 8.79599 7.11905 8.62243 7.05063 8.46018C6.98351 8.29729 6.80753 8.22776 6.35376 8.22776C6.19888 8.22776 6.12466 8.07398 6.12466 7.88291V7.42243C6.12466 7.23179 6.19888 7.07758 6.35376 7.07758C6.80755 7.07758 6.98351 7.00824 7.05063 6.84509C7.11775 6.68292 7.04262 6.50934 6.72198 6.18864C6.61332 6.07998 6.66886 5.91774 6.80421 5.78246L7.12954 5.45753C7.26489 5.32224 7.42687 5.26534 7.53572 5.374C7.85622 5.69463 8.02999 5.77113 8.19307 5.7042C8.35505 5.63688 8.42466 5.4598 8.42466 5.00621C8.42485 4.85198 8.57863 4.77666 8.76951 4.77666V4.77667ZM9.00109 2.84133C6.33659 2.84133 4.18976 4.91783 4.18976 7.654C4.18976 8.96623 4.68507 10.1272 5.49419 10.9788L5.20707 12.2659C5.11222 12.6895 5.40622 12.9744 5.78689 12.7624L7.04372 12.0611C7.64106 12.3204 8.30309 12.4637 9.00106 12.4637C11.6664 12.4637 13.8112 10.3884 13.8112 7.6524C13.8112 4.91773 11.6666 2.84123 9.00106 2.84123L9.00109 2.84133Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.0005 6.58334C8.41034 6.58334 7.93097 7.06289 7.93097 7.65294C7.93097 8.24318 8.41007 8.72248 9.0005 8.72248C9.591 8.72248 10.0701 8.24338 10.0701 7.65294C10.0698 7.06271 9.59074 6.58334 9.0005 6.58334Z'
      fill='white'
    />
  </svg>
);

export { SVGIconThecnicalSupport };
