import React, {
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import style from './TreeListGrid.v3.module.scss';
import { BrassTreeListGridPropsV3 } from './TreeListGridProps.v3.interface';
import {
  onItemChange,
  onHeaderSelectionChange,
  onSelectionChange,
  getColumns,
  getGridToolbar,
  getHelpPanel,
  getExcelExport,
  getPDFExport,
  getGridBrassLoader,
  onDataStateChange,
  onSortChange,
  onPageChange,
  onFilterChange,
  getWindowWidth,
  getUpsertWindow,
  pageData,
  getExcelExportColumns,
  onGroupChange,
  onExpandChange,
  cellRender,
} from './TreeListGrid.v3.functions';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import {
  Grid,
  GridCellProps,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridGroupChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassGridConfigDefault,
  BrassSplitter,
  MainLayoutContainerContext,
} from '../../../..';
import {
  AggregateDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';

const USER_HELP_PANEL_SIZE = 400;

const RenderGridV3 = (props: BrassTreeListGridPropsV3): ReactNode => {
  const {
    sortable,
    reorderable,
    gridState,
    extraGridToolbar,
    customFilterGridToolbar,
    selectable,
    pageable,
    groupable,
    filterable,
    upsertWindow,
    className,
  } = props;
  const _className = classnames('brass-grid', style['brass-grid'], className);
  const anchorRef = useRef<any>(null);
  const { menuExpanded } = useContext(MainLayoutContainerContext);

  const excelExportColumns = getExcelExportColumns(props);
  const columns: GridColumnProps[] = getColumns(props);

  let gridPDFExport: GridPDFExport | null;
  const gridExcelExport = useRef<ExcelExport | null>(null);

  /** REVER */

  const [windowWidth, setwindowWidth] = useState<string>(
    getWindowWidth(menuExpanded),
  );
  useEffect(() => {
    const recalculateWindowWidth = () =>
      setwindowWidth(getWindowWidth(menuExpanded));
    setwindowWidth(getWindowWidth(menuExpanded));
    window.addEventListener('resize', recalculateWindowWidth, true);

    return () => window.removeEventListener('resize', recalculateWindowWidth);
  }, [menuExpanded, window.innerWidth]);

  const aggregates = columns!
    // @ts-ignore
    .filter((col) => col.props && col.props.aggregate)
    // @ts-ignore
    .map((col) => col.props)
    .map(
      (col) =>
        ({
          field: col.aggregateField,
          aggregate: col.aggregate,
        } as AggregateDescriptor),
    );

  const groups = gridState.dataState.group;
  if (groups) {
    groups.forEach((group) => (group.aggregates = aggregates));
  }

  /* */
  return (
    <Fragment>
      {gridState.gridLoading && getGridBrassLoader(anchorRef)}
      <BrassSplitter
        panes={[
          {},
          {
            size: `${USER_HELP_PANEL_SIZE}px`,
            resizable: false,
          },
        ]}
      >
        <Grid
          ref={anchorRef}
          style={{
            width: gridState.showHelpPanel
              ? `${Number(windowWidth.split('px')[0]) - USER_HELP_PANEL_SIZE}px`
              : `${Number(windowWidth.split('px')[0]) - 25}px`,
          }}
          dataItemKey={gridState.dataItemKey}
          expandField='expanded'
          editField='inEdit'
          resizable={true}
          cellRender={(
            defaultRendering: ReactElement<
              HTMLTableCellElement,
              string | JSXElementConstructor<any>
            > | null,
            originalProps: GridCellProps,
          ) => cellRender(defaultRendering, originalProps, props)}
          sortable={sortable!}
          reorderable={reorderable!}
          data={gridState.dataResult}
          {...gridState.dataState}
          className={classnames(_className, style['grid-footer'])}
          onItemChange={(event: GridItemChangeEvent) => onItemChange(event, props)}
          onDataStateChange={(event: GridDataStateChangeEvent) =>
            onDataStateChange(event, props)
          }
          pageable={pageable ? pageData : undefined}
          pageSize={
            gridState.gridConfig
              ? gridState.gridConfig.take
              : BrassGridConfigDefault.take
          }
          total={gridState.recordsList.total}
          onPageChange={(event: GridPageChangeEvent) => onPageChange(event, props)}
          groupable={{
            enabled: !!gridState.gridConfig.group,
            footer: groupable?.footer,
          }}
          group={gridState.group}
          onGroupChange={(event: GridGroupChangeEvent) =>
            onGroupChange(event, props)
          }
          onExpandChange={(event: GridExpandChangeEvent) =>
            onExpandChange(event, props)
          }
          sort={gridState.gridConfig.sort!}
          onSortChange={(event: GridSortChangeEvent) => onSortChange(event, props)}
          filterable={filterable}
          filter={gridState.gridFilter as CompositeFilterDescriptor}
          onFilterChange={(event: GridFilterChangeEvent) =>
            onFilterChange(event, props)
          }
          selectable={selectable}
          selectedField='selected'
          onSelectionChange={(event: GridSelectionChangeEvent) =>
            onSelectionChange(event, props)
          }
          onHeaderSelectionChange={(event: GridHeaderSelectionChangeEvent) =>
            onHeaderSelectionChange(event, props)
          }
        >
          {getGridToolbar(props, gridPDFExport!, gridExcelExport)}
          {gridState.showFilterPanel && customFilterGridToolbar && (
            <GridToolbar>{customFilterGridToolbar}</GridToolbar>
          )}
          {extraGridToolbar}
          {!gridState.gridLoading && <GridNoRecords />}
          {columns}
        </Grid>
        {getHelpPanel(props)}
      </BrassSplitter>

      {upsertWindow && getUpsertWindow(props)}
      <div style={{ overflow: 'hidden', maxHeight: 0, maxWidth: 0 }}>
        {getExcelExport(props, excelExportColumns || columns, gridExcelExport)}
        {getPDFExport(
          props,
          columns,
          (pdfExport: GridPDFExport) => (gridPDFExport = pdfExport),
        )}
      </div>
    </Fragment>
  );
};

export { RenderGridV3 };
