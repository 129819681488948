import React from 'react';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    usersList: 'Usuarios fuera de este grupo',
    name: 'Nombre',
    email: 'Correo electrónico',
    insert: 'Insertar {0} usuarios seleccionados',
  },
  enUS: {
    usersList: 'Users outside this group',
    name: 'Name',
    email: 'E Mail',
    insert: 'Insert {0} selected users',
  },
  ptBR: {
    usersList: 'Usuários fora deste grupo',
    name: 'Nome',
    email: 'Email',
    insert: 'Inserir {0} usuários selecionados',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
