import React, { FunctionComponent, memo, useCallback } from 'react';
import style from './TreeListGrid.v3.module.scss';
import { RenderGridV3 } from './TreeListGrid.v3.grid';
import { RenderTreeListV3 } from './TreeListGrid.v3.treeList';
import { BrassTreeListGridPropsV3 } from './TreeListGridProps.v3.interface';
import { AccessDenied, BrassLogo } from '../../../..';

const BrassTreeListGridV3: FunctionComponent<BrassTreeListGridPropsV3> = memo(
  (props: BrassTreeListGridPropsV3) => {
    const render = useCallback(
      () => (props.treeListColumns ? RenderTreeListV3(props) : RenderGridV3(props)),
      [props],
    );

    if (!props.permissions!.read) {
      return <AccessDenied />;
    }

    return <div style={{ display: 'block' }}>{render()}</div>;
  },
);

BrassTreeListGridV3.defaultProps = {
  gridColumns: [],
  permissions: {
    read: true,
  },
  pageable: true,
  groupable: {
    enabled: true,
    footer: 'none',
  },
  reorderable: true,
  filterable: true,
  sortable: {
    mode: 'multiple',
  },
  selectable: {
    drag: false,
    cell: false,
    enabled: false,
    mode: 'multiple',
  },
};

export { BrassTreeListGridV3 };
