import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { WorkTimeRecordSubmodule } from './WorkTimeRecord.submodule.enum';

enum WorkTimeRecordPermissionsV1 {
  /// <summary>
  /// Apropriação no RHT           [(FE) /home/connect/work-hours-report/register-activity]
  /// Consultar Lançamentos no RHT [(FE) /home/connect/work-hours-report/consult-entries]
  /// NOTA: Não pode ser exportado estes dados para o usuário!!!
  /// </summary>
  APPROPRIATE_TIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.READ,
  APPROPRIATE_TIME_CREATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.CREATE,
  APPROPRIATE_TIME_UPDATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.UPDATE,
  APPROPRIATE_TIME_DELETE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.DELETE,
  /// <summary>
  /// Registro de Atividade Administrativa no RHT [(FE) /home/connect/work-hours-report/register-admin-activity]
  /// NOTA: Permissão do RH para efetuar lançamentos Administrativos em nome de outro usuário/colaborador
  /// </summary>
  ADMINISTRATIVE_APPROPRIATE_TIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.ADMINISTRATIVE_APPROPRIATE_TIME +
    Action.READ,
  ADMINISTRATIVE_APPROPRIATE_TIME_CREATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.ADMINISTRATIVE_APPROPRIATE_TIME +
    Action.CREATE,
  ADMINISTRATIVE_APPROPRIATE_TIME_UPDATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.ADMINISTRATIVE_APPROPRIATE_TIME +
    Action.UPDATE,
  ADMINISTRATIVE_APPROPRIATE_TIME_DELETE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.ADMINISTRATIVE_APPROPRIATE_TIME +
    Action.DELETE,
  /// <summary>
  /// Submissão no RHT [(FE) /home/connect/work-hours-report/submit-report]
  /// </summary>
  APPROPRIATE_TIME_SUBMIT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.SUBMIT,
  /// <summary>
  /// Aprovação no RHT [(FE) /home/connect/work-hours-report/approve-report]
  /// </summary>
  APPROPRIATE_TIME_APPROVE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.APPROVE,
  /// <summary>
  /// Validar Apropriação no RHT [(FE) /home/connect/work-hours-report/validate-report]
  /// </summary>
  APPROPRIATE_TIME_VALIDATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.APPROPRIATE_TIME +
    Action.VALIDATE,
  /// <summary>
  /// Pedidos de Horas Extras [(FE) /home/connect/work-hours-report/my-overtime-requests]
  /// </summary>
  MY_OVERTIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.MY_OVERTIME +
    Action.READ,
  MY_OVERTIME_CREATE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.MY_OVERTIME +
    Action.CREATE,
  MY_OVERTIME_DELETE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.MY_OVERTIME +
    Action.DELETE,
  /// <summary>
  /// Aprovação de Pedidos de Horas Extras do meu Time   [(FE) /home/connect/work-hours-report/my-team-overtime-approval-requests]
  /// </summary>
  TEAM_OVERTIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.TEAM_OVERTIME +
    Action.READ,
  TEAM_OVERTIME_APPROVE = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.TEAM_OVERTIME +
    Action.APPROVE,
  /// <summary>
  /// Relatório de Custo Incorrido [(FE)  /home/connect/work-hours-report/reports/project-cost]
  /// </summary>
  REPORT_PROJECT_SYSTEM_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_COST +
    Action.READ,
  REPORT_PROJECT_SYSTEM_COST_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_COST +
    Action.EXPORT,
  /// <summary>
  /// Relatório de Tempo Incorrido [(FE) /home/connect/work-hours-report/reports/project-time]
  /// </summary>
  REPORT_PROJECT_SYSTEM_TIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_TIME +
    Action.READ,
  REPORT_PROJECT_SYSTEM_TIME_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_TIME +
    Action.EXPORT,
  /// <summary>
  /// Relatório de Atividades Executadas com Custo [(FE) /home/connect/work-hours-report/reports/activities-cost-grid]
  /// </summary>
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_COST +
    Action.READ,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_COST_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_COST +
    Action.EXPORT,
  /// <summary>
  /// Atividades Executadas por Usuário [(FE) home/connect/work-hours-report/reports/executed-user-activities]
  /// </summary>
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_TIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_TIME +
    Action.READ,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_TIME_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_TIME +
    Action.EXPORT,
  /// <summary>
  /// Atividades Executadas por Usuário com Custo [(FE) /home/connect/work-hours-report/reports/executed-user-activities-cost]
  /// </summary>
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_COST +
    Action.READ,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_COST_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_COST +
    Action.EXPORT,
  /// <summary>
  /// Lançamentos do Usuário no Período [(FE) /home/connect/work-hours-report/reports/employee-entries]
  /// </summary>
  REPORT_APPROPRIATE_TIME_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_APPROPRIATE_TIME +
    Action.READ,
  REPORT_APPROPRIATE_TIME_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_APPROPRIATE_TIME +
    Action.EXPORT,
  /// <summary>
  /// Projetos por Status [(FE) /home/connect/work-hours-report/reports/project-by-status]
  /// </summary>
  REPORT_PROJECT_BY_STATUS_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_BY_STATUS +
    Action.READ,
  REPORT_PROJECT_BY_STATUS_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_PROJECT_BY_STATUS +
    Action.EXPORT,
  /// <summary>
  /// Relatório de Atividades [(FE) /home/connect/work-hours-report/reports/activities]
  /// </summary>
  REPORT_ACTIVITIES_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_ACTIVITIES +
    Action.READ,
  REPORT_ACTIVITIES_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_ACTIVITIES +
    Action.EXPORT,
  /// <summary>
  /// Relatório de Banco de Horas por Colaborador [(FE) /home/connect/work-hours-report/reports/compensatory-time-off]
  /// </summary>
  REPORT_COMPENSATORY_TIME_OFF_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_COMPENSATORY_TIME_OFF +
    Action.READ,
  REPORT_COMPENSATORY_TIME_OFF_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_COMPENSATORY_TIME_OFF +
    Action.EXPORT,
  /// <summary>
  /// Relatório de colaboradores [(FE) /home/connect/work-hours-report/reports/employee-activity] EMPLOYEE
  /// </summary>
  REPORT_EMPLOYEE_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_EMPLOYEE +
    Action.READ,
  REPORT_EMPLOYEE_EXPORT = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.REPORT_EMPLOYEE +
    Action.EXPORT,
  INDICATOR_BUDGET_MH_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_BUDGET_MH +
    Action.READ,
  INDICATOR_BUDGET_A1_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_BUDGET_A1 +
    Action.READ,
  INDICATOR_BUDGET_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_BUDGET_COST +
    Action.READ,
  INDICATOR_AVERAGE_HISTORY_MH_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_AVERAGE_HISTORY_MH_COST +
    Action.READ,
  INDICATOR_AVERAGE_HISTORY_A1_COST_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_AVERAGE_HISTORY_A1_COST +
    Action.READ,
  INDICATOR_AVERAGE_HISTORY_PRODUCTIVITY_READ = PermissionModule.WORK_TIME_RECORD +
    WorkTimeRecordSubmodule.INDICATOR_AVERAGE_HISTORY_PRODUCTIVITY +
    Action.READ,
}

export { WorkTimeRecordPermissionsV1 };
