import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  BrassTextField,
  BrassTextFieldProps,
} from '../../../../../../../components';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    groupName: 'Nombre del grupo',
  },
  enUS: {
    groupName: 'Group name',
  },
  ptBR: {
    groupName: 'Nome do grupo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const NameField: FunctionComponent<PropsWithChildren<any>> = (
  props: BrassTextFieldProps,
) => <BrassTextField {...props} name='name' label={translate('groupName')} />;

export { NameField };
