import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    config: 'Configuración',
    active: 'Activo',
    INACTIVE: 'Inactivo',
    CREATED: 'Creado',
    ACTIVE: 'Activo',
    EDITING: 'En edición',
    SUSPENDED: 'Suspendido',
    BLOCKED: 'Bloqueado',
    name: 'Nombre',
    email: 'Correo electrónico',
    cellPhoneNumber: 'Número de teléfono celular',
    status: 'Estado',
    actions: 'Acciones',
    discipline: 'Disciplina',
    gridTitle: 'Gestión de usuarios',
    gridItem: 'Usuario',
    users: 'Gestión de usuarios',
    refresh: 'Actualizar',
    buttonNew: 'Nuevo usuario',
    successOnUpdate: 'Usuario actualizado con éxito',
    errorOnUpdate: 'Error al intentar actualizar al usuario',
    successOnDelete: 'Usuario eliminado con éxito',
    errorOnDelete: 'Error al intentar eliminar al usuario',
    successActive: 'Usuario bloqueado con éxito',
    successSuspensed: 'Usuario suspendido con éxito',
    successBlocked: 'Usuario bloqueado con éxito',
    confirmationDelete: '¿Realmente quieres eliminar a este usuario?',
  },
  enUS: {
    config: 'Configuration',
    active: 'Active',
    INACTIVE: 'Inactive',
    CREATED: 'Created',
    ACTIVE: 'Active',
    EDITING: 'Editing',
    SUSPENDED: 'Suspended',
    BLOCKED: 'Blocked',
    name: 'Name',
    email: 'Email',
    cellPhoneNumber: 'Cell phone number',
    status: 'Status',
    actions: 'Actions',
    discipline: 'Discipline',
    gridTitle: 'User management',
    gridItem: 'User',
    users: 'User management',
    refresh: 'Refresh',
    buttonNew: 'New user',
    successOnUpdate: 'User updated successfully',
    errorOnUpdate: 'Error trying to update user',
    successOnDelete: 'User deleted successfully',
    errorOnDelete: 'Error trying to delete user',
    successActive: 'User blocked successfully',
    successSuspensed: 'User suspended successfully',
    successBlocked: 'User blocked successfully',
    confirmationDelete: 'Do you really want to delete this user?',
  },
  ptBR: {
    config: 'Configuração',
    active: 'Ativo',
    INACTIVE: 'Inativo',
    CREATED: 'Criado',
    ACTIVE: 'Ativo',
    EDITING: 'Em edição',
    SUSPENDED: 'Suspenso',
    BLOCKED: 'Bloqueado',
    name: 'Nome',
    email: 'Email',
    cellPhoneNumber: 'Número de celular',
    status: 'Status',
    actions: 'Ações',
    discipline: 'Disciplina',
    gridTitle: 'Gestão de usuários',
    gridItem: 'Usuário',
    users: 'Gestão de usuários',
    refresh: 'Atualizar',
    buttonNew: 'Novo usuário',
    successOnUpdate: 'Usuário atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar o Usuário',
    successOnDelete: 'Usuário excluído com sucesso',
    errorOnDelete: 'Erro ao tentar excluir o Usuário',
    successActive: 'Usuário bloqueado com sucesso',
    successSuspensed: 'Usuário bloqueado com sucesso',
    successBlocked: 'Usuário bloqueado com sucesso',
    confirmationDelete: 'Você realmente quer deletar este usuário?',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate, translations };
