import React, { FunctionComponent } from 'react';
import { translate } from './MessageField.translations';
import {
  TextAreaField,
  TextAreaFieldProps,
} from '../../../../../../../../components';

const MessageField: FunctionComponent<TextAreaFieldProps> = (
  props: TextAreaFieldProps,
) => <TextAreaField {...props} name='message' label={translate('message')} />;

export { MessageField };
