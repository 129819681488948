import React, { FunctionComponent } from 'react';
import { BrassInput, BrassSwitch } from '../../components';

const InputsPlayground: FunctionComponent = () => (
  <div>
    <BrassInput />
    <BrassSwitch />
  </div>
);

export { InputsPlayground };
