import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconTimeReport: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M9.99997 8.75001C10.9945 8.75001 11.9483 8.35489 12.6516 7.65164C13.3549 6.94839 13.75 5.99454 13.75 4.99997C13.75 4.00541 13.3549 3.05164 12.6516 2.34831C11.9484 1.64506 10.9945 1.24994 9.99997 1.24994C9.00541 1.24994 8.05164 1.64506 7.34831 2.34831C6.64506 3.05156 6.24994 4.00541 6.24994 4.99997C6.24994 5.99454 6.64506 6.94831 7.34831 7.65164C8.05156 8.35489 9.00541 8.75001 9.99997 8.75001V8.75001ZM9.68747 2.50001C9.68747 2.38835 9.74704 2.28522 9.84372 2.22937C9.9404 2.17358 10.0595 2.17358 10.1562 2.22937C10.2529 2.28523 10.3125 2.38836 10.3125 2.50001V5.15634L11.4375 6.00009C11.5268 6.06708 11.5736 6.17666 11.5603 6.28746C11.5468 6.39833 11.4754 6.49364 11.3728 6.53746C11.2701 6.58134 11.1518 6.56708 11.0625 6.50009L9.81248 5.56259C9.73376 5.50354 9.68748 5.41096 9.68748 5.31259L9.68747 2.50001Z' />
    <path d='M16.25 4.375C16.25 3.96062 16.0853 3.56315 15.7924 3.27012C15.4993 2.97715 15.1019 2.8125 14.6875 2.8125H13.7843C14.3009 3.70313 14.4869 4.74733 14.3095 5.76167C14.1321 6.77587 13.6027 7.695 12.8144 8.35733C12.0262 9.01963 11.0296 9.38278 9.99993 9.38278C8.97026 9.38278 7.97376 9.01963 7.18543 8.35733C6.39714 7.69497 5.86773 6.77582 5.69031 5.76167C5.51289 4.74747 5.69891 3.70317 6.21551 2.8125H5.31238C4.89799 2.8125 4.50053 2.97715 4.20749 3.27012C3.91453 3.56315 3.74988 3.96062 3.74988 4.375V17.1875C3.74988 17.6019 3.91453 17.9994 4.20749 18.2924C4.50053 18.5854 4.89799 18.75 5.31238 18.75H14.6874C15.1018 18.75 15.4992 18.5854 15.7923 18.2924C16.0852 17.9994 16.2499 17.6019 16.2499 17.1875L16.25 4.375ZM9.99998 9.6875C10.4144 9.6875 10.8118 9.85215 11.1049 10.1451C11.3978 10.4381 11.5625 10.8356 11.5625 11.25C11.5625 11.6644 11.3978 12.0619 11.1049 12.3549C10.8118 12.6479 10.4144 12.8125 9.99998 12.8125C9.58559 12.8125 9.18813 12.6479 8.89509 12.3549C8.60213 12.0619 8.43748 11.6644 8.43748 11.25C8.43748 10.8356 8.60213 10.4381 8.89509 10.1451C9.18813 9.85215 9.58559 9.6875 9.99998 9.6875ZM12.1875 15.625C12.1875 15.7079 12.1545 15.7874 12.0959 15.846C12.0373 15.9046 11.9579 15.9375 11.875 15.9375H8.12497C8.0421 15.9375 7.9626 15.9046 7.90401 15.846C7.84541 15.7874 7.81247 15.7079 7.81247 15.625V14.6875C7.81247 14.2731 7.97712 13.8757 8.27009 13.5826C8.56312 13.2897 8.96059 13.125 9.37497 13.125H10.625C11.0394 13.125 11.4368 13.2896 11.7299 13.5826C12.0228 13.8757 12.1875 14.2731 12.1875 14.6875L12.1875 15.625Z' />
  </svg>
);

export { SVGIconTimeReport };
