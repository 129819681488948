import React, { FunctionComponent } from 'react';
import style from './ListView.module.scss';
import classnames from 'classnames';
import { ListView, ListViewProps } from '@progress/kendo-react-listview';

interface BrassListViewProps extends ListViewProps {
  inlineItems?: boolean;
}

const BrassListView: FunctionComponent<BrassListViewProps> = (
  props: BrassListViewProps,
) => {
  const { className, inlineItems } = props;
  const _className = classnames(
    inlineItems ? style['brass-listview'] : '',
    className,
  );

  return <ListView {...props} className={_className} />;
};

BrassListView.defaultProps = {
  inlineItems: true,
};

export { BrassListView };
export type { BrassListViewProps };
