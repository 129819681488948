import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { DateInput, DateInputProps } from '@progress/kendo-react-dateinputs';

interface BrassDateInputProps extends DateInputProps {}

const BrassDateInput: FunctionComponent<BrassDateInputProps> = (
  props: BrassDateInputProps,
) => {
  const { className } = props;
  const _className = classnames('brass-date-input', className);

  return <DateInput {...props} className={_className} />;
};

export { BrassDateInput };
export type { BrassDateInputProps };
