import { BrassGridConfigProps } from '../../../components';
import { ApiGridResult, ApiResult, Endpoints } from '../../../models';
import { ApiService, PermissionService } from '../../../services';
import {
    UserGroupData,
    UserGroupNonParticipant,
    UserGroupOtherPrivilege,
    UserGroupPrivilege
} from '../models';

class UserGroupService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getGroupUsersList(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<UserGroupData>>> {
    return await UserGroupService.api.post<ApiGridResult<UserGroupData>>(
      `/connect/v1/user-groups/list/`,
      gridConfig,
    );
  }

  public static async getUserGroupData(
    userGroupId: string,
  ): Promise<ApiResult<UserGroupData>> {
    return await UserGroupService.api.get<UserGroupData>(
      `/connect/v1/user-groups/${userGroupId}`,
    );
  }

  public static async getUserGroupNonParticipants(
    gridConfig: BrassGridConfigProps,
    getRecordsParams: { userGroupId: string },
  ): Promise<ApiResult<ApiGridResult<UserGroupNonParticipant>>> {
    return await UserGroupService.api.post<ApiGridResult<UserGroupNonParticipant>>(
      `/connect/v1/user-groups/${getRecordsParams.userGroupId}/non-participants-user`,
      gridConfig,
    );
  }

  public static async getGroupUserParticipants(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<UserGroupNonParticipant>>> {
    return await new Promise((res) => {
      res(
        new ApiResult({
          data: {
            records: [],
            total: 0,
          },
          message: [JSON.stringify(gridConfig)],
        }),
      );
    });
  }

  public static async getUserGroupOtherPrivilege(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<UserGroupOtherPrivilege>>> {
    return await new Promise((res) => {
      res(
        new ApiResult({
          data: {
            records: PermissionService.permissions.map((permission: any) => {
              return {
                value: permission.value,
                text: permission.text,
                id: permission.value,
              };
            }),
            total: PermissionService.permissions.length,
          },
          message: [JSON.stringify(gridConfig)],
        }),
      );
    });
  }

  public static async getUserGroupPrivilege(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<UserGroupPrivilege>>> {
    return await new Promise((res) => {
      res(
        new ApiResult({
          data: {
            records: [],
            total: 0,
          },
          message: [JSON.stringify(gridConfig)],
        }),
      );
    });
  }

  public static async createUserGroup(userGroupData: any): Promise<ApiResult> {
    return await UserGroupService.api.post(`/connect/v1/user-groups/`, userGroupData);
  }

  public static async updateUserGroup(userGroupData: any): Promise<ApiResult> {
    return await UserGroupService.api.put(`/connect/v1/user-groups/`, userGroupData);
  }

  public static async deleteGroup(groupId: string): Promise<ApiResult> {
    return await UserGroupService.api.delete(`/connect/v1/user-groups/${groupId}`);
  }
}

export { UserGroupService };
