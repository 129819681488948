import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const BrassButtonCancel: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='base'
    className='brass-button-cancel'
    labelKey='cancel'
    tooltipKey='clickToCancel'
    translations={translations}
    icon={<BrassIcon icon={faWindowClose} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    cancel: 'cancelar',
    clickToCancel: 'haga clic para cancelar',
  },
  enUS: {
    cancel: 'cancel',
    clickToCancel: 'click to cancel',
  },
  ptBR: {
    cancel: 'cancelar',
    clickToCancel: 'clique para cancelar',
  },
};

export { BrassButtonCancel };
