import { UploadOnAddEvent } from '@progress/kendo-react-upload';
import React from 'react';
import {
  DocumentActionType,
  ResultPublishDocumentType,
} from '../../../PublishDocumentsModal.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { ImageService, UploadService } from '../../../../../../services';

type SetFilesUrlType = React.Dispatch<
  React.SetStateAction<ResultPublishDocumentType[]>
>;

const onAddMany = async (
  event: UploadOnAddEvent,
  setFilesUrl: SetFilesUrlType,
  fileType: DocumentActionType,
): Promise<any> => {
  setFilesUrl([] as ResultPublishDocumentType[]);

  event.affectedFiles.map(async (uploadFileInfo) => {
    if (uploadFileInfo?.getRawFile) {
      uploadFileInfo.uid = uuidv4();
      const imageBase64 = await ImageService.imageToBase64(
        uploadFileInfo?.getRawFile(),
      );

      setFilesUrl((prev: ResultPublishDocumentType[]) => [
        ...prev,
        {
          fileBase64: imageBase64,
          fileKey: `${uploadFileInfo?.uid ?? ''}${uploadFileInfo?.extension ?? ''}`,
          fileAws: `${UploadService.saveUrl}${uploadFileInfo?.uid ?? ''}${
            uploadFileInfo?.extension ?? ''
          }`,
          fileType,
          fileName: uploadFileInfo.name,
          property: {
            time: {
              hour: 0,
              minutes: 0,
              seconds: 0,
            },
            pages: 0,
          },
        },
      ]);
    }
  });

  UploadService.onAddFile(event);
};

export { onAddMany };
