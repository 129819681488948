import { II18n } from '../../models';

enum AreaTypeEnum {
  PHYSICAL = 'PHYSICAL',
  INSTITUTIONAL = 'INSTITUTIONAL',
}

const translationsAreaTypeEnum: II18n = {
  es: {
    PHYSICAL: 'física',
    INSTITUTIONAL: 'institucional',
  },
  enUS: {
    PHYSICAL: 'physical',
    INSTITUTIONAL: 'institutional',
  },
  ptBR: {
    PHYSICAL: 'física',
    INSTITUTIONAL: 'institucional',
  },
};

export { AreaTypeEnum, translationsAreaTypeEnum };
