export enum Module {
  ALL = 'ALL',
  CONNECT = 'CONNECT',
  PROJECT = 'PROJECT',
  AREA = 'AREA',
  SYSTEM = 'SYSTEM',
  WORK_TIME_RECORD = 'WORK_TIME_RECORD',
  FINANCE = 'FINANCE',
  SMART_LIB = 'SMART_LIB',
  SMART_TREE = 'SMART_TREE',
  SMART_FLUX = 'SMART_FLUX',
  DRIVE_DATASHEET = 'DRIVE_DATASHEET',
  DRIVE_LINE_LIST = 'DRIVE_LINE_LIST',
  PROPERTIES_MANAGER = 'PROPERTIES_MANAGER',
  INTEGRATOR = 'INTEGRATOR',
  SURVEY = 'SURVEY',
  BIND = 'BIND',
  INSPECT = 'INSPECT',
  ACADEMY = 'ACADEMY',
  SGI_BRASS = 'SGI_BRASS',
}
