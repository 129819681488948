import { faHourglassStart } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonSuspend: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-suspend'
    labelKey='suspend'
    tooltipKey='clickToSuspended'
    translations={translations}
    onClick={props.onClick}
    icon={<BrassIcon icon={faHourglassStart} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    suspend: 'Suspender',
    clickToSuspended: 'Haga clic para suspender',
  },
  enUS: {
    suspend: 'Suspend',
    clickToSuspended: 'Click to suspend',
  },
  ptBR: {
    suspend: 'Suspender',
    clickToSuspended: 'Clique para suspender',
  },
};

export { BrassButtonSuspend };
