import React from 'react';
import { emptyGuid, newGuid } from '../../util';
import { NotificationPositionType } from './notificationPosition.enum';
import { NotificationStyles } from './notificationStyles.enum';

class Notification {
  public id: string = emptyGuid();
  public style: NotificationStyles = NotificationStyles.NONE;
  public position: NotificationPositionType = 'bottomCenter';
  public icon: boolean = true;
  public timeout: number = 3000; // milliseconds
  public content: string = '';

  constructor(notification: Partial<Notification>) {
    notification.id = newGuid();
    Object.assign(this, notification);
  }
}

export { Notification };
