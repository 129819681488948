import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    changePassword: 'Cambiar mi contraseña',
    logout: 'Cerrar sesión',
    userProfile: 'Perfil del Usuario',
    loadingData: 'Cargando datos ...',
  },
  enUS: {
    changePassword: 'Change my password',
    logout: 'Logout',
    userProfile: 'User Profile',
    loadingData: 'Loading data ...',
  },
  ptBR: {
    changePassword: 'Alterar minha senha',
    logout: 'Sair',
    userProfile: 'Perfil do Usuário',
    loadingData: 'Carregando dados ...',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
