import { BrassGridConfigProps } from '../components';
import { ApiResult, Endpoints } from '../models';
import { PropertyMap } from '../models/PropertyMap.model';
import { ApiService } from './api.service';

class PropertyService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async getAllMapProperties(
    language: string,
    filter: string,
    take: number,
    skip: number,
  ): Promise<ApiResult<PropertyMap[]>> {
    return await PropertyService.api.get(
      `connect/v1/map-property/list-all?language=${language}&search=${filter}&take=${take}&skip=${skip}`,
    );
  }

  public static async getPropertiesGrid(
    gridConfig: BrassGridConfigProps,
    getRecordsParams: {
      mapPropertyId: string;
      language: string;
    },
  ): Promise<ApiResult> {
    if (!getRecordsParams.mapPropertyId) {
      return new ApiResult({ data: { records: [], total: 0 }, success: true });
    } else {
      return await PropertyService.api.post(
        `connect/v1/property/map-property/${getRecordsParams.mapPropertyId}/list-paginated?language=${getRecordsParams?.language}`,
        gridConfig as any,
      );
    }
  }

  public static async getProperty(id: string, language: string): Promise<ApiResult> {
    return await PropertyService.api.get(`/connect/v1/property/${id}?language=${language}`);
  }

  public static async getUnityMeasure(measureId: string): Promise<ApiResult> {
    return await PropertyService.api.post(`/connect/v1/unit-measure/${measureId}/dropdown`, {
      search: '',
    });
  }
}

export { PropertyService };
