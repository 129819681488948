import {
  createPlugins,
  createBlockquotePlugin,
  createCodeBlockPlugin,
  createHeadingPlugin,
  createParagraphPlugin,
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createUnderlinePlugin,
  PlatePluginComponent,
  createHighlightPlugin,
  createAlignPlugin,
  createListPlugin,
  createTodoListPlugin,
} from '@udecode/plate';
import { plateUI } from '../common/plateUI';
import {
  MyEditor,
  MyOverrideByKey,
  MyPlatePlugin,
  MyValue,
} from '../types/plateTypes';

export const createMyPlugins = (
  plugins: MyPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: MyOverrideByKey;
  },
): any => createPlugins<MyValue, MyEditor>(plugins, options);

export const elementsPlugins = createMyPlugins(
  [
    createBlockquotePlugin(),
    createCodeBlockPlugin(),
    createHeadingPlugin(),
    createParagraphPlugin(),
    createBoldPlugin(),
    createCodePlugin(),
    createItalicPlugin(),
    createStrikethroughPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createUnderlinePlugin(),
    createHighlightPlugin(),
    createAlignPlugin(),
    createListPlugin(),
    createTodoListPlugin(),
  ],
  {
    components: plateUI,
  },
);
