import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonConfigureV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-configure'
    labelKey='configure'
    tooltipKey='clickToConfigure'
    translations={translations}
    icon={<BrassIcon icon={faCog} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    configure: 'Configurar',
    clickToConfigure: 'haga clic para configurar',
  },
  enUS: {
    configure: 'Configure',
    clickToConfigure: 'Click to configure',
  },
  ptBR: {
    configure: 'Configurar',
    clickToConfigure: 'Clique para configurar',
  },
};

export { BrassButtonConfigureV1 };
