import React, { FunctionComponent, useState } from 'react';
import {
  Chat,
  ChatMessageSendEvent,
  ChatProps,
  Message,
  // Message,
  User,
} from '@progress/kendo-react-conversational-ui';
import classnames from 'classnames';
import { ChatMessage } from '../../../models/chat/ChatMessage.model';

interface BrassChatProps extends Omit<ChatProps, 'messages' | 'user'> {
  user: User;
  initialMessages: ChatMessage[];
  callBackFunction: (event: ChatMessageSendEvent) => void;
}

const BrassChat: FunctionComponent<BrassChatProps> = (props: BrassChatProps) => {
  const [messages, setMessages] = useState<ChatMessage[]>(props.initialMessages);
  const { className } = props;
  const _className = classnames('brass-chat', className);

  const addNewMessage = (event: ChatMessageSendEvent) => {
    setMessages([...messages, event.message]);
    props.callBackFunction(event);
  };

  return (
    <Chat
      {...props}
      messages={messages}
      className={_className}
      onMessageSend={addNewMessage}
      user={props.user!}
    />
  );
};

export { BrassChat };
export type { BrassChatProps };
