// @ts-nocheck
import React, { FunctionComponent } from 'react';
import style from './AppRouter.module.scss';
import { Routes, Route, Navigate, BrowserRouter, Redi } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { getAuthRoutes } from '../modules/auth/routes';
import { AppRoute, AuthUser } from '../models';

interface BrassAppRouteProps {
  routes: AppRoute[];
}

const BrassAppRouter: FunctionComponent<BrassAppRouteProps> = (
  props: BrassAppRouteProps,
) => {
  const { routes } = props;
  const userIsAuthenticated = AuthUser.isAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        {getAuthRoutes().map((route: AppRoute, index: number) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
        {routes
          .filter((route: AppRoute) => {
            // NOTA: Ao adicionar mudanças atualizar também: components > shared > layouts > main >left-menu > LeftMenu.tsx
            if (!route.permission && !route.permissions?.length !== 0) {
              return true;
            }

            if (route.permissions?.length === 0) {
              return AuthUser.checkPermission(route.permission!);
            }

            return (
              route.permissions.filter((_) => AuthUser.checkPermission(_!) === true)
                ?.length !== 0
            );
          })
          .map((route: AppRoute, index: number) => {
            if (!route.isPublic && !userIsAuthenticated) {
              return <Route path='*' element={<Navigate to='/login' />} />;
            }
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <CSSTransition timeout={200} classNames={{ ...style }}>
                    {route.component}
                  </CSSTransition>
                }
              />
            );
          })}
        <Route path='*' element={<Navigate to='/home' />} />
      </Routes>
    </BrowserRouter>
  );
};

BrassAppRouter.defaultProps = {
  routes: [],
};

export { BrassAppRouter };
