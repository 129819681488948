import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AuthUser,
  Notification,
  NotificationStyles,
  ApiResult,
  Breadcrumb,
} from '../../../../models';
import { breadcrumbActions, notificationActions } from '../../../../store';
import { CookieService, LocalStorageService } from '../../../../services';
import {
  BrassForm,
  BrassFormElement,
  BrassFormRenderProps,
  BrassLoader,
} from '../../../../components/brass';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassPasswordField,
} from '../../../../components/shared';
import { ProfileService } from '../../services';
import {
  AuthErrorsEnum,
  ChangePasswordFormModel,
  ChangePasswordModel,
} from '../../models';
import { StrengthnessPassword } from './strengthness-password/StrengthnessPassword';
import style from './ChangePassword.module.scss';
import { translate } from './ChangePassword.translations';

const ChangePassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordStrengh, setPasswordStrenght] = useState<string>('weak');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  dispatch(
    breadcrumbActions.setBreadcrumb([
      new Breadcrumb({
        route: '/profile/change-password',
        title: translate('recoverPassword'),
      }),
    ]),
  );

  const renderForm: any = (formRenderProps: BrassFormRenderProps) => (
    <BrassFormElement className={style['form-element']}>
      <h2>{translate('recoverPassword')}</h2>

      <BrassPasswordField
        className={style.passwordField}
        onChange={(e: any) => setCurrentPassword(e.value)}
        value={currentPassword}
        name='currentPassword'
        label={translate('currentPasswordField')}
      />

      <BrassPasswordField
        className={style.passwordField}
        style={{ width: '350px' }}
        onChange={(e: any) => setNewPassword(e.value)}
        value={newPassword}
        name='newPassword'
        label={translate('newPasswordField')}
      />

      <BrassPasswordField
        className={style.passwordField}
        onChange={(e: any) => setConfirmNewPassword(e.value)}
        value={confirmNewPassword}
        name='confirmNewPassword'
        label={translate('confirmNewPasswordField')}
      />

      <StrengthnessPassword
        currentPassword={currentPassword}
        newPassword={newPassword}
        confirmNewPassword={confirmNewPassword}
        setOldPassword={setCurrentPassword}
        setNewPassword={setNewPassword}
        setConfirmNewPassword={setConfirmNewPassword}
        callBackPasswordStrengthness={setPasswordStrenght}
      />

      <div>{translate('description')}</div>

      <div className={style['action-button']}>
        <BrassButtonSave
          themeColor='primary'
          type='submit'
          onClick={formRenderProps.onSubmit}
          disabled={
            passwordStrengh === 'weak' || passwordStrengh === 'differentPasswords'
          }
        />
        <BrassButtonCancel
          themeColor='base'
          onClick={() => navigate('/')}
          className={style['button-cancel']}
        />
      </div>
    </BrassFormElement>
  );

  const handleSubmit = async (
    changePasswordFormModel: ChangePasswordFormModel | any,
  ): Promise<void> => {
    const params = {
      currentPassword: changePasswordFormModel.currentPassword,
      newPassword: changePasswordFormModel.newPassword,
    };

    if (
      !changePasswordFormModel.newPassword ||
      !changePasswordFormModel.currentPassword
    ) {
      return;
    }

    try {
      setLoading(true);
      const apiResult: ApiResult<AuthUser> = await ProfileService.changePassword(
        params as ChangePasswordModel,
      );
      if (apiResult.errorMessage) {
        if (apiResult.errorMessage === AuthErrorsEnum.INCORRECT_USER_PASSWORD) {
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.WARNING,
                content: translate('passwordError'),
                timeout: 3000,
              }),
            ),
          );
          setLoading(false);
          return;
        }

        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.WARNING,
              content: translate('errorOnUpdate'),
              timeout: 3000,
            }),
          ),
        );
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('updateSuccess'),
              timeout: 60000,
            }),
          ),
        );

        const authUser = {
          ...AuthUser.currentUser,

          // needChangePassword
          ncp: false,
        };
        LocalStorageService.setLocalStorageJSON(
          CookieService.cookieAuthUser,
          authUser,
        );
        setTimeout(() => {
          navigate('/home');
        }, 100);
      }
    } catch (ex) {
      setLoading(false);
      dispatch(
        notificationActions.showNotification(
          new Notification({
            style: NotificationStyles.WARNING,
            content: translate('errorOnUpdate'),
            timeout: 3000,
          }),
        ),
      );
    }
  };

  return (
    <div className={style.form}>
      <BrassForm onSubmit={handleSubmit} render={renderForm} />
      {loading && <BrassLoader useLoadingMask />}
    </div>
  );
};

export { ChangePassword };
