import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassTooltip } from '../../brass/tooltip';
import { BrassButtonV1, BrassButtonPropsV1, BrassLoader } from '../../brass/';
import classNames from 'classnames';

interface BrassButtonSharedPropsV1 extends Omit<BrassButtonPropsV1, 'icon'> {
  icon?: any;
  showText?: boolean;
  showLoader?: boolean;
  color?: string;

  // Ocultar o Icone enquando o Loader estiver ON?
  hideIconWhenLoader?: boolean;

  labelKey?: string;
  tooltipKey?: string;
  translations?: II18n;
}

const BrassButtonSharedV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => {
  const _props = { ...props };
  delete _props.icon;
  delete _props.labelKey;
  delete _props.showLoader;
  delete _props.showText;
  delete _props.tooltipKey;
  delete _props.translations;
  delete _props.hideIconWhenLoader;

  const tooltipKey =
    props.translations &&
    I18nService.translate(props.translations!, props.tooltipKey!);
  const labelKey =
    props.showText &&
    props.translations &&
    I18nService.translate(props.translations!, props.labelKey!);

  const button = (
    <span title={tooltipKey}>
      <BrassButtonV1
        themeColor={props.themeColor || 'primary'}
        fillMode={props.fillMode || 'flat'}
        style={{
          width: props.showText ? 'auto' : '40px',
          height: props.showText ? 'auto' : '40px',
          borderRadius: props.showText ? '0' : '50%',
          color: !props.color ? '' : props.color,
        }}
        {..._props}
      >
        {props.showLoader ? (
          <BrassLoader
            themeColor={_props.themeColor === 'primary' ? 'dark' : 'light'}
            style={{ width: 'auto' }}
          />
        ) : (
          props.icon
        )}
        {props.showText && (
          <span
            style={{
              marginLeft: props.showText ? '5px' : '0px',
            }}
          >
            {labelKey}
          </span>
        )}
      </BrassButtonV1>
    </span>
  );

  if (props.translations && props.tooltipKey) {
    return <BrassTooltip>{button}</BrassTooltip>;
  } else {
    return button;
  }
};

BrassButtonSharedV1.defaultProps = {
  showText: true,
  showLoader: false,
  hideIconWhenLoader: false,
  disabled: false,
};

export { BrassButtonSharedV1 };
export type { BrassButtonSharedPropsV1 };
