import { BaseService } from './base.service';

class NumberService extends BaseService {
  public static getExcelExportColumnFormatInt = (): string => '#.##0';

  public static getExcelExportColumnFormatDecimal = (): string =>
    '_-* #.##0,000_-;-* #.##0,000_-;_-* "-"???_-;_-@_-';

  // propertieSum = 'workTimeMinutes' -> Nome da Propriedade para somar
  public static sumColumnGroupHeader = (items: any, propSum: string): number => {
    let sumWorkTime = 0;

    const sumItems = (_item: any): void => {
      if (_item.items) {
        _item.items.forEach((i: any) => sumItems(i));
      } else {
        sumWorkTime += _item[propSum] || 0;
      }
    };

    items.forEach((i: any) => sumItems(i));
    return sumWorkTime;
  };

  /** Retorna sempre o valor formatado com o número DINAMICO de casas decimais
   * Exemplo: 213.3   -> 213,3
   *          213,33  -> 213,33
   *          213,333 -> 213,333
   */
  public static format = (value: number): string =>
    new Intl.NumberFormat(NumberService._userLanguage).format(value);

  /** Retorna sempre o valor formatado com o número FIXO de casas decimais
   * Exemplo: 213.3   -> 213,30
   *          213,33  -> 213,30
   *          213,333 -> 213,30
   */
  public static formatRealNumber = (
    value: number,
    fractionDigits: number = 2,
  ): string =>
    new Intl.NumberFormat(NumberService._userLanguage, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(value);
}

export { NumberService };
