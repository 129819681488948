import { I18nService, II18n } from '../../../';

const translations: II18n = {
  es: {
    editFile: 'Editar archivo',
    includeFile: 'Incluir archivo',
    successOnUpdate: 'Archivo actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar el archivo',
  },
  enUS: {
    editFile: 'Edit File',
    includeFile: 'Include File',
    successOnUpdate: 'File updated successfully',
    errorOnUpdate: 'Error trying to update file',
  },
  ptBR: {
    editFile: 'Edição de Arquivo',
    includeFile: 'Inclusão de Arquivo',
    successOnUpdate: 'Arquivo atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar o arquivo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
