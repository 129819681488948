import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    workTimeRecord: 'IHT',
    system: 'Sistema',
    finance: 'Financiero',
  },
  enUS: {
    workTimeRecord: 'Work Hours Report',
    system: 'System',
    finance: 'Finance',
  },
  ptBR: {
    workTimeRecord: 'RHT',
    system: 'Sistema',
    finance: 'Financeiro',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
