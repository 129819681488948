import { FunctionComponent, useRef } from 'react';
import style from './ScrollViewer.module.scss';
import { ScrollView, ScrollViewProps } from '@progress/kendo-react-scrollview';
import { ViewerTypesEnum } from '../../../../models';
import { BrassPdfViewer } from '../pdf-viewer/PdfViewer';

interface BrassScrollViewItemV1 {
  position: number;
  url: string;
  allow: boolean;
}

interface BrassScrollViewProps extends ScrollViewProps {
  width?: string | '1000px';
  height?: string | '700px';
  items: BrassScrollViewItemV1[];
  type: ViewerTypesEnum;
  positionFile: number;
  className?: string;
  className_item?: string;
}

const BrassScrollViewV1: FunctionComponent<BrassScrollViewProps> = (
  props: BrassScrollViewProps,
) => {
  const { items, width, height, className_item, type, positionFile } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  return (
    <ScrollView
      style={{
        width,
        height,
      }}
      activeView={positionFile + 1}
      {...props}
    >
      {items
        .filter((_) => _.allow)
        .map((item, index) => {
          return (
            <div className={style['div-item']} key={index}>
              {type === 'IMAGE' && (
                <img
                  src={item.url}
                  alt={`Comunicado ${item.position}`}
                  className={className_item}
                  draggable={false}
                />
              )}
              {type === 'VIDEO' && (
                <video
                  className={className_item}
                  ref={videoRef}
                  controls
                  src={item.url}
                >
                  <track kind='captions' />
                </video>
              )}
              {type === 'PDF' && (
                <BrassPdfViewer url={item?.url} className={style.pdf} />
              )}
              {type === 'IMAGE2D3D' && (
                <iframe
                  width={'100%'}
                  height={'100%'}
                  src={`https://3d-viewer.bdbsystem.com.br/final/website/#model=${item?.url}`}
                />
              )}
            </div>
          );
        })}
    </ScrollView>
  );
};

export { BrassScrollViewV1 };
export type { BrassScrollViewItemV1 };
