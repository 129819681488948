import { ApiResult, Endpoints } from '../models';
import { ApiService } from './api.service';

class LanguagesService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async getAllLanguages(): Promise<ApiResult> {
    return await LanguagesService.api.get(`connect/v1/languages/list-all`);
  }
}

export { LanguagesService };
