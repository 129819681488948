import React, { FunctionComponent, Fragment, useState } from 'react';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { BrassTabContainer, BrassTab } from '../components';
import { II18n } from '../models';
import { I18nService } from '../services';
import {
  AvatarPlayground,
  ButtonsPlayground,
  DateInputsPlayground,
  DialogPlayground,
  PlaygroundDropDown,
  FormPlayground,
  IndicatorsPlayground,
  InputsPlayground,
  SplitterPlayground,
  TooltipPlayground,
  LogoPlayground,
  IconPlayground,
} from './components';

const translations: II18n = {
  es: {
    title: 'Playgroud',
    avatar: 'Avatar',
    button: 'Button',
    dateInput: 'Date Input',
    dialog: 'Dialog',
    dropdown: 'DropDown',
    form: 'Form',
    grid: 'Grid',
    indicator: 'Indicator',
    inputs: 'Inputs',
    splitter: 'Splitter',
    tooltip: 'Tooltip',
    icon: 'Icon',
    logo: 'Logo',
  },
  enUS: {
    title: 'Playgroud',
    avatar: 'Avatar',
    button: 'Button',
    dateInput: 'Date Input',
    dialog: 'Dialog',
    dropdown: 'DropDown',
    form: 'Form',
    grid: 'Grid',
    indicator: 'Indicator',
    inputs: 'Inputs',
    splitter: 'Splitter',
    tooltip: 'Tooltip',
    icon: 'Icon',
    logo: 'Logo',
  },
  ptBR: {
    title: 'Playgroud',
    avatar: 'Avatar',
    button: 'Button',
    dateInput: 'Date Input',
    dialog: 'Dialog',
    dropdown: 'DropDown',
    form: 'Form',
    grid: 'Grid',
    indicator: 'Indicator',
    inputs: 'Inputs',
    splitter: 'Splitter',
    tooltip: 'Tooltip',
    icon: 'Icon',
    logo: 'Logo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const Playgroud: FunctionComponent = () => {
  const [selected, setSelected] = useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments): void =>
    setSelected(e.selected);

  return (
    <Fragment>
      <h4>{translate('title')}</h4>
      <BrassTabContainer selected={selected} onSelect={handleSelect}>
        <BrassTab title={translate('avatar')}>
          <AvatarPlayground />
        </BrassTab>
        <BrassTab title={translate('button')}>
          <ButtonsPlayground />
        </BrassTab>
        <BrassTab title={translate('dateInput')}>
          <DateInputsPlayground />
        </BrassTab>
        <BrassTab title={translate('dropdown')}>
          <PlaygroundDropDown />
        </BrassTab>
        <BrassTab title={translate('form')}>
          <FormPlayground />
        </BrassTab>
        <BrassTab title={translate('grid')}>
          <div>!ERROR :: PlaygroundGrid!</div>
        </BrassTab>
        <BrassTab title={translate('indicator')}>
          <IndicatorsPlayground />
        </BrassTab>
        <BrassTab title={translate('inputs')}>
          <InputsPlayground />
        </BrassTab>
        <BrassTab title={translate('splitter')}>
          <SplitterPlayground />
        </BrassTab>
        <BrassTab title={translate('tooltip')}>
          <TooltipPlayground />
        </BrassTab>

        <BrassTab title={translate('logo')}>
          <LogoPlayground />
        </BrassTab>
        <BrassTab title={translate('icon')}>
          <IconPlayground />
        </BrassTab>

        <BrassTab title={translate('dialog')}>
          <DialogPlayground />
        </BrassTab>
      </BrassTabContainer>
    </Fragment>
  );
};

export { Playgroud };
export * from './routes';
