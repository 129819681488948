import { FormRenderProps } from '@progress/kendo-react-form';
import { Address } from '../index';

type ConstructableProps<T> = new (n: T) => any;

/**
 * @param formRender
 * @param ObjectClass
 * @param initialValues
 */
function GetValuesFormRender<T = any>(
  formRender: FormRenderProps,
  ObjectClass: ConstructableProps<T>,
  initialValues: T,
): T {
  const _objectClass = new ObjectClass(initialValues);
  const propertyNames = Object.getOwnPropertyNames(_objectClass);
  const values: T = {} as T;

  propertyNames.forEach((item) => {
    if (formRender.valueGetter(item)) {
      (values as any)[item] = formRender.valueGetter(item);
    }
  });

  return values;
}

export { GetValuesFormRender };
