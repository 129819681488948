import React, { Fragment, FunctionComponent } from 'react';
import { BrassWindow } from '../../../../../components';
import { emptyGuid } from '../../../../../util';
import { translate } from './UpsertUserGroupWindow.translations';
import { UserGroupDataForm } from './user-group-data-form';

interface UpsertUserGroupWindowProps {
  userGroupId: string;
  onSaveClose?: () => void;
  onCancelClose?: () => void;
}

const UpsertUserGroupWindow: FunctionComponent<UpsertUserGroupWindowProps> = (
  props: UpsertUserGroupWindowProps,
) => {
  const { userGroupId, onSaveClose, onCancelClose } = props;
  const isUpdate = userGroupId !== emptyGuid();

  return (
    <BrassWindow
      onClose={onCancelClose}
      minimizeButton={() => null}
      maximizeButton={() => null}
      stage='FULLSCREEN'
      title={translate(isUpdate ? 'addUserGroup' : 'editUserGroup')}
    >
      <UserGroupDataForm
        isUpdate={isUpdate}
        userGroupId={userGroupId}
        onSaveClose={onSaveClose}
        onCancelClose={onCancelClose}
      />
    </BrassWindow>
  );
};

UpsertUserGroupWindow.defaultProps = {
  userGroupId: emptyGuid(),
};

export { UpsertUserGroupWindow };
