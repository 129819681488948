import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { CSSProperties, useState } from 'react';
import { BrassIcon } from '../../shared';
import style from './modal.module.scss';

interface IModalProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  styleModal?: CSSProperties;
  onClose: any;
  classNameHeader?: string;
}
interface IHeaderModalProps {
  title?: React.ReactNode;
  onClose: any;
  classNameHeader?: string;
}

const BrassHeaderModal = ({
  title,
  onClose,
  classNameHeader,
}: IHeaderModalProps): any => (
  <div className={classNames(style['header-modal'], classNameHeader!)}>
    <div id={'title-custom-modal'}>{title}</div>
    <BrassIcon
      icon={faTimes}
      className={style['close']}
      onClick={onClose}
      onKeyDown={() => null}
    />
  </div>
);

const BrassModal = (props: IModalProps): any => {
  const { children, onClose, title, styleModal, classNameHeader } = props;
  return (
    <div id='myModal' className={style['modal']}>
      <div className={style['modal-content']} style={styleModal}>
        <BrassHeaderModal
          classNameHeader={classNameHeader}
          title={title}
          onClose={onClose}
        />
        {children}
      </div>
    </div>
  );
};

export { BrassModal, BrassHeaderModal };
