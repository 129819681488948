import React, {
  Fragment,
  FunctionComponent,
  memo,
  useContext,
  useEffect,
} from 'react';
import style from './BottomPanelBar.module.scss';
import { translate } from './BottomPanelBar.translations';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import {
  BrassBar,
  BrassBarSpacer,
  BrassButtonCollapseDown,
  BrassButtonDownload,
  BrassButtonExpandUp,
  BrassButtonInformation,
  BrassButtonRefresh,
  BrassButtonShare,
  BrassDropDownButtonAdd,
  BrassSearchInput,
} from '../../../../../../components';
import {
  AuthUser,
  ConnectPermissions,
  DropDownData,
  NavigatorPermissions,
} from '../../../../../../models';
import { ViewerContextV1, ViewerDialogs } from '../../../../../../store';
import { emptyGuid } from '../../../../../../util';

interface BottomPanelBarProps {
  showAddButton: boolean;
  showDownloadButton: boolean;
  showInformationButton: boolean;
  showShareButton: boolean;
}

const BottomPanelBar: FunctionComponent<BottomPanelBarProps> = memo(
  (props: BottomPanelBarProps) => {
    const {
      showBottomPanel,
      navigatorFile,
      loadBottomPanelData,
      showHideBottomPanel,
      onBottomPanelBarSearchInputChange,
      showHideDialog,
    } = useContext(ViewerContextV1);

    const {
      showAddButton,
      showDownloadButton,
      showInformationButton,
      showShareButton,
    } = props;

    // Quando for arquivo não haverá filhos assim não exibe o Expandir
    useEffect(() => {
      if (navigatorFile.document.isFile) {
        showHideBottomPanel();
      }
    }, []);
    const hasDocumentId = navigatorFile.document.id !== emptyGuid();

    // Quando o usuário estiver em PROJETO, pode adicionar uma ÁREA ou um SISTEMA,
    // Quando ele estiver em uma ÁREA, só pode adicionar SISTEMAS
    const isArea = navigatorFile.areaId !== emptyGuid();
    const isSystem = navigatorFile.systemId !== emptyGuid();
    const isProjectHomePage =
      navigatorFile.projectId !== emptyGuid() &&
      navigatorFile.fileId === emptyGuid() &&
      navigatorFile.systemId === emptyGuid() &&
      navigatorFile.areaId === emptyGuid();

    const allowCreateArea =
      (isProjectHomePage || isArea) &&
      AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_AREA_CREATE);

    const allowCreateSystem =
      ((isProjectHomePage || isSystem) &&
        AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE)) ||
      (isArea &&
        AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE)) ||
      (isSystem &&
        AuthUser.checkPermission(ConnectPermissions.CONNECT_SYSTEM_CREATE));

    let addableItems: DropDownData[] = [];
    if (allowCreateArea) {
      addableItems.push({
        text: translate('area'),
        value: 'AREA',
      });
    }

    if (allowCreateSystem) {
      addableItems.push({
        text: translate('system'),
        value: 'SYSTEM',
      });
    }

    const handleAddClick = (event: DropDownButtonItemClickEvent): void => {
      if (event.item === 'AREA') {
        showHideDialog(ViewerDialogs.AREA);
      } else if (event.item === 'SYSTEM') {
        showHideDialog(ViewerDialogs.SYSTEM);
      }
    };

    const barTitle = `${navigatorFile.tag ? navigatorFile.tag + ' -' : ''} ${
      navigatorFile.name
    }`;

    return (
      <BrassBar position='bottom' themeColor='dark' className={style['app-bar']}>
        <div className={style['bar-title']}>{barTitle}</div>

        <BrassBarSpacer />

        {showBottomPanel && (
          <BrassSearchInput
            classNameContainer={style['search-input']}
            onChange={(e: InputChangeEvent) =>
              onBottomPanelBarSearchInputChange(e.value)
            }
          />
        )}

        {showDownloadButton && (
          <BrassButtonDownload
            fillMode='flat'
            themeColor='base'
            className={style['white-color']}
            onClick={(): void => showHideDialog(ViewerDialogs.DOWNLOAD)}
          />
        )}

        {showBottomPanel && (
          <Fragment>
            {showAddButton && (
              <BrassDropDownButtonAdd
                themeColor='base'
                fillMode='flat'
                items={addableItems}
                onItemClick={handleAddClick}
                className={style['white-color']}
              />
            )}
            <BrassButtonRefresh
              themeColor='base'
              showText={false}
              fillMode='flat'
              onClick={() => loadBottomPanelData()}
              className={style['white-color']}
            />
          </Fragment>
        )}

        {showShareButton && hasDocumentId && (
          <BrassButtonShare
            fillMode='flat'
            themeColor='base'
            className={style['white-color']}
            onClick={(): void => showHideDialog(ViewerDialogs.SHARE_DOCUMENTS)}
          />
        )}

        {showInformationButton && hasDocumentId && (
          <BrassButtonInformation
            fillMode='flat'
            themeColor='base'
            className={style['white-color']}
            onClick={(): void => showHideDialog(ViewerDialogs.DOCUMENT_INFORMATION)}
          />
        )}

        {showBottomPanel ? (
          <BrassButtonCollapseDown
            themeColor='base'
            fillMode='flat'
            showText={false}
            className={style['button-collapse']}
            onClick={showHideBottomPanel}
          />
        ) : (
          <BrassButtonExpandUp
            themeColor='base'
            fillMode='flat'
            showText={false}
            className={style['button-collapse']}
            onClick={showHideBottomPanel}
          />
        )}
      </BrassBar>
    );
  },
);

export { BottomPanelBar };
export type { BottomPanelBarProps };
