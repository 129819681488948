import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Form, FormProps, FormRenderProps } from '@progress/kendo-react-form';
import { BrassLoader } from '../../indicators';

interface BrassFormRenderProps extends FormRenderProps {}

interface BrassFormProps extends FormProps, PropsWithChildren<any> {
  formLoading?: boolean;
}

const BrassForm: FunctionComponent<BrassFormProps> = (props: BrassFormProps) => {
  const { className, formLoading } = props;
  const _className = classnames('brass-form', className);
  if (formLoading) {
    return <BrassLoader useLoadingMask />;
  } else {
    return (
      <div className={_className}>
        <Form {...props} />
      </div>
    );
  }
};

export { BrassForm };
export type { BrassFormProps, BrassFormRenderProps };
