import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const BrassButtonCheck: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-check'
    translations={translations}
    labelKey='check'
    tooltipKey='clickToCheck'
    icon={<BrassIcon icon={faCheck} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    check: 'Comprobar',
    clickToCheck: 'Haga clic para comprobar',
  },
  enUS: {
    check: 'Check',
    clickToCheck: 'Click to check',
  },
  ptBR: {
    check: 'Verificar',
    clickToCheck: 'Clique para verificar',
  },
};

export { BrassButtonCheck };
