import React, { Dispatch, SetStateAction, FunctionComponent } from 'react';
import { EnvironmentService } from '../../../../../../services';
import { NavigatorCard } from '../../../../../../components';
import {
  NavigatorFileType,
  NavigatorFileChildItem,
  AuthUser,
  NavigatorPermissions,
  TabsModel,
} from '../../../../../../models';
import { NavigatorItemType } from '../../../../../../models/viewer/enum';

interface NavigatorCardByGroupProps {
  dataItem?: NavigatorFileChildItem;
  onPromoteToInitialFileView?: (record: NavigatorFileChildItem) => void;
  onEditRecord?: (record: NavigatorFileChildItem) => void;
  onDeleteRecord?: (record: NavigatorFileChildItem) => void;
}

const NavigatorCardByGroup: FunctionComponent<NavigatorCardByGroupProps> = (
  props: NavigatorCardByGroupProps,
): any => {
  const { onPromoteToInitialFileView, onEditRecord, onDeleteRecord } = props;
  const record = props.dataItem as NavigatorFileChildItem;
  const { conversionFinished } = record;

  const allowEditRecord =
    AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILE_UPDATE) &&
    EnvironmentService.isNavigatorIntegrator;
  const allowDeleteRecord = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_FILE_DELETE,
  );

  let onClickPromoteToInitialFileView;
  if (
    allowEditRecord &&
    record.type === NavigatorItemType.FILE &&
    record.fileType !== NavigatorFileType.ZIP
  ) {
    onClickPromoteToInitialFileView = () => {
      if (onPromoteToInitialFileView) {
        return onPromoteToInitialFileView(record);
      }
    };
  }

  const onClickNavigatorCard = (
    item: NavigatorFileChildItem,
    setCardLoading: Dispatch<SetStateAction<boolean>>,
  ): void => {
    // Name e projectId serão enviados em todos os
    let eventDetail: TabsModel = new TabsModel({
      name: item.name,
      projectId: item.projectId,
    });
    if (item.type === NavigatorItemType.AREA) {
      eventDetail = { ...eventDetail, areaId: item.id };
      const event = new CustomEvent('newTab', {
        detail: eventDetail,
      });
      window.dispatchEvent(event);
      setCardLoading(false);
    } else if (item.type === NavigatorItemType.SYSTEM) {
      eventDetail = { ...eventDetail, systemId: item.id };
      const event = new CustomEvent('newTab', {
        detail: eventDetail,
      });
      window.dispatchEvent(event);
      setCardLoading(false);
    } else if (item.type === NavigatorItemType.FILE) {
      if (item.fileType === NavigatorFileType.UNDEFINED) {
        setCardLoading(false);
      } else if (item.fileType === NavigatorFileType.ZIP) {
        setCardLoading(false);
        if (item.urlDownload) {
          window.open(item.urlDownload);
        }
      } else {
        eventDetail = { ...eventDetail, fileId: item.id };
        const event = new CustomEvent('newTab', {
          detail: eventDetail,
        });
        window.dispatchEvent(event);
        setCardLoading(false);
      }
    }
  };

  return (
    <NavigatorCard
      key={record.id}
      onClick={(setCardLoading: Dispatch<SetStateAction<boolean>>) =>
        onClickNavigatorCard(record, setCardLoading)
      }
      tag={record.tag}
      name={record.name}
      cardDescription={record.description}
      conversionFinished={conversionFinished}
      thumbnail={
        conversionFinished ? record.thumbnail : '/images/file/file-converting.png'
      }
      onClickEdit={() => allowEditRecord && onEditRecord && onEditRecord(record)}
      onClickDelete={() =>
        allowDeleteRecord && onDeleteRecord && onDeleteRecord(record)
      }
      onClickPromoteToInitialFileView={onClickPromoteToInitialFileView}
    />
  );
};

export { NavigatorCardByGroup };
