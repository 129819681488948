import React, { FunctionComponent } from 'react';
import style from './Button.module.scss';
import classnames from 'classnames';
import { Button, ButtonProps } from '@progress/kendo-react-buttons';

interface BrassButtonProps extends Omit<ButtonProps, 'icon'> {
  icon?: any;
  roundBorder?: boolean;
}

const BrassButton: FunctionComponent<BrassButtonProps> = (
  props: BrassButtonProps,
) => {
  const { className, roundBorder, themeColor } = props;
  const _props = { ...props };
  delete _props.roundBorder;

  const _className = classnames(
    'brass-button',
    style['brass-button'],
    roundBorder && style['brass-button-round'],
    themeColor === 'primary'
      ? style['brass-button-primary']
      : style['brass-button-secundary'],
    className,
  );

  return <Button {..._props} className={_className} />;
};

BrassButton.defaultProps = {
  type: 'submit',
};

export { BrassButton };
export type { BrassButtonProps };
