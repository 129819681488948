import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './ReplyNotificationForm.module.scss';
import { translate } from './ReplyNotificationForm.translations';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  BrassFormProps,
  BrassLoader,
  BrassWindowActionsBar,
} from '../../../../../../../components';
import {
  ApiErrorsEnum,
  ApiResult,
  Notification,
  NotificationStyles,
} from '../../../../../../../models';
import { notificationActions } from '../../../../../../../store';
import { UserNotificationV0, UserNotificationResponse } from '../../../../../models';
import { UserNotificationService } from '../../../../../services';
import { MessageField } from './message-field/MessageField';
import { ReplyField } from './reply-field/ReplyField';
import { MessageDateField } from './message-date-field/MessageDateField';

interface ReplyNotificationFormProps {
  entityId?: string;
  onSaveClose?: () => void;
  onCancelClose?: () => void;
}

const ReplyNotificationForm: FunctionComponent<ReplyNotificationFormProps> = (
  props: ReplyNotificationFormProps,
) => {
  const dispatch = useDispatch();
  const { entityId, onSaveClose, onCancelClose } = props;
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageError, setMessageError] = useState<string>('');
  const [initialFormValues, setInitialFormValues] = useState<UserNotificationV0>(
    new UserNotificationV0({}),
  );

  useEffect(() => {
    if (entityId) {
      setIsLoading(true);
      UserNotificationService.getUserNotification(entityId)
        .then((apiResult: ApiResult<UserNotificationV0>) => {
          setInitialFormValues(
            new UserNotificationV0({
              ...apiResult.data,
              messageDate: new Date(apiResult.data.messageDate),
            }),
          );
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [entityId]);

  const handleSubmit = async (
    _userNotification: UserNotificationV0 | any,
  ): Promise<void> => {
    _userNotification = new UserNotificationV0({
      ...initialFormValues,
      ...(_userNotification as UserNotificationV0),
      id: entityId,
    });
    const userNotificationResponse = new UserNotificationResponse({
      toUser: _userNotification.senderId,
      context: _userNotification.context,
      message: _userNotification.reply,
    });
    try {
      setAllowSubmit(true);
      setIsLoading(true);
      const apiResult: ApiResult =
        await UserNotificationService.createUserNotification(
          userNotificationResponse as UserNotificationResponse,
        );
      setIsLoading(false);
      if (apiResult.success) {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnAdd'),
            }),
          ),
        );
        if (onSaveClose) {
          onSaveClose();
        }
      } else {
        setAllowSubmit(false);
        setMessageError(apiResult.errorMessage!);
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: `
                ${translate('errorOnAdd')} :
                ${translate(apiResult.message[0])}
              `,
            }),
          ),
        );
      }
    } catch (error: any) {
      setMessageError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
      setAllowSubmit(false);
    }
  };

  const renderForm: any = (formRenderProps: BrassFormProps) => {
    let disableButtonSave = allowSubmit;
    if (!formRenderProps.valid) {
      disableButtonSave = true;
    } else if (!formRenderProps.allowSubmit) {
      disableButtonSave = true;
    }

    return (
      <BrassFormElement className={style.fields}>
        <MessageDateField disabled />
        <MessageField disabled />
        <ReplyField disabled={isLoading || allowSubmit} />
        <BrassWindowActionsBar>
          <BrassDivFlex />
          {messageError && <BrassError>{messageError}</BrassError>}
          <BrassButtonSave
            autoFocus
            themeColor='primary'
            type='submit'
            onClick={formRenderProps.onSubmit}
            disabled={disableButtonSave}
            showLoader={allowSubmit}
            hideIconWhenLoader
          />
          <BrassButtonCancel onClick={onCancelClose} />
        </BrassWindowActionsBar>
      </BrassFormElement>
    );
  };

  return (
    <Fragment>
      {!isLoading && (
        <BrassForm
          render={renderForm}
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
        />
      )}
      {isLoading && <BrassLoader useLoadingMask />}
    </Fragment>
  );
};

export { ReplyNotificationForm };
