import React, { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { Card } from '@progress/kendo-react-layout';
import { CardProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardProps';
import { BrassTooltip } from '../tooltip';

interface BrassCardProps extends CardProps {
  onClick?: () => void;
  tooltip?: string;
  externDivProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  stylediv?: React.CSSProperties;
}

const BrassCard: FunctionComponent<BrassCardProps> = (props: BrassCardProps) => {
  const { className, onClick, tooltip, externDivProps, stylediv } = props;
  const _className = classnames('brass-card', className);

  const card = (
    <div onClick={onClick} {...externDivProps} style={stylediv}>
      <Card {...props} className={_className} />
    </div>
  );

  if (tooltip) {
    return (
      <BrassTooltip>
        <div title={tooltip}>{card}</div>
      </BrassTooltip>
    );
  }

  return card;
};

export { BrassCard };
export type { BrassCardProps };
