import { UploadOnAddEvent } from '@progress/kendo-react-upload';
import React from 'react';
import {
  DocumentActionType,
  ResultPublishDocumentType,
} from '../../../PublishDocumentsModal.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../../../../../services';

type SetFilesUrlType = React.Dispatch<
  React.SetStateAction<ResultPublishDocumentType[]>
>;

const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve((event as any).target.result);
    };
    reader.readAsDataURL(file);
  });

const getTotalOfPages = (file: File): Promise<unknown> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = () => {
      if (reader.result !== null) {
        resolve((reader.result as any).match(/\/Type[\s]*\/Page[^s]/g).length);
      }
    };
  });

const onAddMany = async (
  event: UploadOnAddEvent,
  setFilesUrl: SetFilesUrlType,
  fileType: DocumentActionType,
): Promise<void> => {
  setFilesUrl([] as ResultPublishDocumentType<any>[]);

  event.affectedFiles.map(async (uploadFileInfo) => {
    if (uploadFileInfo?.getRawFile) {
      uploadFileInfo.uid = uuidv4();
      const urlPDF = URL.createObjectURL(uploadFileInfo?.getRawFile());
      const pages =
        uploadFileInfo.extension === 'pdf'
          ? await getTotalOfPages(uploadFileInfo?.getRawFile())
          : 0;

      setFilesUrl((prev: ResultPublishDocumentType[]) => [
        ...prev,
        {
          fileBase64: urlPDF,
          fileKey: `${uploadFileInfo?.uid ?? ''}${uploadFileInfo?.extension ?? ''}`,
          fileAws: `${UploadService.saveUrl}${uploadFileInfo?.uid ?? ''}${
            uploadFileInfo?.extension ?? ''
          }`,
          fileType,
          fileName: uploadFileInfo.name,
          property: {
            pages,
            time: {
              hour: 0,
              minutes: 0,
              seconds: 0,
            },
          },
        },
      ]);
    }
  });

  UploadService.onAddFile(event);
};

export { onAddMany };
