import React from 'react';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';

interface BrassGridConfigProps {
  skip: number; // Number of records that will be skipped.
  take: number; // Number of records that will be taken.
  sort?: SortDescriptor[];
  filter?: CompositeFilterDescriptor;
  group?: any[];
}

const BrassGridConfigDefault: BrassGridConfigProps = {
  skip: 0,
  take: 100,
};

const BrassGridConfigFullRecords: BrassGridConfigProps = {
  skip: 0,
  take: 10000,
};

const HideHeaderCell = (): any => <div />;

export { BrassGridConfigDefault, BrassGridConfigFullRecords, HideHeaderCell };
export type { BrassGridConfigProps };
