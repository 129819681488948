import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { SystemSubmodule } from './System.submodule.enum';

enum SystemPermissionsV1 {
  /// <summary>
  ///  [(FE) home/connect/disciplines -> home/connect/system/physical]
  /// </summary>
  SYSTEM_PHYSICAL_READ = PermissionModule.SYSTEM +
    SystemSubmodule.PHYSICAL +
    Action.READ,
  SYSTEM_PHYSICAL_CREATE = PermissionModule.SYSTEM +
    SystemSubmodule.PHYSICAL +
    Action.CREATE,
  SYSTEM_PHYSICAL_UPDATE = PermissionModule.SYSTEM +
    SystemSubmodule.PHYSICAL +
    Action.UPDATE,
  SYSTEM_PHYSICAL_DELETE = PermissionModule.SYSTEM +
    SystemSubmodule.PHYSICAL +
    Action.DELETE,
  SYSTEM_PHYSICAL_EXPORT = PermissionModule.SYSTEM +
    SystemSubmodule.PHYSICAL +
    Action.EXPORT,
  /// <summary>
  ///  [(FE) home/connect/disciplines -> home/connect/system/institutional]
  /// </summary>
  SYSTEM_INSTITUTIONAL_READ = PermissionModule.SYSTEM +
    SystemSubmodule.INSTITUTIONAL +
    Action.READ,
  SYSTEM_INSTITUTIONAL_CREATE = PermissionModule.SYSTEM +
    SystemSubmodule.INSTITUTIONAL +
    Action.CREATE,
  SYSTEM_INSTITUTIONAL_UPDATE = PermissionModule.SYSTEM +
    SystemSubmodule.INSTITUTIONAL +
    Action.UPDATE,
  SYSTEM_INSTITUTIONAL_DELETE = PermissionModule.SYSTEM +
    SystemSubmodule.INSTITUTIONAL +
    Action.DELETE,
  SYSTEM_INSTITUTIONAL_EXPORT = PermissionModule.SYSTEM +
    SystemSubmodule.INSTITUTIONAL +
    Action.EXPORT,
}

export { SystemPermissionsV1 };
