import React, {
  FunctionComponent,
  memo,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Comment, CommentGroup } from '../../../../models';
import { emptyGuid } from '../../../../../../util';
import style from './CommentsListSideView.module.scss';
import { CommentChat } from '../comment-chat/CommentChat';
import { translate } from './CommentsListSideView.translations';

interface CommentsListSideViewProps extends PropsWithChildren<any> {
  data: Comment;
  routes: any;
  commentsGroup: CommentGroup;
}

const CommentsListSideView: FunctionComponent<CommentsListSideViewProps> = (
  props: CommentsListSideViewProps,
) => {
  const empty = (
    <div className={style.emptyViewer}>{translate('noCommentsSelected')}</div>
  );
  const { data } = props;
  const [displayCommunication, setDisplayCommunication] =
    useState<JSX.Element>(empty);

  useEffect(() => {
    if (
      data.id !== emptyGuid() &&
      (data.group === props.commentsGroup ||
        props.commentsGroup === CommentGroup.ALL)
    ) {
      setDisplayCommunication(<CommentChat data={data} />);
    } else if (data.group !== props.commentsGroup || data.id === emptyGuid()) {
      setDisplayCommunication(empty);
    }
  }, [data, props.commentsGroup]);

  return <div className={style.displayCommunication}>{displayCommunication}</div>;
};

export type { CommentsListSideViewProps };
export { CommentsListSideView };
