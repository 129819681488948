import React, { Fragment, FunctionComponent, useState, memo } from 'react';
import { filterBy } from '@progress/kendo-data-query';
import {
  DropDownList,
  MultiSelect,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
  MultiSelectChangeEvent,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import style from './ComboboxField.module.scss';
import { Label } from '@progress/kendo-react-labels';

interface ComboboxFlagsData {
  flagShortId: string;
  flagValue: string;
}

interface ComboboxFieldProps extends MultiSelectProps {
  groupId: string;
  groupName: string;
  groupFlags: ComboboxFlagsData[];
  allowSelectMultiple?: boolean;
  required?: boolean;
}

const ComboboxField: FunctionComponent<ComboboxFieldProps> = memo(
  (props: ComboboxFieldProps) => {
    const { groupFlags, required } = props;

    const flagValues = groupFlags.map((a: ComboboxFlagsData) => a.flagValue);
    const [data, setData] = useState(flagValues);

    const [selectedObjects, setSelectedObjects] = useState<ComboboxFlagsData[]>([]);

    const filterChangeMultiselect = (
      event: MultiSelectFilterChangeEvent | DropDownListFilterChangeEvent,
    ): any => {
      const filter = event.filter;
      const allData = flagValues.slice();
      const newData = filter.value.length > 3 ? filterBy(allData, filter) : allData;
      setData(newData);
    };

    const onChange = (
      event: MultiSelectChangeEvent | DropDownListChangeEvent,
    ): any => {
      const valuesSelected: string[] = event.value;
      const objects = groupFlags.filter((param: ComboboxFlagsData) =>
        valuesSelected.includes(param.flagValue),
      );
      setSelectedObjects(objects);
    };

    return (
      <div style={{ display: 'block' }}>
        <Label style={{ opacity: 0.6 }}>
          {required ? `${props.groupName}*` : props.groupName}
        </Label>
        {props.allowSelectMultiple ? (
          <MultiSelect
            {...props}
            data={data}
            onChange={onChange}
            filterable
            onFilterChange={filterChangeMultiselect}
            popupSettings={{
              className: style['brass-dropdown-popup'],
            }}
          />
        ) : (
          <DropDownList
            style={props.style}
            className={props.className}
            data={data}
            onChange={onChange}
            filterable
            onFilterChange={filterChangeMultiselect}
            popupSettings={{
              className: style['brass-dropdown-popup'],
            }}
          />
        )}
      </div>
    );
  },
);

export { ComboboxField };
export type { ComboboxFieldProps };
