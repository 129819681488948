import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { WindowActionsBar } from '@progress/kendo-react-dialogs';
import { WindowActionsBarProps } from '@progress/kendo-react-dialogs/dist/npm/WindowActionsBar';

interface BrassWindowActionsBarProps
  extends WindowActionsBarProps,
    PropsWithChildren<any> {}

const BrassWindowActionsBar: FunctionComponent<BrassWindowActionsBarProps> = (
  props: BrassWindowActionsBarProps,
) => {
  const { className } = props;
  const _className = classnames('brass-window-actions-bar', className);
  return (
    <div className={_className}>
      <WindowActionsBar {...props} />
    </div>
  );
};

export { BrassWindowActionsBar };
export type { BrassWindowActionsBarProps };
