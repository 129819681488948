import React, { FunctionComponent, memo } from 'react';
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownsPopupSettings,
} from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { DropDownData } from '../../../../../';

interface BrassFilterCellProps extends GridFilterCellProps {
  data: DropDownData<any>[];
  defaultValue: DropDownData<any>;
  onChangeFilter: (item: DropDownData) => void;
  popupSettings?: DropDownsPopupSettings;
}

const BrassFilterCell: FunctionComponent<BrassFilterCellProps> = memo(
  (props: BrassFilterCellProps) => {
    const onClearButtonClick = (event: any): void => {
      event.preventDefault();
      props.onChange({
        value: '',
        operator: '',
        syntheticEvent: event,
      });

      props.onChangeFilter({} as DropDownData);
    };

    const onChange = (event: DropDownListChangeEvent): void => {
      const hasValue = event.value.value !== undefined;
      const selected = props.data.find(
        (param: DropDownData) => param.text === event.value.text,
      );

      if (selected) {
        props.onChange({
          value: hasValue ? selected.value : '',
          operator: hasValue ? 'eq' : '',
          syntheticEvent: event.syntheticEvent,
        });

        props.onChangeFilter(selected);
      }
    };

    return (
      <div className='k-filtercell'>
        <DropDownList
          data={props.data.filter(
            (param: DropDownData) => param.text && param.value,
          )}
          onChange={onChange}
          dataItemKey='value'
          textField='text'
          defaultValue={props.defaultValue}
          popupSettings={props.popupSettings}
        />
        <button
          className='k-button k-button-icon k-clear-button-visible'
          title='Clear'
          disabled={!props.defaultValue.value}
          onClick={onClearButtonClick}
        >
          <span className='k-icon k-i-filter-clear' />
        </button>
      </div>
    );
  },
);

export { BrassFilterCell };
export type { BrassFilterCellProps };
