import React, { FunctionComponent, useContext, memo } from 'react';
import { AreaTypeEnum, UpsertArea, ViewerContext } from '../../../../../../../';

const AddAreaDialog: FunctionComponent = memo(() => {
  const {
    navigatorFile: { projectId },

    loadData,
    showHideAddAreaDialog,
  } = useContext(ViewerContext);

  return (
    <UpsertArea
      projectId={projectId}
      type={AreaTypeEnum.PHYSICAL}
      onCancelClose={() => showHideAddAreaDialog()}
      onSaveClose={() => {
        showHideAddAreaDialog();
        loadData();
      }}
    />
  );
});

export { AddAreaDialog };
