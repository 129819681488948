import React, { FunctionComponent } from 'react';
import style from './Avatar.module.scss';
import classnames from 'classnames';
import { Avatar } from '@progress/kendo-react-layout';
import { AvatarProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/AvatarProps';
import { BrassTooltip } from '../tooltip';

interface BrassAvatarProps extends AvatarProps {
  tooltip?: string;
}

const BrassAvatar: FunctionComponent<BrassAvatarProps> = (
  props: BrassAvatarProps,
) => {
  const { tooltip, className } = props;
  const _className = classnames('brass-avatar', style['brass-avatar'], className);

  if (tooltip) {
    return (
      <BrassTooltip anchorElement='target'>
        <span title={tooltip}>
          <Avatar {...props} className={_className} />
        </span>
      </BrassTooltip>
    );
  } else {
    return <Avatar {...props} className={_className} />;
  }
};

export { BrassAvatar };
export type { BrassAvatarProps };
