import React, { FunctionComponent, useEffect, useState } from 'react';
import { translate } from './UsersDropDown.translations';
import { BrassDropDownField, BrassDropDownFieldProps } from '../../../../components';
import { ApiResult, DropDownData } from '../../../../models';
import { I18nService } from '../../../../services';
import { UserService } from '../../services';

interface UsersDropDownProps extends BrassDropDownFieldProps {}

const UsersDropdown: FunctionComponent<UsersDropDownProps> = (
  props: UsersDropDownProps,
) => {
  const [users, setUsers] = useState<DropDownData[]>(
    UserService.cacheUsersDropDownData,
  );
  const [loading, setLoading] = useState<boolean>(users.length === 0);

  useEffect(() => {
    if (!users.length) {
      UserService.getUsersDropDown().then((apiResult: ApiResult<DropDownData[]>) => {
        if (!apiResult.errorMessage) {
          setUsers(apiResult.data);
          setLoading(false);
        }
      });
    }
  }, [users.length]);

  return (
    <BrassDropDownField
      {...props}
      loading={loading}
      options={users}
      label={translate('user')}
    />
  );
};

export { UsersDropdown };
export type { UsersDropDownProps };
