import React, { FunctionComponent, memo, PropsWithChildren } from 'react';
import style from './AlertList.module.scss';
import { BrassDivFlex, BrassListView } from '../../../../../../components';
import { UserAlert } from '../../../../models';
import { DateTimeService } from '../../../../../../services';
import { ListViewItemProps } from '@progress/kendo-react-listview';

interface AlertListProps extends PropsWithChildren<any> {
  alerts: UserAlert[];
}

const ItemRender = (props: ListViewItemProps) => {
  const item: UserAlert = props.dataItem;

  return (
    <div className={style.list} style={{ margin: 0 }}>
      <div>
        <h2 style={{ fontSize: 14, color: '#454545', marginBottom: 0 }}>
          {item.title}
        </h2>
        <div style={{ fontSize: 12, color: '#a0a0a0' }}>{item.message}</div>
      </div>
      <BrassDivFlex />
      <div style={{ margin: '10px' }}>
        <div>{new Date(item.date).toLocaleString()}</div>
      </div>
    </div>
  );
};

const AlertList: FunctionComponent<AlertListProps> = memo(
  (props: AlertListProps) => (
    <BrassListView
      inlineItems={false}
      data={props.alerts}
      item={ItemRender}
      style={{
        width: '100%',
        color: '#a0a0a0',
        padding: '0 10px',
      }}
    />
  ),
);

export type { AlertListProps };
export { AlertList };
