import React, { FunctionComponent } from 'react';
import {
  hoursValidator,
  BrassMaskedTextBoxField,
  BrassMaskedTextBoxFieldProps,
} from '../../../../brass';

const BrassHoursField: FunctionComponent<BrassMaskedTextBoxFieldProps> = (
  props: BrassMaskedTextBoxFieldProps,
) => (
  <BrassMaskedTextBoxField {...props} validator={[hoursValidator]} mask='00:00' />
);

export { BrassHoursField };
