import React, { FunctionComponent, useEffect } from 'react';
import {
  CookieService,
  EncryptionService,
  EnvironmentService,
  LocalStorageService,
  PermissionService,
} from '../../../../services';
import { ApiResult, AuthUser } from '../../../../models';
import { BrassLoader } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../services';
import { AuthUserCredentials } from '../../models';

const AutoLogin: FunctionComponent = () => {
  const navigate = useNavigate();

  const redirect = new URLSearchParams(window.location.search).get('redirect');
  const homePage = `${window.location.protocol}//${window.location.host}/${
    redirect ?? 'home'
  }`;

  const handleRedirectLogin = () => {
    if (navigate) {
      navigate(`/login`);
    } else {
      window.location.pathname = '/login';
    }
  };

  const handleSetLanguage = (authUser: AuthUser) => {
    if (authUser.language === 'pt-BR') {
      localStorage.setItem(
        'language',
        JSON.stringify({
          name: 'Português',
          language: 'pt',
          iconUrl:
            'https://account.bdbsystem.com.br/languages/7346F985-BCE6-4E68-8DE3-02DFF5FD2E06.png',
        }),
      );
      return;
    }

    if (authUser.language === 'es') {
      localStorage.setItem(
        'language',
        JSON.stringify({
          name: 'Español',
          language: 'es',
          iconUrl:
            'https://account.bdbsystem.com.br/languages/9D82CBBC-31D8-44E0-8C8E-8CF97AA8D57B.png',
        }),
      );
      return;
    }

    if (authUser.language === 'en-US' || authUser.language === 'en') {
      localStorage.setItem(
        'language',
        JSON.stringify({
          name: 'English',
          language: 'en',
          iconUrl:
            'https://account.bdbsystem.com.br/languages/FE8A2132-A1E2-4062-9105-18F06D025797.png',
        }),
      );
      return;
    }

    localStorage.setItem(
      'language',
      JSON.stringify({
        name: 'Português',
        language: 'pt',
        iconUrl:
          'https://account.bdbsystem.com.br/languages/7346F985-BCE6-4E68-8DE3-02DFF5FD2E06.png',
      }),
    );
  };

  useEffect(() => {
    LocalStorageService.removeAllLocalStorage();

    let authUser = CookieService.getCookieJSON(
      CookieService.cookieAuthUser,
    ) as AuthUser;

    if (EnvironmentService.isLocalhost) {
      const auth = new URLSearchParams(window.location.search).get('auth');
      if (auth) {
        const user = JSON.parse(decodeURIComponent(escape(atob(auth))));
        CookieService.setCookieJSON(CookieService.cookieAuthUser, user);
        authUser = user;
      }
    }

    if (authUser) {
      handleSetLanguage(authUser);
      PermissionService.getPermissions()
        .then((apiResult: ApiResult<{ permissions: number[] }>) => {
          if (apiResult.success) {
            LocalStorageService.setLocalStorageJSON(
              CookieService.cookiePermissions,
              apiResult.data.permissions,
            );
            setTimeout(() => {
              window.location.href = homePage;
            }, 500);
          } else {
            handleRedirectLogin();
          }
        })
        .catch(() => {
          handleRedirectLogin();
        })
        .finally(() => {});
    } else {
      handleRedirectLogin();
    }
  }, []);

  return <BrassLoader useLoadingMask />;
};

export { AutoLogin };
