enum UserStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  EDITING = 'EDITING',
  SUSPENDED = 'SUSPENDED',
  BLOCKED = 'BLOCKED',
  ALL = 'ALL',
}

export { UserStatusEnum };
