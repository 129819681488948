import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { DatePicker, DatePickerProps } from '@progress/kendo-react-dateinputs';

interface BrassDatePickerProps extends DatePickerProps {}

const BrassDatePicker: FunctionComponent<BrassDatePickerProps> = (
  props: BrassDatePickerProps,
) => {
  const { className } = props;
  const _className = classnames('brass-date-picker', className);

  return <DatePicker {...props} className={_className} />;
};

export { BrassDatePicker };
export type { BrassDatePickerProps };
