import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconKey: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 12 6' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M2.85119 0.354996C3.85549 0.354996 4.72952 0.91593 5.17735 1.74048H10.5342L11.7937 2.87408L10.1563 4.25956L9.40059 3.50383L8.64485 4.25956L7.88912 3.50383L7.13339 4.25956H5.17722C4.7293 5.08405 3.85535 5.64505 2.85105 5.64505C1.39025 5.64505 0.206055 4.4608 0.206055 3.00005C0.206055 1.53925 1.3903 0.355046 2.85105 0.355046L2.85119 0.354996ZM1.96955 2.24433C1.55217 2.24433 1.21382 2.58268 1.21382 3.00006C1.21382 3.41745 1.55217 3.7558 1.96955 3.7558C2.38694 3.7558 2.72529 3.41745 2.72529 3.00006C2.72529 2.58268 2.38694 2.24433 1.96955 2.24433Z' />
  </svg>
);

export { SGVIconKey };
