import React, { FunctionComponent } from 'react';
import { BrassIconUser, BrassIconHome } from '../../components';

const IconPlayground: FunctionComponent = () => (
  <div>
    <BrassIconUser />
    <BrassIconHome />
  </div>
);
export { IconPlayground };
