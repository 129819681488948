import md5 from 'md5';

class EncryptionService {
  constructor() {}

  private static validateText(text: string): string {
    if (typeof text == 'string') {
      return text;
    } else {
      return JSON.stringify(text);
    }
  }

  public static fromBase64(text: string): string {
    try {
      return decodeURIComponent(escape(EncryptionService.validateText(text)));
    } catch {
      return decodeURIComponent(escape(atob(text)));
    }
  }

  public static fromBase64UrlSafe(text: string): string {
    try {
      return decodeURIComponent(
        escape(
          atob(
            EncryptionService.validateText(
              text.replace(/~/g, '+').replace(/_/g, '/').replace(/-/g, '='),
            ),
          ),
        ),
      );
    } catch {
      return decodeURIComponent(
        escape(atob(text.replace(/~/g, '+').replace(/_/g, '/').replace(/-/g, '='))),
      );
    }
  }

  public static fromBase64Loop(text: string, loop: number): string {
    if (text === undefined || text === null || !text) {
      return '';
    }

    let result = text;
    for (let l = 1; l <= loop; l++) {
      result = EncryptionService.fromBase64(result);
    }

    return result;
  }

  public static toBase64(text: string): string {
    try {
      return btoa(
        unescape(encodeURIComponent(EncryptionService.validateText(text))),
      );
    } catch {
      return btoa(unescape(encodeURIComponent(text)));
    }
  }

  public static toBase64UrlSafe(text: string): string {
    try {
      return btoa(unescape(encodeURIComponent(EncryptionService.validateText(text))))
        .replace(/\+/g, '~')
        .replace(/\//g, '_')
        .replace(/=/g, '-');
    } catch {
      return btoa(unescape(encodeURIComponent(text)))
        .replace(/\+/g, '~')
        .replace(/\//g, '_')
        .replace(/=/g, '-');
    }
  }

  public static toBase64Loop(text: string, loop: number): string {
    if (text === undefined || text === null || !text) {
      return '';
    }

    let result = text;
    for (let l = 1; l <= loop; l++) {
      result = EncryptionService.toBase64(result);
    }

    return result;
  }

  public static getMd5Encrypt(text: string): string {
    return md5(EncryptionService.validateText(text));
  }
}

export { EncryptionService };
