import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ApiResult,
  BrassDropDownField,
  BrassDropDownFieldProps,
  DropDownData,
  FileClassificationService,
  I18nService,
  II18n,
} from '../../../../';
// import { FileClassificationService } from '../../services';

interface FileClassificationDropdownProps extends BrassDropDownFieldProps {}
const FileClassificationDropdown: FunctionComponent<
  FileClassificationDropdownProps
> = (props: FileClassificationDropdownProps) => {
  const [systemClassifications, setFileClassification] =
    useState<DropDownData<any>[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    FileClassificationService.getDropdown().then(
      (result: ApiResult<DropDownData<any>[]>) => {
        if (!result.errorMessage) {
          setFileClassification(result.data);
          setLoading(false);
        }
      },
    );
  }, []);

  return (
    <BrassDropDownField
      {...props}
      loading={loading}
      name='fileClassificationId'
      options={systemClassifications}
      label={translate('fileClassification')}
    />
  );
};

const translations: II18n = {
  es: {
    fileClassification: 'Clasificación de Archivo',
  },
  enUS: {
    fileClassification: 'File Classification',
  },
  ptBR: {
    fileClassification: 'Classificação de Arquivos',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { FileClassificationDropdown };
export type { FileClassificationDropdownProps };
