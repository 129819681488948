import { FunctionComponent } from 'react';
import { BrassWindow, BrassWindowTitleCustom } from '../../brass';
import { translate } from './TranslationChecker.translations';
import style from './TranslationChecker.module.scss';
import { FieldCheckerForm } from './field-checker-form/FieldCheckerForm';
import { TranslateProps } from '../../../util/DynamicDispatchAlert';
import { Endpoints } from '../../../models';

interface TranslationCheckerProps {
  isUpdating: boolean;
  id: string;
  route: string;
  fieldsTranslate: string[];
  title?: string;
  routeSave: string;
  onSaveClose: () => void;
  onCancelClose: () => void;
  translateCustom: TranslateProps;
  endpoint?: Endpoints;
  hasRouterId?: boolean;
  width?: number;
  height?: number;
}

const BrassTranslationCheckerWindow: FunctionComponent<TranslationCheckerProps> = (
  props: TranslationCheckerProps,
) => {
  const {
    onSaveClose,
    onCancelClose,
    fieldsTranslate,
    id,
    route,
    routeSave,
    translateCustom,
    endpoint,
    hasRouterId,
    width,
    height,
  } = props;

  return (
    <BrassWindow
      title={
        <BrassWindowTitleCustom
          title={translate('titleModal')}
          className={style.title}
        />
      }
      onClose={onCancelClose}
      maximizeButton={() => null}
      minimizeButton={() => null}
      initialWidth={width ?? 730}
      initialHeight={height ?? 600}
      modal={true}
      overlayStyle={{ zIndex: 0 }}
      showDarkBackground={false}
    >
      <FieldCheckerForm
        isUpdating={false}
        id={id}
        route={route}
        routeSave={routeSave}
        translateCustom={translateCustom}
        fieldsTranslate={fieldsTranslate}
        onCancelClose={onCancelClose}
        onSaveClose={onSaveClose}
        endpoints={endpoint}
        hasRouterId={hasRouterId}
      />
    </BrassWindow>
  );
};

export { BrassTranslationCheckerWindow };
export type { TranslationCheckerProps };
