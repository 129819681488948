import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { FormElement, FormElementProps } from '@progress/kendo-react-form';

interface BrassFormElementProps extends FormElementProps {}

const BrassFormElement: FunctionComponent<BrassFormElementProps> = (
  props: BrassFormElementProps,
) => {
  const { className } = props;
  const _className = classnames('brass-form-element', className);
  return <FormElement {...props} className={_className} />;
};

export { BrassFormElement };
export type { BrassFormElementProps };
