import React, {
  FunctionComponent,
  Fragment,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';
import { BrassButtonShared, ViewerContext } from '../../../../../../';
import style from './SidePanelComponent.module.scss';
import { translations, translate } from './SidePanelComponent.translations';

interface SidePanelComponentProps {
  title: string;
  children: ReactNode;
  onCloseAditionalActions?: () => void;
  onCloseTitle?: string;
  onCloseIcon?: any;
  otherActionButtons?: Array<ReactNode>;
}

const SidePanelComponent: FunctionComponent<SidePanelComponentProps> = (
  props: SidePanelComponentProps,
) => {
  const { showDetails, setShowSidePanel } = useContext(ViewerContext);
  const [disableCloseButton, setDisableCloseButton] = useState<boolean>(false);

  useEffect(() => {
    const fnWindowMessage = (event: any): void => {
      if (event.data) {
        if (event.data.sidePanelComponent) {
          if (event.data.sidePanelComponent === 'disable-close-button') {
            setDisableCloseButton(true);
          } else {
            setDisableCloseButton(false);
          }
        }
      }
    };

    window.addEventListener('message', fnWindowMessage);
    // return () => {
    //   window.removeEventListener('message', fnWindowMessage);
    // };
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      {<h3 className={style.title}>{props.title}</h3>}
      <div
        className={showDetails ? style['children-details-opened'] : style.children}
      >
        {props.children}
      </div>
      <div className={style.bottomButtons}>
        <BrassButtonShared
          showText
          labelKey={props.onCloseTitle}
          icon={props.onCloseIcon}
          translations={translations}
          onClick={() => {
            setShowSidePanel(false);
            if (props.onCloseAditionalActions !== undefined) {
              props.onCloseAditionalActions();
            }
          }}
          className={style.bottomButtonClose}
          disabled={disableCloseButton}
        />
        {props.otherActionButtons}
      </div>
    </div>
  );
};

SidePanelComponent.defaultProps = {
  onCloseTitle: translate('closeTitle'),
};

export { SidePanelComponent };
