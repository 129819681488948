import React from 'react';
import { II18n } from '../../../..//models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    title: 'Cargar archivos adjuntos',
    IMAGE: 'Cargar imágenes',
    VIDEO: 'Subir vídeo',
    DOCUMENT: 'Cargar documento',
    IMAGE2D3D: 'Cargar 2D Drawings y 3D Models',
  },
  enUS: {
    title: 'Upload attachments',
    IMAGE: 'Upload images',
    VIDEO: 'Upload video',
    DOCUMENT: 'Upload document',
    IMAGE2D3D: 'Upload 2D Drawings and 3D Models',
  },
  ptBR: {
    title: 'Carregar anexos',
    IMAGE: 'Carregar imagens',
    VIDEO: 'Carregar vídeo',
    DOCUMENT: 'Carregar documento',
    IMAGE2D3D: 'Carregar Desenhos 2D e Modelos 3D',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
