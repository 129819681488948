class SessionStorageService {
  public static NEW_FEATURES = 'NEW_FEATURES';
  public static getSessionStorageEncrypt = (name: string): string => name;
  public static getSessionStorageDecrypt = (name: string): string => name;

  public static getSessionStorage = (name: string): string | undefined => {
    const result =
      sessionStorage.getItem(SessionStorageService.getSessionStorageEncrypt(name)) ||
      '';
    return SessionStorageService.getSessionStorageDecrypt(result);
  };

  public static getSessionStorageJSON = <T,>(name: string): T | undefined => {
    const result = SessionStorageService.getSessionStorage(name) || '';

    if (!result || result === '') {
      return undefined;
    }

    if (typeof result === 'object') {
      return result as T;
    }

    const resultType = JSON.parse(result) as T;
    return resultType;
  };

  public static setSessionStorage = (name: string, value: string): void => {
    sessionStorage.setItem(
      SessionStorageService.getSessionStorageEncrypt(name),
      SessionStorageService.getSessionStorageEncrypt(value),
    );
  };

  public static setSessionStorageJSON = (name: string, value: any): void =>
    SessionStorageService.setSessionStorage(name, JSON.stringify(value));

  public static removeSessionStorage = (name: string): void =>
    sessionStorage.removeItem(SessionStorageService.getSessionStorageEncrypt(name));

  public static setNewFeaturesFlag = (features: string[]): void => {
    const newFeatures = SessionStorageService.getSessionStorageJSON<any>(
      SessionStorageService.NEW_FEATURES,
    );
    const _features = {};
    features.forEach((feature: string) => {
      (_features as any)[feature] = !newFeatures
        ? true
        : newFeatures[feature] === undefined
        ? true
        : newFeatures[feature];
    });
    SessionStorageService.setSessionStorageJSON(SessionStorageService.NEW_FEATURES, {
      ...newFeatures,
      ..._features,
    });
  };
}

export { SessionStorageService };
