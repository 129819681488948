interface IDownloadFileByLinkProps<V = any, R = any> {
  fileUrl: string;
  callback?: (_value?: V) => R;
}

const DownloadFileByLink = async <V, R = any>(
  _props: IDownloadFileByLinkProps<V, R>,
) => {
  const { fileUrl, callback } = _props;
  return fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const downloadElement = document.createElement('a');
      downloadElement.href = blobUrl;
      downloadElement.download = fileUrl.split('/').pop() as string;
      downloadElement.style.display = 'none';
      document.body.appendChild(downloadElement);
      downloadElement.click();
      URL.revokeObjectURL(blobUrl);
      if (callback) {
        callback(blobUrl as unknown as V);
      }
    });
};

export { DownloadFileByLink };
export type { IDownloadFileByLinkProps };
