import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Animation, AnimationProps } from '@progress/kendo-react-animation';

interface BrassAnimationProps extends AnimationProps {}

const BrassAnimation: FunctionComponent<BrassAnimationProps> = (
  props: BrassAnimationProps,
) => {
  const { className } = props;
  const _className = classnames('brass-fade', className);

  return <Animation {...props} className={_className} />;
};

export { BrassAnimation };
export type { BrassAnimationProps };
