enum UserNotificationEventType {
  ALL = 'ALL',
  RECOVERY_PASSWORD = 'RECOVERY_PASSWORD',
  RHT_SUBMISSION_REQUEST = 'RHT_SUBMISSION_REQUEST',
  RHT_APPROVAL_REQUEST = 'RHT_APPROVAL_REQUEST',
  RHT_INVOICE_REQUEST = 'RHT_INVOICE_REQUEST',
  RHT_INVOICE_SUBMITTED = 'RHT_INVOICE_SUBMITTED',
  DISCIPLINE_LEADER_CHANGE = 'DISCIPLINE_LEADER_CHANGE',
  DISCIPLINE_LEADER_SUBSTITUTE_INCLUDE = 'DISCIPLINE_LEADER_SUBSTITUTE_INCLUDE',
  DISCIPLINE_LEADER_SUBSTITUTE_REMOVE = 'DISCIPLINE_LEADER_SUBSTITUTE_REMOVE',
  EXTRA_HOUR_REQUEST = 'EXTRA_HOUR_REQUEST',
  USER_WEEKLY_ACTIVITY_RECORD_PENDENCY = 'USER_WEEKLY_ACTIVITY_RECORD_PENDENCY',
  PROJECT_LEADER_RECORD_APPROVAL_PENDENCY = 'PROJECT_LEADER_RECORD_APPROVAL_PENDENCY',
  DISCIPLINE_LEADER_RECORD_APPROVAL_PENDENCY = 'DISCIPLINE_LEADER_RECORD_APPROVAL_PENDENCY',
  USER_INVOICE_SUBMIT_PENDENCY = 'USER_INVOICE_SUBMIT_PENDENCY',
  NO_TEMPLATE = 'NO_TEMPLATE',
  PAYCHECK_NOTIFICATION = 'PAYCHECK_NOTIFICATION',
  EXTRA_HOUR_REQUEST_APPROVED = 'EXTRA_HOUR_REQUEST_APPROVED',
  EXTRA_HOUR_REQUEST_DISAPPROVED = 'EXTRA_HOUR_REQUEST_DISAPPROVED',
  NAVIGATOR_SHARED_URL = 'NAVIGATOR_SHARED_URL',
  RHT_APPROPRIATE_PENDENCY = 'RHT_APPROPRIATE_PENDENCY',
  RHT_APPROPRIATE_PENDENCY_TO_LEADER = 'RHT_APPROPRIATE_PENDENCY_TO_LEADER',
  RHT_APPROPRIATE_PENDENCY_BLOCKED_USER = 'RHT_APPROPRIATE_PENDENCY_BLOCKED_USER',
  RHT_APPROPRIATE_PENDENCY_BLOCKED_USER_TO_LEADER = 'RHT_APPROPRIATE_PENDENCY_BLOCKED_USER_TO_LEADER',
  RHT_VALIDATION_PENDENCY = 'RHT_VALIDATION_PENDENCY',
  RHT_VALIDATION_PENDENCY_TO_LEADER = 'RHT_VALIDATION_PENDENCY_TO_LEADER',
  PROJECT_OPENING_TERM = 'PROJECT_OPENING_TERM',
}

export { UserNotificationEventType };
