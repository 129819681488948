import React from 'react';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    privilegesList: 'Privilegios para este grupo de usuarios',
    privileges: 'Privilegios',
    privilegeId: 'Id de Privilegio',
    insert: 'Retirar {0} privilegios seleccionados',
  },
  enUS: {
    privilegesList: 'Privileges for this user group',
    privileges: 'Privileges',
    privilegeId: 'Privilege Id',
    insert: 'Remove {0} selected privileges',
  },
  ptBR: {
    privilegesList: 'Privilégios deste grupo de usuários',
    privileges: 'Privilégios',
    privilegeId: 'Id do Privilégio',
    insert: 'Remover {0} privilégio(s) selecionados',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
