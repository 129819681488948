import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconHelpDesk: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13 7L14.5842 7.00046C13.8127 5.23429 12.0505 3.99996 10 3.99996C7.94954 3.99996 6.18738 5.23427 5.41588 7.00046L7.00006 7V14H4.00006C2.34323 14 1.00006 12.433 1.00006 10.5C1.00006 8.8655 1.96041 7.49267 3.25873 7.10767C4.08339 4.16167 6.78923 2 10.0001 2C13.2109 2 15.9167 4.16167 16.7417 7.109C18.0398 7.49267 19.0001 8.8655 19.0001 10.5C19.0001 12.433 17.6569 14 16.0001 14H15.0001C15.0001 15.5044 13.6746 16.897 12.1682 16.9945L12.0001 17L11.6172 17.001C11.3426 17.3073 10.9439 17.5 10.5001 17.5C9.67168 17.5 9.00013 16.8284 9.00013 16C9.00013 15.1715 9.67168 14.5 10.5001 14.5C10.9443 14.5 11.3434 14.6931 11.6181 14.9998L12.0001 15C12.4323 15 12.9241 14.5344 12.9922 14.0996L13.0001 14L13 7ZM5.00003 9H4.00003C3.44774 9 3.00003 9.67155 3.00003 10.5C3.00003 11.3285 3.44774 12 4.00003 12H5.00003V9ZM16 9H15V12H16C16.5523 12 17 11.3285 17 10.5C17 9.67155 16.5523 9 16 9Z' />
  </svg>
);

export { SVGIconHelpDesk };
