import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    notifications: 'Notificaciones',
    messagesInTotal: 'Mensajes en total',
    alert: 'Alerta',
    alerts: 'Alertas',
  },
  enUS: {
    notifications: 'Notifications',
    messagesInTotal: 'Messages in total',
    alert: 'Alert',
    alerts: 'Alerts',
  },
  ptBR: {
    notifications: 'Notificações',
    messagesInTotal: 'Mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
