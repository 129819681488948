import { BrassIcon } from '../../../index';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
} from 'react';
import style from './InputChat.module.scss';
import { translate } from './InputChat.translations';

type IBrassInputChatType = {
  inputHtmlElement?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  buttonHtmlElement?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
  divHtmlElement?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  color?: string;
};

const BrassInputChat = (props: IBrassInputChatType) => (
  <div
    {...props.divHtmlElement}
    className={classNames(
      style['chat-block-input'],
      props.divHtmlElement?.className,
    )}
    style={{ borderBottom: `1px solid ${props.color ?? '#3a3a3a'}` }}
  >
    <input
      type='text'
      placeholder={translate('commentInput')}
      {...props.inputHtmlElement}
      className={classNames(
        style['chat-block-edit-input'],
        props?.inputHtmlElement?.className,
      )}
    />

    <button
      {...props.buttonHtmlElement}
      className={classNames(
        style['chat-block-edit-button'],
        props.buttonHtmlElement?.className,
      )}
      style={{ backgroundColor: `${props.color ?? '#f6863c'}ad` }}
    >
      <BrassIcon icon={faPaperPlane} />
    </button>
  </div>
);

export { BrassInputChat };
