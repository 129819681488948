import React, { FunctionComponent, PropsWithChildren } from 'react';
import styleCell from './Cell.module.scss';
import classnames from 'classnames';
import { GridCellProps } from '@progress/kendo-react-grid';

interface BrassGridCellProps extends GridCellProps, PropsWithChildren<any> {
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  fieldName?: string;
}

const BrassGridCell: FunctionComponent<BrassGridCellProps> = (
  props: BrassGridCellProps,
) => {
  const { className, dataItem, textAlign, fieldName, children, style } = props;
  const _className = classnames(
    'brass-grid-cell',
    styleCell['brass-grid-cell'],
    className,
  );
  return (
    <td className={_className} style={{ ...style, textAlign }}>
      {fieldName ? dataItem[fieldName] : children}
    </td>
  );
};

export type { BrassGridCellProps };
export { BrassGridCell };
