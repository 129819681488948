import { emptyGuid } from '../../util/Guid';
import { AssetData } from '.';
import { NavigatorFileBreadcrumb } from './navigatorFileBreadcrumb.model';
import { NavigatorFileChild } from './navigatorFileChild.model';
import { NavigatorFileDocument } from './navigatorFileDocument.model';
class NavigatorFile {
  public fileId: string = emptyGuid();
  public accountId: string = emptyGuid();
  public projectId: string = emptyGuid();
  public areaId: string = emptyGuid();
  public systemId: string = emptyGuid();
  public children: NavigatorFileChild[] = [];

  public fileTypeId: string = emptyGuid();

  public tag: string = '';
  public name: string = '';
  public originalName: string = '';
  public description: string = '';

  public assetData: AssetData | undefined = undefined;
  public isProject = this.projectId !== emptyGuid();
  public isArea =
    this.isProject &&
    this.areaId !== emptyGuid() &&
    this.systemId === emptyGuid() &&
    this.fileId === emptyGuid();
  public isSystem =
    this.isProject &&
    !this.isArea &&
    this.systemId !== emptyGuid() &&
    this.fileId === emptyGuid();
  public isFile =
    this.isProject && !this.isArea && !this.isSystem && this.fileId !== emptyGuid();

  public hasAssetLink = this.assetData !== undefined;
  public allowLinkAsset =
    !this.hasAssetLink &&
    this.isSystem &&
    this.children.filter((item: any) => item.type !== 'system').length > 0;

  constructor(navigatorFile: Partial<NavigatorFile>) {
    Object.assign(this, navigatorFile);
  }
}

// Utilizado quando o usuário está VISUALIZANDO um Modelo
class NavigatorFileViewer {
  public accountId: string = emptyGuid();
  public projectId: string = emptyGuid();
  public areaId: string = emptyGuid();
  public systemId: string = emptyGuid();
  public fileId: string = emptyGuid();

  public tag: string = '';
  public name: string = '';
  public children: NavigatorFileChild[] = [];
  public document: NavigatorFileDocument = new NavigatorFileDocument({});
  public breadcrumb: NavigatorFileBreadcrumb[] = [];

  public assetData: AssetData | undefined = undefined;
  public isProject =
    this.areaId === emptyGuid() &&
    this.systemId === emptyGuid() &&
    this.fileId === emptyGuid();
  public isArea =
    this.isProject &&
    String(this.areaId) !== emptyGuid() &&
    this.systemId === emptyGuid() &&
    this.fileId === emptyGuid();
  public isSystem =
    this.isProject &&
    !this.isArea &&
    this.systemId !== emptyGuid() &&
    this.fileId === emptyGuid();
  public isFile =
    this.isProject && !this.isArea && !this.isSystem && this.fileId !== emptyGuid();

  public hasAssetLink = this.assetData !== undefined;

  public isAsset =
    this.isSystem &&
    this.children.filter((item: any) => item.type !== 'system').length > 0;

  public allowLinkAsset =
    !this.hasAssetLink &&
    this.isSystem &&
    this.children.filter((item: any) => item.type !== 'system').length > 0;

  constructor(navigatorFile: Partial<NavigatorFileViewer>) {
    // eslint-disable-next-line prefer-const
    let navigatorFileTemp = navigatorFile;
    navigatorFileTemp.isArea =
      navigatorFile.areaId !== emptyGuid() &&
      navigatorFile.systemId === emptyGuid() &&
      navigatorFile.fileId === emptyGuid();

    navigatorFileTemp.isSystem =
      !navigatorFile.isArea &&
      navigatorFile.systemId !== emptyGuid() &&
      navigatorFile.fileId === emptyGuid();

    navigatorFileTemp.isFile =
      !navigatorFile.isArea &&
      !navigatorFile.isSystem &&
      navigatorFile.fileId !== emptyGuid();

    navigatorFileTemp.isProject = navigatorFile.projectId !== emptyGuid();

    navigatorFileTemp.hasAssetLink = navigatorFile.assetData !== undefined;

    navigatorFileTemp.isAsset =
      navigatorFile.isSystem &&
      navigatorFile.children &&
      navigatorFile.children.filter(
        (item: any) => item.type === 'system' || item.type === 'area',
      ).length < 1;

    navigatorFileTemp.allowLinkAsset =
      !navigatorFileTemp.hasAssetLink &&
      navigatorFile.isSystem &&
      navigatorFile.children &&
      navigatorFile.children.filter(
        (item: any) => item.type === 'system' || item.type === 'area',
      ).length < 1;

    Object.assign(this, navigatorFileTemp);
  }
}

export { NavigatorFile, NavigatorFileViewer };
