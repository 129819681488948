import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Splitter, SplitterProps } from '@progress/kendo-react-layout';

interface BrassSplitterProps extends SplitterProps {}

const BrassSplitter: FunctionComponent<BrassSplitterProps> = (
  props: BrassSplitterProps,
) => {
  const { className } = props;
  const _className = classnames('brass-splitter', className);

  return <Splitter {...props} className={_className} />;
};

export { BrassSplitter };
export type { BrassSplitterProps };
