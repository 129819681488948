import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { getLanguagesStorage } from '../../../components/brass';
import { GeneralCardBorderColorsEnum } from '../../../models/enum/GeneralCardBorderColorsEnum';
import { ListCardModel } from '../../../models/ListCard.model';
import { BrassGeneralCard } from '../card';
import style from './ListCard.module.scss';
import { translateForLanguage } from './ListCard.translations';

interface BrassListCardProps {
  width?: string;
  list: ListCardModel[];
  setList: React.Dispatch<React.SetStateAction<ListCardModel[] | undefined>>;
  onAddClick?: (item: any) => void;
  onEditClick?: (item: any) => void;
  onConfigClick?: (item: any) => void;
  fieldIdName: string;
  allowEdit: boolean;
  imageReverseLine?: boolean;
  bottomLine?: boolean;
  direction?: 'column' | 'row';
  vertical?: boolean;
  descriptionFieldName?: string;
  titleElement?: (e?: any) => ReactElement;
  contentElement?: (e?: any) => ReactElement;
  noImage?: boolean;
  imgStyle?: React.CSSProperties;
  colorIcons?: string;
  widthCard?: string;
  actionsStyle?: React.CSSProperties;
  contentCardText?: React.CSSProperties;
  limitedTextColumn?: boolean;
  limitedTextRow?: boolean;
  limitedText?: boolean;
  className_contentCard?: React.CSSProperties;
  className_contentCardBox1?: React.CSSProperties;
  className_contentCardImage?: React.CSSProperties;
  className_contentCardBox2?: React.CSSProperties;
  className_contentCardText?: React.CSSProperties;
  className_contentCardTextLimited?: React.CSSProperties;
  className_contentCardSubTitle?: React.CSSProperties;
  className_contentCardActions?: React.CSSProperties;
  className_contentCardTextTooltip?: React.CSSProperties;
  take?: number;
  scrollObserver?: any;
  className_contentList?: any;
  className_contentBottomLine?: React.CSSProperties;
  isIconConfig?: boolean;
  noGeneralTooltip?: boolean;
}

const BrassListCard: FunctionComponent<BrassListCardProps> = (
  props: BrassListCardProps,
) => {
  const {
    list,
    setList,
    onAddClick,
    onEditClick,
    onConfigClick,
    isIconConfig,
    fieldIdName,
    width,
    allowEdit,
    imageReverseLine,
    direction,
    bottomLine,
    vertical,
    descriptionFieldName,
    titleElement,
    contentElement,
    noImage,
    imgStyle,
    colorIcons,
    widthCard,
    limitedTextColumn,
    limitedTextRow,
    limitedText,
    className_contentCard,
    className_contentCardBox1,
    className_contentCardImage,
    className_contentCardBox2,
    className_contentCardText,
    className_contentCardTextLimited,
    className_contentCardSubTitle,
    className_contentCardActions,
    className_contentCardTextTooltip,
    take,
    scrollObserver,
    className_contentList,
    className_contentBottomLine,
    noGeneralTooltip,
  } = props;

  const selected = (item: ListCardModel): void => {
    const newList = list?.map((i) => {
      if ((i as any)[fieldIdName] === (item as any)[fieldIdName]) {
        i.isSelected = !i.isSelected;
      } else {
        i.isSelected = false;
      }
      return i;
    });
    setList(newList);
  };

  if (onEditClick) {
    return (
      <div
        className={classNames(
          {
            [style['list']]: !vertical,
            [style['flexColumn']]: vertical,
          },
          className_contentList,
        )}
        style={{ width: width ? width : 'auto' }}
      >
        {list?.map((item: ListCardModel, index) => (
          <BrassGeneralCard
            key={(item as any)[fieldIdName] + index + 1}
            colorIcons={colorIcons}
            imgStyle={imgStyle}
            colorBorder={item?.color}
            title={item.name}
            entity={item}
            titleElement={titleElement}
            contentElement={contentElement}
            onEditClick={() => {
              onEditClick ? onEditClick(item) : null;
            }}
            description={
              !descriptionFieldName
                ? item.property
                : (item as any)[descriptionFieldName]
            }
            onAddClick={() => {
              selected(item);
              onAddClick ? onAddClick(item) : null;
            }}
            noGeneralTooltip={noGeneralTooltip}
            selected={item.isSelected}
            allowEdit={allowEdit}
            image={item.fileUrl!!}
            imageReverseLine={imageReverseLine!}
            direction={direction!}
            bottomLine={bottomLine!}
            vertical={vertical}
            noImage={noImage}
            widthCard={widthCard}
            limitedTextColumn={limitedTextColumn}
            limitedTextRow={limitedTextRow}
            limitedText={limitedText}
            className_contentCard={className_contentCard}
            className_contentCardBox1={className_contentCardBox1}
            className_contentCardImage={className_contentCardImage}
            className_contentCardBox2={className_contentCardBox2}
            className_contentCardText={className_contentCardText}
            className_contentCardTextLimited={className_contentCardTextLimited}
            className_contentCardSubTitle={className_contentCardSubTitle}
            className_contentCardActions={className_contentCardActions}
            className_contentCardTextTooltip={className_contentCardTextTooltip}
            className_contentBottomLine={className_contentBottomLine}
            onConfigClick={() => {
              onConfigClick ? onConfigClick(item) : null;
            }}
            isIconConfig={isIconConfig}
          ></BrassGeneralCard>
        ))}
        {take && scrollObserver && list?.length >= take && (
          <div ref={scrollObserver} style={{ height: '20px' }}></div>
        )}
        {list?.length === 0 && (
          <div
            className={classNames({
              [style['no-data']]: !vertical,
              [style['no-data-vertical']]: vertical,
            })}
          >
            <span>
              {translateForLanguage('noData', getLanguagesStorage().language)}
            </span>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          {
            [style['list']]: !vertical,
            [style['flexColumn']]: vertical,
          },
          className_contentList,
        )}
        style={{ width: width ? width : 'auto' }}
      >
        {list?.map((item: ListCardModel, index) => (
          <BrassGeneralCard
            colorIcons={colorIcons}
            imgStyle={imgStyle}
            key={(item as any)[fieldIdName] + index + 1}
            colorBorder={item?.color}
            title={item?.name}
            entity={item}
            titleElement={titleElement}
            contentElement={contentElement}
            description={
              !descriptionFieldName
                ? item?.property
                : (item as any)[descriptionFieldName]
            }
            onAddClick={() => {
              selected(item);
              onAddClick ? onAddClick(item) : null;
            }}
            selected={item.isSelected}
            allowEdit={false}
            image={item.fileUrl!!}
            imageReverseLine={imageReverseLine!}
            direction={direction}
            vertical={vertical}
            noImage={noImage}
            widthCard={widthCard}
            limitedTextColumn={limitedTextColumn}
            limitedTextRow={limitedTextRow}
            limitedText={limitedText}
            className_contentCard={className_contentCard}
            className_contentCardBox1={className_contentCardBox1}
            className_contentCardImage={className_contentCardImage}
            className_contentCardBox2={className_contentCardBox2}
            className_contentCardText={className_contentCardText}
            className_contentCardTextLimited={className_contentCardTextLimited}
            className_contentCardSubTitle={className_contentCardSubTitle}
            className_contentCardActions={className_contentCardActions}
            className_contentCardTextTooltip={className_contentCardTextTooltip}
            className_contentBottomLine={className_contentBottomLine}
            onConfigClick={() => {
              onConfigClick ? onConfigClick(item) : null;
            }}
            isIconConfig={isIconConfig}
            bottomLine={bottomLine!}
            noGeneralTooltip={noGeneralTooltip}
          ></BrassGeneralCard>
        ))}
        {take && scrollObserver && list?.length >= take && (
          <div ref={scrollObserver} style={{ height: '20px' }}></div>
        )}
        {list?.length === 0 && (
          <div
            className={classNames({
              [style['no-data']]: !vertical,
              [style['no-data-vertical']]: vertical,
            })}
          >
            <span>
              {translateForLanguage('noData', getLanguagesStorage().language)}
            </span>
          </div>
        )}
      </div>
    );
  }
};

export { BrassListCard };
