import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    addUserGroup: 'Editar grupo de usuarios',
    editUserGroup: 'Agregar grupo de usuarios',
  },
  enUS: {
    addUserGroup: 'Edit user group',
    editUserGroup: 'Add user group',
  },
  ptBR: {
    addUserGroup: 'Editar grupo de usuário',
    editUserGroup: 'Adicionar grupo de usuário',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
