import { I18nService } from '../../../../../../services';
import { II18n } from '../../../../../../models';

const translations: II18n = {
  es: {
    area: 'ÁREA',
    system: 'SISTEMA',
    search: 'Buscar',
  },
  enUS: {
    area: 'AREA',
    system: 'SYSTEM',
    search: 'Search',
  },
  ptBR: {
    area: 'ÁREA',
    system: 'SISTEMA',
    search: 'Pesquisar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
