import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonRefreshV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-refresh'
    labelKey='refresh'
    tooltipKey='clickToRefresh'
    translations={translations}
    icon={<BrassIcon icon={faSync} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    refresh: 'actualizar',
    clickToRefresh: 'Haga clic para actualizar',
  },
  enUS: {
    refresh: 'Refresh',
    clickToRefresh: 'Click to refresh',
  },
  ptBR: {
    refresh: 'Atualizar',
    clickToRefresh: 'Clique para atualizar',
  },
};

export { BrassButtonRefreshV1 };
