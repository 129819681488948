import React, { FunctionComponent, memo, PropsWithChildren } from 'react';
import style from './NotificationCard.module.scss';
import classnames from 'classnames';
import { BrassIconProps } from '../../../../../components';

interface NotificationCardProps extends PropsWithChildren<any> {
  Icon: FunctionComponent<BrassIconProps>;
  description: string;
  data: string | number;
}

const NotificationCard: FunctionComponent<NotificationCardProps> = memo(
  (props: NotificationCardProps) => {
    const { Icon, data, description } = props;

    return (
      <div className={classnames(style.container, props.className)}>
        <Icon size='3x' className={style.icon} />
        <span className={style.data}>{data}</span>
        <p
          className={style.description}
          dangerouslySetInnerHTML={{
            __html: description!,
          }}
        ></p>
      </div>
    );
  },
);

export type { NotificationCardProps };
export { NotificationCard };
