import React, { FunctionComponent } from 'react';
import {
  BrassTextField,
  BrassTextFieldProps,
  I18nService,
  II18n,
} from '../../../../';

const translations: II18n = {
  es: {
    originalNameLabel: 'Nome Arquivo',
  },
  enUS: {
    originalNameLabel: 'File Name',
  },
  ptBR: {
    originalNameLabel: 'Nome Arquivo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const OriginalNameField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <BrassTextField
    {...props}
    disabled
    name='originalName'
    label={translate('originalNameLabel')}
  />
);

export { OriginalNameField };
