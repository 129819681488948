import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonOK: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-ok'
    labelKey='ok'
    translations={translations}
    {...props}
  />
);

const translations: II18n = {
  es: {
    ok: 'ok',
  },
  enUS: {
    ok: 'ok',
  },
  ptBR: {
    ok: 'ok',
  },
};

export { BrassButtonOK };
