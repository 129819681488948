import { emptyGuid } from '../index';
import { v4 } from 'uuid';

interface IHierarchyItemsRow {
  rowId: string;
  item: string;
  level: number;
  parentRowId: string;
  [propName: string]: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const HierarchyItems = {
  utils: () => {
    const findListChildren = (_itens: IHierarchyItemsRow[], rowId: string) => {
      const listChildren = _itens.filter((item) => item.parentRowId === rowId);
      return listChildren;
    };

    const findTreeListChildren = (
      _itens: IHierarchyItemsRow[],
      rowId: string,
      _listChildren = [] as unknown as IHierarchyItemsRow[],
    ) => {
      const listChildrenFounded = findListChildren(_itens, rowId);
      listChildrenFounded.forEach((_children) => {
        _listChildren.push(_children);
        findTreeListChildren(_itens, _children.rowId, _listChildren);
      });
      return _listChildren;
    };

    const findTreeListParent = (
      _itens: IHierarchyItemsRow[],
      parentRowId: string,
      listParents = [] as unknown as IHierarchyItemsRow[],
    ) => {
      const parentIndex = _itens.findIndex((item) => item.rowId === parentRowId);
      if (parentIndex > -1) {
        listParents.push(_itens[parentIndex]);
        findTreeListParent(_itens, _itens[parentIndex].parentRowId, listParents);
      }
      return listParents;
    };

    return {
      findListChildren,
      findTreeListChildren,
      findTreeListParent,
    };
  },

  deleteItem: (_itens: IHierarchyItemsRow[], _actualItem: IHierarchyItemsRow) => {
    const actualItemFindIndex = _itens.findIndex(
      (item) => item.rowId === _actualItem.rowId,
    );

    if (actualItemFindIndex > -1) {
      const listChildren = HierarchyItems.utils().findTreeListChildren(
        _itens,
        _actualItem.rowId,
      );

      listChildren.forEach((x) => HierarchyItems.deleteItem(_itens, x));

      _itens.splice(actualItemFindIndex, 1);
    }

    return _actualItem;
  },

  insertSubItem: (_itens: IHierarchyItemsRow[], _actualItem: IHierarchyItemsRow) => {
    const actualItemFindIndex = _itens.findIndex(
      (item) => item.rowId === _actualItem.rowId,
    );
    const nextItemIndex = actualItemFindIndex + 1;

    if (actualItemFindIndex > -1) {
      const listChildren = HierarchyItems.utils().findTreeListChildren(
        _itens,
        _actualItem.rowId,
      );
      // find children in level
      const listChildrenLevel = listChildren.filter(
        (_) => _.level === _actualItem.level + 1,
      );
      const lastChildrenLevel =
        listChildrenLevel.length > 0
          ? listChildrenLevel.slice(-1)[0]
          : ({} as IHierarchyItemsRow);

      let itemValue = _actualItem.item + '.1';

      if (lastChildrenLevel?.rowId) {
        const newLastItemNumber = Number(lastChildrenLevel.item.slice(-1)[0]) + 1;
        const value = lastChildrenLevel.item.split('.').slice(0, -1);
        value.push(newLastItemNumber + '');
        itemValue = value.join('.');
      }

      const item = {
        ..._actualItem,
        rowId: v4(),
        item: itemValue,
        level: _actualItem.level + 1,
        parentRowId: _actualItem.rowId,
      } as IHierarchyItemsRow;

      const lastChildrenLevelFindIndex = _itens.findIndex(
        (_) => lastChildrenLevel.rowId === _.rowId,
      );
      const spliceStart =
        lastChildrenLevelFindIndex > -1
          ? lastChildrenLevelFindIndex + 1
          : nextItemIndex;
      _itens.splice(spliceStart, 0, item);
    }
  },

  insertItem: (_itens: IHierarchyItemsRow[], _actualItem: IHierarchyItemsRow) => {
    const actualItemFindIndex = _itens.findIndex(
      (item) => item.rowId === _actualItem.rowId,
    );

    if (actualItemFindIndex > -1) {
      const splitItem = _actualItem.item.split('.');
      splitItem[splitItem.length] = String(Number(splitItem.slice(-1)) + 1);

      const item = {
        rowId: v4(),
        item: splitItem.join('.'),
        level: _actualItem.level,
        parentRowId: _actualItem.rowId,
      } as IHierarchyItemsRow;

      const listChildren = HierarchyItems.utils().findTreeListChildren(
        _itens,
        _actualItem.rowId,
      );

      _itens.splice(listChildren.length + 1, 0, item);
    } else {
      _itens.push(_actualItem);
    }
  },

  sortItems: <T extends IHierarchyItemsRow>(_itens: T[]) => {
    const result = [] as T[];
    const sortHierarchy = (parentRowId: string) => {
      const children = _itens.filter((i) => i.parentRowId === parentRowId);
      children.sort((a, b) => a.item.localeCompare(b.item));
      children.forEach((i) => {
        result.push({ ...i });
        sortHierarchy(i.rowId);
      });
    };

    sortHierarchy('00000000-0000-0000-0000-000000000000');
    return result;
  },

  HierarchyItemsSort: <T extends IHierarchyItemsRow>(_itens: T[]) => {
    const newList = [] as T[];
    const emptyParentRows = _itens.filter((i) => i.parentRowId === emptyGuid());

    emptyParentRows.forEach((x, i) => {
      x.item = String(i + 1);
      newList.push(x);

      const listChildren = HierarchyItems.utils().findTreeListChildren(
        _itens,
        x.rowId,
      );

      for (let index = 0; index < listChildren?.length; index++) {
        const listOfLevels = listChildren.filter((_) => _.level === index + 1);
        listOfLevels.forEach((l, li) => {
          const splitItem = l.item.split('.');
          splitItem[0] = x.item;
          splitItem[splitItem.length - 1] = String(li + 1);

          l.item = splitItem.join('.');
          newList.push(l as any);
        });
      }
    });

    return HierarchyItems.sortItems<T>(newList);
  },
};

export { HierarchyItems };
export type { IHierarchyItemsRow };
