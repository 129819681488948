import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    addNotification: 'Responder Notificación',
  },
  enUS: {
    addNotification: 'Reply Notification',
  },
  ptBR: {
    addNotification: 'Responder Notificação',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
