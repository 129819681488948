import React, { Fragment, FunctionComponent } from 'react';
import { translate } from './DeleteGroupWindow.translations';
import {
  BrassButtonCancel,
  BrassButtonYes,
  BrassWindow,
} from '../../../../../components';

interface DeleDeleteGroupWindowProps {
  notificationUserId: string;
  onCancelClose: () => void;
  onConfirm: () => void;
}

const DeleteGroupWindow: FunctionComponent<DeleDeleteGroupWindowProps> = (
  props: DeleDeleteGroupWindowProps,
) => (
  <BrassWindow
    minimizeButton={() => <Fragment />}
    maximizeButton={() => <Fragment />}
    /* className={style['chat-window']} */
    /* style={{ paddingBottom: '15px' }} */
    width={400}
    height={170}
    onClose={props.onCancelClose}
    showDarkBackground={false}
    closeOnBackgroundClick={false}
    title={translate('title')}
  >
    <div>
      <div style={{ paddingBottom: '15px' }}>{translate('confirmation')}</div>
      <BrassButtonYes style={{ float: 'right' }} onClick={props.onConfirm} />
      <BrassButtonCancel
        style={{ float: 'right', marginRight: 20 }}
        onClick={props.onCancelClose}
      />
    </div>
  </BrassWindow>
);

export { DeleteGroupWindow };
