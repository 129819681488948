import { emptyGuid } from '../util';
import { getLanguagesStorage } from '../components';

class Stage {
  public moduleId: string = emptyGuid();
  public stageId: string = emptyGuid();
  public name: string = '';
  public description: string = '';
  public order?: number;
  public checked: boolean = false;
  public selected: boolean = false;
  public visualized: boolean = false;
  public type: string = '';
  public status: string = '';
  public document?: any;
  public language: string = getLanguagesStorage()?.language;

  constructor(stage: Partial<Stage>) {
    Object.assign(this, stage);
  }
}

export { Stage };
