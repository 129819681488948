import { ApiResult, Endpoints } from '../models';
import { ApiService } from './api.service';

class RelationalContextMapService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async getAllRelationalContextMap(
    language: string,
    filter: string,
    take: number,
    skip: number,
  ): Promise<ApiResult> {
    return await RelationalContextMapService.api.get(
      `/connect/v1/map-context-relational/list-all?language=${language}&search=${filter}&take=${take}&skip=${skip}`,
    );
  }

  public static async getRelationalContextMapId(
    id: string,
    language: string,
  ): Promise<ApiResult> {
    return await RelationalContextMapService.api.get(
      `/connect/v1/map-context-relational/${id}?language=${language}`,
    );
  }
}

export { RelationalContextMapService };
