const userNotificationTypeGroup = {
  WORK_TIME_RECORD: [
    'RHT_AUTHORIZATION_COMMENT',
    'RHT_SUBMISSION_REQUEST',
    'RHT_APPROVAL_REQUEST',
    'RHT_INVOICE_SUBMITTED',
    'RHT_APPROPRIATE_COMMENT',
    'RHT_APPROPRIATE_PENDENCY',
    'RHT_APPROPRIATE_PENDENCY_TO_LEADER',
    'RHT_APPROPRIATE_PENDENCY_BLOCKED_USER',
    'RHT_APPROPRIATE_PENDENCY_BLOCKED_USER_TO_LEADER',
    'RHT_VALIDATION_PENDENCY',
    'RHT_VALIDATION_PENDENCY_TO_LEADER',
    'DISCIPLINE_LEADER_CHANGE',
    'DISCIPLINE_LEADER_SUBSTITUTE_INCLUDE',
    'DISCIPLINE_LEADER_SUBSTITUTE_REMOVE',
    'EXTRA_HOUR_REQUEST',
    'EXTRA_HOUR_REQUEST_APPROVED',
    'EXTRA_HOUR_REQUEST_DISAPPROVED',
    'USER_WEEKLY_ACTIVITY_RECORD_PENDENCY',
    'PROJECT_LEADER_RECORD_APPROVAL_PENDENCY',
    'DISCIPLINE_LEADER_RECORD_APPROVAL_PENDENCY',
  ],
  FINANCE: [
    'INVOICE_COMMENT',
    'RHT_INVOICE_ISSUE',
    'RHT_INVOICE_REQUEST',
    'USER_INVOICE_SUBMIT_PENDENCY',
    'PAYCHECK_NOTIFICATION',
  ],
  GENERAL: ['RECOVERY_PASSWORD', 'NO_TEMPLATE'],
};

export { userNotificationTypeGroup };
