import React, { Fragment, FunctionComponent } from 'react';
import style from './Dialog.module.scss';
import classnames from 'classnames';
import { Dialog, DialogProps } from '@progress/kendo-react-dialogs';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../shared/icon';
import { createPortal } from 'react-dom';
import { BrassBackground } from '../layout';

interface BrassDialogProps extends DialogProps {
  showDarkBackground?: boolean;
  iconTitle?: IconDefinition;
  alignRight?: boolean;
  styleBackground?: React.CSSProperties;
}

const BrassDialog: FunctionComponent<BrassDialogProps> = (
  props: BrassDialogProps,
) => {
  const { className, iconTitle, title, alignRight, styleBackground } = props;
  const _className = classnames(
    'brass-dialog',
    style['brass-dialog'],
    alignRight ? style['brass-dialog-align-right'] : '',
    className,
  );

  const _props = { ...props };
  delete _props.iconTitle;
  delete _props.title;

  let _title = title;
  if (iconTitle) {
    _title = (
      <div className={style['dialog-title-with-icon']}>
        <BrassIcon icon={iconTitle} />
        <div>{title}</div>
      </div>
    );
  }

  return (
    <Fragment>
      {props.showDarkBackground && <BrassBackground style={styleBackground} />}
      {createPortal(
        <Dialog {...props} className={_className} title={_title} />,
        document.getElementById('overlay-root') as HTMLElement,
      )}
    </Fragment>
  );
};

BrassDialog.defaultProps = {
  showDarkBackground: true,
  alignRight: false,
};

export { BrassDialog };
export type { BrassDialogProps };
