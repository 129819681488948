class Breadcrumb {
  public title: string = '';
  public route: string = '';
  public icon?: any = undefined;
  public disableTranslator: boolean = false;
  public state?: any = undefined;
  public isLoading?: boolean = false;
  public onClick?: () => void = () => {};

  constructor(breadcrumb: Partial<Breadcrumb>) {
    Object.assign(this, breadcrumb);
  }

  private static createPaths(): string[] {
    const routes = location.pathname.split('/').filter((el) => el.length);
    const paths: any = {};
    routes.reduce((prev, next, index) => {
      paths[routes[index]] = prev + '/' + next;
      return prev + '/' + next;
    });
    return paths;
  }

  public static createBreadcrumbs(
    translate: (key: string, args?: string[]) => string,
  ): Breadcrumb[] {
    const paths = this.createPaths();
    return Object.entries(paths).map(
      (entry) =>
        new Breadcrumb({
          route: `/${entry[1]}`,
          title:
            translate(entry[0]).charAt(0).toUpperCase() +
            translate(entry[0]).slice(1),
        }),
    );
  }
}

export { Breadcrumb };
