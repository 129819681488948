import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import {
  BrassField,
  BrassFieldProps,
  BrassUploaderProps,
  requiredValidator,
} from '../../../../brass';
import { BrassButtonShared, BrassIcon } from '../../../../shared';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@progress/kendo-react-labels';
import styles from './SelectUploadImage.module.scss';
import { UploadImageContext } from './UploadImageContext';
import { SelectUploadImageDialog } from './SelectUploadImageDialog/SelectUploadImageDialog';
import style from './SelectUploadImage.module.scss';
import classNames from 'classnames';

export interface UploaderFieldInputProps extends BrassUploaderProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

export interface BrassUploaderImgFieldProps
  extends Omit<BrassFieldProps & UploaderFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string | React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  imageUrl: string;
  showLargeImage?: boolean;
  showMediumImage?: boolean;
  isClose?: boolean;
  IconClass?: string;
  setIsClose?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const DEFAULT_IMAGE =
  'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';

const BrassSelectUploadImageField: FunctionComponent<BrassUploaderImgFieldProps> = (
  props,
) => {
  const {
    required,
    label,
    validator,
    loading,
    onChange,
    showLargeImage,
    setIsClose,
    showMediumImage,
    className,
  } = props;
  const { isActive, setIsActive, saveUrl, base64File } =
    useContext(UploadImageContext);
  const imageRef = useRef<HTMLImageElement>({} as HTMLImageElement);

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  useEffect(() => {
    if (setIsClose) {
      setIsClose(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (base64File || saveUrl) {
      imageRef.current.src = base64File || saveUrl;
    }
  }, [base64File, saveUrl]);

  const handleChangeUrl = (url: string) => {
    onChange(url);
  };

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={'Upload'}
        component={BrassSelectUploadImageField}
      />
    );
  }

  return (
    <>
      <FieldWrapper>
        <Label className={style.label}>
          {label && <div>{required ? `${label}*` : label}</div>}
        </Label>
        <div
          className={classNames(
            {
              [styles['container-view-select']]: !showLargeImage && !showMediumImage,
              [styles['container-view-larger']]: showLargeImage,
              [styles['container-view-medium']]: showMediumImage,
            },
            className,
          )}
        >
          <div
            className={classNames({
              [styles['container-image-dotted']]:
                !showLargeImage && !showMediumImage,
              [styles['larger-container-image']]: showLargeImage,
              [styles['medium-container-image']]: showMediumImage,
            })}
          >
            <img
              src={props?.imageUrl ?? base64File ?? saveUrl ?? DEFAULT_IMAGE}
              alt='img'
              ref={imageRef}
              className={classNames({
                [styles['image-preview']]: !showLargeImage && !showMediumImage,
                [styles['large-image-preview']]: showLargeImage,
                [styles['medium-image-preview']]: showMediumImage,
              })}
              onError={(e: any) => {
                (e.target as HTMLImageElement).src = DEFAULT_IMAGE;
              }}
            />
          </div>
          <BrassButtonShared
            onClick={() => setIsActive(true)}
            type='button'
            className={styles['button-shared-image']}
            icon={
              props.IconClass ? (
                <BrassIcon className={props.IconClass} icon={faImages} />
              ) : (
                <BrassIcon className={props.IconClass} icon={faImages} />
              )
            }
            disabled={props.disabled}
          />
        </div>

        {isActive && (
          <SelectUploadImageDialog visible={isActive} changeUrl={handleChangeUrl} />
        )}
      </FieldWrapper>
    </>
  );
};

export { BrassSelectUploadImageField };
