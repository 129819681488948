import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import 'hammerjs'; // Necessário para resolver uma warning no console
import { Chart, ChartProps } from '@progress/kendo-react-charts';

interface BrassChartProps extends ChartProps, PropsWithChildren<any> {}

const BrassChart: FunctionComponent<BrassChartProps> = (props: BrassChartProps) => {
  const { className } = props;
  const _className = classnames('brass-chart', className);
  return (
    <div className={_className}>
      <Chart {...props} />
    </div>
  );
};

export { BrassChart };
export type { BrassChartProps };
