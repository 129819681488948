import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const BrassButtonExpandUp: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    className='brass-button-expand-up'
    labelKey='open'
    tooltipKey='clickToOpen'
    translations={translations}
    icon={<BrassIcon icon={faChevronUp} size='2x' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    open: 'Abierto',
    clickToOpen: 'Haga clic aquí para abrir/expandir',
  },
  enUS: {
    open: 'Open',
    clickToOpen: 'Click here to open/expand',
  },
  ptBR: {
    open: 'Abrir',
    clickToOpen: 'Clique aqui para abrir/expandir',
  },
};

export { BrassButtonExpandUp };
