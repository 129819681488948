import React, { Fragment, FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import style from './BreadCrumb.module.scss';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BrassBar } from '../../brass/bar';
import { BrassIcon } from '../icon';
import { RootReducerProps } from '../../../store';
import { Breadcrumb } from '../../../models';
import { BrassLoader } from '../../brass';

interface BrassBreadcrumb {
  round?: boolean;
  isModule?: boolean;
}
const BrassBreadCrumb: FunctionComponent<BrassBreadcrumb> = memo(
  (props: BrassBreadcrumb) => {
    const navigate = useNavigate();
    const breadCrumb = useSelector<RootReducerProps>(
      (state) => state.breadcrumb.breadcrumbs,
    ) as Breadcrumb[];

    // Remove breadcrumb of home -> isModule
    // if (breadCrumb.length === 1 && props?.isModule === false) {
    //   return <Fragment />;
    // }

    return (
      <BrassBar
        themeColor='dark'
        className={classnames(
          style['breadcrumb'],
          props.round && style['breadcrumb-round'],
        )}
      >
        {breadCrumb.map((node: Breadcrumb, index: number) => {
          const isFirst = index === 0;
          const isLast = index === breadCrumb.length - 1;
          return (
            <Fragment key={node.title + node.route + index.toString()}>
              <div
                onKeyPress={() => {}}
                role={'button'}
                tabIndex={0}
                className={classnames(
                  isFirst && style['breadcrumb-first'],
                  (!isLast || (isFirst && props.isModule)) &&
                    style['breadcrumb-link'],
                  style['breadcrumb-ellipse'],
                )}
                onClick={(e) => {
                  if (isLast) {
                    e.preventDefault();
                    return;
                  }
                  if (node.onClick) {
                    node.onClick();
                  }
                  if (props.isModule && index === 0) {
                    window.location.replace(node.route);
                  } else {
                    navigate(node.route, { state: node?.state });
                  }
                }}
                translate={node.disableTranslator ? 'no' : 'yes'}
              >
                {node?.isLoading ? (
                  <BrassLoader type='pulsing' />
                ) : (
                  <>
                    {node.icon ? node.icon : <Fragment />}
                    {node.title}
                  </>
                )}
              </div>
              {breadCrumb.length !== index + 1 && (
                <BrassIcon icon={faChevronRight} />
              )}
            </Fragment>
          );
        })}
      </BrassBar>
    );
  },
);

BrassBreadCrumb.defaultProps = {
  round: false,
};

export { BrassBreadCrumb };
