import { BrassIcon } from '../../../shared';
import React, { useEffect, useState } from 'react';
import styles from './DropdownLanguages.module.scss';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { LanguagesService } from '../../../../services/languages.service';

interface LanguagesModel {
  name: string;
  language: string;
  iconUrl: string;
}

export interface ILanguagesModelProps {
  onChange?: (item: LanguagesModel) => void;
}

const BrassDropDownLanguage: React.FunctionComponent<ILanguagesModelProps> = (
  props: ILanguagesModelProps,
) => {
  const { onChange } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [languages, setLanguages] = useState<LanguagesModel[]>(
    [] as LanguagesModel[],
  );
  const [selected, setSelected] = useState<LanguagesModel | null>(null);

  useEffect(() => {
    LanguagesService.getAllLanguages().then((res) => {
      setLanguages(res as unknown as LanguagesModel[]);
      const language: LanguagesModel | null = JSON.parse(
        localStorage.getItem('language') as string,
      );
      if (language === null) {
        localStorage.setItem('language', JSON.stringify((res as any)[0]));
      }
      setSelected(language ?? (res as any)[0]);
    });
  }, []);

  return (
    <div className={styles['dropdown']}>
      <div
        role={'button'}
        tabIndex={-1}
        className={styles['dropdown-btn']}
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => null}
      >
        <img
          src={selected?.iconUrl}
          alt={selected?.name}
          className={styles['dropdown-img']}
        />
        <div className={styles['text-dropdown']}>{selected && selected.name}</div>
        <BrassIcon icon={faSortDown} />
      </div>
      {isActive && (
        <div className={styles['dropdown-content']}>
          {languages.map((item) => (
            <div
              className={styles['dropdown-item']}
              tabIndex={-1}
              role={'button'}
              onClick={() => {
                setSelected(item);
                setIsActive(false);
                localStorage.setItem('language', JSON.stringify(item));
                if (onChange) {
                  onChange(item);
                }
              }}
              key={item.name}
              onKeyDown={() => null}
            >
              <img
                src={item.iconUrl}
                alt={item.name}
                className={styles['dropdown-img']}
              />{' '}
              <div className={styles['text-dropdown']}>{item.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { BrassDropDownLanguage };
export type { LanguagesModel };
