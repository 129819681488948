import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    USER_FILTER_ALL: 'Todos los Usuarios',
    USER_FILTER_ACTIVE: 'Usuarios Activos',
    USER_FILTER_INACTIVE: 'Usuarios Inactivos',
  },
  enUS: {
    USER_FILTER_ALL: 'All Users',
    USER_FILTER_ACTIVE: 'Active Users',
    USER_FILTER_INACTIVE: 'Inactive Users',
  },
  ptBR: {
    USER_FILTER_ALL: 'Todos os Usuários',
    USER_FILTER_ACTIVE: 'Usuários Ativos',
    USER_FILTER_CREATED: 'Usuários Criados',
    USER_FILTER_SUSPENDED: 'Usuários Suspensos',
    USER_FILTER_BLOCKED: 'Usuários Bloqueados',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
