import React, { FunctionComponent } from 'react';
// import classnames from 'classnames';
import { Tooltip, TooltipProps } from '@progress/kendo-react-tooltip';

interface BrassTooltipProps extends TooltipProps {}

const BrassTooltip: FunctionComponent<BrassTooltipProps> = (
  props: BrassTooltipProps,
) => {
  const { className } = props;
  // const _className = classnames('brass-tooltip', className);

  // return (
  //   <Tooltip {...props} className={_className}>
  //     {props.children}
  //   </Tooltip>
  // );

  return props.children;
};

export { BrassTooltip };
export type { BrassTooltipProps };
