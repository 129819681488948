enum Endpoints {
  ACADEMY = 'ACADEMY',
  AUTH = 'AUTH',
  CONNECT = 'CONNECT',
  CONNECT_REPORT = 'CONNECT_REPORT',
  CORE = 'CORE',
  DRIVE = 'DRIVE',
  NAVIGATOR = 'NAVIGATOR',
  SURVEY = 'SURVEY',
  INTEGRATOR = 'INTEGRATOR',
}

type EndpointsType =
  | Endpoints.ACADEMY
  | Endpoints.AUTH
  | Endpoints.CONNECT
  | Endpoints.CONNECT_REPORT
  | Endpoints.CORE
  | Endpoints.DRIVE
  | Endpoints.NAVIGATOR
  | Endpoints.SURVEY
  | Endpoints.INTEGRATOR;

export { Endpoints };
export type { EndpointsType };
