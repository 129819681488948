import React from 'react';
import { BrassIconLayerGroup, BrassIconUser } from '../../components/shared';
import { AppRoute, II18n, ConnectPermissions } from '../../models';
import { I18nService } from '../../services';
import { UserGroupGrid, UserProfile, UsersGrid } from './pages';
import { UsersGridV3 } from './pages/users/users-grid/v3/UsersGrid.V3';

const translations: II18n = {
  es: {
    users: 'Usuarios',
    userGroup: 'Grupo de usuarios',
  },
  enUS: {
    users: 'Users',
    userGroup: 'User Group',
  },
  ptBR: {
    users: 'Usuários',
    userGroup: 'Grupo de Usuários',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const getUserRoutes = (): AppRoute[] => [
  /*
  new AppRoute({
    path: '/home/connect/users',
    component: <UsersGridV3 />,
    text: translate('users'),
    permission: ConnectPermissions.CONNECT_USERGROUP_CREATE,
  }),*/
  new AppRoute({
    path: '/home/connect/user-group',
    component: <UserGroupGrid />,
    text: translate('userGroup'),
    permission: ConnectPermissions.CONNECT_USERGROUP_READ,
  }),
  new AppRoute({
    path: '/profile',
    component: <UserProfile />,
  }),
];

export { getUserRoutes };
