import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialFormState as initialState, IFormState } from './state';

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setInitialValues: (state: IFormState, action: PayloadAction<any>) => {
      state.initialValues = action.payload;
    },
    clearInitialValues: (state: IFormState) => {
      state.initialValues = initialState.initialValues;
    },
    setStepInitialValues: (state: IFormState, action: PayloadAction<any>) => {
      state.stepInitialValues = initialState.stepInitialValues.concat(
        action.payload,
      );
    },
    transformInitialValues: (state: IFormState) => {
      const initialValues = { ...state.initialValues };
      state.stepInitialValues.forEach((values: any) => {
        Object.assign(initialValues, values);
      });
      state.initialValues = initialValues;
    },
    clearStepInitialValues: (state: IFormState) => {
      state.stepInitialValues = initialState.stepInitialValues;
    },
    setDataFetched: (state: IFormState) => {
      state.dataFetched += 1;
    },
    clearDataFetched: (state: IFormState) => {
      state.dataFetched = initialState.dataFetched;
    },
    setSubmissionStatus: (
      state: IFormState,
      action: PayloadAction<{ allowSubmit?: boolean; formLoading?: boolean }>,
    ) => {
      state = {
        ...state,
        ...action.payload,
      };
    },
  },
});

const formReducer = formSlice.reducer;
const formActions = formSlice.actions;

export { formSlice, formReducer, formActions };
