import React, { FunctionComponent, ReactNode } from 'react';
import { FormCallbacks, FormItem, FormState, GridItem } from '../../../../models';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassButtonSharedProps,
  BrassDivFlex,
  BrassError,
  BrassFormRenderProps,
  BrassWindowActionsBar,
} from '../../..';

interface BrassFormButtonsV2Props {
  formState: FormState<GridItem | FormItem>;
  formRenderProps: BrassFormRenderProps;
  formCallbacks: FormCallbacks<GridItem | FormItem>;
  onCancel?: () => void;
  onSave?: () => void;
  buttonSaveProps?: BrassButtonSharedProps;
  buttonCancelProps?: BrassButtonSharedProps;
  hideButtonSave?: boolean;
  hideButtonCancel?: boolean;
  additionalButtons?: ReactNode[];
  additionalButtonsBefore?: ReactNode[];
  additionalButtonsAfter?: ReactNode[];
}

const BrassFormButtonsV2: FunctionComponent<BrassFormButtonsV2Props> = (
  props: BrassFormButtonsV2Props,
) => {
  const {
    formCallbacks,
    formState,
    formRenderProps,
    onCancel,
    onSave,
    buttonSaveProps,
    buttonCancelProps,
    hideButtonSave,
    hideButtonCancel,
    additionalButtons,
    additionalButtonsBefore,
    additionalButtonsAfter,
  } = props;
  return (
    <BrassWindowActionsBar>
      {additionalButtonsBefore}
      <BrassDivFlex />
      {formState.errorMessage && <BrassError>{formState.errorMessage}</BrassError>}
      {!hideButtonSave && (
        <BrassButtonSave
          autoFocus
          type='submit'
          onClick={() => {
            if (onSave) {
              onSave();
            }
          }}
          disabled={formCallbacks.onCheckButtonSaveState(formRenderProps)}
          showLoader={formState.allowSubmit}
          hideIconWhenLoader
          {...buttonSaveProps}
        />
      )}
      {additionalButtons}
      {!hideButtonCancel && (
        <BrassButtonCancel onClick={onCancel} {...buttonCancelProps} />
      )}
      {additionalButtonsAfter}
    </BrassWindowActionsBar>
  );
};

BrassFormButtonsV2.defaultProps = {
  hideButtonSave: false,
  hideButtonCancel: false,
};

export type { BrassFormButtonsV2Props };
export { BrassFormButtonsV2 };
