import React, { FunctionComponent, memo, PropsWithChildren } from 'react';
import style from './StatisticsCard.module.scss';
import { BrassIconProps } from '../../../../components';
import classNames from 'classnames';

interface StatisticsCardProps extends PropsWithChildren<any> {
  Icon: FunctionComponent<BrassIconProps>;
  description: string;
  data: string | number;
  className_card?: string;
  className_icon?: string;
  className_data?: string;
  className_description?: string;
}

const StatisticsCard: FunctionComponent<StatisticsCardProps> = memo(
  (props: StatisticsCardProps) => {
    const {
      Icon,
      data,
      description,
      className_card,
      className_icon,
      className_data,
      className_description,
    } = props;

    return (
      <div className={classNames([style.container], className_card)}>
        <Icon size='3x' className={classNames([style.icon], className_icon)} />
        <span className={classNames([style.data], className_data)}>{data}</span>
        <p className={classNames([style.description], className_description)}>
          {description}
        </p>
      </div>
    );
  },
);

export type { StatisticsCardProps };
export { StatisticsCard };
