import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const BrassButtonView: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-view'
    labelKey='view'
    tooltipKey='clickToView'
    translations={translations}
    icon={<BrassIcon icon={faEye} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    view: 'Ver',
    clickToView: 'haga clic para ver',
  },
  enUS: {
    view: 'View',
    clickToView: 'Click to view',
  },
  ptBR: {
    view: 'Visualizar',
    clickToView: 'Clique para visualizar',
  },
};

export { BrassButtonView };
