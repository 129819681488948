import React, { FunctionComponent } from 'react';
import { BrassTextFieldProps, BrassTextFieldIcon } from '../../../../brass';

const TraductionTextField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <BrassTextFieldIcon
    showIcon={false}
    name='name'
    label={'Nome do Idioma de Cadastro PT-BR'}
    {...props}
  />
);

export { TraductionTextField };
