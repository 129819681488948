import React, { FunctionComponent, Fragment, memo, useState } from 'react';
import { translate } from './UserGroupGrid.translations';
import style from './UserGroupGrid.module.scss';
import { UserGroupService } from '../../../services';
import { UserGroupData } from '../../../models';
import { UpsertUserGroupWindow } from '../upsert-user-group-window';
import { DeleteGroupWindow } from '../delete-group-window';
import { getColumns, performActions } from './UserGridGroup.functions';
import { BrassTreeListGridV3, BrassIconLayerGroup } from '../../../../../components';
import { AuthUser, ConnectPermissions, GridActions } from '../../../../../models';
import { useGrid } from '../../../../../hooks';
import { useDispatch } from 'react-redux';

const UserGroupGrid: FunctionComponent = memo(() => {
  const dispatch = useDispatch;
  const { gridState, gridDispatch, gridCallbacks } = useGrid<UserGroupData>({
    getRecords: UserGroupService.getGroupUsersList,
    deleteRecord: UserGroupService.deleteGroup,
    translate,
  });

  const [groupId, setGroupId] = useState<string>('');
  const [editGroup, setEditGroup] = useState<string | undefined>(undefined);
  const [deleteGroup, setDeleteGroup] = useState<string | undefined>(undefined);
  const columns = getColumns(gridState.gridConfig, setEditGroup, setDeleteGroup);

  return (
    <Fragment>
      <BrassTreeListGridV3
        permissions={{
          read: AuthUser.checkPermission(ConnectPermissions.CONNECT_USERGROUP_READ),
          create: AuthUser.checkPermission(
            ConnectPermissions.CONNECT_USERGROUP_CREATE,
          ),
        }}
        className={style.window}
        gridState={gridState}
        gridDispatch={gridDispatch}
        gridCallbacks={gridCallbacks}
        gridColumns={columns}
        icon={<BrassIconLayerGroup />}
        translate={translate}
        groupable={{
          enabled: true,
          footer: 'none',
        }}
      />
      {editGroup && (
        <UpsertUserGroupWindow
          userGroupId={editGroup}
          onCancelClose={() => setEditGroup(undefined)}
          onSaveClose={() => {
            UserGroupService.updateUserGroup, setEditGroup(undefined);
            gridCallbacks.onRefresh();
          }}
        />
      )}
      {deleteGroup && (
        <DeleteGroupWindow
          notificationUserId={groupId}
          onCancelClose={() => setDeleteGroup(undefined)}
          onConfirm={() => {
            performActions(
              UserGroupService.deleteGroup,
              deleteGroup,
              'groupDeleteSuccess',
              'groupDeleteError',
              setDeleteGroup,
              dispatch,
              gridCallbacks,
            );
            gridCallbacks.onRefresh();
          }}
        />
      )}
      {gridState.recordId && (
        <UpsertUserGroupWindow
          userGroupId={gridState.recordId}
          onCancelClose={() =>
            gridDispatch({
              type: GridActions.UPDATE_RECORD_ID,
              payload: undefined,
            })
          }
          onSaveClose={() => {
            gridDispatch({
              type: GridActions.UPDATE_RECORD_ID,
              payload: undefined,
            });
            gridCallbacks.onRefresh();
          }}
        />
      )}
    </Fragment>
  );
});

export { UserGroupGrid };
