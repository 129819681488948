import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BrassTreeListGridPropsV2 } from './TreeListGridProps.v2.interface';
import {
  deleteColumn,
  editColumn,
  getGridBrassLoader,
  getWindowWidth,
  handleDataStateChange,
  onFilterChange,
  onHeaderSelectionChange,
  onPageChange,
  onSelectionChange,
  onSortChange,
  onTreeListExpandChange,
  TreeListPager,
} from './TreeListGrid.v2.functions';
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  TreeList,
  TreeListColumnProps,
  TreeListDataStateChangeEvent,
  TreeListExpandChangeEvent,
  TreeListFilterChangeEvent,
  TreeListPageChangeEvent,
  TreeListToolbar,
} from '@progress/kendo-react-treelist';
import {
  BrassButtonFilter,
  BrassButtonAdd,
  BrassButtonRefresh,
  MainLayoutContainerContext,
} from '../../../..';
import style from './TreeListGrid.v2.module.scss';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import { GridActions } from '../../../../../models';
import { FilterDescriptor } from '@progress/kendo-data-query';

const USER_HELP_PANEL_SIZE = 400;

const renderTreeListV2 = (props: BrassTreeListGridPropsV2): ReactNode => {
  const {
    excelExportColumns,
    gridState,
    gridCallbacks,
    permissions,
    customFilterGridToolbar,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarBeforeActions,
    toolbarAfterActions,
    translate,
    treeListColumns,
    selectable,
  } = props;
  const subItemsField: string = 'children'; // CHANGE
  const expandField: string = 'expanded';
  const anchorRef = useRef<any>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

  const preColumns = [...treeListColumns!];

  const gridExcelExport = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
      gridExcelExport.current.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null && gridPDFExport !== undefined) {
      gridPDFExport.save();
    }
  };

  editColumn(props, preColumns);
  deleteColumn(props, preColumns);

  let _columns: TreeListColumnProps[] = preColumns.map(
    (col) => col as TreeListColumnProps,
  );
  if (!permissions!.update) {
    _columns = _columns.filter((col: TreeListColumnProps) => col.field !== 'edit');
  }
  if (!permissions!.delete) {
    _columns = _columns.filter((col: TreeListColumnProps) => col.field !== 'delete');
  }

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  let offsetTop = 0;
  let offsetLeft = 0;
  let offsetHeight = 0;
  let offsetWidth = 0;
  if (anchorRef.current && anchorRef.current.element) {
    offsetTop = anchorRef.current.element.offsetTop || 0;
    offsetLeft = anchorRef.current.element.offsetLeft || 0;

    offsetWidth = anchorRef.current.element.offsetWidth || 0;
    offsetHeight = anchorRef.current.element.offsetHeight || 0;
  }

  const { menuExpanded } = useContext(MainLayoutContainerContext);
  const [windowWidth, setwindowWidth] = useState<string>(
    getWindowWidth(menuExpanded),
  );
  useEffect(() => {
    const recalculateWindowWidth = () =>
      setwindowWidth(getWindowWidth(menuExpanded));
    setwindowWidth(getWindowWidth(menuExpanded));
    window.addEventListener('resize', recalculateWindowWidth, true);

    return () => window.removeEventListener('resize', recalculateWindowWidth);
  }, [menuExpanded, window.innerWidth]);

  return (
    <Fragment>
      {gridState.gridLoading && getGridBrassLoader(anchorRef)}
      <TreeList
        ref={anchorRef}
        dataItemKey='id'
        className={style['brass-tree-list']}
        style={{
          width: gridState.showHelpPanel
            ? `${Number(windowWidth.split('px')[0]) - USER_HELP_PANEL_SIZE}px`
            : windowWidth,
        }}
        // style={{overflow: 'auto', maxHeight: '80vh' }}
        expandField={expandField}
        subItemsField={subItemsField} // CHANGE
        resizable
        pager={TreeListPager}
        skip={gridState.gridConfig.skip}
        take={gridState.gridConfig.take}
        data={gridState.recordsList.records}
        columns={treeListColumns as TreeListColumnProps[]}
        onDataStateChange={(event: TreeListDataStateChangeEvent) =>
          handleDataStateChange(event, props)
        }
        onExpandChange={(event: TreeListExpandChangeEvent) =>
          onTreeListExpandChange(event, props)
        }
        sortable={props.sortable ? { mode: 'multiple' } : {}}
        sort={gridState.gridConfig.sort!}
        onSortChange={(event: any) => onSortChange(event, props)}
        filter={gridState.treeListFilter as FilterDescriptor[]}
        onFilterChange={(event: TreeListFilterChangeEvent) =>
          onFilterChange(event, props)
        }
        onPageChange={(event: TreeListPageChangeEvent) => onPageChange(event, props)}
        selectable={selectable}
        selectedField='selected'
        onSelectionChange={(event: any) => onSelectionChange(event, props)}
        onHeaderSelectionChange={(event: any) =>
          onHeaderSelectionChange(event, props)
        }
        toolbar={
          <TreeListToolbar>
            {toolbarBeforeTitle}
            <h2 className={style['brass-grid-title']}>
              {props.icon}
              {translate('gridTitle')}
            </h2>
            {toolbarAfterTitle}
            <ToolbarSpacer />
            {toolbarBeforeActions}
            {permissions?.create && (
              <div className={style['brass-button-add']}>
                <BrassButtonAdd
                  fillMode='outline'
                  className={style['brass-button-insert']}
                  onClick={gridCallbacks.onAdd as any}
                  disabled={gridState.gridLoading}
                />
              </div>
            )}
            <BrassButtonRefresh
              fillMode='outline'
              className={style['brass-button-refresh']}
              onClick={() => {
                props.gridDispatch({
                  type: GridActions.UPDATE_GRID_LOADING,
                  payload: true,
                });
                gridCallbacks.onRefresh();
              }}
              disabled={gridState.gridLoading}
            />
            {permissions?.export && (
              <DropDownButton
                icon='printer'
                items={['PDF', 'Excel']}
                onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
                  itemClickEvent.itemIndex === 0 ? exportPDF() : excelExport()
                }
                disabled={gridState.gridLoading}
              />
            )}
            {customFilterGridToolbar && (
              <BrassButtonFilter
                className={style['brass-button-filter']}
                key='buttonFilter'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              />
            )}
            {toolbarAfterActions}
          </TreeListToolbar>
        }
      />
      {showFilterPanel && customFilterGridToolbar && (
        <TreeListToolbar>{customFilterGridToolbar}</TreeListToolbar>
      )}
      <div style={{ maxHeight: 0 }}>
        <ExcelExport
          ref={gridExcelExport}
          data={gridState.recordsList.records}
          fileName={`${translate('gridTitle')} - ${new Date().toDateString()}`}
        >
          <TreeList data={gridState.recordsList.records} style={{ display: 'none' }}>
            {excelExportColumns || treeListColumns}
          </TreeList>
        </ExcelExport>
        <PDFExport
          // @ts-ignore
          ref={(pdfExport: PDFExport) => (gridPDFExport = pdfExport)}
          fileName={`${translate('gridTitle')} - ${new Date().toDateString()}`}
          paperSize='A4'
          margin='1cm'
        >
          <TreeList data={gridState.recordsList.records} style={{ display: 'none' }}>
            {treeListColumns}
          </TreeList>
        </PDFExport>
      </div>
    </Fragment>
  );
};

export { renderTreeListV2 };
