import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconOnlineTutoring: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.0908 9.54551C13.1817 9.60612 13.3029 9.57579 13.4242 9.51524L15.0302 8.27279C15.0908 8.21218 15.1514 8.12129 15.1514 8.03034C15.1514 7.93945 15.1212 7.84851 15.0302 7.78789L13.4242 6.54544C13.3333 6.48483 13.212 6.45455 13.0908 6.51517C12.9999 6.57578 12.909 6.66666 12.909 6.78788V9.24238C12.9091 9.39395 12.9999 9.48483 13.0908 9.54545V9.54551ZM13.5151 7.42435L14.3333 8.0304L13.5151 8.63645V7.42435Z'
      fill='white'
    />
    <path
      d='M16.5758 5.63634H11.1515C10.9697 5.63634 10.8484 5.75756 10.8484 5.9394V10.1516C10.8484 10.3334 10.9697 10.4546 11.1515 10.4546H16.5757C16.7575 10.4546 16.8787 10.3334 16.8787 10.1516V5.9394C16.8787 5.75757 16.7575 5.6364 16.5757 5.6364L16.5758 5.63634ZM16.2728 9.8485H11.4546V6.2425H16.2728V9.8485Z'
      fill='white'
    />
    <path
      d='M6.66668 6.06067C5.63641 6.06067 4.81818 6.87884 4.81818 7.90917C4.81818 8.93944 5.63634 9.75767 6.66668 9.75767C7.69694 9.75767 8.51518 8.9395 8.51518 7.90917C8.51518 6.87884 7.69694 6.06067 6.66668 6.06067ZM6.66668 9.1515C5.96974 9.1515 5.42423 8.60605 5.42423 7.90905C5.42423 7.21212 6.00001 6.66667 6.66668 6.66667C7.33334 6.66667 7.90913 7.21212 7.90913 7.90912C7.90913 8.60605 7.36363 9.1515 6.66668 9.1515Z'
      fill='white'
    />
    <path
      d='M19.3933 0.787888H0.605008C0.423174 0.787888 0.301941 0.909111 0.301941 1.09095V15.485C0.301941 15.6668 0.423164 15.788 0.605008 15.788H8.30201V17.2729H6.72622C6.21106 17.2729 5.78684 17.6971 5.78684 18.2122C5.78684 18.7274 6.21106 19.1516 6.72622 19.1516H13.2717C13.7869 19.1516 14.2111 18.7274 14.2111 18.2122C14.2111 17.6971 13.7869 17.2729 13.2717 17.2729H11.6959V15.788L19.3929 15.788C19.5748 15.788 19.696 15.6667 19.696 15.4849L19.6959 1.09089C19.6959 0.939393 19.5748 0.787889 19.3929 0.787889L19.3933 0.787888ZM13.6055 18.2429C13.6055 18.4247 13.454 18.5762 13.2722 18.5762H6.72667C6.54484 18.5762 6.39334 18.4247 6.39334 18.2429C6.39334 18.0611 6.54484 17.9096 6.72667 17.9096H13.2722C13.454 17.9096 13.6055 18.0611 13.6055 18.2429ZM11.0903 17.3035H8.90851V15.8187H11.0903V17.3035ZM19.0903 15.2125H0.908674V1.39434H19.0903V15.2125Z'
      fill='white'
    />
    <path
      d='M2.30303 14.1212H17.697C17.8789 14.1212 18.0001 14 18.0001 13.8181L18 2.78778C18 2.60594 17.8788 2.48471 17.697 2.48471H2.30313C2.12129 2.48471 2.00006 2.60593 2.00006 2.78778V13.7878C2.00006 13.9696 2.12128 14.1211 2.30313 14.1211L2.30303 14.1212ZM8.45453 13.5151V12.0606C8.45453 11.8787 8.3333 11.7575 8.15146 11.7575C7.96962 11.7575 7.84839 11.8787 7.84839 12.0606V13.5151H5.51506V12.0606C5.51506 11.8787 5.39384 11.7575 5.21199 11.7575C5.03015 11.7575 4.90893 11.8787 4.90893 12.0606V13.5151H3.54533V11.8485C3.54533 11.1818 4.09078 10.6363 4.75744 10.6363H5.27261C5.99989 11.4848 7.30294 11.4848 8.06044 10.6363H8.57561C9.24228 10.6363 9.78773 11.1818 9.78773 11.8485V13.5151H8.45453ZM10.4242 13.5151V11.8485C10.4242 10.8485 9.60603 10.0303 8.60603 10.0303H7.93936C7.84848 10.0303 7.75753 10.0909 7.69691 10.1515C7.21208 10.8182 6.18174 10.8182 5.69691 10.1515C5.6363 10.0606 5.54541 10.0303 5.45446 10.0303H4.78779C3.78779 10.0303 2.96963 10.8485 2.96963 11.8485V13.5151H2.60603V4.90913H17.3939V13.5151H10.4242ZM17.3939 3.09079V4.30291L2.60603 4.30298V3.09086L17.3939 3.09079Z'
      fill='white'
    />
    <path
      d='M3.78784 3.90918C3.90906 3.78795 3.90906 3.60611 3.78784 3.48496C3.66662 3.36374 3.48477 3.36374 3.36362 3.48496C3.2424 3.60618 3.2424 3.78803 3.36362 3.90918C3.48478 4.03034 3.69689 4.03034 3.78784 3.90918Z'
      fill='white'
    />
    <path
      d='M5.09082 3.90918C5.21204 3.78795 5.21204 3.60611 5.09082 3.48496C4.96959 3.36374 4.78775 3.36374 4.6666 3.48496C4.54538 3.60618 4.54538 3.78803 4.6666 3.90918C4.78782 4.03033 4.96967 4.03034 5.09082 3.90918Z'
      fill='white'
    />
    <path
      d='M6.36364 3.90918C6.48487 3.78795 6.48487 3.60611 6.36364 3.48496C6.24242 3.36374 6.06058 3.36374 5.93943 3.48496C5.8182 3.60618 5.8182 3.78803 5.93943 3.90918C6.06065 4.03034 6.24249 4.03034 6.36364 3.90918Z'
      fill='white'
    />
  </svg>
);

export { SVGIconOnlineTutoring };
