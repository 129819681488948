import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import {
  AcademyModulePermission,
  AcademyFeaturesPermission,
} from './Academy.submodule.enum';

enum AcademyPermissionsV1 {
  ACADEMY_MODULE = PermissionModule.ACADEMY,

  /// <summary>
  /// Área
  /// </summary>
  ACADEMY_AREA_READ = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.READ,
  ACADEMY_AREA_CREATE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.CREATE,
  ACADEMY_AREA_UPDATE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.UPDATE,
  ACADEMY_AREA_DELETE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.DELETE,
  ACADEMY_AREA_KEY_USER = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.KEY_USER,

  /// <summary>
  /// Content
  /// </summary>
  ACADEMY_CONTENT_READ = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.READ,
  ACADEMY_CONTENT_CREATE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.CREATE,
  ACADEMY_CONTENT_UPDATE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.UPDATE,
  ACADEMY_CONTENT_DELETE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.DELETE,
  ACADEMY_CONTENT_UPLOAD = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.UPLOAD,
  ACADEMY_CONTENT_PUBLISH = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.PUBLISH,
  ACADEMY_CONTENT_SUSPEND = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.SUSPEND,
  ACADEMY_CONTENT_ACTIVE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.ACTIVE,
  ACADEMY_CONTENT_BLOCK = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_CONTENT +
    AcademyFeaturesPermission.MANAGEMENT_CONTENT +
    Action.BLOCK,

  /// <summary>
  /// Painel do usuário - Visualização de conteúdos
  /// </summary>
  ACADEMY_USER_PANEL_VISUALIZATION = PermissionModule.ACADEMY +
    AcademyModulePermission.USER_PAINEL +
    AcademyFeaturesPermission.USER_PANEL_VISUALIZATION +
    Action.READ,

  /// <summary>
  /// Trilhas - Gestão das trilhas
  /// </summary>

  ACADEMY_TRAIL_READ = PermissionModule.ACADEMY +
    AcademyModulePermission.TRAIL_MANAGEMENT +
    AcademyFeaturesPermission.TRAIL_MANAGEMENT +
    Action.READ,
  ACADEMY_TRAIL_CREATE = PermissionModule.ACADEMY +
    AcademyModulePermission.TRAIL_MANAGEMENT +
    AcademyFeaturesPermission.TRAIL_MANAGEMENT +
    Action.CREATE,
  ACADEMY_TRAIL_UPDATE = PermissionModule.ACADEMY +
    AcademyModulePermission.TRAIL_MANAGEMENT +
    AcademyFeaturesPermission.TRAIL_MANAGEMENT +
    Action.UPDATE,
  ACADEMY_TRAIL_DELETE = PermissionModule.ACADEMY +
    AcademyModulePermission.TRAIL_MANAGEMENT +
    AcademyFeaturesPermission.TRAIL_MANAGEMENT +
    Action.DELETE,

  /// <summary>
  /// Conexões - Gestão das conexões
  /// </summary>

  ACADEMY_CONNECTION_READ = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.READ,
  ACADEMY_CONNECTION_CREATE = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.CREATE,
  ACADEMY_CONNECTION_UPDATE = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.UPDATE,
  ACADEMY_CONNECTION_DELETE = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.DELETE,
  ACADEMY_CONNECTION_LINK = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.LINK,
  ACADEMY_CONNECTION_FREEZE = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.FREEZE,
  ACADEMY_CONNECTION_UNFREEZE = PermissionModule.ACADEMY +
    AcademyModulePermission.CONNECTION_MANAGEMENT +
    AcademyFeaturesPermission.CONNECTION_MANAGEMENT +
    Action.UNFREEZE,

  /// <summary>
  /// Gestão de vínculos de conteúdos
  /// </summary>
  ACADEMY_MANAGEMENT_OF_CONTENT_LINKS = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_OF_CONTENT_LINKS +
    AcademyFeaturesPermission.MANAGEMENT_OF_CONTENT_LINKS +
    Action.READ,
  ACADEMY_MANAGEMENT_OF_CONTENT_LINKS_LINK = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_OF_CONTENT_LINKS +
    AcademyFeaturesPermission.MANAGEMENT_OF_CONTENT_LINKS +
    Action.LINK,
  ACADEMY_MANAGEMENT_OF_CONTENT_LINKS_FREEZE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_OF_CONTENT_LINKS +
    AcademyFeaturesPermission.MANAGEMENT_OF_CONTENT_LINKS +
    Action.FREEZE,
  ACADEMY_MANAGEMENT_OF_CONTENT_LINKS_UNFREEZE = PermissionModule.ACADEMY +
    AcademyModulePermission.MANAGEMENT_OF_CONTENT_LINKS +
    AcademyFeaturesPermission.MANAGEMENT_OF_CONTENT_LINKS +
    Action.UNFREEZE,
}

export { AcademyPermissionsV1 };
