import React, { FunctionComponent, memo } from 'react';
import { translateProjectStatus } from './ProjectStatusCell.translations';
import {
  BrassChip,
  BrassGridCell,
  BrassGridCellProps,
} from '../../../../components';
import { ProjectStatus } from '../../models/enum/ProjectStatus.enum';

interface ProjectStatusCellProps extends BrassGridCellProps {
  status: ProjectStatus;
}

const ProjectStatusCell: FunctionComponent<ProjectStatusCellProps> = memo(
  (props: ProjectStatusCellProps) => {
    const { status } = props;
    let statusType: null | 'base' | 'info' | 'success' | 'warning' | 'error' = null;

    switch (status) {
      case ProjectStatus.STOPPED:
      case ProjectStatus.CANCELLED:
        statusType = 'warning';
        break;

      case ProjectStatus.COMPLETED:
        statusType = 'info';
        break;

      case ProjectStatus.IN_PROGRESS:
      case ProjectStatus.ACTIVE:
        statusType = 'success';
        break;

      case ProjectStatus.INACTIVE:
        statusType = null;
        break;

      default:
        statusType = null;
        break;
    }

    return (
      <BrassGridCell {...props}>
        <BrassChip
          text={translateProjectStatus(status)}
          themeColor={statusType}
          style={{ width: 150, border: 'solid 1px' }}
        />
      </BrassGridCell>
    );
  },
);

export { ProjectStatusCell };
