import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    averageWorkedHours: 'Media horas trabajadas por dia',
    disciplinesNumber: 'Número de Sujetos',
    averageMonthlyHours: 'Média de horas mensais',
    averageDailyHours: 'Media horas trabajadas por dia',
    totalWorkedDays: 'Días trabajados',
    totalWorkedHours: 'Total de horas trabajadas',
    chartTitle: 'Horas trabajadas',
    titleCategory: 'Dia',
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ].toString(),
  },
  enUS: {
    averageWorkedHours: 'Worked Days',
    disciplinesNumber: 'Number of Subjects',
    averageMonthlyHours: 'Average Monthly Hours',
    averageDailyHours: 'Average hours worked per day',
    totalWorkedDays: 'Worked Days',
    totalWorkedHours: 'Total hours worked',
    chartTitle: 'Hours Worked',
    titleCategory: 'Day',
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mar',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ].toString(),
  },
  ptBR: {
    averageWorkedHours: 'Média horas trabalhadas',
    disciplinesNumber: 'Numero de disciplinas',
    averageMonthlyHours: 'Média de horas mensais',
    averageDailyHours: 'Média horas trabalhadas por dia',
    totalWorkedDays: 'Dias Trabalhados',
    totalWorkedHours: 'Total de horas trabalhadas',
    chartTitle: 'Horas Trabalhadas',
    titleCategory: 'Dia',
    months: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ].toString(),
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
