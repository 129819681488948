import React, { Fragment, FunctionComponent } from 'react';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuProps,
} from '@progress/kendo-react-grid';
// import style from './GridColumnMenu.scss';

interface BrassGridColumnMenuProps extends GridColumnMenuProps {}

const BrassGridColumnMenu: FunctionComponent<BrassGridColumnMenuProps> = (
  props: GridColumnMenuProps,
) => (
  <Fragment>
    <GridColumnMenuSort {...props} />
    <GridColumnMenuFilter {...props} />
  </Fragment>
);

export type { BrassGridColumnMenuProps };
export { BrassGridColumnMenu };
