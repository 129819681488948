import React, { FunctionComponent, useContext, memo } from 'react';
import { ViewerContext, SystemTypeEnum, UpsertSystem } from '../../../../../../../';

const AddSystemDialog: FunctionComponent = memo(() => {
  const {
    navigatorFile: { projectId, systemId, areaId },

    loadData,
    showHideAddSystemDialog,
  } = useContext(ViewerContext);

  return (
    <UpsertSystem
      areaId={areaId}
      projectId={projectId}
      parentSystemId={systemId}
      type={SystemTypeEnum.PHYSICAL}
      onCancelClose={() => showHideAddSystemDialog()}
      onSaveClose={() => {
        showHideAddSystemDialog();
        loadData();
      }}
    />
  );
});

export { AddSystemDialog };
