import React, { FunctionComponent } from 'react';
import { translate } from './ReplyField.translations';
import {
  TextAreaField,
  TextAreaFieldProps,
} from '../../../../../../../../components';

const ReplyField: FunctionComponent<TextAreaFieldProps> = (
  props: TextAreaFieldProps,
) => <TextAreaField {...props} name='reply' label={translate('reply')} />;

export { ReplyField };
