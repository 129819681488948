import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  BrassFormRenderProps,
  BrassLoader,
  BrassWindowActionsBar,
  getLanguagesStorage,
} from '../../../brass';
import { BrassButtonSaveV1 } from '../../../shared';
import {
  DropDownData,
  Endpoints,
  Notification,
  NotificationStyles,
} from '../../../../models';
import { TraductionTextField } from './tradutions-fields/TraductionTextField';
import style from './FieldCheckerForm.module.scss';
import { TranslationCheckerService } from '../TranslationChecker.service';
import { useDispatch } from 'react-redux';
import { translate } from './FieldCheckerForm.translations';
import { notificationActions } from '../../../../store';
import {
  DynamicDispatchAlert,
  TranslateProps,
} from '../../../../util/DynamicDispatchAlert';
import { EditorTextField } from './editor-field/EditorTextField';
import classNames from 'classnames';

interface FieldCheckerFormProps {
  isUpdating: boolean;
  id: string;
  route: string;
  routeSave: string;
  title?: string;
  onSaveClose: () => void;
  onCancelClose: () => void;
  fieldsTranslate: string[];
  translateCustom: TranslateProps;
  endpoints?: Endpoints;
  hasRouterId?: boolean;
}

export interface ILanguageProps {
  id: string;
  fields: ILanguageFieldsProps[];
}

export interface ILanguageFieldsProps {
  key: string;
  name: string;
  translations: ILanguageTranslationsProps[];
}

export interface ILanguageTranslationsProps {
  language: DropDownData<any>;
  value: string;
  isTranslatedManually: boolean;
  isDisabled: boolean;
  isEditor: boolean;
  name?: string;
  key?: string;
}

const FieldCheckerForm: FunctionComponent<FieldCheckerFormProps> = (props: any) => {
  const {
    allowSubmit,
    initialFormValues,
    messageError,
    fieldsTranslate,
    onSaveClose,
    route,
    routeSave,
    id,
    onCancelClose,
    endpoints,
    hasRouterId,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = (_values: any): void => {
    setIsLoading(true);
    const payloadLanguages = {
      id,
      fields: Object.keys(values).map<ILanguageFieldsProps>((a: string) => {
        const convertValues = {
          key: (values as any)[a].key,
          name: (values as any)[a].name.toUpperCase(),
          translations: [(values as any)[a]],
        } as ILanguageFieldsProps;

        return convertValues;
      }),
    } as ILanguageProps;

    payloadLanguages.fields = fieldsTranslate
      .map((item: string) => {
        const find = payloadLanguages.fields.filter(
          (value) => value.name.toLocaleUpperCase() === item.toLocaleUpperCase(),
        );
        if (find.length > 0) {
          const array = find[0];
          /* eslint-disable */
          for (let index = 0; index < find.length; index++) {
            find[0].translations = [
              ...find[0].translations,
              find[index].translations[0],
            ];
          }
          /* eslint-enable */
          return array;
        }

        return {} as ILanguageFieldsProps;
      })
      .filter((item: ILanguageFieldsProps) => Object.keys(item).length > 0);

    new TranslationCheckerService(endpoints ?? Endpoints.DRIVE)
      .updateTranslate(routeSave, {
        language: getLanguagesStorage()?.language,
        ...payloadLanguages,
      })
      .then((res) => {
        if (res.success) {
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.SUCCESS,
                content: translate('successOnAdd'),
              }),
            ),
          );
          if (onSaveClose) {
            onSaveClose();
          }
        } else {
          DynamicDispatchAlert(
            dispatch,
            ['errorOnAdd', ...res.message],
            'ERROR',
            translate,
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: translate('errorOnAdd'),
            }),
          ),
        );
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [fields, setFields] = useState<ILanguageFieldsProps[]>(
    [] as ILanguageFieldsProps[],
  );
  const [values, setValues] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetTranslate = useCallback(async () => {
    setIsLoading(true);
    if ((!!id && id !== undefined) || hasRouterId) {
      new TranslationCheckerService(endpoints ?? Endpoints.DRIVE)
        .getTranslate(route, id, hasRouterId!)
        .then((res) => {
          if (res.success) {
            setFields(res.data.fields);
            setIsLoading(false);
          } else {
            DynamicDispatchAlert(dispatch, [...res.message], 'ERROR', translate);
            onCancelClose();
          }
        })
        .catch(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    handleGetTranslate();
  }, [handleGetTranslate]);

  const renderForm = (formRenderProps: BrassFormRenderProps): any => {
    let disableButtonSave = allowSubmit;

    if (!formRenderProps.valid || !formRenderProps.allowSubmit) {
      disableButtonSave = true;
    }

    return (
      <BrassFormElement>
        {fields?.length > 0 &&
          fields?.map((item, index) => (
            <>
              <div className={style['row-margin']} key={index}>
                {item.translations?.map((items2, index2: number) => (
                  <div
                    className={classNames({
                      [style['row']]: !items2.isEditor,
                      [style['row-editor']]: items2.isEditor,
                    })}
                    key={item.key + index + index2}
                  >
                    {items2.isEditor ? (
                      <EditorTextField
                        label={
                          index2 === 0
                            ? translate('mainLanguage') + items2?.language?.text
                            : items2.language?.text
                        }
                        editabled={index2 === 0 ? false : true}
                        onChangeValue={(property: string) => {
                          formRenderProps.onChange('property', { value: property });
                          setValues({
                            ...values,
                            [item.key + items2?.language.value]: {
                              isDisabled: false,
                              isEditor: true,
                              isTranslatedManually: true,
                              value: property,
                              language: items2?.language,
                              name: item.name,
                              key: item.key,
                            } as ILanguageTranslationsProps,
                          });
                        }}
                        defaultValue={items2.value}
                      />
                    ) : (
                      <TraductionTextField
                        name={item.key + index + index2}
                        label={
                          index2 === 0
                            ? translate('mainLanguage') + items2?.language?.text
                            : items2.language?.text
                        }
                        placeholder={translate(item.name)}
                        defaultValue={items2.value}
                        disabled={index2 === 0}
                        onChange={(event: any) => {
                          setValues({
                            ...values,
                            [item.key + items2?.language.value]: {
                              isDisabled: false,
                              isTranslatedManually: true,
                              isEditor: false,
                              value: event.target.value,
                              language: items2?.language,
                              name: item.name,
                              key: item.key,
                            } as ILanguageTranslationsProps,
                          });
                        }}
                      />
                    )}
                    <img
                      className={style['image-input']}
                      src={items2?.language?.icon}
                      alt='logo'
                    />
                  </div>
                ))}
              </div>
            </>
          ))}

        <BrassWindowActionsBar>
          <BrassDivFlex />
          {messageError && <BrassError>{messageError}</BrassError>}
          <BrassButtonSaveV1
            autoFocus
            type='submit'
            className={style['action-button']}
            disabled={disableButtonSave || isLoading}
            showLoader={isLoading}
            hideIconWhenLoader
          />
        </BrassWindowActionsBar>
      </BrassFormElement>
    );
  };

  return (
    <div className={style.form}>
      {!isLoading && (
        <BrassForm
          render={renderForm}
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
        />
      )}
      {isLoading && <BrassLoader useLoadingMask />}
    </div>
  );
};

export { FieldCheckerForm };
export type { FieldCheckerFormProps };
