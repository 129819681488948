import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassDropDownButton, BrassDropDownButtonProps } from '../../brass/buttons';

interface BrassDropDownButtonAddProps extends BrassDropDownButtonProps {}

const BrassDropDownButtonAdd: FunctionComponent<BrassDropDownButtonAddProps> = (
  props: BrassDropDownButtonAddProps,
) => (
  <BrassDropDownButton
    themeColor='primary'
    className='brass-dorpdown-button-add'
    icon='add'
    text={translate('add')}
    {...props}
  />
);

const translations: II18n = {
  es: {
    add: 'Agregar',
    clickToNew: 'Haga clic para agregar',
  },
  enUS: {
    add: 'Add',
    clickToNew: 'Click to add',
  },
  ptBR: {
    add: 'Adicionar',
    clickToNew: 'Clique para adicionar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export type { BrassDropDownButtonAddProps };
export { BrassDropDownButtonAdd };
