import React, { FunctionComponent, useContext, memo } from 'react';
import style from './EmptyViewer.module.scss';
import { II18n, I18nService, BrassIcon, ViewerContext } from '../../../../../../';
import { faReplyAll } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

interface EmptyViewerProps {
  className?: any;
}

const EmptyViewer: FunctionComponent<EmptyViewerProps> = memo(
  (props: EmptyViewerProps) => {
    // const { showDetails } = useContext(ViewerContext);
    return (
      <div className={classnames(style['main-document'], props.className)}>
        <div className={style['empty-div-viewer']}>
          <h4>{translate('mainDocument')}</h4>
          <BrassIcon icon={faReplyAll} size='3x' rotation={180} />
        </div>
      </div>
    );
  },
);

const translations: II18n = {
  es: {
    mainDocument: 'Documento principal no disponible',
  },
  enUS: {
    mainDocument: 'Main document unavailable',
  },
  ptBR: {
    mainDocument: 'Documento principal indisponível',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { EmptyViewer };
