import React from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    buttonEditCode: 'Editar Código',
    buttonSaveCode: 'Salvar',
  },
  enUS: {
    buttonEditCode: 'Edit Code',
    buttonSaveCode: 'Save',
  },
  ptBR: {
    buttonEditCode: 'Editar Código',
    buttonSaveCode: 'Salvar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
