import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { ProgressBar, ProgressBarProps } from '@progress/kendo-react-progressbars';
import { classNames } from '@progress/kendo-react-common';

interface BrassProgressBarProps extends ProgressBarProps {}

const BrassProgressBar: FunctionComponent<BrassProgressBarProps> = (
  props: BrassProgressBarProps,
) => {
  const { className } = props;
  const _className = classnames('brass-progress-bar', className);

  return (
    <ProgressBar
      {...props}
      className={classNames('progress-bar-style', _className)}
    />
  );
};

export { BrassProgressBar };
export type { BrassProgressBarProps };
