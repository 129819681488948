import React, { Fragment, FunctionComponent, useState } from 'react';
// import style from './DateInputField.module.scss';
import { translate } from './DateInputField.translations';
import { DateInputChangeEvent } from '@progress/kendo-react-dateinputs';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassDateInput, BrassDateInputProps } from '../../../dateinputs';
import { BrassLoader } from '../../../indicators';
import { BrassError } from '../../../labels';
import { requiredValidator } from '../validators/required';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';

interface DateInputInputProps extends BrassDateInputProps {
  value?: Date;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const DateInputInput: FunctionComponent<DateInputInputProps> = (
  fieldRenderProps: any,
) => {
  const { validationMessage, value, disabled, onChange, visited } = fieldRenderProps;

  const [internalValue, setInternalValue] = useState<Date>(value || new Date());
  const onInternalChange = (e: DateInputChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value!);
  };

  return (
    <Fragment>
      <BrassDateInput
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
      />
      {visited && validationMessage && (
        <BrassError>
          {internalValue} / {validationMessage}
        </BrassError>
      )}
    </Fragment>
  );
};

interface BrassDateInputFieldProps
  extends Omit<BrassFieldProps & DateInputInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}

const BrassDateInputField: FunctionComponent<BrassDateInputFieldProps> = (
  props: any,
) => {
  const { name, required, loading } = props;

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassDateInput}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label>{required ? `${props.label}*` : props.label}</Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : DateInputInput}
        label={undefined /* required ? `${props.label}*` : props.label*/}
      />
    </FieldWrapper>
  );
};

export { BrassDateInputField };
export type { BrassDateInputFieldProps };
