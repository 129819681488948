import { Language } from '../../../models';

class UserProfileModel {
  public language: Language = Language.PTBR;

  constructor(userProfile: Partial<UserProfileModel>) {
    Object.assign(this, userProfile);
  }
}

export { UserProfileModel };
