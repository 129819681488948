import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonSave: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    labelKey='save'
    className='brass-button-save'
    tooltipKey='clickToSave'
    translations={translations}
    icon={<BrassIcon icon={faSave} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    save: 'ahorrar',
    clickToSave: 'Haga clic para guardar',
  },
  enUS: {
    save: 'save',
    clickToSave: 'Click to save',
  },
  ptBR: {
    save: 'salvar',
    clickToSave: 'Clique para salvar',
  },
};

export { BrassButtonSave };
