import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    validHours: 'Ingrese una hora correcta',
  },
  enUS: {
    validHours: 'Type a valid hour',
  },
  ptBR: {
    validHours: 'Insira um horário válido',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const hoursValidator = (value: string): string | undefined => {
  if (value && value.includes('_')) {
    return translate('validHours');
  }
  return undefined;
};

export { hoursValidator };
