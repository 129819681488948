import { LanguagesModel } from './DropdownLanguages';

const getLanguagesStorage = (): LanguagesModel => {
  const language: LanguagesModel = JSON.parse(
    localStorage.getItem('language') as string,
  );
  return (
    language ?? {
      name: 'Português',
      language: 'pt',
      iconUrl:
        'https://account.bdbsystem.com.br/languages/7346F985-BCE6-4E68-8DE3-02DFF5FD2E06.png',
    }
  );
};

export { getLanguagesStorage };
