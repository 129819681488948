import React, { Component, Fragment, ReactNode, useRef, useState } from 'react';
import { BrassCardTreeListGridProps } from './CardTreeListGridProps.interface';
import { deleteColumnCard, editColumnCard } from './CardTreeListGrid.functions';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  TreeList,
  TreeListColumnProps,
  TreeListDataStateChangeEvent,
  TreeListExpandChangeEvent,
  TreeListToolbar,
} from '@progress/kendo-react-treelist';
import {
  BrassLoader,
  BrassButtonFilter,
  BrassButtonAdd,
  BrassButtonRefresh,
} from '../../../..';
import style from './CardTreeListGrid.module.scss';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import { GridActions } from '../../../../models';

const renderCardTreeList = (props: BrassCardTreeListGridProps): ReactNode => {
  const {
    excelExportColumns,
    gridState,
    gridDispatch,
    gridCallbacks,
    permissions,
    customFilterGridToolbar,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarBeforeActions,
    toolbarAfterActions,
    translate,
    treeListColumns,
  } = props;
  const subItemsField: string = 'children'; // CHANGE
  const expandField: string = 'expanded';
  const anchorRef = useRef<any>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

  const preColumns = [...treeListColumns!];

  const gridExcelExport = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
      gridExcelExport.current.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null && gridPDFExport !== undefined) {
      gridPDFExport.save();
    }
  };

  editColumnCard(props, preColumns);
  deleteColumnCard(props, preColumns);

  let _columns: TreeListColumnProps[] = preColumns.map(
    (col) => col as TreeListColumnProps,
  );
  if (!permissions!.update) {
    _columns = _columns.filter((col: TreeListColumnProps) => col.field !== 'edit');
  }
  if (!permissions!.delete) {
    _columns = _columns.filter((col: TreeListColumnProps) => col.field !== 'delete');
  }

  const expandRecursive = (recordId: string, rec: any[]): any => {
    const oldRec = rec;
    const newRec = rec.map((item: any) => {
      if (item) {
        if (item.id === recordId) {
          if (item.expanded) {
            item.expanded = false;
          } else {
            item.expanded = true;
          }
          return item;
        } else {
          if (item.children) {
            expandRecursive(recordId, item.children);
          }
        }
      }
    });
    newRec.forEach((el: any, index: number) => {
      if (el === undefined) {
        newRec[index] = oldRec[index]; // Atualiza os registros apenas com o item modificado
      }
    });
    return newRec;
  };

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    const recordId = e.dataItem.id;
    const newStateData = expandRecursive(recordId, gridState.recordsList.records!);
    gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: { records: newStateData },
    });
  };

  const handleDataStateChange = (event: TreeListDataStateChangeEvent) => {
    gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: event.dataState,
    });
  };

  return (
    <Fragment>
      {gridState.gridLoading && (
        <BrassLoader
          useLoadingMask
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      )}
      <TreeList
        ref={anchorRef}
        dataItemKey='id'
        className={style['brass-tree-list']}
        // style={{overflow: 'auto', maxHeight: '80vh' }}
        expandField={expandField}
        subItemsField={subItemsField} // CHANGE
        data={gridState.recordsList.records}
        columns={treeListColumns as TreeListColumnProps[]}
        onDataStateChange={handleDataStateChange}
        onExpandChange={onExpandChange}
        sortable={props.sortable ? { mode: 'multiple' } : {}}
        onFilterChange={() => {}}
        onSortChange={() => {}}
        toolbar={
          <TreeListToolbar>
            {toolbarBeforeTitle}
            <h2 className={style['brass-grid-title']}>
              {props.icon}
              {translate('gridTitle')}
            </h2>
            {toolbarAfterTitle}
            <ToolbarSpacer />
            {toolbarBeforeActions}
            {permissions?.create && (
              <BrassButtonAdd
                fillMode='outline'
                className={style['brass-button-insert']}
                onClick={gridCallbacks.onAdd as any}
                disabled={gridState.gridLoading}
              />
            )}
            <BrassButtonRefresh
              fillMode='outline'
              className={style['brass-button-refresh']}
              onClick={() => {
                gridDispatch({
                  type: GridActions.UPDATE_GRID_LOADING,
                  payload: true,
                });
                gridCallbacks.onRefresh();
              }}
              disabled={gridState.gridLoading}
            />
            {permissions?.export && (
              <DropDownButton
                icon='printer'
                items={['PDF', 'Excel']}
                onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
                  itemClickEvent.itemIndex === 0 ? exportPDF() : excelExport()
                }
                disabled={gridState.gridLoading}
              />
            )}
            {customFilterGridToolbar && (
              <BrassButtonFilter
                key='buttonFilter'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              />
            )}
            {toolbarAfterActions}
          </TreeListToolbar>
        }
      />
      {showFilterPanel && customFilterGridToolbar && (
        <TreeListToolbar>{customFilterGridToolbar}</TreeListToolbar>
      )}
      <div style={{ maxHeight: 0 }}>
        <ExcelExport
          ref={gridExcelExport}
          data={gridState.recordsList.records}
          fileName={`${translate('gridTitle')} - ${new Date().toDateString()}`}
        >
          <TreeList data={gridState.recordsList.records} style={{ display: 'none' }}>
            {excelExportColumns || treeListColumns}
          </TreeList>
        </ExcelExport>
        <PDFExport
          // @ts-ignore
          ref={(pdfExport: PDFExport) => (gridPDFExport = pdfExport)}
          fileName={`${translate('gridTitle')} - ${new Date().toDateString()}`}
          paperSize='A4'
          margin='1cm'
        >
          <TreeList data={gridState.recordsList.records} style={{ display: 'none' }}>
            {treeListColumns}
          </TreeList>
        </PDFExport>
      </div>
    </Fragment>
  );
};

export { renderCardTreeList };
