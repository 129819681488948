import {
  AcademyPermissionsV1,
  AuthUser,
  ConnectPermissions,
  DrivePermissionsV1,
  ModuleEnum, ModuleUrlEnum,
  NavigatorPermissions,
} from '../../../models';

const authorizeAccess = (module: ModuleEnum): boolean => {
  const isLoginPage =
   window.location.pathname.startsWith('/auth/login') ||
    window.location.pathname.startsWith('/login') ||
    window.location.pathname.startsWith('/auto-login');
  const allowConnectAccess = AuthUser.checkPermission(
    ConnectPermissions.CONNECT_MODULE,
  );
  const allowNavigatorAccess = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_MODULE,
  );
  const allowDriveAccess =
    AuthUser.checkPermission(DrivePermissionsV1.SMART_LIB_MODULE) ||
    AuthUser.checkPermission(DrivePermissionsV1.NUCLEAR_MANAGEMENT_MODULE);
  const allowAcademyAccess = AuthUser.checkPermission(
    AcademyPermissionsV1.ACADEMY_MODULE,
  );

  const environment = '';
  let appUrl = '';
  let accessOrder;

  const connect = {
    hasAccess: allowConnectAccess,
    appUrl: ModuleUrlEnum.CONNECT,
  };
  const navigator = {
    hasAccess: allowNavigatorAccess,
    appUrl: ModuleUrlEnum.NAVIGATOR,
  };
  const drive = {
    hasAccess: allowDriveAccess,
    appUrl: ModuleUrlEnum.DRIVE,
  };
  const academy = {
    hasAccess: allowAcademyAccess,
    appUrl: ModuleUrlEnum.ACADEMY,
  };

  if (module === ModuleEnum.CONNECT) {
    accessOrder = [connect, navigator, drive, academy];
  } else if (module === ModuleEnum.NAVIGATOR_INTEGRATION) {
    accessOrder = [navigator, connect, drive, academy];
  } else if (module === ModuleEnum.NAVIGATOR_SURVEY) {
    accessOrder = [navigator, connect, drive, academy];
  } else if (module === ModuleEnum.DRIVE) {
    accessOrder = [drive, connect, navigator, academy];
  } else if (module === ModuleEnum.ACADEMY) {
    accessOrder = [academy, connect, navigator, drive];
  } else {
    accessOrder = [connect, navigator, drive, academy];
  }

  if (!isLoginPage) {
    if (
      !allowConnectAccess &&
      !allowNavigatorAccess &&
      !allowDriveAccess &&
      !allowAcademyAccess
    ) {
      setTimeout(() => (window.location.href = '/login'), 1000);
      return false;
    } else {
      appUrl = accessOrder.filter((m) => m.hasAccess)[0].appUrl;
      if (
        (appUrl === ModuleUrlEnum.CONNECT && module === ModuleEnum.CONNECT) ||
        (appUrl === ModuleUrlEnum.NAVIGATOR_INTEGRATION &&
          module === ModuleEnum.NAVIGATOR_INTEGRATION) ||
        (appUrl === ModuleUrlEnum.NAVIGATOR_SURVEY &&
          module === ModuleEnum.NAVIGATOR_SURVEY) ||
        (appUrl === ModuleUrlEnum.DRIVE && module === ModuleEnum.DRIVE) ||
        (appUrl === ModuleUrlEnum.ACADEMY && module === ModuleEnum.ACADEMY)
      ) {
        return true;
      } else {
        setTimeout(
          () =>
            (window.location.href = `${window.location.origin}/auth/login?redirect=${window.location.origin}/home`),
          500,
        );
        return false;
      }
    }
  }
  return true;
};

export { authorizeAccess };

