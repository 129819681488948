import { AcademyPermissionsV1 } from './academy/Academy.permissions.enum';
import { AreaPermissionsV1 } from './area/Area.permissions.enum';
import { BindPermissionsV1 } from './bind/Bind.permissions.enum';
import {
  ConnectPermissions,
  ConnectPermissionsV1,
} from './connect/Connect.permissions.enum';
import { ExternalIntegrationPermissionsV1 } from './external-integration/ExternalIntegration.permissions.enum';
import { FinancePermissionsV1 } from './finance/Finance.permissions.enum';
import {
  NavigatorPermissions,
  IntegratorPermissionsV1,
} from './integrator/Integrator.permissions.enum';
import { ProjectPermissionsV1 } from './project/Project.permissions.enum';
import { SurveyPermissionsV1 } from './survey/Survey.permissions.enum';
import { WorkTimeRecordPermissionsV1 } from './work-time-record/WorkTimeRecord.permissions.enum';
import { DrivePermissionsV1 } from './drive/Drive.permissions.enum';
import { SgiPermissionsV1 } from './sgi/Sgi.permissions.enum';
import { ConnectHelpCenterPermissions } from './connect/Connect.HelpCenter.permissions.enum';
import { ConnectSetupSystemPermissions } from './connect/Connect.SetupSystem.permissions.enum';

type PermissionsType =
  | string
  | ConnectPermissions
  | NavigatorPermissions
  //
  | AcademyPermissionsV1
  | SgiPermissionsV1
  | AreaPermissionsV1
  | BindPermissionsV1
  | ConnectPermissionsV1
  | ExternalIntegrationPermissionsV1
  | FinancePermissionsV1
  | IntegratorPermissionsV1
  | ProjectPermissionsV1
  | SurveyPermissionsV1
  | WorkTimeRecordPermissionsV1
  | DrivePermissionsV1
  | ConnectHelpCenterPermissions
  | ConnectSetupSystemPermissions;

const Permissions = {
  ...ConnectPermissions,
  ...NavigatorPermissions,
  //
  ...AcademyPermissionsV1,
  ...AreaPermissionsV1,
  ...BindPermissionsV1,
  ...ConnectPermissionsV1,
  ...ExternalIntegrationPermissionsV1,
  ...FinancePermissionsV1,
  ...IntegratorPermissionsV1,
  ...ProjectPermissionsV1,
  ...SurveyPermissionsV1,
  ...WorkTimeRecordPermissionsV1,
  ...DrivePermissionsV1,
  ...SgiPermissionsV1,
  ...ConnectHelpCenterPermissions,
};

export type { PermissionsType };
export { Permissions };
