import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

interface BrassDivHtmlProps {
  html: string;
  className?: string;
}

const BrassDivHtml: FunctionComponent<BrassDivHtmlProps> = (
  props: BrassDivHtmlProps,
) => {
  const { html, className } = props;
  const _className = classnames('brass-div-html', className);

  return <div className={_className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export { BrassDivHtml };
