enum FinanceSubmodule {
  // Verificação/Validação de RHT de Funcionário -> /finance/payslip-shipment
  WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON = 0x00000100,
  // Verificação/Validação de RHT de Pessoa Jurídica -> /work-hours-report/pending-approval
  WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON = 0x00000200,
  // Autorização de Pagamento Pessoa Jurídica -> payment-authorization
  PAYMENT_AUTHORIZATION = 0x00000300,
  // Envio de Nota Fiscal do colaborador
  EMPLOYEE_INVOICE = 0x00000400,
  // Emissão de Contracheque  -> /paycheck-issue
  PAYCHECK_ISSUE = 0x00000500,
  REPORT_LEGAL_PERSON_PAYMENT = 0x00000600,
  INVOICE_ISSUANCE_MANAGEMENT = 0x00000700,
}

export { FinanceSubmodule };
