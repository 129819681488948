import React, { FunctionComponent } from 'react';
import { BrassIcon } from '../icon/Icon';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonFilter: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    togglable
    fillMode='outline'
    className='brass-button-filter'
    icon={<BrassIcon icon={faFilter} />}
    {...props}
  />
);

export { BrassButtonFilter };
