import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconFilmSeen: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 14 11' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7 7.5C8.16998 7.5 9.22 6.51003 10 5.49C9.21998 4.47997 8.17 3.5 7 3.5C5.83 3.5 4.79 4.48002 4 5.49C4.78997 6.50998 5.83 7.5 7 7.5ZM7 6.5C6.43997 6.5 6 6.06003 6 5.5C6 4.93997 6.43997 4.5 7 4.5C7.56003 4.5 8 4.93997 8 5.5C8 6.06003 7.56003 6.5 7 6.5ZM0.8 11H13.2C13.74 11 14 10.74 14 10.2V0.8C14 0.260033 13.74 0 13.2 0H0.8C0.260033 0 0 0.260033 0 0.8V10.2C0 10.74 0.260033 11 0.8 11ZM1 10V9H2V10H1ZM1 8V7H2V8H1ZM1 6V5H2V6H1ZM1 4V3H2V4H1ZM1 2V1H2V2H1ZM3 10V1H11V10H3ZM12 10V9H13V10H12ZM12 8V7H13V8H12ZM12 6V5H13V6H12ZM12 4V3H13V4H12ZM12 2V1H13V2H12Z'
      fill='white'
    />
  </svg>
);

export { SVGIconFilmSeen };
