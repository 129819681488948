import React, { FunctionComponent, useState, memo } from 'react';
import { DropDownData, II18n, LanguageEnum } from '../../../../models';
import {
  BrassDropDownButton,
  BrassDropDownButtonProps,
} from '../../../../components';
import { I18nService } from '../../../../services';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';

interface LanguagesDropdownButtonProps extends BrassDropDownButtonProps {
  onSelectItem: (languages: LanguageEnum) => void;
}

interface DropDownDataWithIcon extends DropDownData {
  icon: string;
}

const LanguagesDropdownButton: FunctionComponent<LanguagesDropdownButtonProps> =
  memo((props: LanguagesDropdownButtonProps) => {
    const languages: DropDownDataWithIcon[] = [
      {
        value: LanguageEnum.PTBR,
        text: translate('portuguese'),
        icon: 'https://cdn-icons-png.flaticon.com/16/197/197386.png',
      },
      {
        value: LanguageEnum.ES,
        text: translate('spanish'),
        icon: 'https://cdn-icons-png.flaticon.com/16/197/197593.png',
      },
      {
        value: LanguageEnum.EN,
        text: translate('english'),
        icon: 'https://cdn-icons-png.flaticon.com/16/197/197484.png',
      },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState<DropDownDataWithIcon>(
      languages[0],
    );

    /* eslint-disable */
    return (
      <BrassDropDownButton
        key='LanguagesDropdownButton'
        fillMode='outline'
        imageUrl={selectedLanguage.icon}
        icon={selectedLanguage.icon}
        items={languages}
        text={selectedLanguage?.text}
        onItemClick={(event: DropDownButtonItemClickEvent | any) => {
          setSelectedLanguage(event.item);
          props.onSelectItem(event.item.value);
        }}
        {...props}
      />
    );
  });

const translations: II18n = {
  es: {
    portuguese: 'Portugués',
    spanish: 'Español',
    english: 'Inglés',
  },
  enUS: {
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    english: 'English',
  },
  ptBR: {
    portuguese: 'Português',
    spanish: 'Espanhol',
    english: 'Inglês',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { LanguagesDropdownButton };
export type { LanguagesDropdownButtonProps };
