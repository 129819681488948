import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    requiredMessage: 'obrigatorio',
  },
  enUS: {
    requiredMessage: 'required',
  },
  ptBR: {
    requiredMessage: 'obrigatório',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const requiredValidator = (value: string): string | undefined => {
  if (value === null || value === undefined || value.length === 0) {
    return translate('requiredMessage');
  }
  return undefined;
};

export { requiredValidator };
