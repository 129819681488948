import React, { Fragment, FunctionComponent, useState } from 'react';
import { translate } from './DatePickerField.translations';
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassDatePicker, BrassDatePickerProps } from '../../../dateinputs';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import classnames from 'classnames';
import style from './DatePickerField.module.scss';
import { Label } from '@progress/kendo-react-labels';

interface DatePickerInputProps extends BrassDatePickerProps {
  value?: Date;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const DatePickerInput: FunctionComponent<DatePickerInputProps> = (
  fieldRenderProps: any,
) => {
  const { validationMessage, value, disabled, onChange, visited } = fieldRenderProps;

  const [internalValue, setInternalValue] = useState<Date>(value || new Date());
  const onInternalChange = (e: DatePickerChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value!);
  };

  return (
    <Fragment>
      <BrassDatePicker
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
      />
      {/* {visited && validationMessage && (
        <BrassError>
          {internalValue} - {validationMessage}
        </BrassError>
      )} */}
    </Fragment>
  );
};

interface BrassDatePickerFieldProps
  extends Omit<BrassFieldProps & DatePickerInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}

const BrassDatePickerField: FunctionComponent<BrassDatePickerFieldProps> = (
  props: BrassDatePickerFieldProps,
) => {
  const { name, required, label, loading, className } = props;
  const _className = classnames('brass-datepicker-field', className);

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassDatePicker}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        className={_className}
        validator={validators!}
        component={loading ? () => <BrassLoader /> : DatePickerInput}
        label={undefined /* required ? `${label!}*` : label!*/}
        popupSettings={{
          className: style['brass-datepicker-field-popup'],
        }}
      />
    </FieldWrapper>
  );
};

export { BrassDatePickerField };
export type { BrassDatePickerFieldProps };
