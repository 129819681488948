/*
  0xFFFFFFFF = 4 294 967 295

  FF XXXXXX = 255 modules
  XX FFFF XX = 65535 submodules
  XX XXXX FF = 255 actions
*/

enum PermissionModule {
  NONE = 0x00000000,
  //------------------------------------
  // Connect
  CONNECT = 0x01000000,
  /// <summary>
  /// Gestão de Projetos
  /// </summary>
  PROJECT = 0x02000000,
  /// <summary>
  /// Gestão de Áreas
  /// </summary>
  AREA = 0x03000000,
  DRIVE = 0x03000000,
  /// <summary>
  /// Gestão de Sistema
  /// </summary>
  SYSTEM = 0x04000000,
  // RHT
  WORK_TIME_RECORD = 0x05000000,
  // Financeiro
  FINANCE = 0x06000000,
  // Proposta Comercial
  // BUSINESSES_PROPOSAL = 0x07000000,
  // Gestão de Cliente
  // CUSTOMER_MANAGEMENT = 0x08000000,
  //------------------------------------
  // Drive
  SMART_LIB = 0x09000000,
  NUCLEAR_MANAGEMENT = 0x0a000000,
  //------------------------------------
  // Navigator
  INTEGRATOR = 0x0f000000,
  SURVEY = 0x10000000,
  BIND = 0x11000000,
  INSPECT = 0x12000000,
  //-------------------------
  SGI = 0x15000000,
  ACADEMY = 0x16000000,
  EXTERNAL_INTEGRATION = 0x14000000,
}

export { PermissionModule };
