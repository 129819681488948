import { random, randomInt } from 'mathjs';

class Math {
  public static randomInt = (min: number, max: number): number =>
    randomInt(min, max);
  public static randomFloat = (min: number, max: number): number => random(min, max);
  public static getExcelExportColumnFormat = (): string => '#.##0';
  public static excelDecimalExportColumnFormat = (): string => '#,##0.0';
}

export { Math };
