import React, {
  Fragment,
  FunctionComponent,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { translate } from './RelationalFlagGrid.translations';
import { getColumns, getExcelExportColumns } from './RelationalFlagGrid.functions';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import style from './RelationalFlagGrid.module.scss';
import { useCallback } from 'react';
import classNames from 'classnames';
import { Label } from '@progress/kendo-react-labels';
import { RelationalFlagsContext } from '../../../../../store/RelationalFlagsContext';
import { emptyGuid } from '../../../../../util';
import { BrassCardTreeListGrid } from '../../../../../components/brass';
import { BrassIcon } from '../../../../../components/shared/icon';
import { ApiGridResult, DropDownData, GridItem } from '../../../../../models';
import { useGrid } from '../../../../../hooks';
import { Flag } from '../../../../../models/Flag.model';
import { RelationalFlagsService } from '../../../../..//services/RelationalFlags.service';

interface FlagGridProps {
  setSelectedFlags: React.Dispatch<React.SetStateAction<DropDownData<string>[]>>;
  selectedFlags: DropDownData[];
  readonly?: boolean;
}

const RelationalFlagGrid: FunctionComponent<FlagGridProps> = memo(
  (props: FlagGridProps) => {
    const { selectedFlags } = props;
    const { language, relationalGroup, flagValue, setFlags, setDataItemRemove } =
      useContext(RelationalFlagsContext);
    const [getRecordsParams, setGetRecordsParams] = useState<{
      groupRelationalId: string;
      language: string;
    }>({
      groupRelationalId: relationalGroup?.groupRelationalId || emptyGuid(),
      language,
    });

    const onRefreshLogic = (apiResultData: ApiGridResult<Flag | GridItem>) => {
      apiResultData.records = apiResultData.records?.map((item: any) => {
        const allFlags = [...selectedFlags!];
        return {
          ...item,
          selected:
            allFlags?.findIndex(
              (_: DropDownData) => _.value === item.flagRelationalId,
            ) >= 0,
        };
      });
    };

    useEffect(() => {
      if (flagValue.value) {
        const index = selectedFlags?.findIndex(
          (item: any) => item?.value === flagValue?.value,
        );
        if (index >= 0) {
          selectedFlags.splice(index, 1);
        }
        gridCallbacks.onRefresh();
      }
    }, [flagValue]);

    const { gridState, gridDispatch, gridCallbacks } = useGrid<Flag>({
      getRecords: RelationalFlagsService.getRelationalFlagsGrid,
      getRecordsParams,
      translate,
      onRefreshLogic,
    });

    const columns = getColumns(gridState);
    const excelExportColumns = getExcelExportColumns(columns);

    const handleGetList = useCallback(() => {
      setGetRecordsParams({
        ...getRecordsParams,
        groupRelationalId:
          (relationalGroup?.groupRelationalId as string) || emptyGuid(),
      });
    }, [relationalGroup]);

    useEffect(() => {
      if (language !== getRecordsParams.language) {
        setGetRecordsParams({
          ...getRecordsParams,
          language,
        });
      }
      if (relationalGroup.groupRelationalId !== getRecordsParams.groupRelationalId) {
        setGetRecordsParams({
          ...getRecordsParams,
          groupRelationalId: relationalGroup.groupRelationalId,
        });
      }
    }, [relationalGroup, language]);

    useEffect(() => {
      handleGetList();
    }, [handleGetList]);

    return (
      <Fragment>
        <div
          style={{
            height: `256px`,
            width: '54%',
          }}
        >
          <Label style={{ opacity: 0.6, fontSize: '11px' }}>
            {translate('flagRelational')}
          </Label>
          <BrassCardTreeListGrid
            gridColumns={columns}
            excelExportColumns={excelExportColumns}
            icon={<BrassIcon icon={faNetworkWired} />}
            translate={translate}
            gridState={gridState}
            gridDispatch={gridDispatch}
            gridCallbacks={gridCallbacks}
            groupable={{
              enabled: props?.readonly ? false : true,
              footer: 'none',
            }}
            className={classNames({
              [style['grid']]: true,
            })}
            width={'100%'}
            selectable={{
              enabled: !props.readonly ? true : false,
              mode: 'multiple',
            }}
            fieldIdName={'flagRelationalId'}
            onSelectionChange={(id: string[], event) => {
              if (!event.dataItem.selected) {
                setDataItemRemove(event.dataItem);
              } else {
                setDataItemRemove(null);
              }
              const flagFormat = gridState.recordsList.records?.map((item: any) => {
                return {
                  text: item?.name,
                  value: item?.flagRelationalId,
                  selected: id.find((_) => item.flagRelationalId === _)
                    ? true
                    : false,
                } as DropDownData;
              });
              setFlags(flagFormat!);
            }}
          />
        </div>
      </Fragment>
    );
  },
);

export { RelationalFlagGrid };
