import React, {
  FunctionComponent,
  useState,
  useEffect,
  Fragment,
  Dispatch,
  SetStateAction,
} from 'react';
import { translate } from './UserGroupPrivilegesGrid.translations';
import { GridColumn } from '@progress/kendo-react-grid'; // CHANGE
import styles from './UserGroupPrivilegesGrid.module.scss';
import { UserGroupData } from '../../../../../models';
import {
  AccessDenied,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassTreeListGrid,
  BrassIconUser,
  BrassButton,
} from '../../../../../../../components';
import {
  AuthUser,
  ApiGridResult,
  ConnectPermissions,
} from '../../../../../../../models';
import { PermissionService } from '../../../../../../../services';

interface UserGroupPrivilegesGridProps {
  setAllowSubmit: Dispatch<SetStateAction<boolean>>;
  userData: UserGroupData;
  setUserData: Dispatch<SetStateAction<UserGroupData>>;
}

interface PrivilegeData {
  id: number;
  privilege: string;
}

const UserGroupPrivilegesGrid: FunctionComponent<UserGroupPrivilegesGridProps> = (
  props: UserGroupPrivilegesGridProps,
) => {
  const [selectedPrivileges, setSelectedPrivileges] = useState<number[] | string[]>(
    [],
  );
  const [gridLoading, setGridLoading] = useState<boolean>(true);
  const [userPrivilegesList, setPrivilegesList] = useState<
    ApiGridResult<PrivilegeData>
  >({ records: [], total: 0 });
  const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>({
    ...BrassGridConfigDefault,
    take: 99999,
  });

  const loadGridData = (): void => {
    setGridLoading(true);
    const tempResult: PrivilegeData[] = [];
    props.userData.privileges.forEach((item) => {
      PermissionService.permissions.forEach((p) => {
        if (p.value === item) {
          tempResult.push({ id: p.value, privilege: p.text });
        }
      });
    });
    setPrivilegesList({
      records: tempResult,
      total: tempResult.length,
    });
    setGridLoading(false);
  };
  useEffect(loadGridData, [props.userData.privileges]);

  const removePrivileges = (): void => {
    setGridLoading(true);
    let privileges = props.userData.privileges;
    privileges = privileges.filter(
      (privilege: any) => !selectedPrivileges.includes(privilege as never),
    );

    props.setUserData({
      ...props.userData,
      privileges,
    });
    props.setAllowSubmit(true);
    setGridLoading(false);
    setSelectedPrivileges([]);
  };

  const columns = [
    <GridColumn
      key='id'
      field='id'
      width='180'
      title={translate('privilegeId')}
      columnMenu={BrassGridColumnMenu}
    />,
    <GridColumn
      key='privilege'
      field='privilege'
      title={translate('privileges')}
      columnMenu={BrassGridColumnMenu}
    />,
  ];

  if (!AuthUser.checkPermission(ConnectPermissions.CONNECT_USERGROUP_READ)) {
    return <AccessDenied />;
  }

  return (
    <Fragment>
      <BrassTreeListGrid
        className={styles.grid}
        visibleButtonRefresh={false}
        columns={columns}
        gridData={userPrivilegesList}
        gridLoading={gridLoading}
        setGridLoading={setGridLoading}
        gridTitle={translate('privilegesList')}
        gridIcon={<BrassIconUser />}
        gridConfig={gridConfig}
        updateGridConfig={setGridConfig}
        updateGridData={setPrivilegesList}
        pageable={false}
        selectable={{
          enabled: true,
          mode: 'multiple',
        }}
        onSelectionChange={setSelectedPrivileges}
        onHeaderSelectionChange={setSelectedPrivileges}
        toolbarBeforeActions={[
          <BrassButton
            type='button'
            disabled={selectedPrivileges.length === 0}
            key='brassbutton'
            onClick={removePrivileges}
          >
            {translate('insert', [
              selectedPrivileges.length > 0
                ? selectedPrivileges.length.toString()
                : '',
            ])}
          </BrassButton>,
        ]}
      />
    </Fragment>
  );
};

export { UserGroupPrivilegesGrid };
