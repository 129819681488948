import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonSharedProps, BrassButtonShared } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const BrassButtonAdd: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-add'
    labelKey='add'
    tooltipKey='clickToAdd'
    translations={translations}
    icon={<BrassIcon icon={faPlus} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    add: 'Agregar',
    clickToAdd: 'Haga clic para agregar',
  },
  enUS: {
    add: 'Add',
    clickToAdd: 'Click to add',
  },
  ptBR: {
    add: 'Adicionar',
    clickToAdd: 'Clique para adicionar',
  },
};

export { BrassButtonAdd };
