import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    communications: 'Comunicaciones',
    noCommunicationSelected: 'No se ha seleccionado ninguna comunicación.',
    messagesInTotal: 'mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',
    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'REGISTRO DE ACTIVIDAD SEMANAL DEL USUARIO EN PENDENCIA',
    RECOVERY_PASSWORD: 'CONTRASEÑA DE RECUPERACIÓN',
    RHT_SUBMISSION_REQUEST: 'SOLICITUD DE PRESENTACIÓN DE IHT',
    RHT_APPROVAL_REQUEST: 'SOLICITUD DE APROBACIÓN IHT',
    RHT_INVOICE_REQUEST: 'AUTORIZACIÓN DE EMISIÓN DE FACTURA',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDENCIA DE ENVÍO DE FACTURA DE USUARIO',

    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY_BUTTON: 'Registrar actividad semanal',
    RECOVERY_PASSWORD_BUTTON: 'Recuperar contraseña',
    RHT_SUBMISSION_REQUEST_BUTTON: 'Enviar IHT',
    RHT_APPROVAL_REQUEST_BUTTON: 'Aprobar IHT',
    RHT_INVOICE_REQUEST_BUTTON: 'Enviar factura',
    USER_INVOICE_SUBMIT_PENDENCY_BUTTON: 'Enviar factura',
    PAYCHECK_NOTIFICATION_BUTTON: 'Ver mis Cheques de Pago',
    PAYCHECK_NOTIFICATION: 'Cheque de pago disponible',

    PROJECT_OPENING_TERM: 'Plazo de apertura del proyecto',
    PROJECT_OPENING_TERM_BUTTON: 'Plazo de apertura del proyecto',
  },
  enUS: {
    communications: 'Communications',
    noCommunicationSelected: 'No communication selected.',
    messagesInTotal: 'messages in total',
    alert: 'Alert',
    alerts: 'Alerts',
    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'REGISTRO DE ACTIVIDAD SEMANAL DEL USUARIO EN PENDENCIA',
    RECOVERY_PASSWORD: 'CONTRASEÑA DE RECUPERACIÓN',
    RHT_SUBMISSION_REQUEST: 'SOLICITUD DE PRESENTACIÓN DE RHT',
    RHT_APPROVAL_REQUEST: 'SOLICITUD DE APROBACIÓN RHT',
    RHT_INVOICE_REQUEST: 'INVOICE ISSUANCE AUTHORIZATION',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDENCIA DE ENVÍO DE FACTURA DE USUARIO',

    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY_BUTTON: 'Record Weekly Activity',
    RECOVERY_PASSWORD_BUTTON: 'Recover Password',
    RHT_SUBMISSION_REQUEST_BUTTON: 'Submit WHR',
    RHT_APPROVAL_REQUEST_BUTTON: 'Approve WHR',
    RHT_INVOICE_REQUEST_BUTTON: 'Send Invoice',
    USER_INVOICE_SUBMIT_PENDENCY_BUTTON: 'Send Invoice',
    PAYCHECK_NOTIFICATION_BUTTON: 'See My Paychecks',
    PAYCHECK_NOTIFICATION: 'Paycheck Available',

    PROJECT_OPENING_TERM: 'Project opening term',
    PROJECT_OPENING_TERM_BUTTON: 'Project opening term',
  },
  ptBR: {
    communications: 'Comunicações',
    noCommunicationSelected: 'Nenhuma comunicação selecionada.',
    messagesInTotal: 'mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',

    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY:
      'PENDÊNCIA DE REGISTRO DE ATIVIDADE SEMANAL DO USUÁRIO',
    RECOVERY_PASSWORD: 'SENHA DE RECUPERAÇÃO',
    RHT_SUBMISSION_REQUEST: 'SOLICITAÇÃO DE SUBMISSÃO DE RHT',
    RHT_APPROVAL_REQUEST: 'PEDIDO DE APROVAÇÃO RHT',
    RHT_INVOICE_REQUEST: 'AUTORIZAÇÃO DE EMISSÃO DE NF',
    USER_INVOICE_SUBMIT_PENDENCY: 'PENDÊNCIA DE ENVIO DE FATURA DE USUÁRIO',

    USER_WEEKLY_ACTIVITY_RECORD_PENDENCY_BUTTON: 'Registrar Atividade Semanal',
    RECOVERY_PASSWORD_BUTTON: 'Recuperar Senha',
    RHT_SUBMISSION_REQUEST_BUTTON: 'Submeter RHT',
    RHT_APPROVAL_REQUEST_BUTTON: 'Aprovar RHT',
    RHT_INVOICE_REQUEST_BUTTON: 'Enviar Nota Fiscal',
    USER_INVOICE_SUBMIT_PENDENCY_BUTTON: 'Enviar Nota Fiscal',
    PAYCHECK_NOTIFICATION_BUTTON: 'Ver meus contracheques',
    PAYCHECK_NOTIFICATION: 'Contracheque Disponível',

    PROJECT_OPENING_TERM: 'Termo de abertura de projeto',
    PROJECT_OPENING_TERM_BUTTON: 'Termo de abertura de projeto',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
