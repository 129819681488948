import React, { Dispatch, Fragment, useState } from 'react';
import style from './DeleteCell.module.scss';
import {
  BrassButtonCancel,
  BrassButtonDelete,
  BrassButtonYes,
  BrassDialog,
  BrassDialogActionsBar,
  BrassGridCell,
  BrassGridCellProps,
} from '../../../../';

const BrassDeleteCell = (
  _props: BrassGridCellProps,
  onDeleteCallback: (item: any) => void,
  gridData: any,
  updateGridData: Dispatch<any>,
): any => {
  const [recordDialogConfirmDelete, setRecordDialogConfirmDelete] = useState<
    any | undefined
  >(undefined);

  let dialogDeleteRecord: JSX.Element = <Fragment />;
  const showDialog =
    recordDialogConfirmDelete &&
    gridData.records &&
    _props.dataItem.id === recordDialogConfirmDelete.id;
  if (showDialog) {
    const onClickButtonCancel = () => setRecordDialogConfirmDelete(undefined);
    const onClickButtonYes = () => {
      if (onDeleteCallback) {
        if (gridData.records) {
          gridData.records = gridData.records.map((record: any) => {
            if (record.id === _props.dataItem.id) {
              record.deleting = true;
            }
            return record;
          });

          updateGridData({ ...gridData });
          onDeleteCallback(recordDialogConfirmDelete);
        }
      }
      setRecordDialogConfirmDelete(undefined);
    };

    dialogDeleteRecord = (
      <BrassDialog
        title='Confirmação de Exclusão'
        onClose={() => setRecordDialogConfirmDelete(undefined)}
      >
        <p>Confirma a exclusão deste Registro?</p>
        <BrassDialogActionsBar>
          <BrassButtonYes themeColor='base' onClick={onClickButtonYes} />
          <BrassButtonCancel autoFocus onClick={onClickButtonCancel} />
        </BrassDialogActionsBar>
      </BrassDialog>
    );
  }

  return (
    <BrassGridCell {..._props}>
      <BrassButtonDelete
        fillMode='flat'
        roundBorder
        showText={false}
        hideIconWhenLoader
        hidden={_props.dataItem.hideDelete}
        showLoader={_props.dataItem.deleting}
        className={style['brass-grid-delete-button']}
        onClick={() => setRecordDialogConfirmDelete(_props.dataItem)}
        disabled={!onDeleteCallback || _props.dataItem.deleting}
      />
      {dialogDeleteRecord}
    </BrassGridCell>
  );
};

export { BrassDeleteCell };
