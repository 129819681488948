import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonPenV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-edit'
    labelKey='edit'
    tooltipKey='clickToEdit'
    translations={translations}
    onClick={props.onClick}
    icon={<BrassIcon icon={faPen} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    edit: 'Editar',
    clickToEdit: 'Haga clic para editar',
  },
  enUS: {
    edit: 'Edit',
    clickToEdit: 'Click to edit',
  },
  ptBR: {
    edit: 'Editar',
    clickToEdit: 'Clique para editar',
  },
};

export { BrassButtonPenV1 };
