import React, { FunctionComponent, useContext } from 'react';
import style from './LeftMenuItemDrawer.module.scss';
import classnames from 'classnames';
import { DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';
import { AppRoute } from '../../../../../models';
import { MainLayoutContainerContext } from '../MainLayoutContainer';
import { BrassTooltip } from '../../../../../components/brass/tooltip/Tooltip';

interface LeftMenuItemDrawerProps extends DrawerItemProps {}

const LeftMenuItemDrawer: FunctionComponent<LeftMenuItemDrawerProps> = (
  props: LeftMenuItemDrawerProps,
) => {
  const { menuExpanded } = useContext(MainLayoutContainerContext);

  const _props = { ...props };
  delete _props.icon;

  const _intrnalProps = props as AppRoute;

  return (
    <BrassTooltip position='right'>
      <DrawerItem
        {..._props}
        title={menuExpanded ? '' : _intrnalProps.text}
        className={classnames(
          style['drawer-item'],
          _props.selected && style['drawer-item-selected'],
        )}
      >
        {_intrnalProps.icon}
        {menuExpanded && _intrnalProps.text}
      </DrawerItem>
    </BrassTooltip>
  );
};

export { LeftMenuItemDrawer };
export type { LeftMenuItemDrawerProps };
