import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonNo: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    className='brass-button-no'
    labelKey='no'
    translations={translations}
    {...props}
  />
);

const translations: II18n = {
  es: {
    no: 'No',
  },
  enUS: {
    no: 'No',
  },
  ptBR: {
    no: 'Não',
  },
};

export { BrassButtonNo };
