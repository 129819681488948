import React, { useState } from 'react';
import style from './CommandCell.module.scss';
import {
  BrassButtonCheck,
  BrassButtonClose,
  BrassButtonDelete,
  BrassButtonEdit,
  BrassGridCell,
  BrassGridCellProps,
  BrassTreeListGridPropsV2,
} from '../../../..';
import { GridItem } from '../../../../../models';

interface BrassCommandCellProps {
  gridProps: BrassTreeListGridPropsV2;
  gridCellProps: BrassGridCellProps;
  onUpdateCallback?: (
    dataItem: GridItem,
    props: BrassTreeListGridPropsV2,
  ) => Promise<void>;
  onCancelCallback?: (dataItem: GridItem, props: BrassTreeListGridPropsV2) => void;
  onEditCallback?: (dataItem: GridItem, props: BrassTreeListGridPropsV2) => void;
  onDeleteCallback?: (
    dataItem: GridItem,
    props: BrassTreeListGridPropsV2,
  ) => Promise<void>;
}

const BrassCommandCell = (_props: BrassCommandCellProps): any => {
  const {
    onUpdateCallback,
    onCancelCallback,
    onEditCallback,
    onDeleteCallback,
    gridProps,
    gridCellProps,
  } = _props;
  const { dataItem } = gridCellProps;
  const inEdit = dataItem[gridProps.gridState.editField!];
  const [showLoader, setShowLoader] = useState<boolean>(false);

  return inEdit ? (
    <BrassGridCell {...gridCellProps}>
      <BrassButtonCheck
        fillMode='flat'
        roundBorder
        showText={false}
        hideIconWhenLoader
        hidden={dataItem.hideEdit}
        showLoader={dataItem.deleting || showLoader}
        className={style['brass-grid-button']}
        disabled={!onUpdateCallback || dataItem.deleting}
        onClick={async (): Promise<void> => {
          if (onUpdateCallback) {
            setShowLoader(true);
            await onUpdateCallback(gridCellProps.dataItem, gridProps);
            setShowLoader(false);
          }
        }}
      />
      <BrassButtonClose
        fillMode='flat'
        roundBorder
        showText={false}
        hideIconWhenLoader
        hidden={dataItem.hideEdit}
        showLoader={dataItem.deleting || showLoader}
        className={style['brass-grid-button']}
        disabled={!onCancelCallback || dataItem.deleting}
        onClick={() =>
          onCancelCallback && onCancelCallback(gridCellProps.dataItem, gridProps)
        }
      />
    </BrassGridCell>
  ) : (
    <BrassGridCell {...gridCellProps}>
      {gridProps.permissions && gridProps.permissions.update && (
        <BrassButtonEdit
          fillMode='flat'
          roundBorder
          showText={false}
          hideIconWhenLoader
          hidden={dataItem.hideEdit}
          showLoader={dataItem.deleting || showLoader}
          className={style['brass-grid-button']}
          disabled={!onEditCallback || dataItem.deleting}
          onClick={() =>
            onEditCallback && onEditCallback(gridCellProps.dataItem, gridProps)
          }
        />
      )}
      {gridProps.permissions && gridProps.permissions.delete && (
        <BrassButtonDelete
          fillMode='flat'
          roundBorder
          showText={false}
          hideIconWhenLoader
          hidden={dataItem.hideEdit}
          showLoader={dataItem.deleting || showLoader}
          className={style['brass-grid-button']}
          disabled={!onDeleteCallback || dataItem.deleting}
          onClick={async (): Promise<void> => {
            if (onDeleteCallback) {
              await onDeleteCallback(gridCellProps.dataItem, gridProps);
            }
          }}
        />
      )}
    </BrassGridCell>
  );
};

export { BrassCommandCell };
