enum ModuleUrlEnum {
  CONNECT = 'connect',
  NAVIGATOR = 'navigator',
  NAVIGATOR_INTEGRATION = 'navigator',
  NAVIGATOR_SURVEY = 'navigator',
  SHARED = 'navigator',
  DRIVE = 'drive',
  ACADEMY = 'academy',
  SGI_BRASS = 'sgi',
  AUTH = 'auth',
  PERSONAL_DEPARTMENT = 'personaldepartment',
}

export { ModuleUrlEnum };
