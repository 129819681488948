enum ModuleEnum {
  ACADEMY = 'ACADEMY',
  DRIVE = 'DRIVE',
  DRIVE_CORE = 'DRIVE_CORE',
  DRIVE_SMARTLIB = 'DRIVE_SMARTLIB',
  CONNECT = 'CONNECT',
  NAVIGATOR_INTEGRATION = 'NAVIGATOR_INTEGRATION',
  NAVIGATOR_SURVEY = 'NAVIGATOR_SURVEY',
  SHARED = 'SHARED',
  SGI_BRASS = 'SGI_BRASS',
}

export { ModuleEnum };
