// @ts-nocheck
import React, {
  Component,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
} from 'react';
import style from './CardTreeListGrid.module.scss';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridGroupChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridPageChangeEvent,
  GridRowProps,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassButtonAdd,
  BrassButtonHelp,
  BrassButtonRefresh,
  BrassLoader,
  BrassCardTreeListGridProps,
  BrassWindow,
  BrassButtonFilter,
  HideHeaderCell,
  BrassGridCell,
} from '../../../..';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnProps,
} from '@progress/kendo-react-excel-export';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  ApiGridResult,
  GridActions,
  GridColumnTypeEnum,
  GridItem,
} from '../../../../models';
import { emptyGuid } from '../../../../util';
import { MoneyService, DateTimeService } from '../../../../services';
import {
  BrassEditCell,
  BrassDeleteCell,
  BrassCommandCell,
  BrassGridFooterCellProps,
} from '../cells';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { groupBy, process, GroupDescriptor } from '@progress/kendo-data-query';

interface CellRenderProps {
  originalProps: GridCellProps;
  td: ReactElement<HTMLTableCellElement>;
  enterEdit: (
    dataItem: GridItem,
    field: string | undefined,
    props: BrassCardTreeListGridProps,
  ) => void;
  editField: string | undefined;
  gridProps: BrassCardTreeListGridProps;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: ReactElement<HTMLTableRowElement>;
  exitEdit: (props: BrassCardTreeListGridProps) => void;
  editField: string | undefined;
  gridProps: BrassCardTreeListGridProps;
}

const enterEdit = (dataItem: GridItem, props: BrassCardTreeListGridProps) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const newData = props.gridState.dataResult.data.map((item: GridItem) => ({
      ...item,
      inEdit: item.id === dataItem.id ? true : undefined,
    }));
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  }
};

const cancelEdit = (dataItem: GridItem, props: BrassCardTreeListGridProps) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const originalItem = props.gridState.recordsList!.records!.find(
      (item: GridItem) => item.id === dataItem.id,
    );
    const newData = props.gridState.dataResult.data.map((item: GridItem) =>
      item.id === originalItem!.id ? { ...originalItem, inEdit: undefined } : item,
    );

    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  }
};

const exitEdit = (props: BrassCardTreeListGridProps) => {
  if (props.gridState.dataResult && props.gridState.dataResult.data) {
    const updatedItem = props.gridState.dataResult.data.find(
      (item: GridItem) => item.inEdit,
    )!;
    // props.gridCallbacks.onUpdate(updatedItem);

    const newData = props.gridState.dataResult.data.map((item: GridItem) => ({
      ...item,
      inEdit: undefined,
    }));
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
    props.gridDispatch({
      type: GridActions.UPDATE_EDIT_FIELD,
      payload: undefined,
    });
  }
};

const pageData: any = {
  info: true,
  buttonCount: 5,
  type: 'numeric',
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 3000, 5000, 10000],
  previousNext: true,
  group: [],
};

const selectColumn = (props: BrassCardTreeListGridProps, columns: ReactNode[]) => {
  if (props.selectable && props.selectable.enabled) {
    columns.unshift(
      <GridColumn
        width='50px'
        key='selected'
        field='selected'
        groupable={false}
        filterable={false}
        className={style['brass-grid-column']}
        headerCell={
          props.onHeaderSelectionChange ? onHeaderSelectionChange() : HideHeaderCell
        }
      />,
    );
  }
};

const commandColumn = (
  props: BrassCardTreeListGridProps,
  baseColumns: ReactNode[],
) => {
  if (
    props.permissions!.update &&
    baseColumns.map((col: any) => col.props).find((col: any) => col.editor)
  ) {
    props.treeListColumns
      ? baseColumns.push({
          cell: editCell(),
          width: 100,
          field: 'actions',
          title: 'Actions',
        })
      : baseColumns.unshift(
          <GridColumn
            key='inline-command'
            field='inlineCommand'
            groupable={false}
            width='120px'
            cell={(_props) =>
              BrassCommandCell({
                gridProps: props,
                gridCellProps: _props,
                onUpdateCallback: async (
                  dataItem: GridItem,
                  props: BrassCardTreeListGridProps,
                ): Promise<void> => {
                  if (
                    props.gridState.dataResult &&
                    props.gridState.dataResult.data
                  ) {
                    const updatedItem = props.gridState.dataResult.data.find(
                      (item: GridItem) => item.inEdit,
                    )!;
                    await props.gridCallbacks.onUpdate(updatedItem);
                    cancelEdit(dataItem, props);
                  }
                },
                onCancelCallback: cancelEdit,
                onEditCallback: enterEdit,
                onDeleteCallback: async (
                  dataItem: GridItem,
                  props: BrassCardTreeListGridProps,
                ): Promise<void> => {
                  if (
                    props.gridState.dataResult &&
                    props.gridState.dataResult.data
                  ) {
                    const updatedItem = props.gridState.dataResult.data.find(
                      (item: GridItem) => item.inEdit,
                    )!;
                    await props.gridCallbacks.onDelete(updatedItem);
                    cancelEdit(dataItem, props);
                  }
                },
              })
            }
            filterable={false}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const editCell = (): any =>
  class extends Component {
    public render(): any {
      return (
        <td>
          teste
          {/* <Button>
            teste
          </Button> */}
        </td>
      );
    }
  };

const editColumn = (props: BrassCardTreeListGridProps, baseColumns: ReactNode[]) => {
  if (
    props.permissions!.update &&
    !baseColumns.map((col: any) => col.props).find((col: any) => col && col.editor)
  ) {
    props.treeListColumns
      ? baseColumns.push({
          cell: editCell(),
          width: 120,
          field: 'actions',
          title: 'Actions',
        })
      : baseColumns.unshift(
          <GridColumn
            resizable={false}
            key='edit'
            field='edit'
            groupable={false}
            width='50px'
            cell={(_props) => BrassEditCell(_props, props.gridCallbacks.onEdit)}
            filterable={false}
            headerCell={HideHeaderCell}
          />,
          0,
        );
  }
};

const deleteColumn = (
  props: BrassCardTreeListGridProps,
  baseColumns: ReactNode[],
) => {
  if (props.permissions!.delete) {
    baseColumns.push(
      <GridColumn
        resizable={false}
        key='delete'
        field='delete'
        width='50px'
        groupable={false}
        cell={(_props) =>
          BrassDeleteCell(
            _props,
            props.gridCallbacks.onDelete,
            props.gridState.recordsList,
            props.gridDispatch,
          )
        }
        filterable={false}
        headerCell={HideHeaderCell}
      />,
    );
  }
};

const onSelectionChange = (
  event: GridSelectionChangeEvent,
  props: BrassCardTreeListGridProps,
  fieldIdName?: string,
  selectable?: GridSelectableSettings,
) => {
  const recordId = !!fieldIdName ? event.dataItem[fieldIdName] : event.dataItem.id;
  const recordSelected = props.gridState.selectedRecords.indexOf(recordId) !== -1;

  let _selectedRecordsId = [];
  if (selectable?.mode === 'multiple') {
    _selectedRecordsId = [...props.gridState.selectedRecords];
  }

  // Atualiza o ESTADO local com os ID selecionados pelo usuário
  if (recordSelected) {
    _selectedRecordsId = _selectedRecordsId.filter((f) => f !== recordId);
  } else {
    _selectedRecordsId.push(recordId);
  }
  props.gridDispatch({
    type: GridActions.UPDATE_SELECTED_RECORDS,
    payload: _selectedRecordsId,
  });

  // ----------------------------------------------------------------------
  // Marca o Registro como SELECIONADO/NÃO-SELECIONADO
  if (
    props.gridState.recordsList.records &&
    props.gridState.recordsList.records.length > 0
  ) {
    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: {
        ...props.gridState.recordsList,
        records: props.gridState.recordsList.records.map((record: any) => {
          if ((!!fieldIdName ? record[fieldIdName] : record.id) === recordId) {
            record[event.selectedField] = !recordSelected;
          } else if (selectable.mode === 'single') {
            record[event.selectedField] = false;
          }
          return record;
        }),
      },
    });
  }

  props.onSelectionChange && props.onSelectionChange(_selectedRecordsId, event);
};

const onGroupChange = (
  event: GridGroupChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  if (event.group.length <= 2) {
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_STATE,
      payload: {
        ...props.gridState.dataState,
        group: event.group,
      },
    });
    props.gridDispatch({
      type: GridActions.UPDATE_GRID_CONFIG,
      payload: {
        ...props.gridState.gridConfig,
        group: event.group,
      },
    });

    props.gridDispatch({
      type: GridActions.UPDATE_GROUP,
      payload: event.group,
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: setExpandedState({
        data: processWithGroups(props.gridState.recordsList.records!, event.group),
        collapsedIds: props.gridState.collapsedState,
      }),
    });
  }
};

const onExpandChange = (
  event: GridExpandChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  const item = event.dataItem;

  if (item.groupId) {
    const newCollapsedIds = !event.value
      ? [...props.gridState.collapsedState, item.groupId]
      : props.gridState.collapsedState.filter((groupId) => groupId !== item.groupId);
    props.gridDispatch({
      type: GridActions.UPDATE_COLLAPSED_STATE,
      payload: newCollapsedIds,
    });
  }
};

const onHeaderSelectionChange = (
  event: GridHeaderSelectionChangeEvent,
  props: BrassCardTreeListGridProps,
  fieldIdName?: string,
) => {
  if (!props) return;

  let _selectedRecordsId = [];
  if (
    props.gridState.recordsList.records &&
    props.gridState.recordsList.records.length > 0
  ) {
    const allRecordsSelected =
      props.gridState.selectedRecords.length ===
      props.gridState.recordsList.records.length;

    if (!allRecordsSelected) {
      _selectedRecordsId = props.gridState.recordsList.records.map((r: any) =>
        fieldIdName ? r[fieldIdName] : r.id,
      );
    }

    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: {
        ...props.gridState.recordsList,
        records: props.gridState.recordsList.records.map((record: any) => {
          record[event.selectedField] = !allRecordsSelected;
          return record;
        }),
      },
    });
  }

  props.gridDispatch({
    type: GridActions.UPDATE_SELECTED_RECORDS,
    payload: _selectedRecordsId,
  });
  props.onHeaderSelectionChange &&
    props.onHeaderSelectionChange(_selectedRecordsId, event);
};

const exportPDF = (gridPDFExport: GridPDFExport | null) => {
  if (gridPDFExport !== null && gridPDFExport !== undefined) {
    gridPDFExport.save();
  }
};

const excelExport = (gridExcelExport: MutableRefObject<ExcelExport | null>) => {
  if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
    gridExcelExport.current.save();
  }
};

const onItemChange = (
  event: GridItemChangeEvent,
  props: BrassCardTreeListGridProps,
): void => {
  const { dataItem, field, value } = event;
  if (props.gridState.editField) {
    let field = event.field || '';
    event.dataItem[field] = event.value;
    const newData = props.gridState.dataResult.data.map((item: GridItem) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    props.gridDispatch({
      type: GridActions.UPDATE_DATA_RESULT,
      payload: {
        data: newData,
        total: (newData && newData.length) || 0,
      },
    });
  } else {
    const update =
      props.gridState.recordsList.records &&
      props.gridState.recordsList.records.map((record: any) => {
        if (!record.oldValues) {
          record.oldValues = record;
        }
        if (record.id === dataItem.id) {
          return { ...record, [field || 'undefined']: value };
        }
        return record;
      });

    props.gridDispatch({
      type: GridActions.UPDATE_RECORDS_LIST,
      payload: { ...update },
    });
  }
};

const processWithGroupsAggregate = (
  recordsList,
  gridConfig,
  gridDispatch,
  gridColumns,
) => {
  const groups = gridConfig.group;
  const aggregates = gridColumns!
    .filter((col) => col.props.aggregate)
    .map((col) => col.props)
    .map((col) => {
      return {
        field: col.aggregateField,
        aggregate: col.aggregate,
      };
    });

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...gridConfig,
      group: groups,
    },
  });

  const newDataState = process(recordsList, gridConfig);
  setGroupIds({
    data: newDataState.data,
    group: gridConfig.group,
  });
  return newDataState;
};

const getCells = (columns, cellProps) => {
  let cells = [];
  columns.forEach((column) => {
    if (column.aggregate && column.aggregateField && column.columnType) {
      const _sumValues =
        cellProps.dataItem.aggregates[column.aggregateField][column.aggregate];
      cells.push(
        <td style={{ fontSize: 18 }}>
          {column.columnType === GridColumnTypeEnum.MONEY
            ? MoneyService.formatDecimal(_sumValues ?? 0)
            : DateTimeService.getHoursFromMinutes(_sumValues)}
        </td>,
      );
    } else {
      cells.push(<td>&nbsp;</td>);
    }
  });
  return cells;
};

const cellRender = (tdElement, cellProps, props: BrassCardTreeListGridProps) => {
  if (
    cellProps.rowType === 'groupHeader' &&
    tdElement &&
    tdElement.props.role != 'presentation'
  ) {
    const columns = props.gridColumns;
    return (
      <>
        <td
          {...tdElement.props}
          colSpan={tdElement.props.colSpan - columns.length}
        ></td>
        {getCells(columns, cellProps)}
      </>
    );
  } else if (cellProps.rowType === 'data' && tdElement) {
    return (
      <BrassGridCell
        {...tdElement.props}
        textAlign='center'
        style={{ fontSize: 16 }}
      />
    );
  }
  return tdElement;
};

const getColumns = (props: BrassCardTreeListGridProps) => {
  const baseColumns = props.gridColumns!.map((columnProps: GridColumnProps) => {
    if (columnProps.aggregate) {
      return (
        <GridColumn
          {...columnProps}
          footerCell={(_props: BrassGridFooterCellProps) => {
            const { colSpan, style: _style } = _props;
            const recordsList = props.gridState.recordsList as ApiGridResult<T>;

            if (recordsList.records && recordsList.records.length === 0) {
              return <td colSpan={colSpan} style={_style}></td>;
            } else {
              const recordSum = recordsList.records?.map(
                (record: any) => record[columnProps.aggregateField] || 0,
              );
              const _sumValues =
                recordSum &&
                recordSum.length &&
                recordSum.reduce(
                  (previousValue: number, currentValue: number) =>
                    previousValue + currentValue,
                );
              const _formatedSum =
                columnProps.columnType === GridColumnTypeEnum.MONEY
                  ? MoneyService.formatDecimal(_sumValues ?? 0)
                  : DateTimeService.getHoursFromMinutes(_sumValues || 0);

              return (
                <td colSpan={colSpan} style={{ ..._style, textAlign: 'center' }}>
                  <span style={{ fontSize: '18px' }}>{_formatedSum}</span>
                </td>
              );
            }
          }}
        />
      );
    }
    return <GridColumn {...columnProps} />;
  });

  commandColumn(props, baseColumns);
  editColumn(props, baseColumns);
  selectColumn(props, baseColumns);
  deleteColumn(props, baseColumns);

  // Converte todas as colunas para o Objeto GridColumnProps
  let _columns: GridColumnProps[] = baseColumns.map(
    (col: any) => col as GridColumnProps,
  );

  if (!props.permissions!.update) {
    _columns = _columns.filter((col: GridColumnProps) => col.field !== 'edit');
  }
  if (!props.permissions!.delete) {
    _columns = _columns.filter((col: GridColumnProps) => col.field !== 'delete');
  }

  return _columns;
};

const getExcelExportColumns = (props: BrassCardTreeListGridProps) =>
  (props.excelExportColumns || (props.gridColumns as ExcelExportColumnProps[]))!.map(
    (columnProps: ExcelExportColumnProps) => <ExcelExportColumn {...columnProps} />,
  );

const getGridToolbar = (
  props: BrassCardTreeListGridProps,
  gridPDFExport?: GridPDFExport,
  gridExcelExport?: MutableRefObject<ExcelExport | null>,
): ReactNode => {
  const {
    permissions,
    gridCallbacks,
    toolbarBeforeActions,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarAfterActions,
    customFilterGridToolbar,
    gridState,
    gridDispatch,
    groupable,
    userHelp,
    translate,
  } = props;

  const getTitle = () => (
    <h2 className={style['brass-grid-title']}>
      {props.icon}
      {translate('gridTitle')}
    </h2>
  );

  const getInsertButton = () =>
    permissions?.create && (
      <BrassButtonAdd
        fillMode='outline'
        className={style['brass-button-insert']}
        onClick={gridCallbacks.onAdd}
        disabled={gridState.gridLoading}
      />
    );

  const getRefreshButton = () => (
    <BrassButtonRefresh
      fillMode='outline'
      showText={!gridState.showHelpPanel}
      className={style['brass-button-refresh']}
      onClick={() => {
        gridDispatch({
          type: GridActions.UPDATE_GRID_LOADING,
          payload: true,
        });
        gridCallbacks.onRefresh();
      }}
      disabled={gridState.gridLoading}
    />
  );

  const getExportButton = () =>
    permissions?.export && (
      <DropDownButton
        icon='printer'
        items={['PDF', 'Excel']}
        className={style['brass-button-export']}
        onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
          itemClickEvent.itemIndex === 0
            ? exportPDF(gridPDFExport)
            : excelExport(gridExcelExport)
        }
        disabled={gridState.gridLoading}
      />
    );

  const getGroupableButton = () =>
    groupable && (
      <Button
        togglable
        title='Agrupar Por'
        icon='aggregate-fields'
        className={style['brass-button-groupable']}
        onClick={() => {
          if (!gridState.gridConfig.group) {
            gridDispatch({
              type: GridActions.UPDATE_GRID_CONFIG,
              payload: {
                ...gridState.gridConfig,
                group: [],
              },
            });
          } else {
            gridDispatch({
              type: GridActions.UPDATE_GRID_CONFIG,
              payload: {
                ...gridState.gridConfig,
                group: undefined,
              },
            });
          }
        }}
      />
    );

  const getCustomFilterGridToolbar = () =>
    customFilterGridToolbar && (
      <BrassButtonFilter
        togglable
        fillMode='flat'
        key='buttonFilter'
        onClick={() =>
          gridDispatch({
            type: GridActions.UPDATE_SHOW_FILTER_PANEL,
            payload: !gridState.showFilterPanel,
          })
        }
      />
    );

  const getHelpUser = () =>
    userHelp && (
      <BrassButtonHelp
        togglable
        fillMode='outline'
        showText={false}
        onClick={() =>
          gridDispatch({
            type: GridActions.UPDATE_SHOW_HELP_PANEL,
            payload: !gridState.showHelpPanel,
          })
        }
      />
    );

  return (
    <GridToolbar>
      {toolbarBeforeTitle}
      {getTitle()}
      {toolbarAfterTitle}
      <ToolbarSpacer />

      {toolbarBeforeActions}
      {getInsertButton()}
      {getRefreshButton()}
      {getExportButton()}
      {getGroupableButton()}
      {getCustomFilterGridToolbar()}
      {toolbarAfterActions}

      {getHelpUser()}
    </GridToolbar>
  );
};

const getHelpPanel = (props: BrassCardTreeListGridProps): ReactNode =>
  props.gridState.showHelpPanel && (
    <div className={style['brass-grid-user-help']}>{props.userHelp}</div>
  );

const getExcelExport = (
  props: BrassCardTreeListGridProps,
  preColunsExportExcel: ReactNode[],
  gridExcelExport: MutableRefObject<ExcelExport | null>,
): ReactNode => (
  <ExcelExport
    ref={gridExcelExport}
    data={props.gridState.recordsList.records}
    fileName={`${props.translate('gridTitle')} - ${new Date().toDateString()}`}
  >
    <Grid data={props.gridState.recordsList.records} style={{ display: 'none' }}>
      {preColunsExportExcel}
    </Grid>
  </ExcelExport>
);

const getPDFExport = (
  props: BrassCardTreeListGridProps,
  columns: GridColumnProps[],
  ref: LegacyRef<GridPDFExport>,
): ReactNode => (
  <GridPDFExport
    ref={ref}
    margin='1cm'
    paperSize='A4'
    fileName={`${props.translate('gridTitle')}${
      props.complementFileNameExport
    } - ${new Date().toDateString()}`}
  >
    <Grid data={props.gridState.recordsList.records}>{columns}</Grid>
  </GridPDFExport>
);

const getGridBrassLoader = (): ReactNode => {
  return (
    <BrassLoader
      useLoadingMask
      style={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};

const onDataStateChange = (
  event: GridDataStateChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      group: event.dataState.group,
    },
  });
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_STATE,
    payload: processWithGroupsAggregate(
      props.gridState.recordsList,
      event.dataState,
      props.gridDispatch,
      props.gridColumns,
    ),
  });
};

const onSortChange = (
  event: GridSortChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      sort: event.sort,
    },
  });
};

const onPageChange = (
  event: GridPageChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  props.gridDispatch({
    type: GridActions.UPDATE_DATA_STATE,
    payload: {
      ...props.gridState.dataState,
      take: event.page.take,
    },
  });
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_LOADING,
    payload: true,
  });

  props.gridDispatch({
    type: GridActions.UPDATE_GRID_CONFIG,
    payload: {
      ...props.gridState.gridConfig,
      skip: event.page.skip,
      take: event.page.take,
    },
  });
};

const onFilterChange = (
  event: GridFilterChangeEvent,
  props: BrassCardTreeListGridProps,
) => {
  if (!event.filter) {
    props.gridDispatch({
      type: GridActions.UPDATE_GRID_CONFIG,
      payload: {
        ...props.gridState.gridConfig,
        filter: event.filter,
      },
    });
  }
  props.gridDispatch({
    type: GridActions.UPDATE_GRID_FILTER,
    payload: event.filter,
  });
};

/** Largura do Menu quando Expandido */
const MENU_EXPANDED_WIDTH = 240;

/** Largura do Menu quando Minimizado */
const MENU_COLLAPSED_WIDTH = 50;

const getWindowWidth = (
  menuExpanded: boolean,
  reduceWindowWidth: number = 0,
  increaseWindowWidth: number = 0,
) =>
  menuExpanded
    ? `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_EXPANDED_WIDTH +
        increaseWindowWidth
      }px`
    : `${
        window.innerWidth -
        reduceWindowWidth -
        MENU_COLLAPSED_WIDTH +
        increaseWindowWidth
      }px`;

const getUpsertWindow = (props: BrassCardTreeListGridProps) => {
  const { gridState, gridDispatch, gridCallbacks, upsertWindow, translate } = props;
  const { isFullscreen, width, title, WindowContent } = upsertWindow!;
  const isUpdating = gridState.recordId !== emptyGuid();
  const onSaveClose = (): void => {
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: undefined,
    });
    gridCallbacks.onRefresh();
  };
  const onCancelClose = (): void => {
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: undefined,
    });
  };
  return (
    gridState.recordId && (
      <BrassWindow
        title={
          title
            ? title
            : translate(isUpdating ? 'formEdit' : 'formAdd', [translate('gridItem')])
        }
        onClose={() =>
          gridDispatch({
            type: GridActions.UPDATE_RECORD_ID,
            payload: undefined,
          })
        }
        stage={isFullscreen ? 'FULLSCREEN' : undefined}
        maximizeButton={() => null}
        minimizeButton={() => null}
        initialLeft={width ? window.innerWidth - width : window.innerWidth}
        initialTop={0}
        initialWidth={width}
        initialHeight={window.innerHeight}
      >
        <WindowContent
          recordId={gridState.recordId}
          onSaveClose={onSaveClose}
          onCancelClose={onCancelClose}
        />
      </BrassWindow>
    )
  );
};

const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
  const newDataState = groupBy(data, group);
  setGroupIds({ data: newDataState, group });

  return newDataState;
};

export {
  pageData as pageDataCard,
  exportPDF as exportPDFCard,
  excelExport as excelExportCard,
  selectColumn as selectColumnCard,
  editColumn as editColumnCard,
  deleteColumn as deleteColumnCard,
  onItemChange as onItemChangeCard,
  onGroupChange as onGroupChangeCard,
  onExpandChange as onExpandChangeCard,
  onSelectionChange as onSelectionChangeCard,
  onHeaderSelectionChange as onHeaderSelectionChangeCard,
  getColumns as getColumnsCard,
  getGridToolbar as getGridToolbarCard,
  getHelpPanel as getHelpPanelCard,
  getExcelExport as getExcelExportCard,
  getExcelExportColumns as getExcelExportColumnsCard,
  getPDFExport as getPDFExportCard,
  getGridBrassLoader as getGridBrassLoaderCard,
  onDataStateChange as onDataStateChangeCard,
  onSortChange as onSortChangeCard,
  onPageChange as onPageChangeCard,
  onFilterChange as onFilterChangeCard,
  getWindowWidth as getWindowWidthCard,
  getUpsertWindow as getUpsertWindowCard,
  cellRender as cellRenderCard,
};
