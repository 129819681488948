import { GridColumn } from '@progress/kendo-react-grid';
import {
  Fragment,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  BrassButton,
  BrassGridCell,
  BrassGridCellProps,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassIconNotification,
  BrassTreeListGrid
} from '../../../../../components';
import {
  Account,
  ApiGridResult,
  ApiResult,
  Breadcrumb,
  Notification,
  NotificationStyles,
} from '../../../../../models';
import { DateTimeService } from '../../../../../services';
import { breadcrumbActions, notificationActions } from '../../../../../store';
import { emptyGuid } from '../../../../../util';
import { UserNotificationV0 } from '../../../models';
import { UserNotificationService } from '../../../services';
import { translate } from './MyNotificationsGrid.translations';
import { ReplyNotificationWindow } from './reply-notification-window/ReplyNotificationWindow';

const NotificationsGrid: FunctionComponent = memo(() => {
  const dispatch = useDispatch();

  const [gridLoading, setGridLoading] = useState(true);
  const [userNotificationList, setUserNotificationList] = useState<
    ApiGridResult<UserNotificationV0>
  >({});
  const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>(
    BrassGridConfigDefault,
  );
  const [userNotificationId, setUserNotificationId] = useState<string | undefined>(
    undefined,
  );

  dispatch(
    breadcrumbActions.setBreadcrumb([
      new Breadcrumb({
        route: '/home/my-notifications',
        title: translate('myNotifications'),
      }),
    ]),
  );

  const loadGridData = (): void => {
    if (Account.isValidAccountId()) {
      setGridLoading(true);
      UserNotificationService.getUserNotifications(gridConfig).then(
        (apiResult: ApiResult<ApiGridResult<UserNotificationV0>>) => {
          if (apiResult.success) {
            apiResult.data.records?.map((rec: UserNotificationV0): any => {
              if (rec.messageDate) {
                rec.messageDateFormated = DateTimeService.getFormattedDate(
                  new Date(rec.messageDate).toISOString(),
                );
              }
            });
          }
          setUserNotificationList(apiResult.data);
          setGridLoading(false);
        },
      );
    }
  };
  useEffect(loadGridData, [gridConfig]);

  const onAddCallback = (): void => setUserNotificationId(emptyGuid());

  const onEditCallback = (userNotification: UserNotificationV0): void =>
    setUserNotificationId(userNotification.id);

  const onDeleteCallback = async (
    userNotification: UserNotificationV0,
  ): Promise<void> => {
    try {
      const apiResult: ApiResult =
        await UserNotificationService.deleteUserNotification(userNotification.id);
      if (apiResult.success) {
        loadGridData();
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnDelete'),
            }),
          ),
        );
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: translate('errorOnDelete'),
            }),
          ),
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        notificationActions.showNotification(
          new Notification({
            style: NotificationStyles.ERROR,
            content: translate('errorOnDelete'),
          }),
        ),
      );
    }
  };

  const columns = [
    <GridColumn
      filterable={false}
      key='messageDateFormated'
      field='messageDateFormated'
      title={translate('date')}
      columnMenu={BrassGridColumnMenu}
      width='100'
    />,
    <GridColumn
      key='origin'
      field='origin'
      title={translate('origin')}
      columnMenu={BrassGridColumnMenu}
      width={gridConfig.group?.length ? '300' : 'auto'}
    />,
    <GridColumn
      key='message'
      field='message'
      title={translate('description')}
      columnMenu={BrassGridColumnMenu}
      width={gridConfig.group?.length ? '300' : 'auto'}
    />,
    <GridColumn
      key='actions'
      field='actions'
      title={translate('actions')}
      width='180'
      groupable={false}
      cell={(_props: BrassGridCellProps) => {
        if (_props.rowType === 'groupHeader') {
          return <Fragment />;
        }
        const record: UserNotificationV0 = _props.dataItem;
        const env: string = '';
        return (
          <BrassGridCell {..._props} textAlign='center'>
            <BrassButton
              themeColor='primary'
              onClick={() => setUserNotificationId(record.id)}
            >
              {translate('answer')}
            </BrassButton>
            {record.origin === 'CONNECT_RHT' && (
              <BrassButton
                themeColor='primary'
                onClick={() => {
                  window.location.href = `
                    https://connect${env}.bdbsystem.com.br/home/connect/work-hours-report/register-activity
                  `;
                }}
              >
                {translate('correctWhr')}
              </BrassButton>
            )}
          </BrassGridCell>
        );
      }}
    />,
  ];

  return (
    <Fragment>
      <BrassTreeListGrid
        groupable
        allowDelete
        columns={columns}
        gridData={userNotificationList}
        gridLoading={gridLoading}
        gridTitle={translate('myNotifications')}
        onRefreshCallback={loadGridData}
        gridIcon={<BrassIconNotification />}
        gridConfig={gridConfig}
        setGridLoading={setGridLoading}
        updateGridConfig={setGridConfig}
        updateGridData={setUserNotificationList}
        onAddCallback={onAddCallback}
        onEditCallback={onEditCallback}
        onDeleteCallback={onDeleteCallback}
      />
      {userNotificationId && (
        <ReplyNotificationWindow
          entityId={userNotificationId}
          onCancelClose={() => setUserNotificationId(undefined)}
          onSaveClose={() => {
            setUserNotificationId(undefined);
            loadGridData();
          }}
        />
      )}
    </Fragment>
  );
});

export { NotificationsGrid };

