import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { AreaSubmodule } from './Area.submodule.enum';

enum AreaPermissionsV1 {
  /// <summary>
  ///  [(FE) home/connect/physical-area -> home/connect/area/physical]
  /// </summary>
  AREA_PHYSICAL_READ = PermissionModule.AREA + AreaSubmodule.PHYSICAL + Action.READ,
  AREA_PHYSICAL_CREATE = PermissionModule.AREA +
    AreaSubmodule.PHYSICAL +
    Action.CREATE,
  AREA_PHYSICAL_UPDATE = PermissionModule.AREA +
    AreaSubmodule.PHYSICAL +
    Action.UPDATE,
  AREA_PHYSICAL_DELETE = PermissionModule.AREA +
    AreaSubmodule.PHYSICAL +
    Action.DELETE,
  AREA_PHYSICAL_EXPORT = PermissionModule.AREA +
    AreaSubmodule.PHYSICAL +
    Action.EXPORT,
  /// <summary>
  ///  [(FE) home/connect/institutional-area -> home/connect/area/institutional]
  /// </summary>
  AREA_INSTITUTIONAL_READ = PermissionModule.AREA +
    AreaSubmodule.INSTITUTIONAL +
    Action.READ,
  AREA_INSTITUTIONAL_CREATE = PermissionModule.AREA +
    AreaSubmodule.INSTITUTIONAL +
    Action.CREATE,
  AREA_INSTITUTIONAL_UPDATE = PermissionModule.AREA +
    AreaSubmodule.INSTITUTIONAL +
    Action.UPDATE,
  AREA_INSTITUTIONAL_DELETE = PermissionModule.AREA +
    AreaSubmodule.INSTITUTIONAL +
    Action.DELETE,
  AREA_INSTITUTIONAL_EXPORT = PermissionModule.AREA +
    AreaSubmodule.INSTITUTIONAL +
    Action.EXPORT,
}

export { AreaPermissionsV1 };
