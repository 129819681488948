import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ApiResult, DropDownData, DropDownVirtualData } from '../../../../models';
import {
  BrassMultiSelectField,
  BrassMultiSelectFieldProps,
} from '../../../../components';
import { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { FlagRelationalService } from '../../../../services/FlagRelational.service';
import { useDebounce } from '../../../../hooks';

interface FlagsFieldProps extends BrassMultiSelectFieldProps {
  customLabel?: string;
  onChangeValue: (event: MultiSelectChangeEvent) => void;
}

const FlagsField: FunctionComponent<FlagsFieldProps> = (props: FlagsFieldProps) => {
  const { onChangeValue, customLabel } = props;

  const [flags, setFlags] = useState<DropDownVirtualData>({
    subsetData: [],
    skip: 0,
    pageSize: 0,
    total: 0,
  });
  const [flagsFiltered, setFlagsFiltered] = useState<DropDownVirtualData>({
    subsetData: [],
    skip: 0,
    pageSize: 0,
    total: 0,
  });
  const [flagsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');

  const debouncedFilter: any = useDebounce(search, 700);

  const handeleFetchFlag = useCallback(() => {
    setLoading(true);
    FlagRelationalService.getFilteredFlags(debouncedFilter)
      .then((apiResult: ApiResult<DropDownData[]>) => {
        if (apiResult.success) {
          setFlags({
            subsetData: apiResult.data.slice(),
            skip: apiResult.data.length,
            pageSize: 0,
            total: apiResult.data.length,
          });
        }
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [debouncedFilter]);

  useEffect(() => {
    handeleFetchFlag();
  }, [handeleFetchFlag]);

  const handleFilter = (event: MultiSelectChangeEvent) => {
    setSearch(event?.target?.state?.text as string);
  };

  return (
    <BrassMultiSelectField
      {...props}
      options={flags.subsetData}
      label={customLabel ? customLabel : 'Flags'}
      textField='text'
      value={flagsIds}
      dataItemKey='value'
      onFilterChange={handleFilter}
      filterable={true}
      ignoreRule={true}
      onChange={(event: MultiSelectChangeEvent): void => {
        onChangeValue(event);
      }}
      virtual={{
        total: flags.total,
        pageSize: flags.pageSize,
        skip: flags.skip,
      }}
      popupSettings={{
        height: '210px',
      }}
    />
  );
};

export { FlagsField };
export type { FlagsFieldProps };
