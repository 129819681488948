import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconEducation: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_227_24)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.875 17.3867C16.4667 17.5048 16.0333 17.5048 15.625 17.3867V15.8829L16.25 15.2579L16.875 15.8829V17.3867ZM19.0957 5.4105L11.4638 1.59417C11.008 1.37145 10.5074 1.25568 10.0001 1.25568C9.4928 1.25568 8.99213 1.37144 8.53635 1.59417L0.903851 5.4105C0.631651 5.54592 0.402817 5.75477 0.243101 6.01343C0.0834007 6.2721 -0.000782591 6.57027 6.74088e-05 6.87423C6.74088e-05 7.49735 0.346934 8.05795 0.904434 8.33738L3.1251 9.448V13.1242C3.1251 15.2267 6.1451 16.8742 10.0001 16.8742C11.1582 16.8742 12.2989 16.721 13.2989 16.4316L12.9514 15.2304C12.0627 15.4879 11.0421 15.6242 10.0002 15.6242C6.68523 15.6242 4.37523 14.3061 4.37523 13.1242V10.073L8.53657 12.1543C8.99138 12.3806 9.4923 12.4987 10.0003 12.4993C10.5059 12.4993 11.0116 12.3793 11.464 12.1537L14.0297 10.8705L13.471 9.75245L10.9047 11.0356C10.3342 11.315 9.66643 11.315 9.09598 11.0356L1.46465 7.21925C1.40013 7.18761 1.3459 7.13852 1.30795 7.07752C1.27006 7.01652 1.25007 6.94607 1.25026 6.87427C1.25026 6.72674 1.33217 6.59425 1.464 6.52864L9.09583 2.7123V2.71237C9.66633 2.43293 10.334 2.43293 10.9045 2.71237L18.5358 6.5287V6.52864C18.6004 6.56034 18.6548 6.60956 18.6927 6.67069C18.7306 6.73176 18.7505 6.80233 18.7502 6.87428C18.7504 6.94602 18.7305 7.01633 18.6927 7.07734C18.6549 7.13835 18.6008 7.1875 18.5365 7.21927L16.2076 8.38424L10.2065 6.28424L9.79398 7.46425L15.6253 9.50558V14.1156L14.3753 15.3656V18.1981L14.7209 18.3706C14.7984 18.4094 15.4997 18.7494 16.2503 18.7494C17.001 18.7494 17.7022 18.4094 17.7797 18.3706L18.1253 18.1981V15.3656L16.8753 14.1156V9.44808L19.0965 8.33747C19.3685 8.20218 19.5973 7.99352 19.757 7.73505C19.9167 7.47665 20.0009 7.17874 20.0003 6.87497C20.0003 6.2512 19.6534 5.68994 19.0959 5.41059L19.0957 5.4105Z'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_227_24'>
        <rect width='20' height='20' fill={props.fill} />
      </clipPath>
    </defs>
  </svg>
);

export { SVGIconEducation };
