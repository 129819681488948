import React, { FunctionComponent, useContext } from 'react';
import style from './MainViewer.module.scss';
import {
  BrassImageViewer,
  BrassLoader,
  BrassPdfViewer,
  BrassPointCloudViewer,
} from '../../../../../components';
import { ViewerContextV1 } from '../../../../../store';
import { NavigatorFileType } from '../../../../../models';
import { EmptyViewer } from './empty-viewer/EmptyViewer';

const MainViewer: FunctionComponent = () => {
  const { showBottomPanel, navigatorFile, loadingViewer } =
    useContext(ViewerContextV1);

  const heightStyle = showBottomPanel
    ? style['document-details-viewer-on']
    : style['document-viewer'];

  if (loadingViewer) {
    return <BrassLoader useLoadingMask />;
  }

  if (!navigatorFile.document.conversionFinished) {
    return (
      <div className={style['progress-viewer']}>
        <h2>{navigatorFile.document.conversionProgress}%</h2>
        <BrassLoader type='converging-spinner' />
      </div>
    );
  }

  switch (navigatorFile.document.type) {
    case NavigatorFileType.POINTCLOUD:
      return (
        <BrassPointCloudViewer
          url={navigatorFile.document.viewer}
          className={heightStyle}
        />
      );

    case NavigatorFileType.PDF:
      return (
        <BrassPdfViewer
          url={navigatorFile.document.viewer}
          className={heightStyle}
        />
      );

    case NavigatorFileType.IMG:
      return (
        <BrassImageViewer
          url={navigatorFile.document.viewer}
          className={heightStyle}
        />
      );

    case NavigatorFileType.VIDEO:
      return (
        <iframe
          height='850px'
          width='100%'
          src={navigatorFile.document.viewer}
          className={heightStyle}
          title={navigatorFile.name}
        />
      );

    default:
      return <EmptyViewer className={heightStyle} />;
  }
};

export { MainViewer };
