import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    showPassword: 'Exibir senha',
    validationMessage: 'Por favor introduce una contraseña válida',
  },
  enUS: {
    showPassword: 'Show Password',
    validationMessage: 'Please enter a valid password',
  },
  ptBR: {
    showPassword: 'Exibir senha',
    validationMessage: 'Por favor, insira uma senha válida',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
