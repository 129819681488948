import { I18nService } from '../../../../../../services/';
import { II18n } from '../../../../../../models/i18n';

const translations: II18n = {
  es: {
    closeTitle: 'Fecha',
  },
  enUS: {
    closeTitle: 'Close',
  },
  ptBR: {
    closeTitle: 'Fechar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate, translations };
