import { ApiResult, DropDownData, Endpoints } from '../models';
import { ApiService } from './api.service';
import { BaseService } from './base.service';

class FlagRelationalService extends BaseService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);
  private static apiReport: ApiService = new ApiService(Endpoints.DRIVE);

  public static cacheFlagRelationalDropDownData: DropDownData[] = [];

  public static async getFilteredFlags(
    search: string,
  ): Promise<ApiResult<DropDownData[]>> {
    return await this.api.post<DropDownData[]>(`/connect/v1/flag-Relational/dropdown`, {
      search,
    });
  }

  public static async getProjectsDropDown(): Promise<ApiResult<DropDownData[]>> {
    const apiResult: ApiResult<DropDownData[]> = await FlagRelationalService.api.get<
      DropDownData[]
    >(`/connect/v1/layer/dropdown`);

    if (!apiResult.errorMessage) {
      FlagRelationalService.cacheFlagRelationalDropDownData = apiResult.data;
    }
    return apiResult;
  }
}

export { FlagRelationalService };
