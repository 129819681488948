import { BrassGridConfigProps } from '../../../components';
import { ApiGridResult, ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { UserNotificationResponse, UserNotificationV0 } from '../models';

class UserNotificationService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getUserNotifications(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<UserNotificationV0>>> {
    return await this.api.post<ApiGridResult<UserNotificationV0>>(
      `/connect/v1/notifications/list`,
      gridConfig,
    );
  }

  public static async getUserNotification(
    userNotificationId: string,
  ): Promise<ApiResult<UserNotificationV0>> {
    return await this.api.get<UserNotificationV0>(
      `/connect/v1/notifications/info/${userNotificationId}`,
    );
  }

  public static async createUserNotification(
    userNotificationResponse: UserNotificationResponse,
  ): Promise<ApiResult> {
    return await this.api.post('/connect/v1/notifications', userNotificationResponse);
  }

  public static async deleteUserNotification(
    userNotificationId: string,
  ): Promise<ApiResult> {
    return await this.api.delete(`/connect/v1/notifications/${userNotificationId}`);
  }
}

export { UserNotificationService };
