import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */
const SVGIconArea: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 22 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M21.8295 6.9981C21.3247 5.84101 19.9517 5.3015 18.7634 5.79307C17.7964 6.19303 17.2222 7.17171 17.3596 8.18574L14.2403 9.45542C13.6974 8.52436 12.7497 7.88364 11.6641 7.71352V4.4531C12.9066 4.14076 13.6537 2.9069 13.3329 1.69718C13.0121 0.487495 11.7448 -0.239976 10.5023 0.0723893C9.2598 0.3847 8.51265 1.6186 8.83345 2.82828C9.04476 3.62512 9.68387 4.24737 10.5023 4.4531V7.7107C9.42581 7.88032 8.48497 8.5128 7.94062 9.43279L4.80968 8.15747C5.05525 6.87554 4.18694 5.6425 2.87026 5.40341C1.55355 5.16432 0.287117 6.00974 0.0415482 7.29166C-0.204039 8.57361 0.664271 9.80662 1.98097 10.0457C2.85916 10.2052 3.75627 9.88167 4.31592 9.20373L7.52528 10.4762C7.31481 11.466 7.55181 12.4962 8.17589 13.304L5.681 15.7641C4.57727 15.1269 3.15187 15.4812 2.49725 16.5559C1.84265 17.6305 2.20674 19.0182 3.31049 19.6556C4.41423 20.293 5.83963 19.9385 6.49423 18.8639C6.92762 18.1524 6.92762 17.2673 6.49423 16.5559L9.01526 14.1014C10.2586 14.9423 11.9078 14.9423 13.1511 14.1014L15.6722 16.5559C15.1391 17.4517 15.2814 18.583 16.0207 19.3272C16.9222 20.2163 18.3933 20.2253 19.3065 19.3476C20.2198 18.4699 20.2293 17.0376 19.3277 16.1485C18.5777 15.4088 17.4064 15.2619 16.4884 15.7925L13.9789 13.3238C14.4558 12.7121 14.7139 11.9653 14.7137 11.1973C14.7141 10.9666 14.6908 10.7363 14.644 10.5102L17.8388 9.23202C18.4725 10.0513 19.593 10.353 20.569 9.96724C21.7475 9.47502 22.3072 8.15686 21.8295 6.9981ZM11.1006 9.06235C11.662 9.06235 12.1172 9.50546 12.1172 10.0521C12.1172 10.5987 11.662 11.0418 11.1006 11.0418C10.5392 11.0418 10.0841 10.5987 10.0841 10.0521C10.0841 9.50546 10.5392 9.06235 11.1006 9.06235ZM13.1337 13.0212H9.06753V12.2521C9.07139 12.0972 9.14591 11.9521 9.27084 11.8562C9.57225 11.6454 9.9081 11.4857 10.2641 11.3839C10.5372 11.3076 10.8194 11.2667 11.1035 11.2623C11.3879 11.2632 11.6706 11.3041 11.9429 11.3839C12.3031 11.475 12.6409 11.6356 12.9362 11.8562C13.0611 11.9521 13.1356 12.0972 13.1395 12.2521L13.1337 13.0212Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconArea };
