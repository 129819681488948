import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { translate } from './UnauthorizedNotification.translations';
import {
  Notification,
  NotificationStyles,
} from '../../../../../../models/notification';
import { notificationActions } from '../../../../../../store/notification';

const UnauthorizedNotification: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const _unauthorizedStatus = (event: any): void => {
      if (event.data && event.data.apiResult) {
        if (event.data.apiResult.status403) {
          dispatch(
            notificationActions.showNotification(
              new Notification({
                timeout: 1000 * 5,
                style: NotificationStyles.ERROR,
                content: translate('unauthorized'),
              }),
            ),
          );
        }
      }
    };

    window.addEventListener('message', _unauthorizedStatus, false);

    return () => {
      window.removeEventListener('message', _unauthorizedStatus, false);
    };
  }, []);

  return <Fragment />;
};

export { UnauthorizedNotification };
