import React, {
  Fragment,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useRef,
} from 'react';
import style from './CommunicationListSideView.module.scss';
import { translate } from './CommunicationListSideView.translations';
import {
  BrassButton,
  BrassButtonDownload,
  BrassDivFlex,
} from '../../../../../../components';
import {
  UserCommunicationRedirectRoutes,
  UserCommunications,
  UserNotificationEventType,
} from '../../../../models';
import { DateTimeService } from '../../../../../../services';
import { emptyGuid } from '../../../../../../util';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useNavigate } from 'react-router-dom';

interface CommunicationListSideViewProps extends PropsWithChildren<any> {
  data: UserCommunications;
  routes: any;
}

const CommunicationListSideView: FunctionComponent<CommunicationListSideViewProps> =
  memo((props: CommunicationListSideViewProps) => {
    const navigate = useNavigate();
    const { data } = props;
    const pdfExportComponent = useRef<PDFExport>(null);
    const exportPDFWithComponent = () => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    };

    let displayCommunication = (
      <div className={style.emptyViewer}>{translate('noCommunicationSelected')}</div>
    );

    if (data.id !== emptyGuid()) {
      displayCommunication = (
        <Fragment>
          <div style={{ height: '20px', paddingBlock: 10 }}>
            <div className={style.title}>{translate(data.type)}</div>
            <BrassDivFlex />
            <div style={{ float: 'right' }}>
              <BrassButtonDownload
                showText={false}
                fillMode='flat'
                onClick={exportPDFWithComponent}
              />
              {DateTimeService.getFormattedDateTime(
                new Date(data.lastAlertDate).toISOString(),
              )}
            </div>
          </div>
          <div className={style.body}>
            <PDFExport
              // @ts-ignore
              ref={pdfExportComponent}
              paperSize='A4'
              fileName={translate(data.type)}
              margin='1.5cm'
              avoidLinks
            >
              <div
                className={style.internDiv}
                dangerouslySetInnerHTML={{
                  __html: data.content!,
                }}
              />
            </PDFExport>
          </div>
          <div className={style.footer}>
            <BrassButton
              style={{ float: 'right' }}
              fillMode='outline'
              onClick={() =>
                navigate(
                  `${
                    props.routes[(UserCommunicationRedirectRoutes as any)[data.type]]
                  }`,
                )
              }
            >
              {translate(data.type + '_BUTTON')}
            </BrassButton>
          </div>
        </Fragment>
      );
    }

    return <div className={style.displayCommunication}>{displayCommunication}</div>;
  });

export type { CommunicationListSideViewProps };
export { CommunicationListSideView };
