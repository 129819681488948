import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

const BrassButtonPay: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-money'
    labelKey='pay'
    tooltipKey='clickToPay'
    translations={translations}
    icon={<BrassIcon icon={faMoneyBillWave} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    pay: 'Pagar',
    clickToPay: 'haga Clic Para Pagar',
  },
  enUS: {
    pay: 'Pay',
    clickToPay: 'Click to Pay',
  },
  ptBR: {
    pay: 'Pagar',
    clickToPay: 'Clique Para Pagar',
  },
};

export { BrassButtonPay };
