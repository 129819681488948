import { ProgressPopUp } from '../../models';

interface ProgressPopUpState {
  stackProgress: ProgressPopUp[];
}

const initialProgressPopUpState: ProgressPopUpState = {
  stackProgress: [],
};

export { initialProgressPopUpState };
export type { ProgressPopUpState };
