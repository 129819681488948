import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconAddTemplate: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M9.92569 13.4425H13.9555V15.3253H9.92569V13.4425Z' fill={props.fill} />
    <path d='M9.92569 9.67899H13.9555V12.5012H9.92569V9.67899Z' fill={props.fill} />
    <path d='M4.72217 7.06183H8.754V15.3255H4.72217V7.06183Z' fill={props.fill} />
    <path
      d='M17.62 4.89183H14.9583V2.40366C14.9583 2.0775 14.6744 2.05008 14.3238 2.05008C13.974 2.05008 13.69 2.07749 13.69 2.40366V4.89183H11.0293C10.6777 4.89183 10.6463 5.15941 10.6463 5.48363C10.6463 5.81181 10.6777 6.07745 11.0293 6.07745H13.6898V8.56561C13.6898 8.88983 13.9738 8.9192 14.3236 8.9192C14.6742 8.9192 14.9582 8.88996 14.9582 8.56561L14.9582 6.07745H17.6199C17.9687 6.07745 17.9999 5.81181 17.9999 5.48363C17.9999 5.15941 17.9687 4.89183 17.6199 4.89183H17.62Z'
      fill={props.fill}
    />
    <path
      d='M15.4268 11.4465V16.7628H3.25401V5.6185H8.99901V4.33926L2.25301 4.34506C2.11304 4.34506 2.00021 4.47989 2.00021 4.64584L2.01004 17.7512C2.01004 17.9172 2.12567 18.05 2.26766 18.05L16.4222 18.0364C16.5643 18.0364 16.6787 17.9016 16.6787 17.7356L16.6729 11.4466H15.427L15.4268 11.4465Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconAddTemplate };
