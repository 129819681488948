import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import {} from './Button';
import { BrassIcon } from '../icon/Icon';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedV1, BrassButtonSharedPropsV1 } from './ButtonV1';

const BrassButtonUploadV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-upload'
    labelKey='upload'
    tooltipKey='clickToUpload'
    translations={translations}
    icon={<BrassIcon icon={faCloudUploadAlt} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    upload: 'Subir',
    clickToUpload: 'Haga clic para cargar',
  },
  enUS: {
    upload: 'Upload',
    clickToUpload: 'Click to upload',
  },
  ptBR: {
    upload: 'Upload',
    clickToUpload: 'Clique para fazer o upload',
  },
};

export { BrassButtonUploadV1 };
