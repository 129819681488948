import React, { FunctionComponent } from 'react';
import { BrassButtonCancel, BrassButtonYes } from '../../shared';
import { BrassWindow, BrassWindowProps } from '../window';
import style from './NotificationWindowV1.module.scss';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

interface NotificationWindowPropsV1 extends BrassWindowProps {
  description: any;
  onCancelClose: () => void;
  onConfirm: () => void;
  windowProps?: BrassWindowProps;
}

interface BrassNotificationWindowPropsV1 extends NotificationWindowPropsV1 {}

const BrassNotificationWindowV1: FunctionComponent<
  BrassNotificationWindowPropsV1
> = (props: BrassNotificationWindowPropsV1) => {
  const { description, windowProps } = props;

  return (
    <BrassWindow
      minimizeButton={() => null}
      className={style['chat-window']}
      style={{ paddingBottom: '15px', zIndex: '10005 !important' }}
      width={400}
      height={170}
      onClose={props.onCancelClose}
      showDarkBackground={false}
      modal={true}
      overlayStyle={{ zIndex: 0 }}
      title={translate('title')}
      {...windowProps}
      {...props}
    >
      <div>
        <div style={{ paddingBottom: '15px' }}>{description}</div>
        <BrassButtonYes style={{ float: 'right' }} onClick={props.onConfirm} />
        <BrassButtonCancel
          style={{ float: 'right', marginRight: 20 }}
          onClick={props.onCancelClose}
        />
      </div>
    </BrassWindow>
  );
};

const translations: II18n = {
  es: {
    title: 'Confirmación',
  },
  enUS: {
    title: 'Confirmation',
  },
  ptBR: {
    title: 'Confirmação',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { BrassNotificationWindowV1 };
export type { BrassNotificationWindowPropsV1 };
