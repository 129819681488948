import React, { FunctionComponent, ReactNode } from 'react';
import { FormCallbacks, FormItem, FormState, GridItem } from '../../../../models';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassDivFlex,
  BrassError,
  BrassFormRenderProps,
  BrassWindowActionsBar,
} from '../../../../components';

interface BrassFormButtonsProps {
  formState: FormState<GridItem | FormItem>;
  formRenderProps: BrassFormRenderProps;
  formCallbacks: FormCallbacks<GridItem | FormItem>;
  onCancelClose?: () => void;
  onSave?: () => void;
  hideButtonSave?: boolean;
  hideButtonCancel?: boolean;
  additionalButtons?: ReactNode[];
  additionalButtonsBefore?: ReactNode[];
  additionalButtonsAfter?: ReactNode[];
}

const BrassFormButtons: FunctionComponent<BrassFormButtonsProps> = (
  props: BrassFormButtonsProps,
) => {
  const {
    formCallbacks,
    formState,
    formRenderProps,
    onCancelClose,
    onSave,
    hideButtonSave,
    hideButtonCancel,
    additionalButtons,
    additionalButtonsBefore,
    additionalButtonsAfter,
  } = props;
  return (
    <BrassWindowActionsBar>
      {additionalButtonsBefore}
      <BrassDivFlex />
      {formState.errorMessage && <BrassError>{formState.errorMessage}</BrassError>}
      {!hideButtonSave && (
        <BrassButtonSave
          autoFocus
          type='submit'
          onClick={formRenderProps.onSubmit}
          disabled={formCallbacks.onCheckButtonSaveState(formRenderProps)}
          showLoader={formState.allowSubmit}
          hideIconWhenLoader
        />
      )}
      {additionalButtons}
      {!hideButtonCancel && <BrassButtonCancel onClick={onCancelClose} />}
      {additionalButtonsAfter}
    </BrassWindowActionsBar>
  );
};

BrassFormButtons.defaultProps = {
  hideButtonSave: false,
  hideButtonCancel: false,
};

export type { BrassFormButtonsProps };
export { BrassFormButtons };
