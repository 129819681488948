import { emptyGuid } from '../../';

export class RequestNavigatorFileParams {
  public accountId: string = emptyGuid();
  public projectId: string = emptyGuid();
  public areaId: string = emptyGuid();
  public systemId: string = emptyGuid();
  public fileId: string = emptyGuid();

  constructor(requestNavigatorFileParams: Partial<RequestNavigatorFileParams>) {
    Object.assign(this, requestNavigatorFileParams);
  }
}
