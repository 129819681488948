enum SurveySubmodule {
  ASSET = 0x00000100,
  AREA = 0x00000200,
  PROJECT = 0x00000300,
  SYSTEM = 0x00000400,
  MANUFACTURER = 0x00000500,
  CUSTOMCODE = 0x00000600,
  FILE = 0x00000700,
  FILESHARE = 0x00000800,
  HIERARCHICAL_PLACE = 0x00000900,
  INFORMATION_BUILDING = 0x00000a00,
  SYSTEM_MAP = 0x00000b00,
}

export { SurveySubmodule };
