import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    comments: 'Comentarios',
    FINANCE: 'Comentarios sobre la Factura',
    WORK_TIME_RECORD: 'Comentarios sobre las apropiaciones de la RHT',
    WORK_TIME_RECORD_AUTHORIZATION: 'Comentarios sobre la Autorización RHT',
    OVERTIME_WORK: 'Comentarios sobre solicitudes de horas extras',
  },
  enUS: {
    comments: 'Comments',
    FINANCE: 'Comments on the Invoice',
    WORK_TIME_RECORD: 'Comments on the WHR Authorization',
    WORK_TIME_RECORD_AUTHORIZATION: 'Comments on the WHR appropriation',
    OVERTIME_WORK: 'Comments on overtime requests',
  },
  ptBR: {
    comments: 'Comentários',
    FINANCE: 'Comentários na Nota Fiscal',
    WORK_TIME_RECORD_AUTHORIZATION: 'Comentários na Autorização do RHT',
    WORK_TIME_RECORD: 'Comentários nas apropriações do RHT',
    OVERTIME_WORK: 'Comentários nos pedidos de horas extras',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
