import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import {
  DateRangePicker,
  DateRangePickerProps,
} from '@progress/kendo-react-dateinputs';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import style from './DateRangePicker.module.scss';

const translations: II18n = {
  es: {
    start: 'Comienzo',
    end: 'Fin',
  },
  enUS: {
    start: 'Start',
    end: 'End',
  },
  ptBR: {
    start: 'Início',
    end: 'Fim',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface BrassDateRangePickerProps extends DateRangePickerProps {}

const BrassDateRangePicker: FunctionComponent<BrassDateRangePickerProps> = (
  props: BrassDateRangePickerProps,
) => {
  const { className } = props;
  const _className = classnames(
    'brass-date-range-picker',
    style['date-range-picker'],
    className,
  );

  return <DateRangePicker {...props} className={_className} />;
};

BrassDateRangePicker.defaultProps = {
  startDateInputSettings: {
    label: translate('start'),
    format: 'dd/MM/yyyy',
  },
  endDateInputSettings: {
    label: translate('end'),
    format: 'dd/MM/yyyy',
  },
};

export { BrassDateRangePicker };
export type { BrassDateRangePickerProps };
