import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconExtraTime: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_1314_2)'>
      <path
        d='M10 0.6875C4.865 0.6875 0.6875 4.865 0.6875 10C0.6875 10.0812 0.688151 10.1544 0.696224 10.2356C0.726237 10.5443 1.00001 10.77 1.31061 10.7406C1.61997 10.7106 1.84564 10.4356 1.81562 10.1262C1.81185 10.0832 1.8125 10.0438 1.8125 10C1.8125 5.48568 5.48567 1.81251 10 1.81251C14.5143 1.81251 18.1875 5.48568 18.1875 10C18.1875 14.5143 14.5143 18.1875 10 18.1875C9.95501 18.1869 9.91628 18.1882 9.87311 18.1844C9.56374 18.1519 9.28874 18.38 9.25873 18.6894C9.22871 18.9988 9.45436 19.2738 9.76374 19.3038C9.84565 19.3119 9.91876 19.3125 10 19.3125C15.135 19.3125 19.3125 15.135 19.3125 10C19.3125 4.86501 15.135 0.6875 10 0.6875Z'
        fill={props.fill}
      />
      <path
        d='M10 4.8475C9.68938 4.8475 9.4375 5.09939 9.4375 5.41V10C9.4375 10.1744 9.5181 10.3387 9.6556 10.445L12.5856 12.7107C12.6881 12.79 12.8094 12.8282 12.9293 12.8282C13.0975 12.8282 13.2637 12.7532 13.3743 12.6101C13.5643 12.3639 13.5193 12.0113 13.2737 11.8207L10.5625 9.72385V5.41018C10.5625 5.0995 10.3106 4.84768 10 4.84768L10 4.8475Z'
        fill={props.fill}
      />
      <path
        d='M4.63567 11.4168C2.45884 11.4168 0.688171 13.1875 0.688171 15.365C0.688171 17.5418 2.45884 19.3125 4.63567 19.3125C6.81251 19.3125 8.58384 17.5418 8.58384 15.365C8.58325 13.1875 6.81267 11.4168 4.63567 11.4168ZM4.63567 18.1875C3.07942 18.1875 1.8125 16.9212 1.8125 15.3643C1.8125 13.8074 3.07879 12.5412 4.63567 12.5412C6.19257 12.5412 7.45884 13.8075 7.45884 15.3643C7.45884 16.9212 6.19197 18.1875 4.63567 18.1875Z'
        fill={props.fill}
      />
      <path
        d='M5.79434 14.8025H5.19747V14.2056C5.19747 13.895 4.94559 13.6431 4.63497 13.6431C4.32435 13.6431 4.07247 13.895 4.07247 14.2056V14.8025H3.47684C3.16622 14.8025 2.91434 15.0544 2.91434 15.365C2.91434 15.6756 3.16622 15.9275 3.47684 15.9275H4.07305V16.5237C4.07305 16.8343 4.32494 17.0862 4.63555 17.0862C4.94617 17.0862 5.19805 16.8343 5.19805 16.5237V15.9275H5.79492C6.10554 15.9275 6.35742 15.6756 6.35742 15.365C6.35742 15.0544 6.10495 14.8025 5.79434 14.8025Z'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_1314_2'>
        <rect width='20' height='20' fill={props.fill} />
      </clipPath>
    </defs>
  </svg>
);

export { SVGIconExtraTime };
