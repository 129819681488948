import { ApiResult, DateRange, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { AcademyStatisticsModel, UserStatistics } from '../models';

class IndicatorsService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getUserStatistics(
    userId: string,
    periodOfAnalysis: DateRange,
  ): Promise<ApiResult<UserStatistics>> {
    return await this.api.post<UserStatistics>('/connect/v1/user/statistics', {
      userId,
      startDate: periodOfAnalysis.start,
      endDate: periodOfAnalysis.end,
    });
  }

  public static async getAcademyStatistics(
    userId: string,
    periodOfAnalysis: DateRange,
  ): Promise<ApiResult<AcademyStatisticsModel>> {
    return await this.api.post<AcademyStatisticsModel>('/connect/v1/user/statistics', {
      userId,
      startDate: periodOfAnalysis.start,
      endDate: periodOfAnalysis.end,
    });
  }
}

export { IndicatorsService };

