import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Switch, SwitchProps } from '@progress/kendo-react-inputs';

interface BrassSwitchProps extends SwitchProps {}

const BrassSwitch: FunctionComponent<BrassSwitchProps> = (
  props: BrassSwitchProps,
) => {
  const { className } = props;
  const _className = classnames('brass-switch', className);
  return <Switch {...props} className={_className} />;
};

export { BrassSwitch };
export type { BrassSwitchProps };
