import {
  AlignPlugin,
  BlockquotePlugin,
  BoldPlugin,
  CodeBlockPlugin,
  CodePlugin,
  ExitBreakPlugin,
  HeadingPlugin,
  HighlightPlugin,
  ItalicPlugin,
  LinkPlugin,
  ListPlugin,
  MediaEmbedPlugin,
  ParagraphPlugin,
  ResetBlockTypePlugin,
  SearchHighlightPlugin,
  SoftBreakPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  TablePlugin,
  TodoListPlugin,
  UnderlinePlugin,
  KbdPlugin,
} from '@udecode/slate-plugins';
import {
  headingTypes,
  options,
  optionsResetBlockTypes,
} from '../common/initialValues';
import React from 'react';

export const plugins = [
  ParagraphPlugin(options),
  BlockquotePlugin(options),
  TodoListPlugin(options),
  HeadingPlugin(options),
  LinkPlugin(options),
  KbdPlugin(options),
  ListPlugin(options),
  TablePlugin(options),
  AlignPlugin(options),
  MediaEmbedPlugin(options),
  CodeBlockPlugin(options),
  BoldPlugin(options),
  CodePlugin(options),
  ItalicPlugin(options),
  HighlightPlugin(options),
  SearchHighlightPlugin(options),
  UnderlinePlugin(options),
  StrikethroughPlugin(options),
  SubscriptPlugin(options),
  SuperscriptPlugin(options),
  ResetBlockTypePlugin(optionsResetBlockTypes),
  SoftBreakPlugin({
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [options.code_block.type, options.blockquote.type, options.td.type],
        },
      },
    ],
  }),
  ExitBreakPlugin({
    rules: [
      {
        hotkey: 'mod+enter',
        level: 1,
      },
      {
        hotkey: 'mod+shift+enter',
        before: true,
        level: 1,
      },
      {
        hotkey: 'enter',
        query: {
          start: true,
          end: true,
          allow: headingTypes,
        },
        level: 1,
      },
    ],
  }),
];
