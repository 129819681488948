import React, { FunctionComponent } from 'react';

import { BrassTooltip } from '../../components';

const TooltipPlayground: FunctionComponent = () => (
  <BrassTooltip>
    <span title='tooltip'>Brass Tooltip</span>
  </BrassTooltip>
);

export { TooltipPlayground };
