import { ApiResult } from '../';
import { Endpoints } from '../models';
import { Area } from './../models/viewer/enum/area.model';
import { ApiService } from './api.service';

class AreaService {
  private static api: ApiService = new ApiService(Endpoints.NAVIGATOR);

  public static async updateArea(area: Area): Promise<ApiResult> {
    return this.api.put('/connect/v2/area', area);
  }

  public static async includeArea(area: Area): Promise<ApiResult> {
    return this.api.post('/connect/v2/area', area);
  }

  public static async deleteArea(
    projectId: string,
    areaId: string,
  ): Promise<ApiResult> {
    return this.api.delete(`/connect/v2/project/${projectId}/area/${areaId}`);
  }

  public static async getArea(
    projectId: string,
    areaId: string,
  ): Promise<ApiResult<Area>> {
    return this.api.get(`/connect/v2/project/${projectId}/area/${areaId}`);
  }
}

export { AreaService };
