import React, { FunctionComponent } from 'react';
import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs';
import classnames from 'classnames';

interface BrassCheckBoxProps extends CheckboxProps {}

const BrassCheckBox: FunctionComponent<BrassCheckBoxProps> = (
  props: BrassCheckBoxProps,
) => {
  const { className } = props;
  const _className = classnames('brass-checkbox', className);

  return <Checkbox {...props} className={_className} />;
};

export { BrassCheckBox };
export type { BrassCheckBoxProps };
