import React, { Fragment, FunctionComponent, memo, useState } from 'react';
import { translate } from './UsersGrid.translations';
import { UserListData } from '../../../../models';
import { UserService } from '../../../../services';
import { useGrid, useWindowSize } from '../../../../../../hooks';
import {
  BrassIconUser,
  BrassCardTreeListGrid,
  BrassCardTitle,
  BrassButton,
  BrassIcon,
  BrassNotificationWindow,
  BrassLoader,
} from '../../../../../../components';
import { ApiResult } from '../../../../../../models';
import {
  getColumns,
  getExcelExportColumns,
  GetRecordsParams,
  getRefreshButton,
  getToolbarBeforeActions,
} from './UsersGrid.functions';
import { UpsertUserWindow } from '../../upsert-user-window/UpsertUserWindow';
import { UserStatusEnum } from '../../../../models/enum/UserStatus.enum';
import style from './UsersGrid.module.scss';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { DynamicDispatchAlert } from '../../../../../../util';

const UsersGrid: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowSize();
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getRecordsParams, setGetRecordsParams] = useState<GetRecordsParams>({
    userStatusFilter: UserStatusEnum.ALL,
  });

  const toolbarBeforeActions = getToolbarBeforeActions(setGetRecordsParams);

  const { gridState, gridDispatch, gridCallbacks } = useGrid<UserListData>({
    getRecords: UserService.getUsersList,
    getRecordsParams,
    deleteRecord: UserService.deleteUser,
    translate,
  });

  const columns = getColumns(
    gridState,
    gridCallbacks,
    dispatch,
    setOpenWindow,
    setUserId,
    setDeleteUser,
    setIsLoading,
  );
  const excelExportColumns = getExcelExportColumns(columns);

  const handleDelete = (): void => {
    setIsLoading(true);
    setDeleteUser(false);
    UserService.deleteUser(userId)
      .then((apiResult: ApiResult) => {
        if (apiResult.success) {
          DynamicDispatchAlert(dispatch, ['successOnDelete'], 'SUCCESS', translate);
        } else {
          DynamicDispatchAlert(
            dispatch,
            ['errorOnDelete', ...apiResult.message],
            'ERROR',
            translate,
          );
        }
        setUserId('');
      })
      .finally(() => {
        setIsLoading(false);
        gridCallbacks.onRefresh();
      });
  };

  return (
    <div
      className={style[`container`]}
      style={{
        width: width - 80,
        height: height - 170,
      }}
    >
      {isLoading && <BrassLoader useLoadingMask />}
      <div className={style[`head-title`]}>
        <BrassIconUser className={style.icon} />
        <BrassCardTitle className={style['info-text-card']}>
          {translate('gridTitle')}
        </BrassCardTitle>
        <div className={style[`toolbar-actions`]}>
          {toolbarBeforeActions}
          {getRefreshButton(gridState, gridDispatch, gridCallbacks)}
          <BrassButton
            /* themeColor='dark' */
            className={style['button-new']}
            onClick={() => {
              setOpenWindow(true);
            }}
          >
            <BrassIcon icon={faPlus} className={style.icon} />{' '}
            {translate('buttonNew')}
          </BrassButton>
        </div>
      </div>
      <BrassCardTreeListGrid
        width={`${width - 82}px`}
        gridColumns={columns}
        excelExportColumns={excelExportColumns}
        className={style[`card-treelist`]}
        icon={<BrassIconUser />}
        translate={translate}
        gridState={gridState}
        gridDispatch={gridDispatch}
        gridCallbacks={gridCallbacks}
        groupable={{
          enabled: true,
          footer: 'none',
        }}
      />
      {openWindow && (
        <UpsertUserWindow
          entityId={userId}
          onCancelClose={() => {
            setOpenWindow(false);
            UserService.lastedStatusUser(userId)
              .then((apiResult) => {
                setUserId('');
                if (!apiResult.success) {
                  DynamicDispatchAlert(
                    dispatch,
                    apiResult.message,
                    'ERROR',
                    translate,
                  );
                }
              })
              .finally(() => {
                gridCallbacks.onRefresh();
              });
          }}
          onSaveClose={() => {
            setOpenWindow(false);
            UserService.lastedStatusUser(userId)
              .then((apiResult) => {
                setUserId('');
                if (!apiResult.success) {
                  DynamicDispatchAlert(
                    dispatch,
                    apiResult.message,
                    'ERROR',
                    translate,
                  );
                }
              })
              .finally(() => {
                gridCallbacks.onRefresh();
              });
          }}
        />
      )}
      {deleteUser && (
        <BrassNotificationWindow
          description={translate('confirmationDelete')}
          onConfirm={handleDelete}
          onCancelClose={() => {
            setDeleteUser(false);
            gridCallbacks.onRefresh();
          }}
        />
      )}
    </div>
  );
};

export { UsersGrid };
