import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    unauthorized: 'Acción no autorizada',
  },
  enUS: {
    unauthorized: 'Action not authorized',
  },
  ptBR: {
    unauthorized: 'Ação não Autorizada',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
