import { ApiResult, Endpoints, PropertyTemplate } from '../models';
import { ApiService } from './api.service';

class TemplatePropertyService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async insertTemplate(request: PropertyTemplate): Promise<ApiResult> {
    const url = '/connect/v1/matrix-components/template-property';
    return await TemplatePropertyService.api.post(url, request);
  }

  public static async updateTemplate(request: PropertyTemplate): Promise<ApiResult> {
    return await TemplatePropertyService.api.put(
      `/connect/v1/matrix-components/template-property`,
      request,
    );
  }

  public static async getTemplate(
    matrixComponentId: string,
    templateId: string,
    language: string,
  ): Promise<ApiResult> {
    return await TemplatePropertyService.api.get(
      `/connect/v1/matrix-components/${matrixComponentId}/template/${templateId}?language=${language}`,
    );
  }

  public static async getTranslate(
    matrixComponentId: string,
    templateId: string,
  ): Promise<ApiResult> {
    return await TemplatePropertyService.api.get(
      `/connect/v1/active-components/${matrixComponentId}/template/${templateId}/translate`,
    );
  }
}

export { TemplatePropertyService };
