import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonSharedProps, BrassButtonShared } from './Button';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const BrassButtonEdit: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-edit'
    labelKey='edit'
    tooltipKey='clickToEdit'
    translations={translations}
    icon={<BrassIcon icon={faEdit} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    edit: 'Editar',
    clickToEdit: 'Haga clic para editar',
  },
  enUS: {
    edit: 'Edit',
    clickToEdit: 'Click to edit',
  },
  ptBR: {
    edit: 'Editar',
    clickToEdit: 'Clique para editar',
  },
};

export { BrassButtonEdit };
