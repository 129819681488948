import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialBreadcrumbState as initialState, BreadcrumbState } from './state';
import { Breadcrumb } from '../../models';
import { RootReducerProps } from '../AppStore';

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb: (state: BreadcrumbState, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = initialState.breadcrumbs.concat(action.payload);
    },
    clearBreadcrumb: (state: BreadcrumbState) => {
      state.breadcrumbs = initialState.breadcrumbs;
    },
  },
});

const useBreadcrumb = (state: RootReducerProps) => state.breadcrumb;
const breadcrumbReducer = breadcrumbSlice.reducer;
const breadcrumbActions = breadcrumbSlice.actions;

export { breadcrumbSlice, breadcrumbReducer, breadcrumbActions, useBreadcrumb };
