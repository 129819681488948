import { ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { getKeyFromItemOnListValue } from '../../../util';
import {
    CommentGroup,
    UserCommunications,
    userNotificationTypeGroup
} from '../models';

class CommunicationService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getCommunicationList(): Promise<
    ApiResult<UserCommunications[]>
  > {
    const apiResult: ApiResult = await this.api.get<UserCommunications[]>(
      `/connect/v2/communications/list`,
    );
    apiResult.data = apiResult.data.map((userCommunication: UserCommunications) => {
      return {
        ...userCommunication,
        group: getKeyFromItemOnListValue(
          userNotificationTypeGroup,
          userCommunication.type,
        )! as CommentGroup,
      };
    });
    return apiResult;
  }
}

export { CommunicationService };
