import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconPlus: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    {...props}
    fill={props?.fill ?? '#424242'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.37501 15.8333V10.625H4.16667V9.375H9.37501V4.16667H10.625V9.375H15.8333V10.625H10.625V15.8333H9.37501Z'
      fill={props?.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconPlus };
