import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const BrassIconPublish: FunctionComponent<ImgHTMLAttributes<unknown>> = (
  props: ImgHTMLAttributes<unknown>,
) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill={props?.style?.color}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.0914 0L12.0133 0.629667L14.1594 3.48739L15.7024 3.16448L15.0914 0M9.71064 1.09788L8.16767 1.41271L10.3216 4.26236L11.8567 3.94753L9.71064 1.09788M5.8728 1.87286L4.32983 2.20383L6.48372 5.05348L8.01886 4.73865L5.8728 1.87286ZM2.02713 2.68012L1.25956 2.8335C0.852579 2.91731 0.4945 3.16416 0.263947 3.51984C0.0333938 3.87553 -0.0507881 4.31097 0.029885 4.73057L0.335346 6.31282L4.17319 5.52977L2.02713 2.68012ZM0.335346 6.31282V14.3855C0.335346 14.8137 0.500383 15.2243 0.794153 15.5271C1.08792 15.8299 1.48636 16 1.90181 16H14.4335C15.3029 16 16 15.2815 16 14.3855V6.31282H0.335346Z'
      fill={props?.style?.color}
    />
  </svg>
);

export { BrassIconPublish };
