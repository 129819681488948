import { FunctionComponent, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';
import style from './AccessDeniedModule.module.scss';

interface AccessDeniedModuleProps {
  redirectToHome?: boolean;
}

const AccessDeniedModule: FunctionComponent<AccessDeniedModuleProps> = (
  props: AccessDeniedModuleProps,
) => {
  var navigate: NavigateFunction | null = null;

  try {
    navigate = useNavigate();
  } catch (error) {
    console.error("useNavigate só pode ser usado dentro de um contexto de <Router>.", error);
    navigate = null;
  }

  useEffect(() => {
    if (props.redirectToHome) {
      setTimeout(() => {
        if(navigate)
        navigate('/login');
      }, 3000);
    }
  }, [props.redirectToHome, navigate]);

  return (
    <div className={style['main-document']}>
      <h4>{translate('accessDenied')}</h4>
      <img
        src='/images/access_denied.svg'
        title={translate('accessDenied')}
        alt={translate('accessDenied')}
        className={style.img}
      />
    </div>
  );
};

AccessDeniedModule.defaultProps = {
  redirectToHome: true,
};

const translations: II18n = {
  es: {
    accessDenied: 'No tienes acceso a este módulo',
  },
  enUS: {
    accessDenied: 'You do not have access to this module',
  },
  ptBR: {
    accessDenied: 'Você não tem acesso a este módulo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { AccessDeniedModule };
