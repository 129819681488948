import { ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { ILanguageProps } from './field-checker-form/FieldCheckerForm';

interface IInsertTranslateProps extends ILanguageProps {
  language: string;
}

class TranslationCheckerService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  constructor(endpoints?: Endpoints) {
    TranslationCheckerService.api = new ApiService(
      Endpoints[endpoints!] ?? Endpoints.DRIVE,
    );
  }

  public async getTranslate(
    route: string,
    id: string,
    hasRouterId?: boolean,
  ): Promise<ApiResult> {
    if (hasRouterId) {
      return await TranslationCheckerService.api.get(`${route}`);
    } else {
      return await TranslationCheckerService.api.get(`${route}/${id}`);
    }
  }

  public async updateTranslate(
    routeSave: string,
    request: IInsertTranslateProps,
  ): Promise<ApiResult> {
    return await TranslationCheckerService.api.put(routeSave, request);
  }
}

export { TranslationCheckerService };
