import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '..';
const BrassButtonSend: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-send'
    labelKey='send'
    tooltipKey='clickToSend'
    translations={translations}
    icon={<BrassIcon icon={faPaperPlane} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    send: 'Enviar',
    clickToSend: 'Haga clic para enviar',
  },
  enUS: {
    send: 'Send',
    clickToSend: 'Click to send',
  },
  ptBR: {
    send: 'Enviar',
    clickToSend: 'Clique para enviar',
  },
};

export { BrassButtonSend };
