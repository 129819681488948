enum FormActions {
  UPDATE_FORM_LOADING = 'UPDATE_FORM_LOADING',
  UPDATE_INITIAL_VALUES = 'UPDATE_INITIAL_VALUES',
  UPDATE_ALLOW_SUBMIT = 'UPDATE_ALLOW_SUBMIT',
  UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE',
  UPDATE_IS_UPDATING = 'UPDATE_IS_UPDATING',
  UPDATE_IS_DATA_FETCHED = 'UPDATE_IS_DATA_FETCHED',
  UPDATE_RECORD_ID = 'UPDATE_RECORD_ID',
  START_HANDLE_SUBMIT = 'START_HANDLE_SUBMIT',
  END_HANDLE_SUBMIT = 'UPDATE_RECORDS_LIST',
}

export { FormActions };
