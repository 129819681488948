import React, { FunctionComponent } from 'react';
import { BrassScrollViewV1 } from '../../../../components/brass';
import { DocumentModel, ViewerTypesEnum } from '../../../../models';
import style from './ViewFilesCarousel.module.scss';

interface BrassViewFilesCarouselProps {
  listFiles: DocumentModel[];
  positionFile: number;
}

const BrassViewFilesCarousel: FunctionComponent<BrassViewFilesCarouselProps> = (
  props: BrassViewFilesCarouselProps,
) => {
  const { listFiles, positionFile } = props;

  return (
    <>
      {listFiles[0]?.fileType === 'IMAGE' && (
        <BrassScrollViewV1
          items={listFiles?.map((item: any, index: number) => {
            return {
              position: index,
              allow: true,
              url: item?.fileUrl ?? item?.fileBase64 ?? item?.thumbnailUrl,
            };
          })}
          positionFile={positionFile}
          type={ViewerTypesEnum.IMAGE}
          width={'100%'}
          height={'100%'}
          className={style.srollview}
          className_item={style['image-scroll']}
          endless={false}
          automaticViewChange={false}
        />
      )}
      {listFiles[0]?.fileType === 'VIDEO' && (
        <BrassScrollViewV1
          items={listFiles?.map((item: any, index: number) => {
            return {
              position: index,
              allow: true,
              url: item?.fileUrl ?? item?.fileBase64 ?? item?.thumbnailUrl,
            };
          })}
          positionFile={positionFile}
          type={ViewerTypesEnum.VIDEO}
          width={'100%'}
          height={'100%'}
          className={style.srollview}
          className_item={style['video']}
          endless={false}
          automaticViewChange={false}
        />
      )}
      {listFiles[0]?.fileType === 'DOCUMENT' && (
        <BrassScrollViewV1
          items={listFiles?.map((item: any, index: number) => {
            return {
              position: index,
              allow: true,
              url: item?.fileUrl ?? item?.fileBase64 ?? item?.thumbnailUrl,
            };
          })}
          positionFile={positionFile}
          type={ViewerTypesEnum.PDF}
          width={'100%'}
          height={'100%'}
          className={style.srollview}
          className_item={style['pdf']}
          endless={false}
          automaticViewChange={false}
        />
      )}
      {listFiles[0]?.fileType === 'IMAGE2D3D' && (
        <BrassScrollViewV1
          items={listFiles?.map((item: any, index: number) => {
            return {
              position: index,
              allow: true,
              url: item?.fileUrl ?? item?.thumbnailUrl,
            };
          })}
          positionFile={positionFile}
          type={ViewerTypesEnum.IMAGE3D2D}
          width={'100%'}
          height={'100%'}
          className={style.srollview}
          className_item={style['pdf']}
          endless={false}
          automaticViewChange={false}
        />
      )}
    </>
  );
};

export { BrassViewFilesCarousel };
