import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconProfileReport: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M7.58001 5.31999C6.29999 5.31999 5.26001 6.35996 5.26001 7.63999C5.26001 8.13999 5.42004 8.62001 5.69998 9.00003C5.86 8.66006 6.17999 8.43999 6.53994 8.43999H8.61994C8.97998 8.43999 9.27998 8.65998 9.45991 9.00003C9.73993 8.62001 9.89988 8.13999 9.89988 7.63999C9.89994 6.36004 8.85996 5.31999 7.57988 5.31999H7.58001ZM7.58001 7.99999C7.06003 7.99999 6.64004 7.58001 6.64004 7.06003C6.64004 6.54004 7.06003 6.12006 7.58001 6.12006C8.09999 6.12006 8.51998 6.54004 8.51998 7.06003C8.53996 7.56003 8.09999 7.99999 7.58001 7.99999Z' />
    <path d='M17.54 5.89999L17.52 5.81998C17.48 5.69999 17.42 5.61998 17.34 5.53996L12.56 0.699965C12.5 0.639938 12.44 0.599965 12.36 0.559991L12.0999 0.479978L4.53995 0.459991C3.31996 0.459991 2.31995 1.45999 2.31995 2.67999V17.32C2.31995 18.54 3.31995 19.54 4.53995 19.54H15.3399C16.5599 19.54 17.5599 18.54 17.5599 17.32V6.11999L17.54 5.89999ZM12.58 3.01999L15.08 5.55999L13.2 5.56006C12.86 5.56006 12.58 5.28004 12.58 4.94008L12.58 3.01999ZM4.43998 7.63999C4.43998 5.89999 5.85997 4.47999 7.59998 4.47999C9.33998 4.47999 10.76 5.89998 10.76 7.63999C10.76 9.37999 9.34 10.8 7.59998 10.8C5.83998 10.78 4.43998 9.38001 4.43998 7.63999ZM15.4 17.06H12.38V16.32L15.4 16.32V17.06ZM15.4 15.12H9.75998V14.38H15.4V15.12ZM15.4 13.24H9.75998V12.5H15.4V13.24Z' />
  </svg>
);

export { SGVIconProfileReport };
