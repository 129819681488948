import React, { FunctionComponent } from 'react';
import style from './ListRelationalGroup.module.scss';
import { translate } from './ListRelationalGroup.translations';
import { DynamicDispatchAlert, emptyGuid } from '../../../../../util';
import { BrassListCard } from '../../../../../components/shared/list-card';
import { BrassLoader } from '../../../../../components/brass';
import { Label } from '@progress/kendo-react-labels';
import { BrassGeneralCard } from '../../../../../components/shared/card';
import { getColorCardEnum } from '../../../../../util/GetColor';
import { ListRelationalGroupFunctions } from './ListRelationalGroup.functions';

interface ListListRelationalGroupProps {}

const ListRelationalGroup: FunctionComponent<ListListRelationalGroupProps> = (
  props: ListListRelationalGroupProps,
) => {
  const {} = props;
  const {
    isLoading,
    relationalGroup,
    list,
    setList,
    onOpen,
    pagination,
    scrollObserver,
  } = ListRelationalGroupFunctions();

  return (
    <div style={{ width: '23%' }}>
      <Label style={{ opacity: 0.6, fontSize: '11px' }}>
        {translate('relationalGroup')}
      </Label>
      <div className={style.container}>
        {!relationalGroup ||
          ((relationalGroup?.parentGroupRelationalId === emptyGuid() ||
            !relationalGroup?.parentGroupRelationalId) && (
            <BrassListCard
              list={list as any}
              setList={setList as any}
              onAddClick={onOpen}
              fieldIdName={'groupRelationalId'}
              allowEdit={false}
              vertical={true}
              width={'270px'}
              noImage={true}
              take={pagination.take}
              scrollObserver={scrollObserver}
            />
          ))}
        {relationalGroup.parentGroupRelationalId &&
          relationalGroup.parentGroupRelationalId !== emptyGuid() && (
            <BrassGeneralCard
              title={relationalGroup.name!}
              colorBorder={getColorCardEnum(relationalGroup.color!)}
              description={relationalGroup?.property}
              onAddClick={onOpen}
              allowEdit={false}
              selected={true}
              noImage={true}
            />
          )}
      </div>
      {isLoading && <BrassLoader useLoadingMask />}
    </div>
  );
};

export { ListRelationalGroup };
