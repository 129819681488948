import React, { FunctionComponent, ReactElement } from 'react';
import { BrassWindow, BrassWindowTitleCustom } from '../../../brass';
import { BrassLinkRelationalFlagsForm } from '../link-relational-flags-form/LinkRelationalFlagsForm';
import { translate } from './LinkRelationalFlagsWindow.translations';
import style from './LinkRelationalFlagsWindow.module.scss';

interface BrassLinkRelationalFlagsProps {
  onCancelClose: () => void;
  onSubmit: (data: any) => void;
  content?: (e?: any) => ReactElement;
  initialValueFlags: any;
  readonly?: boolean;
}

const BrassLinkRelationalFlagsWindow: FunctionComponent<
  BrassLinkRelationalFlagsProps
> = (props: BrassLinkRelationalFlagsProps) => {
  const { onCancelClose, onSubmit, content, initialValueFlags, readonly } = props;

  return (
    <BrassWindow
      onClose={onCancelClose}
      title={
        <BrassWindowTitleCustom title={translate('title')} className={style.title} />
      }
      maximizeButton={() => null}
      minimizeButton={() => null}
      modal={true}
      initialHeight={window.innerHeight < 800 ? window.innerHeight * 0.9 : 800}
      initialWidth={window.innerWidth * 0.8}
      overlayStyle={{ zIndex: 0 }}
      showDarkBackground={false}
    >
      <BrassLinkRelationalFlagsForm
        onSubmit={onSubmit}
        onSaveClose={onCancelClose}
        content={content}
        initialValueFlags={initialValueFlags}
        readonly={readonly}
      />
    </BrassWindow>
  );
};

export type { BrassLinkRelationalFlagsProps };
export { BrassLinkRelationalFlagsWindow };
