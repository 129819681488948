import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonPrint: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-print'
    labelKey='print'
    tooltipKey='clickToPrint'
    translations={translations}
    icon='print'
    {...props}
  />
);

const translations: II18n = {
  es: {
    print: 'impresión',
    clickToPrint: 'haga clic para imprimir',
  },
  enUS: {
    print: 'print',
    clickToPrint: 'click to print',
  },
  ptBR: {
    print: 'imprimir',
    clickToPrint: 'clique para imprimir',
  },
};

export { BrassButtonPrint };
