import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonSharedProps, BrassButtonShared } from './Button';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const BrassButtonDelete: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-delete'
    labelKey='delete'
    tooltipKey='clickToDelete'
    translations={translations}
    icon={<BrassIcon icon={faTrashAlt} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    delete: 'Eliminar',
    clickToDelete: 'Haga clic para eliminar',
  },
  enUS: {
    delete: 'Delete',
    clickToDelete: 'Click to delete',
  },
  ptBR: {
    delete: 'Excluir',
    clickToDelete: 'Clique para excluir',
  },
};

export { BrassButtonDelete };
