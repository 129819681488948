import React, { FunctionComponent } from 'react';
import { BrassDialogProps } from '../../brass/dialog';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassConfirmDialog } from './ConfirmDialog';

interface BrassConfirmExclusionDialogProps extends BrassDialogProps {
  onYes: () => void;
  onNo: () => void;
}

const BrassConfirmExclusionDialog: FunctionComponent<
  BrassConfirmExclusionDialogProps
> = (props: BrassConfirmExclusionDialogProps) => (
  <BrassConfirmDialog
    {...props}
    title={translate('title')}
    content={translate('areYouSure')}
  />
);

const translations: II18n = {
  es: {
    title: 'Confirmación de eliminación',
    areYouSure: '¿Estás seguro de que quieres excluir este registro?',
  },
  enUS: {
    title: 'Deletion Confirmation',
    areYouSure: 'Are you sure you want to exclude this register?',
  },
  ptBR: {
    title: 'Confirmação de Exclusão',
    areYouSure: 'Você tem certeza que deseja excluir este registro?',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export type { BrassConfirmExclusionDialogProps };
export { BrassConfirmExclusionDialog };
