import React, { createContext, ReactNode, useState } from 'react';

interface ISelectUploadImageDialog {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  base64File: string;
  setBase64File: React.Dispatch<React.SetStateAction<string>>;
  saveUrl: string;
  setSaveUrl: React.Dispatch<React.SetStateAction<string>>;
}

interface ISelectUploadImageDialogProvider {
  children: ReactNode;
}

const UploadImageContext = createContext<ISelectUploadImageDialog>(
  {} as ISelectUploadImageDialog,
);

const UploadImageProvider: (props: ISelectUploadImageDialogProvider) => any = ({
  children,
}: ISelectUploadImageDialogProvider) => {
  const [isActive, setIsActive] = useState(false);
  const [base64File, setBase64File] = useState<any>(null);
  const [saveUrl, setSaveUrl] = useState<any>(null);

  return (
    <UploadImageContext.Provider
      value={{
        isActive,
        setIsActive,
        base64File,
        setBase64File,
        saveUrl,
        setSaveUrl,
      }}
    >
      {children}
    </UploadImageContext.Provider>
  );
};

export { UploadImageContext, UploadImageProvider };
