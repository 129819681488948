import React, { FunctionComponent } from 'react';
import { translate } from './ReplyNotificationWindow.translations';
import { BrassWindow } from '../../../../../../components';
import { emptyGuid } from '../../../../../../util';
import { ReplyNotificationForm } from './reply-notification-form/ReplyNotificationForm';

interface ReplyNotificationWindowProps {
  entityId?: string;
  onSaveClose: () => void;
  onCancelClose: () => void;
}

const ReplyNotificationWindow: FunctionComponent<ReplyNotificationWindowProps> = (
  props: ReplyNotificationWindowProps,
) => {
  const { entityId, onSaveClose, onCancelClose } = props;

  return (
    <BrassWindow
      title={translate('addNotification')}
      onClose={onCancelClose}
      maximizeButton={() => null}
      minimizeButton={() => null}
      initialWidth={500}
      initialHeight={410}
    >
      <ReplyNotificationForm
        entityId={entityId}
        onSaveClose={onSaveClose}
        onCancelClose={onCancelClose}
      />
    </BrassWindow>
  );
};

ReplyNotificationWindow.defaultProps = {
  entityId: emptyGuid(),
};
export { ReplyNotificationWindow };
