import React, { FunctionComponent } from 'react';
import {
  BrassButton,
  BrassButtonAdd,
  BrassButtonCancel,
  BrassButtonCheck,
  BrassButtonClearFilters,
  BrassButtonDelete,
  BrassButtonEdit,
  BrassButtonEnter,
  BrassButtonNo,
  BrassButtonOK,
  BrassButtonPrint,
  BrassButtonRefresh,
  BrassButtonSave,
  BrassButtonYes,
  BrassDropDownButton,
} from '../../components';
const itens = ['Login', 'Log Out'];

const ButtonsPlayground: FunctionComponent = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    <BrassButtonAdd />
    <BrassButtonCancel />
    <BrassButtonCheck />
    <BrassButtonClearFilters />
    <BrassButtonEdit />
    <BrassButtonDelete />
    <BrassButtonEnter />
    <BrassButtonNo />
    <BrassButtonOK />
    <BrassButtonPrint />
    <BrassButtonRefresh />
    <BrassButtonSave />
    <BrassButtonYes />
    <BrassButton>Brass</BrassButton>
    <BrassDropDownButton text='User Settings' items={itens} />
  </div>
);

export { ButtonsPlayground };
