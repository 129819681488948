import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Error, ErrorProps } from '@progress/kendo-react-labels';

interface BrassErrorProps extends ErrorProps {}

const BrassError: FunctionComponent<BrassErrorProps> = (props: BrassErrorProps) => {
  const { className } = props;
  const _className = classnames('brass-error', className);

  return <Error {...props} className={_className} />;
};

export { BrassError };
export type { BrassErrorProps };
