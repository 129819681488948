import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { BrassGridConfigDefault, gridReducerCard } from '../components';
import {
  Account,
  ApiGridResult,
  ApiResult,
  Breadcrumb,
  GridAction,
  GridActions,
  GridCallbacks,
  GridItem,
  GridManipulators,
  GridParams,
  GridState,
  Notification,
  NotificationStyles,
} from '../models';
import { emptyGuid } from '../util';
import { breadcrumbActions, notificationActions } from '../store';
import { useDebounce } from './useDebounce';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  process,
} from '@progress/kendo-data-query';

const useGrid = <T extends GridItem>(
  gridParams: GridParams<T>,
): GridManipulators => {
  const {
    getRecords,
    getRecordsParams,
    onRefreshLogic,
    triggerOnRefresh,
    updateRecord,
    updateRecordLogic,
    deleteRecord,
    deleteRecordLogic,
    editRecordLogic,
    addRecordLogic,
    translate,
  } = gridParams;
  const dispatch = useDispatch();
  const [gridState, gridDispatch] = useReducer<
    <T extends GridItem>(state: GridState<T>, action: GridAction) => GridState<T>
  >(gridReducerCard, {
    dataItemKey: 'id',
    dataResult: process([], BrassGridConfigDefault),
    gridLoading: true,
    recordsList: {},
    gridConfig: BrassGridConfigDefault,
    gridFilter: {
      logic: 'and',
      filters: [],
    },
    treeListFilter: [],
    group: undefined,
    collapsedState: [],
    recordId: undefined,
    editField: 'inEdit',
    dataState: BrassGridConfigDefault,
    selectedRecords: [],
    showFilterPanel: false,
    showHelpPanel: false,
  });

  useEffect((): void => {
    if (gridState.recordsList.records) {
      gridDispatch({
        type: GridActions.UPDATE_DATA_RESULT,
        payload: process(gridState.recordsList.records, {
          ...gridState.gridConfig,
          // filter: gridState.gridConfig.filter,
          filter: undefined,
        }),
      });
    }
  }, [gridState.recordsList.records, gridState.gridConfig]);

  useEffect((): void => {
    dispatch(
      breadcrumbActions.setBreadcrumb(Breadcrumb.createBreadcrumbs(translate)),
    );
  }, [dispatch]);

  const debouncedGridFilter: CompositeFilterDescriptor = useDebounce(
    gridState.gridFilter,
    1000,
  );

  const debouncedTreeListFilter: FilterDescriptor = useDebounce(
    gridState.treeListFilter,
    1000,
  );

  useEffect(() => {
    gridDispatch({
      type: GridActions.UPDATE_GRID_CONFIG,
      payload: {
        ...gridState.gridConfig,
        filter: debouncedGridFilter,
      },
    });
  }, [debouncedGridFilter]);

  useEffect(() => {
    gridDispatch({
      type: GridActions.UPDATE_TREE_LIST_FILTER,
      payload: debouncedTreeListFilter,
    });
  }, [debouncedTreeListFilter]);

  const onRefresh = (): void => {
    if (
      (Account.isValidAccountId() && triggerOnRefresh) ||
      (Account.isValidAccountId() && triggerOnRefresh === undefined)
    ) {
      gridDispatch({
        type: GridActions.UPDATE_GRID_LOADING,
        payload: true,
      });
      (getRecordsParams
        ? getRecords(gridState.gridConfig, getRecordsParams)
        : getRecords(gridState.gridConfig)
      )
        .then((apiResult: ApiResult<ApiGridResult<T>>) => {
          if (apiResult.success) {
            if (onRefreshLogic) {
              onRefreshLogic(apiResult.data);
              if (
                apiResult.data.blockedFieldList &&
                apiResult.data.blockedFieldList.length &&
                apiResult.data.records &&
                apiResult.data.records.length
              ) {
                apiResult.data.blockedFieldList.forEach((field: string) => {
                  apiResult.data.records = apiResult.data.records!.map((record) => {
                    const newRecord = { ...record };
                    (newRecord as any)[field] = '*****';
                    return newRecord;
                  });
                });
              }
            }
            gridDispatch({
              type: GridActions.UPDATE_RECORDS_LIST,
              payload: apiResult.data,
            });
          }
        })
        .finally(() =>
          gridDispatch({
            type: GridActions.UPDATE_GRID_LOADING,
            payload: false,
          }),
        );
    }
  };
  useEffect(() => {
    onRefresh();
  }, [gridState.gridConfig, getRecordsParams, triggerOnRefresh]);

  const onAdd = (): void => {
    if (addRecordLogic) {
      addRecordLogic();
    }
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: emptyGuid(),
    });
  };

  // Inicia a edição de um item da grid
  const onEdit = (record: GridItem): void => {
    if (editRecordLogic) {
      editRecordLogic(record);
    }
    gridDispatch({
      type: GridActions.UPDATE_RECORD_ID,
      payload: record.id,
    });
  };

  // Envia os dados para o backend atualizar o item
  const onUpdate = async (record: GridItem): Promise<void> => {
    try {
      const apiResult: ApiResult = await updateRecord!(record as T);
      if (apiResult.success) {
        onRefresh();

        if (updateRecordLogic) {
          updateRecordLogic(record);
        }
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnUpdate'),
            }),
          ),
        );
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: translate('errorOnUpdate', [apiResult.errorMessage!]),
            }),
          ),
        );
      }
    } catch (error: any) {
      dispatch(
        notificationActions.showNotification(
          new Notification({
            style: NotificationStyles.ERROR,
            content: translate('errorOnUpdate', [`${error}`]),
          }),
        ),
      );
    }
  };

  const onDelete = async (record: GridItem): Promise<void> => {
    try {
      const apiResult: ApiResult = await deleteRecord!(record.id);
      if (apiResult.success) {
        onRefresh();
        if (deleteRecordLogic) {
          deleteRecordLogic(record);
        }
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnDelete'),
            }),
          ),
        );
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: translate('errorOnDelete', [apiResult.errorMessage!]),
            }),
          ),
        );
      }
    } catch (error: any) {
      dispatch(
        notificationActions.showNotification(
          new Notification({
            style: NotificationStyles.ERROR,
            content: translate('errorOnDelete', [`${error}`]),
          }),
        ),
      );
    }
  };

  const gridCallbacks: GridCallbacks<GridItem> = {
    onAdd,
    onEdit,
    onUpdate,
    onDelete,
    onRefresh,
  };

  return {
    gridState,
    gridDispatch,
    gridCallbacks,
  };
};

export { useGrid };
