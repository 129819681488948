import React from 'react';
import { DataResult, process, State } from '@progress/kendo-data-query';
import { setGroupIds } from '@progress/kendo-react-data-tools';

const processWithGroups = (data: any[], _dataState: State): DataResult => {
  const newDataState = process(data, _dataState);
  setGroupIds({ data: newDataState.data, group: _dataState.group });
  return newDataState;
};

export { processWithGroups };
