import React, { FunctionComponent, useState } from 'react';
import classnames from 'classnames';
import style from './TreeListGrid.module.scss';
import { renderGrid } from './TreeListGrid.grid';
import { renderTreeList } from './TreeListGrid.treeList';
import { BrassGridConfigDefault } from '../util';
import { GridSortSettings } from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { GridSelectableSettings } from '@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings';
import { BrassTreeListGridProps } from './TreeListGridProps.interface';
import { BrassLogo } from '../../../shared';

const BrassTreeListGrid: FunctionComponent<BrassTreeListGridProps> = (
  props: BrassTreeListGridProps,
) => {
  const { className, pageable } = props;

  const _className = classnames('brass-grid', style['brass-grid'], className);

  const [dataState, setSataState] = useState<State>(BrassGridConfigDefault);

  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);

  const renderGridEvoke = (hideActionButton?: boolean) =>
    props.isTreeGrid
      ? renderTreeList(hideActionButton, props, _className)
      : renderGrid(
          hideActionButton,
          props,
          selectedRecords,
          setSelectedRecords,
          _className,
          dataState,
          setSataState,
        );

  return (
    <div style={{ display: 'block' }}>
      {renderGridEvoke(false)}
      {pageable}
    </div>
  );
};

BrassTreeListGrid.defaultProps = {
  pageable: true,
  groupable: false,
  reorderable: true,
  filterable: true,
  isTreeGrid: false,
  visibleButtonRefresh: true,
  showGridFooterOnlyInGroupMode: true,

  sortable: {
    mode: 'multiple',
  } as GridSortSettings,

  selectable: {
    drag: false,
    cell: false,
    enabled: false,
    mode: 'multiple',
  } as GridSelectableSettings,
};

export { BrassTreeListGrid };
