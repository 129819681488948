import { getLanguagesStorage } from '../../../index';
import { ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services/api.service';
import { IChatLikedPayload, IChatPayload, IChatResponse } from './CommentChat.model';

class ChatService {
  public static api: ApiService = new ApiService(Endpoints.ACADEMY);

  public static async insertChat(request: IChatPayload): Promise<ApiResult> {
    return await ChatService.api.post(`/connect/v1/chat`, request);
  }

  public static async insertLike(
    chatId: string,
    firstParentId: string,
    chatLiked: IChatLikedPayload,
  ): Promise<ApiResult> {
    return await ChatService.api.put(
      `/connect/v1/chat/${chatId}/firstChat/${firstParentId}/liked`,
      chatLiked,
    );
  }

  public static async getChat(
    id: string,
    language: string,
  ): Promise<ApiResult<IChatResponse[]>> {
    return await ChatService.api.get(`/connect/v1/chat/${id}?language=${language}`);
  }

  public static async getChatByReferenceId(
    referenceId: string,
    language = getLanguagesStorage()?.language,
  ): Promise<ApiResult<IChatResponse[]>> {
    return await ChatService.api.get(
      `/connect/v1/chat/reference/${referenceId}?language=${language}`,
    );
  }
}

export { ChatService };
