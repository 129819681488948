import { GridAction, GridActions, GridItem, GridState } from '../../../../../models';

const gridReducer = <T extends GridItem>(
  state: GridState<T>,
  action: GridAction,
): GridState<T> => {
  switch (action.type) {
    case GridActions.UPDATE_DATE_ITEM_KEY: {
      return {
        ...state,
        dataItemKey: action.payload,
      };
    }
    case GridActions.UPDATE_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload,
      };
    }
    case GridActions.UPDATE_DATA_RESULT: {
      return {
        ...state,
        dataResult: action.payload,
      };
    }
    case GridActions.UPDATE_EDIT_FIELD: {
      return {
        ...state,
        editField: action.payload,
      };
    }
    case GridActions.UPDATE_GRID_CONFIG: {
      return {
        ...state,
        gridConfig: action.payload,
      };
    }
    case GridActions.UPDATE_GRID_FILTER: {
      return {
        ...state,
        gridFilter: action.payload,
      };
    }
    case GridActions.UPDATE_TREE_LIST_FILTER: {
      return {
        ...state,
        treeListFilter: action.payload,
      };
    }
    case GridActions.UPDATE_GRID_LOADING: {
      return {
        ...state,
        gridLoading: action.payload,
      };
    }
    case GridActions.UPDATE_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case GridActions.UPDATE_COLLAPSED_STATE: {
      return {
        ...state,
        collapsedState: action.payload,
      };
    }
    case GridActions.UPDATE_RECORDS_LIST: {
      return {
        ...state,
        recordsList: action.payload,
      };
    }
    case GridActions.UPDATE_RECORD_ID: {
      return {
        ...state,
        recordId: action.payload,
      };
    }
    case GridActions.UPDATE_SELECTED_RECORDS: {
      return {
        ...state,
        selectedRecords: action.payload,
      };
    }
    case GridActions.UPDATE_SHOW_HELP_PANEL: {
      return {
        ...state,
        showHelpPanel: action.payload,
      };
    }
    case GridActions.UPDATE_SHOW_FILTER_PANEL: {
      return {
        ...state,
        showFilterPanel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { gridReducer };
