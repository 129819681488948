import { Endpoints } from '../../../models';
import { ApiService } from '../../../services';
// import { Account, ModuleEnum } from '../../../models';

class UserPermissionService {
  private static api: ApiService = new ApiService(Endpoints.AUTH);

  /*
  NOTA: rlzg: Não consegui priorizar esta chamada antes de outras chamadas API,
              assim em 22/07/2021 as permissões do usuário virão na Autenticação (AUTH)

  // UserPermissionService.getUserPermission([ModuleEnum.CONNECT, ModuleEnum.NAVIGATOR]);
  public static async getUserPermission(modules: ModuleEnum[]): Promise<ApiResult<number>> {
    const accountId: string = Account.getId();
    const apiResult: ApiResult<number> = await this.api.get<number>(`/connect/v1/account/${accountId}/user/permissions`, {
      params: {
        modules: modules.join('|'),
        // queryString.stringify({ modules: modules }),
      },
    });

    return apiResult;
  }
  */
}

export { UserPermissionService };
