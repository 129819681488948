import { I18nService, II18n } from '../../../../../';

const translations: II18n = {
  es: {
    titlePromoteToInitialFileView: 'Confirmación de cambio',
    areYouSurePromoteToInitialFileView:
      '¿Está seguro de que desea convertir este documento principal?',
    successOnDeleteSystem: 'Sistema eliminar correctamente',
    successOnDeleteArea: 'Area eliminar correctamente',
  },
  enUS: {
    titlePromoteToInitialFileView: 'Change confirmation',
    areYouSurePromoteToInitialFileView:
      'Are you sure you want to turn this main document?',
    successOnDeleteSystem: 'System successfully delete',
    successOnDeleteArea: 'Area successfully delete',
  },
  ptBR: {
    titlePromoteToInitialFileView: 'Confirmação de alteração',
    areYouSurePromoteToInitialFileView:
      'Você tem certeza que deseja tornar este documento como principal?',
    successOnDeleteSystem: 'Sistema removido com sucesso',
    successOnDeleteArea: 'Area removida com sucesso',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
