import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconVerySatisfied: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.98667 0.58416C3.02983 0.590865 0.615332 2.97816 0.584498 5.94349C0.553313 8.93216 2.95383 11.3845 5.94367 11.4157C8.93217 11.4468 11.3847 9.04649 11.4158 6.05783C11.447 3.06916 9.04533 0.615493 6.05667 0.584326C6.03329 0.584066 6.00999 0.583936 5.98674 0.584066L5.98667 0.58416ZM5.98751 0.960526C6.00919 0.960461 6.03094 0.960591 6.05275 0.960787C8.83791 0.989823 11.0684 3.26879 11.0394 6.05395C11.0104 8.83912 8.73275 11.0685 5.94758 11.0393C3.16108 11.0102 0.931746 8.73279 0.960913 5.94745C0.989755 3.18395 3.23208 0.96662 5.98758 0.96062L5.98751 0.960526Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.63898 7.42083C3.57681 7.42018 3.51841 7.45026 3.48273 7.50117C3.44712 7.55208 3.43898 7.61732 3.46092 7.67545C3.92602 8.90879 4.85187 9.78729 5.95976 9.79845H5.95995C7.06784 9.81069 8.01262 8.95242 8.50395 7.72945C8.52713 7.67177 8.52029 7.60641 8.48579 7.55479C8.45128 7.50309 8.39347 7.47178 8.3313 7.47113L3.63896 7.42093L3.63898 7.42083ZM3.9199 7.80027L8.0424 7.84434C7.57293 8.82201 6.80268 9.43139 5.9639 9.42194V9.42201H5.96371C5.12504 9.41367 4.36832 8.78796 3.91987 7.80032L3.9199 7.80027Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.20701 3.78049C3.1809 3.7801 3.15499 3.78114 3.12927 3.78336C2.85512 3.80751 2.62127 3.99476 2.53084 4.26017C2.35154 4.78674 2.65512 5.54812 3.68442 6.28517V6.28524C3.7186 6.31903 3.7645 6.33843 3.81255 6.33947C3.81574 6.3396 3.81886 6.3396 3.82205 6.3396C3.82296 6.33954 3.82394 6.33954 3.82485 6.33954C3.87179 6.33862 3.91678 6.3202 3.95083 6.28784C4.99471 5.57319 5.31378 4.81811 5.14529 4.28801C5.06033 4.02069 4.83044 3.82857 4.55676 3.79856C4.32668 3.77336 4.07674 3.85266 3.84036 4.03431C3.63384 3.86862 3.41498 3.78339 3.20703 3.78066L3.20701 3.78049ZM3.18845 4.15692C3.3227 4.15452 3.50727 4.22027 3.70473 4.41748C3.77713 4.48981 3.89398 4.49104 3.96788 4.42027C4.18285 4.21436 4.38005 4.15771 4.5158 4.17262C4.65148 4.18747 4.74139 4.2596 4.78657 4.40192C4.87361 4.67589 4.71717 5.27672 3.82133 5.91897C2.93872 5.25757 2.79463 4.65341 2.88715 4.38161C2.9352 4.2404 3.02641 4.17034 3.16228 4.15842C3.17081 4.15764 3.17947 4.15719 3.18846 4.15706L3.18845 4.15692Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.56665 3.82633C7.54055 3.82594 7.51464 3.82698 7.48892 3.82926C7.21477 3.85361 6.98097 4.04091 6.89055 4.30641C6.71112 4.83297 7.01432 5.59449 8.04297 6.33158V6.33164C8.07715 6.36543 8.12298 6.38483 8.17109 6.38587C8.17422 6.386 8.17741 6.386 8.1806 6.38594H8.18346C8.23047 6.38503 8.27546 6.36654 8.30951 6.33411C9.35391 5.61888 9.67324 4.864 9.50501 4.33378C9.42018 4.0664 9.19029 3.87428 8.91666 3.8442C8.68657 3.81887 8.43664 3.8981 8.20019 4.07948C7.99361 3.91399 7.77461 3.82896 7.56666 3.82641L7.56665 3.82633ZM7.54842 4.20283C7.68273 4.20029 7.86717 4.26592 8.06457 4.4628C8.13697 4.535 8.25377 4.53617 8.32759 4.46553C8.54256 4.25993 8.73976 4.20336 8.87544 4.21826C9.01118 4.23317 9.10103 4.30531 9.14614 4.44756C9.23306 4.72146 9.07628 5.32243 8.17981 5.96526C7.29791 5.30393 7.15396 4.69983 7.24667 4.42776C7.29485 4.28642 7.38619 4.21625 7.52212 4.20413C7.53059 4.20335 7.53938 4.20289 7.5483 4.20276L7.54842 4.20283Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconVerySatisfied };
