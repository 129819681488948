import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { BrassButton } from '../buttons';
import {
  getMultiSelectTreeValue,
  MultiSelectTree,
  MultiSelectTreeChangeEvent,
  MultiSelectTreeExpandEvent,
  MultiSelectTreeProps,
} from '@progress/kendo-react-dropdowns';
import {
  expandedState,
  processMultiSelectTreeData,
} from './MultiSelectTree.functions';
import { BrassTextField } from '../form';

interface BrassMultiSelectTreeItems {
  text: string;
  value: string;
  children?: BrassMultiSelectTreeItems[];
}

interface BrassMultiSelectTreeProps
  extends Omit<MultiSelectTreeProps, 'textField' | 'dataItemKey' | 'disabled'> {
  data: BrassMultiSelectTreeItems[];
  onLeftButtonClick?: () => any;
  leftButtonIcon?: any;
  selectParentItem?: boolean;
  textField?: string;
  checkIndeterminateField?: string;
  checkField?: string;
  dataItemKey?: string;
  onChangeCallback: (newValues: string[]) => any;
}

const BrassMultiSelectTree: FunctionComponent<BrassMultiSelectTreeProps> = (
  props: BrassMultiSelectTreeProps,
) => {
  const {
    checkField,
    checkIndeterminateField,
    textField,
    expandField,
    dataItemKey,
    subItemsField,
    data,
  } = props;
  const [value, setValue] = React.useState<any[]>([]);
  const [expanded, setExpanded] = React.useState<any[]>([]);

  useEffect(() => {
    if (props.defaultValue.length > 0) {
      setValue(props.defaultValue);
    }
  }, []);

  const fields = {
    dataItemKey: dataItemKey!,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
  };

  const onChange = (event: MultiSelectTreeChangeEvent) => {
    const response = getMultiSelectTreeValue(data, { ...fields, ...event, value });
    setValue(response);
    props.onChangeCallback(response);
  };

  const onExpandChange = React.useCallback(
    (event: MultiSelectTreeExpandEvent) => {
      setExpanded(expandedState(event.item, dataItemKey!, expanded));
    },
    [expanded],
  );
  const treeData = processMultiSelectTreeData(data, {
    expanded,
    value,
    ...fields,
  });

  return (
    <div style={{ display: 'flex' }}>
      {props.onLeftButtonClick && (
        <BrassButton
          fillMode='flat'
          themeColor='base'
          onClick={props.onLeftButtonClick}
          style={{ float: 'left' }}
          type='button'
        >
          {props.leftButtonIcon}
        </BrassButton>
      )}
      <MultiSelectTree
        {...props}
        data={treeData}
        onChange={onChange}
        value={value}
        placeholder='Select ...'
        textField={textField!}
        dataItemKey={dataItemKey!}
        checkField={checkField}
        checkIndeterminateField={checkIndeterminateField}
        subItemsField={subItemsField}
        expandField={expandField}
        onExpandChange={onExpandChange}
      />
    </div>
  );
};

BrassMultiSelectTree.defaultProps = {
  expandField: 'expanded',
  dataItemKey: 'value',
  checkIndeterminateField: 'checkIndeterminateField',
  checkField: 'isSelected',
  textField: 'text',
  subItemsField: 'children',
  selectParentItem: false,
};

export { BrassMultiSelectTree };
export type { BrassMultiSelectTreeProps };
