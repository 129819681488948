import React, { FunctionComponent, useState, memo } from 'react';
import { translate } from './ProjectStatusDropDownButton.translations';
import { DropDownData } from '../../../../../models';
import {
  BrassDropDownButton,
  BrassDropDownButtonProps,
} from '../../../../../components';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';

enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ALL = 'ALL',
}

interface ProjectStatusDropdownButtonProps extends BrassDropDownButtonProps {
  onSelectItem: (projectStatus: ProjectStatus) => void;
}

const ProjectStatusDropdownButton: FunctionComponent<ProjectStatusDropdownButtonProps> =
  memo((props: ProjectStatusDropdownButtonProps) => {
    const projectStatus: DropDownData[] = Object.entries(ProjectStatus).map(
      ([text, value]) => {
        return { text: translate(text), value };
      },
    );

    const [selectedProjectStatus, setSelectedProjectStatus] = useState<DropDownData>(
      projectStatus.find((p) => p.value === ProjectStatus.ACTIVE)!,
    );

    return (
      <BrassDropDownButton
        key='ProjectStatusDropdownButton'
        fillMode='outline'
        textField='text'
        items={projectStatus.filter((f) => f !== selectedProjectStatus)}
        text={selectedProjectStatus.text}
        onItemClick={(event: DropDownButtonItemClickEvent) => {
          setSelectedProjectStatus(event.item);
          props.onSelectItem(event.item.value);
        }}
        {...props}
      />
    );
  });

export { ProjectStatusDropdownButton };
export type { ProjectStatusDropdownButtonProps };
