import React, { FunctionComponent } from 'react';
import style from './ListViewHeader.module.scss';
import classnames from 'classnames';
import { ListViewHeader } from '@progress/kendo-react-listview';
import ListViewHeaderProps from '@progress/kendo-react-listview/dist/npm/interfaces/ListViewHeaderProps';

interface BrassListViewHeaderProps extends ListViewHeaderProps {}

const BrassListViewHeader: FunctionComponent<BrassListViewHeaderProps> = (
  props: BrassListViewHeaderProps,
) => {
  const { children, className } = props;
  const _className = classnames(style['brass-listview-header'], className);

  return <ListViewHeader className={_className}>{children}</ListViewHeader>;
};

export { BrassListViewHeader };
export type { BrassListViewHeaderProps };
