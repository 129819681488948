import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  BrassSplitter,
  AuthUser,
  NavigatorPermissions,
  AccessDenied,
  BrassIcon,
  EncryptionService,
  ViewerContext,
  EnvironmentService,
  ButtonFloatingAssetManagement,
} from '../../../../../';
import { SplitterOnChangeEvent } from '@progress/kendo-react-layout';
import classnames from 'classnames';
import { MainViewerManagement } from './MainViewerManagement';
import style from './MainViewer.module.scss';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { ButtonFloatingDocumentUploadV1 } from '../../../buttons/ButtonFloatingDocumentUpload.v1';

const MainViewer: FunctionComponent = () => {
  const {
    showSidePanel,
    showHideUploadDocument,
    showHideAssetManagement,
    showAddAssetDialog,
    navigatorFile,
    loadingViewer,
  } = useContext(ViewerContext);
  const sidePanelId = EncryptionService.getMd5Encrypt(
    `${navigatorFile.name}${navigatorFile.document.id}`,
  );
  const [nestedPanes, setNestedPanes] = useState<Array<any>>([
    {},
    { size: 0, resizable: false, min: 470 },
  ]);

  const allowViewerRecord = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_FILE_READ,
  );

  useEffect(() => {
    if (showSidePanel) {
      if (EnvironmentService.isNavigatorIntegrator) {
        setNestedPanes([
          ...nestedPanes,
          (nestedPanes[1].size = '31.5vw'),
          (nestedPanes[1].resizable = true),
        ]);
      }
      if (EnvironmentService.isNavigatorSurvey && !showAddAssetDialog) {
        setNestedPanes([
          ...nestedPanes,
          (nestedPanes[1].size = '31.5vw'),
          (nestedPanes[1].resizable = true),
        ]);
      }
    } else {
      setNestedPanes([
        ...nestedPanes,
        (nestedPanes[1].size = '0vw'),
        (nestedPanes[1].resizable = false),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSidePanel]);

  const onNestedChange = (event: SplitterOnChangeEvent): void =>
    setNestedPanes(event.newState);

  if (!allowViewerRecord) {
    return <AccessDenied />;
  }

  return (
    <>
      <BrassSplitter
        panes={nestedPanes}
        orientation='horizontal'
        onChange={onNestedChange}
      >
        <div style={{ width: '100%' }}>
          {EnvironmentService.isNavigatorIntegrator && (
            <ButtonFloatingDocumentUploadV1
              themeColor='primary'
              text={(<BrassIcon icon={faCloudUploadAlt} size='2x' />) as any}
              className={classnames(style['button-upload-document'])}
              onClick={showHideUploadDocument}
            />
          )}
          {EnvironmentService.isNavigatorSurvey && !loadingViewer && (
            <ButtonFloatingAssetManagement
              themeColor={showAddAssetDialog ? 'light' : 'primary'}
              className={classnames(style['button-asset-management'])}
              onClick={showHideAssetManagement}
            />
          )}
          <MainViewerManagement />
        </div>
        <div id={`side-informations-${sidePanelId}`}></div>
      </BrassSplitter>
      <div id={`float-informations-${sidePanelId}`} />
    </>
  );
};

export { MainViewer };
