import React from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    title: 'Cargar archivos adjuntos',
    addImages: 'Agregar imagenes',
    addVideos: 'Agregar videos',
    addFiles: 'Agregar archivos',
    add3d: 'Adicionar modelos 3d y diseños',
  },
  enUS: {
    title: 'Upload attachments',
    addImages: 'Add images',
    addVideos: 'Add videos',
    addFiles: 'Add files',
    add3d: 'Add 3d models and drawings',
  },
  ptBR: {
    title: 'Carregar anexos',
    addImages: 'Adicionar imagens',
    addVideos: 'Adicionar vídeos',
    addFiles: 'Adicionar arquivos',
    add3d: 'Adicionar modelos 3d e desenhos',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
