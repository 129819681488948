import React, {
  memo,
  Dispatch,
  Fragment,
  useState,
  useContext,
  SetStateAction,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import {
  newGuid,
  orderBy,
  AuthUser,
  BrassTab,
  TabsModel,
  BrassLoader,
  Notification,
  AreaTypeEnum,
  BrassListView,
  ViewerContext,
  SystemTypeEnum,
  BrassTabContainer,
  // NavigatorItemType,
  NavigatorFileType,
  NavigatorFileChild,
  BrassConfirmDialog,
  EnvironmentService,
  NotificationStyles,
  notificationActions,
  NavigatorPermissions,
  NavigatorFileChildItem,
  BrassConfirmExclusionDialog,
} from '../../../../../';
import { NavigatorItemType } from '../../../../../models/viewer/enum/navigator.enum';
import { useDispatch } from 'react-redux';
import { NavigatorCard } from '../../../../../components';
import { useNavigate } from 'react-router-dom';
import style from './BottomDetailsViewer.module.scss';
import { UpsertArea } from '../../../../../modules/area';
import { translate } from './BottomDetailsViewer.translations';
import { UpsertNavigatorFile } from '../../../../../modules/navigator-file';
import { DetailsViewerBar } from './details-viewer-bar/DetailsViewerBar';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { UpsertSystem } from '../../../../../modules/system/upsert-system/UpsertSystem';
import {
  AreaService,
  SystemService,
  FileNavigatorService,
} from '../../../../../services/';

interface NavigatorCardByGroupProps extends PropsWithChildren<any> {
  dataItem?: NavigatorFileChildItem;
  groupTitle: string;
}

const BottomDetailsViewer: FunctionComponent = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prefix = EnvironmentService.isNavigatorSurvey ? '/survey' : '/integrator';
  const {
    showDetails,
    navigatorFile,
    loadingViewer,
    loadingDetailsViewer,
    loadData,
    detailsViewerBarFilterValue,
  } = useContext(ViewerContext);
  const [selected, setSelected] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [showUpsertArea, setShowUpsertArea] = useState<
    NavigatorFileChildItem | undefined
  >(undefined);
  const [showUpsertSystem, setShowUpsertSystem] = useState<
    NavigatorFileChildItem | undefined
  >(undefined);
  const [showUpsertNavigatorFile, setShowUpsertNavigatorFile] = useState<
    NavigatorFileChildItem | undefined
  >(undefined);
  const [showConfirmDeleteRecord, setShowConfirmDeleteRecord] = useState<
    NavigatorFileChildItem | undefined
  >(undefined);
  const [showConfirmPromoteToInitialFileView, setConfirmPromoteToInitialFileView] =
    useState<NavigatorFileChildItem | undefined>(undefined);
  const allowEditRecord =
    AuthUser.checkPermission(NavigatorPermissions.NAVIGATOR_FILE_UPDATE) &&
    EnvironmentService.isNavigatorIntegrator;
  const allowDeleteRecord = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_FILE_DELETE,
  );
  /* eslint-disable */
  const handleSelect = (e: TabStripSelectEventArguments): void =>
    setSelected(e.selected);
  const onClickNavigatorCard = (
    item: NavigatorFileChildItem,
    setCardLoading: Dispatch<SetStateAction<boolean>>,
  ): void => {
    // Name e projectId serão enviados em todos os
    let eventDetail: TabsModel = new TabsModel({
      name: item.name,
      projectId: item.projectId,
    });
    if (item.type === NavigatorItemType.AREA) {
      eventDetail = { ...eventDetail, areaId: item.id };
      const event = new CustomEvent('newTab', {
        detail: eventDetail,
      });
      window.dispatchEvent(event);
      setCardLoading(false);
    } else if (item.type === NavigatorItemType.SYSTEM) {
      eventDetail = { ...eventDetail, systemId: item.id };
      const event = new CustomEvent('newTab', {
        detail: eventDetail,
      });
      window.dispatchEvent(event);
      setCardLoading(false);
    } else if (item.type === NavigatorItemType.FILE) {
      if (item.fileType === NavigatorFileType.UNDEFINED) {
        setCardLoading(false);
      } else if (item.fileType === NavigatorFileType.ZIP) {
        setCardLoading(false);
        if (item.urlDownload) {
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(item.urlDownload);
        }
      } else {
        eventDetail = { ...eventDetail, fileId: item.id };
        const event = new CustomEvent('newTab', {
          detail: eventDetail,
        });
        window.dispatchEvent(event);
        setCardLoading(false);
      }
    }
  };

  if (loadingViewer) {
    return <Fragment />;
  }

  const promoteToInitialFileView = (rec: NavigatorFileChildItem): void => {
    if (rec.type === NavigatorItemType.FILE) {
      setLoading(true);

      FileNavigatorService.promoteToInitialFileView(rec.id).then(() => {
        loadData();
        setLoading(false);
      });
    }
    setConfirmPromoteToInitialFileView(undefined);
  };
  const deleteRecord = (rec: NavigatorFileChildItem): void => {
    setLoading(true);

    if (rec.type === NavigatorItemType.FILE) {
      FileNavigatorService.deleteFileNavigator(rec.id).then(() => {
        loadData();
        setLoading(false);
      });
    } else if (rec.type === NavigatorItemType.SYSTEM) {
      SystemService.deleteSystem(rec.projectId, rec.id).then(() => {
        loadData();
        setLoading(false);
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnDeleteSystem'),
            }),
          ),
        );
      });
    } else if (rec.type === NavigatorItemType.AREA) {
      AreaService.deleteArea(rec.projectId, rec.id).then(() => {
        loadData();
        setLoading(false);
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnDeleteArea'),
            }),
          ),
        );
      });
    }

    setShowConfirmDeleteRecord(undefined);
  };
  const editRecord = (rec: NavigatorFileChildItem): void => {
    if (rec.type === NavigatorItemType.AREA) {
      setShowUpsertArea(rec);
    } else if (rec.type === NavigatorItemType.SYSTEM) {
      setShowUpsertSystem(rec);
    } else if (rec.type === NavigatorItemType.FILE) {
      setShowUpsertNavigatorFile(rec);
    }
  };

  const NavigatorCardByGroup: FunctionComponent<NavigatorCardByGroupProps> = (
    props: NavigatorCardByGroupProps,
  ): any => {
    const record = props.dataItem as NavigatorFileChildItem;
    const { conversionFinished } = record;

    let onClickPromoteToInitialFileView;
    if (
      allowEditRecord &&
      record.type === NavigatorItemType.FILE &&
      record.fileType !== NavigatorFileType.ZIP
    ) {
      onClickPromoteToInitialFileView = () =>
        setConfirmPromoteToInitialFileView(record);
    }

    return (
      <NavigatorCard
        key={record.id}
        onClick={(setCardLoading: Dispatch<SetStateAction<boolean>>) =>
          onClickNavigatorCard(record, setCardLoading)
        }
        tag={record.tag}
        name={record.name}
        cardDescription={record.description}
        conversionFinished={conversionFinished}
        thumbnail={
          conversionFinished ? record.thumbnail : '/images/file/file-converting.png'
        }
        onClickEdit={() => allowEditRecord && editRecord(record)}
        onClickDelete={() => allowDeleteRecord && setShowConfirmDeleteRecord(record)}
        onClickPromoteToInitialFileView={onClickPromoteToInitialFileView}
      />
    );
  };

  // NOTA: quando for arquivo não haverá filhos assim não exibe o TABContainer
  const showTabContainer = !navigatorFile.document.isFile;
  navigatorFile.children = orderBy(navigatorFile.children, 'title');
  return (
    <Fragment>
      <DetailsViewerBar />
      {showTabContainer && showDetails && (
        <Fragment>
          {loadingDetailsViewer && <BrassLoader useLoadingMask />}
          {!loadingDetailsViewer && (
            <BrassTabContainer
              selected={selected}
              onSelect={handleSelect}
              className={style['brass-tab-container']}
            >
              {navigatorFile.children.map((child: NavigatorFileChild) => {
                // Ordenação da TAB com base no TAG ou Name
                child.items = child.items.map((item) => {
                  const orderByValue = item.tag ? item.tag : item.name;
                  return {
                    ...item,
                    orderByField: orderByValue,
                  };
                });

                child.items = orderBy(child.items, 'orderByField');

                return (
                  <BrassTab key={newGuid()} title={child.title}>
                    {loading && <BrassLoader useLoadingMask />}
                    <BrassListView
                      data={child.items.filter((item: NavigatorFileChildItem) =>
                        (item.orderByField || '')
                          .toLowerCase()
                          .includes(detailsViewerBarFilterValue.toLowerCase()),
                      )}
                      item={(props: any) => (
                        <NavigatorCardByGroup
                          groupTitle={child.title}
                          dataItem={props.dataItem}
                        />
                      )}
                      className={style['list-view']}
                    />
                  </BrassTab>
                );
              })}
            </BrassTabContainer>
          )}
        </Fragment>
      )}

      {allowEditRecord && showUpsertArea && (
        <UpsertArea
          onSaveClose={() => {
            setShowUpsertArea(undefined);
            loadData();
          }}
          onCancelClose={() => setShowUpsertArea(undefined)}
          areaId={showUpsertArea.id}
          projectId={showUpsertArea.projectId}
          type={AreaTypeEnum.PHYSICAL}
          titleComplement={showUpsertArea.name}
        />
      )}
      {allowEditRecord && showUpsertSystem && (
        <UpsertSystem
          onSaveClose={() => {
            setShowUpsertSystem(undefined);
            loadData();
          }}
          onCancelClose={() => setShowUpsertSystem(undefined)}
          systemId={showUpsertSystem.id}
          areaId={showUpsertSystem.areaId}
          projectId={showUpsertSystem.projectId}
          type={SystemTypeEnum.PHYSICAL}
          titleComplement={showUpsertSystem.name}
        />
      )}
      {allowEditRecord && showUpsertNavigatorFile && (
        <UpsertNavigatorFile
          onSaveClose={() => {
            setShowUpsertNavigatorFile(undefined);
            loadData();
          }}
          onCancelClose={() => setShowUpsertNavigatorFile(undefined)}
          navigatorFileId={showUpsertNavigatorFile.id}
          projectId={showUpsertNavigatorFile.projectId}
        />
      )}
      {allowEditRecord && showConfirmPromoteToInitialFileView && (
        <BrassConfirmDialog
          onYes={() => promoteToInitialFileView(showConfirmPromoteToInitialFileView)}
          onNo={() => setConfirmPromoteToInitialFileView(undefined)}
          title={translate('titlePromoteToInitialFileView')}
          content={translate('areYouSurePromoteToInitialFileView')}
        />
      )}
      {allowDeleteRecord && showConfirmDeleteRecord && (
        <BrassConfirmExclusionDialog
          onYes={() => deleteRecord(showConfirmDeleteRecord)}
          onNo={() => setShowConfirmDeleteRecord(undefined)}
        />
      )}
    </Fragment>
  );
});

export { BottomDetailsViewer };
