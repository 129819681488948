import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { ChartTitle, ChartTitleProps } from '@progress/kendo-react-charts';

interface BrassTitleProps extends ChartTitleProps, PropsWithChildren<any> {}

const BrassChartTitle: FunctionComponent<BrassTitleProps> = (
  props: BrassTitleProps,
) => {
  const { className } = props;
  const _className = classnames('brass-chart-title', className);
  return (
    <div className={_className}>
      <ChartTitle {...props} />
    </div>
  );
};

export { BrassChartTitle };
export type { BrassTitleProps };
