import React, { Fragment, FunctionComponent } from 'react';
import {
  Field,
  FieldProps,
  FieldValidatorType,
  FieldWrapper,
} from '@progress/kendo-react-form';
import {
  BrassError,
  BrassField,
  BrassHint,
  BrassLoader,
  BrassRadioGroupButton,
  BrassRadioGroupButtonProps,
} from '../../../';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';
import { InputChangeEvent } from '@progress/kendo-react-inputs';

const translations: II18n = {
  es: {
    required: 'Este campo é obrigatório',
    successOnAdd: 'Cliente agregado correctamente',
    errorOnAdd: 'Error al intentar agregar los Clientes',
    successOnUpdate: 'Cliente actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar los Clientes',
  },
  enUS: {
    required: 'This field is required',
    successOnAdd: 'Customer successfully added',
    errorOnAdd: 'Error while trying to add the Customer',
    successOnUpdate: 'Customer successfully updated',
    errorOnUpdate: 'Error while trying to update the Customer',
  },
  ptBR: {
    required: 'Este campo é obrigatório',
    successOnAdd: 'Cliente adicionado com sucesso',
    errorOnAdd: 'Erro ao tentar adicionar os Clientes',
    successOnUpdate: 'Cliente atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar os Clientes',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface BrassRadioGroupButtonFieldInputProps extends BrassRadioGroupButtonProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
  layout?: 'horizontal' | 'vertical';
}

const BrassRadioGroupButtonFieldInput: FunctionComponent<
  BrassRadioGroupButtonFieldInputProps
> = (fieldRenderProps: any): any => {
  const { validationMessage, visited } = fieldRenderProps;

  return (
    <Fragment>
      <BrassRadioGroupButton {...fieldRenderProps} />
      {visited && validationMessage && <BrassError>{validationMessage}</BrassError>}
    </Fragment>
  );
};

interface BrassRadioGroupButtonFieldProps
  extends Omit<FieldProps, 'valid, component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  value?: string;
  visited?: boolean;
  required?: boolean;
  data?: Array<any>;
  buttonClassName?: any;
}

const BrassRadioGroupButtonField: FunctionComponent<
  BrassRadioGroupButtonFieldProps
> = (props: BrassRadioGroupButtonFieldProps) => {
  const requiredValidator = (_: any) => {
    return props.defaultValue && props.required ? '' : translate('required');
  };

  const { name, required, loading } = props;

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassRadioGroupButton}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label>{required ? `${props.label}*` : props.label}</Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : BrassRadioGroupButtonFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label */}
      />
    </FieldWrapper>
  );
};

export { BrassRadioGroupButtonField };
export type { BrassRadioGroupButtonFieldProps };
