import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonNext: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-next'
    labelKey='next'
    tooltipKey='clickToNext'
    translations={translations}
    icon={<BrassIcon icon={faChevronRight} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    next: 'próximo',
    clickToNext: 'haga clic para avançar',
  },
  enUS: {
    next: 'next',
    clickToNext: 'click to go next',
  },
  ptBR: {
    next: 'avançar',
    clickToNext: 'clique para avançar',
  },
};

export { BrassButtonNext };
