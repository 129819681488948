import React, { FunctionComponent } from 'react';
import {
  SystemClassificationDropDownProps,
  SystemClassificationDropdown,
} from '../../../../';

const SystemClassificationField: FunctionComponent<
  SystemClassificationDropDownProps
> = (props: SystemClassificationDropDownProps) => (
  <SystemClassificationDropdown
    {...props}
    name='systemClassificationId'
    style={{ width: 600 }}
  />
);

export { SystemClassificationField };
