import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    comments: 'Comentarios',
    noCommentsSelected: 'Ningún comentario seleccionado.',
    messagesInTotal: 'mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',
  },
  enUS: {
    comments: 'Comments',
    noCommentsSelected: 'No comments selected.',
    messagesInTotal: 'messages in total',
    alert: 'Alert',
    alerts: 'Alerts',
  },
  ptBR: {
    comments: 'Comentários',
    noCommentsSelected: 'Nenhum comentário selecionado.',
    messagesInTotal: 'mensagens no total',
    alert: 'Alerta',
    alerts: 'Alertas',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
