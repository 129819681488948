import {
  SVGIconLike,
  SVGIconChat,
  BrassLoader,
  BrassTooltip,
  BrassAvatarV1,
} from '../../../../index';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IChatResponse, LikeType } from '../CommentChat.model';
import { BrassInputChat } from '../input-chat/InputChat';
import { ChatBlockFunctions } from './ChatBlock.functions';
import style from './ChatBlock.module.scss';
import { translate } from './ChatBlock.translations';

interface IBrassChatBlockV1Props {
  text: string;
  user: string;
  likes: LikeType[];
  messageParentId?: string;
  chatId: string;
  messages: IChatResponse[];
  referenceId: string;
  firstParentId: string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  canComment: boolean;
  canLike: boolean;
  color?: string;
  background?: string;
}

const BrassChatBlockV1 = (props: IBrassChatBlockV1Props): any => {
  const {
    text,
    user,
    likes,
    messages,
    setRefresh,
    canComment,
    canLike,
    color,
    background,
  } = props;
  const {
    openInput,
    setOpenInput,
    setMessage,
    message,
    disableButton,
    insertCommentInChat,
    insertLiked,
    handleInsertKeyPressEnter,
    isLoading,
    verifyLike,
    isLoadingLike,
    formatName,
  } = ChatBlockFunctions({ dataChat: props, setRefresh, likes });

  const [likesByUsers, setLikesByUsers] = useState<any>([]);

  useEffect(() => {
    if (likes?.length > 0) {
      for (let i = 0; i < 3 && i < likes?.length; i++) {
        setLikesByUsers((prev: any) => [...prev, likes[i]]);
      }
    }
  }, [likes]);

  return (
    <div className={style['chat-block']} style={{ background }}>
      <strong className={style['chat-block-user']}>{user}</strong>
      <p className={style['chat-block-description']}>{text}</p>
      {openInput &&
        canComment &&
        (isLoading ? (
          <BrassLoader type={'pulsing'} style={{ color: 'black' }} />
        ) : (
          <BrassInputChat
            buttonHtmlElement={{
              onClick: insertCommentInChat,
              disabled: !message || disableButton,
            }}
            inputHtmlElement={{
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setMessage(e?.target?.value),
              value: message,
              onKeyPress: handleInsertKeyPressEnter,
            }}
            color={color}
          />
        ))}
      <div className={style['chat-block-actions']}>
        {isLoadingLike ? (
          <BrassLoader
            type='pulsing'
            style={{ color: 'black', width: '20px' }}
            size={'small'}
          />
        ) : (
          <>
            {likes?.length > 0 && likesByUsers?.length > 0 && (
              <div className={style['box-avatar']}>
                {likes?.length > 3 && (
                  <span className={style['count']}>+{likes?.length - 3}</span>
                )}
                {likesByUsers?.map((i: any) => (
                  <BrassAvatarV1
                    type='text'
                    content={formatName(i?.userName)}
                    tooltip={i?.userName}
                    className={style['avatar']}
                  />
                ))}
              </div>
            )}
            <span className={style['chat-block-actions-counts']}>
              {likes.length}
            </span>
            <BrassTooltip>
              <span title={translate('supportComment')} className={style.tooltip}>
                <SVGIconLike
                  className={classNames(style['icon'])}
                  onClick={() => {
                    if (canLike) {
                      insertLiked('like-heart');
                    }
                  }}
                  style={{
                    fill: verifyLike(likes) ? '#70ad47' : color ?? '#f6873c',
                    cursor: canLike ? 'pointer' : 'default',
                  }}
                />
              </span>
            </BrassTooltip>
          </>
        )}

        <span className={style['chat-block-actions-counts']}>{messages.length}</span>
        <BrassTooltip>
          <span title={translate('addComment')} className={style.tooltip}>
            <SVGIconChat
              className={classNames(style['icon'])}
              style={{
                fill: messages?.length > 0 ? '#70ad47' : color ?? '#f6873c',
                cursor: canComment ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (canComment) {
                  setOpenInput((prev) => !prev);
                }
              }}
            />
          </span>
        </BrassTooltip>
      </div>
    </div>
  );
};

export { BrassChatBlockV1 };
export type { IBrassChatBlockV1Props };
