import { UserNotification } from './';
import { emptyGuid } from '../../../util/';
import { CommunicationsStatus } from './enum';

class UserCommunications extends UserNotification {
  public id: string = emptyGuid();
  public title: string = '';
  public content?: string = undefined;

  constructor(userCommunications: Partial<UserCommunications>) {
    super(userCommunications);
    Object.assign(this, userCommunications);
  }
}

export { UserCommunications };
