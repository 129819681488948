import React, { FunctionComponent, memo, PropsWithChildren } from 'react';
import style from './NotificationList.module.scss';
import { translate } from './NotificationList.translations';
import {
  BrassBadgeNew,
  BrassChip,
  BrassDivFlex,
  BrassListView,
  BrassListViewFooter,
  BrassListViewHeader,
} from '../../../../../components';
import { NotificationStatus, UserAlert, UserNotification } from '../../../models';
import { ListViewItemProps } from '@progress/kendo-react-listview';
import { DateTimeService } from '../../../../../services';

interface NotificationListProps extends PropsWithChildren<any> {
  data: UserNotification[];
  onAlertClick: (alerts: UserAlert[]) => void;
}

const ItemRender = (
  props: ListViewItemProps,
  onAlertClick: (alerts: UserAlert[]) => void,
) => {
  const item: UserNotification = props.dataItem;

  const getThemeColor = (
    alertCount: number,
  ): 'base' | 'warning' | 'error' | 'info' | 'success' | null | undefined => {
    if (alertCount < 5) {
      return 'base';
    } else if (alertCount >= 5 && alertCount < 8) {
      return 'warning';
    } else {
      return 'error';
    }
  };

  const defaultBackgroundColor =
    item.status === NotificationStatus.CREATED ? 'unset' : '#f5f7f7';

  const notificationTitle = () => {
    let defaultTitle = (
      <div style={{ color: '#202124', paddingBlock: '4px', marginTop: '6px' }}>
        {item.title}
      </div>
    );

    if (item.status === NotificationStatus.CREATED) {
      defaultTitle = (
        <h2 className={style.itemTitle}>
          <BrassBadgeNew align={{ horizontal: 'end', vertical: 'top' }} shape='dot'>
            <div style={{ marginRight: 10, fontWeight: 'bolder' }}>{item.title}</div>
          </BrassBadgeNew>
        </h2>
      );
    }

    return defaultTitle;
  };

  return (
    <div
      className={style.list}
      style={{
        margin: 0,
        backgroundColor: defaultBackgroundColor,
      }}
    >
      <div>
        {notificationTitle()}
        <div style={{ fontSize: 12, color: '#a0a0a0' }}>{item.message}</div>
      </div>
      <BrassDivFlex />
      <div style={{ margin: '10px' }}>
        <div>{new Date(item.lastAlertDate).toLocaleString()}</div>
      </div>
      <div>
        <BrassChip
          text={`${item.alerts.length} ${
            item.alerts.length > 1 ? translate('alerts') : translate('alert')
          }`}
          themeColor={getThemeColor(item.alerts.length)}
          onClick={(): void => {
            if (item.alerts.length) {
              onAlertClick(item.alerts);
            }
          }}
        />
      </div>
    </div>
  );
};

const NotificationList: FunctionComponent<NotificationListProps> = memo(
  (props: NotificationListProps) => {
    const { data, onAlertClick } = props;

    return (
      <BrassListView
        inlineItems={false}
        data={data}
        item={(_props) => ItemRender(_props, onAlertClick)}
        style={{
          width: '100%',
          height: '40vh',
          color: '#a0a0a0',
          border: 'solid 1px rgb(215 215 215)',
        }}
        header={() => (
          <BrassListViewHeader className={style.margin}>
            {translate('notifications')}
          </BrassListViewHeader>
        )}
        footer={() => (
          <BrassListViewFooter className={style.margin}>
            {data.length} {translate('messagesInTotal')}
          </BrassListViewFooter>
        )}
      />
    );
  },
);

export type { NotificationListProps };
export { NotificationList };
