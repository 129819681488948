import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../icon/Icon';

const BrassButtonHelp: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    className='brass-button-help'
    labelKey='help'
    translations={translations}
    icon={<BrassIcon icon={faQuestionCircle} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    help: 'Ayudar',
  },
  enUS: {
    help: 'Help',
  },
  ptBR: {
    help: 'Ajuda',
  },
};

export { BrassButtonHelp };
