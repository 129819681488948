const commentTypeGroup = {
  WORK_TIME_RECORD: [
    'RHT_APPROPRIATE_COMMENT',
    'RHT_APPROPRIATE_COMMENT_LEADER',
    'RHT_APPROPRIATE_COMMENT_VALIDATOR',
  ],
  WORK_TIME_RECORD_AUTHORIZATION: ['RHT_AUTHORIZATION_COMMENT'],
  FINANCE: ['INVOICE_COMMENT', 'RHT_INVOICE_ISSUE', 'RHT_INVOICE_REQUEST'],
  OVERTIME_WORK: ['RHT_OVERTIME_COMMENT', 'RHT_OVERTIME_COMMENT_APROVAL'],
  GENERAL: [],
};

export { commentTypeGroup };
