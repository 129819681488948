import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLanguagesStorage } from '../../../brass';
import {
  ApiResult,
  Notification,
  NotificationStyles,
  PropertyTemplate,
} from '../../../../models';
import { TemplatePropertyService } from '../../../../services';
import { notificationActions } from '../../../../store';
import { DynamicDispatchAlert, emptyGuid } from '../../../../util';
import { translate } from './UpsertTemplateForm.translations';

interface IOpenTranslateProps {
  open: boolean;
  value?: string;
}

const UpsertTemplateFormFunctions = (
  isUpdating: boolean,
  valuesComponent: any,
  onSaveClose?: () => void,
): any => {
  const dispatch = useDispatch();
  const [wasTranslating, setWasTranslating] = useState<boolean>(false);
  const languageLocalStorage = getLanguagesStorage()?.language;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const submitHasCallback = useRef<boolean>(false);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [propertyTemplate, setPropertyTemplate] = useState({} as PropertyTemplate);
  const [openCheckTranslation, setOpenCheckTranslation] =
    useState<IOpenTranslateProps>({} as IOpenTranslateProps);
  const [initialFormValues, setInitialFormValues] = useState<PropertyTemplate>(
    new PropertyTemplate({} as PropertyTemplate),
  );

  const getIdCallBack = (callBack2: () => string): string => {
    setOpenCheckTranslation({ open: true, value: callBack2() });
    return callBack2();
  };

  const handleGetAfterTranslate = (id: string): void => {
    if (id) {
      setIsLoading(true);
      TemplatePropertyService.getTemplate(
        valuesComponent?.matrixComponentId,
        id as string,
        languageLocalStorage,
      ).then((apiResult: ApiResult<PropertyTemplate>) => {
        if (apiResult.success) {
          if (apiResult.data) {
            setInitialFormValues(apiResult.data);
            setPropertyTemplate(apiResult.data);
          }
          setIsLoading(false);
        } else {
          DynamicDispatchAlert(dispatch, apiResult.message, 'ERROR', translate);
        }
      });
    }
  };

  const handleConfirmSubmit = useCallback(
    async (_Template: PropertyTemplate | any): Promise<void> => {
      if (isUpdating || wasTranslating) {
        if (wasTranslating) {
          handleSubmit(_Template);
        } else {
          setOpenConfirm(true);
          setPropertyTemplate(_Template);
        }
      } else {
        if (submitHasCallback.current) {
          handleSubmit(_Template, getIdCallBack).then((result: any) => {
            if (result.success) {
              setOpenCheckTranslation({
                open: true,
                value: openCheckTranslation?.value,
              });
            }
          });
        } else {
          handleSubmit(_Template);
        }
      }
    },
    [],
  );

  const handleSubmit = async (
    _template: PropertyTemplate | any,
    callBack?: (callBack2: any) => string,
  ): Promise<void> => {
    _template = new PropertyTemplate({
      ...initialFormValues,
      ...(_template as PropertyTemplate),
    });
    const dataFormat = {
      name: _template?.name,
      templateId: _template?.templateId ?? initialFormValues?.templateId,
      matrixComponentId: valuesComponent?.matrixComponentId,
      language: languageLocalStorage,
    } as any;
    if (dataFormat?.templateId === emptyGuid()) {
      delete dataFormat?.templateId;
    }
    if (!dataFormat?.name) {
      return;
    }
    try {
      setAllowSubmit(true);
      setIsLoading(true);
      const apiResult: ApiResult =
        isUpdating || initialFormValues?.templateId !== emptyGuid()
          ? await TemplatePropertyService.updateTemplate(
              dataFormat as PropertyTemplate,
            )
          : await TemplatePropertyService.insertTemplate(
              dataFormat as PropertyTemplate,
            );
      if (apiResult.success) {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate(isUpdating ? 'successOnUpdate' : 'successOnAdd'),
            }),
          ),
        );
        setInitialFormValues(apiResult.data);
        setPropertyTemplate(apiResult.data);
        if (onSaveClose && !callBack) {
          onSaveClose();
        } else {
          if (getIdCallBack) {
            getIdCallBack(() => apiResult?.data?.templateId);
          }
        }
        localStorage.setItem('templateIdTraduction', apiResult?.data?.templateId);
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: `
                                          ${translate(
                                            isUpdating
                                              ? 'errorOnUpdate'
                                              : 'errorOnAdd',
                                          )} :
                                          ${translate(apiResult.message[0])}
                                        `,
            }),
          ),
        );
      }
      setAllowSubmit(false);
    } catch (error: any) {
      setAllowSubmit(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    initialFormValues,
    handleSubmit,
    submitHasCallback,
    getIdCallBack,
    setWasTranslating,
    allowSubmit,
    setOpenCheckTranslation,
    openCheckTranslation,
    handleGetAfterTranslate,
    handleConfirmSubmit,
    openConfirm,
    setOpenConfirm,
    propertyTemplate,
    setPropertyTemplate,
  };
};

export { UpsertTemplateFormFunctions };
