import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ApiErrorsEnum,
  ApiResult,
  FormAction,
  FormActions,
  FormCallbacks,
  FormItem,
  FormManipulators,
  FormParams,
  FormState,
  GridItem,
  Notification,
  NotificationStyles,
} from '../models';
import { emptyGuid } from '../util';
import { formActions, notificationActions } from '../store';
import { BrassFormRenderProps } from '../components';

const formReducer = <T extends GridItem | FormItem>(
  state: FormState<T>,
  action: FormAction,
): FormState<T> => {
  switch (action.type) {
    case FormActions.UPDATE_FORM_LOADING: {
      return {
        ...state,
        formLoading: action.payload,
      };
    }
    case FormActions.UPDATE_ALLOW_SUBMIT: {
      return {
        ...state,
        allowSubmit: action.payload,
      };
    }
    case FormActions.UPDATE_IS_UPDATING: {
      return {
        ...state,
        isUpdating: action.payload,
      };
    }
    case FormActions.UPDATE_IS_DATA_FETCHED: {
      return {
        ...state,
        isDataFetched: action.payload,
      };
    }
    case FormActions.UPDATE_RECORD_ID: {
      return {
        ...state,
        recordId: action.payload,
      };
    }
    case FormActions.UPDATE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case FormActions.UPDATE_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: action.payload,
      };
    }
    case FormActions.START_HANDLE_SUBMIT: {
      return {
        ...state,
        allowSubmit: action.payload,
        formLoading: action.payload,
      };
    }
    case FormActions.END_HANDLE_SUBMIT: {
      return {
        ...state,
        formLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const useForm = <T extends GridItem | FormItem>(
  formParams: FormParams<T>,
): FormManipulators | any => {
  const {
    getRecord,
    getRecordLogic,
    createRecord,
    createRecordLogic,
    updateRecord,
    updateRecordLogic,
    onSaveClose,
    onSuccess,
    recordId,
    initialValues,
    translate,
  } = formParams;
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(formReducer, {
    formLoading: false,
    errorMessage: '',
    initialValues,
    allowSubmit: false,
    recordId,
    isDataFetched: false,
    isUpdating: recordId !== emptyGuid(),
  });
  const [formValues, setFormValues] = useState<{
    isDataFechted: boolean;
    data: any;
  }>({ isDataFechted: false, data: {} });

  useEffect(() => {
    if (formState.isUpdating && formState.recordId && getRecord) {
      formDispatch({
        type: FormActions.UPDATE_FORM_LOADING,
        payload: true,
      });
      dispatch(formActions.setSubmissionStatus({ formLoading: true }));
      getRecord(formState.recordId)
        .then((apiResult: ApiResult<T>) => {
          if (getRecordLogic) {
            getRecordLogic(apiResult.data);
          }
          formDispatch({
            type: FormActions.UPDATE_INITIAL_VALUES,
            payload: apiResult.data,
          });
          setFormValues((previousValue) => {
            return {
              ...previousValue,
              data: apiResult.data,
            };
          });
          dispatch(formActions.setStepInitialValues(apiResult.data));
        })
        .finally(() => {
          formDispatch({
            type: FormActions.UPDATE_FORM_LOADING,
            payload: false,
          });
          formDispatch({
            type: FormActions.UPDATE_IS_DATA_FETCHED,
            payload: true,
          });
          setFormValues((previousValue) => {
            return {
              ...previousValue,
              isDataFechted: true,
            };
          });
          dispatch(
            formActions.setSubmissionStatus({
              formLoading: false,
              allowSubmit: true,
            }),
          );
          dispatch(formActions.setDataFetched());
          dispatch(formActions.transformInitialValues());
        });
    }
  }, [formState.recordId, formState.isUpdating, getRecord, getRecordLogic]);

  const onSubmit = useCallback(
    async (_record: T | any): Promise<void | ApiResult> => {
      _record = {
        ...formState.initialValues,
        ...(_record as T),
        id: formState.recordId,
      } as T;
      try {
        formDispatch({
          type: FormActions.START_HANDLE_SUBMIT,
          payload: true,
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: true,
            formLoading: true,
          }),
        );
        const apiResult: ApiResult = formState.isUpdating
          ? await updateRecord!(_record)
          : await createRecord(_record);

        if (!formState.isUpdating && createRecordLogic) {
          createRecordLogic(_record);
        } else if (formState.isUpdating && updateRecordLogic) {
          updateRecordLogic(_record);
        }

        formDispatch({
          type: FormActions.END_HANDLE_SUBMIT,
          payload: false,
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: false,
            formLoading: false,
          }),
        );
        if (apiResult.success) {
          if (onSuccess) {
            onSuccess(apiResult.data);
          }
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.SUCCESS,
                content: translate(
                  formState.isUpdating ? 'successOnUpdate' : 'successOnAdd',
                ),
              }),
            ),
          );
          if (onSaveClose) {
            onSaveClose();
          }
          return apiResult;
        } else {
          formDispatch({
            type: FormActions.UPDATE_ALLOW_SUBMIT,
            payload: false,
          });
          formDispatch({
            type: FormActions.UPDATE_ERROR_MESSAGE,
            payload: apiResult.errorMessage!,
          });
          dispatch(
            formActions.setSubmissionStatus({
              allowSubmit: false,
            }),
          );
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.ERROR,
                content: `
                ${translate(formState.isUpdating ? 'errorOnUpdate' : 'errorOnAdd')} :
                ${translate(apiResult.message[0])}
              `,
              }),
            ),
          );
          return apiResult;
        }
      } catch (error: any) {
        formDispatch({
          type: FormActions.UPDATE_ALLOW_SUBMIT,
          payload: false,
        });
        formDispatch({
          type: FormActions.UPDATE_ERROR_MESSAGE,
          payload: translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR),
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: false,
          }),
        );
      }
    },
    [
      formState.initialValues,
      formState.isUpdating,
      formState.recordId,
      translate,
      dispatch,
      createRecord,
      createRecordLogic,
      updateRecord,
      updateRecordLogic,
      onSaveClose,
      onSuccess,
    ],
  );

  const onSubmitStepForm = useCallback(
    async (_record: T | any): Promise<void | ApiResult> => {
      _record = {
        ...formState.initialValues,
        ...(_record as T),
      } as T;
      try {
        formDispatch({
          type: FormActions.START_HANDLE_SUBMIT,
          payload: true,
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: true,
            formLoading: true,
          }),
        );
        const apiResult: ApiResult = formState.isUpdating
          ? await updateRecord!(_record)
          : await createRecord(_record);

        if (!formState.isUpdating && createRecordLogic) {
          createRecordLogic(_record);
        } else if (formState.isUpdating && updateRecordLogic) {
          updateRecordLogic(_record);
        }

        formDispatch({
          type: FormActions.END_HANDLE_SUBMIT,
          payload: false,
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: false,
            formLoading: false,
          }),
        );
        if (apiResult.success) {
          if (onSuccess) {
            onSuccess(apiResult.data);
          }
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.SUCCESS,
                content: translate(
                  formState.isUpdating ? 'successOnUpdate' : 'successOnAdd',
                ),
              }),
            ),
          );
          if (onSaveClose) {
            onSaveClose();
          }
          return apiResult;
        } else {
          formDispatch({
            type: FormActions.UPDATE_ALLOW_SUBMIT,
            payload: false,
          });
          formDispatch({
            type: FormActions.UPDATE_ERROR_MESSAGE,
            payload: apiResult.errorMessage!,
          });
          dispatch(
            formActions.setSubmissionStatus({
              allowSubmit: false,
            }),
          );
          dispatch(
            notificationActions.showNotification(
              new Notification({
                style: NotificationStyles.ERROR,
                content: `
                ${translate(formState.isUpdating ? 'errorOnUpdate' : 'errorOnAdd')} :
                ${translate(apiResult.message[0])}
              `,
              }),
            ),
          );
          return apiResult;
        }
      } catch (error: any) {
        formDispatch({
          type: FormActions.UPDATE_ALLOW_SUBMIT,
          payload: false,
        });
        dispatch(
          formActions.setSubmissionStatus({
            allowSubmit: false,
          }),
        );
        formDispatch({
          type: FormActions.UPDATE_ERROR_MESSAGE,
          payload: translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR),
        });
      }
    },
    [
      formState.initialValues,
      formState.isUpdating,
      translate,
      dispatch,
      createRecord,
      createRecordLogic,
      updateRecord,
      updateRecordLogic,
      onSaveClose,
      onSuccess,
    ],
  );

  const onCheckButtonSaveState = useCallback(
    (formRenderProps: BrassFormRenderProps): boolean => {
      let disableButtonSave: boolean = formState.allowSubmit;
      if (!formRenderProps.valid) {
        disableButtonSave = true;
      } else if (!formRenderProps.allowSubmit) {
        disableButtonSave = true;
      }
      return disableButtonSave;
    },
    [formState.allowSubmit],
  );

  const [formCallbacks] = useState<FormCallbacks<T>>({
    onSubmit,
    onSubmitStepForm,
    onCheckButtonSaveState,
  });

  const formManipulators = useMemo<FormManipulators>(() => {
    return {
      formState,
      formDispatch,
      formCallbacks,
      formValues,
    };
  }, [formState, formDispatch, formCallbacks, formValues]);

  return formManipulators;
};

export { useForm };
