import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    email: 'Email',
  },
  enUS: {
    email: 'Email',
  },
  ptBR: {
    email: 'Email',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
