import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Calendar, CalendarProps } from '@progress/kendo-react-dateinputs';

interface BrassCalendarProps extends CalendarProps {}

const BrassCalendar: FunctionComponent<BrassCalendarProps> = (
  props: BrassCalendarProps,
) => {
  const { className } = props;
  const _className = classnames('brass-calendar', className);

  return <Calendar {...props} className={_className} />;
};

export { BrassCalendar };
export type { BrassCalendarProps };
