import React, { FunctionComponent, useState } from 'react';
import { ColorPalette } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import style from './ColorPalette.module.scss';
import { FieldValidatorType } from '@progress/kendo-react-form';

interface BrassColorPaletteProps {
  label?: string;
  icon?: any;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  onChangeValue: (color: string) => void;
  defaultValue: string;
}
const BrassColorPalette: FunctionComponent<BrassColorPaletteProps> = (
  props: BrassColorPaletteProps,
) => {
  const [value, setValue] = useState(props.defaultValue || '#70AD47');

  const handleOnChange = (event: any) => {
    setValue(event.value);
    props.onChangeValue(event.value);
  };

  return (
    <div className={style.margin}>
      <Label className={style.label}>
        {props.icon && <div className={style.icon}> {props.icon}</div>}
        {props.label}
      </Label>
      <ColorPalette
        palette={[
          '#70AD47',
          '#FACC2C',
          '#FDA451',
          '#DA0B0B',
          '#7030A0',
          '#0070C0',
          '#3B4351',
          '#595959',
          '#2D2B2B',
        ]}
        tileSize={30}
        defaultValue={props.defaultValue}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};

export { BrassColorPalette };
export type { BrassColorPaletteProps };
