import {
    ApiGridResult,
    ApiResult,
    BrassGridConfigProps,
    BrassUploadFileInfo,
    DocumentInformation,
    DocumentInformationVisualization,
    emptyGuid,
    NavigatorFileViewer,
    RequestNavigatorFileParams,
    SurveyLinkArea,
    SurveyLinkAssets,
    SurveyLinkSystemModel,
} from '../';
import { Endpoints } from '../models/';
import { ApiService } from './api.service';

class ViewerService {
  private static api: ApiService = new ApiService(Endpoints.NAVIGATOR);
  private static apiAuth: ApiService = new ApiService(Endpoints.AUTH);

  public static async getNavigatorFileViewer(
    requestNavigatorFileViewerParams: RequestNavigatorFileParams,
  ): Promise<ApiResult<NavigatorFileViewer>> {
    const { projectId, areaId, systemId, fileId } = requestNavigatorFileViewerParams;

    let urlApi = `/connect/v2/project/${projectId}/viewer`;
    if (areaId && areaId !== emptyGuid()) {
      urlApi = `/connect/v2/project/${projectId}/area/${areaId}/viewer`;
    } else if (systemId && systemId !== emptyGuid()) {
      urlApi = `/connect/v2/project/${projectId}/system/${systemId}/viewer`;
    } else if (fileId && fileId !== emptyGuid()) {
      urlApi = `/connect/v2/project/${projectId}/file/${fileId}/viewer`;
    }

    return this.api.get<NavigatorFileViewer>(urlApi);
  }

  public static async getDownloadLink(): Promise<ApiResult<string>> {
    const apiResult: ApiResult<string> = await this.api.get<string>(
      'connect/v1/project/area/viewer/viewerId/download',
      {},
    );

    // await setTimeout(() => console.log('getDownloadLink'), 1000);
    // const zipUrl = `${s3Url}/d1.zip`;
    // const apiResult = { data: s3Url + zipUrl } as ApiResult<string>;

    return apiResult;
  }

  public static async getDocumentInformations(
    fileId: string,
  ): Promise<ApiResult<DocumentInformation>> {
    const apiResult: ApiResult<DocumentInformation> =
      await this.api.get<DocumentInformation>(`/connect/v1/file/${fileId}`, {});
    return apiResult;
  }

  public static async getDocumentInformationHistories(
    gridConfig: BrassGridConfigProps,
    gridParam: {
      fileId: string;
    },
  ): Promise<ApiResult<ApiGridResult<DocumentInformationVisualization>>> {
    return await ViewerService.api.post<
      ApiGridResult<DocumentInformationVisualization>
    >(`/connect/v1/file/${gridParam.fileId}/histories`, gridConfig);
  }

  public static async getDocumentSharedLinks(
    gridConfig: BrassGridConfigProps,
    gridParam: {
      fileId: string;
    },
  ): Promise<ApiResult<ApiGridResult<DocumentInformationVisualization>>> {
    return await ViewerService.api.post<
      ApiGridResult<DocumentInformationVisualization>
    >(`/connect/v1/shared/files/${gridParam.fileId}/0`, gridConfig);
  }

  public static async deleteDocumentSharedLink(
    sharedId: string,
  ): Promise<ApiResult> {
    return await ViewerService.api.delete<any>(`/connect/v1/shared/${sharedId}`);
  }

  public static async getGenerateLinkToShared(
    navigatorFileId: string,
    validDate: string,
    sendToEmail?: string[],
  ): Promise<ApiResult> {
    return await ViewerService.api.post<any>(`/connect/v1/shared/generate-url`, {
      navigatorFileId,
      validDate,
      sendToEmail,
    });
  }

  public static async shareDocument(emaiList: string[]): Promise<ApiResult> {
    // const apiResult: ApiResult = await this.api.post<any>(
    //   'connect/v1/project/area/viewer/viewerId/share',
    //    { data: { emailList }}
    // );
    await new Promise((res) => {
      console.log(emaiList);
      res(true);
    });
    const apiResult = { data: {} } as ApiResult;

    return apiResult;
  }

  public static async createShareLink(): Promise<ApiResult<string>> {
    // const apiResult: ApiResult = await this.api.post<any>(
    //   'connect/v1/project/area/viewer/viewerId/share',
    //    { data: {}}
    // );
    await new Promise((res) => {
      res(true);
    });
    const apiResult = { data: 'https://google.com' } as ApiResult;

    return apiResult;
  }

  public static async createTemporaryCredentialUploadV1(
    fileKey: string,
  ): Promise<ApiResult<string>> {
    return await this.apiAuth.get<string>(
      `/connect/v1/storage/token/upload?fileKey=${fileKey}`,
    );
  }

  // Registra na Fila o novo arquivo que precisa ser CONVERTIDO
  public static async addSQSConvertFile(
    uploadFileInfo: BrassUploadFileInfo,
    uploadFileParams: {
      accountId: string;
      projectId: string;
      areaId: string;
      systemId: string;
    },
  ): Promise<ApiResult> {
    const { accountId, projectId, areaId, systemId } = uploadFileParams;
    const apiResult: ApiResult = await this.api.post<any>(
      `/connect/v2/project/${projectId}/convert-file`,
      {
        accountId,
        projectId,
        areaId,
        systemId,
        fileKey: uploadFileInfo.bucketKey,
        fileName: uploadFileInfo.name,
        fileSize: uploadFileInfo.size,
        fileExtension: uploadFileInfo.extension,
        startUpload: uploadFileInfo.uploadStartTime,
        endUpload: uploadFileInfo.uploadEndTime,
      },
      {},
    );

    return apiResult;
  }

  // Busca o token para download deste documento no S3
  public static async getTokenDownloadDocument(
    keyFile: string,
  ): Promise<ApiResult<string>> {
    const apiResult: ApiResult = await this.apiAuth.post(
      'connect/v1/storage/token/download',
      {
        key: keyFile,
      },
      {},
    );

    return apiResult;
  }

  public static listAreas: SurveyLinkArea[] = [
    {
      id: 'ab123',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0001-000',
      responsible: 'Draco',
      tag: '1df0s0',
    } as SurveyLinkArea,
    {
      id: 'ab456',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0002-000',
      responsible: 'Petra',
      tag: 't02a',
    } as SurveyLinkArea,
    {
      id: 'ab789',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0002-000',
      responsible: 'Cronos',
      tag: 'tas23',
    } as SurveyLinkArea,
  ];

  public static listSystems: SurveyLinkSystemModel[] = [
    {
      id: 'ab123',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0004-020',
      responsible: 'Draco',
      tag: '1df0s0',
    } as SurveyLinkSystemModel,
    {
      id: 'ab456',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0005-000',
      responsible: 'Petra',
      tag: 't02a',
    } as SurveyLinkSystemModel,
    {
      id: 'ab789',
      linkId: '',
      image:
        `https://scontent-gru1-1.xx.fbcdn.net/v/t1.6435-9/102840179_143432380650347_` +
        `4225596540932920472_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=973b4a&_nc_ohc=B7hvaSB-0k0AX9O` +
        `iK5B&_nc_ht=scontent-gru1-1.xx&oh=d17b064a09c14ed03315468287aa039c&oe=61C8C065`,
      name: '0004-000',
      responsible: 'Cronos',
      tag: 'tas23',
    } as SurveyLinkSystemModel,
  ];

  public static async getNavigatorSurveyLinkData(
    areaId: string,
    fileId: string,
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<SurveyLinkArea>>> {
    await new Promise((res, _) => res(true));
    let objectReturned:
      | SurveyLinkAssets[]
      | SurveyLinkSystemModel[]
      | SurveyLinkArea[]
      | undefined = [];

    if (areaId && areaId !== emptyGuid()) {
      objectReturned = this.listAreas;
    } else if (fileId && fileId !== emptyGuid()) {
      objectReturned = this.listSystems;
    }

    return {
      environment: JSON.stringify(gridConfig),
      message: [],
      success: true,
      utcTime: '2021-08-30T17:32:38.3613595Z',
      data: {
        records: objectReturned,
        total: 3,
      } as ApiGridResult<SurveyLinkArea>,
    } as ApiResult;
  }

  public static async updateNavigatorSurveyLinkAreas(
    surveyArea: SurveyLinkArea,
    linkId: string,
  ): Promise<any> {
    await new Promise((res, _) => res(true));

    const index = this.listAreas
      .map((area: SurveyLinkArea) => area.id)
      .indexOf(surveyArea.id);
    this.listAreas[index] = { ...surveyArea, linkId };
    return {
      environment: 'dev',
      message: [],
      success: true,
      utcTime: '2021-08-30T17:52:52.6547815Z',
      data: [],
    };
  }

  public static async getNavigatorSurveyLinkAssets(
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<SurveyLinkAssets>>> {
    return this.api.post<ApiGridResult<SurveyLinkAssets>>(
      `/connect/v1/surveyasset/list`,
      gridConfig,
      {},
    );
  }

  public static async getNavigatorSurveyLinkSystemToAsset(
    systemId: string,
    surveyAssetId: string,
    gridConfig: BrassGridConfigProps,
  ): Promise<ApiResult<ApiGridResult<SurveyLinkAssets>>> {
    return this.api.put<ApiGridResult<SurveyLinkAssets>>(
      `/connect/v1/system/${systemId}/surveyAsset/${surveyAssetId}`,
      gridConfig,
      {},
    );
  }
}

export { ViewerService };
