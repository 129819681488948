import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import {
  ChartCategoryAxisItem,
  ChartCategoryAxisItemProps,
} from '@progress/kendo-react-charts';

interface BrassChartCategoryAxisItemProps
  extends ChartCategoryAxisItemProps,
    PropsWithChildren<any> {}

const BrassChartCategoryAxisItem: FunctionComponent<
  BrassChartCategoryAxisItemProps
> = (props: BrassChartCategoryAxisItemProps) => {
  const { className } = props;
  const _className = classnames('brass-chart-category-axis-item', className);
  return (
    <div className={_className}>
      <ChartCategoryAxisItem {...props} />
    </div>
  );
};

export { BrassChartCategoryAxisItem };
export type { BrassChartCategoryAxisItemProps };
