import React, { FunctionComponent, useEffect, useState, memo } from 'react';
import { translate } from './ProjectsDropDown.translations';
import { ProjectService } from '../../services';
import { ApiResult, DropDownData } from '../../../../models';
import { I18nService } from '../../../../services';
import { BrassDropDownList, BrassDropDownListProps } from '../../../../components';

interface ProjectsDropDownProps extends BrassDropDownListProps {}

const ProjectsDropdown: FunctionComponent<ProjectsDropDownProps> = memo(
  (props: ProjectsDropDownProps) => {
    const [projects, setProjects] = useState<DropDownData[]>(
      ProjectService.cacheProjectsDropDownData,
    );
    const [loading, setLoading] = useState<boolean>(projects.length === 0);

    useEffect(() => {
      if (!projects.length) {
        setLoading(true);
        ProjectService.getProjectsDropDown().then(
          (apiResult: ApiResult<DropDownData[]>) => {
            if (apiResult.success) {
              setProjects(apiResult.data);
              setLoading(false);
            }
          },
        );
      }
    }, [projects.length]);

    return (
      <BrassDropDownList
        {...props}
        loading={loading}
        data={projects}
        label={translate('project')}
        textField='text'
        dataItemKey='value'
      />
    );
  },
);

export { ProjectsDropdown };
export type { ProjectsDropDownProps };
