import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { DriveSubmodule } from './Drive.submodule.enum';

enum DriveModulePermissionsV1 {
  SMART_LIB_MODULE = 0x00010000,
  NUCLEAR_MANAGEMENT_MODULE = 0x00020000,
}

enum DriveFeaturesPermissionsV1 {
  MATRIX_COMPONENT = 0x00000100,
  MANAGEMENT_CODE_MATRIX_COMPONENT = 0x00000200,
  ACTIVE_COMPONENT = 0x00000300,

  FLAG_TAXONOMIC = 0x00000100,
  FLAG_RELATIONAL = 0x00000200,
  CODE_FLAG_TAXONOMIC = 0x00000300,
  PROPERTY = 0x00000400,
  CODE_PROPERTY = 0x00000500,
  MEASURE = 0x00000600,
  CODE_MEASURE = 0x00000700,
}

enum DrivePermissionsV1 {
  SMART_LIB_MODULE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE,
  NUCLEAR_MANAGEMENT_MODULE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE,

  /// <summary>
  /// Flag taxonômica
  /// </summary>
  FLAG_TAXONOMIC_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.READ,
  FLAG_TAXONOMIC_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.UPDATE,
  FLAG_TAXONOMIC_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.UPDATE,
  FLAG_TAXONOMIC_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.DELETE,
  FLAG_TAXONOMIC_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.ACTIVE,
  FLAG_TAXONOMIC_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_TAXONOMIC +
    Action.SUSPEND,

  /// <summary>
  /// Flag relacional
  /// </summary>
  FLAG_RELATIONAL_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.READ,
  FLAG_RELATIONAL_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.UPDATE,
  FLAG_RELATIONAL_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.UPDATE,
  FLAG_RELATIONAL_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.DELETE,
  FLAG_RELATIONAL_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.ACTIVE,
  FLAG_RELATIONAL_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.FLAG_RELATIONAL +
    Action.SUSPEND,

  /// <summary>
  /// Gestão de código taxonômico
  /// </summary>
  CODE_FLAG_TAXONOMIC_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.CODE_FLAG_TAXONOMIC +
    Action.UPDATE,

  /// <summary>
  /// Propriedade
  /// </summary>
  PROPERTY_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.READ,
  PROPERTY_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.UPDATE,
  PROPERTY_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.UPDATE,
  PROPERTY_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.DELETE,
  PROPERTY_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.ACTIVE,
  PROPERTY_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.PROPERTY +
    Action.SUSPEND,

  /// <summary>
  /// Gestão de código da propriedade
  /// </summary>
  CODE_PROPERTY_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.CODE_PROPERTY +
    Action.UPDATE,

  /// <summary>
  /// Grandeza
  /// </summary>
  MEASURE_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.READ,
  MEASURE_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.UPDATE,
  MEASURE_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.UPDATE,
  MEASURE_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.DELETE,
  MEASURE_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.ACTIVE,
  MEASURE_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.MEASURE +
    Action.SUSPEND,

  /// <summary>
  /// Gestão de código da grandeza
  /// </summary>
  CODE_MEASURE_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.NUCLEAR_MANAGEMENT_MODULE +
    DriveFeaturesPermissionsV1.CODE_MEASURE +
    Action.UPDATE,

  /// <summary>
  /// Componentes matriz
  /// </summary>
  COMPONENT_MATRIX_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.READ,
  COMPONENT_MATRIX_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.UPDATE,
  COMPONENT_MATRIX_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.UPDATE,
  COMPONENT_MATRIX_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.DELETE,
  COMPONENT_MATRIX_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.ACTIVE,
  COMPONENT_MATRIX_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MATRIX_COMPONENT +
    Action.SUSPEND,

  /// <summary>
  /// Gestão de código do componentes matriz
  /// </summary>
  CODE_COMPONENT_MATRIX_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.MANAGEMENT_CODE_MATRIX_COMPONENT +
    Action.UPDATE,

  /// <summary>
  /// Componentes ativos
  /// </summary>
  COMPONENT_ACTIVE_READ = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.READ,
  COMPONENT_ACTIVE_CREATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.UPDATE,
  COMPONENT_ACTIVE_UPDATE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.UPDATE,
  COMPONENT_ACTIVE_DELETE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.DELETE,
  COMPONENT_ACTIVE_ACTIVE = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.ACTIVE,
  COMPONENT_ACTIVE_SUSPENDED = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.SUSPEND,
  COMPONENT_ACTIVE_UPLOAD = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.UPLOAD,
  COMPONENT_ACTIVE_DOWNLOAD = PermissionModule.DRIVE +
    DriveModulePermissionsV1.SMART_LIB_MODULE +
    DriveFeaturesPermissionsV1.ACTIVE_COMPONENT +
    Action.DOWNLOAD,
}

export { DrivePermissionsV1, DriveModulePermissionsV1 };
