import { FunctionComponent } from 'react';
import style from './ScrollViewer.module.scss';
import { ScrollView, ScrollViewProps } from '@progress/kendo-react-scrollview';

interface BrassScrollViewItem {
  position: number;
  url: string;
  allow: boolean;
}

interface BrassScrollViewProps extends ScrollViewProps {
  width?: string | '1000px';
  height?: string | '700px';
  items: BrassScrollViewItem[];
  className?: string;
  className_Image?: string;
}

const BrassScrollView: FunctionComponent<BrassScrollViewProps> = (
  props: BrassScrollViewProps,
) => {
  const { items, width, height, className_Image } = props;

  return (
    <ScrollView
      style={{
        width,
        height,
      }}
      {...props}
    >
      {items
        .filter((_) => _.allow)
        .map((item, index) => {
          return (
            <div className={style['div-image']} key={index}>
              <img
                src={item.url}
                alt={`Comunicado ${item.position}`}
                className={className_Image}
                draggable={false}
              />
            </div>
          );
        })}
    </ScrollView>
  );
};

export { BrassScrollView };
export type { BrassScrollViewItem };
