import React, {
  FunctionComponent,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  createContext,
} from 'react';
import { EnvironmentService, LocalStorageService } from '../../../../services';
import { ApiResult, AppRoute } from '../../../../models';
import { parseBool } from '../../../../util/Bool';
import { NotificationService, UserNotificationCount } from '../../../../modules';

interface RouterContextProps {
  menuExpanded: boolean;
  routes: AppRoute[];
  setMenuExpanded?: Dispatch<SetStateAction<boolean>>;
  notificationCount?: string | number;
  setNotificationCount?: React.Dispatch<React.SetStateAction<string | number>>;
  commentCount?: string | number;
  setCommentCount?: React.Dispatch<React.SetStateAction<string | number>>;
  isPulsingNotification?: boolean;
  setIsPulsingNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  isPulsingComment?: boolean;
  setIsPulsingComment?: React.Dispatch<React.SetStateAction<boolean>>;
  ticketsCount?: string | number;
  setTicketsCount?: React.Dispatch<React.SetStateAction<string | number>>;
  isPulsingTickets?: boolean;
  setIsPulsingTickets?: React.Dispatch<React.SetStateAction<boolean>>;
  handleCountNotifications?: any;
  handleCountComments?: any;
  handleCountTickets?: any;
  isContentEditor?: boolean;
  setIsContentEditor?: React.Dispatch<React.SetStateAction<boolean>>;
  changeToEditor?: any;
}

interface RouterContainerContextProps {
  children: ReactNode;
  routes: AppRoute[];
}

const emptyDrawerContext = {
  routes: [],
  menuExpanded: false,
};

const MainLayoutContainerContext =
  createContext<RouterContextProps>(emptyDrawerContext);

const MainLayoutContainer: FunctionComponent<RouterContainerContextProps> = (
  props: RouterContainerContextProps,
) => {
  const initialState = EnvironmentService.isNavigatorIntegrator
    ? 'false'
    : LocalStorageService.getLocalStorage('main-layout-menu-expanded') || 'false';
  const [drawerExpanded, setDrawerExpanded] = useState<boolean>(
    parseBool(initialState),
  );

  const [notificationCount, setNotificationCount] = useState<number | string>(0);
  const [isPulsingNotification, setIsPulsingNotification] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState<number | string>(0);
  const [isPulsingComment, setIsPulsingComment] = useState<boolean>(false);
  const [ticketsCount, setTicketsCount] = useState<number | string>(0);
  const [isPulsingTickets, setIsPulsingTickets] = useState<boolean>(false);
  const [isContentEditor, setIsContentEditor] = useState<boolean>(false);

  const handleCountNotifications = () => {
    NotificationService.getNotificationCount().then(
      (apiResult: ApiResult<UserNotificationCount>) => {
        if (apiResult.success && setNotificationCount) {
          setNotificationCount(
            apiResult.data.count < 100 ? apiResult.data.count : '99+',
          );
          setIsPulsingNotification(apiResult.data.pulse);
        }
      },
    );
  };

  const handleCountComments = () => {
    NotificationService.getCommentsCount().then(
      (apiResult: ApiResult<UserNotificationCount>) => {
        if (apiResult.success && setCommentCount) {
          setCommentCount(apiResult.data.count < 100 ? apiResult.data.count : '99+');
          setIsPulsingComment(apiResult.data.pulse);
        }
      },
    );
  };

  const handleCountTickets = () => {
    setTicketsCount(0);
    setIsPulsingTickets(false);
  };

  const valueProvider: RouterContextProps = {
    ...emptyDrawerContext,
    routes: props.routes,
    menuExpanded: drawerExpanded,
    setMenuExpanded: setDrawerExpanded,
    notificationCount,
    setNotificationCount,
    isPulsingNotification,
    setIsPulsingNotification,
    commentCount,
    setCommentCount,
    isPulsingComment,
    setIsPulsingComment,
    ticketsCount,
    setTicketsCount,
    isPulsingTickets,
    setIsPulsingTickets,
    handleCountNotifications,
    handleCountComments,
    handleCountTickets,
    isContentEditor,
    setIsContentEditor,
  };

  return (
    <MainLayoutContainerContext.Provider value={valueProvider}>
      {props.children}
    </MainLayoutContainerContext.Provider>
  );
};

export { MainLayoutContainerContext, MainLayoutContainer };
