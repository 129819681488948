import React from 'react';
import { I18nService, II18n } from '../../../index';

const translations: II18n = {
  es: {
    commentInput: 'Escriba un comentario',
    getChatError: 'Error al obtener el chat',
    noData: 'No se encontraron registros',
    addComment: 'Agregar comentario',
    supportComment: 'Comentario de apoyo',
    open: 'Haga clic aquí para abrir/expandir',
    close: 'Haga clic aquí para cerrar/minimizar',
  },
  enUS: {
    commentInput: 'Type a comment',
    getChatError: 'Error fetching chat ',
    noData: 'No record found',
    addComment: 'Add comment',
    supportComment: 'Support comment',
    open: 'Click here to open/expand',
    close: 'Click here to close/minimize',
  },
  ptBR: {
    commentInput: 'Digite um comentário',
    getChatError: 'Error ao buscar o chat',
    noData: 'Nenhum registro encontrado',
    addComment: 'Adicionar comentário',
    supportComment: 'Apoiar comentário',
    open: 'Clique aqui para abrir/expandir',
    close: 'Clique aqui para fechar/minimizar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
