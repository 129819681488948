import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconScheduleReport: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M3.56251 15.5598H7.50584C7.88182 15.5598 7.88182 16.1061 7.50584 16.1061H3.59034C3.23631 16.1059 3.20831 15.5598 3.56254 15.5598H3.56251ZM3.58894 13.6637H6.5636C6.93984 13.6637 6.93984 14.2101 6.5636 14.2101H3.59027C3.21514 14.2102 3.21384 13.6637 3.5889 13.6637H3.58894ZM3.58894 11.767C4.4698 11.775 5.3491 11.775 6.22894 11.767C6.60517 11.7645 6.60517 12.3132 6.22894 12.3132H3.59027C3.21514 12.3132 3.21384 11.7643 3.5889 11.767H3.58894ZM3.56251 9.87217C4.56004 9.85817 5.56367 9.87087 6.56367 9.87217C6.92721 9.87328 6.92721 10.4184 6.56367 10.4184H3.59034C3.22681 10.4184 3.19919 9.87815 3.56254 9.87217H3.56251ZM3.58119 7.97667H7.50586C7.87031 7.97667 7.87031 8.52289 7.50586 8.52289H3.59036C3.22591 8.52289 3.21634 7.97667 3.58124 7.97667H3.58119ZM2.91562 2.95534C2.32741 2.95534 1.85541 3.38132 1.85541 3.91022V17.8384C1.85541 18.3673 2.32741 18.7922 2.91562 18.7922H13.3516C13.9387 18.7922 14.4109 18.3673 14.4109 17.8384V17.7181L13.288 17.7192C10.1574 17.7192 7.61936 15.1764 7.61936 12.0411C7.61936 8.90441 10.1574 6.36258 13.288 6.36258H14.4109V3.91041C14.4109 3.38151 13.9387 2.95553 13.3516 2.95553H12.3007V4.41973C12.3007 5.02148 11.796 5.51348 11.198 5.51348L5.06804 5.51355C4.47117 5.51355 3.96537 5.02155 3.96537 4.4198V2.9556L2.91564 2.95553L2.91562 2.95534Z' />
    <path d='M8.1332 2.39951C8.52565 2.39951 8.84361 2.72731 8.84361 3.13109C8.84361 3.53506 8.52538 3.86267 8.1332 3.86267C7.74095 3.86267 7.42278 3.53532 7.42278 3.13109C7.42252 2.72731 7.74075 2.39951 8.1332 2.39951ZM8.1332 1.20641C7.32206 1.20641 6.603 1.73994 6.34936 2.53042H5.10295C4.78471 2.53042 4.53003 2.78172 4.53003 3.09534V4.40446C4.53003 4.71716 4.78471 4.97047 5.10295 4.97047H11.1643C11.4816 4.97047 11.7363 4.71716 11.7363 4.40446V3.09534C11.7363 2.78181 11.4816 2.53042 11.1643 2.53042H9.91558C9.662 1.74129 8.94311 1.20641 8.13308 1.20641H8.1332Z' />
    <path d='M13.288 8.37334C14.2591 8.37334 15.1908 8.75954 15.8773 9.44736C16.5635 10.1347 16.95 11.0676 16.95 12.041C16.95 13.0132 16.5635 13.9459 15.8773 14.6337C15.1907 15.3224 14.2591 15.7086 13.288 15.7086C11.266 15.7076 9.62717 14.0661 9.62717 12.0411C9.62711 10.0156 11.266 8.37339 13.288 8.37339V8.37334ZM13.288 7.16826C10.6018 7.16826 8.42334 9.35009 8.42334 12.0413C8.42334 14.7323 10.6018 16.9139 13.288 16.9139C14.5787 16.9139 15.816 16.3997 16.7278 15.4866C17.6409 14.5726 18.1525 13.3327 18.1525 12.0412C18.1525 10.7483 17.6412 9.50874 16.7278 8.59574C15.8161 7.68129 14.5787 7.16703 13.288 7.16839V7.16826Z' />
    <path d='M13.2325 8.85351C13.1074 8.85573 13.0067 8.95748 13.0081 9.08371V9.3606C13.0081 9.48638 13.1098 9.5897 13.2361 9.5897C13.3619 9.5897 13.4639 9.48657 13.4639 9.3606V9.08371C13.4652 8.95507 13.3612 8.85103 13.2325 8.85351V8.85351Z' />
    <path d='M13.2325 14.4888C13.1063 14.4899 13.0056 14.5944 13.0081 14.7204V14.9969C13.0081 15.1231 13.1099 15.2262 13.2361 15.2262C13.3619 15.2262 13.4639 15.1231 13.4639 14.9969V14.7204C13.4666 14.592 13.3621 14.4864 13.2325 14.4888V14.4888Z' />
    <path d='M15.8888 11.8128C15.5995 11.8408 15.6212 12.2702 15.9119 12.2677H16.1875C16.3193 12.2768 16.4304 12.1731 16.4304 12.041C16.4304 11.909 16.3193 11.8037 16.1875 11.8128H15.9119H15.8888Z' />
    <path d='M10.2628 11.8128C9.97323 11.8408 9.99393 12.2702 10.2848 12.2677H10.56C10.6922 12.2768 10.8033 12.1731 10.8033 12.041C10.8033 11.909 10.6922 11.8037 10.56 11.8128H10.2848H10.2628Z' />
    <path d='M15.1261 13.7055C14.9199 13.703 14.8184 13.9544 14.9664 14.0966L15.1619 14.2933C15.25 14.3879 15.3989 14.3906 15.4906 14.2993C15.5821 14.2077 15.5797 14.0584 15.4851 13.9704L15.2904 13.7748C15.2477 13.7297 15.1886 13.7055 15.1261 13.7055L15.1261 13.7055Z' />
    <path d='M11.124 9.7205C10.9316 9.74004 10.8496 9.97519 10.9887 10.1105L11.1829 10.3072C11.2709 10.402 11.4191 10.4045 11.5104 10.3131C11.6031 10.2216 11.5995 10.0723 11.5049 9.98424L11.3104 9.78868C11.2615 9.73894 11.1931 9.71342 11.124 9.72051L11.124 9.7205Z' />
    <path d='M15.3172 9.72051C15.2591 9.72142 15.2036 9.74694 15.1623 9.78874L14.9667 9.9843C14.8763 10.0734 14.8763 10.2192 14.9656 10.3083C15.0556 10.3988 15.2016 10.3974 15.2907 10.3072L15.4854 10.1105C15.6321 9.9647 15.5241 9.7147 15.3172 9.7205L15.3172 9.72051Z' />
    <path d='M11.3388 13.7055C11.2799 13.7066 11.2233 13.7322 11.1826 13.7748L10.9884 13.9704C10.8931 14.0584 10.8899 14.2077 10.981 14.2993C11.0725 14.3906 11.2219 14.388 11.3099 14.2933L11.5044 14.0966C11.6537 13.952 11.5471 13.6984 11.3388 13.7055V13.7055Z' />
    <path d='M13.2313 9.90334C13.1054 9.90444 13.0045 10.0085 13.0056 10.1347L13.008 12.0412C13.008 12.1669 13.1109 12.2679 13.236 12.2679H14.5763C14.7025 12.269 14.8056 12.1658 14.8056 12.0401C14.8056 11.9141 14.7025 11.8107 14.5763 11.8121L13.464 11.8132L13.4627 10.135C13.4651 10.0061 13.3601 9.90101 13.2314 9.90341L13.2313 9.90334Z' />
  </svg>
);

export { SVGIconScheduleReport };
