import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import {
  AuthUser,
  BrassAvatarV1,
  BrassButtonCollapseDown,
  BrassButtonExpandUp,
  BrassIcon,
  BrassLoader,
  BrassTooltip,
  getLanguagesStorage,
  IClassNamesChat,
  IInsertCommentChat,
  ItemLikes,
  SVGIconChat,
  SVGIconLike
} from '../../../index';
import { Endpoints } from '../../../models';
import { ChatFunctions } from './CommentChat.functions';
import { translate } from './CommentChat.translations';
import style from './CommentChatV1.module.scss';
import { BrassInputChat } from './input-chat/InputChat';
import { TreeChatV1 } from './TreeCommentChatV1';

export interface IPermissionChatV1 {
  canComment?: boolean;
  canLike?: boolean;
}

interface IBrassCommentChatV1Props {
  referenceId: string;
  forceRefresh: boolean;
  item: ItemLikes;
  onClickSendMessage?: (e: IInsertCommentChat) => IInsertCommentChat | void;
  endpoints?: Endpoints | null;
  permissions: IPermissionChatV1;
  onClickLikeForum?: (e: any) => any | void;
  onHandleAddComment?: () => void;
  color?: string;
  background?: string;
  classNamesChat?: IClassNamesChat;
  initOpen?: boolean;
}

const BrassCommentChatV1 = (props: IBrassCommentChatV1Props): any => {
  const {
    onClickSendMessage,
    permissions,
    onClickLikeForum,
    item,
    referenceId,
    color,
    background,
    classNamesChat,
  } = props;
  const {
    isLoading,
    data,
    disableButton,
    message,
    setDisableButton,
    setMessage,
    handleInsertKeyPressEnter,
    setRefresh,
    setIsLoading,
    openColapse,
    setOpenColapse,
    verifyLike,
    handleActionShowComment,
    openComment,
    formatName,
  } = ChatFunctions({ ...props });

  return (
    <>
      <div className={style['container']}>
        <div className={style['box']}>
          <div
            className={classNames(style['box-footer'], classNamesChat?.boxFooter)}
            style={{ borderBottom: `1px solid ${props.color ?? '#3a3a3a'}` }}
          >
            <div className={style['box-footer-left']}>
              {item?.likes?.length > 0 && (
                <div className={style['box-avatar']}>
                  {item?.likes?.length > 3 && (
                    <span className={style['count']}>
                      +{item?.likes?.length - 3}
                    </span>
                  )}
                  {item?.likes?.slice(0, 3)?.map((i: any) => (
                    <BrassAvatarV1
                      type='text'
                      content={formatName(i?.userName)}
                      tooltip={i?.userName}
                      className={style['avatar']}
                    />
                  ))}
                </div>
              )}
              <>
                <span className={style['box-footer-actions-counts']}>
                  {item?.quantityLike ?? 0}
                </span>
                <BrassTooltip>
                  <span
                    title={translate('supportComment')}
                    className={style.tooltip}
                  >
                    <SVGIconLike
                      onClick={() => {
                        if (onClickLikeForum && permissions?.canComment) {
                          onClickLikeForum(item);
                        }
                      }}
                      className={classNames(style['icon'], style['box-icons'])}
                      style={{
                        fill: verifyLike(item?.likes)
                          ? '#70ad47'
                          : color ?? '#3a3a3a',
                        cursor: permissions?.canComment ? 'pointer' : 'default',
                      }}
                    />
                  </span>
                </BrassTooltip>
              </>
              <span className={style['box-footer-actions-counts']}>
                {item?.quantityComents ?? 0}
              </span>
              <BrassTooltip>
                <span title={translate('addComment')} className={style.tooltip}>
                  <SVGIconChat
                    className={classNames(style['icon'], style['box-icons'])}
                    onClick={handleActionShowComment}
                    style={{
                      fill:
                        item?.quantityComents && item?.quantityComents > 0
                          ? '#70ad47'
                          : color ?? '#3a3a3a',
                      cursor: permissions?.canComment ? 'pointer' : 'default',
                    }}
                  />
                </span>
              </BrassTooltip>
            </div>
            <div className={style['box-footer-right']}>
              {openColapse ? (
                <BrassButtonExpandUp
                  showText={false}
                  className={classNames(
                    style['icon-chat'],
                    style['button-collapse'],
                  )}
                  onClick={() => setOpenColapse((prev: boolean) => !prev)}
                  icon={<BrassIcon icon={faCaretUp} color={color ?? '#3a3a3a'} />}
                  tooltipKey={translate('close')}
                />
              ) : (
                <BrassButtonCollapseDown
                  showText={false}
                  className={classNames(
                    style['icon-chat'],
                    style['button-collapse'],
                  )}
                  onClick={() => setOpenColapse((prev: boolean) => !prev)}
                  icon={<BrassIcon icon={faCaretDown} color={color ?? '#3a3a3a'} />}
                  tooltipKey={translate('open')}
                />
              )}
            </div>
          </div>
        </div>
        {openColapse && (
          <div className={style['box-colapse']}>
            <div className={style['chat-container']}>
              <div className={style['chat-content']}>
                <div
                  className={style['chat-block-container']}
                  style={{ background }}
                >
                  {data.length > 0 ? (
                    <>
                      {!isLoading && (
                        <TreeChatV1
                          listChat={data}
                          canComment={permissions.canComment as boolean}
                          canLike={permissions.canLike as boolean}
                          setRefresh={setRefresh}
                          color={color}
                          background={background}
                        />
                      )}
                    </>
                  ) : (
                    <div className={classNames(style['no-data'])}>
                      <span>{translate('noData')}</span>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={style['separator']}
                style={{ background: color ?? '#3a3a3a' }}
              ></div>
            </div>
          </div>
        )}

        {openColapse &&
          openComment &&
          permissions?.canComment &&
          (isLoading ? (
            <BrassLoader type='pulsing' style={{ color: 'black' }} />
          ) : (
            <BrassInputChat
              color={color}
              divHtmlElement={{
                className: style['input-block-modified'],
              }}
              inputHtmlElement={{
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setMessage(e?.target?.value),
                value: message,
                className: style['input-block-modified-input'],
                onKeyPress: handleInsertKeyPressEnter,
              }}
              buttonHtmlElement={{
                onClick: () => {
                  if (onClickSendMessage) {
                    onClickSendMessage({
                      payload: {
                        language: getLanguagesStorage()?.language,
                        message,
                        referenceId,
                        user: [{ userId: AuthUser.getId() }],
                      },
                      options: {
                        setIsLoading,
                        setMessage,
                        setDisableButton,
                      },
                    } as any);
                  }
                },
                disabled: !message ? true : disableButton,
                className: style['input-block-modified-button'],
              }}
            />
          ))}
      </div>
      {isLoading && <BrassLoader useLoadingMask style={{ zIndex: 10008 }} />}
    </>
  );
};

export { BrassCommentChatV1 };
export type { IBrassCommentChatV1Props };

