import { FunctionComponent, useContext } from 'react';
import { BrassButtonRefresh } from '../../../../../components/shared/buttons';
import { BrassSearchInputV1 } from '../../../../../components/shared/inputs';
import { RelationalFlagsContext } from '../../../../../store/RelationalFlagsContext';
import style from './SearchFlags.module.scss';

const SearchFlags: FunctionComponent = () => {
  const { setFilter, filter, setRefresh, refresh, setRelationalContextMap } =
    useContext(RelationalFlagsContext);

  return (
    <div className={style.content}>
      <BrassButtonRefresh
        showText={false}
        themeColor='primary'
        className={style['button']}
        fillMode='solid'
        onClick={() => {
          setRefresh(!refresh);
          setRelationalContextMap({} as any);
        }}
      />
      <BrassSearchInputV1
        className={style.input}
        onChange={(e: any) => {
          setFilter(e.target.value?.toString() || '');
        }}
      />
    </div>
  );
};

export { SearchFlags };

