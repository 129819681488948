import React, { FunctionComponent, useContext } from 'react';
import { BrassButton } from '../../../../../brass';
import { MainLayoutContainerContext } from '../../MainLayoutContainer';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../../../../../components/shared/icon/Icon';
import style from './MenuButton.module.scss';
import { LocalStorageService } from '../../../../../../services';

const MenuButton: FunctionComponent = () => {
  const { menuExpanded, setMenuExpanded } = useContext(MainLayoutContainerContext);
  const onClick = (): void => {
    setMenuExpanded && setMenuExpanded(!menuExpanded);
    LocalStorageService.setLocalStorage(
      'main-layout-menu-expanded',
      `${!menuExpanded}`,
    );
  };

  return (
    <BrassButton fillMode='flat' onClick={onClick} className={style['menubutton']}>
      <BrassIcon icon={faBars} size='2x' color='white' />
    </BrassButton>
  );
};

export { MenuButton };
