import { BrassFieldProps, BrassInputProps } from '../../../../brass';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';

import styles from './RelationalFlags.module.scss';

import { useEffect } from 'react';
import { useState } from 'react';
import { BrassIcon } from '../../../../../index';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames, { Argument } from 'classnames';
export interface RelationFlagFieldProps
  extends Omit<BrassFieldProps & TextFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  initialValue?: any[];
  onChange?: () => {};
  removable?: boolean;
  setInitialValue?: (e: any) => any;
  setOnDelete?: any;
  classNamePanel?: string;
}

interface TextFieldInputProps extends BrassInputProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

export interface ChipProps {
  item: any;
  removable: boolean;
  actionRemove: () => void;
}

export const Chip: React.FunctionComponent<ChipProps> = (props: ChipProps) => {
  const { item, actionRemove, removable } = props;
  return (
    <div className={styles.chip}>
      {item.text}
      {removable && (
        <BrassIcon
          icon={faTimes}
          style={{ cursor: 'pointer' }}
          onClick={() => actionRemove()}
        />
      )}
    </div>
  );
};

const BrassRelationalFlags = (props: RelationFlagFieldProps): any => {
  const {
    required,
    label,
    initialValue,
    removable,
    setInitialValue,
    setOnDelete,
    classNamePanel,
  } = props;
  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, value]);

  const handleRemoveChip = (index: number) => {
    if (removable) {
      value.splice(index, 1);
      if (setInitialValue) {
        setInitialValue(value);
      }
      setValue(value);
    }
  };

  return (
    <>
      <FieldWrapper>
        <Label style={{ opacity: 0.6 }} className={styles.label}>
          {label && <div>{required ? `${label}*` : label}</div>}
        </Label>
        <div
          className={classNames(styles['panel-view-flag'], classNamePanel)}
          id={'painel-view-flag'}
        >
          {value?.map((item: any, key: number) => (
            <Chip
              item={item}
              key={key}
              removable={removable as boolean}
              actionRemove={() => {
                handleRemoveChip(key);
                setOnDelete(item);
              }}
            />
          ))}
        </div>
      </FieldWrapper>
    </>
  );
};

export { BrassRelationalFlags };
