import React, {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import style from './TreeListGrid.module.scss';
import { BrassTreeListGridProps } from './TreeListGridProps.interface';
import {
  DeleteColumn,
  EditColumn,
  SelectColumn,
  onHeaderSelectionChangeFunction,
  onSelectionChangeFunction,
} from './TreeListGrid.functions';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  CompositeFilterDescriptor,
  process,
  State,
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassButtonAddV1,
  BrassButtonHelp,
  BrassButtonRefreshV1,
  BrassButtonGroup,
  BrassGridConfigDefault,
  BrassLoader,
  BrassSplitter,
  BrassButtonFilter,
} from '../../..';
import { BrassEditCell, BrassDeleteCell } from '../cells';
import { useDebounce } from '../../../../hooks/useDebounce';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    group: 'Agrupar por',
  },
  enUS: {
    group: 'Group by',
  },
  ptBR: {
    group: 'Agrupar por',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

/**
 * Obsoleto: use(renderGridV1)
 *
 * @param hideActionButtons
 * @param props
 * @param selectedRecords
 * @param setSelectedRecords
 * @param _className
 * @param dataState
 * @param setSataState
 */
const renderGrid = (
  hideActionButtons: boolean = false,
  props: BrassTreeListGridProps,
  selectedRecords: string[],
  setSelectedRecords: Dispatch<SetStateAction<string[]>>,
  _className: string,
  dataState: State,
  setSataState: Dispatch<SetStateAction<State>>,
): ReactNode => {
  const {
    columns,
    colunsExportExcell,
    allowInsert,
    allowEdit,
    allowDelete,
    allowExport,
    visibleButtonRefresh,
    sortable,
    reorderable,
    onAddCallback,
    onRefreshCallback,
    onEditCallback,
    onDeleteCallback,
    toolbarBeforeActions,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarAfterActions,
    extraGridToolbar,
    customFilterGridToolbar,
    gridConfig,
    updateGridConfig,
    gridData,
    selectable,
    updateGridData,
    gridLoading,
    pageable,
    groupable,
    setGridLoading,
    filterable,
    // showGridFooterOnlyGroupModle,
    dataItemKey,
    helpUser,
  } = props;
  const anchorRef = useRef<any>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

  const [isGrouping, setIsGrouping] = useState<boolean>(
    (gridConfig.group || []).length !== 0,
  );
  const [showHelpPanel, setShowHelpPanel] = useState<boolean>(false);
  const preColumns = [...columns];
  const pageData: any = {
    info: true,
    buttonCount: 5,
    type: 'numeric',
    pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 3000, 5000, 10000],
    previousNext: true,
    group: [],
  };

  const gridExcelExport = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
      gridExcelExport.current.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null && gridPDFExport !== undefined) {
      gridPDFExport.save();
    }
  };

  // ---------------------------------------------------------------------------------
  EditColumn(allowEdit!, preColumns, false, BrassEditCell, onEditCallback!);
  SelectColumn(preColumns, gridData, selectedRecords, selectable);
  DeleteColumn(
    allowDelete!,
    preColumns,
    BrassDeleteCell,
    onDeleteCallback!,
    gridData,
    updateGridData,
  );

  // Converte todas as colunas para o Objeto GridColumnProps
  let _columns: GridColumnProps[] = preColumns.map(
    (col: any) => col as GridColumnProps,
  );

  if (hideActionButtons) {
    _columns = _columns.filter(
      (col: GridColumnProps) => col.field !== 'edit' && col.field !== 'delete',
    );
  }

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  const onItemChange = (event: GridItemChangeEvent): void => {
    const { dataItem, field, value } = event;

    const update =
      gridData.records &&
      gridData.records.map((record: any) => {
        if (!record.oldValues) {
          record.oldValues = record;
        }
        if (record.id === dataItem.id) {
          return { ...record, [field || 'undefined']: value };
        }
        return record;
      });

    updateGridData({ ...update });
  };

  const [idCollapsedGroup, setIdCollapsedGroup] = useState<
    { field: string; value: string }[]
  >([]);
  const onGridExpandChange = (event: GridExpandChangeEvent): void => {
    const {
      dataItem: { value, field },
      value: isExpanded,
    } = event;
    let newIdList = [...idCollapsedGroup];

    if (!isExpanded) {
      newIdList.push({ value, field });
    } else {
      newIdList = newIdList.filter((f) => !(f.value === value && f.field === field));
    }

    newIdList = [...Array.from(new Set(newIdList))]; // Unique array
    setIdCollapsedGroup(newIdList);
  };

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  let offsetTop = 0;
  let offsetLeft = 0;
  let offsetHeight = 0;
  let offsetWidth = 0;
  if (anchorRef.current && anchorRef.current.element) {
    offsetTop = anchorRef.current.element.offsetTop || 0;
    offsetLeft = anchorRef.current.element.offsetLeft || 0;

    offsetWidth = (anchorRef.current.element.offsetWidth || 0) + 100;
    offsetHeight = anchorRef.current.element.offsetHeight || 0;
  }

  // const _dataState = {...dataState};
  const filtering = props.denyFilterOnGridProcessing ? undefined : gridConfig.filter;
  const gridDataRecords = process(gridData.records || [], {
    ...gridConfig,
    filter: filtering,
  });
  if (isGrouping && idCollapsedGroup.length) {
    const fnExpandRecords = (data: any[]): void => {
      data.map((rec: any) => {
        const { value, field } = rec;
        const existRec = idCollapsedGroup.find(
          (f) => f.value === value && f.field === field,
        );

        rec.expanded = existRec === undefined;

        if (rec.items && rec.items.length) {
          fnExpandRecords(rec.items);
        }

        return rec;
      });
    };

    fnExpandRecords(gridDataRecords.data);
  }

  // ---------------------------------------------------------------------------------
  const [localGridFilter, setLocalGridFilter] = useState<CompositeFilterDescriptor>(
    gridConfig.filter!,
  );
  const debouncedGridFilter: CompositeFilterDescriptor = useDebounce(
    localGridFilter,
    1000,
  );
  useEffect(() => {
    updateGridConfig &&
      updateGridConfig({
        ...gridConfig,
        filter: debouncedGridFilter,
      });
  }, [debouncedGridFilter]);

  // ---------------------------------------------------------------------------------
  return (
    <Fragment>
      {gridLoading &&
        (offsetTop + offsetLeft + offsetHeight + offsetWidth >= 1 ? (
          <BrassLoader
            useLoadingMask
            style={{
              top: offsetTop,
              left: offsetLeft,
              height: offsetHeight,
              width: offsetWidth,
            }}
          />
        ) : (
          <BrassLoader useLoadingMask />
        ))}

      <BrassSplitter
        panes={[
          {},
          {
            size: '400px',
            resizable: false,
          },
        ]}
      >
        <Grid
          ref={anchorRef}
          dataItemKey={dataItemKey || 'id'}
          expandField='expanded'
          resizable={true}
          onExpandChange={isGrouping ? onGridExpandChange : undefined}
          data={gridDataRecords}
          sortable={sortable!}
          className={classnames(_className, style['grid-footer'])}
          reorderable={reorderable!}
          onItemChange={onItemChange}
          {...dataState}
          onDataStateChange={(event: GridDataStateChangeEvent) => {
            updateGridConfig!({
              ...gridConfig,
              group: event.dataState.group,
            });
            setSataState(event.dataState);
          }}
          pageable={pageable ? pageData : undefined}
          pageSize={gridConfig ? gridConfig.take : BrassGridConfigDefault.take}
          onPageChange={(event: GridPageChangeEvent) => {
            setSataState({
              ...dataState,
              take: event.page.take,
            });
            if (updateGridConfig) {
              setGridLoading && setGridLoading(true);
              updateGridConfig({
                ...gridConfig,
                skip: event.page.skip,
                take: event.page.take,
              });
            }
          }}
          groupable={groupable && isGrouping}
          sort={gridConfig.sort!}
          onSortChange={(event: GridSortChangeEvent) => {
            if (updateGridConfig) {
              updateGridConfig({
                ...gridConfig,
                sort: event.sort,
              });
            }
          }}
          filterable={filterable}
          filter={localGridFilter}
          onFilterChange={(event: GridFilterChangeEvent) => {
            if (!event.filter) {
              updateGridConfig &&
                updateGridConfig({
                  ...gridConfig,
                  filter: event.filter,
                });
            }
            setLocalGridFilter(event.filter);
          }}
          selectable={selectable}
          selectedField='selected'
          onSelectionChange={(event: GridSelectionChangeEvent) =>
            onSelectionChangeFunction(
              event,
              selectedRecords,
              setSelectedRecords,
              gridData,
              updateGridData,
              props.onSelectionChange!,
            )
          }
          onHeaderSelectionChange={(event: GridHeaderSelectionChangeEvent) =>
            onHeaderSelectionChangeFunction(
              event,
              selectedRecords,
              gridData,
              updateGridData,
              setSelectedRecords,
              props.onHeaderSelectionChange!,
            )
          }
        >
          <GridToolbar>
            {toolbarBeforeTitle}
            <h2 className={style['brass-grid-title']}>
              {props.gridIcon}
              {props.gridTitle}
            </h2>
            {toolbarAfterTitle}
            <ToolbarSpacer />

            {toolbarBeforeActions}
            {allowInsert && (
              <BrassButtonAddV1
                fillMode='solid'
                className={style['brass-button-insert']}
                onClick={onAddCallback as any}
                disabled={gridLoading}
                themeColor='primary'
                style={{ borderRadius: '5px' }}
              />
            )}
            {visibleButtonRefresh && (
              <BrassButtonRefreshV1
                fillMode='outline'
                showText={false}
                className={style['brass-button-refresh']}
                onClick={() => {
                  setGridLoading && setGridLoading(true);
                  onRefreshCallback && onRefreshCallback();
                }}
                disabled={gridLoading}
              />
            )}
            {allowExport && (
              <DropDownButton
                icon='printer'
                items={['PDF', 'Excel']}
                className={style['brass-button-export']}
                onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
                  itemClickEvent.itemIndex === 0 ? exportPDF() : excelExport()
                }
                disabled={gridLoading}
              />
            )}
            {groupable && (
              <Button
                togglable
                title={translate('group')}
                icon='aggregate-fields'
                className={style['brass-button-groupable']}
                onClick={() => {
                  if (isGrouping) {
                    updateGridConfig!({
                      ...gridConfig,
                      group: [],
                    });
                  }
                  setIsGrouping(!isGrouping);
                }}
              />
            )}
            {customFilterGridToolbar && (
              <BrassButtonFilter
                togglable
                fillMode='flat'
                key='buttonFilter'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              />
            )}
            {toolbarAfterActions}

            {helpUser && (
              <BrassButtonHelp
                togglable
                fillMode='outline'
                showText={false}
                onClick={() => setShowHelpPanel(!showHelpPanel)}
              />
            )}
          </GridToolbar>

          {showFilterPanel && customFilterGridToolbar && (
            <GridToolbar>{customFilterGridToolbar}</GridToolbar>
          )}

          {extraGridToolbar}
          {!gridLoading && <GridNoRecords />}
          {_columns}
        </Grid>

        {showHelpPanel && <div className={style['help-user']}>{helpUser}</div>}
      </BrassSplitter>

      <div style={{ overflow: 'hidden', maxHeight: 0, maxWidth: 0 }}>
        <ExcelExport
          ref={gridExcelExport}
          data={gridData.records}
          fileName={`${props.gridTitle} - ${new Date().toDateString()}`}
        >
          <Grid data={gridData.records} style={{ display: 'none' }}>
            {colunsExportExcell || columns}
          </Grid>
        </ExcelExport>
        <PDFExport
          // @ts-ignore
          ref={(pdfExport: PDFExport) => (gridPDFExport = pdfExport)}
          fileName={`${props.gridTitle} - ${new Date().toDateString()}`}
          paperSize='A4'
          margin='1cm'
        >
          <Grid data={gridData.records}>{columns}</Grid>
        </PDFExport>
      </div>
    </Fragment>
  );
};

export { renderGrid };
