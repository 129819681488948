import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

/* eslint-disable */
const translations: II18n = {
  es: {
    connect: 'CONNECT',
    academy: 'ACADEMY',
    navigator_survey: 'SURVEY',
    navigator_integration: 'INTEGRATOR',
    drive: 'DRIVE',
    drive_core: 'DRIVE',
    drive_smartlib: 'DRIVE',
    sgi_brass: 'SGI Brass',
  },
  enUS: {
    connect: 'CONNECT',
    academy: 'ACADEMY',
    navigator_survey: 'SURVEY',
    navigator_integration: 'INTEGRATOR',
    drive: 'DRIVE',
    drive_core: 'DRIVE',
    drive_smartlib: 'DRIVE',
    sgi_brass: 'SGI Brass',
  },
  ptBR: {
    connect: 'CONNECT',
    academy: 'ACADEMY',
    navigator_survey: 'SURVEY',
    navigator_integration: 'INTEGRATOR',
    drive: 'DRIVE',
    drive_core: 'DRIVE',
    drive_smartlib: 'DRIVE',
    sgi_brass: 'SGI Brass',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
