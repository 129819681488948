import React, { CSSProperties, FunctionComponent } from 'react';
import { BrassIconProps } from '../../shared';
import style from './Header.module.scss';

interface IHeaderTitleBarProps {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  style?: CSSProperties;
  icon?: FunctionComponent<BrassIconProps> | React.ReactNode;
}

const BrassHeader = (props: IHeaderTitleBarProps): any => {
  const prop = props;
  return (
    <div className={style['head-title']} style={props.style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props?.icon}
        {props?.title}
      </div>
      <div className={style['box-buttons']}>{props?.children}</div>
    </div>
  );
};

export { BrassHeader };
export type { IHeaderTitleBarProps };
