import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { FinanceSubmodule } from './Finance.submodule.enum';

enum FinancePermissionsV1 {
  /// <summary>
  /// Permissão para envio de nota fiscal pelo colaborador [(FE) /home/connect/finance/upload-invoice]
  /// </summary>
  EMPLOYEE_INVOICE_READ = PermissionModule.FINANCE +
    FinanceSubmodule.EMPLOYEE_INVOICE +
    Action.READ,
  EMPLOYEE_INVOICE_UPLOAD = PermissionModule.FINANCE +
    FinanceSubmodule.EMPLOYEE_INVOICE +
    Action.UPLOAD,
  /// <summary>
  /// Verificação/Validação de RHT de Funcionário -> [(FE) /finance/payslip-shipment -> /work-hours-report/validation/physical-person]
  /// </summary>
  WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON_READ = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON +
    Action.READ,
  WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON_VALIDATE = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON +
    Action.VALIDATE,
  WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON_EXPORT = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_PHYSICAL_PERSON +
    Action.EXPORT,
  /// <summary>
  /// Verificação/Validação de RHT de Pessoa Jurídica -> [(FE) /work-hours-report/pending-approval -> /work-hours-report/validation/legal-person]
  /// </summary>
  WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON_READ = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON +
    Action.READ,
  WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON_VALIDATE = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON +
    Action.VALIDATE,
  WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON_EXPORT = PermissionModule.FINANCE +
    FinanceSubmodule.WORK_TIME_RECORD_VALIDATION_LEGAL_PERSON +
    Action.EXPORT,
  /// <summary>
  /// Autorização de Pagamento Pessoa Jurídica -> [(FE) /home/connect/finance/payment-authorization]
  /// </summary>
  PAYMENT_AUTHORIZATION_READ = PermissionModule.FINANCE +
    FinanceSubmodule.PAYMENT_AUTHORIZATION +
    Action.READ,
  PAYMENT_AUTHORIZATION_APPROVE = PermissionModule.FINANCE +
    FinanceSubmodule.PAYMENT_AUTHORIZATION +
    Action.APPROVE,
  /// <summary>
  /// Emissão de Contracheque  -> [(FE) home/connect/finance/paycheck-issue]
  /// </summary>
  PAYCHECK_ISSUE_READ = PermissionModule.FINANCE +
    FinanceSubmodule.PAYCHECK_ISSUE +
    Action.READ,
  PAYCHECK_ISSUE_UPLOAD = PermissionModule.FINANCE +
    FinanceSubmodule.PAYCHECK_ISSUE +
    Action.UPLOAD,
  /// <summary>
  /// Histórico de Pagamento de PJ [(FE)  /home/connect/work-hours-report/reports/payment-history]
  /// </summary>
  REPORT_LEGAL_PERSON_PAYMENT_READ = PermissionModule.FINANCE +
    FinanceSubmodule.REPORT_LEGAL_PERSON_PAYMENT +
    Action.READ,
  REPORT_LEGAL_PERSON_PAYMENT_EXPORT = PermissionModule.FINANCE +
    FinanceSubmodule.REPORT_LEGAL_PERSON_PAYMENT +
    Action.EXPORT,
  // Relatório de Atividades Executadas com Custo
  INVOICE_ISSUANCE_MANAGEMENT_READ = PermissionModule.FINANCE +
    FinanceSubmodule.INVOICE_ISSUANCE_MANAGEMENT +
    Action.READ,
  INVOICE_ISSUANCE_MANAGEMENT_NOTIFY = PermissionModule.FINANCE +
    FinanceSubmodule.INVOICE_ISSUANCE_MANAGEMENT +
    Action.NOTIFY,
  INVOICE_ISSUANCE_MANAGEMENT_READ_SENSITIVE_DATA = PermissionModule.FINANCE +
    FinanceSubmodule.INVOICE_ISSUANCE_MANAGEMENT +
    Action.READ_SENSITIVE_DATA,
}

export { FinancePermissionsV1 };
