import { Endpoints, ModuleUrlEnum } from '../models';
class EnvironmentService {
  private static myWindow = window as any;

  public static get isDev(): boolean {
    return (
      EnvironmentService.myWindow.appEnviroment &&
      EnvironmentService.myWindow.appEnviroment === 'dev'
    );
  }

  public static get isStg(): boolean {
    return (
      EnvironmentService.myWindow.appEnviroment &&
      EnvironmentService.myWindow.appEnviroment === 'stg'
    );
  }

  public static get isProd(): boolean {
    return (
      EnvironmentService.myWindow.appEnviroment &&
      EnvironmentService.myWindow.appEnviroment === 'prod'
    );
  }

  public static get isLocalhost(): boolean {
    return EnvironmentService.myWindow.location.hostname === 'localhost';
  }

  public static get isModule(): boolean {
    return EnvironmentService.myWindow.location.isModule === true;
  }

  // Indica que o usuário está no Autenticador(Login)
  public static get isAuth(): boolean {
    return (
      EnvironmentService.myWindow.appAuth &&
      EnvironmentService.myWindow.appAuth === true
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Connect
  public static get isConnect(): boolean {
    return (
      EnvironmentService.myWindow.appConnect &&
      EnvironmentService.myWindow.appConnect === true
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Academy
  public static get isAppSgi(): boolean {
    return (
      EnvironmentService.myWindow.appSgi &&
      EnvironmentService.myWindow.appSgi === true
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Academy
  public static get isAppAcademy(): boolean {
    return (
      EnvironmentService.myWindow.appAcademy &&
      EnvironmentService.myWindow.appAcademy === true
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Academy
  public static get isAcademy(): boolean {
    return (
      EnvironmentService.myWindow.appApiAcademy &&
      EnvironmentService.myWindow.appApiAcademy === true
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Navigator
  public static get isNavigator(): boolean {
    return (
      EnvironmentService.myWindow.appNavigator &&
      EnvironmentService.myWindow.appNavigator === true
    );
  }
  // Indica que o usuário está no Navigator
  public static get isNavigatorIntegrator(): boolean {
    return (
      EnvironmentService.myWindow.appNavigatorIntegrator &&
      EnvironmentService.myWindow.appNavigatorIntegrator === true
    );
  }
  // Indica que o usuário está no Navigator
  public static get isNavigatorSurvey(): boolean {
    return (
      EnvironmentService.myWindow.appNavigatorSurvey &&
      EnvironmentService.myWindow.appNavigatorSurvey === true
    );
  }
  // Indica que o usuário está no Navigator
  public static get isNavigatorBind(): boolean {
    return (
      EnvironmentService.myWindow.appNavigator &&
      EnvironmentService.myWindow.appNavigator === true &&
      window.location.pathname.startsWith('/bind')
    );
  }

  // ---------------------------------------------------------------
  // Indica que o usuário está no Drive
  public static get isDrive(): boolean {
    return (
      EnvironmentService.myWindow.appDrive &&
      EnvironmentService.myWindow.appDrive === true &&
      !window.location.pathname.startsWith('/core') &&
      !window.location.pathname.startsWith('/smartlib')
    );
  }
  public static get isDriveCore(): boolean {
    return (
      EnvironmentService.myWindow.appDrive &&
      EnvironmentService.myWindow.appDrive === true &&
      window.location.pathname.startsWith('/core')
    );
  }
  public static get isDriveSmartLib(): boolean {
    return (
      EnvironmentService.myWindow.appDrive &&
      EnvironmentService.myWindow.appDrive === true &&
      window.location.pathname.startsWith('/smartlib')
    );
  }
  public static get shared(): boolean {
    return (
      EnvironmentService.myWindow.shared &&
      EnvironmentService.myWindow.shared === true
    );
  }

  // ---------------------------------------------------------------
  public static get app(): ModuleUrlEnum | null {
    if (EnvironmentService.isConnect) {
      return ModuleUrlEnum.CONNECT;
    } else if (EnvironmentService.isNavigator) {
      return ModuleUrlEnum.NAVIGATOR;
    } else if (EnvironmentService.isAcademy) {
      return ModuleUrlEnum.ACADEMY;
    } else if (EnvironmentService.isDrive) {
      return ModuleUrlEnum.DRIVE;
    } else if (EnvironmentService.isAuth) {
      return ModuleUrlEnum.AUTH;
    } else if (EnvironmentService.shared) {
      return ModuleUrlEnum.SHARED;
    } else {
      return null;
    }
  }

  public static get endpoints(): Endpoints | null {
    if (EnvironmentService.isConnect) {
      return Endpoints.CONNECT;
    } else if (EnvironmentService.isNavigator) {
      return Endpoints.NAVIGATOR;
    } else if (EnvironmentService.isAcademy) {
      return Endpoints.ACADEMY;
    } else if (EnvironmentService.isDrive) {
      return Endpoints.DRIVE;
    } else if (EnvironmentService.isAuth) {
      return Endpoints.AUTH;
    } else {
      return null;
    }
  }

  // Usuários ativos para visualizar o Status Page
  public static showStatusPage = (userEmail: string): boolean => {
    if (EnvironmentService.isProd) {
      const users = [
        'ahenrique@brassbrasil.com.br', // Adriano
        'dnunes@brassbrasil.com.br', // Davison Nunes
        'rsiqueira@brassbrasil.com.br', // Rafael Siqueira
        'drocha@brassbrasil.com.br', // Daniel Rocha
        'rzolio@brassbrasil.com.br', // Rodrigo Zólio
      ];

      return users.includes(userEmail);
    }

    return true;
  };

  // Usuários ativos para visualizar recursos experimentais
  public static showDevelopmentFeatures = (userEmail: string): boolean => {
    if (EnvironmentService.isProd) {
      const users = [
        'ahenrique@brassbrasil.com.br', // Adriano
        'dnunes@brassbrasil.com.br', // Davison Nunes
        'rsiqueira@brassbrasil.com.br', // Rafael Siqueira
        'drocha@brassbrasil.com.br', // Daniel Rocha
      ];

      return users.includes(userEmail);
    } else if (EnvironmentService.isStg || EnvironmentService.isDev) {
      const users = [
        'dnunes@brassbrasil.com.br', // Davison Nunes
        'rsiqueira@brassbrasil.com.br', // Rafael Siqueira
        'lnogueira@brassbrasil.com.br', // Leonardo Nogueira
        'drocha@brassbrasil.com.br', // Daniel Rocha
      ];

      return users.includes(userEmail);
    }
    return true;
  };
}

export { EnvironmentService };
