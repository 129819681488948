import React, { FunctionComponent, memo, useCallback } from 'react';
import style from './TreeListGrid.v2.module.scss';
import { renderGridV2 } from './TreeListGrid.v2.grid';
import { renderTreeListV2 } from './TreeListGrid.v2.treeList';
import { BrassTreeListGridPropsV2 } from './TreeListGridProps.v2.interface';
import { AccessDenied, BrassLogo } from '../../../../../components';

const BrassTreeListGridV2: FunctionComponent<BrassTreeListGridPropsV2> = memo(
  (props: BrassTreeListGridPropsV2) => {
    if (!props.permissions!.read) {
      return <AccessDenied />;
    }

    const render = useCallback(
      () => (props.treeListColumns ? renderTreeListV2(props) : renderGridV2(props)),
      [props],
    );

    return (
      <div style={{ display: 'block' }}>
        {render()}
        {props.pageable}
      </div>
    );
  },
);

BrassTreeListGridV2.defaultProps = {
  gridColumns: [],
  permissions: {
    read: true,
  },
  pageable: true,
  groupable: {
    enabled: true,
    footer: 'none',
  },
  reorderable: true,
  filterable: true,
  sortable: {
    mode: 'multiple',
  },
  selectable: {
    drag: false,
    cell: false,
    enabled: false,
    mode: 'multiple',
  },
};

export { BrassTreeListGridV2 };
