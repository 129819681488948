import {
  UserNotificationGroup,
  UserNotificationEventType,
  NotificationStatus,
} from './enum';
import { UserAlert } from './UserAlert.model';

class UserNotification {
  public type: UserNotificationEventType = UserNotificationEventType.ALL;
  public group: UserNotificationGroup = UserNotificationGroup.ALL;
  public title: string = '';
  public message: string = '';
  public alerts: UserAlert[] = [];
  public lastAlertDate: Date = new Date();
  public viewed: boolean = false;
  public status: NotificationStatus = NotificationStatus.READED;

  constructor(userNotification: Partial<UserNotification>) {
    Object.assign(this, userNotification);
  }
}

export { UserNotification };
