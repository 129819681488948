import React, { FunctionComponent } from 'react';
import style from './PdfViewer.module.scss';
import classNames from 'classnames';

interface BrassPdfViewerProps {
  url: string;
  className?: string;
}

const BrassPdfViewer: FunctionComponent<BrassPdfViewerProps> = (
  props: BrassPdfViewerProps,
) => {
  const { className } = props;
  const _className = classNames('pdf-viewer', style['pdf-viewer'], className);
  return (
    <object
      data={`${props.url}`}
      type='application/pdf'
      aria-label='pdf-viewer'
      className={_className}
    />
  );
};
export { BrassPdfViewer };
