import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TextAreaField, TextAreaFieldProps } from '../../../../../../../components';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    groupDescription: 'Descripción del Grupo',
  },
  enUS: {
    groupDescription: 'Group description',
  },
  ptBR: {
    groupDescription: 'Descrição do grupo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const DescriptionField: FunctionComponent<PropsWithChildren<any>> = (
  props: TextAreaFieldProps,
) => (
  <TextAreaField
    {...props}
    required={false}
    name='description'
    label={translate('groupDescription')}
  />
);

export { DescriptionField };
