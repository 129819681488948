import { ModuleUrlEnum } from '../models';
import { CookieService, LocalStorageService } from '../services';

const redirectPlatform = (platform: ModuleUrlEnum, router?: string) => {
  const environment = '';

  const user = LocalStorageService.getLocalStorageJSON(
    CookieService.cookieAuthUser,
  ) as any;
  if (user) {
    user.permission = [];
    CookieService.setCookieJSON(CookieService.cookieAuthUser, user);
  }

  const redirect: string = window.location.href;
  const environmentUrl: string = redirect.split('//')[1].split('.')[0].split('-')[1];
  window.location.href = `https://${platform}${
    environmentUrl === 'canary' ? '-canary' : environment
  }.bdbsystem.com.br/auto-login/?redirect=${encodeURIComponent(router ?? 'home')}`;
};

const redirectPlatform2 = (platform: ModuleUrlEnum, router?: string) => {
  const environment = '';

  const user = LocalStorageService.getLocalStorageJSON(
    CookieService.cookieAuthUser,
  ) as any;
  if (user) {
    user.permission = [];
    CookieService.setCookieJSON(CookieService.cookieAuthUser, user);
  }

  const redirect: string = window.location.href;
  const environmentUrl: string = redirect.split('//')[1].split('.')[0].split('-')[1];
  window.open(`https://${platform}${
    environmentUrl === 'canary' ? '-canary' : environment
  }.bdbsystem.com.br/auto-login/?redirect=${encodeURIComponent(router ?? 'home')}`);
};

const getRedirectPlatformUrl = (platform: ModuleUrlEnum, router?: string) => {
  const environment = '';

  const user = LocalStorageService.getLocalStorageJSON(
    CookieService.cookieAuthUser,
  ) as any;
  if (user) {
    user.permission = [];
    CookieService.setCookieJSON(CookieService.cookieAuthUser, user);
  }

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams && searchParams.get('redirect')) {
    const redirect: string = searchParams.get('redirect') as string;
    const environmentUrl: string = redirect
      .split('//')[1]
      .split('.')[0]
      .split('-')[1];
    return `https://${platform}${
      environmentUrl === 'canary' ? '-canary' : environment
    }.bdbsystem.com.br/auto-login/?redirect=${encodeURIComponent(router ?? 'home')}`;
  } else {
    return `https://${platform}${environment}.bdbsystem.com.br/auto-login/?redirect=${encodeURIComponent(
      router ?? 'home',
    )}`;
  }
};

export { getRedirectPlatformUrl, redirectPlatform, redirectPlatform2 };

