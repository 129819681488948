import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { removeDuplicatesInArray } from '../../../../../index';
import { DropDownData } from '../../../../../models';
import { RelationalFlagsContext } from '../../../../../store/RelationalFlagsContext';
import { BrassRelationalFlags, BrassTextFieldProps } from '../../../../brass';
import { BrassIcon } from '../../../icon';
import { translate } from './RelationalFlagSelected.translations';

interface RelationalFlagSelectedProps extends BrassTextFieldProps {
  setSelectedFlags: React.Dispatch<React.SetStateAction<DropDownData<string>[]>>;
  selectedFlags: DropDownData[];
  readonly?: boolean;
}

      function filteredData(prev: any[], newSelectedFlags: any[], dataItemRemove: any) {
        if ((dataItemRemove as any)?.flagRelationalId) {
          return [...prev, ...newSelectedFlags].filter(
            (_) => _.value !== (dataItemRemove as any)?.flagRelationalId,
          );
        }
        return [...prev, ...newSelectedFlags];
      }

const RelationalFlagSelected: FunctionComponent<RelationalFlagSelectedProps> = (
  props: RelationalFlagSelectedProps,
) => {
  const { setSelectedFlags, selectedFlags } = props;
  const { flags, setFlagValue, flagsNoSelecteds, dataItemRemove } = useContext(
    RelationalFlagsContext,
  );

  useEffect(() => {
    const inSub: any[] = [];
    const newList = flags
      .map((_: any) => {
        const encontrou = selectedFlags.find(
          (_select: any) => _select.value === _.value && _?.selected === false,
        );
        if (encontrou?.value) {
          inSub.push(encontrou);
        }
        return _;
      })
      .filter((_: any) => _.selected);

    if (selectedFlags.length > 0) {
      const newSelectedFlags: any[] = [...selectedFlags, ...newList].filter(
        (_: any) => {
          const result = inSub.find((_select: any) => _select.value === _.value);
          if (result === undefined) {
            return true;
          }
          return false;
        },
      );

      

      setSelectedFlags(
        (prev: any) => removeDuplicatesInArray(filteredData(prev, newSelectedFlags, dataItemRemove), 'value') as any,
      );
    } else {
      const newSelectedFlags: any[] = removeDuplicatesInArray(
        [...newList],
        'value',
      ).filter((_: any) => {
        const result = inSub.find((_select: any) => _select.value === _.value);
        if (result === undefined) {
          return true;
        }
        return false;
      });
      setSelectedFlags(newSelectedFlags);
    }
  }, [flags]);

  return (
    <BrassRelationalFlags
      showIcon={true}
      icon={<BrassIcon icon={faBookmark} />}
      name='relationalFlags'
      removable={props?.readonly ? false : true}
      label={translate('relationalFlagSelected')}
      initialValue={selectedFlags}
      setOnDelete={setFlagValue}
      {...props}
    />
  );
};

export { RelationalFlagSelected };

