import React, { FunctionComponent } from 'react';
import {
  BrassWindow,
  BrassWindowProps,
  BrassWindowTitleCustom,
} from '../../../components/brass';
import { DocumentModel } from '../../../models';
import { BrassViewFilesCarousel } from './view-files-carousel/ViewFilesCarousel';
import style from './ViewFilesCarouselWindow.module.scss';
import { translate } from './ViewFilesCarouselWindow.translations';

interface BrassViewFilesCarouselProps extends BrassWindowProps {
  onCancelClose: () => void;
  listFiles: DocumentModel[];
  positionFile: number;
  title?: string;
}

const BrassViewFilesCarouselWindow: FunctionComponent<
  BrassViewFilesCarouselProps
> = (props: BrassViewFilesCarouselProps) => {
  const { onCancelClose, listFiles, positionFile, title, className } = props;

  return (
    <BrassWindow
      onClose={onCancelClose}
      title={
        <BrassWindowTitleCustom
          title={title ? title : translate(`${listFiles[0]?.fileType}`)}
          className={style.title}
        />
      }
      minimizeButton={() => null}
      modal={true}
      overlayStyle={{ zIndex: 0 }} // por default é necessário o zIndex ser 0 para funcionamento correto das modais existentes
      showDarkBackground={false}
      initialHeight={650}
      initialWidth={800}
      {...props}
    >
      <BrassViewFilesCarousel listFiles={listFiles} positionFile={positionFile} />
    </BrassWindow>
  );
};

export type { BrassViewFilesCarouselProps };
export { BrassViewFilesCarouselWindow };
