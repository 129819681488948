import React, { FunctionComponent, ReactNode } from 'react';
import {
  BrassDialog,
  BrassDialogActionsBar,
  BrassDialogProps,
} from '../../brass/dialog';
import { BrassButtonNo, BrassButtonYes } from '../buttons';

interface BrassConfirmDialogProps extends BrassDialogProps {
  content: string | ReactNode;

  onYes: () => void;
  onNo: () => void;
}

const BrassConfirmDialog: FunctionComponent<BrassConfirmDialogProps> = (
  props: BrassConfirmDialogProps,
) => (
  <BrassDialog {...props}>
    <p style={{ margin: '25px', textAlign: 'center' }}>{props.content}</p>
    <BrassDialogActionsBar>
      <BrassButtonNo onClick={props.onNo} />
      <BrassButtonYes themeColor='base' onClick={props.onYes} />
    </BrassDialogActionsBar>
  </BrassDialog>
);

export type { BrassConfirmDialogProps };
export { BrassConfirmDialog };
