import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonSharedV1, BrassButtonSharedPropsV1 } from './ButtonV1';
import { BrassIcon } from '../icon/Icon';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const BrassButtonViewV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-view'
    labelKey='view'
    tooltipKey='clickToView'
    translations={translations}
    icon={<BrassIcon icon={faEye} size='lg' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    view: 'Ver',
    clickToView: 'haga clic para ver',
  },
  enUS: {
    view: 'View',
    clickToView: 'Click to view',
  },
  ptBR: {
    view: 'Visualizar',
    clickToView: 'Clique para visualizar',
  },
};

export { BrassButtonViewV1 };
