import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { CardTitle } from '@progress/kendo-react-layout';
import { CardTitleProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardTitleProps';

interface BrassCardTitleProps extends CardTitleProps {}

const BrassCardTitle: FunctionComponent<BrassCardTitleProps> = (
  props: BrassCardTitleProps,
) => {
  const { className } = props;
  const _className = classnames('brass-card-title', className);

  return <CardTitle {...props} className={_className} />;
};

export { BrassCardTitle };
export type { BrassCardTitleProps };
