import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { RadioButton, RadioButtonProps } from '@progress/kendo-react-inputs';

interface BrassRadioButtonProps extends RadioButtonProps {}

const BrassRadioButton: FunctionComponent<BrassRadioButtonProps> = (
  props: BrassRadioButtonProps,
) => {
  const { className } = props;
  const _className = classnames('brass-radio-button', className);
  return <RadioButton {...props} className={_className} />;
};

export { BrassRadioButton };
export type { BrassRadioButtonProps };
