import React, { FunctionComponent } from 'react';
import {
  BrassTextField,
  BrassTextFieldProps,
  I18nService,
  II18n,
} from '../../../../';

const translations: II18n = {
  es: {
    nameLabel: 'Nome',
  },
  enUS: {
    nameLabel: 'Name',
  },
  ptBR: {
    nameLabel: 'Nome',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const NameField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <BrassTextField
    {...props}
    required={false}
    name='name'
    label={translate('nameLabel')}
  />
);

export { NameField };
