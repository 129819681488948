import { useCallback, useEffect, useState } from 'react';
import {
  ProgressPopUp,
  DownloadFileByLink,
  IDownloadFileByLinkProps,
} from '../../../index';
import { useDispatch, useSelector } from 'react-redux';
import {
  progressPopUpActions,
  useProgressPopUp,
} from '../../../store/preogress-popup';

const ProgressPopUpFunctions = () => {
  const dispatch = useDispatch();
  const selector = useSelector(useProgressPopUp);
  const [closeProgressPopUp, setCloseProgressPopUp] = useState<boolean>(false);
  const updateProgressPopUpList = (progress: ProgressPopUp, value: number) => {
    dispatch(
      progressPopUpActions.updateProgressPopUp({ ...progress, progress: value }),
    );
  };

  const handleProgress = useCallback(async () => {
    if (selector?.progressPopUp?.stackProgress) {
      for (const x of selector.progressPopUp.stackProgress) {
        let status = await x.services.getStatusProgress();

        const NOT_RUNNING = async () => {
          x.services.requestDocument(x.props).finally(() => console.log('alef'));
        };

        const RUNNING = async () => {
          status = await x.services.getStatusProgress();
          if (status?.data.status) {
            updateProgressPopUpList(x, status.data?.percentComplete);
            actions[status?.data?.status]();
          }
        };

        const READY = async () => {
          updateProgressPopUpList(x, status.data?.percentComplete);
          DownloadFileByLink({
            fileUrl: status?.data.downloadLink,
            callback: () => {},
          } as IDownloadFileByLinkProps<string, void>);
        };

        const actions = {
          NOT_RUNNING,
          RUNNING,
          READY,
        };

        if (status?.data?.status) {
          actions[status?.data?.status]();
        }
      }
    }
  }, [selector?.progressPopUp?.stackProgress.length]);

  const onClose = () => {
    dispatch(progressPopUpActions.closeProgressPopUp());
    setCloseProgressPopUp(false);
  };

  useEffect(() => {
    handleProgress();
  }, [handleProgress]);

  return {
    selector,
    closeProgressPopUp,
    setCloseProgressPopUp,
    handleProgress,
    onClose,
  };
};

export { ProgressPopUpFunctions };
