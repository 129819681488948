import React, { Dispatch, ReactNode } from 'react';
import { ExcelExportColumnProps } from '@progress/kendo-react-excel-export';
import { translate, translateForLanguage } from './RelationalFlagGrid.translations';
import { GridColumnProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import style from './RelationalFlagGrid.module.scss';
import {
  DropDownData,
  GridAction,
  GridActions,
  GridItem,
  GridState,
} from '../../../../../models';
import {
  BrassButton,
  BrassGridCell,
  BrassGridCellProps,
  BrassGridColumnMenu,
  BrassGridColumnProps,
} from '../../../../../components/brass';
import { BrassIcon } from '../../../../../components/shared/icon';
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedV1 } from '../../../../../components/shared/buttons';

const getGroupableButton = (
  gridState: GridState<GridItem>,
  gridDispatch: Dispatch<GridAction>,
): ReactNode => (
  <BrassButton
    togglable
    title='Agrupar Por'
    icon='aggregate-fields'
    onClick={() => {
      if (!gridState.gridConfig.group) {
        gridDispatch({
          type: GridActions.UPDATE_GRID_CONFIG,
          payload: {
            ...gridState.gridConfig,
            group: [],
          },
        });
      } else {
        gridDispatch({
          type: GridActions.UPDATE_GRID_CONFIG,
          payload: {
            ...gridState.gridConfig,
            group: undefined,
          },
        });
      }
    }}
  />
);

const getColumns = (gridState: GridState<any>): GridColumnProps[] => {
  const hasGroup =
    gridState.gridConfig.group && gridState.gridConfig.group.length !== 0;

  const getColumnName = (): GridColumnProps =>
    ({
      resizable: true,
      field: 'name.value',
      minResizableWidth: 250,
      width: hasGroup ? 400 : 'auto',
      title: translate('name'),
      columnMenu: BrassGridColumnMenu,
      cell: (_props: BrassGridCellProps) => (
        <BrassGridCell textAlign='left' fieldName='name' {..._props} />
      ),
    } as GridColumnProps);

  const columns: GridColumnProps[] = [getColumnName()];
  return columns;
};

const getExcelExportColumns = (
  columns: BrassGridColumnProps[],
): ExcelExportColumnProps[] => {
  const _columns: ExcelExportColumnProps[] = columns
    .map((column: BrassGridColumnProps): ExcelExportColumnProps => {
      const result = {
        field: column.field,
        title: column.title,
      } as ExcelExportColumnProps;

      if (column.field === 'name') {
        result.width = 400;
      } else {
        result.width = 120;
      }
      return result;
    })
    .map((col: ExcelExportColumnProps, index: number) => {
      return {
        ...col,
        key: index,
      };
    });

  return _columns;
};

export { getColumns, getExcelExportColumns, getGroupableButton };
