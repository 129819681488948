import React, {
  useState,
  Fragment,
  useEffect,
  PropsWithChildren,
  FunctionComponent,
} from 'react';
import { Comment, CommentGroup } from '../../../../models';
import style from './CommentChat.module.scss';
import { translate } from './CommentChat.translations';
import { BrassChat, BrassLoader } from '../../../../../../components';
import { CommentsService } from '../../../../services/comments.service';
import { ApiResult, AuthUser, ChatMessage } from '../../../../../../models';
import { ChatMessageSendEvent } from '@progress/kendo-react-conversational-ui';
import { emptyGuid } from '../../../../../../util';

interface CommentChatProps extends PropsWithChildren<any> {
  data: Comment;
}

const CommentChat: FunctionComponent<CommentChatProps> = (
  props: CommentChatProps,
) => {
  const [messages, setMessages] = useState<ChatMessage[] | undefined>(undefined);
  const [lastMessage, setLastMessage] = useState<string>('');

  const onSendMessage = async (event: ChatMessageSendEvent): Promise<void> => {
    CommentsService.newChatMessage(
      props.data.referenceId!,
      props.data.group!,
      event.message,
    );
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setLastMessage(event.message.text!);
  };

  useEffect(() => {
    setMessages(undefined);
    props.data.referenceId !== emptyGuid() &&
      CommentsService.getChatMessages(
        props.data.referenceId!,
        props.data.group!,
      ).then((apiResult: ApiResult<ChatMessage[]>) => {
        setMessages(undefined);
        setMessages(
          apiResult.data.map(
            (item) =>
              new ChatMessage({ ...item, timestamp: new Date(item.timestamp!) }),
          ),
        );
      });
  }, [lastMessage, props.data]);

  return (
    <div className={style.displayCommunication}>
      {messages && (
        <BrassChat
          className={style.chat}
          callBackFunction={onSendMessage}
          initialMessages={messages}
          user={{ id: AuthUser.getId(), name: AuthUser.currentUser.name }}
        />
      )}
      {!messages && <BrassLoader useLoadingMask />}
    </div>
  );
};

export type { CommentChatProps };
export { CommentChat };
