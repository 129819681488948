import React, { FunctionComponent } from 'react';
import { translate } from './UpsertUserWindow.translations';
import { UpsertUserTabs } from '../upsert-user-tabs/UpsertUserTabs';
import { emptyGuid } from '../../../../../util';
import { BrassWindow } from '../../../../../components';

interface UpsertUserWindowProps {
  entityId?: string;
  onSaveClose: () => void;
  onCancelClose: () => void;
}

const UpsertUserWindow: FunctionComponent<UpsertUserWindowProps> = (
  props: UpsertUserWindowProps,
) => {
  const { entityId, onSaveClose, onCancelClose } = props;
  const isUpdating = entityId !== emptyGuid();

  return (
    <BrassWindow
      title={translate(isUpdating ? 'editUser' : 'addUser')}
      onClose={onCancelClose}
      minimizeButton={() => null}
      maximizeButton={() => null}
      showDarkBackground={false}
      initialWidth={700}
      initialHeight={305}
      modal={true}
    >
      <UpsertUserTabs
        isUpdating={isUpdating}
        recordId={entityId!}
        onSaveClose={onSaveClose}
        onCancelClose={onCancelClose}
      />
    </BrassWindow>
  );
};

UpsertUserWindow.defaultProps = {
  entityId: emptyGuid(),
};

export { UpsertUserWindow };
