import { emptyGuid } from '../util';
import { CookieService, LocalStorageService } from '../services';
// import { UserPermissionState } from '../modules/auth/store/state';
import { Language, ModuleUrlEnum } from './enum';
import {
  AcademyPermissionsV1,
  ConnectPermissions,
  NavigatorPermissions,
} from '../models';

interface AreaAuthResult {
  areaId: string;
  name: string;
  systems: SystemAuthResult[];
}

interface SystemAuthResult {
  systemId: string;
  name: string;
}

class AuthUser {
  public id: string = emptyGuid();
  public name: string = '';
  public email: string = '';
  public role: string = '';
  public photo: string = '';

  public sessionKey: string = '';
  public accountId: string = emptyGuid();
  public accountName: string = '';
  public areas: AreaAuthResult[] = [];
  public dataUser: [key: string, value?: string][] = [];

  public token: string = '';
  public tokenExpirationDate: Date = new Date();
  public language: Language = Language.DEFAULT;

  // needChangePassword
  public ncp: boolean = false;

  // public permissions: UserPermissionState = {
  //   connect: [],
  //   core: [],
  //   navigator: [],
  //   drive: [],
  //   academy: [],
  //   integration: [],
  // };

  public permission: number[] = [];

  public static _currentUser: AuthUser;

  constructor(authUser: Partial<AuthUser>) {
    Object.assign(this, authUser);
  }

  public static get currentUser(): AuthUser {
    AuthUser._currentUser = CookieService.getCookieJSON<AuthUser>(
      CookieService.cookieAuthUser,
    ) as AuthUser;

    if (AuthUser._currentUser) {
      AuthUser._currentUser.permission = LocalStorageService.getLocalStorageJSON<
        number[]
      >(CookieService.cookiePermissions) as number[];
    }

    return AuthUser._currentUser;
  }

  public static set currentUser(authUser: AuthUser) {
    const permission = authUser.permission;
    authUser.permission = [];
    CookieService.setCookieJSON(CookieService.cookieAuthUser, authUser);
    LocalStorageService.setLocalStorageJSON(
      CookieService.cookiePermissions,
      permission,
    );

    AuthUser._currentUser = CookieService.getCookieJSON<AuthUser>(
      CookieService.cookieAuthUser,
    ) as AuthUser;

    if (AuthUser._currentUser) {
      AuthUser._currentUser.permission = LocalStorageService.getLocalStorageJSON<
        number[]
      >(CookieService.cookiePermissions) as number[];
    }
  }

  public static getId = (): string => {
    if (AuthUser.isAuthenticated()) {
      return AuthUser.currentUser.id;
    }
    return emptyGuid();
  };

  public static getEmail = (): string => {
    if (AuthUser.isAuthenticated()) {
      return AuthUser.currentUser.email;
    }
    return '';
  };

  public static isAuthenticated = (): boolean => {
    const user = AuthUser.currentUser;
    if (user && user.id && user.id !== '' && user.id !== emptyGuid()) {
      return true;
    }
    return false;
  };

  public static userNeedChangePassword = (): boolean => {
    if (AuthUser.isAuthenticated()) {
      return AuthUser.currentUser.ncp;
    }
    return false;
  };

  public static getUserPermissions = (): number[] => {
    if (AuthUser.isAuthenticated()) {
      return AuthUser.currentUser.permission as number[];
    }

    return [];
  };

  public static getAdditionalUserPermissions = (): string[] => {
    try {
      if (AuthUser.isAuthenticated()) {
        var adp = JSON.parse(
          sessionStorage.getItem('additional-permissions') || '[]',
        ) as string[];
        return adp;
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  };

  public static getDefaultModule = (): ModuleUrlEnum => ModuleUrlEnum.CONNECT;
  /* RLG: 03-03-2022 -> Ficou acordado que o usuário ao atenticar sempre carrega o CONNECT
                        como ponto inicial de acesso
    {
    const permissions = AuthUser.getUserPermissions();
    // A ordem dos ifs é importante
    if (permissions && permissions.includes(ConnectPermissions.CONNECT_MODULE)) {
      return ModuleUrlEnum.CONNECT;
    } else if (
      permissions &&
      permissions.includes(NavigatorPermissions.NAVIGATOR_MODULE)
    ) {
      return ModuleUrlEnum.NAVIGATOR;
    } else if (
      permissions &&
      permissions.includes(SmartLibPermissions.SMART_LIB_MODULE)
    ) {
      return ModuleUrlEnum.DRIVE;
    } else if (
      permissions &&
      permissions.includes(AcademyPermissionsV1.ACADEMY_MODULE)
    ) {
      return ModuleUrlEnum.ACADEMY;
    } else {
      return ModuleUrlEnum.CONNECT;
    }
  };*/

  public static isValidUserId = (): boolean => AuthUser.getId() !== emptyGuid();

  public static checkPermission = (
    permission:
      | ConnectPermissions
      | NavigatorPermissions
      | AcademyPermissionsV1
      | number,
  ): boolean => {
    const permissions = AuthUser.getUserPermissions();
    const result = permissions && permissions?.includes(permission);
    if (permission === 140000 || permission === 120000 || permission === 110000) {
      // Remover código!
      return true;
    }
    return result;
  };

  public static checkPermissionV2 = (
    permission:
      | ConnectPermissions
      | NavigatorPermissions
      | AcademyPermissionsV1
      | number
      | string,
  ): boolean => {
    const permissions = AuthUser.getUserPermissions();
    if (typeof permission == 'number') {
      const result = permissions && permissions?.includes(permission as number);
      if (permission === 140000 || permission === 120000 || permission === 110000) {
        // Remover código!
        return true;
      }
    } else {
      var perm = AuthUser.getAdditionalUserPermissions();
      return perm.includes(`${permission}`);
    }
    return false;
  };
}

export { AuthUser };
