import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonSharedPropsv2, BrassButtonSharedV2 } from './ButtonV2';

const BrassButtonSaveV1: FunctionComponent<BrassButtonSharedPropsv2> = (
  props: BrassButtonSharedPropsv2,
) => (
  <BrassButtonSharedV2
    themeColor={'dark'}
    labelKey='save'
    fillMode={'solid'}
    className='brass-button-save'
    tooltipKey='clickToSave'
    translations={translations}
    style={{ fontSize: '12px' }}
    icon={<BrassIcon icon={faSave} fontSize={14} style={{ fontSize: '14px' }} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    save: 'ahorrar',
    clickToSave: 'Haga clic para guardar',
  },
  enUS: {
    save: 'save',
    clickToSave: 'Click to save',
  },
  ptBR: {
    save: 'salvar',
    clickToSave: 'Clique para salvar',
  },
};

export { BrassButtonSaveV1 };
