import { emptyGuid } from '../../../util';
import { WorkedDaysPlotData } from './WorkedDaysPlotData.model';

class AcademyStatisticsModel {
  public userId: string = emptyGuid();
  public dailyHoursLimit: string = '';
  public dailyHoursLimitNumber: number = 0;
  public totalClassHours: number = 0;
  public totalClassHoursText: string = '';
  public completedCourses: number = 0;
  public coursesTaken: number = 0;
  public performance: number = 0;
  public disciplinePerformance: number = 0;
  public workedDaysPlotData: WorkedDaysPlotData[] = [];

  constructor(userStatistics: Partial<AcademyStatisticsModel>) {
    Object.assign(this, userStatistics);
  }
}

export { AcademyStatisticsModel };
