import { BaseService } from './base.service';

class ValidatorService extends BaseService {
  public static validateEmail = (text: string): boolean => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    return emailRegex.test(text);
  };
}

export { ValidatorService };
