import React from 'react';
import { emptyGuid, newGuid } from '../../util';
import { ApiResult } from '../api';

export enum EProgressPopUpTypeStatus {
  NOT_RUNNING = 'NOT_RUNNING',
  RUNNING = 'RUNNING',
  READY = 'READY',
}

export interface IStatusProgressPopUp {
  status: EProgressPopUpTypeStatus;
  percentComplete: number;
  downloadLink: string;
}

export interface IServicesProgressPopUp {
  getStatusProgress: () => Promise<ApiResult<IStatusProgressPopUp>>;
  requestDocument: (_props?: any) => Promise<ApiResult<any>>;
}

class ProgressPopUp {
  public id: string = emptyGuid();
  public name: string = '';
  public size: string | number = '';
  public progress: number = 0;
  public services: IServicesProgressPopUp = {} as IServicesProgressPopUp;
  public props?: any;

  constructor(notification: Partial<ProgressPopUp>) {
    notification.id = newGuid();
    Object.assign(this, notification);
  }
}

export { ProgressPopUp };
