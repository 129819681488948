import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconTrash: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    {...props}
    fill={props?.fill ?? '#424242'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.43748 17.5C5.10415 17.5 4.81248 17.375 4.56248 17.125C4.31248 16.875 4.18748 16.5833 4.18748 16.25V4.375H3.33331V3.125H7.24998V2.5H12.75V3.125H16.6666V4.375H15.8125V16.25C15.8125 16.5833 15.6875 16.875 15.4375 17.125C15.1875 17.375 14.8958 17.5 14.5625 17.5H5.43748ZM14.5625 4.375H5.43748V16.25H14.5625V4.375ZM7.64581 14.4583H8.89581V6.14583H7.64581V14.4583ZM11.1041 14.4583H12.3541V6.14583H11.1041V14.4583ZM5.43748 4.375V16.25V4.375Z'
      fill={props?.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconTrash };
