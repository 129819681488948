import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconCopy: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.22964 0.200989H15.2788C15.9178 0.200989 16.4986 0.461989 16.9192 0.882572C17.3398 1.30314 17.6008 1.88401 17.6008 2.52301V12.7633C17.6008 13.4023 17.3398 13.9831 16.9192 14.4038C16.4987 14.8243 15.9178 15.0854 15.2788 15.0854H13.0925V17.4777C13.0925 18.1167 12.8315 18.6975 12.4109 19.1181C11.9903 19.5387 11.4095 19.7997 10.7705 19.7997H2.72079C2.08179 19.7997 1.50099 19.5387 1.08094 19.1181C0.66037 18.6976 0.399353 18.1167 0.399353 17.4777V7.23736C0.399353 6.59836 0.660353 6.01756 1.08094 5.59692C1.5015 5.17636 2.08237 4.91534 2.72079 4.91534H4.90712V2.52301C4.90712 1.88401 5.16812 1.30321 5.5887 0.882572C6.00927 0.462005 6.59014 0.200989 7.22914 0.200989H7.22964ZM11.944 15.0847H7.22964C6.59064 15.0847 6.00984 14.8237 5.5892 14.4031C5.16864 13.9825 4.90762 13.4016 4.90762 12.7626V6.06297H2.72129C2.39909 6.06297 2.10565 6.195 1.89329 6.40796C1.68027 6.62097 1.54772 6.91434 1.54772 7.23654V17.4769C1.54772 17.7991 1.67975 18.0925 1.89329 18.3055C2.1063 18.5185 2.39967 18.6504 2.72129 18.6504H10.771C11.0932 18.6504 11.3866 18.5184 11.5995 18.3055C11.8126 18.0924 11.9445 17.7991 11.9445 17.4769V15.0845L11.944 15.0847ZM15.2788 1.34866H7.22964C6.90744 1.34866 6.614 1.48069 6.40105 1.69364C6.18804 1.90666 6.05607 2.20002 6.05607 2.52222V12.7626C6.05607 13.0848 6.1881 13.3782 6.40105 13.5911C6.61407 13.8042 6.90744 13.9361 7.22964 13.9361H15.2793C15.6015 13.9361 15.8949 13.8041 16.1079 13.5911C16.3209 13.3781 16.4529 13.0848 16.4529 12.7626V2.52222C16.4529 2.20002 16.3208 1.90659 16.1079 1.69364C15.8949 1.48062 15.6015 1.34866 15.2793 1.34866H15.2788Z'
      fill='white'
    />
  </svg>
);

export { SVGIconCopy };
