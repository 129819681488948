import React, { FunctionComponent } from 'react';
import {
  IPublishDocumentsListProps,
  IPublishDocumentsPayload,
  ResultPublishDocumentType,
} from '../PublishDocumentsModal.interfaces';
import { UploadImage } from './upload-file/upload-image/UploadImage';
import { UploadPDF } from './upload-file/upload-pdf/UploadPDF';
import { UploadVideo } from './upload-file/upload-video/UploadVideo';
import style from './PublishDocumentWindow.module.scss';
import {
  BrassWindow,
  BrassWindowProps,
  BrassWindowTitleCustom,
} from '../../../brass';
import { UploadImage2d3d } from './upload-file/upload-image-2d-3d/UploadImage2d3d';
import { translate } from './PublishDocumentWindow.translations';
import { UploadImageV2 } from './upload-file/upload-image-v2/UploadImageV2';
import { UploadVideoV2 } from './upload-file/upload-video-v2/UploadVideoV2';
import { UploadPDFV2 } from './upload-file/upload-pdf-v2/UploadPDFV2';
import { UploadImage2d3dV2 } from './upload-file/upload-image-2d-3d-v2/uploadImage2d3dV2';

interface BrassPublishDocumentWindowProps extends BrassWindowProps {
  onCancelClose: () => void;
  publishDocumentElement: IPublishDocumentsListProps;
  setPublishDocument: IPublishDocumentsPayload;
  getPublishDocument: (e: ResultPublishDocumentType<any>[]) => any;
}

const BrassPublishDocumentWindow: FunctionComponent<
  BrassPublishDocumentWindowProps
> = (props: BrassPublishDocumentWindowProps) => {
  const {
    onCancelClose,
    publishDocumentElement,
    setPublishDocument,
    getPublishDocument,
  } = props;

  const handleGetPublishDocument = (e: ResultPublishDocumentType<any>[]): any => {
    getPublishDocument(e);
  };

  return (
    <BrassWindow
      onClose={onCancelClose}
      title={
        <BrassWindowTitleCustom
          title={translate(
            `${
              publishDocumentElement?.action ? publishDocumentElement?.action : ''
            }`,
          )}
          className={style.title}
        />
      }
      maximizeButton={() => null}
      minimizeButton={() => null}
      modal={true}
      overlayStyle={{ zIndex: 0 }} // por default é necessário o zIndex ser 0 para funcionamento correto das modais
      showDarkBackground={false}
      initialHeight={475}
      initialWidth={435}
      {...props}
    >
      <div className={style['publish-main-content']}>
        {publishDocumentElement.action === 'IMAGE' && (
          // <UploadImage
          //   setPublishDocument={setPublishDocument}
          //   onChange={(e: any) => handleGetPublishDocument(e)}
          //   publishDocumentElement={publishDocumentElement}
          //   onCancelClose={onCancelClose}
          // />
          <UploadImageV2
            setPublishDocument={setPublishDocument}
            onChange={(e: any) => handleGetPublishDocument(e)}
            publishDocumentElement={publishDocumentElement}
            onCancelClose={onCancelClose}
          />
        )}
        {publishDocumentElement.action === 'VIDEO' && (
          // <UploadVideo
          //   setPublishDocument={setPublishDocument}
          //   onChange={(e) => getPublishDocument(e)}
          //   publishDocumentElement={publishDocumentElement}
          //   onCancelClose={onCancelClose}
          // />
          <UploadVideoV2
            setPublishDocument={setPublishDocument}
            onChange={(e) => getPublishDocument(e)}
            publishDocumentElement={publishDocumentElement}
            onCancelClose={onCancelClose}
          />
        )}
        {publishDocumentElement.action === 'DOCUMENT' && (
          // <UploadPDF
          //   setPublishDocument={setPublishDocument}
          //   onChange={(e) => getPublishDocument(e)}
          //   publishDocumentElement={publishDocumentElement}
          //   onCancelClose={onCancelClose}
          // />
          <UploadPDFV2
            setPublishDocument={setPublishDocument}
            onChange={(e) => getPublishDocument(e)}
            publishDocumentElement={publishDocumentElement}
            onCancelClose={onCancelClose}
          />
        )}
        {publishDocumentElement.action === 'IMAGE2D3D' && (
          // <UploadImage2d3d
          //   setPublishDocument={setPublishDocument}
          //   onChange={(e) => getPublishDocument(e)}
          //   publishDocumentElement={publishDocumentElement}
          //   onCancelClose={onCancelClose}
          // />
          <UploadImage2d3dV2
            setPublishDocument={setPublishDocument}
            onChange={(e) => getPublishDocument(e)}
            publishDocumentElement={publishDocumentElement}
            onCancelClose={onCancelClose}
          />
        )}
      </div>
    </BrassWindow>
  );
};

export type { BrassPublishDocumentWindowProps };
export { BrassPublishDocumentWindow };
