import React, { FunctionComponent } from 'react';
import style from './ListRelationalContextMap.module.scss';
import { translate } from './ListRelationalContextMap.translations';
import { BrassListCard } from '../../../../../components/shared/list-card';
import { BrassLoader } from '../../../../../components/brass';
import { Label } from '@progress/kendo-react-labels';
import { BrassBreadcrumbV1 } from '../../../../../components/shared/breadcrumb';
import { ListRelationalMapFunctions } from './LIstRelationalContextMap.functions';

interface ListTaxonomicMapWindowProps {}

const ListRelationalContextMap: FunctionComponent<
  ListTaxonomicMapWindowProps
> = () => {
  const {
    relationalGroup,
    relationalContextMap,
    handleGetGroupId,
    list,
    setList,
    onOpen,
    isLoading,
    pagination,
    scrollObserver,
  } = ListRelationalMapFunctions();

  return (
    <>
      {!relationalGroup.breadcrumb ? (
        <BrassBreadcrumbV1
          items={[relationalContextMap]}
          className={style.breadcrumb}
        />
      ) : (
        <BrassBreadcrumbV1
          onClick={handleGetGroupId}
          items={relationalGroup?.breadcrumb?.map((item: any) => {
            item.id = item.groupRelationalId;
            return item;
          })}
          className={style.breadcrumb}
        />
      )}
      <Label style={{ opacity: 0.6, fontSize: '11px' }}>
        {translate('contextMap')}
      </Label>
      <div className={style.container}>
        <BrassListCard
          list={list as any}
          setList={setList as any}
          onAddClick={onOpen}
          fieldIdName={'mapContextRelationalId'}
          allowEdit={false}
          noImage={true}
          take={pagination.take}
          scrollObserver={scrollObserver}
          className_contentCardText={{
            width: '92%',
          }}
        />
      </div>

      {isLoading && <BrassLoader useLoadingMask />}
    </>
  );
};

export { ListRelationalContextMap };
