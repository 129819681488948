import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    name: 'Nombre',
    flagRelational: 'Seleccione la bandera de relación',
    link: 'Enlance',
  },
  enUS: {
    name: 'Name',
    flagRelational: 'Select the relationship flag',
    link: 'Link',
  },
  ptBR: {
    name: 'Nome',
    flagRelational: 'Selecione a flag de relacionamento',
    link: 'Vincular',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const translateForLanguage = (
  key: string,
  language: string,
  args?: string[],
): string => new I18nService(translations).translateForLanguage(key, language, args);

export { translate, translateForLanguage };
