import React, {
  FunctionComponent,
  useState,
  useEffect,
  Fragment,
  Dispatch,
  SetStateAction,
} from 'react';
import { translate } from './UserGroupGridNonParticipants.translations';
import { GridColumn } from '@progress/kendo-react-grid'; // CHANGE
import styles from './UserGroupGridNonParticipants.module.scss';
import {
  UserGroupData,
  UserGroupNonParticipant,
  UserGroupParticipant,
} from '../../../../../models';
import { I18nService } from '../../../../../../../services';
import {
  AuthUser,
  ApiGridResult,
  ConnectPermissions,
} from '../../../../../../../models';
import {
  AccessDenied,
  BrassButton,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassTreeListGrid,
  BrassIconUser,
} from '../../../../../../../components';

interface UserGroupGridNonParticipantsProps {
  usersInsideGroupList: UserGroupData;
  setUsersInsideGroupList: any;
  setAllowSubmit: Dispatch<SetStateAction<boolean>>;
  usersOutsideGroupList: ApiGridResult<UserGroupNonParticipant>;
  setUsersOutsideGroupList: any;
}

const UserGroupGridNonParticipants: FunctionComponent<
  UserGroupGridNonParticipantsProps
> = (props: UserGroupGridNonParticipantsProps) => {
  const [gridLoading, setGridLoading] = useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [usersOutsideGroupList, setUsersOutsideGroupList] = useState<
    ApiGridResult<UserGroupParticipant>
  >(props.usersOutsideGroupList);
  const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>({
    ...BrassGridConfigDefault,
    take: 99999,
  });

  const loadGridData = (): void => {
    setGridLoading(true);
    setUsersOutsideGroupList(props.usersOutsideGroupList);
    setGridLoading(false);
  };

  useEffect(loadGridData, [props.usersOutsideGroupList, gridLoading]);

  const insertUsersIntoGroup = (): void => {
    // insert user into group
    const newUsersIntoGroup = (props.usersInsideGroupList.users ||
      []) as UserGroupParticipant[];

    selectedUsers.map((userListId: string): void => {
      const userSelected = usersOutsideGroupList.records?.find(
        (item: UserGroupParticipant) => item.id === userListId,
      );

      newUsersIntoGroup.unshift({
        ...userSelected,
        name: userSelected!.name,
        isTemporary: true,
        deleting: false,
      } as UserGroupParticipant);
    });

    // variavel para para iteração
    let outsiderUsers = usersOutsideGroupList;
    // remove user from the list outsiderUsers
    selectedUsers.map((userListId) => {
      const remainedUser = outsiderUsers.records?.filter(
        (item: any) => item.id !== userListId,
      );
      outsiderUsers = {
        ...outsiderUsers,
        records: remainedUser,
      };
    });

    // lista interna
    setUsersOutsideGroupList({
      ...usersOutsideGroupList,
      records: outsiderUsers.records,
    });

    // lista externa de usuarios
    props.setUsersOutsideGroupList({
      ...props.usersOutsideGroupList,
      records: outsiderUsers.records,
    });

    // lista de usuarios incluidos no grupo
    props.setUsersInsideGroupList({
      ...props.usersInsideGroupList,
      users: newUsersIntoGroup,
    });
    props.setAllowSubmit(true);
  };

  const columns = [
    <GridColumn
      key='name'
      field='name'
      title={translate('name')}
      columnMenu={BrassGridColumnMenu}
      width={gridConfig.group?.length ? '200' : 'auto'}
    />,
    <GridColumn
      key='email'
      field='email'
      title={translate('email')}
      columnMenu={BrassGridColumnMenu}
      width={gridConfig.group?.length ? '200' : 'auto'}
    />,
  ];

  if (!AuthUser.checkPermission(ConnectPermissions.CONNECT_USERGROUP_READ)) {
    return <AccessDenied />;
  }

  return (
    <Fragment>
      <BrassTreeListGrid
        dataItemKey='id'
        visibleButtonRefresh={false}
        className={styles.grid}
        columns={columns}
        gridData={usersOutsideGroupList}
        gridLoading={gridLoading}
        setGridLoading={setGridLoading}
        gridTitle={translate('usersList')}
        gridIcon={<BrassIconUser />}
        gridConfig={gridConfig}
        updateGridConfig={setGridConfig}
        updateGridData={setUsersOutsideGroupList}
        toolbarBeforeActions={[
          <BrassButton
            type='button'
            disabled={selectedUsers.length === 0}
            key='brassbutton'
            onClick={() => insertUsersIntoGroup()}
          >
            {translate('insert', [
              selectedUsers.length > 0 ? selectedUsers.length.toString() : '',
            ])}
            {/* Inserir {selectedUsers.length > 0 && selectedUsers.length} usuários selecionados */}
          </BrassButton>,
        ]}
        selectable={{
          enabled: true,
          mode: 'multiple',
        }}
        onSelectionChange={setSelectedUsers}
        onHeaderSelectionChange={setSelectedUsers}
        pageable={false}
      />
    </Fragment>
  );
};

export { UserGroupGridNonParticipants };
