import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassBadge, BrassBadgeContainer, BrassBadgeProps } from '../../brass';

const BrassBadgeNew: FunctionComponent<BrassBadgeProps> = (
  props: BrassBadgeProps,
) => {
  return (
    <BrassBadgeContainer>
      <BrassBadge
        align={{ vertical: 'top', horizontal: 'start' }}
        position='outside'
        size='small'
        shape='pill'
        themeColor='error'
        style={{
          margin: '14px 0 0 -2px',
        }}
        {...props}
      >
        {translate('new')}
      </BrassBadge>
      {props.children}
    </BrassBadgeContainer>
  );
};

const translations: II18n = {
  es: {
    new: 'Nuevo',
  },
  enUS: {
    new: 'New',
  },
  ptBR: {
    new: 'Novo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { BrassBadgeNew };
