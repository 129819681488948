import React, { FunctionComponent } from 'react';
import style from './Module.module.scss';
import { translate } from './Module.translations';
import { ModuleEnum } from '../../../../../../models';

interface ModuleProps {
  iconModule: any;
  module: ModuleEnum;
}

const Module: FunctionComponent<ModuleProps> = (props: ModuleProps) => (
  <div className={style['module-title']} translate='no'>
    {props.iconModule}
    {translate(props.module.toLocaleLowerCase())}
  </div>
);

export { Module };
export type { ModuleProps };
