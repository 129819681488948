import React, { Fragment, FunctionComponent } from 'react';
import {
  FieldProps,
  FieldValidatorType,
  FieldWrapper,
} from '@progress/kendo-react-form';
import {
  BrassError,
  BrassField,
  BrassInput,
  BrassLoader,
  BrassMultiSelectTree,
  BrassMultiSelectTreeProps,
} from '../../..';
import { DropDownTreeData, II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';
import style from './MultiSelectField.module.scss';

const translations: II18n = {
  es: {
    required: 'Este campo é obrigatório',
  },
  enUS: {
    required: 'This field is required',
  },
  ptBR: {
    required: 'Este campo é obrigatório',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface BrassMultiSelectTreeFieldInputProps extends BrassMultiSelectTreeProps {
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const BrassMultiSelectTreeFieldInput: FunctionComponent<
  BrassMultiSelectTreeFieldInputProps
> = (fieldRenderProps: any): any => {
  const { validationMessage, disabled, onChange, visited, defaultValue } =
    fieldRenderProps;

  const values: any[] = [];
  const findEntireObject = (tree: DropDownTreeData[]): any => {
    tree.forEach((treeElement: DropDownTreeData) => {
      if (defaultValue && defaultValue.includes(treeElement.value)) {
        values.push(treeElement);
      } else if (treeElement.children) {
        findEntireObject(treeElement.children);
      }
    });
    return;
  };

  findEntireObject(fieldRenderProps.data);

  const onValueChange = (newValues: DropDownTreeData[]) => {
    // fieldRenderProps.onChangeCallback(newValues);
    onChange({ value: newValues });
  };

  return (
    <Fragment>
      <BrassMultiSelectTree
        {...fieldRenderProps}
        disabled={disabled}
        defaultValue={values}
        onChangeCallback={onValueChange}
        className={style.selector}
        popupSettings={{ popupClass: style.selector, ...fieldRenderProps }}
      />
      {visited && validationMessage && <BrassError>{validationMessage}</BrassError>}
    </Fragment>
  );
};

interface BrassMultiSelectTreeFieldProps
  extends Omit<FieldProps, 'valid, component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  value?: string;
  visited?: boolean;
  required?: boolean;
  data?: Array<any>;
  leftButtonIcon?: any;
  onLeftButtonClick?: () => any;
  // selectParentItem?: boolean;
  // textField?: string;
  // dataItemKey?: string;
}

const BrassMultiSelectTreeField: FunctionComponent<
  BrassMultiSelectTreeFieldProps
> = (props: BrassMultiSelectTreeFieldProps) => {
  const { name, required, loading } = props;
  const requiredValidator = (value: any) => {
    if (!props.required) return '';
    else return value && props.required ? '' : translate('required');
  };

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (props.disabled) {
    return (
      <FieldWrapper>
        <Label>{props.required ? `${props.label}*` : props.label}</Label>
        <BrassInput disabled />
      </FieldWrapper>
    );
  }

  if (loading) {
    return (
      <FieldWrapper>
        <Label style={{ opacity: 0.6 }}>
          {required ? `${props.label}*` : props.label}
        </Label>
        <BrassLoader primary={false} />
      </FieldWrapper>
    );
  }

  return (
    <FieldWrapper>
      <Label>{props.required ? `${props.label}*` : props.label}</Label>
      <BrassField
        {...props}
        name={name!}
        component={BrassMultiSelectTreeFieldInput}
        validator={validators}
        label={undefined /* required ? `${props.label}*` : props.label */}
      />
    </FieldWrapper>
  );
};

export { BrassMultiSelectTreeField };
export type { BrassMultiSelectTreeFieldProps };
