import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    NOT_STARTED: 'NO COMENZADO',
    IN_PROGRESS: 'EN PROGRESO',
    COMPLETED: 'COMPLETADO',
    STOPPED: 'DETENIDO',
    CANCELLED: 'CANCELADO',
    ACTIVE: 'ACTIVO',
    INACTIVE: 'INACTIVO',
    ALL: 'TODOS',
  },
  enUS: {
    NOT_STARTED: 'NOT STARTED',
    IN_PROGRESS: 'IN PROGRESS',
    COMPLETED: 'COMPLETED',
    STOPPED: 'STOPPED',
    CANCELLED: 'CANCELLED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    ALL: 'ALL',
  },
  ptBR: {
    NOT_STARTED: 'NÃO INICIADO',
    IN_PROGRESS: 'EM PROGRESSO',
    COMPLETED: 'FINALIZADO',
    STOPPED: 'PARADO',
    CANCELLED: 'CANCELADO',
    ACTIVE: 'ATIVO',
    INACTIVE: 'INATIVO',
    ALL: 'TODOS',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
