import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconUnLinked: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M15.625 13.9375L14.6042 12.9167C15.3125 12.7778 15.9028 12.4444 16.375 11.9167C16.8472 11.3889 17.0833 10.7708 17.0833 10.0625C17.0833 9.25694 16.7986 8.56944 16.2292 8C15.6597 7.43056 14.9722 7.14583 14.1667 7.14583H10.9375V5.89583H14.1667C15.3194 5.89583 16.3021 6.30208 17.1146 7.11458C17.9271 7.92708 18.3333 8.90972 18.3333 10.0625C18.3333 10.9236 18.0833 11.7014 17.5833 12.3958C17.0833 13.0903 16.4306 13.6042 15.625 13.9375ZM12.375 10.6875L11.125 9.4375H13.2292V10.6875H12.375ZM16.9583 18.8333L1.3125 3.1875L2.20833 2.29167L17.8542 17.9375L16.9583 18.8333ZM9.375 14.1667H5.83333C4.68056 14.1667 3.69792 13.7604 2.88542 12.9479C2.07292 12.1354 1.66667 11.1528 1.66667 10C1.66667 9 1.97569 8.11805 2.59375 7.35417C3.21181 6.59028 4 6.11111 4.95833 5.91667L6.125 7.08333H5.83333C5.02778 7.08333 4.34028 7.36805 3.77083 7.9375C3.20139 8.50694 2.91667 9.19444 2.91667 10C2.91667 10.8056 3.20139 11.4931 3.77083 12.0625C4.34028 12.6319 5.02778 12.9167 5.83333 12.9167H9.375V14.1667ZM6.77083 10.625V9.375H8.41667L9.66667 10.625H6.77083Z' />
  </svg>
);

export { SGVIconUnLinked };
