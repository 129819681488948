import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    dailyWorkHours: 'Horas trabajadas',
    dailyWorkHoursLimit: 'Límites de horas de trabajo',
  },
  enUS: {
    dailyWorkHours: 'Hours Worked',
    dailyWorkHoursLimit: 'Hours of Work',
  },
  ptBR: {
    dailyWorkHours: 'Horas Trabalhadas',
    dailyWorkHoursLimit: 'Limites de Horas de Trabalho',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
