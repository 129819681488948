import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import style from './Input.module.scss';

interface BrassInputProps extends InputProps {}
const BrassInput: FunctionComponent<BrassInputProps> = (props: BrassInputProps) => {
  const { className } = props;
  const _className = classnames(style['brass-input'], className);
  return <Input {...props} className={_className} />;
};

export { BrassInput };
export type { BrassInputProps };
