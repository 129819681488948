import React from 'react';
import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    successOnUpdate: 'Grupo de usuarios actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar un grupo de usuarios',
    errorOnInsert: 'Error al intentar agregar un grupo de usuarios',
    successOnInsert: 'Grupo de usuarios agregado exitosamente',
    INTERNAL_SERVER_ERROR: 'Error interno del servidor. Inténtalo de nuevo.',
    usersInThisGroup: 'Usuarios en este grupo',
    data: 'Dados del grupo',
    users: 'Usuarios',
    usersOutThisGroup: 'Usuarios fuera de este grupo',
    privileges: 'Privilegios',
    saveGroupChanges: 'Guardar cambios de grupo',
  },
  enUS: {
    successOnUpdate: 'Usergroup updated successfully',
    errorOnUpdate: 'Error trying to update a user group',
    errorOnInsert: 'Error trying to add a user group',
    successOnInsert: 'Usergroup successfully added',
    INTERNAL_SERVER_ERROR: 'Internal server error. Try again.',
    usersInThisGroup: 'Users in this group',
    data: 'Group Data',
    users: 'Users',
    usersOutThisGroup: 'Users out of this group',
    privileges: 'Privileges',
    saveGroupChanges: 'Save group changes',
  },
  ptBR: {
    successOnUpdate: 'Grupo de Usuários atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar um grupo de usuário',
    errorOnInsert: 'Erro ao tentar adicionar um grupo de usuário',
    successOnInsert: 'Grupo de usuário adicionado com sucesso',
    INTERNAL_SERVER_ERROR: 'Erro interno no servidor. Tente novamente.',
    usersInThisGroup: 'Usuários neste grupo',
    data: 'Dados do Grupo',
    users: 'Usuários',
    usersOutThisGroup: 'Usuários fora deste grupo',
    privileges: 'Privilégios',
    saveGroupChanges: 'Salvar modificações do grupo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
