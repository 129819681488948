import React, { CSSProperties, Fragment, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { Loader, LoaderProps } from '@progress/kendo-react-indicators';
import { BrassBackground } from '../layout';

interface BrassLoaderProps extends LoaderProps {
  primary?: boolean;
  useLoadingMask?: boolean;
  useBackground?: boolean;
  style?: CSSProperties;
}

const BrassLoader: FunctionComponent<BrassLoaderProps> = (
  props: BrassLoaderProps,
) => {
  const { className, primary, useLoadingMask, useBackground, style, size } = props;
  const _className = classnames('k-loading-mask', 'brass-loader', className);

  // if (useLoadingMask) {
  //   return (
  //     <Fragment>
  //       {useBackground && <BrassBackground />}
  //       {createPortal(
  //         <div className={_className} style={style}>
  //           <span className='k-loading-text'>Loading</span>
  //           <div className='k-loading-image'></div>
  //           <div className='k-loading-color'></div>
  //         </div>,
  //         document.getElementById('overlay-root') as HTMLElement,
  //       )}
  //     </Fragment>
  //   );
  // } else {
  //   return (
  //     <Fragment>
  //       {createPortal(
  //         <Loader themeColor={primary ? 'light' : 'dark'} className={_className} />,
  //         document.getElementById('overlay-root') as HTMLElement,
  //       )}
  //     </Fragment>
  //   );
  // }

  if (useLoadingMask) {
    return (
      <div className={_className} style={style}>
        <span className='k-loading-text'>Loading</span>
        <div className='k-loading-image'></div>
        <div className='k-loading-color'></div>
      </div>
    );
  } else {
    return (
      <Loader
        themeColor={primary ? 'light' : 'dark'}
        style={style}
        className={_className}
        size={size}
      />
    );
  }
};

BrassLoader.defaultProps = {
  primary: true,
  useLoadingMask: false,
  useBackground: false,
};

export { BrassLoader };
export type { BrassLoaderProps };
