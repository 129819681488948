import React, { FunctionComponent } from 'react';
import { BrassTooltip } from '../../../components/brass/tooltip/Tooltip';

interface BrassLogoProps {
  height?: string;
  width?: string;
  className?: string;
}

const BrassLogo: FunctionComponent<BrassLogoProps> = (props: BrassLogoProps) => (
  <div className={props.className}>
    <BrassTooltip>
      <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M30 15C30 23.2843 23.2843 30 15 30C6.71572 30 0 23.2843 0 15C0 6.71572 6.71572 0 15 0C23.2843 0 30 6.71572 30 15Z'
          fill='#C4102F'
        />
        <path
          name='white'
          d='M16.6519 14.1635C17.3 14.1277 17.9005 14.1811 18.4553 14.3343C19.0094 14.4869 19.4914 14.747 19.9006 15.1057C20.3097 15.4638 20.6297 15.9128 20.8598 16.4341C21.0899 16.9566 21.2055 17.5548 21.205 18.2193C21.205 19.6928 20.642 20.7688 19.5166 21.6599C18.3913 22.5428 16.8568 23.2872 14.9125 23.7715C13.378 24.1408 11.8429 24.4601 10.3083 24.4601C10.5643 23.6929 10.8197 22.9168 11.0756 22.1325V8.83199C10.8197 8.08116 10.5643 7.32271 10.3083 6.55545C11.8429 6.55545 13.378 6.23552 14.9125 5.86686C15.6451 5.69016 16.3449 5.64437 17.01 5.75356C17.6751 5.86216 18.2592 6.087 18.7623 6.42748C19.2648 6.76796 19.6657 7.21293 19.9645 7.74596C20.2628 8.27958 20.4125 8.85194 20.4125 9.44838C20.4125 9.92564 20.3185 10.3835 20.1313 10.8256C19.9434 11.2682 19.6839 11.6868 19.3511 12.0912C19.0188 12.4957 18.622 12.8726 18.1617 13.2283C17.7015 13.5841 17.1978 13.8975 16.6525 14.1646M14.9131 14.4364C15.3903 14.2192 15.7655 13.8999 16.0384 13.4831C16.3108 13.0669 16.5198 12.6325 16.6648 12.1799C16.8092 11.7273 16.9031 11.3005 16.946 10.8984C16.9883 10.4962 17.01 10.2015 17.01 10.0137C17.01 9.9456 16.9976 9.70961 16.9718 9.30631C16.9466 8.90302 16.8697 8.47331 16.7417 8.01776C16.6137 7.56222 16.4047 7.15247 16.1147 6.78851C15.8247 6.42454 15.4244 6.24784 14.9125 6.25195V14.4353L14.9131 14.4364ZM14.9131 23.3888C15.5271 23.1217 16.0003 22.7295 16.3325 22.2223C16.6654 21.7157 16.9078 21.1974 17.0616 20.6673C17.2319 20.065 17.334 19.4198 17.3687 18.73C17.351 17.9839 17.2495 17.3247 17.0616 16.7541C16.9084 16.2439 16.6654 15.7901 16.3325 15.3933C15.9997 14.9965 15.5265 14.8086 14.9131 14.8204V23.3888Z'
          fill='white'
        />
      </svg>
    </BrassTooltip>
  </div>
);

BrassLogo.defaultProps = {
  width: '30px',
  height: '30px',
};

export type { BrassLogoProps };
export { BrassLogo };
