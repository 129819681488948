import React, {
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import style from './TreeListGrid.v1.module.scss';
import { BrassTreeListGridPropsV1 } from '../TreeListGridProps.interface';
import {
  DeleteColumn,
  EditColumn,
  SelectColumn,
  onItemChange,
  onHeaderSelectionChangeFunction,
  onSelectionChangeFunction,
  getColumns,
  getGridToolbar,
  getHelpPanel,
  getExcelExport,
  getPDFExport,
  onGridExpandChange,
  fnExpandRecords,
  getGridBrassLoader,
  onDataStateChange,
  onSortChange,
  onPageChange,
  onFilterChange,
  getWindowWidth,
  cellRender,
} from './TreeListGrid.v1.functions';
import {
  CompositeFilterDescriptor,
  process,
  State,
} from '@progress/kendo-data-query';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnProps,
} from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  BrassGridConfigDefault,
  BrassSplitter,
  MainLayoutContainerContext,
} from '../../../..';
import { BrassEditCell, BrassDeleteCell } from '../../cells';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { BrassGridColumnProps } from '../../';

const pageData: any = {
  info: true,
  buttonCount: 5,
  type: 'numeric',
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 3000, 5000, 10000],
  previousNext: true,
  group: [],
};

const renderGridV1 = (
  hideActionButtons: boolean = false,
  props: BrassTreeListGridPropsV1,
  selectedRecords: string[],
  setSelectedRecords: Dispatch<SetStateAction<string[]>>,
  _className: string,
  dataState: State,
  setSataState: Dispatch<SetStateAction<State>>,
): ReactNode => {
  const {
    columnsGrid,
    colunsGridExportExcell,
    allowEdit,
    allowDelete,
    sortable,
    reorderable,
    onEditCallback,
    onDeleteCallback,
    extraGridToolbar,
    customFilterGridToolbar,
    gridConfig,
    updateGridConfig,
    gridData,
    selectable,
    updateGridData,
    gridLoading,
    pageable,
    groupable,
    setGridLoading,
    filterable,
    dataItemKey,
    helpUser,
    reduceWindowWidth,
    increaseWindowWidth,
    disableAutoWindowWidth,
  } = props;
  const anchorRef = useRef<any>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const { menuExpanded } = useContext(MainLayoutContainerContext);

  const [windowWidth, setwindowWidth] = useState<string>(
    getWindowWidth(menuExpanded, reduceWindowWidth || 0, increaseWindowWidth || 0),
  );
  useEffect(() => {
    const recalculateWindowWidth = () =>
      setwindowWidth(
        getWindowWidth(
          menuExpanded,
          reduceWindowWidth || 0,
          increaseWindowWidth || 0,
        ),
      );
    setwindowWidth(
      getWindowWidth(menuExpanded, reduceWindowWidth || 0, increaseWindowWidth || 0),
    );
    window.addEventListener('resize', recalculateWindowWidth, true);

    return () => window.removeEventListener('resize', recalculateWindowWidth);
  }, [menuExpanded, window.innerWidth]);

  const [isGrouping, setIsGrouping] = useState<boolean>(
    (gridConfig.group || []).length !== 0,
  );
  const [showHelpPanel, setShowHelpPanel] = useState<boolean>(false);

  let gridPDFExport: GridPDFExport | null;
  const gridExcelExport = useRef<ExcelExport | null>(null);

  // ---------------------------------------------------------------------------------
  const preColunsExportExcell = (colunsGridExportExcell ||
    (columnsGrid as ExcelExportColumnProps[]))!.map(
    (columnProps: ExcelExportColumnProps) => <ExcelExportColumn {...columnProps} />,
  );

  const preColumns = columnsGrid!.map((columnProps: GridColumnProps) => (
    <GridColumn {...columnProps} />
  ));
  EditColumn(allowEdit!, preColumns, false, BrassEditCell, onEditCallback!);
  SelectColumn(preColumns, gridData, selectedRecords, selectable);
  DeleteColumn(
    allowDelete!,
    preColumns,
    BrassDeleteCell,
    onDeleteCallback!,
    gridData,
    updateGridData,
  );

  // Converte todas as colunas para o Objeto GridColumnProps
  const _columns: BrassGridColumnProps[] = getColumns(preColumns, hideActionButtons);

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  const [idCollapsedGroup, setIdCollapsedGroup] = useState<
    { field: string; value: string }[]
  >([]);

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  const filtering = props.denyFilterOnGridProcessing ? undefined : gridConfig.filter;
  const gridDataRecords = process(gridData.records || [], {
    ...gridConfig,
    filter: filtering,
  });
  if (isGrouping && idCollapsedGroup.length) {
    fnExpandRecords(gridDataRecords.data, idCollapsedGroup);
  }

  const groups = dataState.group;
  if (groups) {
    groups.map((group) => (group.aggregates = props.aggregates));
  }

  // ---------------------------------------------------------------------------------
  const [localGridFilter, setLocalGridFilter] = useState<CompositeFilterDescriptor>(
    gridConfig.filter!,
  );
  const debouncedGridFilter: CompositeFilterDescriptor = useDebounce(
    localGridFilter,
    1000,
  );
  useEffect(() => {
    updateGridConfig &&
      updateGridConfig({
        ...gridConfig,
        filter: debouncedGridFilter,
      });
  }, [debouncedGridFilter]);

  // ---------------------------------------------------------------------------------

  return (
    <Fragment>
      {gridLoading && getGridBrassLoader(anchorRef)}
      <BrassSplitter
        panes={[
          {},
          {
            size: '400px',
            resizable: false,
          },
        ]}
      >
        <Grid
          ref={anchorRef}
          style={{ width: disableAutoWindowWidth ? '100%' : windowWidth }}
          dataItemKey={dataItemKey || 'id'}
          expandField='expanded'
          onExpandChange={(event: GridExpandChangeEvent) =>
            isGrouping
              ? onGridExpandChange(event, idCollapsedGroup, setIdCollapsedGroup)
              : undefined
          }
          sortable={sortable!}
          data={gridDataRecords}
          reorderable={reorderable!}
          className={classnames(_className, style['grid-footer'])}
          rowRender={props.rowRender}
          cellRender={(
            tdElement: any,
            cellProps: GridCellProps,
          ):
            | ReactElement<HTMLTableCellElement>
            | ReactElement<HTMLTableCellElement>[]
            | null =>
            cellRender(tdElement, cellProps, columnsGrid as BrassGridColumnProps[])
          }
          onItemChange={(event: GridItemChangeEvent) =>
            onItemChange(event, updateGridData, gridData)
          }
          {...dataState}
          onDataStateChange={(event: GridDataStateChangeEvent) =>
            onDataStateChange(event, gridConfig, setSataState, updateGridConfig)
          }
          pageable={pageable ? pageData : undefined}
          pageSize={gridConfig ? gridConfig.take : BrassGridConfigDefault.take}
          onPageChange={(event: GridPageChangeEvent) =>
            onPageChange(
              event,
              dataState,
              gridConfig,
              setSataState,
              updateGridConfig,
              setGridLoading,
            )
          }
          groupable={{
            enabled: isGrouping,
            footer: groupable?.footer,
          }}
          onGroupChange={(param: any) => {
            if (updateGridConfig && param.group.length <= 2) {
              setSataState({
                ...dataState,
                group: param.group,
              });
              updateGridConfig({
                ...gridConfig,
                group: param.group,
              });
            }
          }}
          sort={gridConfig.sort!}
          onSortChange={(event: GridSortChangeEvent) =>
            onSortChange(event, gridConfig, updateGridConfig)
          }
          filterable={filterable}
          filter={localGridFilter}
          onFilterChange={(event: GridFilterChangeEvent) =>
            onFilterChange(event, gridConfig, setLocalGridFilter, updateGridConfig)
          }
          selectable={selectable}
          selectedField='selected'
          onSelectionChange={(event: GridSelectionChangeEvent) =>
            onSelectionChangeFunction(
              event,
              selectedRecords,
              setSelectedRecords,
              gridData,
              updateGridData,
              props.onSelectionChange!,
            )
          }
          onHeaderSelectionChange={(event: GridHeaderSelectionChangeEvent) =>
            onHeaderSelectionChangeFunction(
              event,
              selectedRecords,
              gridData,
              updateGridData,
              setSelectedRecords,
              props.onHeaderSelectionChange!,
            )
          }
        >
          {getGridToolbar(
            props,
            gridPDFExport!,
            gridExcelExport,
            showHelpPanel,
            setShowHelpPanel,
            showFilterPanel,
            setShowFilterPanel,
            isGrouping,
            setIsGrouping,
          )}
          {showFilterPanel && customFilterGridToolbar && (
            <GridToolbar>{customFilterGridToolbar}</GridToolbar>
          )}

          {extraGridToolbar}
          {!gridLoading && <GridNoRecords />}
          {_columns}
        </Grid>

        {getHelpPanel(showHelpPanel, helpUser)}
      </BrassSplitter>

      <div style={{ overflow: 'hidden', maxHeight: 0, maxWidth: 0 }}>
        {getExcelExport(
          gridData,
          props.gridTitle!,
          preColunsExportExcell || _columns,
          gridExcelExport,
        )}
        {getPDFExport(
          gridData,
          _columns,
          props.gridTitle!,
          props.complementFileNameExport!,

          // @ts-ignore
          (pdfExport: PDFExport) => (gridPDFExport = pdfExport),
        )}
      </div>
    </Fragment>
  );
};

export { renderGridV1 };
