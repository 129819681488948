import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { ConnectModulePermissions } from './Connect.submodule.enum';

enum ConnectSetupSystemFeaturesPermissions {
  MAPACCESSPERMISSION = 0x00000100,
  MANAGEMENTOFACTIONSBYFEATURES = 0x00000200,
  INSTITUCIONALAREAS = 0x00000300,
}

enum ConnectSetupSystemPermissions {
  SETUP_MODULE = PermissionModule.CONNECT + ConnectModulePermissions.SETUP_MODULE,

  /// <summary>
  /// Setup do Sistema - MAPACCESSPERMISSION
  /// </summary>
  SETUP_MODULE_MAPACCESSPERMISSION_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.MAPACCESSPERMISSION +
    Action.READ,
  SETUP_MODULE_MAPACCESSPERMISSION_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.MAPACCESSPERMISSION +
    Action.KEY_USER,

  /// <summary>
  /// Setup do Sistema - MAPACCESSPERMISSION
  /// </summary>
  SETUP_MODULE_MANAGEMENTOFACTIONSBYFEATURES_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.MANAGEMENTOFACTIONSBYFEATURES +
    Action.READ,
  SETUP_MODULE_MANAGEMENTOFACTIONSBYFEATURES_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.MANAGEMENTOFACTIONSBYFEATURES +
    Action.KEY_USER,

  /// <summary>
  /// Setup do Sistema - INSTITUCIONALAREAS
  /// </summary>
  SETUP_MODULE_INSTITUCIONALAREAS_READ = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.INSTITUCIONALAREAS +
    Action.READ,
  SETUP_MODULE_INSTITUCIONALAREAS_KEY_USER = PermissionModule.CONNECT +
    ConnectModulePermissions.SETUP_MODULE +
    ConnectSetupSystemFeaturesPermissions.INSTITUCIONALAREAS +
    Action.KEY_USER,
}

export { ConnectSetupSystemPermissions };
