import { I18nService, II18n } from '../../../';

const translations: II18n = {
  es: {
    editArea: 'Editar area{0}',
    includeArea: 'Incusão de area{0}',

    successOnUpdate: 'Area actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar el area',

    successOnInclude: 'Area agregar correctamente',
    errorOnInclude: 'Error al intentar agregar el area',
  },
  enUS: {
    editArea: 'Edit area{0}',
    includeArea: 'Include area{0}',

    successOnUpdate: 'Area successfully updated',
    errorOnUpdate: 'Error while trying to update the area',

    successOnInclude: 'Area successfully add',
    errorOnInclude: 'Error while trying to add the area',
  },
  ptBR: {
    editArea: 'Edição de área{0}',
    includeArea: 'Inclusão de área{0}',

    successOnUpdate: 'Área atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar a área',

    successOnInclude: 'Área adicionada com sucesso',
    errorOnInclude: 'Erro ao tentar adicionar a área',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
