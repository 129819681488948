import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    title: 'Agregar Documento',
    hint: 'Arrastre y suelte archivos aquí para cargarlos.',
    note: 'Solo se permiten archivos {0}.',
    informationTitle: 'Subiendo archivos',
    informationDescription: 'Espere mientras la carga está en curso. ',
    uploadSuccess: 'añadido con éxito',
    uploadError: 'Error al cargar el archivo',
    file: 'Archivo',
    NAME_CONTENTS_EXISTS_IN_AREA:
      'Error al guardar contenido, ya existe contenido con ese nombre',
  },
  enUS: {
    title: 'Add Document',
    hint: 'Drag and drop files here to upload.',
    note: 'Only {0} files are allowed.',
    informationTitle: 'Uploading Files',
    informationDescription: 'Please wait while the upload is in progress.',
    uploadSuccess: 'added successfully',
    uploadError: 'Error loading file',
    file: 'File',
    NAME_CONTENTS_EXISTS_IN_AREA:
      'Error saving content, content with that name already exists',
  },
  ptBR: {
    title: 'Upload de Documento',
    hint: 'Arraste e solte arquivos aqui para fazer o upload.',
    note: 'Apenas arquivos {0} são permitidos.',
    informationTitle: 'Realizando Upload de Arquivos',
    informationDescription:
      'Por favor, aguarde enquanto o upload estiver em andamento.',
    uploadSuccess: 'adicionado com sucesso',
    uploadError: 'Erro ao carregar o arquivo',
    file: 'Arquivo',
    NAME_CONTENTS_EXISTS_IN_AREA:
      'Erro ao salvar conteúdo, já existe um conteúdo com esse nome',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
