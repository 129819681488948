import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import {
  DropDownList,
  DropDownListFilterChangeEvent,
  DropDownListProps,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import classnames from 'classnames';
import {
  cloneElement,
  FunctionComponent,
  ReactElement,
  useState,
} from 'react';
import { BrassButton } from '../../buttons';
import style from './DropDownList.module.scss';

interface BrassDropDownListProps extends DropDownListProps {
  onLeftButtonClick?: () => void;
  leftButtonIcon?: any;
}

const BrassDropDownList: FunctionComponent<BrassDropDownListProps> = (
  props: BrassDropDownListProps,
) => {
  const { className, popupSettings } = props;
  const _className = classnames('brass-dropdown-list', className);

  const [data, setData] = useState(props.data!);

  const filterData = (filter: FilterDescriptor): any[] => {
    const _data = props.data!.slice();
    return filterBy(_data, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent): void =>
    setData(filterData(event.filter));

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <span className={itemProps.dataItem.disabled ? style['disabled'] : ''}>
        {li.props.children}
      </span>
    );

    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <div style={{ display: 'flex' }}>
      {props.leftButtonIcon && props.onLeftButtonClick && (
        <BrassButton
          fillMode='outline'
          themeColor='base'
          onClick={props.onLeftButtonClick}
          style={{ float: 'left' }}
          type='button'
        >
          {props.leftButtonIcon}
        </BrassButton>
      )}
      <DropDownList
        {...props}
        data={data}
        className={_className}
        onFilterChange={filterChange}
        itemRender={itemRender}
        popupSettings={{
          className: classnames(
            style['brass-dropdown-popup'],
            popupSettings && popupSettings.className,
          ),
        }}
      />
    </div>
  );
};

BrassDropDownList.defaultProps = {
  filterable: true,
};

export { BrassDropDownList };
export type { BrassDropDownListProps };

