import React, {
  Fragment,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import style from './NotificationBoard.module.scss';
import classnames from 'classnames';
import {
  BrassIconClock,
  BrassIconMoneyBill,
  BrassIconProps,
  BrassIconTasks,
  BrassLoader,
  MainLayoutContainerContext,
} from '../../../../components';
import { NotificationCard } from './notification-card/NotificationCard';
import {
  NotificationCardItem,
  NotificationStatus,
  UserAlert,
  UserNotification,
  UserNotificationGroup,
} from '../../models';
import { translate } from './NotificationBoard.translations';
import { ApiResult } from '../../../../models';
import { NotificationList } from './notification-list/NotificationList';
import { NotificationService } from '../../services';
import { AlertDialog } from './alert-dialog/AlertDialog';

interface NotificationBoardProps extends PropsWithChildren<any> {}

const NotificationBoard: FunctionComponent<NotificationBoardProps> = memo(() => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notificationAlerts, setNotificationAlerts] = useState<
    UserAlert[] | undefined
  >();
  const [notificationList, setNotificationList] = useState<UserNotification[]>([]);
  const [filteredNotificationList, setFilteredNotificationList] = useState<
    UserNotification[]
  >([]);
  const [notificationGroup, setNotificationGroup] = useState<UserNotificationGroup>(
    UserNotificationGroup.ALL,
  );

  const { handleCountNotifications } = useContext(MainLayoutContainerContext);

  const notificationCards: NotificationCardItem[] = [
    {
      icon: (_props: BrassIconProps) => <BrassIconClock {..._props} />,
      data: notificationList.filter(
        (notification: UserNotification) =>
          notification.group === UserNotificationGroup.WORK_TIME_RECORD,
      ).length,
      description: translate('workTimeRecord'),
      group: UserNotificationGroup.WORK_TIME_RECORD,
    },
    {
      icon: (_props: BrassIconProps) => <BrassIconMoneyBill {..._props} />,
      data: notificationList.filter(
        (notification: UserNotification) =>
          notification.group === UserNotificationGroup.FINANCE,
      ).length,
      description: translate('finance'),
      group: UserNotificationGroup.FINANCE,
    },
    {
      icon: (_props: BrassIconProps) => <BrassIconTasks {..._props} />,
      data: notificationList.filter(
        (notification: UserNotification) =>
          notification.group === UserNotificationGroup.GENERAL,
      ).length,
      description: translate('system'),
      group: UserNotificationGroup.GENERAL,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    NotificationService.getNotificationList().then(
      (apiResult: ApiResult<UserNotification[]>) => {
        if (apiResult.success) {
          setNotificationList(apiResult.data);
          setFilteredNotificationList(apiResult.data);
        }
        setIsLoading(false);
        handleCountNotifications && handleCountNotifications();
      },
    );
  }, []);

  useEffect(() => {
    if (notificationGroup !== UserNotificationGroup.ALL) {
      setFilteredNotificationList(
        notificationList.filter(
          (n: UserNotification) => n.group === notificationGroup,
        ),
      );
    } else {
      setFilteredNotificationList(notificationList);
    }
  }, [notificationGroup]);

  if (isLoading) {
    return <BrassLoader useLoadingMask />;
  }

  return (
    <Fragment>
      <div className={style.container}>
        {notificationCards.map((notificationCard, index) => (
          <div
            key={index}
            className={classnames(style.card)}
            onClick={(): void => {
              if (notificationGroup === notificationCard.group) {
                setNotificationGroup(UserNotificationGroup.ALL);
              } else {
                setNotificationGroup(
                  notificationCard.group as UserNotificationGroup,
                );
              }
            }}
          >
            <NotificationCard
              className={
                notificationGroup === notificationCard.group
                  ? style['card-on']
                  : style['card-off']
              }
              Icon={notificationCard.icon}
              data={notificationCard.data}
              description={notificationCard.description}
            />
          </div>
        ))}
      </div>
      <NotificationList
        data={filteredNotificationList}
        onAlertClick={(alerts: UserAlert[]): void => setNotificationAlerts(alerts)}
      />
      {notificationAlerts && (
        <div className={style.dialog}>
          <AlertDialog
            alerts={notificationAlerts}
            onClose={(): void => setNotificationAlerts(undefined)}
          />
        </div>
      )}
    </Fragment>
  );
});

export type { NotificationBoardProps };
export { NotificationBoard };
