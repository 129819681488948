import { Dispatch } from 'redux';
import { Dispatch as DispatchR } from 'react';
import { Notification, NotificationStyles } from '../models/notification';
import { NotificationPositionType } from '../models/notification/notificationPosition.enum';
import { notificationActions } from '../store/notification';

export type TranslateProps = (key: string, args?: string[]) => string;

type DynamicDispatchAlertProps = (
  dispatch: Dispatch<any> | DispatchR<any>,
  messages: string[],
  notificationType: keyof typeof NotificationStyles,
  translate: TranslateProps,
  position?: NotificationPositionType,
) => void;

const DynamicDispatchAlert: DynamicDispatchAlertProps = (
  dispatch,
  messages,
  notificationType,
  translate,
  position = 'bottomCenter',
): void => {
  messages?.forEach((message) => {
    dispatch(
      notificationActions.showNotification(
        new Notification({
          style: NotificationStyles[notificationType],
          position,
          content: translate(message),
        }),
      ),
    );
  });
};

export { DynamicDispatchAlert };
