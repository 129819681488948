import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  BrassUploaderFieldProps,
  BrassUploaderField,
  BrassLoader,
} from '../../../../../../brass';
import { BrassButtonSave, BrassButtonSaveV1 } from '../../../../../../shared';
import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload';
import styles from './UploadImage.module.scss';
import { translate } from './UploadImage.translations';
import classNames from 'classnames';
import { useContext } from 'react';
import { UploadImageContext } from '../../UploadImageContext';
import { ImageService } from '../../../../../../../services/image.service';
import { UploadService } from '../../../../../../../services';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../../../../../store';
import {
  BrassUploadFileInfo,
  Notification,
  NotificationStyles,
} from '../../../../../../../models';

interface IconFieldProps extends BrassUploaderFieldProps {
  icon?: string;
}

const UploadImage: FunctionComponent<IconFieldProps> = (props: IconFieldProps) => {
  const { onChange } = props;
  const [icon, setIcon] = useState<string>(props.icon || '');
  const [filesToUpload, setFilesToUpload] = useState<BrassUploadFileInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { base64File, setBase64File, setIsActive, setSaveUrl } =
    useContext(UploadImageContext);
  const [saveUrl, setSaveUrlAWS] = useState<string>(UploadService.saveUrl);
  const uploadRef = React.createRef<Upload>();
  const [, setForceUpdate] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onAdd = async (event: UploadOnAddEvent): Promise<void> => {
    const imageBase64 = await ImageService.imageToBase64(
      // @ts-ignore
      event.affectedFiles[0].getRawFile(),
    );

    setIcon(imageBase64);
    setBase64File(imageBase64);
    onChange(imageBase64);

    onChange(
      event.target.state.files[0]?.uid + event.target.state.files[0]?.extension,
    );
    setSaveUrlAWS(
      UploadService.saveUrl +
        event.target.state.files[0]?.uid +
        event.target.state.files[0]?.extension,
    );
    setSaveUrl(
      event.target.state.files[0]?.uid + event.target.state.files[0]?.extension,
    );

    UploadService.onAddFile(event);
    setForceUpdate((previousValue) => !previousValue);
  };

  const handleSave = (): void => {
    setIsLoading(true);
    UploadService.uploadFilesToS3().then(() => {
      dispatch(
        notificationActions.showNotification(
          new Notification({
            style: NotificationStyles.SUCCESS,
            content: translate('uploadSuccessfully'),
            timeout: 5000,
          }),
        ),
      );
      setIsActive(false);
      setIsLoading(true);
    });
  };

  useEffect(() => {
    var uploadFiles = true;
    if (uploadFiles) {
      UploadService.setFilesToUpload = setFilesToUpload;
    }
    return () => {
      uploadFiles = false;
    };
  }, []);

  UploadService.uploadCallback = (fileUploadInfo: BrassUploadFileInfo) => {
    var upload = fileUploadInfo;
    return new Promise((resolve) => resolve(upload as any));
  };

  return (
    <>
      <div className={styles['container-image-dotted']}>
        <ExternalDropZone
          uploadRef={uploadRef}
          customHint={' '}
          customNote={translate('hint')}
          className={classNames(
            styles['external-drop-zone'],
            styles[icon ? 'is-image' : ''],
          )}
          style={{ backgroundImage: `url(${icon ?? base64File})` }}
        />
      </div>

      <div className={styles['container-select-save']}>
        <Upload
          className={styles['upload-button']}
          ref={uploadRef}
          accept={'.png, .jpg, .jpeg'}
          autoUpload={false}
          defaultFiles={filesToUpload}
          showActionButtons={false}
          showFileList={false}
          multiple={false}
          restrictions={{
            allowedExtensions: ['.jpg', '.jpeg', '.png'],
            maxFileSize: 50000000,
          }}
          onBeforeUpload={(event: UploadOnBeforeUploadEvent): void => {
            setSaveUrlAWS(
              UploadService.saveUrl + event.files[0]?.uid + event.files[0].extension,
            );
            setSaveUrl(event.files[0]?.uid + event.files[0].extension);
            setForceUpdate((previousValue) => !previousValue);
          }}
          onStatusChange={(event: UploadOnStatusChangeEvent): void => {
            if (event.response && event.response.status === 200) {
              setIsLoading(true);
              UploadService.uploadFilesToS3()
                .then(() => {
                  dispatch(
                    notificationActions.showNotification(
                      new Notification({
                        style: NotificationStyles.SUCCESS,
                        content: translate('uploadSuccessfully'),
                        timeout: 5000,
                      }),
                    ),
                  );
                  setIsLoading(false);
                })
                .catch(() => {
                  dispatch(
                    notificationActions.showNotification(
                      new Notification({
                        style: NotificationStyles.ERROR,
                        content: translate('errorOnUpload'),
                        timeout: 5000,
                      }),
                    ),
                  );
                  setIsLoading(false);
                });
            } else if (event.response && event.response.status !== 200) {
              dispatch(
                notificationActions.showNotification(
                  new Notification({
                    style: NotificationStyles.ERROR,
                    content: translate('errorOnUpload'),
                    timeout: 5000,
                  }),
                ),
              );
            }
          }}
          onAdd={onAdd}
          saveUrl={saveUrl}
          saveMethod='PUT'
        />
        <BrassButtonSaveV1
          onClick={handleSave}
          type='button'
          value='Anexar'
          disabled={base64File === null}
        />
      </div>
      {isLoading && <BrassLoader useLoadingMask />}
    </>
  );
};

export { UploadImage };
