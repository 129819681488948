import React, { FunctionComponent } from 'react';
import { BrassButton, BrassDialog, BrassDialogActionsBar } from '../../components';

const DialogPlayground: FunctionComponent = () => (
  <BrassDialog title='Please confirm'>
    <p style={{}}>Are you sure you want to continue?</p>
    <BrassDialogActionsBar>
      <BrassButton>Brass</BrassButton>
    </BrassDialogActionsBar>
  </BrassDialog>
);

export { DialogPlayground };
