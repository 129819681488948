import React, { FunctionComponent } from 'react';
import style from './ComboBox.module.scss';
import classnames from 'classnames';
import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns';
import { DropDownData } from '../../../../models';

interface BrassComboBoxProps extends ComboBoxProps {
  data?: DropDownData[] | any[];
  textField?: string;
  dataItemKey?: string;
}

const BrassComboBox: FunctionComponent<BrassComboBoxProps> = (
  props: BrassComboBoxProps,
) => {
  const { className } = props;
  const _className = classnames('brass-combobox', className);

  return (
    <ComboBox
      {...props}
      className={_className}
      data={props.data!.filter((d) => d.text && d.value)}
      textField={props.textField}
      dataItemKey={props.dataItemKey}
      popupSettings={{
        className: style['brass-combobox-popup'],
      }}
    />
  );
};

BrassComboBox.defaultProps = {
  textField: 'text',
  dataItemKey: 'value',
};

export { BrassComboBox };
export type { BrassComboBoxProps };
