import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import style from './SearchInput.module.scss';
import { translate } from './SearchInput.translations';
import { BrassInput, BrassInputProps } from '../../../brass';
import { I18nService } from '../../../../services';
import { BrassIcon } from '../../icon/Icon';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface BrassSearchInput extends BrassInputProps {
  classNameIcon?: string;
  classNameContainer?: string;
}
const BrassSearchInput: FunctionComponent<BrassSearchInput> = (
  props: BrassSearchInput,
) => {
  const { classNameContainer, classNameIcon } = props;
  const _classNameContainer = classnames(
    style['search-input-container'],
    classNameContainer,
  );

  const _props = { ...props };
  delete _props.classNameIcon;
  delete _props.classNameContainer;

  return (
    <div className={_classNameContainer}>
      <BrassInput
        autoFocus
        className={style['search-input']}
        placeholder={translate('search')}
        {..._props}
      />
      <BrassIcon
        className={classnames(style['search-icon'], classNameIcon)}
        icon={faSearch}
      />
    </div>
  );
};

export { BrassSearchInput };
