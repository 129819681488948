import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    confirmationDelete:
      'Al cerrar todo el progreso incompleto se perderá, ¿quieres continuar?',
    downloadDocument: 'Descargar Documento',
    size: 'Tamaño',
  },
  enUS: {
    confirmationDelete:
      'When closing all uncompleted progress will be lost, do you want to continue?',
    downloadDocument: 'Download Document',
    size: 'Size',
  },
  ptBR: {
    confirmationDelete:
      'Ao fechar todo progresso não concluído sera perdido, deseja continuar?',
    downloadDocument: 'Baixar documento',
    size: 'Tamanho',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate, translations };
