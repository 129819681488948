import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Pager, PagerProps } from '@progress/kendo-react-data-tools';

interface BrassPagerProps extends PagerProps {}

const BrassPager: FunctionComponent<BrassPagerProps> = (props: BrassPagerProps) => {
  const { className } = props;
  const _className = classnames('brass-pager', className);

  return <Pager {...props} className={_className} />;
};

export { BrassPager };
export type { BrassPagerProps };
