import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { NumericTextBox, NumericTextBoxProps } from '@progress/kendo-react-inputs';

interface BrassNumericTextBoxProps extends NumericTextBoxProps {}

const BrassNumericTextBox: FunctionComponent<BrassNumericTextBoxProps> = (
  props: BrassNumericTextBoxProps,
) => {
  const { className } = props;
  const _className = classnames('brass-numeric-textbox', className);
  return <NumericTextBox {...props} className={_className} />;
};

export { BrassNumericTextBox };
export type { BrassNumericTextBoxProps };
