import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconLinked: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.37496 14.1667H5.83329C4.68051 14.1667 3.69788 13.7604 2.88538 12.9479C2.07288 12.1354 1.66663 11.1528 1.66663 10C1.66663 8.84722 2.07288 7.86458 2.88538 7.05208C3.69788 6.23958 4.68051 5.83333 5.83329 5.83333H9.37496V7.08333H5.83329C5.02774 7.08333 4.34024 7.36805 3.77079 7.9375C3.20135 8.50694 2.91663 9.19444 2.91663 10C2.91663 10.8056 3.20135 11.4931 3.77079 12.0625C4.34024 12.6319 5.02774 12.9167 5.83329 12.9167H9.37496V14.1667ZM6.77079 10.625V9.375H13.2291V10.625H6.77079ZM10.625 14.1667V12.9167H14.1666C14.9722 12.9167 15.6597 12.6319 16.2291 12.0625C16.7986 11.4931 17.0833 10.8056 17.0833 10C17.0833 9.19444 16.7986 8.50694 16.2291 7.9375C15.6597 7.36805 14.9722 7.08333 14.1666 7.08333H10.625V5.83333H14.1666C15.3194 5.83333 16.302 6.23958 17.1145 7.05208C17.927 7.86458 18.3333 8.84722 18.3333 10C18.3333 11.1528 17.927 12.1354 17.1145 12.9479C16.302 13.7604 15.3194 14.1667 14.1666 14.1667H10.625Z'
      fill={props.fill}
    />
  </svg>
);

export { SGVIconLinked };
