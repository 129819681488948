import React, { FunctionComponent } from 'react';
import style from './Badge.module.scss';
import classnames from 'classnames';
import { Badge, BadgeProps } from '@progress/kendo-react-indicators';

interface BrassBadgeProps extends BadgeProps {}

const BrassBadge: FunctionComponent<BrassBadgeProps> = (props: BrassBadgeProps) => {
  const { className } = props;
  const _className = classnames('brass-badge', style['brass-badge'], className);

  return <Badge {...props} className={_className} />;
};

export { BrassBadge };
export type { BrassBadgeProps };
