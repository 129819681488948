import React, {
  Fragment,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useState,
} from 'react';
import style from './CommunicationList.module.scss';
import { translate } from './CommunicationList.translations';
import {
  BrassBadgeNew,
  BrassDivFlex,
  BrassListView,
  BrassListViewFooter,
  BrassListViewHeader,
} from '../../../../../components';
import {
  UserCommunications,
  UserNotificationGroup,
  UserNotificationEventType,
  NotificationStatus,
} from '../../../models';
import { emptyGuid } from '../../../../../util';
import { ListViewItemProps } from '@progress/kendo-react-listview';
import { CommunicationListSideView } from './communication-list-side-view/CommunicationListSideView';

interface CommunicationListProps extends PropsWithChildren<any> {
  data: UserCommunications[];
  routes: any;
}

const CommunicationList: FunctionComponent<CommunicationListProps> = memo(
  (props: CommunicationListProps) => {
    const [communication, setCommunication] = useState<UserCommunications>({
      id: emptyGuid(),
      title: '',
      alerts: [],
      message: '',
      viewed: false,
      lastAlertDate: new Date(),
      status: NotificationStatus.READED,
      group: UserNotificationGroup.FINANCE,
      type: UserNotificationEventType.ALL,
    });
    const { data } = props;

    const ItemRender = (props: ListViewItemProps) => {
      const item: UserCommunications = props.dataItem;

      const selectedRowColor = '#c2dbff';
      const defaultBackgroundColor =
        item.status !== NotificationStatus.CREATED ? 'unset' : '#f5f7f7';

      const communicationTitle = () => {
        let defaultTitle = (
          <div style={{ color: '#202124', paddingBlock: '4px', marginTop: '6px' }}>
            {item.title}
          </div>
        );

        if (item.status === NotificationStatus.CREATED) {
          defaultTitle = (
            <h2 className={style.itemTitle}>
              <BrassBadgeNew
                align={{ horizontal: 'end', vertical: 'top' }}
                shape='dot'
              >
                <div style={{ marginRight: 10, fontWeight: 'bolder' }}>
                  {translate(item.type)}
                </div>
              </BrassBadgeNew>
            </h2>
          );
        }

        return defaultTitle;
      };

      return (
        <div
          className={style.list}
          style={{
            margin: 0,
            backgroundColor:
              communication.id === props.dataItem.id
                ? selectedRowColor
                : defaultBackgroundColor,
          }}
          onClick={() => {
            if (communication.id === props.dataItem.id) {
              setCommunication(new UserCommunications({}));
            } else {
              setCommunication(props.dataItem);
            }
          }}
        >
          <div>
            {communicationTitle()}
            <div className={style.itemMessage}>{item.message}</div>
          </div>
          <BrassDivFlex />
          <div>
            <div className={style.itemMessage}>
              {new Date(item.lastAlertDate).toLocaleString()}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={style.div}>
        <BrassListView
          inlineItems={false}
          data={data}
          item={ItemRender}
          className={style.listView}
          header={() => (
            <BrassListViewHeader className={style.margin}>
              {translate('communications')}
            </BrassListViewHeader>
          )}
          footer={() => (
            <BrassListViewFooter className={style.margin}>
              {data.length} {translate('messagesInTotal')}
            </BrassListViewFooter>
          )}
        />
        <CommunicationListSideView data={communication} routes={props.routes} />
      </div>
    );
  },
);

export type { CommunicationListProps };
export { CommunicationList };
