import { emptyGuid } from '../../util';

class GridItem {
  public id: string = emptyGuid();
  public hideEdit: boolean = false;
  public hideDelete: boolean = false;
  public inEdit: string | undefined = undefined;
  public selected: string | undefined = undefined;

  constructor(gridItem: Partial<GridItem>) {
    Object.assign(this, gridItem);
  }
}
export { GridItem };
