import React, { Fragment, FunctionComponent, memo } from 'react';
import classnames from 'classnames';
import style from './BreadCrumbV1.module.scss';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BrassBar } from '../../brass';
import { BrassIcon } from '../../shared';
import { BreadcrumbV1 } from '../../../models/breadcrumb';

interface BrassBreadcrumb {
  round?: boolean;
  onClick?: (id?: string, item?: any) => void;
  items?: BreadcrumbV1[];
  color?: string;
  backgroundLigth?: string;
  background?: string;
  fontWeight?: string;
  height?: string;
  width?: string;
  className?: string;
  clickable?: boolean;
}
const BrassBreadcrumbV1: FunctionComponent<BrassBreadcrumb> = memo(
  (props: BrassBreadcrumb) => {
    const {
      onClick,
      items,
      color,
      backgroundLigth,
      background,
      fontWeight,
      height,
      width,
      className,
      clickable,
    } = props;
    const _className = classnames('breadcrumb', className);

    return (
      <BrassBar
        className={classnames(
          _className,
          style['breadcrumb'],
          props.round && style['breadcrumb-round'],
        )}
        style={{
          background: !!backgroundLigth ? backgroundLigth + '40' : background,
          padding: 0,
          color,
          fontWeight: fontWeight ? fontWeight : 'inherit',
          height: height!,
          width: width!,
        }}
      >
        {items?.map((node: BreadcrumbV1, index: number) => {
          const isFirst = index === 0;
          const isLast = index === items.length - 1;
          return (
            <Fragment key={node?.id}>
              <div
                role={'breadList'}
                style={{
                  fontWeight: fontWeight ? fontWeight : 'inherit',
                }}
                className={classnames(
                  isFirst && style['breadcrumb-firstV1'],
                  !isLast && style['breadcrumb-linkV1'],
                )}
                onClick={() => {
                  if (clickable) {
                    onClick && onClick(node?.id, node);
                  } else {
                    if ((isFirst && !clickable && items.length === 1) || isLast) {
                      return;
                    } else {
                      onClick && onClick(node?.id, node);
                    }
                  }
                }}
                onKeyDown={() => null}
              >
                <div className={classnames(clickable && style['breadcrumb-linkV1'])}>
                  {node?.name}
                </div>
              </div>
              {items.length !== index + 1 && (
                <BrassIcon style={{ color: color + '99' }} icon={faChevronRight} />
              )}
            </Fragment>
          );
        })}
      </BrassBar>
    );
  },
);

BrassBreadcrumbV1.defaultProps = {
  round: false,
};

export { BrassBreadcrumbV1 };
