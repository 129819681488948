import React, { useEffect, useRef } from 'react';
import style from './ProgressBarCircular.module.scss';

interface IBrassProgressCircularBarProps {
  progressValue?: number;
  styleProgressBar?: React.CSSProperties;
  styleValueText?: React.CSSProperties;
  colors?: {
    textColor?: string;
    progressColor?: string;
    lineProgressColor?: string;
    backgroundColor?: string;
  };
}

const BrassProgressCircularBar = (_props: IBrassProgressCircularBarProps) => {
  const { progressValue, styleProgressBar, styleValueText, colors } = _props;
  const circularProgressBarRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  useEffect(() => {
    circularProgressBarRef.current.style.background = `conic-gradient(
        ${colors?.progressColor ?? 'black'} ${(progressValue ?? 0) * 3.6}deg,
        ${colors?.lineProgressColor ?? 'transparent'} ${
      (progressValue ?? 0) * 3.6
    }deg
        )`;
  }, [circularProgressBarRef, progressValue, colors]);

  return (
    <>
      <div
        className={style['circular-progress-bar']}
        style={styleProgressBar}
        ref={circularProgressBarRef}
      >
        <div
          className={style['value-progress-bar']}
          style={{
            background: colors?.backgroundColor,
            color: colors?.textColor,
            ...styleValueText,
          }}
        >
          {progressValue}%
        </div>
      </div>
    </>
  );
};

export { BrassProgressCircularBar };
export type { IBrassProgressCircularBarProps };
