import React, { FunctionComponent } from 'react';
import style from './AlertDialog.module.scss';
import { translate } from './AlertDialog.translations';
import { BrassDialog } from '../../../../../components';
import { AlertList } from './alert-list/AlertList';
import { UserAlert } from '../../../models';

interface AlertDialogProps {
  alerts?: UserAlert[];
  onClose: () => void;
}

const AlertDialog: FunctionComponent<AlertDialogProps> = (
  props: AlertDialogProps,
) => {
  const { alerts, onClose } = props;

  return (
    <BrassDialog
      alignRight
      className={style.dialog}
      showDarkBackground={false}
      title={translate('alerts')}
      onClose={onClose}
      width='500px'
      height='100vh'
    >
      <AlertList alerts={alerts!} />
    </BrassDialog>
  );
};

export { AlertDialog };
