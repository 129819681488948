import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconClipBoardCost: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M6.43634 16.5402H4.2345V5.3865H5.06542V6.67647H12.7143V5.3865H13.5449V8.35633C13.9554 8.46219 14.3486 8.6088 14.7205 8.7937V4.79836C14.7205 4.47363 14.4575 4.21028 14.1327 4.21028H12.7143V3.7177C12.7143 3.344 12.4113 3.04106 12.0376 3.04106H11.1094C11.0781 1.83936 10.0991 0.873398 8.88977 0.873398C7.68052 0.873398 6.7016 1.83935 6.6701 3.04106H5.74192C5.36822 3.04106 5.06529 3.344 5.06529 3.7177V4.21028L3.6466 4.21035C3.32187 4.21035 3.05884 4.4737 3.05884 4.79843V17.1283C3.05884 17.453 3.32185 17.7163 3.6466 17.7163H7.09177C6.83259 17.3513 6.61039 16.9588 6.43624 16.5402L6.43634 16.5402ZM8.88984 2.33933C9.30937 2.33933 9.6494 2.67936 9.6494 3.0989C9.6494 3.51843 9.30937 3.85846 8.88984 3.85846C8.47031 3.85846 8.13021 3.51843 8.13021 3.0989C8.13021 2.67936 8.47031 2.33933 8.88984 2.33933Z' />
    <path d='M12.0307 9.3055C9.31868 9.3055 7.12018 11.504 7.12018 14.216C7.12018 16.928 9.31868 19.1265 12.0307 19.1265C14.7427 19.1265 16.9412 16.928 16.9412 14.216C16.9412 11.504 14.7427 9.3055 12.0307 9.3055V9.3055ZM12.3867 16.6313V17.4468H11.6108V16.6866C11.0803 16.6708 10.5657 16.5201 10.2649 16.3536L10.5025 15.4276C10.8348 15.6093 11.3022 15.7757 11.8168 15.7757C12.2683 15.7757 12.5848 15.5934 12.5848 15.2848C12.5848 14.984 12.3236 14.7938 11.7378 14.5957C10.8902 14.3108 10.3123 13.9153 10.3123 13.1472C10.3123 12.4423 10.8033 11.8961 11.6503 11.7375V10.9853H12.4262V11.6822C12.9566 11.6981 13.3133 11.8165 13.5826 11.9435L13.3449 12.8386C13.1468 12.7431 12.775 12.5608 12.2044 12.5608C11.6897 12.5608 11.5239 12.7904 11.5239 13.0123C11.5239 13.2656 11.801 13.4401 12.4736 13.6855C13.4239 14.0178 13.7964 14.4535 13.7964 15.1742C13.7964 15.8791 13.3055 16.4727 12.3867 16.6313L12.3867 16.6313Z' />
  </svg>
);

export { SVGIconClipBoardCost };
