import React, { FunctionComponent } from 'react';
import { BrassSplitter } from '../../components';

const SplitterPlayground: FunctionComponent = () => {
  const panes = [{}, { size: '30%', min: '20px', collapsible: true }];
  return (
    <BrassSplitter panes={panes} style={{ width: '100vw', height: '100vh' }}>
      <div className='pane-content'>Painel A</div>
      <div className='pane-content'>Painel B</div>
    </BrassSplitter>
  );
};

export { SplitterPlayground };
