
import { BrassGridConfigProps } from '../components';
import { ApiResult, Endpoints } from '../models';
import { ApiService } from './api.service';

class RelationalFlagsService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async getAllRelationalGroup(
    mapContextRelationalId: string,
    language: string,
  ): Promise<ApiResult> {
    return await RelationalFlagsService.api.get(
      `/connect/v1/group-subgroup/map-context-relational/${mapContextRelationalId}/list-Groups?language=${language}`,
    );
  }

  public static async getRelationalFlagsGrid(
    gridConfig: BrassGridConfigProps,
    getRecordsParams: {
      groupRelationalId: string;
      language: string;
    },
  ): Promise<ApiResult> {
    return await RelationalFlagsService.api.post(
      `connect/v1/flag-relational/group-subgroup/${getRecordsParams.groupRelationalId}/list-All-paginated?language=${getRecordsParams?.language}`,
      gridConfig as any,
    );
  }

  public static async getFlagId(
    flagId: string,
    language: string,
  ): Promise<ApiResult> {
    return await RelationalFlagsService.api.get(
      `/connect/v1/flag-relational/${flagId}/?language=${language}`,
    );
  }
}

export { RelationalFlagsService };

