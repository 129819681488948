import React, { FunctionComponent } from 'react';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon, BrassIconProps } from '..';

const BrassIconNavigatorV1: FunctionComponent<BrassIconProps> = (props: any) => (
  <svg
    viewBox='0 0 971 972'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M927.429 1.26481C919.754 3.01881 20.6646 325.573 16.7226 327.986C11.4846 331.193 4.68957 339.537 2.34657 345.638C-0.933427 354.184 -0.763423 364.556 2.78458 372.306C6.34058 380.073 9.48657 384.096 14.9406 387.848C18.0406 389.981 81.8636 413.384 225.161 464.933C338.333 505.646 431.238 539.267 431.615 539.648C431.993 540.029 465.604 632.921 506.308 746.076C556.68 886.109 581.268 953.199 583.301 956.154C586.937 961.439 591.643 965.133 599.073 968.535C606.567 971.965 617.306 972.114 625.676 968.903C632.604 966.245 641.101 958.791 644.139 952.703C647.655 945.659 968.606 49.9648 970.112 42.9938C974.238 23.8908 961.568 5.19481 941.63 0.963808C935.316 -0.375192 934.532 -0.359189 927.429 1.26481ZM623.389 297.379L450.85 469.918L297.153 414.629C212.62 384.22 143.45 359.098 143.443 358.803C143.435 358.508 182.691 344.21 230.679 327.029C278.667 309.849 425.029 257.36 555.929 210.386C686.829 163.413 794.379 124.949 794.929 124.91C795.478 124.872 718.286 202.483 623.389 297.379ZM729.714 502.185C665.507 681.295 612.74 827.834 612.452 827.827C612.164 827.82 587.037 758.662 556.614 674.143L501.299 520.473L673.572 348.197C768.322 253.446 845.981 176.059 846.148 176.226C846.316 176.393 793.92 323.075 729.714 502.185Z'
      fill='black'
    />
  </svg>
);
export { BrassIconNavigatorV1 };
