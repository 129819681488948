import { faHourglassEnd, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';

const BrassButtonActivateV1: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-activate'
    labelKey='activate'
    tooltipKey='clickToActivate'
    translations={translations}
    onClick={props.onClick}
    icon={<BrassIcon icon={faHourglassEnd} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    activate: 'Activar',
    clickToActivate: 'Haga clic para activar',
  },
  enUS: {
    activate: 'Activate',
    clickToActivate: 'Click to activate',
  },
  ptBR: {
    activate: 'Ativar',
    clickToActivate: 'Clique para ativar',
  },
};

export { BrassButtonActivateV1 };
