import { emptyGuid } from '../../util';
import { Message, User } from '@progress/kendo-react-conversational-ui';

class ChatMessage implements Message {
  public author: User = { id: emptyGuid() };
  public text?: string = '';
  public timestamp?: Date = new Date();

  constructor(chatMessage: Partial<ChatMessage>) {
    Object.assign(this, chatMessage);
  }
}

export { ChatMessage };
