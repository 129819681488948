import React, { FunctionComponent, memo, useEffect, useState } from 'react';
// import style from './MultiSelectField.module.scss';
import { Label } from '@progress/kendo-react-labels';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import {
  MultiSelectChangeEvent,
  DropDownsPopupSettings,
} from '@progress/kendo-react-dropdowns';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassLoader } from '../../../indicators';
import { BrassError } from '../../../labels';
import { BrassMultiSelect, BrassMultiSelectProps } from '../../../dropdown';
import { requiredValidator } from '../validators/required';
import { DropDownData } from '../../../../../models';
import { LocalStorageService } from '../../../../../services';
import { BrassInput } from '../../../inputs';

interface MultiSelectInputProps extends BrassMultiSelectProps {
  value?: any[];
  visited?: boolean;
  disabled?: boolean;
  label?: string;
  options: DropDownData<any>[];
  popupSettings?: DropDownsPopupSettings;
  useLastValue?: boolean;
  onFilterChange?: () => void;
  filterable?: boolean;
  ignoreRule?: boolean;
}

const MultiSelectInput: FunctionComponent<MultiSelectInputProps> = memo(
  (fieldRenderProps: MultiSelectInputProps) => {
    const {
      filterable,
      onFilterChange,
      options,
      label,
      disabled,
      onChange,
      visited,
      defaultValue,
      validationMessage,
      popupSettings,
      useLastValue,
      name,
      ignoreRule,
    } = fieldRenderProps;

    const [internalValue, setInternalValue] = useState<DropDownData | undefined>(
      undefined,
    );
    const [lastValue, setLastValue] = useState<DropDownData | undefined>(undefined);

    const onInternalChange = (e: MultiSelectChangeEvent): void => {
      if (onChange) {
        onChange(e.value);
      }
      setInternalValue(e.value);
      const lastData = LocalStorageService.getLocalStorageJSON(
        'defaultValueMultiSelect',
      );
      const newData = {
        ...(lastData as Object),
        [name as string]: e.value,
      };
      LocalStorageService.setLocalStorageJSON('defaultValueMultiSelect', newData);
    };

    // const _defaultValue = options?.find(
    //   (o: DropDownData) =>
    //     o.value === internalValue?.value || o.value === defaultValue![0],
    // );

    useEffect(() => {
      if (options?.length === 1) {
        const newValue = { ...options![0] };

        if (onChange) {
          onChange({
            value: newValue.value,
          } as MultiSelectChangeEvent);
        }
      }
    }, [options]);

    useEffect(() => {
      if (useLastValue) {
        const savedData: any =
          LocalStorageService.getLocalStorageJSON('defaultValue');
        if (savedData) {
          Object.entries(savedData as Object).forEach((res) => {
            if (res[0] === name) {
              setInternalValue(res[1]);
              setLastValue(res[1]);
            }
          });
        }
      }
    }, []);

    if (options.length === 1) {
      return (
        <div style={fieldRenderProps.style}>
          <BrassMultiSelect
            id={label}
            label={label}
            data={options}
            textField='text'
            dataItemKey='value'
            // value={options[0]}
            defaultValue={ignoreRule ? ([] as any) : options[0].value}
            filterable={false}
            popupSettings={popupSettings}
          />
        </div>
      );
    }

    if (useLastValue) {
      return (
        <div style={fieldRenderProps.style}>
          <BrassMultiSelect
            id={label}
            label={label}
            disabled={disabled}
            textField='text'
            dataItemKey='value'
            // defaultValue={lastValue ? lastValue : null}
            onChange={onInternalChange}
            data={options.filter((option) => option.text && option.value)}
            popupSettings={popupSettings}
          />
          {visited! && validationMessage && (
            <BrassError>{validationMessage}</BrassError>
          )}
        </div>
      );
    }

    return (
      <div style={fieldRenderProps.style}>
        <BrassMultiSelect
          id={label}
          label={label}
          disabled={disabled}
          textField='text'
          dataItemKey='value'
          // defaultValue={_defaultValue}
          onChange={onInternalChange}
          data={options.filter((option) => option.text && option.value)}
          popupSettings={popupSettings}
          onFilterChangeCustom={onFilterChange}
          filterable={filterable}
        />
        {visited! && validationMessage && (
          <BrassError>{validationMessage}</BrassError>
        )}
      </div>
    );
  },
);

interface BrassMultiSelectFieldProps
  extends Omit<BrassFieldProps & MultiSelectInputProps, 'valid'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  component?: any;
  options?: DropDownData<any>[];
  className?: string;
}

const BrassMultiSelectField: FunctionComponent<BrassMultiSelectFieldProps> = memo(
  (props: any) => {
    const { name, required, loading, disabled, className } = props;

    const validators = [];
    if (required) {
      validators.push(requiredValidator);
    }

    if (disabled) {
      return (
        <FieldWrapper className={className}>
          <Label>{required ? `${props.label}*` : props.label}</Label>
          <BrassInput disabled />
        </FieldWrapper>
      );
    }

    if (loading) {
      return (
        <FieldWrapper className={className}>
          <Label style={{ opacity: 0.6 }}>
            {required ? `${props.label}*` : props.label}
          </Label>
          <BrassLoader primary={false} />
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper className={className}>
        <Label style={{ opacity: 0.6 }}>
          {required ? `${props.label}*` : props.label}
        </Label>
        <BrassField
          {...props}
          name={name!}
          validator={validators}
          component={MultiSelectInput}
          label={undefined /* required ? `${props.label}*` : props.label*/}
        />
      </FieldWrapper>
    );
  },
);

export { BrassMultiSelectField };
export type { BrassMultiSelectFieldProps };
