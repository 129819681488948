import React, {
  memo,
  Fragment,
  useState,
  useEffect,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react';
import style from './CommentsBoard.module.scss';
import { ApiResult } from '../../../../models';
import { CommentsService } from '../../services';
import { translate } from './CommentsBoard.translations';
import { CommentsList } from './comments-list/CommentsList';
import { Comment, CommentCardItem, CommentGroup } from '../../models';
import { NotificationCard } from '../notification-board/notification-card/NotificationCard';
import {
  BrassIconCardMoney,
  BrassIconClock,
  BrassIconProps,
  BrassLoader,
  IconCommentsDots,
  MainLayoutContainerContext,
} from '../../../../components';

interface CommentsBoardProps extends PropsWithChildren<any> {
  routes: any;
}

const CommentsBoard: FunctionComponent<CommentsBoardProps> = memo(
  (props: CommentsBoardProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filteredCommentsList, setFilteredCommentsList] = useState<
      CommentCardItem[]
    >([]);
    const [commentsList, setCommentsList] = useState<CommentCardItem[]>([]);
    const [commentsGroup, setCommentsGroup] = useState<CommentGroup>(
      CommentGroup.ALL,
    );

    const { handleCountComments } = useContext(MainLayoutContainerContext);

    const commentsCards: CommentCardItem[] = [
      {
        icon: (_props: BrassIconProps) => <IconCommentsDots {..._props} />,
        data: commentsList.filter(
          (comment: Comment) =>
            comment.group === CommentGroup.WORK_TIME_RECORD_AUTHORIZATION,
        ).length,
        title: translate(CommentGroup.WORK_TIME_RECORD_AUTHORIZATION),
        message: '',
        commentDate: new Date(),
        lastAlertDate: new Date(),
        group: CommentGroup.WORK_TIME_RECORD_AUTHORIZATION,
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconClock {..._props} />,
        data: commentsList.filter(
          (comment: Comment) => comment.group === CommentGroup.WORK_TIME_RECORD,
        ).length,
        title: translate(CommentGroup.WORK_TIME_RECORD),
        message: '',
        commentDate: new Date(),
        lastAlertDate: new Date(),
        group: CommentGroup.WORK_TIME_RECORD,
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconCardMoney {..._props} />,
        data: commentsList.filter(
          (comment: Comment) => comment.group === CommentGroup.FINANCE,
        ).length,
        title: translate(CommentGroup.FINANCE),
        message: '',
        commentDate: new Date(),
        lastAlertDate: new Date(),
        group: CommentGroup.FINANCE,
      },
      {
        icon: (_props: BrassIconProps) => <BrassIconClock {..._props} />,
        data: commentsList.filter(
          (comment: Comment) => comment.group === CommentGroup.OVERTIME_WORK,
        ).length,
        title: translate('OVERTIME_WORK'),
        message: '',
        commentDate: new Date(),
        lastAlertDate: new Date(),
        group: CommentGroup.OVERTIME_WORK,
      },
    ];

    useEffect(() => {
      setIsLoading(true);
      CommentsService.getCommentsList().then(
        (apiResult: ApiResult<CommentCardItem[]>) => {
          if (apiResult.success) {
            setCommentsList(apiResult.data);
            setFilteredCommentsList(apiResult.data);
          }
          setIsLoading(false);
          handleCountComments && handleCountComments();
        },
      );
    }, []);

    useEffect(() => {
      if (commentsGroup !== CommentGroup.ALL) {
        setFilteredCommentsList(
          commentsList.filter((n: CommentCardItem) => n.group === commentsGroup),
        );
      } else {
        setFilteredCommentsList(commentsList);
      }
    }, [commentsGroup]);

    if (isLoading) {
      return <BrassLoader useLoadingMask />;
    }

    return (
      <Fragment>
        <div className={style.container}>
          {commentsCards.map((commentsCard, index) => (
            <div
              key={index}
              className={style.card}
              onClick={(): void => {
                if (commentsGroup === commentsCard.group) {
                  setCommentsGroup(CommentGroup.ALL);
                } else {
                  setCommentsGroup(commentsCard.group as CommentGroup);
                }
              }}
            >
              <NotificationCard
                onClick={(): void => {
                  if (commentsGroup === commentsCard.group) {
                    setCommentsGroup(CommentGroup.ALL);
                  } else {
                    setCommentsGroup(commentsCard.group as CommentGroup);
                  }
                }}
                className={
                  commentsGroup === commentsCard.group
                    ? style['card-on']
                    : style['card-off']
                }
                Icon={commentsCard.icon!}
                data={commentsCard.data!}
                description={commentsCard.title}
              />
            </div>
          ))}
        </div>
        <CommentsList
          data={filteredCommentsList}
          routes={props.routes}
          commentsGroup={commentsGroup}
        />
      </Fragment>
    );
  },
);

export type { CommentsBoardProps };
export { CommentsBoard };
