import { Dispatch, SetStateAction } from 'react';
import {
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { AWSError, S3, config, Credentials } from 'aws-sdk';
import { ApiResult, BrassUploadFileInfo } from '../models';
// import { BaseService } from './base.service';

class UploadService {
  private static BUCKET_NAME: string = 'digitalplatform-temp';
  public static managedUploads: S3.ManagedUpload[] = [];
  private static userUploadCrendentials: Credentials = {
    accessKeyId: 'AKIA3BO7D7ST44EENZLS',
    secretAccessKey: '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',
  } as Credentials;
  public static filesToUpload: BrassUploadFileInfo[] = [];
  public static setFilesToUpload: Dispatch<SetStateAction<BrassUploadFileInfo[]>>;
  public static saveUrl: string = `https://${UploadService.BUCKET_NAME}.s3-accelerate.amazonaws.com/`;
  public static progressFiles: any;

  /*
    Função para avisar o backend que o upload foi concluído no bucket.
    Esta função deve estar dentro de um serviço e não dentro de um componente,
    pois se ela estivesse dentro de um componente ela seria destruída quando o
    componente for destruído. Para que esse serviço seja utilizado na store
    a função do uploadCallback não pode ser destruída.
  */
  public static uploadCallback: (
    fileUploadInfo: BrassUploadFileInfo,
  ) => Promise<ApiResult>;

  public static progressCallback: (progress: any) => any;

  public static getFilesToUpload(): BrassUploadFileInfo[] {
    return UploadService.filesToUpload;
  }

  public static onAddFile(event: UploadOnAddEvent): void {
    UploadService.filesToUpload = event.newState.filter(
      (f) => !f.validationErrors,
    ) as BrassUploadFileInfo[];
    UploadService.setFilesToUpload(UploadService.filesToUpload);
  }

  public static onRemoveFile(event: UploadOnRemoveEvent): void {
    UploadService.filesToUpload = UploadService.filesToUpload.filter((f) => {
      if (event.affectedFiles.length) {
        return f.uid !== event.affectedFiles[0].uid;
      } else {
        return true;
      }
    });
    UploadService.setFilesToUpload(UploadService.filesToUpload);
  }

  public static async uploadFilesToS3(): Promise<void> {
    config.update({
      ...UploadService.userUploadCrendentials,
      region: 'us-east-1',
      useAccelerateEndpoint: true,
      httpOptions: {
        timeout: 0,
      },
    });

    UploadService.filesToUpload.forEach(async (file: BrassUploadFileInfo) => {
      file.uploadStartTime = new Date();
      file.bucketKey = `${file.uid}${file.extension!}`;

      const managedUpload: S3.ManagedUpload = new S3.ManagedUpload({
        params: {
          Bucket: UploadService.BUCKET_NAME,
          Key: file.bucketKey,
          Body: file.getRawFile && file.getRawFile(),
        },
      }).on('httpUploadProgress', (progress: S3.ManagedUpload.Progress) => {
        file.progress = Math.round((progress.loaded / progress.total) * 99);
        file.status = UploadFileStatus.Uploading;
        UploadService.setFilesToUpload(UploadService.filesToUpload);
      });

      UploadService.managedUploads.push(managedUpload);

      managedUpload.send(async (err: AWSError) => {
        if (err) {
          file.status = UploadFileStatus.UploadFailed;
        } else {
          file.uploadEndTime = new Date();
          try {
            await UploadService.uploadCallback(file);
            file.progress = 100;
            file.status = UploadFileStatus.Uploaded;
          } catch (error) {
            console.error('Error on UploadCallback');
          }
        }
        UploadService.setFilesToUpload(UploadService.filesToUpload);
      });
    });
  }

  public static async uploadFilesAsyncToS3(): Promise<void> {
    config.update({
      ...UploadService.userUploadCrendentials,
      region: 'us-east-1',
      useAccelerateEndpoint: true,
      httpOptions: {
        timeout: 0,
      },
    });

    UploadService.filesToUpload.forEach(async (file: BrassUploadFileInfo) => {
      file.uploadStartTime = new Date();
      file.bucketKey = `${file.uid}${file.extension!}`;

      const managedUpload: S3.ManagedUpload = new S3.ManagedUpload({
        params: {
          Bucket: UploadService.BUCKET_NAME,
          Key: file.bucketKey,
          Body: file.getRawFile && file.getRawFile(),
        },
      }).on('httpUploadProgress', (progress: S3.ManagedUpload.Progress) => {
        file.progress = Math.round((progress.loaded / progress.total) * 99);
        file.status = UploadFileStatus.Uploading;
        const files = UploadService.getFilesToUpload();
        if (files.filter((file) => file.progress >= 99).length === files.length) {
          setTimeout(() => {
            UploadService.uploadCallback(file);
          }, 1200);
        }
      });

      UploadService.managedUploads.push(managedUpload);

      managedUpload.send(async (err: AWSError) => {
        if (err) {
          file.status = UploadFileStatus.UploadFailed;
        } else {
          file.uploadEndTime = new Date();
          try {
            file.progress = 100;
            file.status = UploadFileStatus.Uploaded;
          } catch (error) {
            console.error('Error on UploadCallback');
          }
        }
        UploadService.setFilesToUpload(UploadService.filesToUpload);
      });
    });
  }

  public static abortUpload(): void {
    if (UploadService.managedUploads.length) {
      UploadService.managedUploads.forEach((file) => {
        if (file) {
          file.abort();
        }
      });
    }
  }
}

export { UploadService };
