import {
  Drawer,
  DrawerContent,
  DrawerItemProps,
} from '@progress/kendo-react-layout';
import { DrawerSelectEvent } from '@progress/kendo-react-layout/dist/npm/drawer/interfaces/DrawerSelectEvent';
import classNames from 'classnames';
import {
  cloneElement,
  Fragment,
  FunctionComponent,
  ReactNode,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvironmentService } from '../../../../../';
import { AppRoute, AuthUser } from '../../../../../models';
import { MainLayoutContainerContext } from '../MainLayoutContainer';
import style from './LeftMenu.module.scss';
import { LeftMenuItemDrawer } from './LeftMenuItemDrawer';

interface LeftMenuProps {
  hideLeftMenu?: boolean;
  children: ReactNode | any;
}

/**
 * @param path
 * @param caseSensitive
 * @param end
 */
function compilePath(
  path: string,
  caseSensitive = false,
  end = true,
): [RegExp, string[]] {
  const paramNames: string[] = [];
  let regexpSource =
    '^' +
    path
      .replace(/\/*\*?$/, '') // Ignore trailing / and /*, we'll handle it below
      .replace(/^\/*/, '/') // Make sure it has a leading /
      .replace(/[\\.*+^$?{}|()[\]]/g, '\\$&') // Escape special regex chars
      .replace(/:(\w+)/g, (_: string, paramName: string) => {
        paramNames.push(paramName);
        return '([^\\/]+)';
      });

  if (path.endsWith('*')) {
    paramNames.push('*');
    regexpSource +=
      path === '*' || path === '/*'
        ? '(.*)$' // Already matched the initial /, just match the rest
        : '(?:\\/(.+)|\\/*)$'; // Don't include the / in params["*"]
  } else {
    regexpSource += end
      ? '\\/*$' // When matching to the end, ignore trailing slashes
      : // Otherwise, match a word boundary or a proceeding /. The word boundary restricts
        // parent routes to matching only their own words and nothing more, e.g. parent
        // route "/home" should not match "/home2".
        '(?:\\b|\\/|$)';
  }

  const matcher = new RegExp(regexpSource, caseSensitive ? undefined : 'i');

  return [matcher, paramNames];
}

const LeftMenu: FunctionComponent<LeftMenuProps> = (props: LeftMenuProps) => {
  const { children, hideLeftMenu } = props;
  const navigate = useNavigate();
  const { menuExpanded, routes, setMenuExpanded, setIsContentEditor } = useContext(
    MainLayoutContainerContext,
  );
  const isPath = (actualPath: string) => {
    if (!actualPath) {
      return false;
    }

    const pattern = {
      path: actualPath,
      caseSensitive: false,
      end: true,
    } as any;

    const [matcher] = compilePath(pattern.path, pattern.caseSensitive, pattern.end);

    const match = window.location.pathname.match(matcher);
    const return1 = !match;
    return !return1;
  };

  const onSelect = (e: DrawerSelectEvent): void => {
    if (e.itemTarget.props.path) {
      navigate(e.itemTarget.props.path);
    } else if (e.itemTarget.props.onCustomClick()) {
      e.itemTarget.props.onCustomClick();
    }
  };

  const itemsDrawer: DrawerItemProps[] = [];

  if (setIsContentEditor) {
    setIsContentEditor(routes.findIndex((_) => _.isContentEditor) > -1 ? true : false);
  }

  routes
    // Se tiver ícone, entonces significa que é para adicionar ao menu lateral
    .filter((route: AppRoute) => {
      const allowConnect = AuthUser.checkPermission(route.permission!);
      const isRoutes =
        route.isRoutes?.length === 0 ||
        (route.isRoutes?.filter((_) => isPath(_)).length ?? 0) > 0;
      const isNotRoutes =
        (route.isNotRoutes?.filter((_) => isPath(_)).length ?? 0) > 0;
      return route.icon && allowConnect && isRoutes && !isNotRoutes;
    })
    .forEach((route: AppRoute) => {
      itemsDrawer.push({
        icon: route.icon!,
        text: route.text!,
        path: route.path!,
        selected: isPath(route.path!),
        onCustomClick: route.onClick!,
      });

      if (route.addSeparatorAfter) {
        itemsDrawer.push({ separator: true });
      }
    });

  let cloneSelectedRoute = children;
  const selectedRoute = routes.find((route: AppRoute) => {
    if (isPath(route.path!)) {
      return true;
    }

    // Varre todos os parametros para saber se está e a rota correta
    const splitPath = route.path?.split('/').filter((f) => f !== '');
    const splitPathNeme = window.location.pathname
      .split('/')
      .filter((f) => f !== '');
    if (splitPath?.length !== splitPathNeme.length) {
      return false;
    }

    let splitIsValid = true;
    splitPath.forEach((pathPart: string, index: number) => {
      if (pathPart.startsWith(':')) {
        // Ignore, pois pode ser qualquer valor
      } else if (pathPart !== splitPathNeme[index]) {
        splitIsValid = false;
      }
    });

    return splitIsValid;
  });
  const isNavigatorSurvey = EnvironmentService.isNavigatorSurvey;

  if (selectedRoute) {
    const parametersFromRoute = {};
    const splitPath = selectedRoute.path?.split('/');
    const splitPathNeme = window.location.pathname.split('/');

    splitPath?.forEach((pathPart: string, index: number) => {
      if (pathPart.startsWith(':')) {
        (parametersFromRoute as any)[pathPart.substring(1)] = splitPathNeme[index];
      }
    });

    cloneSelectedRoute = cloneElement(children, parametersFromRoute);
  }

  if (hideLeftMenu || isNavigatorSurvey) {
    return cloneSelectedRoute || <Fragment />;
  }

  const onOverlayClick = () => {
    if (EnvironmentService.isNavigatorIntegrator) {
      setMenuExpanded!(!menuExpanded);
    }
  };

  return (
    <Drawer
      mini={EnvironmentService.isNavigatorIntegrator ? false : true}
      animation
      mode={EnvironmentService.isNavigatorIntegrator ? 'overlay' : 'push'}
      position='start'
      onSelect={onSelect}
      items={itemsDrawer}
      expanded={menuExpanded}
      className={classNames({
        [style.drawer]: true,
      })}
      item={LeftMenuItemDrawer}
      onOverlayClick={() => onOverlayClick()}
    >
      <DrawerContent>{cloneSelectedRoute || <Fragment />}</DrawerContent>
    </Drawer>
  );
};

LeftMenu.defaultProps = {
  hideLeftMenu: false,
};

export { LeftMenu };
export type { LeftMenuProps };

