import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { Popup, PopupProps } from '@progress/kendo-react-popup';

interface BrassContextMenuProps extends PopupProps {
  menuItems?: MenuItemProps[];
  onSelect?: (event: MenuSelectEvent) => void;
}

const BrassContextMenu: FunctionComponent<BrassContextMenuProps> = (
  props: BrassContextMenuProps,
) => {
  const { className, menuItems, onSelect } = props;
  const _props = { ...props };
  delete _props.menuItems;
  delete _props.onSelect;
  const _className = classnames('brass-context-menu', className);

  return (
    <Popup show {..._props}>
      <Menu vertical onSelect={onSelect} className={_className}>
        {menuItems?.map((item: MenuItemProps, index: number) => (
          <MenuItem key={index} {...item} />
        ))}
      </Menu>
    </Popup>
  );
};

export { BrassContextMenu };
export type { BrassContextMenuProps };
