import React from 'react';
import { I18nService, II18n } from '../../../../index';

const translations: II18n = {
  es: {
    commentInput: 'Escriba un comentario',
  },
  enUS: {
    commentInput: 'Type a comment',
  },
  ptBR: {
    commentInput: 'Digite um comentário',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
