import { emptyGuid } from '../../../util';
import { WorkedDaysPlotData } from './WorkedDaysPlotData.model';

class UserStatistics {
  public userId: string = emptyGuid();
  public dailyHoursLimit: string = '';
  public dailyHoursLimitNumber: number = 0;
  public totalWorkedHours: number = 0;
  public totalWorkedHoursText: string = '';
  public totalWorkedDays: number = 0;
  public averageWorkedHours: number = 0;
  public averageWorkedHoursText: string = '';
  public averageMonthlyWorkedHours: number = 0;
  public averageDailyWorkedHours: number = 0;
  public countWorkedProjets: number = 0;
  public countWorkedDisciplines: number = 0;
  public workedDaysPlotData: WorkedDaysPlotData[] = [];

  constructor(userStatistics: Partial<UserStatistics>) {
    Object.assign(this, userStatistics);
  }
}

export { UserStatistics };
