import React from 'react';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    privilegesTitle: 'Otros Privilegios',
    value: 'Id do Privilégio',
    text: 'Privilegios',
    insert: 'Insertar {0} privilegios seleccionados',
  },
  enUS: {
    privilegesTitle: 'Other Privileges',
    value: 'Id do Privilégio',
    text: 'Privileges',
    insert: 'Insert {0} selected privileges',
  },
  ptBR: {
    privilegesTitle: 'Outros Privilégios',
    value: 'Id do Privilégio',
    text: 'Privilégios',
    insert: 'Inserir {0} privilegios selecionados',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
