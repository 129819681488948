import { ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { getKeyFromItemOnListValue } from '../../../util';
import {
    UserNotification,
    UserNotificationCount,
    UserNotificationGroup,
    userNotificationTypeGroup,
} from '../models';

class NotificationService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getNotificationList(): Promise<ApiResult<UserNotification[]>> {
    const apiResult: ApiResult = await this.api.get<UserNotification[]>(
      `/connect/v2/notifications/list`,
    );
    apiResult.data = apiResult.data.map((userNotification: UserNotification) => {
      return {
        ...userNotification,
        group: getKeyFromItemOnListValue(
          userNotificationTypeGroup,
          userNotification.type,
        )! as UserNotificationGroup,
      };
    });
    return apiResult;
  }

  public static async getNotificationCount(): Promise<
    ApiResult<UserNotificationCount>
  > {
    return await this.api.get<UserNotificationCount>(`/connect/v2/notifications/count`);
  }

  public static async getCommentsCount(): Promise<ApiResult<UserNotificationCount>> {
    return await this.api.get<UserNotificationCount>(`/connect/v2/comments/count`);
  }
}

export { NotificationService };
