import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconUploadAlt: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    {...props}
    fill={props?.fill ?? '#424242'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.20834 16.6667C4.0139 16.6667 2.98612 16.2361 2.12501 15.375C1.2639 14.5139 0.833344 13.4861 0.833344 12.2917C0.833344 11.2083 1.17709 10.2535 1.86459 9.42708C2.55209 8.60069 3.43751 8.10417 4.52084 7.9375C4.79862 6.59028 5.4514 5.48958 6.47918 4.63542C7.50696 3.78125 8.69446 3.35417 10.0417 3.35417C11.6111 3.35417 12.9271 3.92014 13.9896 5.05208C15.0521 6.18403 15.5833 7.54167 15.5833 9.125V9.625C16.5833 9.59722 17.4306 9.92014 18.125 10.5937C18.8195 11.2674 19.1667 12.1181 19.1667 13.1458C19.1667 14.1042 18.8195 14.9306 18.125 15.625C17.4306 16.3194 16.6042 16.6667 15.6458 16.6667H10.625C10.2917 16.6667 10 16.5417 9.75001 16.2917C9.50001 16.0417 9.37501 15.75 9.37501 15.4167V10.0417L7.64584 11.7708L6.75001 10.875L10 7.625L13.25 10.875L12.3542 11.7708L10.625 10.0417V15.4167H15.6458C16.2708 15.4167 16.8056 15.1944 17.25 14.75C17.6945 14.3056 17.9167 13.7708 17.9167 13.1458C17.9167 12.5208 17.6945 11.9861 17.25 11.5417C16.8056 11.0972 16.2708 10.875 15.6458 10.875H14.3333V9.125C14.3333 7.88889 13.9132 6.82639 13.0729 5.9375C12.2326 5.04861 11.1945 4.60417 9.95834 4.60417C8.72223 4.60417 7.68057 5.04861 6.83334 5.9375C5.98612 6.82639 5.56251 7.88889 5.56251 9.125H5.16668C4.30557 9.125 3.5764 9.42708 2.97918 10.0312C2.38195 10.6354 2.08334 11.3819 2.08334 12.2708C2.08334 13.1319 2.3889 13.8715 3.00001 14.4896C3.61112 15.1076 4.34723 15.4167 5.20834 15.4167H8.12501V16.6667H5.20834Z'
      fill={props?.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconUploadAlt };
