import { ApiResult, DropDownData, Endpoints } from '../models';
import { System } from '../models/viewer/system.model';
import { ApiService } from './api.service';

class SystemService {
  private static apiNavigator: ApiService = new ApiService(Endpoints.NAVIGATOR);

  public static async deleteSystem(
    projectId: string,
    systemId: string,
  ): Promise<ApiResult> {
    return this.apiNavigator.delete(`/connect/v2/project/${projectId}/system/${systemId}`);
  }

  public static async updateSystem(system: System): Promise<ApiResult> {
    return this.apiNavigator.put(`/connect/v2/system`, system);
  }

  public static async includeSystem(system: System): Promise<ApiResult> {
    return this.apiNavigator.post('/connect/v2/system', system);
  }

  public static async getSystem(
    projectId: string,
    systemId: string,
  ): Promise<ApiResult<System>> {
    return this.apiNavigator.get(`/connect/v2/project/${projectId}/system/${systemId}`);
  }

  public static async getSystemDropdownItems(): Promise<ApiResult<DropDownData[]>> {
    return this.apiNavigator.get(`/connect/v1/system/dropdown`);
  }
}

export { SystemService };
