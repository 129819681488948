import { createAppStore } from '@brass-brasil/core';
import { AxiosRequestConfig } from 'axios';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';

const start = performance.now();

const AppRouter = lazy(() => import('./AppRouter'));

ReactDOM.render(
  <StrictMode>
    <Provider store={createAppStore()}>
      <Suspense
        fallback={
          <div className='k-loading-mask'>
            <span className='k-loading-text'>Loading</span>
            <div className='k-loading-image'></div>
            <div className='k-loading-color'></div>
          </div>
        }
      >
        <AppRouter />
      </Suspense>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
/*

const end = performance.now();
console.log(`Render time: ${end - start}ms`);

const intercept = (config: AxiosRequestConfig<any>) => {
  config.baseURL = 'http://192.168.15.100:5000';
  config.url = config.url.replace('connect/', '');
  return config;
};

window['xintercept'] = intercept;
*/


const end = performance.now();
console.log(`Render time: ${end - start}ms`);

const intercept = (config: AxiosRequestConfig<any>) => {

  if (config.baseURL.includes("/connect")) {
    config.baseURL = config.baseURL.replace('/connect', '');
  }
  if (config.baseURL.includes("/auth")) {
    config.baseURL = config.baseURL.replace('/auth', '');
  }
  //console.log(config.baseURL, config.url);
  if (document.location.href.includes("localhost")) {
    config.baseURL = 'http://192.168.15.100:5000';
    config.url = config.url.replace('connect/', '');

  }
  return config;
};

window['xintercept'] = intercept;