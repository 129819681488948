import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { BrassButton } from '../buttons';
import {
  DropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
  DropDownTreeProps,
} from '@progress/kendo-react-dropdowns';
import { expandedState, processTreeData } from './DropdownTree.functions';
import { DropDownTreeData, II18n } from '../../../models';
import { I18nService } from '../../../services';

interface BrassDropdownTreeProps
  extends Omit<DropDownTreeProps, 'textField' | 'dataItemKey'> {
  data: DropDownTreeData[];
  onLeftButtonClick?: () => void;
  leftButtonIcon?: any;
  selectParentItem?: boolean;
  onChange?: (event: DropDownTreeChangeEvent) => void;
  defaultValue?: any;
  dataItemKey?: string;
  textField?: string;
}

const BrassDropdownTree: FunctionComponent<BrassDropdownTreeProps> = (
  props: BrassDropdownTreeProps,
) => {
  const {
    textField,
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
    data,
    defaultValue,
  } = props;
  const [value, setValue] = useState<any[]>([]);
  const [expanded, setExpanded] = useState(
    data[0] ? [(data as any)[0][dataItemKey!]] : [],
  );

  const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
  };

  // let valueDefault: any;
  // const findEntireObject = (tree: DropDownTreeData[]): any => {
  //   tree.forEach((treeElement: DropDownTreeData) => {
  //     if (props.defaultValue && props.defaultValue.includes(treeElement.value)) {
  //       valueDefault = treeElement;
  //     } else if (treeElement.children) {
  //       findEntireObject(treeElement.children);
  //     }
  //   });
  //   return;
  // };

  // findEntireObject(props.data!);

  // const onChange = (event: DropDownTreeChangeEvent) => {
  //   let newValue = null;
  //   if (event.value === null || !props.selectParentItem) {
  //     newValue = event.value;
  //   } else if (event.value && event.value.children) {
  //     newValue = event.value;
  //   } else {
  //     let positionValue = data[event.level[0]];
  //     event.level.map((position: number, index: number) => {
  //       if (index > 0) {
  //         if (positionValue.children && !positionValue.children[position].children)
  //           return;
  //         else positionValue = positionValue.children![position];
  //       }
  //     });
  //     newValue = positionValue;
  //   }
  //   setValue(newValue);
  //   props.onChange(event.value);
  // };

  const onChange = (event: DropDownTreeChangeEvent) => {
    setValue(event.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const onExpandChange = useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey!, expanded)),
    [expanded],
  );

  const treeData = useMemo(
    () => processTreeData(data, { expanded, value }, fields),
    [expanded, value],
  );

  return (
    <div style={{ display: 'flex' }}>
      {props.onLeftButtonClick && (
        <BrassButton
          fillMode='outline'
          themeColor='base'
          onClick={props.onLeftButtonClick}
          style={{ float: 'left' }}
          type='button'
        >
          {props.leftButtonIcon}
        </BrassButton>
      )}
      <DropDownTree
        data={treeData}
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={translate('select')}
        textField={textField!}
        dataItemKey={dataItemKey!}
        selectField={selectField}
        expandField={expandField}
        onExpandChange={onExpandChange}
        subItemsField={subItemsField}
      />
    </div>
  );
};

const translations: II18n = {
  es: {
    select: 'Selecionar',
  },
  enUS: {
    select: 'Select',
  },
  ptBR: {
    select: 'Selecionar',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

BrassDropdownTree.defaultProps = {
  selectField: 'selected',
  expandField: 'expanded',
  dataItemKey: 'value',
  textField: 'text',
  subItemsField: 'children',
  selectParentItem: false,
};

export { BrassDropdownTree };
export type { BrassDropdownTreeProps };
