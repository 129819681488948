import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DialogActionsBarProps } from '@progress/kendo-react-dialogs/dist/npm/DialogActionsBar';

interface BrassDialogActionsBarProps
  extends DialogActionsBarProps,
    PropsWithChildren<any> {}

const BrassDialogActionsBar: FunctionComponent<BrassDialogActionsBarProps> = (
  props: BrassDialogActionsBarProps,
) => {
  const { className } = props;
  const _className = classnames('brass-dialog-actions-bar', className);
  return (
    <div className={_className}>
      <DialogActionsBar {...props} />
    </div>
  );
};

export { BrassDialogActionsBar };
export type { BrassDialogActionsBarProps };
