import React from 'react';
import { AppRoute } from '../../models';
import { NotificationsGrid } from './pages';

const getNotificationRoutes = (): AppRoute[] => [
  new AppRoute({
    path: '/home/my-notifications',
    component: <NotificationsGrid />,
  }),
];

export { getNotificationRoutes };
