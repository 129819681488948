import React, {
  forwardRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  Fragment,
  FunctionComponent,
  RefAttributes,
  useState,
} from 'react';
import style from './EditorField.module.scss';
import { translate } from './EditorField.translations';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassEditor, BrassEditorProps } from '../../../editor';
import { BrassError } from '../../../labels/Error';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import { Label } from '@progress/kendo-react-labels';

interface EditorFieldInputProps extends BrassEditorProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const EditorFieldInput: FunctionComponent<EditorFieldInputProps> = (
  fieldRenderProps: any,
): any => {
  const { maxLength, validationMessage, value, disabled, onChange, visited } =
    fieldRenderProps;

  const [internalValue, setInternalValue] = useState<string>(value || '');
  const onInternalChange = (e: InputChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value);
  };

  return (
    <Fragment>
      <BrassEditor
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
      />
      {visited && validationMessage && <BrassError>{validationMessage}</BrassError>}
      {maxLength && !validationMessage && (
        <div className={style['div-messages-info']}>
          {internalValue.length || 0} / {maxLength}
        </div>
      )}
    </Fragment>
  );
};

interface BrassEditorFieldProps
  extends Omit<BrassFieldProps & EditorFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}

const BrassEditorField: ForwardRefExoticComponent<
  Pick<BrassEditorFieldProps, keyof BrassEditorFieldProps> & RefAttributes<unknown>
> = forwardRef((props: BrassEditorFieldProps, ref: any) => {
  const { name, required, loading, validator } = props;

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={() => <BrassEditor ref={ref} />}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : EditorFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label */}
      />
    </FieldWrapper>
  );
});

export { BrassEditorField };
export type { BrassEditorFieldProps };
