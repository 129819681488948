import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AuthUser,
  DynamicDispatchAlert,
  getLanguagesStorage,
} from '../../../../index';
import { IChatLikedPayload, IChatPayload, LikeType } from '../CommentChat.model';
import { ChatService } from '../CommentChat.service';
import { IBrassChatBlockProps } from './ChatBlock';
import { translate } from './ChatBlock.translations';

interface IChatBlockFunctions {
  dataChat: IBrassChatBlockProps;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  likes: LikeType[];
}

const ChatBlockFunctions = (props: IChatBlockFunctions) => {
  const [openInput, setOpenInput] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLike, setIsLoadingLike] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setRefresh, likes } = props;

  const insertCommentInChat = () => {
    if (message) {
      setIsLoading(true);
      setDisableButton(true);
      const chatPayload = {
        language: getLanguagesStorage()?.language,
        message,
        referenceId: props?.dataChat?.referenceId,
        messageParentId: props?.dataChat?.chatId,
        receivers: [{ userId: AuthUser?.getId() }],
      } as IChatPayload;
      ChatService.insertChat(chatPayload)
        .then((res) => {
          if (res.success) {
            DynamicDispatchAlert(
              dispatch,
              ['insertCommentInChatSuccess'],
              'SUCCESS',
              translate,
            );
            setMessage('');
          } else {
            DynamicDispatchAlert(
              dispatch,
              ['insertCommentInChatError'],
              'ERROR',
              translate,
            );
          }
        })
        .finally(() => {
          setDisableButton(false);
          setRefresh((prev) => !prev);
          setIsLoading(false);
        });
    }
  };

  const insertLiked = (type: string) => {
    if (props.dataChat.chatId) {
      setIsLoadingLike(true);
      setDisableButton(true);
      const chatLikedPayload = {
        iconKey: '',
        type:  type ? 'LIKE' : type,
        chatId: props.dataChat?.chatId,
      } as IChatLikedPayload;
      ChatService.insertLike(
        props.dataChat.chatId,
        props?.dataChat?.firstParentId,
        chatLikedPayload,
      )
        .then((res) => {
          if (res.success) {
            DynamicDispatchAlert(
              dispatch,
              ['insertCommentInChatSuccess'],
              'SUCCESS',
              translate,
            );
          } else {
            DynamicDispatchAlert(dispatch, ['errorInsertLike'], 'ERROR', translate);
          }
        })
        .finally(() => {
          setIsLoadingLike(false);
          setRefresh((prev) => !prev);
        });
    }
  };

  const verifyLike = (_likes: LikeType[]) => {
    if (_likes && _likes.length > 0) {
      return (
        _likes.filter((_) => _?.userId === AuthUser._currentUser?.id)?.length > 0
      );
    } else {
      return false;
    }
  };

  const handleInsertKeyPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      insertCommentInChat();
    }
  };

  const formatName = (name: string) => {
    const splitUserName = name.split(' ');
    const firstUserName = splitUserName[0].split('')[0];
    const lastUserName = splitUserName[splitUserName.length - 1].split('')[0];
    const initialUserName = firstUserName.concat(lastUserName);

    return initialUserName;
  };

  return {
    openInput,
    setOpenInput,
    setMessage,
    message,
    insertCommentInChat,
    disableButton,
    setDisableButton,
    insertLiked,
    handleInsertKeyPressEnter,
    isLoading,
    verifyLike,
    isLoadingLike,
    formatName,
  };
};

export { ChatBlockFunctions };

