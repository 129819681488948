import React, { FunctionComponent, useState } from 'react';
import style from './PasswordInputField.module.scss';
import { translate } from './PasswordInputField.translations';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  BrassButton,
  BrassTextField,
  BrassTextFieldProps,
} from '../../../../components/brass';
import { I18nService } from '../../../../services';
import { BrassIcon } from '../../../../components/shared/icon';

const BrassPasswordField: FunctionComponent<any> = (props: BrassTextFieldProps) => {
  const { component, label, ...others } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={style.container}>
      <BrassTextField
        type={showPassword ? 'text' : 'password'}
        label={translate(label!)}
        {...others}
      />
      <BrassButton
        roundBorder
        fillMode='flat'
        type='button'
        onClick={(): void => setShowPassword(!showPassword)}
        className={style['button-eye']}
      >
        <BrassIcon icon={showPassword ? faEye : faEyeSlash} />
      </BrassButton>
    </div>
  );
};

export { BrassPasswordField };
