class ProgressBar {
  public value: number = 0;
  public max: number = 0;
  public progressStyle: any = {};
  public emptyStyle: any = {};
  public label: string = '';

  constructor(progressBar: Partial<ProgressBar>) {
    Object.assign(this, progressBar);
  }
}

export { ProgressBar };
