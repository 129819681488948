import React from 'react';
import { II18n } from '../../../../../../../../models';
import { I18nService } from '../../../../../../../../services';

const translations: II18n = {
  es: {
    message: 'Mensaje',
  },
  enUS: {
    message: 'Message',
  },
  ptBR: {
    message: 'Mensagem',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
