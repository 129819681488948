import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

const BrassButtonShare: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-share'
    labelKey='share'
    tooltipKey='clickToShare'
    translations={translations}
    icon={<BrassIcon icon={faShareAlt} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    share: 'Compartir',
    clickToShare: 'haga clic para compartir',
  },
  enUS: {
    share: 'Share',
    clickToShare: 'Click to share',
  },
  ptBR: {
    share: 'Compartilhar',
    clickToShare: 'Clique para compartilhar',
  },
};

export { BrassButtonShare };
