enum IntegratorSubmodule {
  SYSTEM_CLASSIFICATION = 0x00000100,
  FILE_TYPE = 0x00000200,
  FILE_CLASSIFICATION = 0x00000300,
  FILE = 0x00000400,
  FILE_INFORMATION = 0x00000500,
  SYSTEM_TYPE = 0x00000600,
}

export { IntegratorSubmodule };
