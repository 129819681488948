import React, { Fragment, FunctionComponent } from 'react';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../../../../../components/shared/icon/Icon';
import { BrassButton } from '../../../../../../components/brass/buttons/Button';
import { EnvironmentService } from '../../../../../../services';
import { AuthUser } from '../../../../../../models/authUser.model';

const StatusPage: FunctionComponent = () => {
  if (!EnvironmentService.showStatusPage(AuthUser.getEmail())) {
    return <Fragment />;
  }

  const onClick = (): void => {
    window.open('https://brassbrasil.checklyhq.com', '_blank');
  };

  return (
    <BrassButton fillMode='flat' onClick={onClick}>
      <BrassIcon icon={faSignal} size='2x' color='white' />
    </BrassButton>
  );
};

export { StatusPage };
