import React, { FunctionComponent, ReactElement } from 'react';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../../../icon/Icon';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { EnvironmentService } from '../../../../../../services';
import {
  AuthUser,
  ConnectPermissions,
  AcademyPermissionsV1,
  NavigatorPermissions,
  DrivePermissionsV1,
  ModuleUrlEnum,
  SgiPermissionsV1,
} from '../../../../../../models';
import style from './MenuModuleButton.module.scss';
import { orderBy, redirectPlatform } from '../../../../../../util';
import {
  BrassIconAcademyV1,
  BrassIconConnectV1,
  BrassIconDriveV1,
  BrassIconNavigatorV1,
  SVGIconEducation,
} from '../../../../icon';

interface MenuItemElementProps {
  text: string;
  icon?: string;
  svg?: ReactElement;
  url: string;
}

const MenuModuleButton: FunctionComponent = () => {
  const allowConnect = AuthUser.checkPermission(ConnectPermissions.CONNECT_MODULE);
  const allowSgi = AuthUser.checkPermission(SgiPermissionsV1.SGI_MODULE);
  const allowAcademy = AuthUser.checkPermission(AcademyPermissionsV1.ACADEMY_MODULE);
  const allowDrive =
    AuthUser.checkPermission(DrivePermissionsV1.SMART_LIB_MODULE) ||
    AuthUser.checkPermission(DrivePermissionsV1.NUCLEAR_MANAGEMENT_MODULE);
  const allowNavigator = AuthUser.checkPermission(
    NavigatorPermissions.NAVIGATOR_MODULE,
  );
  // const allowSurvey = AuthUser.checkPermission(
  //   NavigatorPermissions.NAVIGATOR_MODULE,
  // );

  const brassButton = (): ReactElement => (
    <BrassIcon icon={faTh} size='2x' color='white' />
  );

  const visibleCoreElements: MenuItemElementProps[] = [];
  if (allowConnect) {
    if (!EnvironmentService.isConnect) {
      visibleCoreElements.push({
        text: 'Connect',
        svg: <BrassIconConnectV1 width={'14px'} />,
        url: 'CONNECT',
      });
    }
  }

  if (allowNavigator) {
    if (!EnvironmentService.isNavigator) {
      visibleCoreElements.push({
        text: 'Navigator',
        svg: <BrassIconNavigatorV1 width={'14px'} />,
        url: 'NAVIGATOR',
      });
    }
  }

  if (allowDrive) {
    if (!EnvironmentService.isDrive && !EnvironmentService.isProd) {
      visibleCoreElements.push({
        text: 'Drive',
        svg: <BrassIconDriveV1 width={'14px'} />,
        url: 'DRIVE',
      });
    }
  }

  if (allowSgi) {
    if (!EnvironmentService.isAppSgi) {
      visibleCoreElements.push({
        text: 'SGI Brass',
        svg: <BrassIconAcademyV1 width={'14px'} />,
        url: 'sgi',
      });
    }
  }

  if (allowAcademy && !EnvironmentService.isProd) {
    if (!EnvironmentService.isAppAcademy) {
      visibleCoreElements.push({
        text: 'Academy',
        svg: <SVGIconEducation width={'14px'} />,
        url: 'academy',
      });
    }
  }

  return (
    <Menu className={style.arrowIcon}>
      <MenuItem render={brassButton}>
        {visibleCoreElements?.map((item: MenuItemElementProps, index: number) => (
          <MenuItem
            key={index}
            cssStyle={{
              padding: '0',
            }}
            render={() => (
              <button
                translate='no'
                className={style.button}
                onClick={() => {
                  redirectPlatform(item.url as ModuleUrlEnum);
                }}
              >
                {item.icon && <span className={`k-icon k-i-${item.icon}`}></span>}
                {item.svg && item.svg}
                {item.text}
              </button>
            )}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

export { MenuModuleButton };
