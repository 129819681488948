import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';

enum AcademyModulePermission {
  MANAGEMENT_CONTENT = 0x00010000,
  USER_PAINEL = 0x00020000,
  CONNECTION_MANAGEMENT = 0x00030000,
  TRAIL_MANAGEMENT = 0x00040000,
  MANAGEMENT_OF_CONTENT_LINKS = 0x00050000,
}

enum AcademyFeaturesPermission {
  MANAGEMENT_AREA_SYSTEM = 0x00000100,
  MANAGEMENT_CONTENT = 0x00000200,

  USER_PANEL_VISUALIZATION = 0x00000100,

  CONNECTION_MANAGEMENT = 0x00000100,

  TRAIL_MANAGEMENT = 0x00000100,

  MANAGEMENT_OF_CONTENT_LINKS = 0x00000100,
}

export { AcademyModulePermission, AcademyFeaturesPermission };
