import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    id: 'ID',
    groupname: 'Nombre del grupo',
    actions: 'Aciones',
    gidItem: 'Nombre del grupo',
    gridTitle: 'Gestión de grupos de usuarios',
    'user-group': 'Gestión de grupos de usuarios',
    config: 'Configuración',
    userGroupList: 'Gestión de grupos de usuarios',
    successOnUpdate: 'Grupo actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar el grupo',
    successOnDelete: 'Grupo eliminado correctamente',
    errorOnDelete: 'Error al intentar eliminar el grupo',
    delete: 'Borrar',
    edit: 'Editar',
  },
  enUS: {
    id: 'ID',
    groupname: 'Group name',
    actions: 'Actions',
    gidItem: 'Group name',
    gridTitle: 'User group management',
    'user-group': 'User group management',
    config: 'Configuration',
    userGroupList: 'User group management',
    successOnUpdate: 'Group updated successfully',
    errorOnUpdate: 'Error trying to update group',
    successOnDelete: 'Group deleted successfully',
    errorOnDelete: 'Error trying to delete group',
    delete: 'Delete',
    edit: 'Edit',
  },
  ptBR: {
    id: 'ID',
    groupname: 'Nome do grupo',
    actions: 'Ações',
    gidItem: 'Nome do grupo',
    gridTitle: 'Gestão de grupo de usuários',
    name: 'Nome do grupo',
    'user-group': 'Gestão de grupo de usuários',
    config: 'Configuração',
    userGroupList: 'Gestão de grupo de usuários',
    successOnUpdate: 'Item atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar o item',
    successOnDelete: 'Item excluído com sucesso',
    errorOnDelete: 'Erro ao tentar excluir o item',
    delete: 'Excluir',
    edit: 'Editar',
  },
};

const translationsHelp: II18n = {
  es: {
    userHelp: `
      <h3>...</h3>
      <p>...</p>
    `,
  },
  enUS: {
    userHelp: `
      <h3>...</h3>
      <p>...</p>
    `,
  },
  ptBR: {
    userHelp: `
      <h3>...</h3>
      <p>...</p>
    `,
  },
};

const fullTranslations: II18n = {
  es: {
    ...translations.es,
    ...translationsHelp.es,
  },
  enUS: {
    ...translations.enUS,
    ...translationsHelp.enUS,
  },
  ptBR: {
    ...translations.ptBR,
    ...translationsHelp.ptBR,
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(fullTranslations).translate(key, args);

export { translate };
