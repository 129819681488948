import { emptyGuid } from '../../';

export class TabsModel {
  public name: string = '';
  public areaId: string = emptyGuid();
  public systemId: string = emptyGuid();
  public fileId: string = emptyGuid();
  public projectId: string = emptyGuid();

  constructor(tabs: Partial<TabsModel>) {
    Object.assign(this, tabs);
  }
}
