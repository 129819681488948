import React, { ComponentType, ReactNode } from 'react';

class AppRoute {
  public onClick?: any;
  public path?: string;
  public component?: ComponentType | ReactNode | any;
  public isPublic?: boolean = false;
  public useMainLayout?: boolean = true;
  public text?: string = '';
  public hideLeftMenu?: boolean = false;

  // Se o ícone existir, então a rota será exibida no menu lateral
  public icon?: any = undefined;

  // Permite adicionar um SEPARADOR após o item do Menu
  public addSeparatorAfter?: boolean = false;

  // Permite exibir o BreadCrumb em determinadas ROTAS
  public showBreadcrumb?: boolean = true;
  public roundBreadcrumb?: boolean = true;

  // ID da permissão para acesso a ROTA
  // EXEMPLO: ConnectPermissions.CONNECT_ACCOUNT_READ
  public permission?: number = 0;
  public permissions?: number[] = [];

  // Rotas que deve aparecer o menu
  public isRoutes?: string[] = [];
  // Rotas que não deve aparecer o menu
  public isNotRoutes?: string[] = [];

  // Estados que definem um editor de conteúdo
  public isContentEditor?: boolean = false;

  constructor(appRoute: AppRoute) {
    Object.assign(this, appRoute);
  }
}

export { AppRoute };
