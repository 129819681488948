import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';
import { BrassTooltip } from '../../brass/tooltip';
import { BrassButton, BrassButtonProps, BrassLoader } from '../../brass/';

interface BrassButtonSharedProps extends Omit<BrassButtonProps, 'icon'> {
  icon?: any;
  showText?: boolean;
  showLoader?: boolean;

  // Ocultar o Icone enquando o Loader estiver ON?
  hideIconWhenLoader?: boolean;

  labelKey?: string;
  tooltipKey?: string;
  translations?: II18n;
}

const BrassButtonShared: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => {
  const _props = { ...props };
  delete _props.icon;
  delete _props.labelKey;
  delete _props.showLoader;
  delete _props.showText;
  delete _props.tooltipKey;
  delete _props.translations;
  delete _props.hideIconWhenLoader;

  const tooltipKey =
    props.translations &&
    I18nService.translate(props.translations!, props.tooltipKey!);
  const labelKey =
    props.showText &&
    props.translations &&
    I18nService.translate(props.translations!, props.labelKey!);

  const button = (
    <span title={tooltipKey}>
      <BrassButton
        themeColor={_props.themeColor || 'primary'}
        fillMode={_props.fillMode || 'outline'}
        {..._props}
      >
        {props.showLoader ? (
          <BrassLoader
            themeColor={_props.themeColor === 'primary' ? 'dark' : 'light'}
            style={{ width: 'auto' }}
          />
        ) : (
          props.icon
        )}
        {props.showText && (
          <span
            style={{
              marginLeft: props.showText ? '5px' : '0px',
            }}
          >
            {labelKey}
          </span>
        )}
      </BrassButton>
    </span>
  );

  if (props.translations && props.tooltipKey) {
    return <BrassTooltip>{button}</BrassTooltip>;
  } else {
    return button;
  }
};

BrassButtonShared.defaultProps = {
  showText: true,
  showLoader: false,
  hideIconWhenLoader: false,
};

export { BrassButtonShared };
export type { BrassButtonSharedProps };
