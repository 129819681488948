interface IFormState {
  initialValues: any;
  stepInitialValues: any[];
  dataFetched: number;
  allowSubmit: boolean;
  formLoading: boolean;
}

const initialFormState: IFormState = {
  initialValues: {},
  stepInitialValues: [],
  dataFetched: 0,
  allowSubmit: false,
  formLoading: false,
};

export { initialFormState };
export type { IFormState };
