import React, { Fragment, FunctionComponent, useState } from 'react';
import style from './TextField.module.scss';
import { translate } from './TextField.translations';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassInput, BrassInputProps } from '../../../inputs/Input';
import { BrassError } from '../../../labels/Error';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import { Label } from '@progress/kendo-react-labels';

interface TextFieldInputProps extends BrassInputProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const TextFieldInput: FunctionComponent<TextFieldInputProps> = (
  fieldRenderProps: any,
): any => {
  const {
    maxLength,
    validationMessage,
    value,
    disabled,
    onChange,
    visited,
    onBlur,
    notValidationMessage,
  } = fieldRenderProps;

  const [internalValue, setInternalValue] = useState<string>(value || '');
  const onInternalChange = (e: InputChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value);
  };

  return (
    <Fragment>
      <BrassInput
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
        style={{
          opacity: disabled ? 0.6 : 1.0,
          borderColor: visited && validationMessage ? 'red' : 'rgba(0, 0, 0, 0.12)',
        }}
      />
      {visited && validationMessage && !notValidationMessage && (
        <BrassError>{validationMessage}</BrassError>
      )}
      {maxLength && !validationMessage && (
        <div className={style['div-messages-info']}>
          {internalValue.length || 0} / {maxLength}
        </div>
      )}
    </Fragment>
  );
};

interface BrassTextFieldProps
  extends Omit<BrassFieldProps & TextFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
  notValidationMessage?: boolean;
}

const BrassTextField: FunctionComponent<BrassTextFieldProps> = (props: any) => {
  const { name, required, loading, validator } = props;

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassInput}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : TextFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label */}
      />
    </FieldWrapper>
  );
};

export { BrassTextField };
export type { BrassTextFieldProps };
