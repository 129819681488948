import React, { FunctionComponent, memo, PropsWithChildren } from 'react';
import style from './EmptyViewer.module.scss';
import { translate } from './EmptyViewer.translations';
import classnames from 'classnames';
import { faReplyAll } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../../../../../components';

const EmptyViewer: FunctionComponent<PropsWithChildren<any>> = memo(
  (props: PropsWithChildren<any>) => (
    <div className={classnames(style['main-document'], props.className)}>
      <div className={style['empty-viewer']}>
        <h4>{translate('mainDocument')}</h4>
        <BrassIcon icon={faReplyAll} size='3x' rotation={180} />
      </div>
    </div>
  ),
);

export { EmptyViewer };
