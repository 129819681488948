import React, { ReactElement } from 'react';
import style from './ProgressBar.module.scss';

interface ProgressBarV1Props {
  percent: number;
  color?: string;
  top?: string;
  left?: string;
  styleProgress?: React.CSSProperties;
}

const ProgressBarV1 = (props: ProgressBarV1Props | any): ReactElement => {
  const { percent, color, top, left, styleProgress } = props;

  return (
    <div
      style={styleProgress}
      className={style.progress}
      role='progressbar'
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={percent ?? 0}
      aria-disabled='false'
      dir='ltr'
      {...props}
    >
      <div
        style={{
          position: 'relative',
          background:
            Math.round(percent ?? 0) < 100 ? (color ? color : '#de8c4e') : '#9AC06D',
          height: '100%',
          borderRadius: '8px',
          width: `${percent}%`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: top ? top : '-20px',
            left: left
              ? left
              : Math.round(percent ?? 0) < 15
              ? '100%'
              : Math.round(percent ?? 0) < 100
              ? `calc(100% - 30px)`
              : 'calc(100% - 36px)',
          }}
          {...props.percentProps}
        >
          {percent ?? 0}%
        </div>
      </div>
    </div>
  );
};

export { ProgressBarV1 };
