import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { BrassTooltip } from '../../../components/brass';

// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
// https://fontawesome.com/v5.15/icons

interface BrassIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icon?: IconProp;
}

const BrassIcon: FunctionComponent<BrassIconProps> = (props: BrassIconProps) => {
  const {
    icon,
    color,
    spin,
    pulse,
    rotation,
    size,
    flip,
    pull,
    className,
    title,
    swapOpacity,
  } = props;
  const _className = classnames('brass-icon', className);

  if (title) {
    return (
      <BrassTooltip>
        <FontAwesomeIcon
          icon={icon!}
          className={_className}
          color={color}
          spin={spin}
          pulse={pulse}
          flip={flip}
          size={size}
          pull={pull}
          title={title}
          rotation={rotation}
          swapOpacity={swapOpacity}
          style={{ cursor: 'pointer' }}
          {...props}
        />
      </BrassTooltip>
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={icon!}
        className={_className}
        color={color}
        spin={spin}
        pulse={pulse}
        flip={flip}
        size={size}
        pull={pull}
        title={title}
        rotation={rotation}
        swapOpacity={swapOpacity}
        {...props}
      />
    );
  }
};

export { BrassIcon };
export type { BrassIconProps };
