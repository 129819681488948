// import { EnvironmentService, EncryptionService } from '../services';
// mport { BaseService } from './base.service';

class LocalStorageService {
  public static NEW_FEATURES = 'NEW_FEATURES';
  public static getLocalStorageEncrypt = (name: string): string => name;
  public static getLocalStorageDecrypt = (name: string): string => name;
  // const getLocalStorageEncrypt = (name: string): string => (EnvironmentService.isDev ? name : EncryptionService.toBase64Loop(name, 4));
  // const getLocalStorageDecrypt = (name: string): string => (EnvironmentService.isDev ? name : EncryptionService.fromBase64Loop(name, 4));

  public static getLocalStorage = (name: string): string | undefined => {
    const result =
      localStorage.getItem(LocalStorageService.getLocalStorageEncrypt(name)) || '';
    return LocalStorageService.getLocalStorageDecrypt(result);
  };

  public static getLocalStorageJSON = <T,>(name: string): T | undefined => {
    const result = LocalStorageService.getLocalStorage(name) || '';

    if (!result || result === '') {
      return undefined;
    }

    if (typeof result === 'object') {
      return result as T;
    }

    const resultType = JSON.parse(result) as T;
    return resultType;
  };

  public static setLocalStorage = (name: string, value: string): void => {
    localStorage.setItem(
      LocalStorageService.getLocalStorageEncrypt(name),
      LocalStorageService.getLocalStorageEncrypt(value),
    );
  };

  public static setLocalStorageJSON = (name: string, value: any): void =>
    LocalStorageService.setLocalStorage(name, JSON.stringify(value));

  public static removeLocalStorage = (name: string): void =>
    localStorage.removeItem(LocalStorageService.getLocalStorageEncrypt(name));

  public static removeAllLocalStorage = (): void => {
    var api = localStorage.getItem('alternative-api');
    console.log('checando api alternativa', api);
    localStorage.clear();
    if (api != null) {
      localStorage.setItem('alternative-api', api);
    }
  };

  public static setNewFeaturesFlag = (features: string[]): void => {
    const newFeatures = LocalStorageService.getLocalStorageJSON<any>(
      LocalStorageService.NEW_FEATURES,
    );
    const _features = {};
    features.forEach((feature: string) => {
      (_features as any)[feature] = !newFeatures
        ? true
        : newFeatures[feature] === undefined
        ? true
        : newFeatures[feature];
    });
    LocalStorageService.setLocalStorageJSON(LocalStorageService.NEW_FEATURES, {
      ...newFeatures,
      ..._features,
    });
  };

  public static unsetNewFeatureFlag = (feature: string): void => {
    const newFeatures = LocalStorageService.getLocalStorageJSON<any>(
      LocalStorageService.NEW_FEATURES,
    );
    const _features = { ...newFeatures };
    _features[feature] = false;
    LocalStorageService.setLocalStorageJSON(LocalStorageService.NEW_FEATURES, {
      ..._features,
    });
  };

  public static getNewFeatureFlagState = (feature: string): boolean => {
    const newFeatures = LocalStorageService.getLocalStorageJSON<any>(
      LocalStorageService.NEW_FEATURES,
    );
    if (!newFeatures) {
      return false;
    } else {
      return newFeatures[feature];
    }
  };

  public static getSavedDate = (name: string): Date => {
    if (localStorage.getItem(name) != null) {
      var savedate = `${localStorage.getItem(name)}`;
      return new Date(savedate);
    }

    return new Date();
  };

  public static saveDateToLocalstorage = (name: string, date: Date | null): void => {
    console.log('salvando data', date);
    localStorage.setItem(name, date!.toISOString());
  };
}

export { LocalStorageService };
