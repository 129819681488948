import React from 'react';
import { AppRoute } from '../../models';
import { LoginRedirect, AutoLogin } from './components';
import { ChangePassword } from '../auth/pages/change-password/ChangePassword';
import { AccessDeniedModule } from '../../components/brass/viewer/access-denied-module/AccessDeniedModule';

const getAuthRoutes = (): AppRoute[] => [
  new AppRoute({
    path: '/login',
    component: <LoginRedirect />,
    isPublic: true,
    useMainLayout: false,
  }),
  new AppRoute({
    path: '/auto-login',
    component: <AutoLogin />,
    isPublic: true,
    useMainLayout: false,
  }),
  new AppRoute({
    path: '/auto-login/:auth',
    component: <AutoLogin />,
    isPublic: true,
    useMainLayout: false,
  }),
  new AppRoute({
    path: '/access-denied-module',
    component: <AccessDeniedModule />,
    isPublic: true,
    useMainLayout: false,
  }),
];

const getProfileRoutes = (): AppRoute[] => [
  new AppRoute({
    path: '/profile/change-password',
    component: <ChangePassword />,
    isPublic: true,
  }),
];

export { getAuthRoutes, getProfileRoutes };
