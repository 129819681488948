import { emptyGuid } from '../../util';

class NavigatorFileDocument {
  public id: string = emptyGuid();
  public type: string = '';
  public size: number = 0;

  public cnd: string = '';

  // Nome do Arquivo de Visualização
  public viewer: string = '';

  // Nome do Arquivo de Downloa
  public download: string = '';

  // Caminho do Arquivo utilizado para o Viewer/Downloa/Share
  public key: string = '';

  // Indica que este documento é um ARQUIVO e não podera ter filhos
  public isFile: boolean = false;

  // Indica o Progresso e Status de CONVERSÃO do documento
  public conversionFinished: boolean = true;
  public conversionProgress: number = 0;

  constructor(document: Partial<NavigatorFileDocument>) {
    Object.assign(this, document);
  }
}

export { NavigatorFileDocument };
