import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonPrevious: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-previous'
    labelKey='previous'
    tooltipKey='clickToPrevious'
    translations={translations}
    icon={<BrassIcon icon={faChevronLeft} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    previous: 'voltar',
    clickToPrevious: 'haga clic para voltar',
  },
  enUS: {
    previous: 'previous',
    clickToPrevious: 'click to go previous',
  },
  ptBR: {
    previous: 'voltar',
    clickToPrevious: 'clique para voltar',
  },
};

export { BrassButtonPrevious };
