import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconManagement: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M14.531 16.6583C14.531 17.7086 15.3826 18.5602 16.4328 18.5602C17.4831 18.5602 18.3347 17.7086 18.3347 16.6583C18.3347 15.7753 17.7325 15.0331 16.9168 14.8188V12.4363H10.432V12.0133H10.9633V10.8411C11.3273 10.7443 11.6722 10.5997 11.9911 10.4157L12.8197 11.2443L14.3788 9.68529L13.5501 8.85664C13.7342 8.53776 13.8794 8.19284 13.9756 7.82884H15.1477V5.62418H13.9756C13.8794 5.26018 13.7349 4.91526 13.5501 4.59638L14.3788 3.76793L12.8197 2.20861L11.9911 3.03751C11.6722 2.85326 11.3273 2.70868 10.9633 2.61186V1.43973H8.75858V2.61186C8.39458 2.70874 8.04967 2.85326 7.73078 3.03751L6.90233 2.20861L5.34302 3.76793L6.17192 4.59638C5.98767 4.91526 5.84262 5.26018 5.74627 5.62418H4.57413V7.82884H5.74627C5.84314 8.19284 5.98767 8.53776 6.17192 8.85664L5.34302 9.68529L6.90233 11.2443L7.73078 10.4157C8.04967 10.5997 8.39458 10.7447 8.75858 10.8411V12.0133H9.44458V12.4363H3.07342V14.8215C2.26267 15.0388 1.66528 15.7789 1.66528 16.6583C1.66528 17.7086 2.51685 18.5602 3.56712 18.5602C4.61738 18.5602 5.46895 17.7086 5.46895 16.6583C5.46895 15.7789 4.87155 15.0388 4.06082 14.8215V13.4238H9.44448V14.839C8.66493 15.0766 8.098 15.8014 8.098 16.6583C8.098 17.7086 8.94957 18.5602 9.99983 18.5602C11.0501 18.5602 11.9017 17.7086 11.9017 16.6583C11.9017 15.7566 11.274 15.0012 10.4319 14.8057V13.4238H15.9292V14.8238C15.1235 15.0447 14.5308 15.7824 14.5308 16.6583L14.531 16.6583ZM9.86117 4.90116C10.8691 4.90116 11.6867 5.71848 11.6867 6.72666C11.6867 7.73461 10.8691 8.55216 9.86117 8.55216C8.85297 8.55216 8.03567 7.73458 8.03567 6.72666C8.03567 5.71846 8.85298 4.90116 9.86117 4.90116ZM9.86117 4.00494C11.3648 4.00494 12.5828 5.22291 12.5828 6.72661C12.5828 8.23019 11.3646 9.44828 9.86117 9.44828C8.35738 9.44828 7.1395 8.23004 7.1395 6.72661C7.1395 5.22283 8.35747 4.00494 9.86117 4.00494V4.00494Z' />
  </svg>
);

export { SVGIconManagement };
