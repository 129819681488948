import React, { FunctionComponent } from 'react';
import {
  FileClassificationDropdown,
  FileClassificationDropdownProps,
} from '../../../../';

const FileClassificationField: FunctionComponent<FileClassificationDropdownProps> = (
  props: FileClassificationDropdownProps,
) => (
  <FileClassificationDropdown
    {...props}
    name='fileClassificationId'
    style={{ width: 390 }}
  />
);

export { FileClassificationField };
