import React, { Fragment, FunctionComponent, useState } from 'react';
import style from './MaskedTextBoxField.module.scss';
import { translate } from './MaskedTextBoxField.translations';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassMaskedTextBox, BrassMaskedTextBoxProps } from '../../../inputs';
import { BrassError } from '../../../labels/Error';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';

interface MaskedTextBoxFieldInputProps extends BrassMaskedTextBoxProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}
const MaskedTextBoxFieldInput: FunctionComponent<MaskedTextBoxFieldInputProps> = (
  fieldRenderProps: any,
) => {
  const {
    maxLength,
    validationMessage,
    value,
    disabled,
    onChange,
    visited,
    notValidationMessage,
  } = fieldRenderProps;

  const [internalValue, setInternalValue] = useState<string>(value || '');
  const onInternalChange = (e: MaskedTextBoxChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value);
  };

  return (
    <Fragment>
      <BrassMaskedTextBox
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
        style={{
          borderColor: visited && validationMessage ? 'red' : 'rgba(0, 0, 0, 0.12)',
        }}
      />
      {visited && validationMessage && !notValidationMessage && (
        <BrassError>{validationMessage}</BrassError>
      )}
      {maxLength && !validationMessage && (
        <div className={style['div-messages-info']}>
          {internalValue.length || 0} / {maxLength}
        </div>
      )}
    </Fragment>
  );
};

interface BrassMaskedTextBoxFieldProps
  extends Omit<BrassFieldProps & MaskedTextBoxFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}
const BrassMaskedTextBoxField: FunctionComponent<BrassMaskedTextBoxFieldProps> = (
  props: any,
) => {
  const { name, required, loading, validator } = props;

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassMaskedTextBox}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : MaskedTextBoxFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label*/}
      />
    </FieldWrapper>
  );
};

export { BrassMaskedTextBoxField };
export type { BrassMaskedTextBoxFieldProps };
