import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconAccepted: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.43202 0.335165C0.829939 0.366805 0.335022 0.882231 0.335022 1.49076V12.5146C0.335022 13.1428 0.862439 13.6653 1.49062 13.6653H12.5145C13.1426 13.6653 13.6652 13.1428 13.6652 12.5146V1.49076C13.6652 0.862581 13.1426 0.335165 12.5145 0.335165H1.49062C1.47103 0.335165 1.45143 0.334123 1.43203 0.335165H1.43202ZM2.00169 2.00183H11.9969V11.997H2.00169V2.00183ZM9.18586 4.7085C8.96404 4.70856 8.75142 4.79704 8.59504 4.95427L6.27087 7.2801L5.40336 6.41258C5.24698 6.25535 5.03434 6.16695 4.81254 6.16695C4.59074 6.16695 4.37811 6.25536 4.22172 6.41258C4.06645 6.56877 3.97934 6.77996 3.97934 7.00015C3.97934 7.2204 4.06645 7.4316 4.22172 7.58772L5.68006 9.04441C5.8365 9.20164 6.04914 9.29005 6.27087 9.29005C6.49261 9.29005 6.70524 9.20164 6.86169 9.04441L9.77669 6.12942C9.93196 5.9733 10.0191 5.7621 10.0191 5.54185C10.0191 5.32166 9.93196 5.11046 9.77669 4.95428C9.62031 4.79705 9.40767 4.70858 9.18587 4.70851L9.18586 4.7085Z'
      fill='white'
    />
  </svg>
);

export { SVGIconAccepted };
