import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { FieldProps, FieldWrapper } from '@progress/kendo-react-form';
import {
  BrassDropdownTree,
  BrassError,
  BrassField,
  BrassLoader,
  BrassRadioGroupButtonProps,
} from '../../..';
import { DropDownTreeData, II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';
import { DropDownTreeChangeEvent } from '@progress/kendo-react-dropdowns';

const translations: II18n = {
  es: {
    required: 'Este campo é obrigatório',
  },
  enUS: {
    required: 'This field is required',
  },
  ptBR: {
    required: 'Este campo é obrigatório',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface DropdownTreeProps {
  value: string;
  text: string;
  children?: DropdownTreeProps[];
}

interface BrassDropdownTreeFieldInputProps extends BrassRadioGroupButtonProps {
  value?: string;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const BrassDropdownTreeFieldInput: FunctionComponent<
  BrassDropdownTreeFieldInputProps
> = (props: any) => {
  const { validationMessage, disabled, visited, value, name, onChange, ...others } =
    props;

  let values: any;
  const findEntireObject = (tree: DropDownTreeData[]): any => {
    tree.forEach((treeElement: DropDownTreeData) => {
      if (props.defaultValue) {
        if (props.defaultValue && props.defaultValue.includes(treeElement.value)) {
          values = treeElement;
        }
      } else if (treeElement.children) {
        findEntireObject(treeElement.children);
      }
    });
    return;
  };

  findEntireObject(props.data);

  const onValueChange = useCallback(
    (newValue: any) => {
      onChange({ value: newValue.value });
    },
    [onChange, value],
  );

  return (
    <Fragment>
      <BrassDropdownTree
        {...others}
        disabled={disabled}
        name={name}
        onChange={onValueChange}
        value={value}
      />
      {visited && validationMessage && <BrassError>{validationMessage}</BrassError>}
    </Fragment>
  );
};

interface BrassDropdownTreeFieldProps extends Omit<FieldProps, 'component'> {
  label?: string;
  value?: string;
  visited?: boolean;
  disabled?: boolean;
  required?: boolean;
  data: DropdownTreeProps[];
  leftButtonIcon?: any;
  onLeftButtonClick?: () => any;
  selectParentItem?: boolean;
  textField?: string;
  dataItemKey?: string;
  onChange?: (event: DropDownTreeChangeEvent) => void;
}

const BrassDropdownTreeField: FunctionComponent<BrassDropdownTreeFieldProps> = (
  props: BrassDropdownTreeFieldProps,
) => {
  const requiredValidator = (value: any) => {
    if (!props.required) {
      return '';
    } else {
      return value && props.required ? '' : translate('required');
    }
  };

  const { name, required, loading, validator } = props;

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassDropdownTree}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : BrassDropdownTreeFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label */}
      />
    </FieldWrapper>
  );
};

export { BrassDropdownTreeField };
export type { BrassDropdownTreeFieldProps };
