import React, { FunctionComponent } from 'react';
import styles from './ChipV1.module.scss';
import { Chip, ChipProps } from '@progress/kendo-react-buttons';

interface BrassChipPropsV1 extends ChipProps {
  width?: string;
}
const BrassChipV1: FunctionComponent<BrassChipPropsV1> = (
  props: BrassChipPropsV1,
) => (
  <Chip
    className={styles['brass-chip']}
    {...props}
    style={{
      border: '1px solid',
      width: props?.width ? props?.width : '90px',
      fontSize: '12px',
      padding: '5px 15px',
    }}
  />
);

export { BrassChipV1 };
export type { BrassChipPropsV1 };
