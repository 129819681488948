import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconIndifferent: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.98785 0.584167C3.03101 0.590808 0.616679 2.977 0.584346 5.94217V5.94223C0.553161 8.9309 2.95485 11.3832 5.94335 11.4157H5.94348C8.93198 11.4469 11.3845 9.04523 11.4156 6.05673V6.05667C11.4481 3.068 9.04648 0.615668 6.05781 0.584501C6.03444 0.584241 6.01113 0.584111 5.98789 0.584241L5.98785 0.584167ZM5.98869 0.960534C6.01037 0.960469 6.03212 0.960599 6.05386 0.960795C8.83903 0.989831 11.0695 3.26746 11.0392 6.05263V6.05269C11.0102 8.83786 8.7327 11.0684 5.94736 11.0394H5.94723C3.16207 11.0091 0.931565 8.73153 0.960565 5.94636V5.94629C0.990644 3.1828 3.2329 0.966795 5.98857 0.960628L5.98869 0.960534Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.19185 4.38716C3.80207 4.38378 3.4772 4.69986 3.47382 5.09061L3.47375 5.09068C3.47037 5.48105 3.78652 5.80481 4.17642 5.80865V5.80871H4.177H4.1772C4.56737 5.8134 4.89133 5.49621 4.89523 5.10611V5.10546V5.1052C4.89985 4.71477 4.58208 4.39225 4.19263 4.38717V4.3871H4.19179L4.19185 4.38716ZM4.18775 4.76353H4.18801C4.37524 4.76607 4.52141 4.91483 4.51893 5.1011H4.51886V5.10201C4.51723 5.28834 4.36762 5.43482 4.18129 5.43234H4.18136H4.18051C3.9936 5.43071 3.84855 5.28299 3.85018 5.09399C3.85181 4.90792 4.00077 4.76241 4.18768 4.76366L4.18775 4.76353Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.8415 4.42617C7.45107 4.42155 7.12855 4.73932 7.12347 5.12884V5.12878V5.12956C7.12009 5.51914 7.4357 5.84376 7.82614 5.84759V5.84766H7.82672H7.82692C8.21787 5.85234 8.54112 5.53464 8.54495 5.14499V5.14434V5.14408C8.54957 4.75391 8.23245 4.42994 7.84235 4.42604V4.42598H7.84177H7.84151L7.8415 4.42617ZM7.8374 4.80254H7.83825C8.02458 4.80417 8.17107 4.95378 8.16858 5.14011V5.14082C8.16695 5.32774 8.01904 5.47364 7.83102 5.47116V5.47122H7.83023C7.64417 5.4696 7.49865 5.32064 7.4999 5.13366V5.1334C7.50244 4.94616 7.65114 4.8 7.8374 4.80248L7.8374 4.80254Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.19585 7.19633C6.96415 7.19627 6.731 7.2199 6.50159 7.26958C6.50152 7.26958 6.50139 7.26964 6.50126 7.26964C5.76714 7.4298 5.20939 7.82862 4.93544 8.35396V8.35389C4.91142 8.39829 4.90621 8.45038 4.92093 8.49862C4.93571 8.54686 4.96917 8.58716 5.01389 8.61047C5.05862 8.63384 5.11083 8.6382 5.15882 8.62271C5.2068 8.60721 5.24658 8.57303 5.26917 8.52798C5.482 8.11991 5.93532 7.7785 6.58115 7.63748C6.58128 7.63748 6.58135 7.63742 6.58148 7.63742C7.22724 7.49777 7.92308 7.5938 8.49048 7.8587C8.53586 7.88064 8.58813 7.88357 8.63559 7.86671C8.68312 7.84978 8.72186 7.81462 8.74315 7.76892C8.7645 7.72328 8.76658 7.67094 8.749 7.62374C8.73143 7.57647 8.69568 7.53826 8.64966 7.51762C8.20832 7.31164 7.70552 7.19652 7.19582 7.19633H7.19585Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconIndifferent };
