import { Label } from '@progress/kendo-react-labels';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrassCheckBox } from '../../../components/brass';
import { DocumentModel } from '../../../models';
import { BrassViewFilesCarouselWindow } from '../view-files-window';
import style from './ListFiles.module.scss';
import { translate } from './ListFiles.translations';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentPDF: any = Document as any;
const PagePDF: any = Page as any;
interface IOpenModal {
  items: DocumentModel[];
  open: boolean;
  positionFile: number;
}

interface BrassListFilesProps {
  fileList: DocumentModel[] | any[];
  width?: string;
  onClickCheckBox: (item: any, isChecked: boolean) => void;
  color?: string;
  hideCheckBox?: boolean;
  hideTitle?: boolean;
}

const BrassListFiles: FunctionComponent<BrassListFilesProps> = (
  props: BrassListFilesProps,
) => {
  const { fileList, width, onClickCheckBox, color, hideCheckBox, hideTitle } = props;
  const [openView, setOpenView] = useState<IOpenModal>({
    open: false,
    items: {} as any,
    positionFile: 0,
  });
  const [isCheckedImages, setIsCheckedImages] = useState<boolean>(false);
  const [isCheckedVideo, setIsCheckedVideo] = useState<boolean>(false);
  const [isCheckedDocuments, setIsCheckedDocuments] = useState<boolean>(false);
  const [isChecked2d3d, setIsChecked2d3d] = useState<boolean>(false);
  const [listImages, setListImages] = useState<DocumentModel[]>([]);
  const [listVideos, setListVideos] = useState<DocumentModel[]>([]);
  const [listDocument, setListDocument] = useState<DocumentModel[]>([]);
  const [listImage2d3d, setListImage2d3d] = useState<DocumentModel[]>([]);

  useEffect(() => {
    setListImages(fileList?.filter((item) => item.fileType === 'IMAGE'));
    setListVideos(fileList?.filter((item) => item.fileType === 'VIDEO'));
    setListDocument(fileList?.filter((item) => item.fileType === 'DOCUMENT'));
    setListImage2d3d(fileList?.filter((item) => item.fileType === 'IMAGE2D3D'));
  }, [fileList]);

  const handleCheckbox = (item: DocumentModel): void => {
    if (item.isChecked === undefined) {
      item.isChecked = true;
    } else {
      item.isChecked = !item.isChecked;
    }
    onClickCheckBox(item?.documentId, item.isChecked);
    if (!item.isChecked) {
      switch (item.fileType.toUpperCase()) {
        case 'IMAGE':
          return setIsCheckedImages(false);
        case 'VIDEO':
          return setIsCheckedVideo(false);
        case 'DOCUMENT':
          return setIsCheckedDocuments(false);
        case 'IMAGE2D3D':
          return setIsChecked2d3d(false);
        default:
          return;
      }
    }
  };

  return (
    <>
      {listImages?.length > 0 && (
        <div>
          <div className={style['box-checkbox']}>
            {!hideCheckBox && (
              <BrassCheckBox
                style={{ display: 'flex' }}
                onClick={() => {
                  const isChecked = !isCheckedImages;
                  setIsCheckedImages(isChecked);
                  listImages.map((item) => {
                    item.isChecked = isChecked;
                    onClickCheckBox(item.documentId, item.isChecked);
                  });
                }}
                checked={isCheckedImages || false}
              />
            )}
            <Label className={style.label} style={{ color: `${color ?? '#000'}` }}>
              {!hideTitle && translate('images') + ':'}
            </Label>
          </div>
          <div className={style.carousel} style={{ width: width ? width : '100%' }}>
            {listImages?.map((item, index) => (
              <div className={style.box}>
                {!hideCheckBox && (
                  <BrassCheckBox
                    style={{ display: 'flex' }}
                    onClick={() => handleCheckbox(item)}
                    checked={item.isChecked ?? false}
                  />
                )}
                <div
                  role={'button'}
                  tabIndex={0}
                  onClick={() =>
                    setOpenView({
                      open: true,
                      items: listImages,
                      positionFile: index,
                    })
                  }
                  onKeyDown={() => {}}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    alt='list images'
                    src={item?.thumbnailUrl ?? item?.fileUrl ?? item?.fileBase64}
                    style={{ objectFit: 'cover', height: 100, width: 100 }}
                    onError={() => {
                      if (item) {
                        item.thumbnailUrl =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                        item.fileUrl =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                        item.fileBase64 =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                      }
                    }}
                  />
                  <p className={style['filename']}>{item?.fileName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {listVideos?.length > 0 && (
        <div>
          <div className={style['box-checkbox']}>
            {!hideCheckBox && (
              <BrassCheckBox
                style={{ display: 'flex' }}
                onClick={() => {
                  const isChecked = !isCheckedVideo;
                  setIsCheckedVideo(isChecked);
                  listVideos.map((item) => {
                    item.isChecked = isChecked;
                    onClickCheckBox(item.documentId, item.isChecked);
                  });
                }}
                checked={isCheckedVideo || false}
              />
            )}
            <Label className={style.label} style={{ color: `${color ?? '#000'}` }}>
              {!hideTitle && translate('video') + ':'}
            </Label>
          </div>
          <div className={style.carousel} style={{ width: width ? width : '100%' }}>
            {listVideos?.map((item, index) => (
              <div className={style.box}>
                {!hideCheckBox && (
                  <BrassCheckBox
                    style={{ display: 'flex' }}
                    onClick={() => handleCheckbox(item)}
                    checked={item.isChecked || false}
                  />
                )}
                <div
                  role={'button'}
                  tabIndex={0}
                  onClick={() =>
                    setOpenView({
                      open: true,
                      items: listVideos,
                      positionFile: index,
                    })
                  }
                  onKeyDown={() => {}}
                  style={{ cursor: 'pointer' }}
                >
                  <video
                    src={item?.fileUrl ?? item?.thumbnailUrl ?? item?.fileBase64}
                    style={{ objectFit: 'cover', height: 100, width: 100 }}
                    onError={() => {
                      if (item) {
                        item.fileUrl =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                        item.fileBase64 =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                        item.thumbnailUrl =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                      }
                    }}
                  >
                    <track default kind='captions' />
                  </video>
                  <p className={style['filename']}>{item?.fileName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {listDocument?.length > 0 && (
        <div>
          <div className={style['box-checkbox']}>
            {!hideCheckBox && (
              <BrassCheckBox
                style={{ display: 'flex' }}
                onClick={() => {
                  const isChecked = !isCheckedDocuments;
                  setIsCheckedDocuments(isChecked);
                  listDocument.map((item) => {
                    item.isChecked = isChecked;
                    onClickCheckBox(item.documentId, item.isChecked);
                  });
                }}
                checked={isCheckedDocuments || false}
              />
            )}
            <Label className={style.label} style={{ color: `${color ?? '#000'}` }}>
              {!hideTitle && translate('document') + ':'}
            </Label>
          </div>
          <div className={style.carousel} style={{ width: width ? width : '100%' }}>
            {listDocument?.map((item, index) => (
              <div className={style.box}>
                {!hideCheckBox && (
                  <BrassCheckBox
                    style={{ display: 'flex' }}
                    onClick={() => handleCheckbox(item)}
                    checked={item.isChecked || false}
                  />
                )}
                <div
                  role={'button'}
                  tabIndex={0}
                  onClick={() =>
                    setOpenView({
                      open: true,
                      items: listDocument,
                      positionFile: index,
                    })
                  }
                  onKeyDown={() => {}}
                  style={{ cursor: 'pointer' }}
                >
                  {item?.fileName?.includes('.pdf') ? (
                    <>
                      <DocumentPDF file={item?.thumbnailUrl}>
                        <PagePDF
                          pageNumber={1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          className={style['custom-preview-pdf']}
                          renderMode='canvas'
                        />
                      </DocumentPDF>
                    </>
                  ) : (
                    <>
                      <img
                        alt='document'
                        src={item?.thumbnailUrl}
                        style={{ objectFit: 'cover', height: 130, width: 100 }}
                        onError={() => {
                          if (item) {
                            item.thumbnailUrl =
                              'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                          }
                        }}
                      />
                    </>
                  )}
                  <p className={style['filename']}>{item?.fileName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {listImage2d3d?.length > 0 && (
        <div>
          <div className={style['box-checkbox']}>
            {!hideCheckBox && (
              <BrassCheckBox
                style={{ display: 'flex' }}
                onClick={() => {
                  const isChecked = !isChecked2d3d;
                  setIsChecked2d3d(isChecked);
                  listImage2d3d.map((item) => {
                    item.isChecked = isChecked;
                    onClickCheckBox(item.documentId, item.isChecked);
                  });
                }}
                checked={isChecked2d3d || false}
              />
            )}
            <Label className={style.label} style={{ color: `${color ?? '#000'}` }}>
              {!hideTitle && translate('images2d3d') + ':'}
            </Label>
          </div>
          <div className={style.carousel} style={{ width: width ? width : '100%' }}>
            {listImage2d3d?.map((item, index) => (
              <div className={style.box}>
                {!hideCheckBox && (
                  <BrassCheckBox
                    style={{ display: 'flex' }}
                    onClick={() => handleCheckbox(item)}
                    checked={item.isChecked || false}
                  />
                )}
                <div
                  role={'button'}
                  tabIndex={0}
                  onClick={() =>
                    setOpenView({
                      open: true,
                      items: listImage2d3d,
                      positionFile: index,
                    })
                  }
                  onKeyDown={() => {}}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    alt='image2d3d'
                    src={item?.thumbnailUrl}
                    style={{ objectFit: 'cover', height: 100, width: 100 }}
                    onError={() => {
                      if (item) {
                        item.thumbnailUrl =
                          'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                      }
                    }}
                  />
                  <p className={style['filename']}>{item?.fileName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {openView.open && (
        <BrassViewFilesCarouselWindow
          onCancelClose={() =>
            setOpenView({ open: false, items: {} as any, positionFile: 0 })
          }
          listFiles={openView.items}
          positionFile={openView.positionFile}
        />
      )}
    </>
  );
};

export { BrassListFiles };
