import Cookies, { CookieSetOptions } from 'universal-cookie';
import { EnvironmentService } from './environment.service';
// import { EnvironmentService } from './environment.service';
// import { EncryptionService } from './encryption.service';

class CookieService {
  public static cookieAuthUser: string = 'user';
  public static cookiePermissions: string = 'permissions';
  public static cookies: Cookies = new Cookies();

  constructor() {}

  public static encryptCookie(name: string): string {
    return name;
    // EnvironmentService.isDev ? name : EncryptionService.toBase64Loop(name, 4);
  }

  public static decryptCookie(name: string): string {
    return name;
    /*
    return EnvironmentService.isDev
      ? name
      : EncryptionService.fromBase64Loop(name, 4);
      */
  }

  public static getCookie(name: string): string | undefined {
    const result = CookieService.cookies.get(name);

    /*
    RLZ: Não esta funcionando este em PRODUÇÃO
    if (result) {
      return CookieService.decryptCookie(result);
    }
    */

    return result;
  }

  public static setCookie(name: string, value: string): void {
    // 1 Day = 24 Hrs = 24*60*60 = 86400.
    document.cookie = `${name}=${value}; SameSite=lax; Secure; Domain=.bdbsystem.com.br; max-age=86400; Path=/;`;

    if (EnvironmentService.isDev) {
      document.cookie = `${name}=${value}; Domain=localhost; max-age=86400; Path=/;`;
    }
  }

  public static removeCookie(name: string): void {
    document.cookie = `${name}=; Domain=.bdbsystem.com.br; Path=/;`;

    if (EnvironmentService.isDev) {
      document.cookie = `${name}=; Domain=localhost Path=/;`;
    }

    CookieService.cookies.remove(name);
  }

  public static removeAllCookie(): void {
    document.cookie.split(';').forEach(function (c) {
      const name = c.split('=')[0]?.replace(' ', '');
      document.cookie = `${name}=;expires=${new Date().toUTCString()};Domain=.bdbsystem.com.br; Path=/;`;
    });
  }

  public static getCookieJSON<T>(name: string): T | undefined {
    const result = CookieService.getCookie(name) || '';
    if (!result || result === '' || result === 'undefined') {
      return undefined;
    }

    if (typeof result === 'object') {
      return result as T;
    }

    const resultType = JSON.parse(result) as T;
    return resultType;
  }

  public static setCookieJSON(name: string, value: any): void {
    CookieService.setCookie(name, JSON.stringify(value));
  }
}

export { CookieService };
