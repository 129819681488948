import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Scheduler, SchedulerProps } from '@progress/kendo-react-scheduler';

interface BrassSchedulerProps extends SchedulerProps {}

const BrassScheduler: FunctionComponent<BrassSchedulerProps> = (
  props: BrassSchedulerProps,
) => {
  const { className } = props;
  const _className = classnames('brass-scheduler', className);

  return <Scheduler {...props} className={_className} />;
};

export { BrassScheduler };
export type { BrassSchedulerProps };
