import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const BrassButtonCollapseDown: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-collapse-down'
    labelKey='close'
    tooltipKey='closeTooltip'
    translations={translations}
    icon={<BrassIcon icon={faChevronDown} size='2x' />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    close: 'Cerca',
    closeTooltip: 'Haga clic aquí para cerrar/minimizar',
  },
  enUS: {
    close: 'Close',
    closeTooltip: 'Click here to close/minimize',
  },
  ptBR: {
    close: 'Fechar',
    closeTooltip: 'Clique aqui para fechar/minimizar',
  },
};

export { BrassButtonCollapseDown };
