import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import {
  ChartCategoryAxis,
  ChartCategoryAxisProps,
} from '@progress/kendo-react-charts';

interface BrassChartCategoryAxisProps
  extends ChartCategoryAxisProps,
    PropsWithChildren<any> {}

const BrassChartCategoryAxis: FunctionComponent<BrassChartCategoryAxisProps> = (
  props: BrassChartCategoryAxisProps,
) => {
  const { className } = props;
  const _className = classnames('brass-chart-category-axis', className);
  return (
    <div className={_className}>
      <ChartCategoryAxis {...props} />
    </div>
  );
};

export { BrassChartCategoryAxis };
export type { ChartCategoryAxisProps };
