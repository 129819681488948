import React from 'react';
import { Playgroud } from './';
import { AppRoute } from '../models';
import { EnvironmentService } from '../services';

const getPlaygroundRoutes = (): AppRoute[] => {
  if (EnvironmentService.isDev) {
    return [
      new AppRoute({
        path: '/playground',
        component: <Playgroud />,
      }),
    ];
  }

  return [];
};

export { getPlaygroundRoutes };
