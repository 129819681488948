import React, { FunctionComponent, useEffect } from 'react';
import { BrassDropDownField, BrassDropDownFieldProps } from '../../../..';
import { II18n, DropDownData } from '../../../../../models';
import { I18nService } from '../../../../../services';
import { LanguageEnum, Language } from '../../../../../models/enum';

const translations: II18n = {
  es: {
    language: 'Idioma',
    english: 'Inglés',
    portuguese: 'Portugués',
    spanish: 'Español',
  },
  enUS: {
    language: 'Language',
    english: 'English',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
  },
  ptBR: {
    language: 'Linguagem',
    english: 'Inglês',
    portuguese: 'Português',
    spanish: 'Espanhol',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface LanguagesFieldProps extends BrassDropDownFieldProps {
  useOldEnum?: boolean;
}

const LanguagesField: FunctionComponent<LanguagesFieldProps> = (
  props: LanguagesFieldProps,
) => {
  let options: DropDownData[];
  if (props.useOldEnum) {
    options = [
      { text: translate('portuguese'), value: LanguageEnum.PTBR },
      { text: translate('spanish'), value: LanguageEnum.ES },
      { text: translate('english'), value: LanguageEnum.EN },
    ];
  } else {
    options = [
      { text: translate('portuguese'), value: Language.PTBR },
      { text: translate('spanish'), value: Language.ES },
      { text: translate('english'), value: Language.EN },
    ];
  }

  return (
    <BrassDropDownField
      {...props}
      name={props.useOldEnum ? 'selectedLanguage' : 'language'}
      options={options}
      label={translate('language')}
    />
  );
};

LanguagesField.defaultProps = {
  useOldEnum: false,
};

export { LanguagesField };
