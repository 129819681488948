import {
    // ApiService,
    ApiResult,
    // Endpoints,
    DropDownData,
} from '../';
import { Endpoints } from '../models';
import { ApiService } from './api.service';

class FileTypeService {
  private static apiNavigator: ApiService = new ApiService(Endpoints.NAVIGATOR);

  public static async getDropdown(): Promise<ApiResult<DropDownData<any>[]>> {
    const api = this.apiNavigator;

    return api.get(`/connect/v1/filetype/dropdown`);
  }
}

export { FileTypeService };
