import { ApiResult, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { UserProfileModel } from '../models';

class UserProfileService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);
  private static apiResult: ApiResult = {
    data: {},
    success: true,
    environment: 'dev',
    utcTime: new Date().toUTCString(),
    message: [],
  };

  public static async getUserProfile(): Promise<ApiResult<UserProfileModel>> {
    return await this.api.get<UserProfileModel>(`/connect/v1/user-profile`);
  }

  public static async updateUserProfile(
    userProfile: UserProfileModel,
  ): Promise<ApiResult> {
    return await new Promise((res) => {
      console.log(userProfile);
      res(this.apiResult);
    });
    // return await this.api.put(`/connect/v1/user-profile`, userProfile);
  }

  public static async saveUserLanguage(language: string): Promise<ApiResult> {
    return await UserProfileService.api.put(`/connect/v1/user-profile`, { language });
  }
}

export { UserProfileService };
