import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { SgiFeaturesPermission, SgiModulePermission } from './Sgi.submodule.enum';

enum SgiPermissionsV1 {
  SGI_MODULE = PermissionModule.SGI,

  /// <summary>
  /// Área
  /// </summary>
  SGI_AREA_READ = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.READ,
  SGI_AREA_CREATE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.CREATE,
  SGI_AREA_UPDATE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.UPDATE,
  SGI_AREA_DELETE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_AREA_SYSTEM +
    Action.DELETE,

  /// <summary>
  /// Content
  /// </summary>
  SGI_CONTENT_READ = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.READ,
  SGI_CONTENT_UPDATE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.UPDATE,
  SGI_CONTENT_DELETE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.DELETE,
  SGI_CONTENT_UPLOAD = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.UPLOAD,
  SGI_CONTENT_PUBLISH = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.PUBLISH,
  SGI_CONTENT_SUSPEND = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.SUSPEND,
  SGI_CONTENT_ACTIVE = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.ACTIVE,
  SGI_CONTENT_BLOCK = PermissionModule.SGI +
    SgiModulePermission.MANAGEMENT_CONTENT +
    SgiFeaturesPermission.MANAGEMENT_CONTENT +
    Action.BLOCK,

  /// <summary>
  /// LessonLearned
  /// </summary>
  SGI_LESSONLEARNED_READ = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.LESSON_LEARNED +
    Action.READ,
  SGI_LESSONLEARNED_UPDATE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.LESSON_LEARNED +
    Action.UPDATE,
  SGI_LESSONLEARNED_DELETE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.LESSON_LEARNED +
    Action.DELETE,
  SGI_LESSONLEARNED_UPLOAD = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.LESSON_LEARNED +
    Action.UPLOAD,
  SGI_LESSONLEARNED_KEY_USER = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.LESSON_LEARNED +
    Action.KEY_USER,

  /// <summary>
  /// Suggestion
  /// </summary>
  SGI_SUGGESTION_READ = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.READ,
  SGI_SUGGESTION_UPDATE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.UPDATE,
  SGI_SUGGESTION_DELETE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.DELETE,
  SGI_SUGGESTION_APPROVE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.APPROVE,
  SGI_SUGGESTION_LIKE = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.LIKE,
  SGI_SUGGESTION_COMMENT = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.COMMENT,
  SGI_SUGGESTION_KEY_USER = PermissionModule.SGI +
    SgiModulePermission.LESSON_LEARNED +
    SgiFeaturesPermission.MANAGEMENT_SUGESTION +
    Action.KEY_USER,

  /// <summary>
  /// Painel do usuário - Visualização de conteúdos
  /// </summary>
  SGI_USER_PAINEL_VISUALIZATION_CONTENT_READ = PermissionModule.SGI +
    SgiModulePermission.USER_PAINEL +
    SgiFeaturesPermission.USER_PAINEL_VISUALIZATION_CONTENT +
    Action.READ,

  /// <summary>
  /// Painel do usuário - Lições aprendidas
  /// </summary>
  SGI_USER_PAINEL_LESSON_LEARNED_READ = PermissionModule.SGI +
    SgiModulePermission.USER_PAINEL +
    SgiFeaturesPermission.USER_PAINEL_LESSON_LEARNED +
    Action.READ,
  SGI_USER_PAINEL_LESSON_LEARNED_COMMENT = PermissionModule.SGI +
    SgiModulePermission.USER_PAINEL +
    SgiFeaturesPermission.USER_PAINEL_LESSON_LEARNED +
    Action.COMMENT,
  SGI_USER_PAINEL_LESSON_LEARNED_LIKE = PermissionModule.SGI +
    SgiModulePermission.USER_PAINEL +
    SgiFeaturesPermission.USER_PAINEL_LESSON_LEARNED +
    Action.LIKE,
}

export { SgiPermissionsV1 };
