import React, { FunctionComponent, useState, memo } from 'react';
import { translate } from './UserStatusDropDownButton.translations';
import { DropDownData } from '../../../../models';
import {
  BrassDropDownButton,
  BrassDropDownButtonProps,
} from '../../../../components';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { UserStatusEnum } from '../../models/enum/UserStatus.enum';

interface UserStatusDropdownButtonProps extends BrassDropDownButtonProps {
  onSelectItem: (userStatus: UserStatusEnum) => void;
}

const UserStatusDropdownButton: FunctionComponent<UserStatusDropdownButtonProps> =
  memo((props: UserStatusDropdownButtonProps) => {
    const userStatus: DropDownData[] = [
      {
        value: UserStatusEnum.ALL,
        text: translate(`USER_FILTER_${UserStatusEnum.ALL}`).toUpperCase(),
      },
      {
        value: UserStatusEnum.ACTIVE,
        text: translate(`USER_FILTER_${UserStatusEnum.ACTIVE}`).toUpperCase(),
      },
      {
        value: UserStatusEnum.CREATED,
        text: translate(`USER_FILTER_${UserStatusEnum.CREATED}`).toUpperCase(),
      },
      {
        value: UserStatusEnum.SUSPENDED,
        text: translate(`USER_FILTER_${UserStatusEnum.SUSPENDED}`).toUpperCase(),
      },
      {
        value: UserStatusEnum.BLOCKED,
        text: translate(`USER_FILTER_${UserStatusEnum.BLOCKED}`).toUpperCase(),
      },
    ];

    const [selectedUserStatus, setSelectedUserStatus] = useState<DropDownData>(
      userStatus.find((p) => p.value === UserStatusEnum.ALL)!,
    );

    return (
      <BrassDropDownButton
        key='UserStatusDropdownButton'
        fillMode='outline'
        textField='text'
        items={userStatus.filter((f) => f !== selectedUserStatus)}
        text={selectedUserStatus.text}
        onItemClick={(event: DropDownButtonItemClickEvent) => {
          setSelectedUserStatus(event.item);
          props.onSelectItem(event.item.value);
        }}
        {...props}
      />
    );
  });

export { UserStatusDropdownButton };
export type { UserStatusDropdownButtonProps };
