import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconMap: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.48 18.8L16.22 10.38C16.16 10.14 15.94 9.96 15.68 9.96H10.26C11.28 8.02 12.14 6.04 12.14 4.94C12.14 2.5 10.16 0.5 7.70003 0.5C5.24003 0.5 3.26003 2.48 3.26003 4.94C3.26003 6.04 4.12003 8.02 5.14003 9.96H4.34003C4.08003 9.96 3.86003 10.14 3.80003 10.38L1.52003 18.8C1.48003 18.96 1.52003 19.14 1.62003 19.28C1.72003 19.42 1.90003 19.5 2.06003 19.5H17.94C18.12 19.5 18.28 19.42 18.38 19.28C18.48 19.14 18.52 18.96 18.48 18.8ZM7.70003 1.62C9.52003 1.62 11.02 3.1 11.02 4.94C11.02 5.94 9.90003 8.48 8.00003 11.76C7.92003 11.92 7.76003 11.94 7.70003 11.94C7.64003 11.94 7.50003 11.92 7.40003 11.76C5.52003 8.48 4.38003 5.94 4.38003 4.94C4.38003 3.1 5.88003 1.62 7.70003 1.62ZM4.76003 11.08H5.74003C5.98003 11.5 6.20003 11.92 6.44003 12.3C6.70003 12.76 7.18003 13.04 7.70003 13.04C8.22003 13.04 8.70003 12.76 8.96003 12.3C9.18003 11.9 9.42003 11.5 9.66003 11.08H11.16L9.64003 14.88L3.68003 15.14L4.76003 11.08ZM3.36003 16.26L10.04 15.98C10.26 15.98 10.46 15.84 10.54 15.62L10.98 14.52L12.64 15.46L13.12 18.36H2.80003L3.36003 16.26ZM14.26 18.38L13.7 15.04C13.68 14.88 13.58 14.72 13.42 14.64L11.4 13.48L12.36 11.08H15.24L17.2 18.38H14.26Z'
      fill={props.fill}
    />
    <path
      d='M9.84 4.94C9.84 3.76 8.88 2.8 7.7 2.8C6.52 2.8 5.56 3.76 5.56 4.94C5.56 6.12 6.52 7.08 7.7 7.08C8.88 7.08 9.84 6.12 9.84 4.94ZM6.68 4.94C6.68 4.38 7.14 3.92 7.7 3.92C8.26 3.92 8.72 4.38 8.72 4.94C8.72 5.5 8.26 5.96 7.7 5.96C7.14 5.94 6.68 5.5 6.68 4.94Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconMap };
