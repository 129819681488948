import React, { FunctionComponent } from 'react';
import style from './ButtonV1.module.scss';
import classnames from 'classnames';
import { Button, ButtonProps } from '@progress/kendo-react-buttons';

interface BrassButtonPropsV1 extends Omit<ButtonProps, 'icon'> {
  icon?: any;
  roundBorder?: boolean;
}

const BrassButtonV1: FunctionComponent<BrassButtonPropsV1> = (
  props: BrassButtonPropsV1,
) => {
  const { className, roundBorder, themeColor } = props;
  const _props = { ...props };
  delete _props.roundBorder;

  const _className = classnames(
    'brass-button',
    style['brass-button'],
    roundBorder && style['brass-button-round'],
    themeColor === 'primary'
      ? style['brass-button-primary']
      : style['brass-button-secundary'],
    className,
  );

  return <Button {..._props} className={_className} />;
};

BrassButtonV1.defaultProps = {
  type: 'submit',
};

export { BrassButtonV1 };
export type { BrassButtonPropsV1 };
