import React from 'react';
import { EnvironmentService } from '../../services/environment.service';
import { BrassIconHome } from '../../components';
import { Breadcrumb, ModuleUrlEnum } from '../../models';
import { getRedirectPlatformUrl } from '../../util';

interface BreadcrumbState {
  breadcrumbs: Breadcrumb[];
}

let route = EnvironmentService.isConnect
  ? '/home'
  : EnvironmentService.isNavigator || EnvironmentService.isNavigatorIntegrator
  ? '/integrator/home'
  : EnvironmentService.isModule
  ? getRedirectPlatformUrl(ModuleUrlEnum.CONNECT)
  : '/home';

const initialBreadcrumbState: BreadcrumbState = {
  breadcrumbs: [
    new Breadcrumb({
      title: 'Home',
      route: route,
      disableTranslator: true,
      // icon: <BrassIconHome />,
    }),
  ],
};

export { initialBreadcrumbState };
export type { BreadcrumbState };
