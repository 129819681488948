import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconLink: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.42314 8.07867L5.16911 9.33264C4.47718 10.0246 3.35311 10.0246 2.66111 9.33264C1.96873 8.64032 1.96873 7.51664 2.66111 6.82431L3.91469 5.57072C4.14496 5.34006 4.14496 4.96611 3.91469 4.73551C3.68443 4.50524 3.31014 4.50524 3.07986 4.73551L1.82589 5.98947C0.673026 7.14234 0.673026 9.0148 1.82589 10.1675C2.97914 11.3203 4.85106 11.3203 6.00439 10.1675L7.25804 8.91389C7.48824 8.68322 7.48824 8.30934 7.25804 8.07867C7.02778 7.84841 6.65343 7.84841 6.42321 8.07867H6.42314ZM7.25876 3.89984L3.91543 7.24317C3.68523 7.47344 3.68523 7.84772 3.91543 8.07801C4.14609 8.30867 4.52004 8.30867 4.75026 8.07801L8.09359 4.73467C8.32419 4.50447 8.32419 4.13012 8.09359 3.89984C7.86333 3.66964 7.48898 3.66964 7.25876 3.89984ZM5.58626 3.89906L6.83991 2.64547C7.53223 1.95309 8.65591 1.95309 9.34824 2.64547C10.0402 3.33741 10.0402 4.46147 9.34824 5.15347L8.09466 6.40712C7.86399 6.63772 7.86399 7.01167 8.09466 7.24234C8.32486 7.47254 8.69921 7.47254 8.92949 7.24234L10.1831 5.98869C11.3363 4.83544 11.3363 2.96352 10.1831 1.81019C9.03021 0.657325 7.15791 0.657325 6.00508 1.81019L4.75143 3.06377C4.52083 3.29444 4.52083 3.66839 4.75143 3.89899C4.98169 4.12926 5.35604 4.12926 5.58626 3.89899V3.89906Z'
      fill={props.fill}
    />
  </svg>
);

export { SVGIconLink };
