import { I18nService } from '../../../../services';
import { II18n } from '../../../../models';

const translations: II18n = {
  es: {
    converting: 'mudada',
    clickToOpen: 'haga clic para abrir',
    edit: 'Editar',
    delete: 'Borrar',
    promoteInitialView: 'Promocionar la vista inicial',
  },
  enUS: {
    converting: 'converting',
    clickToOpen: 'click to open',
    edit: 'Edit',
    delete: 'Delete',
    promoteInitialView: 'Promote Initial View',
  },
  ptBR: {
    converting: 'convertendo',
    clickToOpen: 'clique para abrir',
    edit: 'Editar',
    delete: 'Apagar',
    promoteInitialView: 'Tornar visão inicial',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate, translations };
