import React, { FunctionComponent, useContext, memo, Fragment } from 'react';
import { ViewerContext } from '../../../../store/';
import style from './Viewer.module.scss';
import classnames from 'classnames';
import { MainViewer } from './main-viewer/MainViewer';
import { BottomDetailsViewer } from './bottom-details-viewer/BottomDetailsViewer';
import { BrassSplitter } from '../../../';
// import { SidePanelViewer } from './main-viewer/side-viewer/SidePanelViewer';

interface ViewerProps {
  sidePanelViewer: FunctionComponent;
}

const Viewer: FunctionComponent<ViewerProps> = memo((props: ViewerProps) => {
  const { showDetails, showSidePanel, loadingViewer } = useContext(ViewerContext);

  const footerSize = showDetails ? '304px' : '78px';

  return (
    <Fragment>
      <props.sidePanelViewer />
      <BrassSplitter
        className={classnames(
          style['brass-plitter-spliter'],
          loadingViewer && style['brass-plitter-spliter-loading'],
        )}
        orientation='vertical'
        panes={[
          {},
          {
            resizable: false,
            size: footerSize,
          },
        ]}
      >
        <div style={{ width: '100vw' }}>
          <MainViewer />
        </div>
        <div style={{ width: '100vw' }}>
          <BottomDetailsViewer />
        </div>
      </BrassSplitter>
    </Fragment>
  );
});

export { Viewer };
