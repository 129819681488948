import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { ChartLegend, ChartLegendProps } from '@progress/kendo-react-charts';

interface BrassLegendProps extends ChartLegendProps, PropsWithChildren<any> {}

const BrassChartLegend: FunctionComponent<BrassLegendProps> = (
  props: BrassLegendProps,
) => {
  const { className } = props;
  const _className = classnames('brass-chart-legend', className);
  return (
    <div className={_className}>
      <ChartLegend {...props} />
    </div>
  );
};

export { BrassChartLegend };
export type { BrassLegendProps };
