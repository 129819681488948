import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import classnames from 'classnames';
import {
  FloatingActionButton,
  FloatingActionButtonProps,
} from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { I18nService } from '../../../services';
import { BrassIcon } from '../icon';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const ButtonFloatingAssetManagement: FunctionComponent<FloatingActionButtonProps> = (
  props: FloatingActionButtonProps,
) => {
  const { className } = props;
  const _props = { ...props };
  delete _props.className;

  const _className = classnames(
    'brass-button-floating-asset-management k-fab k-fab-inverse k-fab-pill k-fab-md k-pos-fixed k-bottom-end',
    className,
  );

  return (
    <Tooltip openDelay={100} position='top' anchorElement='target'>
      <FloatingActionButton
        alignOffset={{ x: 40, y: 50 }}
        positionMode='absolute'
        title={translate('assetManagement')}
        themeColor='primary'
        text={(<BrassIcon icon={faPaperPlane} size='2x' />) as any}
        className={_className}
        {...props}
      />
    </Tooltip>
  );
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const translations: II18n = {
  es: {
    assetManagement: 'Haga clic para gerenciar el activo',
  },
  enUS: {
    assetManagement: 'Click to manage the asset',
  },
  ptBR: {
    assetManagement: 'Clique para gerenciar o ativo',
  },
};

export { ButtonFloatingAssetManagement };
