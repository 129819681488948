import Cookies from 'js-cookie';
import { Credentials } from 'aws-sdk';
import { newGuid } from '../util';
import { LocalStorageService } from './localStorage.service';
import { CookieService } from './cookie.service';
import { AuthUser, Language } from '../models';

class BaseService {
  protected static _id: string;
  protected static _userLanguage =
    (CookieService.getCookieJSON<AuthUser>(CookieService.cookieAuthUser) as AuthUser)
      ?.language || Language.DEFAULT;
  protected static _userCurrency = '';
  protected static _userUploadCrendentials: Credentials = {
    accessKeyId: 'AKIA3BO7D7ST5Q3G6OKB',
    secretAccessKey: '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',
  } as Credentials;

  constructor() {
    BaseService._id = newGuid();
  }

  public get id(): string {
    return BaseService._id;
  }

  public static get userLanguage(): string {
    return BaseService._userLanguage;
  }

  public static get userCurrency(): string {
    if (!BaseService._userCurrency) {
      // https://www.w3schools.com/jsref/jsref_tolocalestring_number.asp
      BaseService._userCurrency =
        BaseService.userLanguage === 'pt-BR'
          ? 'BRL'
          : BaseService.userLanguage === 'es'
          ? 'es-US'
          : 'USD';
    }

    return BaseService.userCurrency;
  }

  public static get userUploadCrendentials(): Credentials {
    return BaseService._userUploadCrendentials;
  }
}

export { BaseService };
