import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconCommentsAlt: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    {...props}
    fill={props?.fill ?? '#424242'}
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.66669 14.1667V2.54167C1.66669 2.34722 1.75696 2.15278 1.93752 1.95833C2.11808 1.76389 2.30558 1.66667 2.50002 1.66667H13.3125C13.5209 1.66667 13.7153 1.76042 13.8959 1.94792C14.0764 2.13542 14.1667 2.33333 14.1667 2.54167V9.95833C14.1667 10.1528 14.0764 10.3472 13.8959 10.5417C13.7153 10.7361 13.5209 10.8333 13.3125 10.8333H5.00002L1.66669 14.1667ZM5.85419 15C5.65974 15 5.46877 14.9028 5.28127 14.7083C5.09377 14.5139 5.00002 14.3194 5.00002 14.125V12.0833H15.4167V5H17.5C17.6945 5 17.882 5.09722 18.0625 5.29167C18.2431 5.48611 18.3334 5.6875 18.3334 5.89583V18.3125L15.0209 15H5.85419ZM12.9167 2.91667H2.91669V11.1458L4.47919 9.58333H12.9167V2.91667ZM2.91669 2.91667V11.1458V2.91667Z'
      fill={props?.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconCommentsAlt };
