import React, { FunctionComponent } from 'react';
import { translate } from './EmailField.translations';
import {
  BrassTextField,
  BrassTextFieldProps,
  emailValidator,
} from '../../../../brass';

const BrassEmailField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <BrassTextField
    validator={[emailValidator]}
    label={translate('email')}
    type='email'
    {...props}
  />
);

export { BrassEmailField };
