import React, { FunctionComponent } from 'react';
import {
  BrassTextField,
  BrassTextFieldProps,
  I18nService,
  II18n,
} from '../../../../';

const translations: II18n = {
  es: {
    costCenterLabel: 'Centro de costos',
  },
  enUS: {
    costCenterLabel: 'Cost Center',
  },
  ptBR: {
    costCenterLabel: 'Centro de Custo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const CostCenterField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <BrassTextField
    {...props}
    name='costCenter'
    label={translate('costCenterLabel')}
  />
);

export { CostCenterField };
