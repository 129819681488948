import React, { FunctionComponent, useEffect, useState, memo } from 'react';
import {
  ApiErrorsEnum,
  BrassButtonCancel,
  BrassButtonSave,
  BrassDialog,
  BrassDialogActionsBar,
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  BrassFormRenderProps,
  Notification,
  Account,
  ApiResult,
  notificationActions,
  NotificationStyles,
} from '../../../';
import { AreaTypeEnum } from '../../../models/';
import { useDispatch } from 'react-redux';
import { Area } from '../../../models/viewer/enum/area.model';
import { AreaService } from '../../../services/area.service';
import style from './Area.module.scss';
import { translate } from './Areas.translations';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { TagField } from './upsert-area-form/TagField';
import { CostCenterField } from './upsert-area-form/CostCenterField';
import { NameField } from './upsert-area-form/NameField';
import { DescriptionField } from './upsert-area-form/DescriptionField';

interface UpsertAreaProps {
  show?: boolean;

  onSaveClose?: () => void;
  onCancelClose?: () => void;

  areaId?: string;
  projectId: string;
  type?: AreaTypeEnum;

  titleComplement?: string;
}

const UpsertArea: FunctionComponent<UpsertAreaProps> = memo(
  (props: UpsertAreaProps) => {
    const { onSaveClose, onCancelClose, projectId, areaId, type, titleComplement } =
      props;
    const isUpdate = areaId !== undefined;

    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(isUpdate);

    const _titleComplement = titleComplement ? `: ${titleComplement}` : '';

    const [initialFormValues, setInitialFormValues] = useState<Area>(
      new Area({
        accountId: Account.getId(),
        projectId,
        areaId,
        type,
      }),
    );

    const onSubmitInclude = (_fieldsValue: Area | any): void => {
      setSubmiting(true);
      setLoginError('');

      const fieldsValue = _fieldsValue as Area;

      AreaService.includeArea({
        ...initialFormValues,
        ...fieldsValue,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnInclude'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnInclude'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    const onSubmitUpdate = (_fieldsValue: Area | any): void => {
      setSubmiting(true);
      setLoginError('');
      const fieldsValue = _fieldsValue as Area;

      AreaService.updateArea({
        ...initialFormValues,
        ...fieldsValue,
        projectId,
        id: areaId!,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnUpdate'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnUpdate'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    useEffect(() => {
      if (isUpdate && areaId) {
        AreaService.getArea(projectId, areaId).then((result: ApiResult<Area>) => {
          setInitialFormValues(result.data);
          setLoadingData(false);
        });
      }
    }, [isUpdate, areaId, projectId]);

    return (
      <BrassDialog
        onClose={onCancelClose}
        iconTitle={faEdit}
        title={translate(isUpdate ? 'editArea' : 'includeArea', [_titleComplement])}
      >
        <BrassForm
          onSubmit={isUpdate ? onSubmitUpdate : onSubmitInclude}
          render={({ allowSubmit, valid, onSubmit }: BrassFormRenderProps) => {
            let disableButtonSave = submiting;
            if (!valid) {
              disableButtonSave = true;
            } else if (!allowSubmit) {
              disableButtonSave = true;
            }

            return (
              <div style={isUpdate ? { padding: 20 } : {}}>
                <BrassFormElement style={{ height: 280, width: 600 }}>
                  <div className={style['div-fields-tag-costcenter']}>
                    <TagField
                      disabled={loadingData || submiting}
                      defaultValue={initialFormValues && initialFormValues.tag}
                      loading={loadingData}
                    />
                    <div className={style['div-fields-divider']} />
                    <CostCenterField
                      disabled={loadingData || submiting}
                      defaultValue={
                        initialFormValues && initialFormValues.costCenter
                      }
                      loading={loadingData}
                    />
                  </div>

                  <NameField
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.name}
                    loading={loadingData}
                  />

                  <DescriptionField
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.description}
                    loading={loadingData}
                  />
                </BrassFormElement>

                <BrassDialogActionsBar>
                  <BrassDivFlex />
                  {loginError && <BrassError>{loginError}</BrassError>}
                  <BrassButtonSave
                    autoFocus
                    onClick={onSubmit}
                    disabled={disableButtonSave}
                    showLoader={submiting}
                    hideIconWhenLoader
                  />
                  <BrassButtonCancel onClick={onCancelClose} />
                </BrassDialogActionsBar>
              </div>
            );
          }}
        />
      </BrassDialog>
    );
  },
);

UpsertArea.defaultProps = {
  type: AreaTypeEnum.PHYSICAL,
};

export { UpsertArea };
export type { UpsertAreaProps };
