import { emptyGuid } from '../../../';
import { AreaTypeEnum } from '../../';

class Area {
  // Informa a GRID que o registro está em processo de EXCLUSÃO
  public deleting: boolean = false;

  public id: string = emptyGuid();
  public areaId: string = emptyGuid();
  public projectId: string = emptyGuid();
  public accountId: string = emptyGuid();

  public tag: string = '';
  public name: string = '';
  public thumbnail: string = '';
  public costCenter: string = '';
  public description: string = '';

  public type: AreaTypeEnum = AreaTypeEnum.PHYSICAL;

  constructor(project: Partial<Area>) {
    Object.assign(this, project);
  }
}

export { Area };
