import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconNounHeader: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.4 9.4H12.6C13.5289 9.4 14.3872 8.90443 14.8517 8.1C15.3161 7.29556 15.3161 6.3045 14.8517 5.5C14.3872 4.69556 13.5289 4.2 12.6 4.2H7.4C6.4711 4.2 5.61284 4.69556 5.14834 5.5C4.68395 6.30443 4.68395 7.2955 5.14834 8.1C5.61279 8.90443 6.47112 9.4 7.4 9.4ZM7.4 5.8H12.6C12.9573 5.8 13.2874 5.99063 13.466 6.3C13.6447 6.60936 13.6447 6.99063 13.466 7.3C13.2874 7.60936 12.9573 7.8 12.6 7.8H7.4C7.0427 7.8 6.71264 7.60936 6.53399 7.3C6.35534 6.99063 6.35534 6.60936 6.53399 6.3C6.71264 5.99063 7.0427 5.8 7.4 5.8Z'
      fill={props.fill}
    />
    <path
      d='M5.6 12.6H14.4C14.6858 12.6 14.9499 12.4475 15.0928 12.2C15.2357 11.9525 15.2357 11.6475 15.0928 11.4C14.9499 11.1525 14.6858 11 14.4 11H5.6C5.3142 11 5.05007 11.1525 4.90717 11.4C4.76426 11.6475 4.76426 11.9525 4.90717 12.2C5.05007 12.4475 5.3142 12.6 5.6 12.6Z'
      fill={props.fill}
    />
    <path
      d='M5.6 15.8H14.4C14.6858 15.8 14.9499 15.6475 15.0928 15.4C15.2357 15.1525 15.2357 14.8475 15.0928 14.6C14.9499 14.3525 14.6858 14.2 14.4 14.2H5.6C5.3142 14.2 5.05007 14.3525 4.90717 14.6C4.76426 14.8475 4.76426 15.1525 4.90717 15.4C5.05007 15.6475 5.3142 15.8 5.6 15.8Z'
      fill={props.fill}
    />
  </svg>
);

export { SGVIconNounHeader };
