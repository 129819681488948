
import React, { ReactNode } from 'react';

interface BrassTabProps {
  children: ReactNode;
  title: string;
  contentClassName?: string;
  disabled?: boolean;
}


const BrassTab: React.FC<BrassTabProps> = ({ children }) => {
  return <div>{children}</div>;
};

export { BrassTab };
export type { BrassTabProps };

