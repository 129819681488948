import { Dispatch } from 'react';
import { DynamicDispatchAlert } from './DynamicDispatchAlert';

const handleCopyClipboard = (
  dispatch: Dispatch<any>,
  translate: any,
  copyText: string,
) => {
  navigator.clipboard.writeText(copyText);
  DynamicDispatchAlert(dispatch, [translate('copyClipboard')], 'SUCCESS', translate);
};

export { handleCopyClipboard };
