enum WorkTimeRecordSubmodule {
  /// <summary>
  /// Apropriação de horas no RHT
  /// </summary>
  APPROPRIATE_TIME = 0x00000100,
  /// <summary>
  /// Registros Administrativos de Apropriação de horas no RHT
  /// </summary>
  ADMINISTRATIVE_APPROPRIATE_TIME = 0x00000200,
  /// <summary>
  /// Validação das apropriações de horas no RHT
  /// </summary>
  VALIDATE_TIME = 0x00000300,
  /// <summary>
  /// Permissão para usuários Administrativos efetuarem lançamentos em nome de outros usuários,
  /// Lançamentos Administrativos
  /// </summary>
  ADMINISTRATIVE = 0x00000400,
  /// <summary>
  /// Acompanhamento de Projetos
  /// </summary>
  PROJECT_FOLLOW_UP = 0x00000500,
  REPORT_ACTIVITIES = 0x00000600,
  REPORT_PROJECT_BY_STATUS = 0x00000700,
  REPORT_PROJECT_SYSTEM_COST = 0x00000800,
  REPORT_PROJECT_SYSTEM_TIME = 0x00000900,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_COST = 0x00000a00,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_COST = 0x00000b00,
  REPORT_PROJECT_SYSTEM_EXECUTED_ACTIVITIES_USER_TIME = 0x00000c00,
  PROJECT_LEADER = 0x00000d00,
  PROJECT_MANAGER = 0x00000e00,
  /// <summary>
  /// Meus pedidos de Hora Extra
  /// </summary>
  MY_OVERTIME = 0x00000f00,
  /// <summary>
  /// Aprovação de Pedidos de Horas Extras do meu Time
  /// </summary>
  TEAM_OVERTIME = 0x00001000,
  REPORT_EMPLOYEE = 0x00001100,
  // Banco de Horas
  REPORT_COMPENSATORY_TIME_OFF = 0x00001200,
  REPORT_APPROPRIATE_TIME = 0x00001300,
  /// <summary>
  /// Indicador de Homem Hora
  /// </summary>
  INDICATOR_BUDGET_MH = 0x00001400,
  INDICATOR_BUDGET_A1 = 0x00001500,
  INDICATOR_BUDGET_COST = 0x00001600,
  /// <summary>
  /// Indicador de Homem Hora
  /// </summary>
  INDICATOR_AVERAGE_HISTORY_MH_COST = 0x00001700,
  INDICATOR_AVERAGE_HISTORY_A1_COST = 0x00001800,
  INDICATOR_AVERAGE_HISTORY_PRODUCTIVITY = 0x00001900,
}

export { WorkTimeRecordSubmodule };
