import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { BrassCard, BrassCardSubtitle, BrassCardTitle } from '../../../brass/card';
import styles from './GeneralCard.module.scss';
import { BrassGeneralTooltip } from '../../tooltips';
import classNames from 'classnames';
import { BrassIcon } from '../../icon';
import {
  faCog,
  faPen,
  faTrashAlt,
  faTrashRestoreAlt,
} from '@fortawesome/free-solid-svg-icons';
import { BrassTooltip } from '../../../brass';

interface BrassGeneralCardProps {
  onAddClick?: (e?: any) => void;
  onEditClick?: (e?: any) => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
  title: string;
  entity?: any;
  titleElement?: (e?: any) => ReactElement;
  contentElement?: (e?: any) => ReactElement;
  contentElementFooter?: (e?: any) => ReactElement;
  subTitle?: string;
  colorBorder?: string;
  description?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  direction?: 'column' | 'row';
  image?: string;
  selected?: boolean;
  limitedTextColumn?: boolean;
  limitedTextRow?: boolean;
  limitedText?: boolean;
  imageReverseLine?: boolean;
  bottomLine?: boolean;
  vertical?: boolean;
  noImage?: boolean;
  imgStyle?: React.CSSProperties;
  colorIcons?: string;
  widthCard?: string;
  noGeneralTooltip?: boolean;
  className_contentCard?: React.CSSProperties;
  className_contentCardBox1?: React.CSSProperties;
  className_contentCardImage?: React.CSSProperties;
  className_contentCardBox2?: React.CSSProperties;
  className_contentCardText?: React.CSSProperties;
  className_contentCardTextLimited?: React.CSSProperties;
  className_contentCardSubTitle?: React.CSSProperties;
  className_contentCardActions?: React.CSSProperties;
  className_contentCardTextTooltip?: React.CSSProperties;
  className_contentImg?: React.CSSProperties;
  className_contentBottomLine?: React.CSSProperties;
  className_contentImagePreview?: React.CSSProperties;
  onConfigClick?: (e?: any) => void;
  onDeleteClick?: (e?: any) => void;
  isIconConfig?: boolean;
  classNameMain?: string;
}

const BrassGeneralCard: FunctionComponent<BrassGeneralCardProps> = ({
  colorBorder,
  onAddClick,
  disabled,
  title,
  subTitle,
  entity,
  titleElement,
  contentElement,
  contentElementFooter,
  description,
  style,
  onEditClick,
  image,
  direction,
  selected,
  limitedTextColumn,
  limitedTextRow,
  limitedText,
  allowEdit,
  allowDelete,
  imageReverseLine,
  bottomLine,
  vertical,
  noImage,
  imgStyle,
  colorIcons,
  widthCard,
  className_contentCard,
  className_contentCardBox1,
  className_contentCardImage,
  className_contentCardBox2,
  className_contentCardText,
  className_contentCardTextLimited,
  className_contentCardSubTitle,
  className_contentCardActions,
  className_contentCardTextTooltip,
  className_contentImg,
  className_contentBottomLine,
  noGeneralTooltip,
  onConfigClick,
  isIconConfig,
  className_contentImagePreview,
  classNameMain,
  onDeleteClick,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled ?? false);
  const [isSelected, setIsSelected] = useState<boolean>(selected ?? false);
  const [recoveryImage, setRecoveryImage] = useState<string>();

  useEffect(() => {
    setIsDisabled(disabled ?? false);
    setIsSelected(selected ?? false);
  }, [disabled, selected]);

  const htmlDecode = (content: string): string | null => {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  return (
    <>
      <div
        className={classNames({
          [styles['container-card-snippet']]: !vertical,
          [styles['container-card-vertical']]: vertical,
          [classNameMain as string]: classNameMain!,
        })}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onAddClick && onAddClick(e);
        }}
        onKeyDown={undefined}
        role='button'
        tabIndex={0}
        style={style}
      >
        <BrassCard
          stylediv={{ width: '100%' }}
          className={classNames({
            [styles['info-card']]: true,
            [styles['card-subtitle']]: !!subTitle,
            [styles[
              `selected__${colorBorder ? getColorCardString(colorBorder) : 'base'}`
            ]]: isSelected,
          })}
          style={{
            borderLeft: !bottomLine ? `solid 12px ${colorBorder}` : '',
            borderBottom: bottomLine ? `solid 12px ${colorBorder}` : '',
            filter: isDisabled ? 'contrast(70%)' : 'contrast(100%)',
            width: widthCard,
            ...className_contentCard,
          }}
        >
          <div
            className={classNames({
              [styles['content-card']]: true,
              [styles['image-reverse-line']]: imageReverseLine,
            })}
            style={{ flexDirection: direction, ...className_contentCardBox1 }}
          >
            {image && !noImage && !imageReverseLine && (
              <div className={styles['image-preview']}>
                <img
                  className={classNames({
                    [styles['image-preview__column']]: direction === 'column',
                    [styles['image-preview__row']]:
                      !direction || direction === 'row',
                    [styles['image-preview__subtitle']]: !!subTitle,
                  })}
                  src={image!}
                  style={{ ...imgStyle, ...className_contentCardImage }}
                  onError={(e: any) => {
                    (e.target as HTMLImageElement).src =
                      'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                    e.onerror = null;
                  }}
                ></img>
              </div>
            )}
            <div
              className={classNames({
                [styles['content-column']]: direction === 'column' && image,
                [styles['content']]: !direction || direction === 'row',
                [styles['content-subtitle']]: !!subTitle,
                [styles['content-bottomLine']]: bottomLine,
              })}
              style={className_contentCardBox2}
            >
              {titleElement ? (
                titleElement(entity)
              ) : (
                <BrassTooltip>
                  <span
                    title={title}
                    className={classNames({
                      [styles.titleTooltipColumn]: direction === 'column',
                      [styles.titleTooltipRow]: !direction || direction === 'row',
                    })}
                    style={className_contentCardTextTooltip}
                  >
                    <BrassCardTitle
                      className={classNames({
                        [styles['info-text-card']]: true,
                        [styles['text-bottomLine']]: bottomLine,
                      })}
                      style={className_contentCardText}
                    >
                      <div
                        style={className_contentBottomLine}
                        className={classNames({
                          [styles['text-align-bottomLine']]: bottomLine,
                        })}
                      >
                        <div
                          style={className_contentCardTextLimited}
                          className={classNames({
                            [styles['limited-text-column']]: limitedTextColumn,
                            [styles['limited-text-row']]: limitedTextRow,
                            [styles['limited-text']]:
                              imageReverseLine || bottomLine || limitedText,
                          })}
                          dangerouslySetInnerHTML={{
                            __html: htmlDecode(title?.toString()) as string,
                          }}
                        />
                      </div>
                    </BrassCardTitle>
                  </span>
                </BrassTooltip>
              )}
              {subTitle && (
                <BrassTooltip>
                  <span title={subTitle} className={styles.titleTooltip}>
                    <BrassCardSubtitle
                      className={classNames({
                        [styles['text-subtitle']]: true,
                      })}
                      style={className_contentCardSubTitle}
                    >
                      <div
                        className={styles.subtitle}
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(subTitle?.toString()) as string,
                        }}
                      />
                    </BrassCardSubtitle>
                  </span>
                </BrassTooltip>
              )}
              {!imageReverseLine && (
                <div>{contentElement && contentElement(entity)}</div>
              )}
              <div
                className={classNames({
                  [styles['actions']]: true,
                  [styles['actions-row']]: !onEditClick && direction === 'column',
                  [styles['no-edit']]: !allowEdit && bottomLine,
                })}
                style={className_contentCardActions}
              >
                {onConfigClick && isIconConfig && (
                  <BrassIcon
                    icon={faCog}
                    className={styles['button-custom']}
                    color={colorIcons ?? '#8d8e91'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onConfigClick();
                    }}
                  />
                )}
                {allowEdit && onEditClick && (
                  <BrassIcon
                    icon={faPen}
                    className={styles['button-custom']}
                    color={colorIcons ?? '#8d8e91'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onEditClick();
                    }}
                  />
                )}
                {allowDelete && onDeleteClick && (
                  <BrassIcon
                    icon={faTrashAlt}
                    className={styles['button-custom']}
                    color={colorIcons ?? '#8d8e91'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDeleteClick();
                    }}
                  />
                )}
                {noGeneralTooltip ? (
                  ''
                ) : (
                  <BrassGeneralTooltip
                    className={styles['tooltip-custom']}
                    description={description!}
                    color={colorIcons ?? '#8d8e91'}
                  />
                )}
              </div>
            </div>
            {imageReverseLine && !noImage && (
              <div style={className_contentImg}>
                <div>{contentElement && contentElement(entity)}</div>
                <div
                  className={styles['image-preview']}
                  style={className_contentImagePreview}
                >
                  <img
                    className={classNames({
                      [styles['image-preview__row']]: true,
                    })}
                    src={
                      recoveryImage ??
                      image ??
                      'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png'
                    }
                    style={{ ...imgStyle, ...className_contentCardImage }}
                    onError={(e: any) => {
                      (e.target as HTMLImageElement).src =
                        'https://account.bdbsystem.com.br/v1/brassbrasil-logo-card.png';
                      e.onerror = null;
                    }}
                  ></img>
                </div>
              </div>
            )}
            {contentElementFooter && contentElementFooter(entity)}
          </div>
        </BrassCard>
      </div>
    </>
  );
};

const getColorCardString = (color: string): string => {
  switch (color?.toUpperCase()) {
    case '#FACC2C':
      return 'yellow';
    case '#FDA451':
      return 'orange';
    case '#DA0B0B':
      return 'red';
    case '#3B4351':
      return 'midgray';
    case '#595959':
      return 'lightgray';
    case '#2D2B2B':
      return 'darkgray';
    case '#0070C0':
      return 'blue';
    case '#7030A0':
      return 'purple';
    default:
      return 'green';
  }
};

export { BrassGeneralCard };
