import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import style from './StrengthnessPassword.module.scss';
import { translate } from './StrengthnessPassword.translations';
import { I18nService } from '../../../../../services';

interface StrengthnessPasswordProps {
  currentPassword: string;
  setOldPassword: Dispatch<SetStateAction<string>>;
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  confirmNewPassword: string;
  setConfirmNewPassword: Dispatch<SetStateAction<string>>;
  callBackPasswordStrengthness: any;
}

const StrengthnessPassword: FunctionComponent<StrengthnessPasswordProps> = (
  props: StrengthnessPasswordProps,
) => {
  const {
    confirmNewPassword,
    newPassword,
    callBackPasswordStrengthness,
    currentPassword,
  } = props;
  const [passwordStrengthText, setPasswordStrengthText] = useState<string>('');
  const [classnameStrengthnessPassword, setClassnameStrengthnessPassword] =
    useState<string>(style.default);

  const changePasswordStrengthText = useCallback((): void => {
    const isDifferent = confirmNewPassword !== newPassword;

    if (isDifferent) {
      setClassnameStrengthnessPassword(style.differentPasswords);
      setPasswordStrengthText(translate('differentPasswords'));
      callBackPasswordStrengthness('differentPasswords');
      return;
    }

    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    );
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
    );

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      return;
    }
    if (strongRegex.test(newPassword)) {
      setPasswordStrengthText(translate('strong'));
      setClassnameStrengthnessPassword(style.strong);
      callBackPasswordStrengthness('strong');
    } else if (mediumRegex.test(newPassword)) {
      setPasswordStrengthText(translate('medium'));
      setClassnameStrengthnessPassword(style.medium);
      callBackPasswordStrengthness('medium');
    } else {
      setPasswordStrengthText(translate('weak'));
      setClassnameStrengthnessPassword(style.weak);
      callBackPasswordStrengthness('weak');
    }
  }, [
    confirmNewPassword,
    newPassword,
    callBackPasswordStrengthness,
    currentPassword,
  ]);

  useEffect(() => {
    changePasswordStrengthText();
  }, [currentPassword, newPassword, confirmNewPassword, changePasswordStrengthText]);

  return <div className={classnameStrengthnessPassword}>{passwordStrengthText}</div>;
};

export { StrengthnessPassword };
