import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonRefresh: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-refresh'
    labelKey='refresh'
    tooltipKey='clickToRefresh'
    translations={translations}
    icon={<BrassIcon icon={faSync} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    refresh: 'actualizar',
    clickToRefresh: 'Haga clic para actualizar',
  },
  enUS: {
    refresh: 'Refresh',
    clickToRefresh: 'Click to refresh',
  },
  ptBR: {
    refresh: 'Atualizar',
    clickToRefresh: 'Clique para atualizar',
  },
};

export { BrassButtonRefresh };
