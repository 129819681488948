import React, { Component, Fragment, ReactNode, useRef, useState } from 'react';
import { BrassTreeListGridProps } from './TreeListGridProps.interface';
import { DeleteColumn, EditColumn } from './TreeListGrid.functions';
import { BrassDeleteCell, BrassEditCell } from '../cells';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from '@progress/kendo-react-buttons';
import {
  TreeList,
  TreeListColumnProps,
  TreeListDataStateChangeEvent,
  TreeListExpandChangeEvent,
  TreeListToolbar,
  TreeListFilterChangeEvent,
} from '@progress/kendo-react-treelist';
import {
  BrassLoader,
  BrassButtonFilter,
  BrassButtonAdd,
  BrassButtonRefresh,
} from '../../..';
import style from './TreeListGrid.module.scss';
import {
  ExcelExport,
  ExcelExportColumnProps,
  ExcelExportData,
} from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from '@progress/kendo-react-pdf';
import { composeSortDescriptors } from '@progress/kendo-data-query';
/* eslint-disable */
const renderTreeList = (
  hideActionButtons: boolean = false,
  props: BrassTreeListGridProps,
  _className: string,
): ReactNode => {
  const {
    columns,
    colunsExportExcell,
    allowEdit,
    allowDelete,
    onEditCallback,
    onDeleteCallback,
    gridData,
    updateGridData,
    gridConfig,
    customFilterGridToolbar,
    updateGridConfig,
    gridLoading,
    toolbarBeforeTitle,
    toolbarAfterTitle,
    toolbarBeforeActions,
    toolbarAfterActions,
    allowExport,
    visibleButtonRefresh,
    allowInsert,
    onAddCallback,
    setGridLoading,
    onRefreshCallback,
    filterable,
  } = props;

  const subItemsField: string = 'children'; // CHANGE
  const expandField: string = 'expanded';
  const anchorRef = useRef<any>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

  const preColumns = [...columns];

  const editCell = (): any =>
    class extends Component {
      public render(): any {
        return (
          <td>
            <Button>teste</Button>
          </td>
        );
      }
    };

  const gridExcelExport = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (gridExcelExport.current !== null && gridExcelExport.current !== undefined) {
      gridExcelExport.current.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null && gridPDFExport !== undefined) {
      gridPDFExport.save();
    }
  };

  EditColumn(allowEdit!, preColumns, true, BrassEditCell, onEditCallback!);
  DeleteColumn(
    allowDelete!,
    preColumns,
    BrassDeleteCell,
    onDeleteCallback!,
    gridData,
    updateGridData,
  );

  let _columns: TreeListColumnProps[] = preColumns.map(
    (col) => col as TreeListColumnProps,
  );
  if (hideActionButtons) {
    _columns = _columns.filter((f) => f.field !== 'edit' && f.field !== 'delete');
  }

  const expandRecursive = (recordId: string, rec: any[]): any => {
    const oldRec = rec;
    const newRec = rec.map((item: any) => {
      if (item) {
        if (item.id === recordId) {
          if (item.id === recordId) {
            if (item.expanded) {
              item.expanded = false;
            } else {
              item.expanded = true;
            }
          } else {
            if (item.children && item.child) {
              expandRecursive(recordId, item.children);
            }
          }
          return item; // [undef, undef, [modified item], undef,...]
        }
      }
    });
    newRec.forEach((el: any, index: number) => {
      if (el === undefined) {
        newRec[index] = oldRec[index]; // Atualiza os registros apenas com o item modificado
      }
    });
    return newRec;
  };

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    const recordId = e.dataItem.id;
    const newStateData = expandRecursive(recordId, gridData);
    updateGridData([...newStateData]);
  };

  const handleDataStateChange = (event: TreeListDataStateChangeEvent) => {
    updateGridData(event.dataState);
  };

  // ---------------------------------------------------------------------------------
  // Calcula a posição exata do Loader
  let offsetTop = 0;
  let offsetLeft = 0;
  let offsetHeight = 0;
  let offsetWidth = 0;
  if (anchorRef.current && anchorRef.current.element) {
    offsetTop = anchorRef.current.element.offsetTop || 0;
    offsetLeft = anchorRef.current.element.offsetLeft || 0;

    offsetWidth = anchorRef.current.element.offsetWidth || 0;
    offsetHeight = anchorRef.current.element.offsetHeight || 0;
  }

  return (
    <Fragment>
      {gridLoading &&
        (offsetTop + offsetLeft + offsetHeight + offsetWidth >= 1 ? (
          <BrassLoader
            useLoadingMask
            style={{
              top: offsetTop,
              left: offsetLeft,
              height: offsetHeight,
              width: offsetWidth,
            }}
          />
        ) : (
          <BrassLoader useLoadingMask />
        ))}
      <TreeList
        ref={anchorRef}
        dataItemKey='id'
        className={style.treeliststyle}
        // style={{overflow: 'auto', maxHeight: '80vh' }}
        expandField={expandField}
        subItemsField={subItemsField} // CHANGE
        data={gridData}
        columns={columns as TreeListColumnProps[]}
        onDataStateChange={handleDataStateChange}
        onExpandChange={onExpandChange}
        sortable={props.sortable ? { mode: 'multiple' } : {}}
        onFilterChange={() => {}}
        onSortChange={() => {}}
        toolbar={
          <TreeListToolbar>
            {toolbarBeforeTitle}
            <h2 className={style['brass-grid-title']}>
              {props.gridIcon}
              {props.gridTitle}
            </h2>
            {toolbarAfterTitle}
            <ToolbarSpacer />
            {toolbarBeforeActions}
            {allowInsert && (
              <BrassButtonAdd
                fillMode='outline'
                className={style['brass-button-insert']}
                onClick={onAddCallback as any}
                disabled={gridLoading}
              />
            )}
            {visibleButtonRefresh && (
              <BrassButtonRefresh
                fillMode='outline'
                className={style['brass-button-refresh']}
                onClick={() => {
                  setGridLoading && setGridLoading(true);
                  onRefreshCallback && onRefreshCallback();
                }}
                disabled={gridLoading}
              />
            )}
            {allowExport && (
              <DropDownButton
                icon='printer'
                items={['PDF', 'Excel']}
                onItemClick={(itemClickEvent: DropDownButtonItemClickEvent) =>
                  itemClickEvent.itemIndex === 0 ? exportPDF() : excelExport()
                }
                disabled={gridLoading}
              />
            )}
            {customFilterGridToolbar && (
              <BrassButtonFilter
                key='buttonFilter'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              />
            )}
            {toolbarAfterActions}
          </TreeListToolbar>
        }
      />
      {showFilterPanel && customFilterGridToolbar && (
        <TreeListToolbar>{customFilterGridToolbar}</TreeListToolbar>
      )}
      <div style={{ maxHeight: 0 }}>
        <ExcelExport
          ref={gridExcelExport}
          data={gridData.records}
          fileName={`${props.gridTitle} - ${new Date().toDateString()}`}
        >
          <TreeList data={gridData.records} style={{ display: 'none' }}>
            {colunsExportExcell || columns}
          </TreeList>
        </ExcelExport>
        <PDFExport
          // @ts-ignore
          ref={(pdfExport: PDFExport) => (gridPDFExport = pdfExport)}
          fileName={`${props.gridTitle} - ${new Date().toDateString()}`}
          paperSize='A4'
          margin='1cm'
        >
          <TreeList data={gridData.records} style={{ display: 'none' }}>
            {columns}
          </TreeList>
        </PDFExport>
      </div>
    </Fragment>
  );
};

export { renderTreeList };
