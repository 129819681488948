import { ApiResult, DropDownData } from '../';
import { Endpoints } from '../models';
import { ApiService } from './api.service';
// import { SystemClassificationModel } from '../models';

class SystemClassificationService {
  private static apiNavigator: ApiService = new ApiService(Endpoints.NAVIGATOR);

  // public static getSystemClassification(
  //   systemClassificationId: string,
  // ): Promise<ApiResult<SystemClassificationModel>> {
  //   return this.apiNavigator.get<SystemClassificationModel>(
  //     `/connect/v1/systemclassification/${systemClassificationId}`,
  //   );
  // }

  // public static getSystemClassificationList(
  //   gridConfig: BrassGridConfigProps,
  // ): Promise<ApiResult<ApiGridResult<SystemClassificationModel>>> {
  //   return this.apiNavigator.post<ApiGridResult<SystemClassificationModel>>(
  //     '/connect/v1/systemclassification/list',
  //     gridConfig,
  //   );
  // }

  // public static insertSystemClassificationData(
  //   systemClassification: SystemClassificationModel,
  // ): Promise<ApiResult> {
  //   return this.apiNavigator.post('/connect/v1/systemclassification', systemClassification);
  // }

  // public static updateSystemClassificationData(
  //   systemClassification: SystemClassificationModel,
  // ): Promise<ApiResult> {
  //   return this.apiNavigator.put('/connect/v1/systemclassification', systemClassification);
  // }

  // public static deleteSystemClassification(
  //   systemClassificationId: string,
  // ): Promise<ApiResult> {
  //   return this.apiNavigator.delete(
  //     `/connect/v1/systemclassification/${systemClassificationId}`,
  //   );
  // }

  // public static cacheSystemClassificationDropDownData: DropDownData<any>[];

  public static async getDropdown(): Promise<ApiResult<DropDownData<any>[]>> {
    return this.apiNavigator.get('/connect/v1/systemclassification/dropdown');
  }
}

export { SystemClassificationService };
