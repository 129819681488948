import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const BrassButtonInformation: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    fillMode='outline'
    themeColor='primary'
    className='brass-button-information'
    tooltipKey='info'
    translations={translations}
    icon={<BrassIcon icon={faInfo} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    info: 'Información',
  },
  enUS: {
    info: 'Information',
  },
  ptBR: {
    info: 'Informações',
  },
};

export { BrassButtonInformation };
