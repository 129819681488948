import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';
import { BrassIcon } from '../icon/Icon';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const BrassButtonAlert: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    className='brass-button-alert'
    labelKey='alert'
    tooltipKey='clickToAlert'
    translations={translations}
    icon={<BrassIcon icon={faBell} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    alert: 'Alertar',
    clickToAlert: 'haga clic para alertar',
  },
  enUS: {
    alert: 'Alert',
    clickToAlert: 'Click to alert',
  },
  ptBR: {
    alert: 'Alertar',
    clickToAlert: 'Clique para alertar',
  },
};

export { BrassButtonAlert };
