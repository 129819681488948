import React from 'react';
import { II18n } from '../../../../../../../models';
import { I18nService } from '../../../../../../../services';

const translations: II18n = {
  es: {
    usersList: 'Usuarios en este grupo',
    name: 'Nombre',
    email: 'Correo electrónico',
    saveToKeep: ' - Guardar para mantener los cambios',
  },
  enUS: {
    usersList: 'Users in this Group',
    name: 'Name',
    email: 'E Mail',
    saveToKeep: ' - Save to keep user',
  },
  ptBR: {
    usersList: 'Usuários neste Grupo',
    name: 'Nome',
    email: 'Email',
    saveToKeep: ' - Salvar para manter usuário',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
