import React, { FunctionComponent, useState } from 'react';

import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import style from './UploadPDFV2.module.scss';
import { translate } from './UploadPDFV2.translations';
import {
  IPublishDocumentsListProps,
  IPublishDocumentsPayload,
  ResultPublishDocumentType,
} from '../../../PublishDocumentsModal.interfaces';
import { UploadService } from '../../../../../../services';
import { ApiResult, BrassUploadFileInfo } from '../../../../../../models';
import { BrassButtonSaveV1 } from '../../../../buttons';
import { BrassDivFlex, BrassModal, getLanguagesStorage } from '../../../../../brass';
import { useWindowSize } from '../../../../../../hooks';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BrassIcon } from '../../../../icon';
import { onAddMany } from './UploadPDFV2.functions';
import { getFileAction } from '../../../PublishListDocuments';

interface IconFieldProps {
  icon?: string;
  onChange: (e: ResultPublishDocumentType[]) => ResultPublishDocumentType[];
  publishDocumentElement: IPublishDocumentsListProps;
  setPublishDocument: IPublishDocumentsPayload;
  onCancelClose: () => void;
}

const allowedExtensionsDocument = getFileAction('DOCUMENT')?.split(',') as string[];
const allowedExtensions = [...allowedExtensionsDocument];

const UploadPDFV2: FunctionComponent<IconFieldProps> = (props: IconFieldProps) => {
  const { onCancelClose, onChange, publishDocumentElement } = props;
  const [uploading, setUploading] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [, setForceUpdate] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<BrassUploadFileInfo[]>([]);
  const uploadRef = React.createRef<Upload>();
  const [filesUrl, setFilesUrl] = useState<ResultPublishDocumentType[]>(
    [] as ResultPublishDocumentType[],
  );
  const [x] = useWindowSize();

  const onSelectOne = (event: UploadOnAddEvent): Promise<any> =>
    onAddMany(event, setFilesUrl, publishDocumentElement?.action);

  const saveFiles = async (data: BrassUploadFileInfo) => {
    const payload = {
      name: data.name,
      language: getLanguagesStorage()?.language,
      files: [data],
      fileType: data.extension,
    };
    const result = new Promise<ApiResult>((res) =>
      setTimeout(() => {
        res(new ApiResult({ data: payload }));
        onChange(
          filesUrl
            .map((_) => {
              _.fileKey = data?.bucketKey as string;
              return _;
            })
            .filter((_) => _.fileName === data?.name),
        );
      }, 2000),
    );

    return result;
  };

  UploadService.setFilesToUpload = setFilesToUpload;
  UploadService.uploadCallback = (fileUploadInfo: BrassUploadFileInfo) => {
    setFilesToUpload((prev) => [...prev, fileUploadInfo]);
    return saveFiles(fileUploadInfo);
  };

  const onClose = (): void => {
    UploadService.abortUpload();
    onCancelClose();
  };

  return (
    <>
      <BrassModal
        styleModal={{ width: `${(70 / 100) * x}px` }}
        title={translate('title')}
        onClose={onClose}
      >
        <ExternalDropZone
          uploadRef={uploadRef}
          customHint={translate('hint')}
          customNote={translate('note', [
            allowedExtensions.join(', ').toUpperCase(),
          ])}
          className={style['dropzone']}
        />
        <Upload
          ref={uploadRef}
          className={style['upload-button']}
          multiple
          autoUpload={false}
          defaultFiles={filesToUpload}
          accept={allowedExtensions.join(',')}
          showActionButtons={false}
          onProgress={() => {
            setForceUpdate((previousValue) => !previousValue);
          }}
          onAdd={(event: UploadOnAddEvent) => {
            UploadService.onAddFile(event);
            setUploading(true);
            setShowUploadButton(false);
            UploadService.uploadFilesToS3();
            onSelectOne(event);
          }}
          onRemove={(event: UploadOnRemoveEvent) => {
            UploadService.onRemoveFile(event);
            setForceUpdate((previousValue) => !previousValue);
          }}
        />
        <BrassDivFlex />
        <div
          className={style['brass-action-bar']}
          style={{ marginTop: uploading ? '20px' : 0 }}
        >
          <BrassButtonSaveV1
            labelKey='fechar'
            className={style['brass-close']}
            icon={<BrassIcon icon={faTimes} />}
            onClick={onClose}
            hidden={true}
          />
          {showUploadButton && (
            <BrassButtonSaveV1
              onClick={() => {
                setUploading(true);
                setShowUploadButton(false);
                UploadService.uploadFilesToS3();
              }}
              disabled={filesToUpload.length === 0}
              hidden={true}
            />
          )}
        </div>
      </BrassModal>

      {/* {isLoading && <BrassLoader useLoadingMask />} */}
    </>
  );
};

export { UploadPDFV2 };
