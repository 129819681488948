import React from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    noData: 'No se encontraron registros',
  },
  enUS: {
    noData: 'No record found',
  },
  ptBR: {
    noData: 'Nenhum registro encontrado',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const translateForLanguage = (
  key: string,
  language: string,
  args?: string[],
): string => new I18nService(translations).translateForLanguage(key, language, args);

export { translate, translateForLanguage };
