import React, {
  FunctionComponent,
  useState,
  ReactElement,
  cloneElement,
} from 'react';
import style from './DropDownList.module.scss';
import classnames from 'classnames';
import {
  DropDownList,
  DropDownListProps,
  DropDownListFilterChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { BrassButton } from '../../buttons';

interface BrassDropDownListV1Props extends DropDownListProps {
  onLeftButtonClick?: () => void;
  leftButtonIcon?: any;
}

const BrassDropDownListV1: FunctionComponent<BrassDropDownListV1Props> = (
  props: BrassDropDownListV1Props,
) => {
  const { className, popupSettings } = props;
  const _className = classnames('brass-dropdown-list', className);

  const [data, setData] = useState(props.data!);

  const filterData = (filter: FilterDescriptor): any[] => {
    const _data = props.data!.slice();
    return filterBy(_data, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent): void =>
    setData(filterData(event.filter));

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <span
        className={itemProps.dataItem.disabled ? style['disabled'] : ''}
        style={{ color: (itemProps.dataItem as any)?.color, fontSize: '12px' }}
      >
        {li.props.children}
      </span>
    );

    return cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element: any, value: any) => {
    if (!value) {
      return element;
    }

    const children = [
      <span
        style={{
          color: value?.color ?? '#000',
          fontSize: '10px',
        }}
        key={2}
      >
        {value.text}
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <div style={{ display: 'flex' }}>
      {props.onLeftButtonClick && (
        <BrassButton
          fillMode='outline'
          themeColor='base'
          onClick={props.onLeftButtonClick}
          style={{ float: 'left' }}
          type='button'
        >
          {props.leftButtonIcon}
        </BrassButton>
      )}
      <DropDownList
        {...props}
        data={data}
        className={_className}
        valueRender={valueRender}
        filterable={false}
        style={{ height: '20px' }}
        onFilterChange={filterChange}
        itemRender={itemRender}
        popupSettings={{
          className: classnames(
            style['brass-dropdown-popup'],
            popupSettings && popupSettings.className,
          ),
        }}
      />
    </div>
  );
};

BrassDropDownListV1.defaultProps = {
  filterable: true,
};

export { BrassDropDownListV1 };
export type { BrassDropDownListV1Props };
