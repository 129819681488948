/*
  0xFFFFFFFF = 4 294 967 295

  FF XXXXXX = 255 modules
  XX FFFF XX = 65535 submodules
  XX XXXX FF = 255 actions
*/

enum Action {
  // Ações primárias da plataforma
  READ = 0x00000001,
  CREATE = 0x00000002,
  UPDATE = 0x00000003,
  DELETE = 0x00000004,
  EXPORT = 0x00000005,
  SHARE = 0x00000006,
  UPLOAD = 0x00000007,
  DOWNLOAD = 0x00000008,
  // -------------------
  // Ações secudárias da plataforma
  SUBMIT = 0x00000010,
  NOTIFY = 0x00000011,
  COMMENT = 0x00000012,
  APPROVE = 0x00000013,
  UNAPPROVE = 0x00000014,
  VALIDATE = 0x00000015,
  /// <summary>
  /// Remove as Restrições de Filtro para os registros da Grid.
  /// Exepmlo:
  ///     1) Relatório de Custo Incorrido -> Exibe todos os Projetos, e não somente os projetos em que o usuários participa.
  /// </summary>
  FULL_RECORDS = 0x00000016,
  /// <summary>
  /// Permite promover um Registro.
  /// Exemplo: no Navigator permite promover um arquivo como VISÃO PRINCIPAL
  /// </summary>
  PROMOTE = 0x00000017,
  /// <summary>
  /// Permite ligar dois objetos
  /// Exemplo: no Survey permite lincar um Integrator-Sistema a um Ativo-Survey
  /// </summary>
  LINK = 0x00000018,
  /// <summary>
  /// Permite deligar dois objetos
  /// Exemplo: no Survey permite deslincar um Integrator-Sistema a um Ativo-Survey
  /// </summary>
  UNLINK = 0x00000019,
  READ_SENSITIVE_DATA = 0x00000020,
  /// <summary>
  /// Permite suspender ou bloquear
  /// Exemplo: no connect permite suspender ou bloquear um cliente .
  /// </summary>
  SUSPEND = 0x00000021,
  BLOCK = 0x00000022,

  ACTIVE = 0x00000023,
  PUBLISH = 0x00000025,
  LIKE = 0x00000026,
  KEY_USER = 0x00000027,
  /// <summary>
  /// Permite congelar ou descongelar
  /// </summary>
  FREEZE = 0x00000028,
  UNFREEZE = 0x00000029,
}

export { Action };
