import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconManagementUsers: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.01714 13.5714H5.75143C5.64857 13.5714 5.55428 13.6257 5.50286 13.7171L4.99142 14.6172L4.47999 13.7143C4.42857 13.6257 4.33428 13.5714 4.23142 13.5714H3.96857C3.35714 13.5714 2.85714 14.0657 2.85714 14.6714V16.8571C2.85714 17.0171 2.98571 17.1429 3.14285 17.1429H3.8698V15.8877C3.8698 15.681 3.99773 15.5138 4.15551 15.5138C4.3133 15.5138 4.44123 15.681 4.44123 15.8877V17.1429H5.54251V15.8877C5.54251 15.681 5.67044 15.5138 5.82823 15.5138C5.98601 15.5138 6.11394 15.681 6.11394 15.8877V17.1429H6.84C6.99714 17.1429 7.12571 17.0171 7.12571 16.8571V14.6714C7.12571 14.0657 6.62857 13.5714 6.01714 13.5714Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M6.23142 12.1886C6.23142 12.8743 5.67428 13.4286 4.99142 13.4286C4.30857 13.4286 3.75143 12.8743 3.75143 12.1886C3.75143 11.5057 4.30857 10.9514 4.99142 10.9514C5.67428 10.9514 6.23142 11.5057 6.23142 12.1886Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M16.0343 13.5714H15.7686C15.6657 13.5714 15.5714 13.6257 15.52 13.7171L15.0114 14.6172L14.4971 13.7143C14.4486 13.6257 14.3514 13.5714 14.2486 13.5714H13.9857C13.3743 13.5714 12.8771 14.0657 12.8771 14.6714V16.8571C12.8771 17.0171 13.0029 17.1429 13.1629 17.1429H13.8877V15.8627C13.8877 15.6698 14.0156 15.5138 14.1734 15.5138C14.3312 15.5138 14.4591 15.6698 14.4591 15.8627V17.1429H15.5604V15.8627C15.5604 15.6698 15.6883 15.5138 15.8461 15.5138C16.0039 15.5138 16.1318 15.6698 16.1318 15.8627V17.1429H16.8571C17.0171 17.1429 17.1428 17.0171 17.1428 16.8571V14.6714C17.1428 14.0657 16.6457 13.5714 16.0343 13.5714Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M16.2486 12.1886C16.2486 12.8743 15.6943 13.4286 15.0086 13.4286C14.3257 13.4286 13.7714 12.8743 13.7714 12.1886C13.7714 11.5057 14.3257 10.9514 15.0086 10.9514C15.6943 10.9514 16.2486 11.5057 16.2486 12.1886Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M8.15142 9.05144H8.87882C8.87882 9.05123 8.87873 9.05109 8.87873 9.05088V7.76796C8.87873 7.57655 9.00666 7.4217 9.16445 7.4217C9.32223 7.4217 9.45016 7.57655 9.45016 7.76796V9.05088C9.45016 9.05109 9.45008 9.05123 9.45008 9.05144H10.5515C10.5515 9.05123 10.5514 9.05109 10.5514 9.05088V7.76796C10.5514 7.57655 10.6794 7.4217 10.8372 7.4217C10.9949 7.4217 11.1229 7.57655 11.1229 7.76796V9.05088C11.1229 9.05109 11.1228 9.05123 11.1228 9.05144H11.8486C12.0057 9.05144 12.1343 8.92285 12.1343 8.76573V6.58C12.1343 5.97143 11.6371 5.48001 11.0257 5.48001H10.76C10.6571 5.48001 10.5629 5.53428 10.5114 5.62287L10.0029 6.5257L9.48858 5.62287C9.44 5.53428 9.34286 5.48001 9.23999 5.48001H8.97714C8.36571 5.48001 7.86571 5.97143 7.86571 6.58V8.76573C7.86571 8.92285 7.99428 9.05144 8.15142 9.05144Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M11.24 4.09713C11.24 4.77999 10.6857 5.33715 10 5.33715C9.31714 5.33715 8.75999 4.77999 8.75999 4.09713C8.75999 3.4143 9.31714 2.85714 10 2.85714C10.6857 2.85714 11.24 3.4143 11.24 4.09713Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      d='M12.64 12.24L11.9743 11.9943L12.2714 11.3457C12.32 11.2371 12.2971 11.1114 12.2143 11.0257L11.7857 10.5971C11.7 10.5114 11.5714 10.4886 11.4629 10.54L10.8171 10.8343L10.5686 10.1714C10.5286 10.06 10.4229 9.98573 10.3029 9.98573H9.69715C9.57715 9.98573 9.47144 10.06 9.42858 10.1714L9.18287 10.8372L8.53715 10.54C8.42858 10.4886 8.3 10.5114 8.21429 10.5971L7.78572 11.0257C7.70001 11.1114 7.67715 11.24 7.72857 11.3486L8.02571 11.9943L7.36001 12.24C7.24857 12.28 7.17429 12.3886 7.17429 12.5086V13.1143C7.17429 13.2314 7.24857 13.34 7.36001 13.38L8.02287 13.6286L7.72857 14.2743C7.67715 14.3828 7.70287 14.5114 7.78572 14.5943L8.21429 15.0228C8.3 15.1086 8.42572 15.1315 8.53429 15.0828L9.18287 14.7857L9.42858 15.4514C9.46858 15.5657 9.57715 15.64 9.69715 15.64H10.3029C10.4229 15.64 10.5286 15.5657 10.5686 15.4543L10.82 14.7857L11.4629 15.0828C11.5714 15.1315 11.7 15.1086 11.7857 15.0228L12.2143 14.5943C12.2971 14.5114 12.32 14.3828 12.2714 14.2743L11.9743 13.6286L12.6429 13.38C12.7543 13.34 12.8286 13.2314 12.8286 13.1143V12.5086C12.8286 12.3886 12.7543 12.28 12.64 12.24ZM10 13.8286C9.44 13.8286 8.98286 13.3714 8.98286 12.8114C8.98286 12.2486 9.44 11.7943 10 11.7943C10.56 11.7943 11.0171 12.2486 11.0171 12.8114C11.0171 13.3714 10.56 13.8286 10 13.8286Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconManagementUsers };
