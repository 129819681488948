import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import { AppBar, AppBarSpacer, AppBarSection } from '@progress/kendo-react-layout';
import style from './EmptyLayout.module.scss';

interface EmptyLayoutProps {
  children: ReactNode;
}

const EmptyLayout: FunctionComponent<EmptyLayoutProps> = (
  props: EmptyLayoutProps,
) => {
  const { children } = props;
  return (
    <Fragment>
      <AppBar themeColor='dark' className={style.topbar}>
        <AppBarSpacer style={{ width: 4 }} />
        <AppBarSection>
          <span style={{ fontSize: 21, fontFamily: 'Poppins' }}>
            Visualização do projeto
          </span>
        </AppBarSection>
      </AppBar>
      {children}
    </Fragment>
  );
};

export { EmptyLayout };
