import React, { FunctionComponent, memo } from 'react';
import { DropDownData, II18n, Module } from '../../../../models';
import { BrassDropDownButtonProps, BrassDropDownButton } from '../../..';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    ALL: 'TODOS',
  },
  enUS: {
    ALL: 'ALL',
  },
  ptBR: {
    ALL: 'TODOS',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

interface ModulesDropDownProps extends BrassDropDownButtonProps {
  excludeModules: string[];
}

const ModulesDropDown: FunctionComponent<ModulesDropDownProps> = memo(
  (props: ModulesDropDownProps) => {
    const { excludeModules } = props;

    const modules: DropDownData[] = Object.entries(Module)
      .map(([text, value]) => {
        return {
          text: translate(text),
          value,
        };
      })
      .filter((status) => !excludeModules.includes(status.value));

    return (
      <BrassDropDownButton
        {...props}
        fillMode='outline'
        textField='label'
        items={modules}
      />
    );
  },
);

export { ModulesDropDown };
export type { ModulesDropDownProps };
