import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
  UserGroupData,
  UserGroupNonParticipant,
  UserGroupParticipant,
} from '../../../../models';
import { NameField } from './name-field/';
import { UserService, UserGroupService } from '../../../../services';
import { useDispatch } from 'react-redux';
import { translate } from './UserGroupDataForm.translations';
import styles from './UserGroupDataForm.module.scss';
import { DescriptionField } from './description-field/DescriptionField';
import { UserGroupParticipants } from './user-group-participants/UserGroupParticipants';
import { UserGroupGridNonParticipants } from './user-group-grid-non-participants/UserGroupGridNonParticipants';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { UserGroupPrivilegesGrid } from './user-group-privileges/UserGroupPrivilegesGrid';
import { UserGroupGridOtherPrivileges } from './user-group-other-privileges/UserGroupGridOtherPrivileges';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  BrassGridConfigFullRecords,
  BrassSplitter,
  BrassTab,
  BrassTabContainer,
  BrassWindowActionsBar,
} from '../../../../../../components';
import { notificationActions } from '../../../../../../store';
import {
  ApiErrorsEnum,
  ApiGridResult,
  ApiResult,
  Notification,
  NotificationStyles,
} from '../../../../../../models';

interface UserGroupFormProps {
  isUpdate: boolean;
  userGroupId: string;
  onSaveClose?: () => void;
  onCancelClose?: () => void;
}

const UserGroupDataForm: FunctionComponent<UserGroupFormProps> = (
  props: UserGroupFormProps,
) => {
  const [selected, setSelected] = useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments): void =>
    setSelected(e.selected);

  const dispatch = useDispatch();
  const { userGroupId, isUpdate, onSaveClose, onCancelClose } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(isUpdate);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [messageError, setMessageError] = useState('');
  const [initialFormValues, setInitialFormValues] = useState<UserGroupData>(
    new UserGroupData({}),
  );
  const [userGroupNonParticipantsList, setUserGroupNonParticipantsList] =
    useState<ApiGridResult<UserGroupNonParticipant>>();

  useEffect(() => {
    if (isUpdate && userGroupId) {
      UserGroupService.getUserGroupData(userGroupId)
        .then((result: ApiResult<UserGroupData>) => {
          setInitialFormValues(result.data as UserGroupData);
        })
        .then(() => {
          UserGroupService.getUserGroupNonParticipants(BrassGridConfigFullRecords, {
            userGroupId,
          }).then((result: ApiResult<ApiGridResult<UserGroupNonParticipant>>) => {
            setUserGroupNonParticipantsList(result.data);
          });
        })
        .finally(() => {
          setTimeout(() => setLoadingData(false), 1500);
        });
    }
    if (!isUpdate) {
      UserService.getUsersList(BrassGridConfigFullRecords)
        .then((result: ApiResult<ApiGridResult<any>>) =>
          setUserGroupNonParticipantsList(result.data),
        )
        .finally(() => {
          setTimeout(() => setLoadingData(false), 1500);
        });
    }
  }, [isUpdate, userGroupId]);

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true);
    setMessageError('');

    const usersId =
      initialFormValues.users &&
      initialFormValues.users.map((user: UserGroupParticipant) => user.id);

    try {
      setAllowSubmit(true);
      const apiResult: ApiResult = isUpdate
        ? await UserGroupService.updateUserGroup({
            ...initialFormValues,
            users: usersId,
          })
        : await UserGroupService.createUserGroup({
            ...initialFormValues,
            users: usersId,
          });
      setLoadingData(false);
      if (apiResult.success) {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate(isUpdate ? 'successOnUpdate' : 'successOnInsert'),
            }),
          ),
        );
        if (onSaveClose) {
          onSaveClose();
        }
      } else {
        setAllowSubmit(false);
        setMessageError(apiResult.errorMessage!);
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: `
              ${translate(isUpdate ? 'errorOnUpdate' : 'errorOnAdd')} :
              ${translate(apiResult.message[0])}
            `,
            }),
          ),
        );
      }
    } catch (error: any) {
      setMessageError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
      setAllowSubmit(false);
    }
  };

  return (
    <BrassForm
      onSubmit={handleSubmit}
      render={() => (
        <BrassFormElement>
          <BrassTabContainer
            selected={selected}
            onSelect={handleSelect}
            className={styles.tabContainer}
          >
            <BrassTab title={translate('data')}>
              <NameField
                onChange={(param: any) => {
                  setInitialFormValues({
                    ...initialFormValues,
                    name: param.value,
                  });
                  setAllowSubmit(true);
                }}
                loading={loadingData}
                disabled={loadingData || submitting}
                defaultValue={initialFormValues.name}
              />

              <DescriptionField
                onChange={(param: any) => {
                  setInitialFormValues({
                    ...initialFormValues,
                    description: param.value,
                  });
                  setAllowSubmit(true);
                }}
                loading={loadingData}
                disabled={loadingData || submitting}
                defaultValue={initialFormValues.description}
              />
            </BrassTab>

            <BrassTab
              title={translate('users')}
              contentClassName={styles.tabContent}
              disabled={loadingData || submitting}
            >
              <BrassSplitter
                panes={[
                  {},
                  {
                    size: '50vw',
                    resizable: false,
                  },
                ]}
              >
                <UserGroupParticipants
                  setAllowSubmit={setAllowSubmit}
                  setUsersInsideGroupList={setInitialFormValues}
                  users={initialFormValues.users}
                  usersInsideGroupList={initialFormValues}
                  usersOutsideGroupList={userGroupNonParticipantsList!}
                  setUsersOutsideGroupList={setUserGroupNonParticipantsList}
                />
                <UserGroupGridNonParticipants
                  setAllowSubmit={setAllowSubmit}
                  setUsersInsideGroupList={setInitialFormValues}
                  usersInsideGroupList={initialFormValues}
                  usersOutsideGroupList={userGroupNonParticipantsList!}
                  setUsersOutsideGroupList={setUserGroupNonParticipantsList}
                />
              </BrassSplitter>
            </BrassTab>
            <BrassTab
              title={translate('privileges')}
              disabled={loadingData || submitting}
            >
              <BrassSplitter
                panes={[
                  {},
                  {
                    size: '50vw',
                    resizable: false,
                  },
                ]}
              >
                <UserGroupPrivilegesGrid
                  setAllowSubmit={setAllowSubmit}
                  userData={initialFormValues}
                  setUserData={setInitialFormValues}
                />
                <UserGroupGridOtherPrivileges
                  setAllowSubmit={setAllowSubmit}
                  userData={initialFormValues}
                  setUserData={setInitialFormValues}
                />
              </BrassSplitter>
            </BrassTab>
          </BrassTabContainer>

          <BrassWindowActionsBar>
            <BrassDivFlex />
            {messageError && <BrassError>{messageError}</BrassError>}
            <BrassButtonSave
              autoFocus
              onClick={handleSubmit}
              disabled={!allowSubmit || submitting}
              showLoader={submitting}
              hideIconWhenLoader
              labelKey={translate('saveGroupChanges')}
            />
            <BrassButtonCancel onClick={onCancelClose} />
          </BrassWindowActionsBar>
        </BrassFormElement>
      )}
    />
  );
};

export { UserGroupDataForm };
