import { II18n } from '../../../../../../models/i18n';
import { I18nService } from '../../../../../../services/i18n.service';

const translations: II18n = {
  es: {
    title: 'Carregar Imagem',
  },
  enUS: {
    title: 'Load Imagec',
  },
  ptBR: {
    title: 'Carregar Imagem',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
