import { ApiResult, ChatMessage, Endpoints } from '../../../models';
import { ApiService } from '../../../services';
import { getKeyFromItemOnListValue } from '../../../util';
import { Comment, UserCommunications } from '../models';
import { commentTypeGroup } from '../models/CommentTypeGroup.object';
import { CommentGroup } from '../models/enum';

class CommentsService {
  private static api: ApiService = new ApiService(Endpoints.CONNECT);

  public static async getCommentsList(): Promise<ApiResult<Comment[]>> {
    const apiResult: ApiResult = await this.api.get<UserCommunications[]>(
      `/connect/v2/comments/list`,
    );
    apiResult.data = apiResult.data.map((comment: Comment) => {
      return {
        ...comment,
        group: getKeyFromItemOnListValue(commentTypeGroup, comment.type)!,
      };
    });
    return apiResult;
  }

  public static async getChatMessages(
    id: string,
    group: CommentGroup,
  ): Promise<ApiResult> {
    let url = `/connect/v1/worktime-record/appropriation-comment/${id}`;
    if (group === CommentGroup.WORK_TIME_RECORD_AUTHORIZATION) {
      url = `/connect/v1/worktime-record/worktimerecord-authorization-comments/${id}`;
    } else if (group === CommentGroup.FINANCE) {
      url = `/connect/v1/finance/invoice/invoice-comments/${id}`;
    } else if (group === CommentGroup.OVERTIME_WORK) {
      url = `/connect/v1/worktime-record/insert-comment-overtime/${id}`;
    }
    return await this.api.get<ChatMessage[]>(url);
  }

  public static async newChatMessage(
    id: string,
    group: CommentGroup,
    message: ChatMessage,
  ): Promise<ApiResult> {
    let url = `/connect/v1/worktime-record/appropriation-comment/${id}`;
    if (group === CommentGroup.WORK_TIME_RECORD_AUTHORIZATION) {
      url = `/connect/v1/worktime-record/worktimerecord-authorization-comments/${id}`;
    } else if (group === CommentGroup.FINANCE) {
      url = `/connect/v1/finance/invoice/invoice-comments/${id}`;
    } else if (group === CommentGroup.OVERTIME_WORK) {
      url = `/connect/v1/worktime-record/insert-comment-overtime/${id}`;
    }
    return await this.api.post(url, {
      ...message,
    });
  }
}

export { CommentsService };
