import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import style from './AvatarV1.module.scss';

interface BrassAvatarV1Props {
  type: 'img' | 'text';
  content: string;
  tooltip?: string;
  className?: any;
}

const BrassAvatarV1: FunctionComponent<BrassAvatarV1Props> = (
  props: BrassAvatarV1Props,
) => {
  const { className, type, content, tooltip } = props;

  return (
    <div className={classNames(style['box'], className)}>
      {type === 'img' && <img src={content} className={style.image} />}
      {type === 'text' && (
        <span title={tooltip ?? content} className={style.text}>
          {content}
        </span>
      )}
    </div>
  );
};

export { BrassAvatarV1 };
export type { BrassAvatarV1Props };
