enum ConnectSubmodule {
  ACCOUNT = 0x00000100,
  USER = 0x00000200,
  EMPLOYEE = 0x00000300,
  USER_GROUP = 0x00000400,
  // Configurações Administrativas
  ADMINISTRATIVE_OPERATIONAL_CALENDAR = 0x00000500,
  ADMINISTRATIVE_MARITAL_STATUS = 0x00000600,
  ADMINISTRATIVE_ETHNICITY = 0x00000700,
  ADMINISTRATIVE_MEDICAL_EXAM = 0x00000800,
  ADMINISTRATIVE_MEDICAL_EXAM_SCHEDULER = 0x00000900,
  ADMINISTRATIVE_GENDER = 0x00000a00,
  ADMINISTRATIVE_REASONS_FOR_CONTRACT_CHANGE = 0x00000b00,
  ADMINISTRATIVE_NATIONALITY = 0x00000c00,
  ADMINISTRATIVE_EMPLOYEE_HIRING_LEVEL = 0x00000d00,
  ADMINISTRATIVE_EMPLOYMENT_RELATIONSHIP = 0x00000e00,
  ADMINISTRATIVE_ATTACHMENT_TYPE = 0x00000f00,
  // Configurações Recursos Humanos / Departamento Pessoal
  HUMAN_RESOURCES_EMPLOYEE_BENEFIT = 0x00001000,
  HUMAN_RESOURCES_EMPLOYEE_POSITION = 0x00001100,

  // CLIENTES dos projetos
  PROJECT_CUSTOMER = 0x00001200,

  // leitura de informacoes protegidas por LGPD
  READ_PROTECTED_LGPD_INFO = 0x00001300,
}

enum ConnectModulePermissions {
  SETUP_MODULE = 0x00010000,
  MANAGER_AREA_MODULE = 0x00020000,
  FINANCE_MODULE = 0x00030000,
  CONTRACT_MANAGMENT_MODULE = 0x00040000,
  RH_DP_MODULE = 0x00050000,
  EMPLOYEE_AREA_MODULE = 0x00060000,
  HELP_CENTER_MODULE = 0x00070000,
}

export { ConnectSubmodule, ConnectModulePermissions };
