import { GridItem } from '../../../models';
import { UserGroupParticipant } from './UserGroupParticipant.model';

class UserGroupData extends GridItem {
  public name: string = '';
  public description: string = '';
  public privileges: number[] = [];
  public users: UserGroupParticipant[] = [];

  constructor(userGroupData: Partial<UserGroupData>) {
    super(userGroupData);
    Object.assign(this, userGroupData);
  }
}

export { UserGroupData };
