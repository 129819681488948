import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Hint, HintProps } from '@progress/kendo-react-labels';

interface BrassHintProps extends HintProps {}

const BrassHint: FunctionComponent<BrassHintProps> = (props: BrassHintProps) => {
  const { className } = props;
  const _className = classnames('brass-hint', className);

  return <Hint {...props} className={_className} />;
};

export { BrassHint };
export type { BrassHintProps };
