import React, { FunctionComponent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { ChartSeries, ChartSeriesProps } from '@progress/kendo-react-charts';

interface BrassChartSeriesProps extends ChartSeriesProps, PropsWithChildren<any> {}

const BrassChartSeries: FunctionComponent<BrassChartSeriesProps> = (
  props: BrassChartSeriesProps,
) => {
  const { className } = props;
  const _className = classnames('brass-chart-series', className);
  return (
    <div className={_className}>
      <ChartSeries {...props} />
    </div>
  );
};

export { BrassChartSeries };
export type { BrassChartSeriesProps };
