import React, { FunctionComponent } from 'react';
import style from './ListViewFooter.module.scss';
import classnames from 'classnames';
import { ListViewFooter } from '@progress/kendo-react-listview';
import ListViewFooterProps from '@progress/kendo-react-listview/dist/npm/interfaces/ListViewFooterProps';

interface BrassListViewFooterProps extends ListViewFooterProps {}

const BrassListViewFooter: FunctionComponent<BrassListViewFooterProps> = (
  props: BrassListViewFooterProps,
) => {
  const { children, className } = props;
  const _className = classnames(
    'brass-listview-footer',
    style['brass-listview-footer'],
    className,
  );

  return <ListViewFooter className={_className}>{children}</ListViewFooter>;
};

export { BrassListViewFooter };
export type { BrassListViewFooterProps };
