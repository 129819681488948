import { II18n } from '../../models';

enum SystemTypeEnum {
  INSTITUTIONAL = 'INSTITUTIONAL',
  PHYSICAL = 'PHYSICAL',
}

const translationsSystemTypeEnum: II18n = {
  es: {
    PHYSICAL: 'físico',
    INSTITUTIONAL: 'institucional',
  },
  enUS: {
    PHYSICAL: 'physical',
    INSTITUTIONAL: 'institutional',
  },
  ptBR: {
    PHYSICAL: 'físico',
    INSTITUTIONAL: 'institucional',
  },
};

export { SystemTypeEnum, translationsSystemTypeEnum };
