import { AnyAction, combineReducers, createStore, EmptyObject, Store } from 'redux';
import { breadcrumbReducer, BreadcrumbState } from './breadcrumb';
import { notificationReducer, NotificationState } from './notification';
import { formReducer, IFormState } from './form';
import { progressPopUpReducer, ProgressPopUpState } from './preogress-popup';

interface RootReducerProps {
  breadcrumb: BreadcrumbState;
  notification: NotificationState;
  form: IFormState;
  progressPopUp: ProgressPopUpState;
}

const getReducers = (): RootReducerProps | any =>
  ({
    breadcrumb: breadcrumbReducer,
    notification: notificationReducer,
    form: formReducer,
    progressPopUp: progressPopUpReducer,
  } as any);

const createAppStore = (): Store<EmptyObject & RootReducerProps, AnyAction> =>
  createStore(combineReducers<RootReducerProps>(getReducers()));

const createAppStoreOthers = <T, TInterface>(
  otherStore: T,
): Store<EmptyObject & RootReducerProps & TInterface, AnyAction> =>
  createStore(
    combineReducers<RootReducerProps>({
      ...getReducers(),
      ...otherStore,
    }),
  );

export { createAppStore, createAppStoreOthers };
export type { RootReducerProps };
