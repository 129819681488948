import React from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    images: 'Imágenes',
    video: 'Vídeos',
    document: 'Documentos',
    images2d3d: 'Dibujos 2D | Modelos 3D',
  },
  enUS: {
    images: 'Images',
    video: 'Videos',
    document: 'Documents',
    images2d3d: '2D Drawings | 3D Models',
  },
  ptBR: {
    images: 'Imagens',
    video: 'Vídeos',
    document: 'Documentos',
    images2d3d: 'Desenhos 2D | Modelos 3D',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
