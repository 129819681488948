import React, { createContext, ReactNode, useState } from 'react';
import { Property } from '../models/Property.model';
import { PropertyMap } from '../models/PropertyMap.model';
import { getLanguagesStorage } from '../components';

interface PropertyState {
  mapProperty: PropertyMap;
  property: Property;
  propertyId: string;
  language: string;
  filter: string;
  propertiesSelecteds: Property[];
  refresh: boolean;
  setProperty: React.Dispatch<React.SetStateAction<Property>>;
  setMapProperty: React.Dispatch<React.SetStateAction<PropertyMap>>;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setPropertyId: React.Dispatch<React.SetStateAction<string>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setPropertiesSelecteds: React.Dispatch<React.SetStateAction<Property[]>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PropertyStateProvider {
  children: ReactNode;
}

const PropertyContext = createContext<PropertyState>({} as PropertyState);

const PropertyProvider: (props: PropertyStateProvider) => any = ({
  children,
}: PropertyStateProvider) => {
  const languageLocalStorage = getLanguagesStorage()?.language;
  const [property, setProperty] = useState<Property>({} as Property);
  const [mapProperty, setMapProperty] = useState<PropertyMap>({} as PropertyMap);
  const [language, setLanguage] = useState<string>(languageLocalStorage);
  const [propertyId, setPropertyId] = useState('');
  const [filter, setFilter] = useState<string>('');
  const [propertiesSelecteds, setPropertiesSelecteds] = useState<Property[]>(
    [] as Property[],
  );
  const [refresh, setRefresh] = useState<boolean>(false);

  return (
    <PropertyContext.Provider
      value={{
        mapProperty,
        property,
        language,
        filter,
        propertyId,
        propertiesSelecteds,
        refresh,
        setMapProperty,
        setProperty,
        setLanguage,
        setPropertyId,
        setFilter,
        setPropertiesSelecteds,
        setRefresh,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export { PropertyContext, PropertyProvider };
