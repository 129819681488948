import React, { useEffect, useState } from 'react';
import { IChatResponse as TreeChatType } from './CommentChat.model';
import style from './CommentChat.module.scss';
import { BrassChatBlockV1 } from './chat-block/ChatBlockV1';
export interface TreeChatProps {
  listChat: TreeChatType[];
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  level?: number;
  canComment: boolean;
  canLike: boolean;
  color?: string;
  background?: string;
}

const TreeChatV1: React.FC<TreeChatProps> = ({
  level = 1,
  listChat: listChatProp,
  setRefresh,
  canComment,
  canLike,
  color,
  background,
}) => {
  const [listChat, setListChat] = useState<TreeChatType[]>(listChatProp || []);

  return (
    <>
      {listChat.map((item, index) => (
        <div key={`${index}-${item?.chatId}`} className={style['tree-chat']}>
          <div
            style={{
              marginRight: level === 1 ? 0 : `${level * 16}px`,
              marginTop: '12px',
            }}
          >
            <BrassChatBlockV1
              text={item?.message}
              user={item?.sender?.name as string}
              chatId={item.chatId}
              referenceId={item?.referenceId}
              messageParentId={item?.messageParentId}
              likes={item?.likes}
              messages={item?.messages}
              setRefresh={setRefresh}
              firstParentId={item.firstParentId}
              canComment={canComment}
              canLike={canLike}
              color={color}
              background={background}
            />
          </div>
          {item.messages && (
            <TreeChatV1
              listChat={item.messages}
              level={level + 1}
              setRefresh={setRefresh}
              canComment={canComment}
              canLike={canLike}
              color={color}
              background={background}
            />
          )}
        </div>
      ))}
    </>
  );
};

export { TreeChatV1 };
