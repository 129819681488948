import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    date: 'Fecha',
    origin: 'Origen',
    description: 'Descripción',
    myNotifications: 'Mis Notificaciones',
    actions: 'Acciónes',
    answer: 'Responder',
    correctWhr: 'Corrigir IHT',
    successOnDelete: 'Notificación eliminado correctamente',
    errorOnDelete: 'Error al intentar excluir la Notificación',
  },
  enUS: {
    date: 'Date',
    origin: 'Origin',
    description: 'Description',
    myNotifications: 'My Notifications',
    actions: 'Actions',
    answer: 'Answer',
    correctWhr: 'Correct WHR',
    successOnDelete: 'Notification successfully deleted',
    errorOnDelete: 'Error while trying to exclude the Notification',
  },
  ptBR: {
    date: 'Data',
    origin: 'Origem',
    description: 'Descrição',
    myNotifications: 'Minhas Notificações',
    actions: 'Ações',
    answer: 'Responder',
    correctWhr: 'Corrigir RHT',
    successOnDelete: 'Notificação excluído com sucesso',
    errorOnDelete: 'Erro ao tentar excluir a Notificação',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
