import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { FormAction, FormCallbacks, FormState, GridItem } from '../../../models';
import {
  UserGroupData,
  UserGroupNonParticipant,
  UserGroupOtherPrivilege,
  UserGroupParticipant,
  UserGroupPrivilege,
} from '../models';

interface UserGroupContextProps {
  userGroupData: UserGroupData;
  setUserGroupData: Dispatch<SetStateAction<UserGroupData>>;
  groupParticipants: UserGroupParticipant[];
  setGroupParticipants: Dispatch<SetStateAction<UserGroupParticipant[]>>;
  groupNonParticipants: UserGroupNonParticipant[];
  setGroupNonParticipants: Dispatch<SetStateAction<UserGroupNonParticipant[]>>;
  groupPrivileges: UserGroupPrivilege[];
  setGroupPrivileges: Dispatch<SetStateAction<UserGroupPrivilege[]>>;
  otherPrivileges: UserGroupOtherPrivilege[];
  setOtherPrivileges: Dispatch<SetStateAction<UserGroupOtherPrivilege[]>>;
  formState: FormState<GridItem>;
  setFormState: Dispatch<SetStateAction<FormState<GridItem>>>;
  formCallbacks: FormCallbacks<GridItem>;
  setFormCallbacks: Dispatch<SetStateAction<FormCallbacks<GridItem>>>;
  formDispatch: Dispatch<FormAction>;
  setFormDispatch: Dispatch<SetStateAction<Dispatch<FormAction>>>;
}

interface UserGroupContextProviderProps extends PropsWithChildren<any> {}

const UserGroupContext = createContext<UserGroupContextProps>({
  userGroupData: new UserGroupData({}),
  setUserGroupData: () => {},
  groupParticipants: [],
  setGroupParticipants: () => {},
  groupNonParticipants: [],
  setGroupNonParticipants: () => {},
  groupPrivileges: [],
  setGroupPrivileges: () => {},
  otherPrivileges: [],
  setOtherPrivileges: () => {},
  formState: {} as FormState<GridItem>,
  setFormState: () => {},
  formCallbacks: {} as FormCallbacks<GridItem>,
  setFormCallbacks: () => {},
  formDispatch: {} as Dispatch<FormAction>,
  setFormDispatch: () => {},
});

const UserGroupContextProvider: FunctionComponent<UserGroupContextProviderProps> = (
  props: UserGroupContextProviderProps,
) => {
  const [userGroupData, setUserGroupData] = useState<UserGroupData>(
    new UserGroupData({}),
  );
  const [groupParticipants, setGroupParticipants] = useState<UserGroupParticipant[]>(
    [],
  );
  const [groupNonParticipants, setGroupNonParticipants] = useState<
    UserGroupNonParticipant[]
  >([]);
  const [groupPrivileges, setGroupPrivileges] = useState<UserGroupPrivilege[]>([]);
  const [otherPrivileges, setOtherPrivileges] = useState<UserGroupOtherPrivilege[]>(
    [],
  );
  const [formState, setFormState] = useState<FormState<GridItem>>(
    {} as FormState<GridItem>,
  );
  const [formCallbacks, setFormCallbacks] = useState<FormCallbacks<GridItem>>(
    {} as FormCallbacks<GridItem>,
  );
  const [formDispatch, setFormDispatch] = useState<Dispatch<FormAction>>(
    {} as Dispatch<FormAction>,
  );

  const valueProvider: UserGroupContextProps = {
    userGroupData,
    setUserGroupData,
    groupParticipants,
    setGroupParticipants,
    groupNonParticipants,
    setGroupNonParticipants,
    groupPrivileges,
    setGroupPrivileges,
    otherPrivileges,
    setOtherPrivileges,
    formState,
    setFormState,
    formCallbacks,
    setFormCallbacks,
    formDispatch,
    setFormDispatch,
  };

  return (
    <UserGroupContext.Provider value={valueProvider}>
      {props.children}
    </UserGroupContext.Provider>
  );
};

export { UserGroupContext, UserGroupContextProvider };
