import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './HelpCenter.module.scss';
import {
  BrassBadge,
  BrassBadgeContainer,
  BrassButton,
  BrassIcon,
  SVGIconHelpDesk,
} from '../../../../..';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import {
  NotificationService,
  UserNotificationCount,
} from '../../../../../../modules/notification';
import { ApiResult, ModuleUrlEnum } from '../../../../../../models';
import { redirectPlatform } from '../../../../../../util';
import { windowStage } from '@progress/kendo-react-dialogs/dist/npm/StageEnum';
import { EnvironmentService } from '../../../../../../services';
import { MainLayoutContainerContext } from '../../MainLayoutContainer';

const HelpCenter: FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    ticketsCount,
    setTicketsCount,
    isPulsingTickets,
    setIsPulsingTickets,
    handleCountTickets,
  } = useContext(MainLayoutContainerContext);

  useEffect(() => {
    handleCountTickets && handleCountTickets();
  }, []);

  return (
    <BrassBadgeContainer>
      <BrassButton
        fillMode='flat'
        onClick={(): void => {
          window.open('http://support.brassengineering.com/');
          /*
          setTicketsCount && setTicketsCount(0);
          setIsPulsingTickets && setIsPulsingTickets(false);

          const actualPlatform = EnvironmentService.app;

          if (actualPlatform == ModuleUrlEnum.CONNECT) {
            if (navigate) {
              navigate('/help-center');
              return;
            }
          }

          redirectPlatform(ModuleUrlEnum.CONNECT, 'help-center');*/
        }}
        className={isPulsingTickets ? style.alert : ''}
      >
        <SVGIconHelpDesk className={style.helpdesk} />
        {((ticketsCount && Number(ticketsCount) > 0) || ticketsCount === '99+') && (
          <BrassBadge
            align={{ vertical: 'top', horizontal: 'end' }}
            position='edge'
            themeColor='error'
            style={{
              margin: '12px 6px 0 0',
              fontSize: '0.7rem !important',
              zIndex: 0,
            }}
          >
            {ticketsCount}
          </BrassBadge>
        )}
      </BrassButton>
    </BrassBadgeContainer>
  );
};

export { HelpCenter };
