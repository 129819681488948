import React, {
  FunctionComponent,
  useState,
  useEffect,
  Fragment,
  SetStateAction,
  memo,
  Dispatch,
} from 'react';
import { translate } from './UserGroupParticipants.translations';
import { GridColumn } from '@progress/kendo-react-grid'; // CHANGE
import styles from './UserGroupParticipants.module.scss';
import {
  UserGroupData,
  UserGroupNonParticipant,
  UserGroupParticipant,
} from '../../../../../models';
import {
  AuthUser,
  ApiGridResult,
  ConnectPermissions,
} from '../../../../../../../models';
import {
  AccessDenied,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassTreeListGrid,
  BrassIconUser,
} from '../../../../../../../components';

interface UserGroupParticipantsProps {
  setAllowSubmit: Dispatch<SetStateAction<boolean>>;
  setUsersInsideGroupList: Dispatch<SetStateAction<UserGroupData>>;
  usersInsideGroupList: UserGroupData;
  setUsersOutsideGroupList: any;
  usersOutsideGroupList: ApiGridResult<UserGroupNonParticipant>;
  users: UserGroupParticipant[];
}

const UserGroupParticipants: FunctionComponent<UserGroupParticipantsProps> = memo(
  (props: UserGroupParticipantsProps) => {
    const {
      setUsersInsideGroupList,
      setUsersOutsideGroupList,
      users,
      usersInsideGroupList,
      usersOutsideGroupList,
    } = props;
    const [gridLoading, setGridLoading] = useState<boolean>();
    const [usersList, setUsersList] = useState<ApiGridResult<UserGroupParticipant>>({
      records: [],
      total: 0,
    });
    const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>({
      ...BrassGridConfigDefault,
      take: 99999,
    });

    const loadGridData = (): void => {
      setGridLoading(true);
      setUsersList({
        records: users,
        total: users ? users.length : 0,
      });
      setGridLoading(false);
    };

    useEffect(loadGridData, [users, gridLoading]);

    const onDeleteCallback = (record: any): void => {
      setUsersInsideGroupList({
        ...usersInsideGroupList,
        users: usersInsideGroupList.users.filter((item) => item.id !== record.id),
      });

      setUsersOutsideGroupList({
        ...usersOutsideGroupList,
        records: usersOutsideGroupList.records?.concat(record),
      });
      props.setAllowSubmit(true);
    };

    const columns = [
      <GridColumn
        key='name'
        field='name'
        title={translate('name')}
        columnMenu={BrassGridColumnMenu}
        cell={(_props: any) => {
          const record = _props.dataItem as UserGroupParticipant;
          if (record.isTemporary) {
            return (
              <td className={'project-name-group'}>
                {record.name}
                {translate('saveToKeep')}
              </td>
            );
          }
          return <td>{record.name}</td>;
        }}
        width={gridConfig.group?.length ? '200' : 'auto'}
      />,
      <GridColumn
        key='email'
        field='email'
        title={translate('email')}
        columnMenu={BrassGridColumnMenu}
        width={gridConfig.group?.length ? '200' : 'auto'}
      />,
    ];

    if (!AuthUser.checkPermission(ConnectPermissions.CONNECT_USERGROUP_READ)) {
      return <AccessDenied />;
    }

    return (
      <Fragment>
        <BrassTreeListGrid
          allowDelete={AuthUser.checkPermission(
            ConnectPermissions.CONNECT_USERGROUP_UPDATE,
          )}
          visibleButtonRefresh={false}
          className={styles.grid}
          columns={columns}
          gridData={usersList}
          gridLoading={gridLoading}
          setGridLoading={setGridLoading}
          gridTitle={translate('usersList')}
          gridIcon={<BrassIconUser />}
          gridConfig={gridConfig}
          updateGridConfig={setGridConfig}
          updateGridData={setUsersList}
          onRefreshCallback={loadGridData}
          onDeleteCallback={onDeleteCallback}
          pageable={false}
        />
      </Fragment>
    );
  },
);

export { UserGroupParticipants };
