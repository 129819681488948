import { I18nService, II18n } from '../../../';

const translations: II18n = {
  es: {
    editSystem: 'Editar {0}',
    includeSystem: 'Incusão de {0}',
    system: 'Conjunto',

    successOnUpdate: 'Sistema actualizado correctamente',
    errorOnUpdate: 'Error al intentar actualizar el sistema',

    successOnInclude: 'Sistema agregar correctamente',
    errorOnInclude: 'Error al intentar agregar el sistema',
  },
  enUS: {
    editSystem: 'Edit {0}',
    includeSystem: 'Include {0}',
    system: 'Set',

    successOnUpdate: 'System successfully updated',
    errorOnUpdate: 'Error while trying to update the system',

    successOnInclude: 'System successfully add',
    errorOnInclude: 'Error while trying to add the system',
  },
  ptBR: {
    editSystem: 'Edição de {0}',
    includeSystem: 'Inclusão de {0}',
    system: 'Sistema',

    successOnUpdate: 'Sistema atualizado com sucesso',
    errorOnUpdate: 'Erro ao tentar atualizar o sistema',

    successOnInclude: 'Sistema adicionado com sucesso',
    errorOnInclude: 'Erro ao tentar adicionar o sistema',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
