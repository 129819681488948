import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { translate } from './UserProfile.translations';
import style from './UserProfile.module.scss';
import {
  BrassButtonCancel,
  BrassButtonSave,
  BrassForm,
  BrassFormElement,
  BrassFormRenderProps,
  BrassLoader,
  BrassTextField,
  LanguagesField,
  LanguagesModel,
} from '../../../../components';
import { breadcrumbActions, notificationActions } from '../../../../store';
import {
  ApiResult,
  Breadcrumb,
  NotificationStyles,
  Notification,
  AuthUser,
  Language,
  ConnectPermissions,
} from '../../../../models';
import { UserProfileModel } from '../../models';
import { UserProfileService } from '../../services';
import { LanguagesService } from '../../../../services/languages.service';

const UserProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<UserProfileModel>(
    new UserProfileModel({}),
  );
  const [languages, setLanguages] = useState<LanguagesModel[]>(
    [] as LanguagesModel[],
  );
  const [selected, setSelected] = useState<LanguagesModel | null>(null);

  dispatch(
    breadcrumbActions.setBreadcrumb([
      new Breadcrumb({
        route: '/profile',
        title: translate('userProfile'),
      }),
    ]),
  );

  const LanguageMap = (language: Language) =>
    language === 'pt-BR'
      ? 'pt'
      : language === 'en-US'
      ? 'en'
      : language === 'es'
      ? 'es'
      : 'pt';

  useEffect(() => {
    LanguagesService.getAllLanguages().then((res) => {
      setLanguages(res as unknown as LanguagesModel[]);
      const language: LanguagesModel | null = JSON.parse(
        localStorage.getItem('language') as string,
      );
      if (language === null) {
        localStorage.setItem('language', JSON.stringify((res as any)[0]));
      }
      setSelected(language ?? (res as any)[0]);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    UserProfileService.getUserProfile()
      .then((apiResult: ApiResult<UserProfileModel>) => {
        setInitialFormValues(apiResult.data);
        const authUser = {
          ...AuthUser.currentUser,
          language: apiResult.data.language,
        };
        AuthUser.currentUser = authUser;
        languages.forEach((x) => {
          if (x.language === LanguageMap(apiResult.data.language)) {
            localStorage.setItem('language', JSON.stringify(x));
          }
        });
      })
      .finally(() => setLoading(false));
  }, [selected]);

  const renderForm: any = (formRenderProps: BrassFormRenderProps) => (
    <BrassFormElement className={style.form}>
      <h2>{translate('userProfile')}</h2>
      <LanguagesField
        defaultValue={initialFormValues.language}
        formRenderProps={formRenderProps}
      />

      {(window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1') && (
        <BrassTextField
          name='alternativeBackend'
          label='Backend'
          defaultValue={sessionStorage.getItem('alternative-api')}
          onChange={(e: any) => {
            sessionStorage.setItem('alternative-api', e.value);
          }}
        />
      )}

      <div>
        <BrassButtonSave type='submit' className={style['action-button']} />
        <BrassButtonCancel
          themeColor='base'
          onClick={() => navigate('/')}
          className={style['action-button']}
        />
      </div>
    </BrassFormElement>
  );

  const saveUserLanguage = async (
    _userProfile: UserProfileModel | any,
  ): Promise<void> => {
    _userProfile = new UserProfileModel({
      ...initialFormValues,
      ...(_userProfile as UserProfileModel),
    });
    try {
      setLoading(true);
      const apiResult: ApiResult = await UserProfileService.saveUserLanguage(
        _userProfile.language,
      );
      setLoading(false);
      if (apiResult.success) {
        handleSubmit(_userProfile);
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: `
                ${translate('errorOnUpdate')} :
                ${translate(apiResult.message[0])}
              `,
            }),
          ),
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleSubmit = async (
    _userProfile: UserProfileModel | any,
  ): Promise<void> => {
    _userProfile = new UserProfileModel({
      ...initialFormValues,
      ...(_userProfile as UserProfileModel),
    });
    try {
      setLoading(true);
      const apiResult: ApiResult = await UserProfileService.updateUserProfile(
        _userProfile as UserProfileModel,
      );
      setLoading(false);
      if (apiResult.success) {
        const authUser = {
          ...AuthUser.currentUser,
          language: _userProfile.language,
        };
        AuthUser.currentUser = authUser;
        languages.forEach((x) => {
          if (x.language === LanguageMap(_userProfile.language)) {
            localStorage.setItem('language', JSON.stringify(x));
          }
        });
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.SUCCESS,
              content: translate('successOnUpdate'),
            }),
          ),
        );
        location.reload();
      } else {
        dispatch(
          notificationActions.showNotification(
            new Notification({
              style: NotificationStyles.ERROR,
              content: `
                ${translate('errorOnUpdate')} :
                ${translate(apiResult.message[0])}
              `,
            }),
          ),
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div className={style.form}>
      {!loading && (
        <BrassForm
          onSubmit={saveUserLanguage}
          render={renderForm}
          initialValues={initialFormValues}
        />
      )}
      {loading && <BrassLoader useLoadingMask />}
    </div>
  );
};

export { UserProfile };
