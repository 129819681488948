import React, { FunctionComponent, memo } from 'react';
import { BrassChip } from '../../../../components';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';
import { ProjectStatus } from '../../models/enum/ProjectStatus.enum';

const translations: II18n = {
  es: {
    NOT_STARTED: 'NO COMENZADO',
    IN_PROGRESS: 'EN PROGRESO',
    COMPLETED: 'COMPLETADO',
    STOPPED: 'DETENIDO',
    CANCELLED: 'CANCELADO',
  },
  enUS: {
    NOT_STARTED: 'NOT STARTED',
    IN_PROGRESS: 'IN PROGRESS',
    COMPLETED: 'COMPLETED',
    STOPPED: 'STOPPED',
    CANCELLED: 'CANCELLED',
  },
  ptBR: {
    NOT_STARTED: 'NÃO INICIADO',
    IN_PROGRESS: 'EM PROGRESSO',
    COMPLETED: 'FINALIZADO',
    STOPPED: 'PARADO',
    CANCELLED: 'CANCELADO',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);
interface ProjectsDropDownProps
  extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  status: ProjectStatus;
}

const ProjectStatusGridCell: FunctionComponent<ProjectsDropDownProps> = memo(
  (props: ProjectsDropDownProps) => {
    let statusType: null | 'base' | 'info' | 'success' | 'warning' | 'error' = null;

    switch (props.status) {
      case ProjectStatus.STOPPED:
      case ProjectStatus.CANCELLED:
        statusType = 'warning';
        break;

      case ProjectStatus.COMPLETED:
        statusType = 'info';
        break;

      case ProjectStatus.IN_PROGRESS:
        statusType = 'success';
        break;

      default:
        statusType = null;
    }

    return (
      <td {...props}>
        <BrassChip
          text={translate(props.status)}
          themeColor={statusType}
          style={{ width: 150, border: 'solid 1px' }}
        />
      </td>
    );
  },
);

export { ProjectStatusGridCell };
