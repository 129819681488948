import React, { FunctionComponent } from 'react';
import {
  BrassTextFieldProps,
  I18nService,
  II18n,
  TextAreaField,
} from '../../../../';

const translations: II18n = {
  es: {
    descriptionLabel: 'Descripción',
  },
  enUS: {
    descriptionLabel: 'Description',
  },
  ptBR: {
    descriptionLabel: 'Descrição',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const DescriptionField: FunctionComponent<BrassTextFieldProps> = (
  props: BrassTextFieldProps,
) => (
  <TextAreaField
    {...props}
    maxLength={300}
    name='description'
    label={translate('descriptionLabel')}
  />
);

export { DescriptionField };
