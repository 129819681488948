import React from 'react';
import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    validationMessage:
      'Por favor, introduce una dirección de correo electrónico válida',
  },
  enUS: {
    validationMessage: 'Please enter a valid email',
  },
  ptBR: {
    validationMessage: 'Por favor, insira um email válido',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

const emailValidator = (value: string): string | undefined => {
  if (value && emailRegex.test(value)) {
    return undefined;
  } else if (!value) {
    return undefined;
  }

  return translate('validationMessage');
};

export { emailValidator };
