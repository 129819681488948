import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ApiResult,
  BrassDropDownField,
  BrassDropDownFieldProps,
  DropDownData,
  FileTypeService,
  I18nService,
  II18n,
} from '../../../../';

interface FileTypeDropDownProps extends BrassDropDownFieldProps {}
const FileTypeDropdown: FunctionComponent<FileTypeDropDownProps> = (
  props: FileTypeDropDownProps,
) => {
  const [systemClassifications, setFileType] = useState<DropDownData<any>[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    FileTypeService.getDropdown().then((result: ApiResult<DropDownData<any>[]>) => {
      if (!result.errorMessage) {
        setFileType(result.data);
        setLoading(false);
      }
    });
  }, []);

  return (
    <BrassDropDownField
      {...props}
      loading={loading}
      name='fileTypeId'
      options={systemClassifications}
      label={translate('fileType')}
    />
  );
};

const translations: II18n = {
  es: {
    fileType: 'Tipo de archivo',
  },
  enUS: {
    fileType: 'File Type',
  },
  ptBR: {
    fileType: 'Tipo do Arquivo',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { FileTypeDropdown };
export type { FileTypeDropDownProps };
