import React from 'react';
import { II18n } from '../../../../models';
import { I18nService } from '../../../../services';

const translations: II18n = {
  es: {
    completedCourses: 'Cursos completados',
    coursesTaken: 'Porcentaje de Cursos Tomados',
    totalClassHours: 'Total horas lectivas',
    performance: 'Desempeño',
    disciplinePerformance: 'Rendimiento de La Asignatura',
    chartTitle: 'Total de horas trabajadas por mes',
    titleCategory: 'Meses',
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ].toString(),
  },
  enUS: {
    completedCourses: 'Completed Courses',
    coursesTaken: 'Percentage Courses Taken',
    totalClassHours: 'Lesson time total',
    performance: 'Performance',
    disciplinePerformance: 'Subject Performance',
    chartTitle: 'Total Hours Worked Per Month',
    titleCategory: 'Months',
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mar',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ].toString(),
  },
  ptBR: {
    completedCourses: 'Cursos Concluídos',
    coursesTaken: 'Porcentagem de Cursos Feitos',
    totalClassHours: 'Total de Horas Aula',
    performance: 'Desempenho',
    disciplinePerformance: 'Desempenho na disciplina',
    chartTitle: 'Total de Horas Trabalhadas por Mês',
    titleCategory: 'Meses',
    months: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ].toString(),
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
