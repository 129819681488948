import {
  BrassIconDocument,
  BrassIconImages,
  BrassIconVideo,
  SVGIcon3d,
} from '../icon';
import {
  DocumentActionType,
  IPublishDocumentsListProps,
} from './PublishDocumentsModal.interfaces';
import style from './PublishDocumentsModal.module.scss';
import { translate } from './PublishDocumentsModal.translations';

const listPublishDocuments: IPublishDocumentsListProps[] = [
  {
    name: translate('addImages'),
    icon: (props: any) => (
      <BrassIconImages
        color={props?.color}
        className={style['icon-publish']}
        style={{ cursor: props?.cursor }}
        {...props}
      />
    ),
    action: 'IMAGE',
    multiple: true,
  },
  {
    name: translate('addVideos'),
    icon: (props: any) => (
      <BrassIconVideo
        color={props?.color}
        className={style['icon-publish']}
        style={{ cursor: props?.cursor }}
        {...props}
      />
    ),
    action: 'VIDEO',
    multiple: false,
  },
  {
    name: translate('addFiles'),
    icon: (props: any) => (
      <BrassIconDocument
        color={props?.color}
        className={style['icon-publish']}
        style={{ cursor: props?.cursor }}
        {...props}
      />
    ),
    action: 'DOCUMENT',
    multiple: false,
  },
  {
    name: translate('add3d'),
    icon: (props: any) => (
      <SVGIcon3d
        className={style['icon-publish']}
        {...props}
        style={{ cursor: props?.cursor }}
      />
    ),
    action: 'IMAGE2D3D',
    multiple: false,
  },
];

const getFileAction = (actionType: DocumentActionType): string | undefined => {
  switch (actionType) {
    case 'IMAGE':
      return '.jpeg, .jpg, .png';
    case 'VIDEO':
      return '.webm, .mp4, .mp3';
    case 'DOCUMENT':
      return '.pdf, .xls, .xlsx, .ppt, .pptx, .docx, .rar, .zip';
    case 'IMAGE2D3D':
      return '.fbx, .stl, .ifc, .step';
    default:
      return undefined;
  }
};

export { listPublishDocuments, getFileAction };
export type { IPublishDocumentsListProps, DocumentActionType };
