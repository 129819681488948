import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconDissatisfied: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.98782 0.584167C3.03099 0.590808 0.616486 2.977 0.584319 5.9435H0.584254V5.94357C0.553069 8.93223 2.95475 11.3846 5.94342 11.4157C8.93209 11.4469 11.3844 9.04523 11.4156 6.05673V6.05667C11.448 3.068 9.04625 0.615668 6.05775 0.584501C6.03438 0.584241 6.01107 0.584111 5.98783 0.584241L5.98782 0.584167ZM5.98867 0.960534C6.01035 0.960469 6.03209 0.960599 6.05383 0.960795C8.839 0.989831 11.0695 3.26746 11.0392 6.05263H11.0391V6.05269C11.0101 8.83786 8.73244 11.0684 5.94727 11.0394C3.1621 11.0103 0.931604 8.73286 0.960604 5.94753V5.94746C0.990682 3.18279 3.23294 0.966628 5.9886 0.960628L5.98867 0.960534Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.94299 6.77467C4.89976 6.77936 3.96049 7.30162 3.31733 8.11744C3.28536 8.15657 3.27045 8.20683 3.27592 8.25702C3.28139 8.30728 3.30678 8.35318 3.34643 8.38443C3.38614 8.41568 3.43666 8.42975 3.48679 8.42336C3.53686 8.41698 3.5823 8.39068 3.61283 8.35051C4.20392 7.60096 5.0517 7.14068 5.9885 7.15123H5.98876C6.92561 7.16067 7.76276 7.63853 8.33843 8.4013C8.36812 8.44212 8.41297 8.46926 8.46291 8.47669C8.51284 8.48411 8.56369 8.47115 8.60392 8.44075C8.64422 8.41035 8.67065 8.36503 8.67716 8.31497C8.68374 8.2649 8.66994 8.21432 8.63882 8.1746C8.00255 7.3315 7.05568 6.7856 5.99265 6.77487H5.99239H5.94285L5.94299 6.77467Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.10283 4.01467C3.7124 4.01005 3.38988 4.32782 3.3848 4.71727H3.38473V4.71818C3.38135 5.10803 3.69743 5.43283 4.08818 5.43621C4.47881 5.4396 4.80238 5.12338 4.80745 4.73361L4.80751 4.73355V4.7327C4.8109 4.3426 4.49403 4.01857 4.10361 4.01467V4.0146H4.10303H4.10277L4.10283 4.01467ZM4.09873 4.39103H4.09866H4.09951C4.28558 4.39266 4.43233 4.54221 4.43109 4.7286V4.72886C4.42855 4.91609 4.28044 5.06148 4.09144 5.05978C3.90538 5.05815 3.75986 4.90919 3.76111 4.72221V4.72195C3.76371 4.53472 3.91241 4.38855 4.09868 4.39103H4.09873Z'
      fill={props.fill ?? '#424242'}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.93815 4.05467C7.5483 4.05128 7.2252 4.3675 7.22012 4.75727V4.75733V4.75818C7.21673 5.14776 7.53235 5.47238 7.92279 5.47621V5.47628H7.92337H7.92357C8.31485 5.4809 8.63777 5.16313 8.64283 4.77368V4.77361V4.77277C8.64622 4.3824 8.3289 4.05812 7.93815 4.05473L7.93815 4.05467ZM7.9349 4.43104C8.12096 4.43266 8.26771 4.58221 8.26648 4.7686V4.76886C8.26394 4.9561 8.11563 5.10226 7.92768 5.09978L7.92762 5.09984H7.92683C7.74077 5.09822 7.59525 4.94926 7.5965 4.76228V4.76202C7.59904 4.57478 7.74761 4.42947 7.93485 4.4311L7.9349 4.43104Z'
      fill={props.fill ?? '#424242'}
    />
  </svg>
);

export { SVGIconDissatisfied };
