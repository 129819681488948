import React, { FunctionComponent, useContext, useState } from 'react';
import { BrassDialog, BrassWindowTitleCustom } from '../../../../../brass';
import { BrassIcon } from '../../../../../shared';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { UploadImage } from './UploadImage/UpLoadImage';
import { translate } from './SelectUploadImageDialog.translations';
import { UploadImageContext } from '../UploadImageContext';
import style from './SelectUploadImageDialog.module.scss';

interface ISelectUploadImageDialog {
  visible: boolean;
  changeUrl?: any;
}

const SelectUploadImageDialog: FunctionComponent<ISelectUploadImageDialog> = (
  props: any,
) => {
  const { changeUrl } = props;
  const { isActive, setIsActive } = useContext(UploadImageContext);

  const updateUrl = (e: string) => {
    changeUrl(e);
  };

  return (
    <>
      {isActive && (
        <BrassDialog
          onClose={() => setIsActive(false)}
          title={
            <BrassWindowTitleCustom
              className={style.label}
              title={translate('title')}
            />
          }
          height='473px'
          width='443px'
          showDarkBackground={false}
        >
          <UploadImage onChange={updateUrl} />
        </BrassDialog>
      )}
    </>
  );
};

export { SelectUploadImageDialog };
export type { ISelectUploadImageDialog };
