import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { BrassButtonSharedPropsV1, BrassButtonSharedV1 } from './ButtonV1';
import { BrassIconPublish } from '../icon/academy/IconPublish';

const BrassButtonPublish: FunctionComponent<BrassButtonSharedPropsV1> = (
  props: BrassButtonSharedPropsV1,
) => (
  <BrassButtonSharedV1
    themeColor='primary'
    className='brass-button-edit'
    labelKey='edit'
    tooltipKey='clickToPublish'
    translations={translations}
    icon={
      <BrassIconPublish style={{ color: props?.disabled ? '#9e9e9e' : '#3d3c3e' }} />
    }
    {...props}
  />
);

const translations: II18n = {
  es: {
    publish: 'Publicar',
    clickToPublish: 'Haga clic para publicar',
  },
  enUS: {
    publish: 'publish',
    clickToPublish: 'Click to publish',
  },
  ptBR: {
    publish: 'Publicar',
    clickToPublish: 'Clique para publicar',
  },
};

export { BrassButtonPublish };
