import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIconImage: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#clip0_366_13253)'>
      <path
        d='M0.503174 5.1875C0.503174 4.3587 0.832414 3.56384 1.41847 2.97779C2.00452 2.39174 2.79937 2.0625 3.62817 2.0625H22.3782C23.207 2.0625 24.0018 2.39174 24.5879 2.97779C25.1739 3.56384 25.5032 4.3587 25.5032 5.1875V20.8125C25.5032 21.6413 25.1739 22.4362 24.5879 23.0222C24.0018 23.6083 23.207 23.9375 22.3782 23.9375H3.62817C2.79937 23.9375 2.00452 23.6083 1.41847 23.0222C0.832414 22.4362 0.503174 21.6413 0.503174 20.8125V5.1875ZM2.06567 19.25V20.8125C2.06567 21.2269 2.23029 21.6243 2.52332 21.9174C2.81635 22.2104 3.21377 22.375 3.62817 22.375H22.3782C22.7926 22.375 23.19 22.2104 23.483 21.9174C23.7761 21.6243 23.9407 21.2269 23.9407 20.8125V15.3438L18.0391 12.3016C17.8926 12.2282 17.7267 12.2027 17.5649 12.2288C17.4031 12.2549 17.2536 12.3312 17.1375 12.4469L11.3407 18.2437L7.18442 15.475C7.03436 15.3751 6.85436 15.3302 6.67495 15.3478C6.49554 15.3655 6.32776 15.4446 6.20005 15.5719L2.06567 19.25ZM9.87817 9.09375C9.87817 8.47215 9.63124 7.87601 9.19171 7.43647C8.75217 6.99693 8.15602 6.75 7.53442 6.75C6.91282 6.75 6.31668 6.99693 5.87714 7.43647C5.4376 7.87601 5.19067 8.47215 5.19067 9.09375C5.19067 9.71535 5.4376 10.3115 5.87714 10.751C6.31668 11.1906 6.91282 11.4375 7.53442 11.4375C8.15602 11.4375 8.75217 11.1906 9.19171 10.751C9.63124 10.3115 9.87817 9.71535 9.87817 9.09375Z'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_366_13253'>
        <rect width='25' height='25' fill='white' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export { SVGIconImage };
