import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './Projects.module.scss';
import { translate } from './Projects.translations';
import { GridColumn } from '@progress/kendo-react-grid';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { breadcrumbActions } from '../../../../store';
import {
  ApiGridResult,
  ApiResult,
  Breadcrumb,
  Project,
  FiltersTextProps,
  ModuleEnum,
} from '../../../../models';
import {
  BrassTreeListGrid,
  BrassGridColumnMenu,
  BrassGridConfigDefault,
  BrassGridConfigProps,
  BrassDropDownButton,
  BrassStatusCell,
  BrassTooltip,
  BrassDivHtml,
  BrassGridCell,
} from '../../../../components/brass';
import {
  BrassIconNavigator,
  BrassIconInformation,
} from '../../../../components/shared';
import { ProjectFilters, ProjectFiltersText, ProjectService } from '../../services';

interface ProjectGridProps {
  module: ModuleEnum;
}

const ProjectGrid: FunctionComponent<ProjectGridProps> = (
  prop: ProjectGridProps,
) => {
  const dispatch = useDispatch();

  const [gridLoading, setGridLoading] = useState<boolean>(true);
  const [projectList, setProjectList] = useState<ApiGridResult<Project>>({});
  const [gridConfig, setGridConfig] = useState<BrassGridConfigProps>(
    BrassGridConfigDefault,
  );

  const [timer, setTimer] = useState<any>();

  const [filterList, setFilterList] = useState<
    FiltersTextProps<ProjectFiltersText, ProjectFilters>
  >(
    prop.module === ModuleEnum.CONNECT
      ? ProjectService.filterTextActive
      : ProjectService.filterTextActiveNavigatorOnly,
  );

  const loadGridData = useCallback((): void => {
    const loadData = (): void => {
      setGridLoading(true);
      ProjectService.getNavigatorProjects(filterList.filter, gridConfig).then(
        (apiResult: ApiResult<ApiGridResult<Project>>) => {
          setProjectList(apiResult.data);
          setGridLoading(false);
        },
      );
    };
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(loadData, 1000));
  }, [gridConfig, filterList]);

  if (prop.module === ModuleEnum.NAVIGATOR_INTEGRATION) {
    dispatch(
      breadcrumbActions.setBreadcrumb([
        new Breadcrumb({
          route: '/integrator/home',
          title: 'Integrator',
        }),
        new Breadcrumb({
          route: '/projects',
          title: translate('projectList'),
        }),
      ]),
    );
  } else {
    dispatch(
      breadcrumbActions.setBreadcrumb([
        new Breadcrumb({
          route: '/projects',
          title: translate('projectList'),
        }),
      ]),
    );
  }

  useEffect(loadGridData, [loadGridData]);

  const columns = [
    <GridColumn
      key='tag'
      field='tag'
      title={translate('columnTag')}
      width='200px'
      columnMenu={BrassGridColumnMenu}
    />,
    <GridColumn
      key='name'
      field='name'
      width='450px'
      title={translate('columnName')}
      columnMenu={BrassGridColumnMenu}
      cell={(_props: any) => {
        const record = _props.dataItem as Project;

        if (prop.module === ModuleEnum.NAVIGATOR_INTEGRATION) {
          const _connect = translate('connect');
          const _connectTooltip = translate('nameSecundaryConnectTooltip');

          return (
            <td>
              <div>{record.nameNavigator || record.name}</div>
              <BrassTooltip>
                <div title={_connectTooltip} className={style['project-name-group']}>
                  <BrassIconInformation size='sm' />
                  <div className={style['project-name-secundary']}>
                    {_connect}: {record.name}
                  </div>
                </div>
              </BrassTooltip>
            </td>
          );
        } else {
          const _navigator = translate('navigator');
          const _navigatorTooltip = translate('nameSecundaryNavigatorTooltip');
          return (
            <td>
              <div>{record.name}</div>
              {record.nameNavigator && record.nameNavigator !== record.name && (
                <BrassTooltip>
                  <div
                    title={_navigatorTooltip}
                    className={style['project-name-group']}
                  >
                    <BrassIconInformation size='sm' />
                    <div className={style['project-name-secundary']}>
                      {_navigator}: {record.nameNavigator}
                    </div>
                  </div>
                </BrassTooltip>
              )}
            </td>
          );
        }
      }}
    />,

    <GridColumn
      key='description'
      field='description'
      title={translate('columnDescription')}
      columnMenu={BrassGridColumnMenu}
      cell={(_props: any) => {
        const record = _props.dataItem as Project;

        return (
          <BrassGridCell {..._props}>
            <BrassDivHtml html={record.description} />
          </BrassGridCell>
        );
      }}
    />,

    <GridColumn
      key='status'
      field='status'
      title={translate('columnStatus')}
      cell={(_props: any) => {
        const record = _props.dataItem as Project;

        return (
          <BrassStatusCell
            {..._props}
            isActive={record.isActive}
            afterCell={
              record.isActiveNavigator && (
                <BrassIconNavigator
                  color='green'
                  size='2x'
                  title={translate('ACTIVE_ITEM')}
                  className={style['icon-navigator']}
                />
              )
            }
          />
        );
      }}
      width='130px'
      filterable={false}
    />,
  ];

  return (
    <BrassTreeListGrid
      columns={columns}
      gridData={projectList}
      gridLoading={gridLoading}
      gridTitle={translate('projectList')}
      onRefreshCallback={loadGridData}
      gridConfig={gridConfig}
      updateGridConfig={setGridConfig}
      updateGridData={setProjectList}
      setGridLoading={setGridLoading}
      toolbarBeforeActions={[
        <BrassDropDownButton
          key='BrassDropDownButton'
          fillMode='flat'
          textField='label'
          items={[
            ProjectService.filterTextAll,
            ProjectService.filterTextActive,
            ProjectService.filterTextInactive,
            ProjectService.filterTextActiveNavigatorOnly,
          ].filter((f) => f !== filterList)}
          text={filterList.label}
          onItemClick={(event: DropDownButtonItemClickEvent) =>
            setFilterList(event.item)
          }
        />,
      ]}
    />
  );
};

ProjectGrid.defaultProps = {
  module: ModuleEnum.CONNECT,
};

export { ProjectGrid };
