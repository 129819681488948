import React, { FunctionComponent, PropsWithChildren } from 'react';
import style from './FooterCell.module.scss';
import classnames from 'classnames';
import { GridFooterCellProps } from '@progress/kendo-react-grid';

interface BrassGridFooterCellProps
  extends GridFooterCellProps,
    PropsWithChildren<any> {
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  fieldName?: string;
}

const BrassGridFooterCell: FunctionComponent<BrassGridFooterCellProps> = (
  props: BrassGridFooterCellProps,
) => {
  const { className, dataItem, textAlign, fieldName, children } = props;
  const _className = classnames(
    'brass-grid-footer-cell',
    style['brass-grid-footer-cell'],
    className,
  );
  return (
    <td className={_className} style={{ textAlign }}>
      {fieldName ? dataItem[fieldName] : children}
    </td>
  );
};

export type { BrassGridFooterCellProps };
export { BrassGridFooterCell };
