import React, { FunctionComponent, useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { NameField } from './upset-system-form/NameField';
import { CostCenterField } from './upset-system-form/CostCenterField';
import { DescriptionField } from './upset-system-form/DescriptionField';
import { TagField } from './upset-system-form/TagField';
import style from './UpsertSystem.module.scss';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { SystemClassificationField } from './upset-system-form/SystemClassificationField';
import { translate } from './UpsertSystem.translations';
import {
  ApiResult,
  Account,
  BrassButtonCancel,
  BrassButtonSave,
  BrassDialog,
  BrassDialogActionsBar,
  BrassDivFlex,
  BrassError,
  BrassForm,
  BrassFormElement,
  Notification,
  notificationActions,
  NotificationStyles,
  ApiErrorsEnum,
  BrassFormRenderProps,
} from '../../../';
import { FileClassificationField } from './upset-system-form/FileClassificationField';
import { SystemTypeEnum } from '../../../models/';
import { System } from '../../../models/viewer/system.model';
import { SystemService } from '../../../services';

interface UpsertSystemProps {
  show?: boolean;

  onSaveClose?: () => void;
  onCancelClose?: () => void;

  projectId: string;

  areaId?: string;

  systemId?: string;
  parentSystemId?: string;

  type?: SystemTypeEnum;
  titleComplement?: string;
}

const UpsertSystem: FunctionComponent<UpsertSystemProps> = memo(
  (props: UpsertSystemProps) => {
    const {
      onSaveClose,
      onCancelClose,
      projectId,
      areaId,
      systemId,
      parentSystemId,
      type,
      titleComplement,
    } = props;
    const isUpdate = systemId !== undefined;

    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(isUpdate);

    const _titleComplement = titleComplement
      ? ` ${titleComplement}`
      : translate('system');

    const [initialFormValues, setInitialFormValues] = useState<System>(
      new System({
        type,
        accountId: Account.getId(),
        projectId,
        areaId,
        systemId,
        parentSystemId,
      }),
    );

    const onSubmitInclude = (_fieldsValue: System | any): void => {
      setSubmiting(true);
      setLoginError('');
      const fieldsValue = _fieldsValue as System;

      SystemService.includeSystem({
        ...initialFormValues,
        ...fieldsValue,
        projectId,
        areaId: areaId!,
        parentSystemId: parentSystemId!,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnInclude'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnInclude'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    const onSubmitUpdate = (_fieldsValue: System | any): void => {
      setSubmiting(true);
      setLoginError('');
      const fieldsValue = _fieldsValue as System;

      SystemService.updateSystem({
        ...initialFormValues,
        ...fieldsValue,
        type: type!,
        accountId: Account.getId(),
        projectId,
        areaId: areaId!,
        id: systemId!,
      })
        .then((apiResult: ApiResult) => {
          if (apiResult.errorMessage) {
            setLoginError(apiResult.errorMessage);
            setSubmiting(false);
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.ERROR,
                  content: translate('errorOnUpdate'),
                }),
              ),
            );
          } else {
            if (onSaveClose) {
              onSaveClose();
            }
            dispatch(
              notificationActions.showNotification(
                new Notification({
                  style: NotificationStyles.SUCCESS,
                  content: translate('successOnUpdate'),
                }),
              ),
            );
          }
        })
        .catch(() => {
          setLoginError(translate(ApiErrorsEnum.INTERNAL_SERVER_ERROR));
          setSubmiting(false);
        });
    };

    useEffect(() => {
      if (isUpdate && systemId) {
        SystemService.getSystem(projectId, systemId).then(
          (result: ApiResult<System>) => {
            setInitialFormValues(result.data);
            setLoadingData(false);
          },
        );
      }
    }, [isUpdate, systemId, projectId]);

    return (
      <BrassDialog
        onClose={onCancelClose}
        iconTitle={faEdit}
        title={translate(isUpdate ? 'editSystem' : 'includeSystem', [
          _titleComplement,
        ])}
      >
        <BrassForm
          onSubmit={isUpdate ? onSubmitUpdate : onSubmitInclude}
          render={({ allowSubmit, valid, onSubmit }: BrassFormRenderProps) => {
            let disableButtonSave = submiting;
            if (!valid) {
              disableButtonSave = true;
            } else if (!allowSubmit) {
              disableButtonSave = true;
            }

            return (
              <div style={isUpdate ? { padding: 10 } : {}}>
                <BrassFormElement style={{ height: 450, width: 600 }}>
                  <TagField
                    loading={loadingData}
                    style={{ width: 300 }}
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.tag}
                  />

                  <div className={style['div-fields-tag-name-file-classification']}>
                    <NameField
                      loading={loadingData}
                      disabled={loadingData || submiting}
                      defaultValue={initialFormValues && initialFormValues.name}
                    />
                  </div>

                  <div className={style['div-fields-tag-costcenter']}>
                    <SystemClassificationField
                      loading={loadingData}
                      style={{ width: 300 }}
                      disabled={loadingData || submiting}
                      defaultValue={
                        initialFormValues && initialFormValues.systemClassificationId
                      }
                    />
                  </div>

                  <div className={style['div-fields-tag-costcenter']}>
                    <FileClassificationField
                      loading={loadingData}
                      disabled={loadingData || submiting}
                      defaultValue={
                        initialFormValues && initialFormValues.fileClassificationId
                      }
                    />
                    <div className={style['div-fields-divider']} />
                    <CostCenterField
                      loading={loadingData}
                      disabled={loadingData || submiting}
                      defaultValue={
                        initialFormValues && initialFormValues.costCenter
                      }
                    />
                  </div>

                  <DescriptionField
                    loading={loadingData}
                    disabled={loadingData || submiting}
                    defaultValue={initialFormValues && initialFormValues.description}
                  />
                </BrassFormElement>

                <BrassDialogActionsBar>
                  <BrassDivFlex />
                  {loginError && <BrassError>{loginError}</BrassError>}
                  <BrassButtonSave
                    autoFocus
                    onClick={onSubmit}
                    disabled={disableButtonSave}
                    showLoader={submiting}
                    hideIconWhenLoader
                  />
                  <BrassButtonCancel onClick={onCancelClose} />
                </BrassDialogActionsBar>
              </div>
            );
          }}
        />
      </BrassDialog>
    );
  },
);

UpsertSystem.defaultProps = {
  type: SystemTypeEnum.PHYSICAL,
};

export { UpsertSystem };
export type { UpsertSystemProps };
