import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SVGIconMegaphonePlus: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 18 16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14 7.13601C14 7.26863 14.0527 7.39578 14.1464 7.4896C14.2402 7.58335 14.3674 7.63602 14.5 7.63602H17.5C17.6787 7.63602 17.8437 7.5407 17.933 7.38602C18.0223 7.23133 18.0223 7.0407 17.933 6.88602C17.8437 6.73133 17.6786 6.63602 17.5 6.63602H14.5C14.3674 6.63602 14.2402 6.68869 14.1464 6.78244C14.0527 6.87625 14 7.0034 14 7.13602V7.13601Z'
      fill='white'
    />
    <path
      d='M16.18 0.896005C16.1089 0.78396 15.9962 0.704922 15.8666 0.676405C15.7371 0.64789 15.6016 0.672173 15.49 0.743984L12.96 2.35798C12.8424 2.4266 12.758 2.54015 12.7261 2.67243C12.6941 2.80473 12.7175 2.94425 12.7908 3.05895C12.8641 3.1736 12.9809 3.25342 13.1143 3.28005C13.2478 3.30668 13.3863 3.27777 13.4979 3.19997L16.0279 1.58597C16.1395 1.51455 16.2182 1.40179 16.2467 1.27244C16.2752 1.14308 16.2512 1.00766 16.1799 0.896005H16.18Z'
      fill='white'
    />
    <path
      d='M12.96 11.914L15.49 13.528H15.4899C15.6401 13.6157 15.825 13.6191 15.9783 13.537C16.1316 13.4548 16.2312 13.299 16.2413 13.1254C16.2515 12.9518 16.1707 12.7854 16.028 12.686L13.498 11.072C13.3863 10.9942 13.2478 10.9653 13.1144 10.9919C12.9809 11.0186 12.8641 11.0984 12.7909 11.213C12.7175 11.3278 12.6942 11.4673 12.7261 11.5996C12.758 11.7318 12.8425 11.8454 12.96 11.914L12.96 11.914Z'
      fill='white'
    />
    <path
      d='M10 1.42201C9.86739 1.42201 9.74024 1.47468 9.64642 1.56843C9.55267 1.66225 9.5 1.7894 9.5 1.92202V2.58803C8.48398 3.41603 5.844 5.09803 1 5.30603V4.77407C1 4.59542 0.904687 4.43032 0.75 4.34107C0.595313 4.25174 0.404683 4.25174 0.25 4.34107C0.0953133 4.43032 0 4.59543 0 4.77407V9.49807C0 9.67672 0.0953133 9.84175 0.25 9.93107C0.404687 10.0204 0.595317 10.0204 0.75 9.93107C0.904683 9.84174 1 9.6767 1 9.49807V8.9521C1.27402 8.96408 1.53997 8.98211 1.8 9.0021V14.2361C1.8 14.6291 2.00963 14.9922 2.35 15.1887C2.69037 15.3852 3.10963 15.3852 3.45 15.1887C3.79037 14.9922 4 14.6291 4 14.2361V9.3041C6.972 9.89407 8.718 11.0401 9.5 11.6821V12.3501C9.5 12.5288 9.59531 12.6938 9.75 12.7831C9.90469 12.8724 10.0953 12.8725 10.25 12.7831C10.4047 12.6938 10.5 12.5288 10.5 12.3501V1.92213C10.5 1.78952 10.4473 1.66237 10.3536 1.56855C10.2598 1.4748 10.1326 1.42213 10 1.42213L10 1.42201Z'
      fill='white'
    />
  </svg>
);

export { SVGIconMegaphonePlus };
