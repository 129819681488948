import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { useWindowSize } from '../../../../hooks';
import BodyEnd from '../../body-end';
import { BrassIconInformation } from '../../icon';
import styles from './GeneralTooltip.module.scss';

interface ITooltipCustomModel {
  className?: string;
  description: string;
  color?: string;
}

const BrassGeneralTooltip: FunctionComponent<ITooltipCustomModel> = ({
  className,
  description,
  color,
}) => {
  const [openTootip, setOpenTootip] = useState<boolean>();
  const [pageXTootip, setPageXTootip] = useState<string>();
  const [pageYTootip, setPageYTootip] = useState<string>();
  const [width, height] = useWindowSize();

  const handleHover = (e: any) => {
    if (!pageXTootip) setPageXTootip(`${e?.clientX}px`);
    if (!pageYTootip) setPageYTootip(`${e?.clientY}px`);
    setOpenTootip(true);
  };

  const handleHoverLeave = () => {
    setPageXTootip('');
    setPageYTootip('');
    setOpenTootip(false);
  };

  return (
    <div className={[styles['brass-tooltip-custom'], className].join(' ')}>
      <BrassIconInformation
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
        className={styles['tooltip-icon']}
        color={color ?? '#8d8e91'}
      />
      <BodyEnd>
        <div
          style={{
            borderRadius: '6px',
            display: openTootip ? 'flex' : 'none',
            top: pageYTootip,
            left: pageXTootip,
            maxWidth: `${width - Number(pageXTootip)}px`,
          }}
          className={classNames({
            [styles['tooltip-body']]: true,
          })}
        >
          {description ? (
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></span>
          ) : (
            <span>-</span>
          )}
        </div>
      </BodyEnd>
    </div>
  );
};

export { BrassGeneralTooltip };
