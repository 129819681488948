import React, {
  FunctionComponent,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { ViewerService } from '../services/viewer.service';
import {
  NavigatorFileBreadcrumb,
  NavigatorFileViewer,
  RequestNavigatorFileParams,
} from '../models/viewer';
import { EnvironmentService, LocalStorageService } from '../services';
import { ApiResult, Breadcrumb } from '../models';
import { parseBool } from '../util';
import { emptyGuid } from '../util/Guid';
import { breadcrumbActions } from './breadcrumb';

interface ViewerContextProps {
  projectId?: string;
  areaId?: string;
  systemId?: string;
  fileId?: string;

  // Indica que o BE ainda não retonou os dados
  loadingViewer: boolean;

  // Indica que o usuário clicou no botão Atualizar
  loadingDetailsViewer: boolean;

  showDetails: boolean;
  showSidePanel: boolean;
  showEditDialog: boolean;
  showDownloadDialog: boolean;
  showShareDialog: boolean;
  showAddDocumentDialog: boolean;
  showAddAssetDialog: boolean;
  showViewedDocumentSharingDialog: boolean;
  showDocumentInformationsDialog: boolean;
  showAddAreaDialog: boolean;
  showAddSystemDialog: boolean;
  showUpsertActiveDialog: boolean;
  navigatorFile: NavigatorFileViewer;
  setShowSidePanel: (arg0: boolean) => void;
  setShowUpsertActiveDialog: Dispatch<SetStateAction<boolean>>;
  setNavigatorFile: Dispatch<SetStateAction<NavigatorFileViewer>>;
  showHideDetailsClick: () => void;
  showHideEditDialog: () => void;
  showHideDownloadDialog: () => void;
  showHideUploadDialog: () => void;
  showHideShareDialog: () => void;
  showHideUploadDocument: () => void;
  showHideAssetManagement: () => void;
  showHideViewedDocumentSharingDialog: () => void;
  showHideDocumentInformationsDialog: () => void;
  showHideAddAreaDialog: () => void;
  showHideAddSystemDialog: () => void;
  onEdit: () => void;
  onShare: (emailList: string[]) => void;
  onCreateShareLink: () => Promise<string>;
  onAddDocument: () => void;
  onAddAsset: () => void;
  onAddSubarea: () => void;
  onAddSystem: () => void;
  loadData: () => void;
  detailsViewerBarFilterValue: string;
  onDetailsViewerBarFilterChange: (value: string) => void;
  module: string;
}

interface ViewerContextProviderProps extends PropsWithChildren<any> {
  projectId?: string;
  areaId?: string;
  systemId?: string;
  fileId?: string;
}

const ViewerContext = createContext<ViewerContextProps>({
  projectId: emptyGuid(),
  areaId: emptyGuid(),
  systemId: emptyGuid(),
  fileId: emptyGuid(),
  loadingViewer: true,
  loadingDetailsViewer: true,
  showDetails: false,
  showSidePanel: false,
  showEditDialog: false,
  showDownloadDialog: false,
  showViewedDocumentSharingDialog: false,
  showShareDialog: false,
  showAddDocumentDialog: false,
  showAddAssetDialog: false,
  showAddAreaDialog: false,
  showAddSystemDialog: false,
  showDocumentInformationsDialog: false,
  showUpsertActiveDialog: false,
  navigatorFile: new NavigatorFileViewer({}),
  showHideViewedDocumentSharingDialog: () => {},
  setNavigatorFile: () => {},
  setShowSidePanel: () => {},
  setShowUpsertActiveDialog: () => {},
  showHideDetailsClick: () => {},
  showHideEditDialog: () => {},
  showHideDownloadDialog: () => {},
  showHideUploadDialog: () => {},
  showHideShareDialog: () => {},
  showHideUploadDocument: () => {},
  showHideAssetManagement: () => {},
  showHideDocumentInformationsDialog: () => {},
  showHideAddAreaDialog: () => {},
  showHideAddSystemDialog: () => {},
  onEdit: () => {},
  onShare: (_: string[]) => {},
  onCreateShareLink: () => new Promise(() => {}),
  onAddDocument: () => {},
  onAddAsset: () => {},
  onAddSubarea: () => {},
  onAddSystem: () => {},
  loadData: () => {},
  detailsViewerBarFilterValue: '',
  onDetailsViewerBarFilterChange: (_: string) => {},
  module: '',
});

const ViewerContextProvider: FunctionComponent<ViewerContextProviderProps> = (
  props: ViewerContextProviderProps,
) => {
  const { projectId, areaId, systemId, fileId } = props;
  const prefix = EnvironmentService.isNavigatorSurvey ? '/survey' : '/integrator';
  const dispatch = useDispatch();
  const [loadingViewer, setLoadingViewer] = useState<boolean>(true);
  const [loadingDetailsViewer, setLoadingDetailsViewer] = useState<boolean>(true);
  const lsIniticalStateShowDetails = LocalStorageService.getLocalStorage(
    'navigator-viewer-show-bottom-panel',
  );
  const initicalStateShowDetails = (lsIniticalStateShowDetails || 'false') as string;

  const lsShowDetails = parseBool(initicalStateShowDetails);
  const [showDetails, setShowDetails] = useState<boolean>(lsShowDetails);

  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
  const [showUpsertActiveDialog, setShowUpsertActiveDialog] =
    useState<boolean>(false);
  const [showAddDocumentDialog, setShowAddDocumentDialog] = useState<boolean>(false);
  const [showAddAssetDialog, setShowAddAssetDialog] = useState<boolean>(false);
  const [showViewedDocumentSharingDialog, setShowViewedDocumentSharingDialog] =
    useState<boolean>(false);
  const [showDocumentInformationsDialog, setShowDocumentInformationsDialog] =
    useState<boolean>(false);
  const [showAddAreaDialog, setShowAddAreaDialog] = useState<boolean>(false);
  const [showAddSystemDialog, setShowAddSystemDialog] = useState<boolean>(false);
  const [navigatorFile, setNavigatorFile] = useState<NavigatorFileViewer>(
    new NavigatorFileViewer({}),
  );
  const module = '';

  const showHideDetailsClick = (): void => {
    const newState = !showDetails;
    setShowDetails(newState);
    LocalStorageService.setLocalStorage(
      'navigator-viewer-show-bottom-panel',
      newState.toString(),
    );
  };

  const closeAllSidePanels = (): void => {
    setShowDownloadDialog(false);
    setShowAddDocumentDialog(false);
    setShowAddAssetDialog(false);
    setShowUploadDialog(false);
    setShowDocumentInformationsDialog(false);
    setShowViewedDocumentSharingDialog(false);
    setShowSidePanel(false);
  };

  const showHideEditDialog = (): void => setShowEditDialog(!showEditDialog);

  const showHideDownloadDialog = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showDownloadDialog);
    setShowDownloadDialog(!showDownloadDialog);
  };
  const showHideUploadDialog = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showUploadDialog);
    setShowUploadDialog(!showUploadDialog);
  };
  const showHideShareDialog = (): void => setShowShareDialog(!showShareDialog);
  const showHideUploadDocument = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showAddDocumentDialog);
    setShowAddDocumentDialog(!showAddDocumentDialog);
  };
  const showHideAssetManagement = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showAddAssetDialog);
    setShowAddAssetDialog(!showAddAssetDialog);
  };
  const showHideViewedDocumentSharingDialog = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showViewedDocumentSharingDialog);
    setShowViewedDocumentSharingDialog(!showViewedDocumentSharingDialog);
  };

  const showHideDocumentInformationsDialog = (): void => {
    closeAllSidePanels();
    setShowSidePanel(!showDocumentInformationsDialog);
    setShowDocumentInformationsDialog(!showDocumentInformationsDialog);
  };
  const showHideAddAreaDialog = (): void => setShowAddAreaDialog(!showAddAreaDialog);
  const showHideAddSystemDialog = (): void =>
    setShowAddSystemDialog(!showAddSystemDialog);

  const [detailsViewerBarFilterValue, setDetailsViewerBarFilterValue] =
    useState<string>('');

  const onEdit = (): void => {
    setShowEditDialog(!showEditDialog);
  };

  const onShare = async (emailList: string[]): Promise<void> => {
    const apiResult: ApiResult<string> = await ViewerService.shareDocument(
      emailList,
    );
    if (apiResult.data || !apiResult.data) {
      setShowShareDialog(!showShareDialog);
    }
  };

  const onCreateShareLink = async (): Promise<string> => {
    const apiResult: ApiResult<string> = await ViewerService.createShareLink();
    return apiResult.data;
  };

  const onAddDocument = (): void => {
    setShowAddDocumentDialog(!showAddDocumentDialog);
  };

  const onAddAsset = (): void => {
    setShowAddAssetDialog(!showAddAssetDialog);
  };

  const onAddSubarea = (): void => {
    setShowAddAreaDialog(!showAddAreaDialog);
  };

  const onAddSystem = (): void => {
    setShowAddSystemDialog(!showAddSystemDialog);
  };

  // Carrega os dados do Back
  const loadData = (): void => {
    setLoadingDetailsViewer(true);

    setNavigatorFile((s) => {
      return {
        ...s,
        children: [],
      };
    });

    const requestNavigatorFileParams = new RequestNavigatorFileParams({
      projectId: props.projectId || emptyGuid(),
      areaId: props.areaId || emptyGuid(),
      systemId: props.systemId || emptyGuid(),
      fileId: props.fileId || emptyGuid(),
    });

    ViewerService.getNavigatorFileViewer(requestNavigatorFileParams).then(
      (apiResult: ApiResult<NavigatorFileViewer>) => {
        const navigatorFileViewer = apiResult.data as NavigatorFileViewer;

        if (navigatorFileViewer.document) {
          if (navigatorFileViewer.document.key) {
            navigatorFileViewer.document.key = atob(
              atob(atob(navigatorFileViewer.document.key)),
            );
          }
          if (navigatorFileViewer.document.cnd) {
            navigatorFileViewer.document.cnd = atob(
              atob(atob(navigatorFileViewer.document.cnd)),
            );
          }
          if (navigatorFileViewer.document.viewer) {
            navigatorFileViewer.document.viewer = atob(
              atob(atob(navigatorFileViewer.document.viewer)),
            );
          }
          if (navigatorFileViewer.document.download) {
            navigatorFileViewer.document.download = atob(
              atob(atob(navigatorFileViewer.document.download)),
            );
          }

          if (navigatorFileViewer.children) {
            navigatorFileViewer.children.map((c) => {
              if (c.items) {
                c.items.map((i) => {
                  if (i.thumbnail) {
                    i.thumbnail = atob(atob(atob(i.thumbnail)));
                  }
                  return i;
                });
              }
              return c;
            });
          }
        }

        setNavigatorFile(new NavigatorFileViewer(navigatorFileViewer));

        setLoadingViewer(false);
        setLoadingDetailsViewer(false);

        const _breadcrumb = apiResult.data.breadcrumb.map(
          (bc: NavigatorFileBreadcrumb) => {
            let _route = '';

            if (bc.type === 'project') {
              _route = `${prefix}/projects/${bc.projectId}`;
            } else if (bc.type === 'area') {
              _route = `${prefix}/projects/${bc.projectId}/viewer-area/${bc.areaId}`;
            } else if (bc.type === 'system') {
              _route = `${prefix}/projects/${bc.projectId}/viewer-system/${bc.systemId}`;
            } else if (bc.type === 'file') {
              _route = `${prefix}/projects/${bc.projectId}/viewer-file/${bc.fileId}`;
            }

            return new Breadcrumb({
              route: _route,
              title: bc.name,
            });
          },
        );
        dispatch(breadcrumbActions.setBreadcrumb(_breadcrumb));
      },
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadData, [
    dispatch,
    props.areaId,
    props.fileId,
    props.projectId,
    props.systemId,
  ]);

  const onDetailsViewerBarFilterChange = (value: string): void =>
    setDetailsViewerBarFilterValue(value);

  const valueProvider: ViewerContextProps = {
    projectId,
    areaId,
    systemId,
    fileId,
    loadingViewer,
    loadingDetailsViewer,
    showDetails,
    showSidePanel,
    showEditDialog,
    showDownloadDialog,
    showShareDialog,
    showAddDocumentDialog,
    showAddAssetDialog,
    showViewedDocumentSharingDialog,
    showDocumentInformationsDialog,
    showAddAreaDialog,
    showAddSystemDialog,
    showUpsertActiveDialog,
    setShowUpsertActiveDialog,
    navigatorFile,
    setNavigatorFile,
    setShowSidePanel,
    showHideDetailsClick,
    showHideEditDialog,
    showHideDownloadDialog,
    showHideUploadDialog,
    showHideShareDialog,
    showHideUploadDocument,
    showHideAssetManagement,
    showHideViewedDocumentSharingDialog,
    showHideDocumentInformationsDialog,
    showHideAddAreaDialog,
    showHideAddSystemDialog,
    onEdit,
    onShare,
    onCreateShareLink,
    onAddDocument,
    onAddAsset,
    onAddSubarea,
    onAddSystem,
    loadData,
    detailsViewerBarFilterValue,
    onDetailsViewerBarFilterChange,
    module,
  };

  return (
    <ViewerContext.Provider value={valueProvider}>
      {props.children}
    </ViewerContext.Provider>
  );
};

export { ViewerContext, ViewerContextProvider };
