import React, { FunctionComponent } from 'react';
import style from './ListRelationalSubGroup.module.scss';
import { translate } from './ListRelationalSubGroup.translations';
import { BrassListCard } from '../../../../../components/shared/list-card';
import { BrassLoader } from '../../../../../components/brass';
import { Label } from '@progress/kendo-react-labels';
import { ListRelationalSubGroupFunctions } from './ListRelationalSubGroup.functions';

interface ListListRelationalSubGroupProps {}

const ListRelationalSubGroup: FunctionComponent<ListListRelationalSubGroupProps> = (
  props: ListListRelationalSubGroupProps,
) => {
  const {} = props;
  const { list, setList, onOpen, isLoading, pagination, scrollObserver } =
    ListRelationalSubGroupFunctions();

  return (
    <div style={{ width: '23%' }}>
      <Label style={{ opacity: 0.6, fontSize: '11px' }}>
        {translate('relationalSubGroup')}
      </Label>
      <div className={style.container}>
        <BrassListCard
          list={list as any}
          setList={setList as any}
          onAddClick={onOpen}
          fieldIdName={'groupRelationalId'}
          allowEdit={false}
          vertical={true}
          width={'270px'}
          noImage={true}
          take={pagination.take}
          scrollObserver={scrollObserver}
        />
      </div>

      {isLoading && <BrassLoader useLoadingMask />}
    </div>
  );
};

export { ListRelationalSubGroup };
