import { ApiResult, DropDownData } from '../';
import { Endpoints } from '../models';
import { ApiService } from './api.service';

class FileClassificationService {
  private static apiNavigator: ApiService = new ApiService(Endpoints.NAVIGATOR);

  public static async getDropdown(): Promise<ApiResult<DropDownData<any>[]>> {
    const api = this.apiNavigator;

    return api.get(`/connect/v1/fileclassification/dropdown`);
  }

  // public static getFileClassification(
  //   fileClassificationId: string,
  // ): Promise<ApiResult<FileClassificationModel>> {
  //   const api = this.apiNavigator;

  //   return api.get<FileClassificationModel>(
  //     `/connect/v1/fileclassification/${fileClassificationId}`,
  //   );
  // }

  // public static getFileClassificationList(
  //   gridConfig: BrassGridConfigProps,
  // ): Promise<ApiResult<ApiGridResult<FileClassificationModel>>> {
  //   const api = this.apiNavigator;

  //   return api.post<ApiGridResult<FileClassificationModel>>(
  //     '/connect/v1/fileclassification/list',
  //     gridConfig,
  //   );
  // }

  // public static insertFileClassificationData(
  //   fileClassificationParam: FileClassificationModel,
  // ): Promise<ApiResult> {
  //   const api = this.apiNavigator;

  //   return api.post('/connect/v1/fileclassification', fileClassificationParam);
  // }

  // public static updateFileClassificationData(
  //   fileClassificationParam: FileClassificationModel,
  // ): Promise<ApiResult> {
  //   const api = this.apiNavigator;

  //   return api.put('/connect/v1/fileclassification', fileClassificationParam);
  // }

  // public static deleteFileClassification(
  //   fileClassificationId: string,
  // ): Promise<ApiResult> {
  //   const api = this.apiNavigator;

  //   return api.delete(`/connect/v1/fileclassification/${fileClassificationId}`);
  // }
}

export { FileClassificationService };
