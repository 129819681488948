import React, { FunctionComponent } from 'react';
/* eslint-disable */

const SGVIconBussinessReport: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M17.74 5.82001L17.72 5.73999C17.6801 5.62001 17.62 5.52001 17.54 5.43999L12.7 0.539992C12.64 0.479965 12.58 0.439992 12.5 0.400018L12.22 0.320005L4.58001 0.300018C3.34004 0.300018 2.32001 1.32 2.32001 2.56002V17.36C2.32001 18.6 3.33999 19.62 4.58001 19.62H15.5C16.74 19.62 17.76 18.6 17.76 17.36V6.02002L17.74 5.82001ZM12.72 2.90001L15.24 5.46001H13.34C13.0001 5.46001 12.72 5.17999 12.72 4.84002L12.72 2.90001ZM6.70003 8.22001L5.08004 10.08L4.30003 9.38001L6.52003 6.82001L8.00004 7.80002L9.10004 6.66006L8.26008 6.14007L10.7201 5.30011L10.88 7.80011L10 7.24007L8.14002 9.18007L6.70003 8.22001ZM15.58 17.1H12.52V16.36H15.58V17.1ZM15.58 15.14H9.88003V14.4H15.58V15.14ZM15.58 13.24H9.88003V12.5H15.58V13.24Z' />
  </svg>
);

export { SGVIconBussinessReport };
