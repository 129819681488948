import React, { FunctionComponent } from 'react';
import {
  BrassDatePickerField,
  BrassDatePickerFieldProps,
} from '../../../../../../../../components';
import { translate } from './MessageDateField.translations';

const MessageDateField: FunctionComponent<BrassDatePickerFieldProps> = (
  props: BrassDatePickerFieldProps,
) => (
  <BrassDatePickerField
    {...props}
    required
    formatPlaceholder={{
      year: 'YYYY',
      month: 'MM',
      day: 'DD',
    }}
    format='dd/MM/yyyy'
    name='messageDate'
    label={translate('date')}
  />
);

export { MessageDateField };
