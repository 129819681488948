import { II18n } from '../../../../../models';
import { I18nService } from '../../../../../services';

const translations: II18n = {
  es: {
    confirmation: '¿Está seguro de que desea apagar el cliente?',
    title: 'Confirmación',
    sendNotificationUserSuccesfully: 'Cliente Apagado',
    sendNotificationUserError: 'Hubo un error al apagar el cliente',
  },
  enUS: {
    confirmation: 'Are you sure you want to delete the customer?',
    title: 'Confirmation',
    sendNotificationUserSuccesfully: 'Customer Deleted',
    sendNotificationUserError: 'There was an error deleting the client',
  },
  ptBR: {
    confirmation: 'Tem certeza que deseja deletar o cliente?',
    title: 'Confirmação',
    sendNotificationUserSuccesfully: 'Cliente Deletado',
    sendNotificationUserError: 'Ocorreu um erro ao deletar o cliente',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
