import React, { FunctionComponent, ImgHTMLAttributes } from 'react';
/* eslint-disable */

const SVGIconFilePublish: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width='25'
    height='26'
    viewBox='0 0 25 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#clip0_366_13268)'>
      <path
        d='M14.5203 0.5H6.25C5.4212 0.5 4.62634 0.82924 4.04029 1.41529C3.45424 2.00134 3.125 2.7962 3.125 3.625V22.375C3.125 23.2038 3.45424 23.9987 4.04029 24.5847C4.62634 25.1708 5.4212 25.5 6.25 25.5H18.75C19.5788 25.5 20.3737 25.1708 20.9597 24.5847C21.5458 23.9987 21.875 23.2038 21.875 22.375V7.85469C21.8749 7.44032 21.7102 7.04296 21.4172 6.75L15.625 0.957813C15.332 0.664767 14.9347 0.500088 14.5203 0.5V0.5ZM14.8438 5.96875V2.84375L19.5312 7.53125H16.4062C15.9918 7.53125 15.5944 7.36663 15.3014 7.0736C15.0084 6.78058 14.8438 6.38315 14.8438 5.96875ZM7.90156 9.875H17.0984L17.1875 13H16.4062C16.125 11.2875 15.85 11.1375 13.7594 11.0703L13.2938 11.0547V19.0078C13.2938 19.7422 13.45 19.9172 14.7047 20.0312V20.8125H10.2969V20.0312C11.5453 19.9172 11.7 19.7437 11.7 19.0094V11.0547L11.2422 11.0703C9.15 11.1375 8.875 11.2891 8.59375 13H7.8125L7.90156 9.875Z'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_366_13268'>
        <rect width='25' height='25' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export { SVGIconFilePublish };
