import React, { FunctionComponent } from 'react';
import { II18n } from '../../../models';
import { BrassIcon } from '../icon/Icon';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BrassButtonShared, BrassButtonSharedProps } from './Button';

const BrassButtonCheckCircle: FunctionComponent<BrassButtonSharedProps> = (
  props: BrassButtonSharedProps,
) => (
  <BrassButtonShared
    themeColor='primary'
    labelKey='activate'
    className='brass-button-activate'
    tooltipKey='clickToActivate'
    translations={translations}
    icon={<BrassIcon icon={faCheckCircle} />}
    {...props}
  />
);

const translations: II18n = {
  es: {
    activate: 'activar',
    clickToActivate: 'haga clic para activar',
  },
  enUS: {
    activate: 'activate',
    clickToActivate: 'click to activate',
  },
  ptBR: {
    activate: 'ativar',
    clickToActivate: 'clique para ativar',
  },
};

export { BrassButtonCheckCircle };
