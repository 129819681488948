import { FunctionComponent, ReactNode } from 'react';
import { AppRoute, AuthUser } from '../../../../models';
import { ModuleEnum } from '../../../../models/enum/ModuleEnum';
import { LoginRedirect } from '../../../../modules/auth';
import { BrassGeneralNotification } from '../../../shared';
import { BrassBreadCrumb } from '../../breadcrumb';
import { BrassProgressPopUp } from '../../progress-pop-up';
import { LeftMenu } from './left-menu/LeftMenu';
import { MainLayoutContainer } from './MainLayoutContainer';
import { TopBar } from './top-bar/TopBar';

interface MainLayoutProps {
  iconModule: any;
  module: ModuleEnum;
  children: ReactNode;
  routes: AppRoute[];

  hideLeftMenu?: boolean;

  showBreadcrumb?: boolean;
  roundBreadcrumb?: boolean;
  isModule?: boolean;
}
const MainLayout: FunctionComponent<MainLayoutProps> = (props: MainLayoutProps) => {
  const {
    routes,
    children,
    iconModule,
    module,
    showBreadcrumb,
    hideLeftMenu,
    roundBreadcrumb,
    isModule,
  } = props;
  const userIsAuthenticated = AuthUser.isAuthenticated();
  if (!userIsAuthenticated) {
    return <LoginRedirect />;
  }
  return (
    <MainLayoutContainer routes={routes}>
      <TopBar
        showMenuButton={!hideLeftMenu }
        iconModule={iconModule}
        module={module}
      />
      {showBreadcrumb && (
        <BrassBreadCrumb round={roundBreadcrumb} isModule={isModule} />
      )}
      <BrassGeneralNotification />
      <BrassProgressPopUp />
      <LeftMenu hideLeftMenu={hideLeftMenu}>{children}</LeftMenu>
    </MainLayoutContainer>
  );
};

MainLayout.defaultProps = {
  showBreadcrumb: true,
};

export { MainLayout };

