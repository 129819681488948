import { emptyGuid } from '../../../util';
import { UserNotificationContext } from './userNotificationContext.model';

class UserNotificationV0 {
  public id: string = emptyGuid();
  public origin: string = '';
  public senderId: string = emptyGuid();
  public senderName: string = '';
  public messageDate: Date = new Date();
  public messageDateFormated: string = '';
  public message: string = '';
  public reply: string = '';
  public context: UserNotificationContext = {};

  constructor(userNotification: Partial<UserNotificationV0>) {
    Object.assign(this, userNotification);
  }
}

export { UserNotificationV0 };
