import { ApiResult, Endpoints } from '../models';
import { ApiService } from './api.service';

class RelationalGroupService {
  private static api: ApiService = new ApiService(Endpoints.DRIVE);

  public static async getAllRelationalGroup(
    mapContextRelationalId: string,
    language: string,
    filter: string,
    take: number,
    skip: number,
  ): Promise<ApiResult> {
    return await RelationalGroupService.api.get(
      `/connect/v1/group/map-context-relational/${mapContextRelationalId}/list-Groups?language=${language}&search=${filter}&take=${take}&skip=${skip}`,
    );
  }

  public static async getAllRelationalSubGroup(
    parentGroupRelationalId: string,
    language: string,
    filter: string,
    take: number,
    skip: number,
  ): Promise<ApiResult> {
    return await RelationalGroupService.api.get(
      `/connect/v1/group/${parentGroupRelationalId}/list-Subgroups?language=${language}&search=${filter}&take=${take}&skip=${skip}`,
    );
  }

  public static async getGroupId(
    groupRelationalId: string,
    language: string,
  ): Promise<ApiResult> {
    return await RelationalGroupService.api.get(
      `/connect/v1/group/${groupRelationalId}/?language=${language}`,
    );
  }
}

export { RelationalGroupService };
