import { FunctionComponent, useEffect, useState } from 'react';
import {
  ApiResult,
  BrassDropDownField,
  BrassDropDownFieldProps,
  DropDownData,
  I18nService,
  II18n,
} from '../../../../';
import { SystemClassificationService } from '../../../../services/SystemClassification.service';

interface SystemClassificationDropDownProps extends BrassDropDownFieldProps {}

const SystemClassificationDropdown: FunctionComponent<
  SystemClassificationDropDownProps
> = (props: SystemClassificationDropDownProps) => {
  const [systemClassifications, setSystemClassification] = useState<
    DropDownData<any>[]
  >([]);
  const [loading, setLoading] = useState<boolean>(
    systemClassifications.length === 0,
  );

  useEffect(() => {
    SystemClassificationService.getDropdown().then(
      (result: ApiResult<DropDownData<any>[]>) => {
        if (!result.errorMessage) {
          setSystemClassification(result.data);
          setLoading(false);
        }
      },
    );
  }, [systemClassifications.length]);

  return (
    <BrassDropDownField
      {...props}
      loading={loading}
      name='systemClassificationId'
      options={systemClassifications}
      label={translate('systemClassification')}
    />
  );
};

const translations: II18n = {
  es: {
    systemClassification: 'Tipo de sistema',
  },
  enUS: {
    systemClassification: 'System Type',
  },
  ptBR: {
    systemClassification: 'Tipo de Sistema',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { SystemClassificationDropdown };
export type { SystemClassificationDropDownProps };

