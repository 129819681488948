import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';
import { filterBy } from '@progress/kendo-data-query';
import {
  MultiSelect,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
} from '@progress/kendo-react-dropdowns';
import style from './MultiSelect.module.scss';

interface BrassMultiSelectProps extends MultiSelectProps {
  onFilterChangeCustom?: (event: MultiSelectFilterChangeEvent) => void;
}

const BrassMultiSelect: FunctionComponent<BrassMultiSelectProps> = (
  props: BrassMultiSelectProps,
) => {
  const { className, data, onFilterChangeCustom } = props;
  const _className = classnames(
    'brass-multiselect',
    style['brass-multiselect'],
    className,
  );
  const [dataFiltered, setDataFiltered] = useState<any[] | undefined>(data);

  useEffect(() => setDataFiltered(data), [data]);

  const onFilterChange = (event: MultiSelectFilterChangeEvent) =>
    setDataFiltered(filterBy(dataFiltered || [], event.filter));

  const onClose = () => setDataFiltered(data);

  return (
    <div className={style['brass-multiselect']}>
      <MultiSelect
        {...props}
        data={dataFiltered}
        className={_className}
        onFilterChange={(event: MultiSelectFilterChangeEvent) => {
          if (onFilterChangeCustom) {
            onFilterChangeCustom(event);
          } else {
            onFilterChange(event);
          }
        }}
        onClose={onClose}
      />
    </div>
  );
};

export { BrassMultiSelect };
export type { BrassMultiSelectProps };
