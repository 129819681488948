import { GeneralCardBorderColorsEnum } from '../models/enum/GeneralCardBorderColorsEnum';

const getColorCardString = (color: string): string => {
  switch (color?.toUpperCase()) {
    case '#FACC2C':
      return 'yellow';
    case '#FDA451':
      return 'orange';
    case '#DA0B0B':
      return 'red';
    case '#3B4351':
      return 'midgray';
    case '#595959':
      return 'lightgray';
    case '#2D2B2B':
      return 'darkgray';
    case '#0070C0':
      return 'blue';
    case '#7030A0':
      return 'purple';
    default:
      return 'green';
  }
};

const getColorCardEnum = (color: string): GeneralCardBorderColorsEnum => {
  switch (color?.toUpperCase()) {
    case '#FACC2C':
      return GeneralCardBorderColorsEnum.YELLOW;
    case '#FDA451':
      return GeneralCardBorderColorsEnum.ORANGE;
    case '#DA0B0B':
      return GeneralCardBorderColorsEnum.RED;
    case '#3B4351':
      return GeneralCardBorderColorsEnum.MIDGRAY;
    case '#595959':
      return GeneralCardBorderColorsEnum.LIGHTGRAY;
    case '#2D2B2B':
      return GeneralCardBorderColorsEnum.DARKGRAY;
    case '#0070C0':
      return GeneralCardBorderColorsEnum.BLUE;
    case '#7030A0':
      return GeneralCardBorderColorsEnum.PURPLE;

    default:
      return GeneralCardBorderColorsEnum.GREEN;
  }
};

export { getColorCardString, getColorCardEnum };
