import React, { FunctionComponent } from 'react';
import style from './TopBar.module.scss';
import { AppBar, AppBarSpacer } from '@progress/kendo-react-layout';
import { BrassLogo } from '../../../logo';
import { Title } from './title/Title';
import { UserAvatar } from './user-avatar/UserAvatar';
import { MenuButton } from './menu-button/MenuButton';
import { Module } from './module/Module';
import { ModuleEnum } from '../../../../../models/enum/ModuleEnum';
import { MenuModuleButton } from './menu-module-button/MenuModuleButton';
import { StatusPage } from './status-page/StatusPage';
import { TokenValidTo } from './token-valid-to/TokenValidTo';
import { UserNotifications } from './user-notifications/UserNotifications';
import { UnauthorizedNotification } from './unauthorized-notification/UnauthorizedNotification';
import { UserComments } from './user-comments/UserComments';
import { SVGIconHelpDesk } from '../../../../shared/icon';
import { HelpCenter } from './help-center/HelpCenter';
import { ConnectHelpCenterPermissions } from '../../../../../models/permission/connect/Connect.HelpCenter.permissions.enum';
import { AuthUser } from '../../../../../models';

interface TopBarProps {
  iconModule: any;
  module: ModuleEnum;
  showMenuButton: boolean;
}

const TopBar: FunctionComponent<TopBarProps> = (props: TopBarProps) => (
  <AppBar className={style.topbar}>
    {props.showMenuButton && <MenuButton />}
    <Module iconModule={props.iconModule} module={props.module} />

    <AppBarSpacer />
    <UnauthorizedNotification />
    <BrassLogo className={style.brasslogo} />
    {AuthUser.checkPermission(ConnectHelpCenterPermissions.HELP_CENTER_MODULE) && (
      <HelpCenter />
    )}
    <StatusPage />
    <UserNotifications />
    <UserComments />
    {/* <TokenValidTo /> Verificar funcionalidade */}
    <MenuModuleButton />
    <UserAvatar />
  </AppBar>
);

export { TopBar };
// https://brassbrasil.checklyhq.com/
