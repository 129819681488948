import React, { FunctionComponent, memo, PropsWithChildren, useState } from 'react';
import style from './CommentsList.module.scss';
import { translate } from './CommentsList.translations';
import {
  BrassBadgeNew,
  BrassChip,
  BrassDivFlex,
  BrassListView,
  BrassListViewFooter,
  BrassListViewHeader,
} from '../../../../../components';
import { emptyGuid } from '../../../../../util';
import { Comment, CommentGroup, CommentStatus } from '../../../models';
import { ListViewItemProps } from '@progress/kendo-react-listview';
import { CommentsListSideView } from './comment-list-side-view/CommentsListSideView';
interface CommentsListProps extends PropsWithChildren<any> {
  data: Comment[];
  routes: any;
  commentsGroup: CommentGroup;
}

const CommentsList: FunctionComponent<CommentsListProps> = memo(
  (props: CommentsListProps) => {
    const [comment, setComment] = useState<Comment>({
      id: emptyGuid(),
      title: '',
      message: '',
      commentDate: new Date(),
      lastAlertDate: new Date(),
      newComments: 0,
      type: '',
      group: CommentGroup.ALL,
      status: CommentStatus.READED,
    });
    const { data } = props;

    const ItemRender = (props: ListViewItemProps) => {
      const item: Comment = props.dataItem;

      const selectedRowColor = '#c2dbff';
      const defaultBackgroundColor =
        item.status !== CommentStatus.CREATED ? 'unset' : '#f5f7f7';

      const commentsTitle = () => {
        let defaultTitle = (
          <div style={{ color: '#202124', paddingBlock: '4px', marginTop: '6px' }}>
            {translate(item.title)}
          </div>
        );

        if (item.status === CommentStatus.CREATED) {
          defaultTitle = (
            <h2 className={style.itemTitle}>
              <BrassBadgeNew
                align={{ horizontal: 'end', vertical: 'top' }}
                shape='dot'
              >
                <div style={{ marginRight: 10, fontWeight: 'bolder' }}>
                  {translate(item.title)}
                </div>
              </BrassBadgeNew>
            </h2>
          );
        }

        return defaultTitle;
      };

      return (
        <div
          className={style.list}
          style={{
            margin: 0,
            backgroundColor:
              comment.id === props.dataItem.id
                ? selectedRowColor
                : defaultBackgroundColor,
          }}
          onClick={() => {
            if (comment.id === props.dataItem.id) {
              setComment({ ...comment, id: emptyGuid(), group: CommentGroup.ALL });
            } else {
              setComment(props.dataItem);
            }
          }}
        >
          <div>
            {commentsTitle()}
            <div className={style.itemMessage}>{item.message}</div>
          </div>
          <BrassDivFlex />
          <div className={style.itemMessage}>
            {new Date(item.lastAlertDate).toLocaleString()}
          </div>
          {/* <div> Melhorar layout
            {item.hasNewAlerts! > 0 && (
              <BrassChip themeColor={'info'}>
                {`${item.hasNewAlerts}`}
              </BrassChip>
            )}
          </div> */}
        </div>
      );
    };

    return (
      <div className={style.div}>
        <BrassListView
          inlineItems={false}
          data={data}
          item={ItemRender}
          className={style.listView}
          header={() => (
            <BrassListViewHeader className={style.margin}>
              {translate('comments')}
            </BrassListViewHeader>
          )}
          footer={() => (
            <BrassListViewFooter className={style.margin}>
              {data.length} {translate('messagesInTotal')}
            </BrassListViewFooter>
          )}
        />
        <CommentsListSideView
          data={comment}
          routes={props.routes}
          commentsGroup={props.commentsGroup}
        />
      </div>
    );
  },
);

export type { CommentsListProps };
export { CommentsList };
