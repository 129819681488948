import { Action } from '../Permission.action.enum';
import { PermissionModule } from '../Permission.module.enum';
import { SurveySubmodule } from './Survey.submodule.enum';

enum SurveyPermissionsV1 {
  /// <summary>
  /// Cadastro de ativo
  /// </summary>
  ASSET_READ = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.READ,
  ASSET_CREATE = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.CREATE,
  ASSET_UPDATE = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.UPDATE,
  ASSET_DELETE = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.DELETE,
  ASSET_EXPORT = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.EXPORT,

  /// <summary>
  /// Permite vincular um Ativo a um Sistema no Integrator
  /// </summary>
  ASSET_LINK = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.LINK,
  ASSET_UNLINK = PermissionModule.SURVEY + SurveySubmodule.ASSET + Action.UNLINK,

  /// <summary>
  /// Cadastro de área
  /// </summary>
  AREA_READ = PermissionModule.SURVEY + SurveySubmodule.AREA + Action.READ,
  AREA_CREATE = PermissionModule.SURVEY + SurveySubmodule.AREA + Action.CREATE,
  AREA_UPDATE = PermissionModule.SURVEY + SurveySubmodule.AREA + Action.UPDATE,
  AREA_DELETE = PermissionModule.SURVEY + SurveySubmodule.AREA + Action.DELETE,
  AREA_EXPORT = PermissionModule.SURVEY + SurveySubmodule.AREA + Action.EXPORT,

  /// <summary>
  /// Cadastro de projeto
  /// </summary>
  SURVEY_PROJECT_READ = PermissionModule.SURVEY +
    SurveySubmodule.PROJECT +
    Action.READ,
  SURVEY_PROJECT_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.PROJECT +
    Action.CREATE,
  SURVEY_PROJECT_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.PROJECT +
    Action.UPDATE,
  SURVEY_PROJECT_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.PROJECT +
    Action.DELETE,
  SURVEY_PROJECT_EXPORT = PermissionModule.SURVEY +
    SurveySubmodule.PROJECT +
    Action.EXPORT,

  /// <summary>
  /// Cadastro de sistemas
  /// </summary>
  SYSTEM_READ = PermissionModule.SURVEY + SurveySubmodule.SYSTEM + Action.READ,
  SYSTEM_CREATE = PermissionModule.SURVEY + SurveySubmodule.SYSTEM + Action.CREATE,
  SYSTEM_UPDATE = PermissionModule.SURVEY + SurveySubmodule.SYSTEM + Action.UPDATE,
  SYSTEM_DELETE = PermissionModule.SURVEY + SurveySubmodule.SYSTEM + Action.DELETE,
  SYSTEM_EXPORT = PermissionModule.SURVEY + SurveySubmodule.SYSTEM + Action.EXPORT,

  /// <summary>
  /// Cadastro de fabricantes
  /// </summary>
  MANUFACTURER_READ = PermissionModule.SURVEY +
    SurveySubmodule.MANUFACTURER +
    Action.READ,
  MANUFACTURER_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.MANUFACTURER +
    Action.CREATE,
  MANUFACTURER_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.MANUFACTURER +
    Action.UPDATE,
  MANUFACTURER_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.MANUFACTURER +
    Action.DELETE,
  MANUFACTURER_EXPORT = PermissionModule.SURVEY +
    SurveySubmodule.MANUFACTURER +
    Action.EXPORT,

  /// <summary>
  /// Cadastro de códigos customizados
  /// </summary>
  CUSTOMCODE_READ = PermissionModule.SURVEY +
    SurveySubmodule.CUSTOMCODE +
    Action.READ,
  CUSTOMCODE_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.CUSTOMCODE +
    Action.CREATE,
  CUSTOMCODE_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.CUSTOMCODE +
    Action.UPDATE,
  CUSTOMCODE_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.CUSTOMCODE +
    Action.DELETE,
  CUSTOMCODE_EXPORT = PermissionModule.SURVEY +
    SurveySubmodule.CUSTOMCODE +
    Action.EXPORT,

  /// <summary>
  /// Cadastro de arquivos
  /// </summary>
  FILE_READ = PermissionModule.SURVEY + SurveySubmodule.FILE + Action.READ,
  FILE_CREATE = PermissionModule.SURVEY + SurveySubmodule.FILE + Action.CREATE,
  FILE_UPDATE = PermissionModule.SURVEY + SurveySubmodule.FILE + Action.UPDATE,
  FILE_DELETE = PermissionModule.SURVEY + SurveySubmodule.FILE + Action.DELETE,
  FILE_DOWNLOAD = PermissionModule.SURVEY + SurveySubmodule.FILE + Action.DOWNLOAD,

  /// <summary>
  /// Compartilhamneto de arquivos
  /// </summary>
  FILESHARE_READ = PermissionModule.SURVEY + SurveySubmodule.FILESHARE + Action.READ,
  FILESHARE_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.FILESHARE +
    Action.CREATE,
  FILESHARE_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.FILESHARE +
    Action.UPDATE,
  FILESHARE_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.FILESHARE +
    Action.DELETE,

  HIERARCHICAL_PLACE_READ = PermissionModule.SURVEY +
    SurveySubmodule.HIERARCHICAL_PLACE +
    Action.READ,
  HIERARCHICAL_PLACE_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.HIERARCHICAL_PLACE +
    Action.CREATE,
  HIERARCHICAL_PLACE_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.HIERARCHICAL_PLACE +
    Action.UPDATE,
  HIERARCHICAL_PLACE_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.HIERARCHICAL_PLACE +
    Action.DELETE,

  INFORMATION_BUILDING_READ = PermissionModule.SURVEY +
    SurveySubmodule.INFORMATION_BUILDING +
    Action.READ,
  INFORMATION_BUILDING_CREATE = PermissionModule.SURVEY +
    SurveySubmodule.INFORMATION_BUILDING +
    Action.CREATE,
  INFORMATION_BUILDING_UPDATE = PermissionModule.SURVEY +
    SurveySubmodule.INFORMATION_BUILDING +
    Action.UPDATE,
  INFORMATION_BUILDING_DELETE = PermissionModule.SURVEY +
    SurveySubmodule.INFORMATION_BUILDING +
    Action.DELETE,

  SYSTEM_MAP_READ = PermissionModule.SURVEY +
    SurveySubmodule.SYSTEM_MAP +
    Action.READ,
}

export { SurveyPermissionsV1 };
