import React, { Fragment, FunctionComponent, useState } from 'react';
import style from './NumericTextBoxField.module.scss';
import { translate } from './NumericTextBoxField.translations';
import { FieldValidatorType, FieldWrapper } from '@progress/kendo-react-form';
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { BrassField, BrassFieldProps } from '../Field';
import { BrassNumericTextBox, BrassNumericTextBoxProps } from '../../../inputs';
import { BrassError } from '../../../labels/Error';
import { BrassLoader } from '../../../indicators';
import { requiredValidator } from '../validators/required';
import { I18nService } from '../../../../../services';
import { Label } from '@progress/kendo-react-labels';

interface NumericTextBoxFieldInputProps extends BrassNumericTextBoxProps {
  value?: number;
  visited: boolean;
  maxLength?: number;
  disabled?: boolean;
}
const NumericTextBoxFieldInput: FunctionComponent<NumericTextBoxFieldInputProps> = (
  fieldRenderProps: any,
) => {
  const { validationMessage, value, disabled, onChange, visited } = fieldRenderProps;

  const [, setInternalValue] = useState<number | null>(value || 0);
  const onInternalChange = (e: NumericTextBoxChangeEvent): void => {
    if (onChange) {
      onChange(e);
    }
    setInternalValue(e.value);
  };

  return (
    <Fragment>
      <BrassNumericTextBox
        {...fieldRenderProps}
        disabled={disabled}
        onChange={onInternalChange}
      />
      {visited && validationMessage && <BrassError>{validationMessage}</BrassError>}
    </Fragment>
  );
};

interface BrassNumericTextBoxFieldProps
  extends Omit<BrassFieldProps & NumericTextBoxFieldInputProps, 'valid,component'> {
  name?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  validator?: FieldValidatorType[];
}
const BrassNumericTextBoxField: FunctionComponent<BrassNumericTextBoxFieldProps> = (
  props: any,
) => {
  const { name, required, loading, validator } = props;

  const validators = validator && validator.length ? [...validator] : [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (loading) {
    return (
      <BrassField
        {...props}
        disabled
        name={name!}
        component={BrassNumericTextBox}
        defaultValue={translate('loadingData')}
      />
    );
  }

  return (
    <FieldWrapper>
      <Label style={{ opacity: 0.6 }}>
        {required ? `${props.label}*` : props.label}
      </Label>
      <BrassField
        {...props}
        name={name!}
        validator={validators}
        component={loading ? () => <BrassLoader /> : NumericTextBoxFieldInput}
        label={undefined /* required ? `${props.label}*` : props.label*/}
      />
    </FieldWrapper>
  );
};

export { BrassNumericTextBoxField };
export type { BrassNumericTextBoxFieldProps };
