import React from 'react';
import { II18n } from '../../../models';
import { I18nService } from '../../../services';

const translations: II18n = {
  es: {
    título: 'Ver archivos adjuntos',
    IMAGE: 'Ver imágenes',
    VIDEO: 'Ver vídeos',
    DOCUMENT: 'Ver documentos',
    IMAGE2D3D: 'Ver Dibujos 2D y Modelos 3D',
  },
  enUS: {
    title: 'View Attachments',
    IMAGE: 'View images',
    VIDEO: 'View videos',
    DOCUMENT: 'View documents',
    IMAGE2D3D: 'View 2D Drawings and 3D Models',
  },
  ptBR: {
    title: 'Visualizar anexos',
    IMAGE: 'Visualizar imagens',
    VIDEO: 'Visualizar vídeos',
    DOCUMENT: 'Visualizar documentos',
    IMAGE2D3D: 'Visualizar Desenhos 2D e Modelos 3D',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
