enum NavigatorFileType {
  IMG = 'IMG',
  ZIP = 'ZIP',
  PDF = 'PDF',
  UNDEFINED = 'UNDEFINED',
  POINTCLOUD = 'POINTCLOUD',
  VIDEO = 'VIDEO',
  STL = 'STL',
}

enum NavigatorItemType {
  AREA = 'area',
  SYSTEM = 'system',
  FILE = 'file',
}

export { NavigatorFileType, NavigatorItemType };
