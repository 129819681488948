import { II18n } from '../../../../../../models';
import { I18nService } from '../../../../../../services';

const translations: II18n = {
  es: {
    mainDocument: 'Documento principal no disponible',
  },
  enUS: {
    mainDocument: 'Main document unavailable',
  },
  ptBR: {
    mainDocument: 'Documento principal indisponível',
  },
};

const translate = (key: string, args?: string[]): string =>
  new I18nService(translations).translate(key, args);

export { translate };
